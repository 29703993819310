import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FunctionType } from '@app/shared/services/template.service';
import { Chip, ChipType } from '../destination-chip/destination-chip.component';
import { ReferenceTableKey } from '../reference-table-form/reference-table-form.component';
import { flatMap } from 'lodash-es';

@Component({
  selector: 'app-reference-table',
  templateUrl: './reference-table.component.html',
  styleUrls: ['./reference-table.component.scss']
})
export class ReferenceTableComponent implements OnInit {
  @Input() set convert(con: string) {
    const convert = con.split(FunctionType.ReferenceTable)[1];
    const table = convert.split(';')[0];
    const rule = convert.split(';')[1];
    const keys: Array<ReferenceTableKey> = convert.split(';').slice(2).map(i => {
      const name = i.split('=')[0];
      const value = i.split('=')[1];
      const isXPath = value ? value.startsWith('$X{') : false;
      return { name, value, isXPath };
    });
    this.chips = [
      { value: table, missing: 'None', preSign: 'Table:', sign: '' },
      { value: rule, missing: 'None', preSign: 'Rule:', sign: 'Keys:' },
      ...flatMap(keys, k => {
        return [
          { value: k.name, missing: 'None', sign: '=' },
          { value: k.value, missing: 'None', sign: ';' }
        ];
      }),
    ].map(chip => {
      if (chip.value?.startsWith(`'$X{`)) {
        return {...chip, value: `XPATH:${chip.value.split(`'$X{`)[1].slice(0, -2)}`, type: ChipType.Variable };
      } else if (chip.value?.startsWith(`$X{`)) {
        return {...chip, value: `XPATH:${chip.value.split(`$X{`)[1].slice(0, -1)}`, type: ChipType.Variable };
      } else if (chip.value?.startsWith(`'$D{`)) {
        return {...chip, value: `${chip.value.split(`'$D{`)[1].slice(0, -2)}`, type: ChipType.Variable };
      } else if (chip.value?.startsWith(`$D{`)) {
        return {...chip, value: `${chip.value.split(`$D{`)[1].slice(0, -1)}`, type: ChipType.Variable };
      }
      return {...chip, type: ChipType.Literal };
    });
  }
  @Output() removeKey: EventEmitter<string> = new EventEmitter();
  @Input() wrap = false;

  chips: Array<Chip> = [];

  constructor() { }

  ngOnInit(): void {
  }

}
