import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { take, tap } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private auth: AuthService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.redirectIfUnauthenticated(next, state);
  }

  canLoad(): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(take(1));
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.redirectIfUnauthenticated(childRoute, state);
  }

  private redirectIfUnauthenticated({queryParams}: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(
      tap(
        (loggedIn) => {
          if (!loggedIn) {
            if (queryParams.email) {
              if (queryParams.message) {
                this.auth.loginWithRedirect({
                  appState: {target: state.url.split('?')[0]}
                });
              } else {
                this.auth.loginWithRedirect({
                  appState: {target: state.url.split('?')[0]},
                  authorizationParams: {
                    login_hint: queryParams.email,
                    usePasswordless: true
                  }
                });
              }
            } else {
              if (queryParams.from_inriver)
                this.auth.loginWithRedirect({
                  appState: {target: state.url.split('?')[0]},
                  authorizationParams: {
                    connection: `${environment.inriverConnection}`
                  }
                });
              else {
                this.auth.loginWithRedirect({appState: {target: state.url.split('?')[0]}});
              }
            }
          } else {
            return of(true);
          }
        }
      )
    );
  }
}


