/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModifiedDate } from './modifiedDate';
import { ProductImportStatusError } from './productImportStatusError';


export interface ProductImportStatus { 
    batchLoadId?: number;
    cancelFlag?: boolean;
    cancelUser?: string;
    createdDate?: ModifiedDate;
    endDate?: string;
    errors?: Array<ProductImportStatusError>;
    failCount?: number;
    fileErrorMessage?: string;
    fileName?: string;
    filePath?: string;
    fileType?: string;
    lastUpdated?: ModifiedDate;
    loadedAs?: string;
    organizationId?: number;
    passCount?: number;
    pluginName?: string;
    processType?: string;
    runStatus?: string;
    startDate?: string;
    statsId?: number;
    totalCount?: number;
}

