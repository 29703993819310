<div style="display: flex;">
  <div style="width: 49%; margin-right: 0.7em;">
    <span style="font-size: 0.9em;">Internal Field</span>
    <div class="AImatch" [ngClass]="{ 'stretch': showAITooltipTPfield }">
      <div class="AItooltip">
        <div class="AIicon" (mouseover)="showAITooltipTPfield = true" (mouseout)="showAITooltipTPfield = false">
          <i style="position: relative;
          right: -0.77em;
          top: -0.05em;" class="fas fa-sparkles"></i>
        </div>
        <div class="body" *ngIf="showAITooltipTPfield">AI Field Match Pairing</div>
      </div>
    </div>
    <app-suggestion [readOnly]="readOnlyField" [showClose]="showCloseOption"
      style="border: 11px solid #e3d9f5 !important;" class="field-select" label="Select an internal Field"
      (optionSelected)="selectSyndic8Field($event)" optionProp="id" [showList]="true"
      [selected]="[selectedSyndic8Field]" [provided]="internalFieldSuggestions">
    </app-suggestion>
  </div>
  <div style="position: relative; top: 2.5em;">
    <img class="arrowsAI" src="https://cdn-icons-png.flaticon.com/512/545/545783.png" width="32" height="32" />
  </div>
  <div style="width: 50%; margin-left: 0.7em;">
    <span style="font-size: 0.9em;">Trading Partner Field</span>

    <app-suggestion [readOnly]="readOnlyField" [showClose]="showCloseOption" class="field-list-select"
      style="margin-top: 0.66em;" label="Select a Trading Partner Field"
      (optionSelected)="selecTradingPartnerField($event)" [showList]="true" [selected]="[activeTemplateFieldTemp?.name]"
      [provided]="fieldListSuggestions">
    </app-suggestion>
  </div>
</div>
<div class="justify-right">
  <app-button [disabled]="(alignments$ | async)?.length > 0 || !enableBotButton" color="purple"
    style="font-size: 1rem; letter-spacing: normal; margin-right: 0.5em;" (click)="runAiBot()">
    <i style="margin-top: 0.18em;" class="fas fa-robot"></i>
    <span style="margin-left:0.5rem">Valid Value Bot</span>
  </app-button>
  <div *ngIf="!enableBotButton && (alignments$ | async)?.length == 0 " style="display: flex;">
    <span style="font-size: 0.8em; margin-right: 0.4em; position: relative; top: 0.4em;">Enabling button</span>
    <app-spinner [smaller]="true"></app-spinner>
  </div>
  <img *ngIf="(alignments$ | async)?.length == 0 && !validValueBot && enableBotButton" class="pulseArrow"
    src="https://cdn-icons-png.flaticon.com/512/1787/1787023.png" width="26" height="26" title="Click"
    style="  position: relative; top: 1.7em; left: -0.3em; height:-webkit-fill-available !important;">
  <div class="flex-fill"></div>
  <app-button *ngIf="(alignments$ | async)?.length > 0;" title="Remove" color="red" (click)="removeAllAlignments()">
    Remove all Alignments</app-button>
  <!-- start-   check for new values button -->
  <app-button style="margin-left: 0.5em;" *ngIf="(alignments$ | async)?.length > 0;" title="Check" color="purple"
    (click)="checkForNewValidValue()">
    New Valid Value</app-button>
  <!-- end -  check for new values button -->
  <app-button [disabled]="disableAcceptAllButton || this.newAlignmentsForm.length == 0"
    *ngIf="(alignments$ | async)?.length == 0 && validValueBot " icon="plus" color="green"
    (click)="addNewAlignments()">Accept
    All/Save</app-button>
</div>
<ng-container *ngIf="activeTemplateFieldTemp?.name && !alignmentsLoading">
  <!-- START - Alignments View -->
  <div *ngIf="(alignments$ | async)?.length > 0;">
    <span
      style="font-style: normal; font-size: 1.05rem; font-weight: 400; display: flex; flex-wrap: nowrap; align-content: center; justify-content: space-around; position: relative; bottom: 2em; color: black;">Alignments</span>
  </div>
  <span *ngIf="(alignments$ | async)?.length > 0;" style="text-align: center; font-size: 0.7em; margin-bottom: 1.8em;">

    <img class="rotate" width="16" height="16" src="https://cdn-icons-png.flaticon.com/512/1035/1035633.png"
      style="margin: 0 auto;" />
    Hover to show correlation %
  </span>
  <div *ngIf="(alignments$ | async)?.length > 0;"
    style="display: flex;flex-wrap: nowrap; align-content: center; justify-content: space-around;">
    <span style="margin-bottom: 1em; margin-left: 1em;">Unique Internal Fields</span>
    <span style="margin-bottom: 1em; margin-left: 1em;">Recommended Destination Value</span>
  </div>
  <div class="fields-2" *ngIf="(alignments$ | async)?.length > 0; else noAlignments">
    <div *ngFor="let alignment of alignmentsObject; index as i" style="display: flex; padding: 0em 2em 0em 3em;"
      class="alingmentsView">
      <input readonly
        style=" width: 45%; height: 41px; margin-bottom: 1.5em; cursor: not-allowed; font-size: 0.80em; padding: 10px 8px 10px 8px; -webkit-appearance: none; background: transparent; border: 0.5px solid lightgrey;"
        [value]="alignment?.columnValueBrand">
      <div class="tooltip" style="width: 8%;">
        <span *ngIf="alignment?.aiPercent !==null" class="tooltiptext">
          {{alignment?.aiPercent * 100 ?? 0 | number:'1.0-2'}} % AI correlation </span>
        <span *ngIf="alignment?.aiPercent  == null" class="tooltiptext2">AI correlation not
          found
        </span>
        <img style="position: absolute; bottom: 2.8em;min-width: 26px;
        min-height: 24px; max-width: 24px;
        max-height: 24px;" class="rotate" *ngIf="alignment?.aiPercent !==null && alignment?.aiPercent >=0.8 "
          id="{{i}}" width="24" height="24" src="../../../../../assets/logos/green_lightbulb.png" />
        <img style="position: absolute; bottom: 2.8em;min-width: 26px;
        min-height: 24px; max-width: 24px;
        max-height: 24px;" class="rotate" *ngIf="alignment?.aiPercent !==null && alignment?.aiPercent < 0.8 "
          id="{{i}}" width="24" height="24" src="https://cdn-icons-png.flaticon.com/512/1035/1035633.png" />
        <img style=" position: absolute; bottom: 2.8em; min-width: 26px;
        min-height: 24px; max-width: 24px;
        max-height: 24px;" class="rotate" *ngIf="alignment?.aiPercent  == null || alignment?.aiPercent  == 0"
          id="{{i}}" width="24" height="24" src="https://cdn-icons-png.flaticon.com/512/1035/1035471.png" />
      </div>
      <app-suggestion style="width: 45%; margin-bottom: 1.5em;" label="Select a Trading Partner Value"
        (optionSelected)="tradingPartnerValueOptionSelected($event, i); updateAlignmentValue($event, i)"
        [backgroundColor]="'white'"
        [selected]="[alignmentsForm.at(i)?.untouched ? alignment?.columnValueTradingPartner : null]"
        include="Trading Partner Field Values" [filters]="tradingPartnerFieldFilters"
        (closed)="clearAlignmentValue($event,i);">
      </app-suggestion>
      <app-spinner *ngIf="visibleIndices_2.has(i)" style="position: relative; bottom: 0.8em; left: 0.8em;"
        [smaller]="true"></app-spinner>
      <img class="checked" *ngIf="visibleIndices.has(i)" src="https://cdn-icons-png.flaticon.com/128/5290/5290119.png"
        width="21" height="21" title="Updated" style="position: relative; top: 0.3em; left: 0.6em; max-width: 21px;
        max-height: 21px;">
    </div>
  </div>
  <!-- END - Alignments View -->
  <ng-template #noAlignments>
    <div *ngIf="!validValueBot" class="no-alignments">No Field Alignments found</div>
    <div *ngIf="!validValueBot" class="no-alignments_2">Click on the Valid Value Bot button to get AI matches for fields
    </div>
  </ng-template>
  <!-- START - AI Valid Values automatches view -->
  <div class="valid-values-bot" *ngIf="validValueBot && (alignments$ | async)?.length == 0;">
    <div *ngIf="(alignments$ | async)?.length == 0;">
      <span
        style="font-style: normal; font-size: 1.05rem; font-weight: 400; display: flex; flex-wrap: nowrap; align-content: center; justify-content: space-around; position: relative; bottom: 2em; color: #845bcb;">AI
        Automatched Values</span>
    </div>
    <div *ngIf="(alignments$ | async)?.length == 0;"
      style="text-align: center; font-size: 0.7em; margin-bottom: 1.8em;">
      <img class="rotate" width="16" height="16" src="https://cdn-icons-png.flaticon.com/512/1035/1035633.png"
        style="margin: 0 auto;" />
      Hover to show correlation %
    </div>
    <div style="display: flex;flex-wrap: nowrap; align-content: center; justify-content: space-around;">
      <span style="margin-bottom: 1em; margin-left: 1em; font-weight: 400;">Unique Internal Fields</span>
      <span style="margin-bottom: 1em; margin-left: 1em; font-weight: 400;">Recommended Destination Value</span>
    </div>
    <div class="fields-2">
      <div *ngFor="let newAlignmentForm of newAlignmentsForm.controls; let index = index"
        style="display: flex; padding: 0em 2em 0em 3em;">
        <app-suggestion style=" width: 45%;margin-bottom: 1.5em;" [form]="newAlignmentForm" field="columnValueBrand"
          optionProp="name" [include]="'Product Distinct Values_' + activeInternalColumnName"
          [exclude]="excludedSyndic8Values" [hideDisabled]="true" [showClose]="false" [readOnly]="true">
        </app-suggestion>
        <div class="tooltip" style="width: 8%;">
          <span *ngIf="newAlignmentForm?.value?.tradingPartnerId?.name !==null" class="tooltiptext">
            {{newAlignmentForm?.value?.tradingPartnerId?.resultValue * 100 | number:'1.0-2'}} % AI correlation </span>
          <span *ngIf="newAlignmentForm?.value?.tradingPartnerId?.name == null " class="tooltiptext2">AI correlation not
            found
          </span>
          <!-- green lightbulb = 100% correlation-->
          <img style=" position: absolute; bottom: 2.8em;  min-width: 26px;
          min-height: 24px; max-width: 24px;
          max-height: 24px;" class="rotate"
            *ngIf="newAlignmentForm?.value?.tradingPartnerId?.name  !==null && newAlignmentForm?.value?.tradingPartnerId?.resultValue >= 0.8"
            id="{{index}}" width="24" height="24" src="../../../../../assets/logos/green_lightbulb.png" />
          <!-- yellow lightbulb = from 0 to 99% correlation-->
          <img style=" position: absolute; bottom: 2.8em;  min-width: 26px;
          min-height: 24px; max-width: 24px;
          max-height: 24px;" class="rotate"
            *ngIf="newAlignmentForm?.value?.tradingPartnerId?.name  !==null && newAlignmentForm?.value?.tradingPartnerId?.resultValue < 0.8"
            id="{{index}}" width="24" height="24" src="https://cdn-icons-png.flaticon.com/512/1035/1035633.png" />
          <!-- red lightbulb means no correlation found-->
          <img style=" position: absolute; bottom: 2.8em; min-width: 26px;
          min-height: 24px;   max-width: 24px;
          max-height: 24px;" class="rotate"
            *ngIf="newAlignmentForm?.value?.tradingPartnerId?.name  == null || newAlignmentForm?.value?.tradingPartnerId?.resultValue  == 0 "
            id="{{index}}" width="24" height="24" src="https://cdn-icons-png.flaticon.com/512/1035/1035471.png" />
        </div>
        <app-suggestion style="width: 45%;margin-bottom: 1.5em;" label="Select trading partner value"
          [form]="newAlignmentForm" field="tradingPartnerId" optionProp="id" include="Trading Partner Field Values"
          [filters]="tradingPartnerFieldFilters" [backgroundColor]="'white'"
          (closed)="caseFunctionClosed($event, index);" (optionSelected)="caseFunctionSelected($event, index);">
        </app-suggestion>
        <img class="checked" *ngIf="visibleIndices_3.has(index)"
          src="https://cdn-icons-png.flaticon.com/128/5290/5290119.png" width="21" height="21" title="Checked" style="position: relative; top: 0.3em; left: 0.6em; max-width: 21px;
          max-height: 21px;">
      </div>
      <div *ngIf="this.newAlignmentsForm.length == 0" style=" display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 200;
      color: black;
      font-size: 20px;
      height: 100px;">
        No Results
      </div>
    </div>
    <div style="display: flex; justify-content: flex-end;margin-top: 1.5em;">
    </div>
  </div>
  <!-- END - AI Valid Values automatches view -->

  <!-- Add a new valid value from Alignments view-->

  <app-modal [(open)]="showModalNewValidValueFromAligments" (close)="showModalNewValidValueFromAligments=false"
  [width]="700" [height]="550">
    <app-modal-head>
      <!-- <app-modal-title style="text-align: center; font-weight: 450 !important; color: rgb(0, 0, 0); font-size: 1em;">
        Add New Valid Value
      </app-modal-title> -->
    </app-modal-head>

    <app-modal-body style="background-color: #f6f5f5; ">
      <div *ngIf="!showResultsForNewValidValue" style="text-align: center;">
        <!-- *ngIf="!showResultsForNewValidValue"-->
        <app-spinner style="position: relative; top: 90px;" [bigger]="true"></app-spinner>
        <span style="text-align: center; position: relative; top: 70px;">Searching for new valid value
          matchings...</span>
      </div>
      <div *ngIf="showResultsForNewValidValue && !noResultsForNewValidValue ">
        <span style="position: relative; top: 70px;">
          <div class="tw-border-b tw-border-gray-200 tw-pb-5">
            <h3 class="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900" style="padding: 0 0 0 2em; color: #845bcb;">New
              Valid Values</h3>
            <p class="tw-mt-2 tw-max-w-4xl tw-text-sm tw-text-gray-500" style="padding: 0 0 0 2.2em;">Now we are able to
              add new valid value matchings
              without removing all existing matchings.</p>
          </div>
          <!--test values -->
          <div *ngFor="let value of newValidValue; let index = index" style="display: flex; padding: 2em 2em 0em 3em;">
            <input readonly
              style=" width: 45%; height: 41px; margin-bottom: 1.5em; cursor: not-allowed; font-size: 0.80em; padding: 10px 8px 10px 8px; -webkit-appearance: none; background: transparent; border: 0.5px solid lightgrey;"
              [value]="value?.inputName">
            <div class="tooltipNewMatch" style="width: 8%;">
              <span *ngIf="value?.resultValue !==null" class="tooltiptext">
                {{value?.resultValue * 100 ?? 0 | number:'1.0-2'}} % AI correlation </span>
              <span *ngIf="value?.resultValue  == null" class="tooltiptext2">AI correlation not
                found
              </span>
              <img style="position: absolute; bottom: 3.1em;min-width: 26px;
                min-height: 24px; max-width: 24px;
                max-height: 24px;" class="rotate" *ngIf="value?.resultValue !==null && value?.resultValue >=0.8 "
                id="{{i}}" width="24" height="24" src="../../../../../assets/logos/green_lightbulb.png" />
              <img style="position: absolute; bottom: 3.1em;min-width: 26px;
                min-height: 24px; max-width: 24px;
                max-height: 24px;" class="rotate" *ngIf="value?.resultValue !==null && value?.resultValue < 0.8 "
                id="{{i}}" width="24" height="24" src="https://cdn-icons-png.flaticon.com/512/1035/1035633.png" />
              <img style=" position: absolute; bottom: 3.1em; min-width: 26px;
                min-height: 24px; max-width: 24px;
                max-height: 24px;" class="rotate" *ngIf="value?.resultValue  == null || value?.resultValue  == 0"
                id="{{i}}" width="24" height="24" src="https://cdn-icons-png.flaticon.com/512/1035/1035471.png" />
            </div>
            <app-suggestion style="width: 45%;margin-bottom: 1.5em;" label="Select trading partner value"
              [selected]="[value?.name]" [form]="newAlignmentForm" field="tradingPartnerId" optionProp="id"
              include="Trading Partner Field Values" [filters]="tradingPartnerFieldFilters" [backgroundColor]="'white'"
              (optionSelected)="optionSelectedForNewValidValue($event, index);">
            </app-suggestion>
          </div>
        </span>
      </div>
      <div style="text-align: center; position: relative; top: 70px;" *ngIf="showResultsForNewValidValue && noResultsForNewValidValue">
        <span style="text-align: center; position: relative; top: 70px;"> No new valid values matchings available</span>
      </div>

    </app-modal-body>
    <app-modal-foot>
      <app-button  *ngIf="showResultsForNewValidValue && !noResultsForNewValidValue " (click)="addNewValidValue()" style="float: right;" color="purple" [disabled]="showResultsForNewValidValue && noResultsForNewValidValue">Add
      </app-button>
    </app-modal-foot>
  </app-modal>
</ng-container>

<app-page-loading style="text-align: center; position: relative; top: 70px;" [loading]="alignmentsLoading"></app-page-loading>