import { Pipe, PipeTransform } from '@angular/core';
import { FilterEntry } from "@app/core";

/** Used to filter down Template Fields used in app-template */
@Pipe({
  name: 'fieldsfilter'
})
export class FieldsFilterPipe implements PipeTransform {
  transform(items: any[], filters: FilterEntry[]): any[] {
    if (!items) {
      return [];
    }
    items = items.map((item, idx) => { return { ...item, idx: idx } });
    if (!filters) {
      return items;
    }
    let i = [...items];
    filters.forEach((filter) => {

      const column = filter?.filterBy;
      const value = filter?.filterValue.toLowerCase();

      if (column === 'name') {

        i = i.filter((field) => {
          const name = field.name?.toLowerCase();

          return name?.includes(value)
        });
      } else if (column === 'field') {
        i = i.filter((field) => {
          const fieldStartMatch = field.fieldStartMatch?.toLowerCase();
          return fieldStartMatch?.includes(value)
        });
      } else if (column === 'required') {
        i = i.filter((field) => {
          const requiredDisplay = field.requiredDisplay;
          const recommendedDisplay = field.recommendedDisplay;

          if (value === 'required' && requiredDisplay)
            return field;

          if (value === 'recommended' && (!requiredDisplay && recommendedDisplay)) {
            return field;
          }

          if (value === 'conditional' && (!requiredDisplay && !recommendedDisplay)) {
            return field;
          }

        });
      }
    });
    return i;
  }
}
