/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediaSlot } from './mediaSlot';
import { OrganizationReference } from './organizationReference';


export interface TPSpecs { 
    backgroundColor?: string;
    brandOrgRef?: OrganizationReference;
    description?: string;
    destinationOrgRef?: OrganizationReference;
    imageFormat?: string;
    imageFormatOverride?: string;
    imageMaxHeight?: number;
    imageMaxWidth?: number;
    mediaSlotList?: Array<MediaSlot>;
    mediaSlots?: number;
    mediaSlotsRequired?: number;
    swatchMaxHeight?: number;
    swatchMaxWidth?: number;
    tpSpecId?: number;
}

