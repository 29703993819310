import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PanelService } from '../../../services/panel.service';

@Component({
  selector: 'app-panel-edit-lock',
  templateUrl: './panel-edit-lock.component.html',
  styleUrls: ['./panel-edit-lock.component.scss']
})
export class PanelEditLockComponent implements OnInit {
  @Input() control: AbstractControl;
  @Output() save: EventEmitter<boolean> = new EventEmitter();
  @Output() discard: EventEmitter<boolean> = new EventEmitter();

  editable$ = this.panelSvc.editable$;

  constructor(private panelSvc: PanelService) { }

  ngOnInit(): void {
  }

  unlockEdit(): void {
    this.panelSvc.edit(true);
  }

  saveClick(): void {
    if (this.control && this.control.invalid) {
      return;
    }
    this.save.emit();
    this.panelSvc.edit(false);
  }

  discardClick(): void {
    this.discard.emit();
    this.panelSvc.edit(false);
  }
}
