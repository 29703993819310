import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HistoryService } from '@app/shared';

@Component({
  selector: 'app-missing',
  templateUrl: './missing.component.html',
  styleUrls: ['./missing.component.scss']
})
export class MissingComponent implements OnInit {

  in = false;
  supportUrl: string = 'https://support.syndic8.io';

  constructor(private router: Router, private history: HistoryService) { }

  ngOnInit() {
    setTimeout(() => this.in = true, 500);
    const currentUrl = window.location.href;
    this.supportUrl = currentUrl?.toLowerCase()?.includes('rapidmap') ? 'https://support.rapidmap.io' : 'https://support.syndic8.io'
  }

  navigateBack(): void {
    this.router.navigateByUrl(this.history.getUrl());
  }
}
