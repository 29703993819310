/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Audit } from './audit';
import { PurchaseOrderDetail } from './purchaseOrderDetail';
import { EdiLog } from './ediLog';
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';
import { Location } from './location';


export interface Container { 
    auditList?: Array<Audit>;
    blNumber?: string;
    companyIdAsn?: number;
    companyIdLsp?: number;
    containerId?: number;
    containerNumber?: string;
    containerType?: string;
    containerVolume?: number;
    containerVolumeUnit?: string;
    containerWeight?: number;
    containerWeightUnit?: string;
    createdDate?: ModifiedDate;
    estTimeOfArrivalDate?: string;
    estTimeOfDepartureDate?: string;
    fwdActPickupDate?: string;
    fwdBookingRequestDate?: string;
    fwdCargoReadyDate?: string;
    fwdEstPickupDate?: string;
    incoTerm?: string;
    ladingQuantity?: number;
    lastUpdated?: ModifiedDate;
    locationBillTo?: Location;
    locationConsignee?: Location;
    locationCustomer?: Location;
    locationFactory?: Location;
    locationFinalDestination?: Location;
    locationFreightForwarder?: Location;
    locationPortOfDischarge?: Location;
    locationShipTo?: Location;
    locationSupplier?: Location;
    masterBlNumber?: string;
    msgList?: Array<EdiLog>;
    orgRef?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    portOfLoading?: string;
    purchaseOrderDetails?: Array<PurchaseOrderDetail>;
    reqDeliveryDate?: string;
    sealNumber?: string;
    shipmentId?: string;
    statsInterfaceId?: number;
    veselMode?: string;
    vesselEta?: string;
    vesselName?: string;
    voyageName?: string;
}

