import {DataProcessField, Template} from "@app/core";

export class SetActiveDataProcessField {
  static readonly type = '[Preflight] Set Active DataProcessField';
  constructor(public dataProcessField: DataProcessField ) { }
}

export class UpdateSummaryStats {
  static readonly type = '[Preflight] Updates the Summary Stats';
  constructor(public payload: { summaryStats:  any}) { }
}

export class SaveEditedFunctions {
  static readonly type = '[Preflight] Saves the edited the functions for the template';
  constructor(public payload: { fields: Array<DataProcessField> } ) { }
}

export class LoadPreflightTemplate {
  static readonly type = '[Preflight] Loads the Selected Preflight Template';
  constructor() { }
}

export class SetPreflightTemplate {
  static readonly type = '[Preflight] Sets the Active Preflight Template';
  constructor(public template: Template) { }
}

export class StartPreflightProductsExport {
  static readonly type = '[Preflight] Starts a Preflight Product Export';
  constructor() { }
}

export class RestartPreflightProductsExport {
  static readonly type = '[Preflight] Restarts a Preflight Product Export';
  constructor() { }
}

export class ConnectToPreflightExportProductStream {
  static readonly type = '[Preflight] Connect to Preflight SSE stream for Product Export';
  constructor() { }
}

export class RecordStreamEventActionForPreflightProductExport {
  static readonly type = '[Preflight] Records the preflight stream.';
  constructor(public payload: { message: any }) { }
}

export class LoadHeadersForPreflightProductsExport {
  static readonly type = '[Preflight] Load Preflight Columns for Product Exports';
  constructor(public payload: { templateName: string }) { }
}

export class LoadSyndic8FieldSuggestionsActionForPreflight {
  static readonly type = '[Preflight] Load Syndicate Field suggestions for Preflight';
  constructor() { }
}

export class CreateImportTemplateHeaders {
  static readonly type = '[Preflight] Creates the import headers for Preflight';
  constructor() { }
}

export class DownloadPreflightFile {
  static readonly type = '[Preflight] Download Generated PreflightFile';
  constructor() { }
}

export class ClosePreflight {
  static readonly type = '[Preflight] Closes Preflight';
  constructor() { }
}

export class ResetPreflightState {
  static readonly type = '[Preflight] Resets Preflight State';
  constructor() { }
}

export class AddRows {
  static readonly type = '[Preflight] Add Preflight Rows to AG Grid';
  constructor(public rows: any) { }
}

export class UploadCorrectionFile {
  static readonly type = '[Preflight] Preflight Upload a Correction File';
  constructor(public files: any) { }
}
