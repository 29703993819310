/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Suggestion { 
    altName?: string;
    description?: string;
    destinationId?: number;
    disabled?: boolean;
    email?: string;
    geographyGroup?: string;
    id?: string;
    jsonField?: boolean;
    latitude?: string;
    logo?: string;
    longitude?: string;
    name?: string;
    organizationId?: number;
    scacCode?: string;
    seedFile?: string;
    syndicationType?: string;
    timeZone?: string;
    tradingPartnerFlag?: boolean;
    type1?: string;
    type2?: string;
    type3?: string;
    type4?: string;
    type5?: string;
}

