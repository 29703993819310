import { Component, OnInit, Input, HostBinding, HostListener, OnDestroy } from '@angular/core';
import { NavigationService, NavigationActionItem } from '../../../services/navigation.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation-freight-forward-list',
  templateUrl: './navigation-freight-forward-list.component.html',
  styleUrls: ['./navigation-freight-forward-list.component.scss']
})
export class NavigationFreightForwardListComponent implements OnInit, OnDestroy {
  @Input() accountNavigationActionItem: NavigationActionItem;
  @Input() dashboardAction: NavigationActionItem;
  @Input() adminAction: NavigationActionItem;
  @Input() referenceTableListAction: NavigationActionItem;
  @Input() referenceTableCreateAction: NavigationActionItem;
  @Input() referenceTableUploadAction: NavigationActionItem;
  @Input() importListAction: NavigationActionItem;
  @Input() importHistoryAction: NavigationActionItem;
  @Input() importUploadAction: NavigationActionItem;
  @Input() purchaseOrdersListAction: NavigationActionItem;
  @Input() containersListAction: NavigationActionItem;
  @Input() purchaseOrdersMappingRequiredAction: NavigationActionItem;
  @Input() purchaseOrdersReadyToSendAction: NavigationActionItem;
  @Input() newDataFlowAction: NavigationActionItem;
  @Input() allDataFlowsAction: NavigationActionItem;
  @Input() ftpConfigAction: NavigationActionItem;
  @Input() purchaseOrdersSentAction: NavigationActionItem;
  @Input() purchaseOrdersAcknowledgedAction: NavigationActionItem;
  @Input() inboxOutboxAction: NavigationActionItem;
  @Input() exportAction: NavigationActionItem;
  @Input() orgSelectAction: NavigationActionItem;
  @Input() freightForwardLocationsAction: NavigationActionItem;
  @Input() userTokenMsLevel = 0;

  selectionOpen = false;
  activeNavigationSub: Subscription;
  activeSelectionSub: Subscription;
  showTestingBanner: boolean = false;

  @HostBinding('class.expanded') navigationOpen = false;
  @HostListener('mouseenter')
  mouseEnter() {
    this.navigation.expandNavigation(undefined);
  }
  @HostListener('mouseleave')
  mouseLeave() {
    this.navigation.collapseNavigation();
  }

  constructor(private navigation: NavigationService, private router: Router) { }

  ngOnInit(): void {
    const currentUrl = window.location.href;
    this.showTestingBanner = !(currentUrl?.toLowerCase()?.includes('app.rapidmap.io'));
    this.activeNavigationSub = this.navigation.activeNavigation.subscribe(
      (action: NavigationActionItem) => {
        if (!action) {
          this.navigationOpen = false;
        } else {
          this.navigationOpen = true;
        }
      }
    );
    this.activeSelectionSub = this.navigation.activeSelection.subscribe(
      (action: NavigationActionItem) => {
        if (!action) {
          this.selectionOpen = false;
        } else {
          this.selectionOpen = true;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.activeNavigationSub.unsubscribe();
    this.activeSelectionSub.unsubscribe();
    this.navigation.collapseBoth();
  }

  goHome(): void {
    this.navigation.collapseBoth();
    this.router.navigate(['/'], { queryParams: { default: true } });
  }
}
