import { Component, OnInit, Input, HostBinding, OnDestroy } from '@angular/core';
import { Organization, Notification } from '@app/core/api';
import { Store, Select } from '@ngxs/store';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { cloneDeep } from 'lodash-es';
import * as moment from 'moment';
import { NotificationState } from '../../../stores/notification.state';
import { Observable, Subscription } from 'rxjs';
import { MarkAllNotificationsReadAction, RemoveAllNotificationsAction } from '../../../stores/notification.actions';
import { NavigationActionItem, NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit, OnDestroy {
  @Select(NotificationState.getNotifications) notifications$: Observable<Array<Notification>>;

  @HostBinding('class.show') show = false;

  @Input() action: NavigationActionItem;

  organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
  notifications: Array<Notification>;
  notificationsSub: Subscription;
  activeSelectionSub: Subscription;

  notificationDates: Array<any> = [];

  constructor(private store: Store, private navigation: NavigationService) { }

  ngOnInit(): void {
    this.activeSelectionSub = this.navigation.activeSelection.subscribe(
      (action: NavigationActionItem) => {
        if (!action || this.action.route !== action.route) {
          this.show = false;
        } else {
          this.show = true;
        }
      }
    );
    this.notificationsSub = this.notifications$.subscribe(
      (notifications: Array<Notification>) => {
        this.notifications = notifications;
        this.groupNotificationsByDate();
      }
    );
  }

  ngOnDestroy(): void {
    this.activeSelectionSub.unsubscribe();
    this.notificationsSub.unsubscribe();
  }

  markAllAsRead() {
    this.store.dispatch(new MarkAllNotificationsReadAction());
  }

  groupNotificationsByDate() {
    // make a local copy of notifications
    const notificationsClone = cloneDeep(this.notifications);
    const datesArray = [];

    // order by the most recent
    notificationsClone.sort((a, b) => (a.sentTime < b.sentTime) ? 1 : -1);

    // convert the unix epoch timestamp on each notification to a date
    notificationsClone.forEach((notification: Notification) => {
      const sentTime: number = moment(notification.sentTime).valueOf();
      const formattedDate = moment.unix(sentTime / 1000).format('MMMM Do YYYY');
      // check to see if date exists in the dates array
      const dateIndex = datesArray.findIndex(x => x.date === formattedDate);
      // if it exists, push the notification to date.notifications, else create a new date object with the current notification
      if (dateIndex !== -1) {
        datesArray[dateIndex].notifications.push(notification);
      } else {
        datesArray.push({
          date: formattedDate,
          notifications: [notification]
        });
      }
    });

    this.notificationDates = datesArray;
  }

  dismissAll() {
    this.store.dispatch(new RemoveAllNotificationsAction());
  }

}
