<form [formGroup]="filterForm" (ngSubmit)="addFilter()">
  <div class="filter-container" *ngIf="loadProductTypeQueryFiltersAction && !selectedProductType">
    <select (change)="fetchProductTypeProperties($event.target.value)" *ngIf="!areProductTypeSuggestionsLoading"
      [ngClass]="{'placeholder': !filterForm.value.type }" formControlName="type">
      <option value="" selected disabled>Select product type</option>
      <option *ngFor="let type of productTypeSuggestions" [value]="type.name">
        {{ type.name === 'Other' ? 'Core Fields' : type.name}}
      </option>
    </select>
    <div class="spinner" *ngIf="areProductTypeSuggestionsLoading">
      <app-spinner [smaller]="true"></app-spinner>
    </div>
  </div>
  <div class="filter-container" *ngIf="loadProductTypeQueryFiltersAction && selectedProductType">
    <div class="product-type">
      <div class="selected-type">
        {{ selectedProductType === 'Other' ? 'Core Fields' : selectedProductType }}
      </div>
      <div class="flex-fill"></div>
      <app-simple-button icon="times" (click)="resetProductType()">Reset</app-simple-button>
    </div>
  </div>
  <div class="filter-container" *ngIf="filters.length >= 1">
    <select [ngClass]="{'placeholder': !filterForm.value.join }" formControlName="join">
      <option value="" selected disabled>Select filter conjunction</option>
      <option *ngFor="let option of currentFilterJoinOptions" [value]="option">
        {{ option | beautifyUpperCase }}
      </option>
    </select>
  </div>
  <div class="filter-container">
    <select (change)="onSelectedPropertyChange($event.target.value)" *ngIf="!areQueryFilterPropertiesLoading"
      [ngClass]="{'placeholder': !filterForm.value.by }" formControlName="by"
      [attr.disabled]="loadProductTypeQueryFiltersAction && (!areQueryFilterPropertiesFetched && !selectedProductType) ? '' : null">
      <option value="" selected disabled>Select filter property</option>
      <ng-container *ngFor="let property of queryFilters">
        <option *ngIf="!property.hidden" [value]="property.propertyName">
          {{ property.displayName }}
        </option>
      </ng-container>
    </select>
    <div class="spinner" *ngIf="areQueryFilterPropertiesLoading">
      <app-spinner [smaller]="true"></app-spinner>
    </div>
  </div>
  <div class="filter-container" *ngIf="!activeQueryFilter?.multiSelectionFilterMatch">
    <select [ngClass]="{'placeholder': !filterForm.value.match }" formControlName="match">
      <option value="" selected disabled>Select filter operator</option>
      <ng-container *ngFor="let option of currentFilterMatchOptions">
        <option [value]="option.filterMatch">
          {{ option.displayName }}
        </option>
      </ng-container>
    </select>
  </div>
  <div class="filter-container"
    *ngIf="filterForm.value.match !== FilterMatch.ISNULL && filterForm.value.match !== FilterMatch.ISNOTNULL">
    <ng-container *ngIf="activeInputType === InputType.Boolean; else input">
      <select formControlName="value">
        <option [ngValue]="true">Yes</option>
        <option [ngValue]="false">No</option>
      </select>
    </ng-container>
    <ng-template #input>
      <input
        *ngIf="!activeQueryFilter?.suggestion && activeInputType !== InputType.Datetime && activeInputType !== InputType.Date"
        [type]="activeInputType" placeholder="Enter filter value" formControlName="value">

      <!-- Datetime -->
      <app-date-time *ngIf="activeInputType === InputType.Datetime" placeholder="Enter filter value"
        formControlName="value"></app-date-time>
      <!-- Date -->
      <app-date *ngIf="activeInputType === InputType.Date" placeholder="Enter filter value" formControlName="value">
      </app-date>

      <app-suggestion
        *ngIf="activeQueryFilter?.suggestion && suggestions[activeQueryFilter.suggestion]; else notProvided"
        [provided]="suggestions[activeQueryFilter.suggestion]" label="Choose filter value"
        [multiSelection]="activeQueryFilter.multiSelection" [showList]="true" [form]="filterForm" field="value"
        [optionProp]="activeQueryFilter.suggestionProperty ? activeQueryFilter.suggestionProperty : 'name'"
        [removeBorder]="true">
      </app-suggestion>
      <ng-template #notProvided>
        <app-suggestion *ngIf="activeQueryFilter?.suggestion" [include]="activeQueryFilter.suggestion"
          label="Choose filter value" [multiSelection]="activeQueryFilter.multiSelection" [showList]="true"
          [form]="filterForm" field="value"
          [optionProp]="activeQueryFilter.suggestionProperty ? activeQueryFilter.suggestionProperty : 'name'"
          [removeBorder]="true">
        </app-suggestion>
      </ng-template>
    </ng-template>
  </div>
</form>
