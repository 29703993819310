/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UrlReference } from './urlReference';
import { CatalogReference } from './catalogReference';
import { WorkflowTask } from './workflowTask';
import { OrganizationReference } from './organizationReference';


export interface Workflow { 
    catalogs?: Array<CatalogReference>;
    description?: string;
    disabledTasks?: Array<WorkflowTask>;
    enabledTasks?: Array<WorkflowTask>;
    externalRef?: Array<UrlReference>;
    id?: number;
    inLine?: boolean;
    jobType?: string;
    lastRuntime?: string;
    name?: string;
    nextTriggerTime?: string;
    orgRef?: OrganizationReference;
    productType?: string;
    status?: string;
    subWorkflow?: boolean;
}

