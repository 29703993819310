/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { FilterDefinition } from '../model/models';
import { FilterJoin } from '../model/models';
import { FilterMatch } from '../model/models';
import { OrderBy } from '../model/models';
import { View } from '../model/models';
import { ViewField } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ViewApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Add a Reference Table
     * @param orgId An Organization ID number used to indicate the current org context
     * @param view A new View to add
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addReferenceTable(orgId: number, view: View, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<View>;
    public addReferenceTable(orgId: number, view: View, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<View>>;
    public addReferenceTable(orgId: number, view: View, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<View>>;
    public addReferenceTable(orgId: number, view: View, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling addReferenceTable.');
        }
        if (view === null || view === undefined) {
            throw new Error('Required parameter view was null or undefined when calling addReferenceTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<View>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/reference/add`,
            view,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a View
     * @param orgId An Organization ID number used to indicate the current org context
     * @param view A new View to add
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addView(orgId: number, view: View, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<View>;
    public addView(orgId: number, view: View, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<View>>;
    public addView(orgId: number, view: View, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<View>>;
    public addView(orgId: number, view: View, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling addView.');
        }
        if (view === null || view === undefined) {
            throw new Error('Required parameter view was null or undefined when calling addView.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<View>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view`,
            view,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a View
     * @param orgId An Organization ID number used to indicate the current org context
     * @param view A new View to add
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addViewMetadata(orgId: number, view: View, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<View>;
    public addViewMetadata(orgId: number, view: View, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<View>>;
    public addViewMetadata(orgId: number, view: View, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<View>>;
    public addViewMetadata(orgId: number, view: View, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling addViewMetadata.');
        }
        if (view === null || view === undefined) {
            throw new Error('Required parameter view was null or undefined when calling addViewMetadata.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<View>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/metadata`,
            view,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a View
     * @param viewId 
     * @param orgId An Organization ID number used to indicate the current org context
     * @param viewField A new View Field to add
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addViewMetadataField(viewId: number, orgId: number, viewField: ViewField, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ViewField>;
    public addViewMetadataField(viewId: number, orgId: number, viewField: ViewField, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ViewField>>;
    public addViewMetadataField(viewId: number, orgId: number, viewField: ViewField, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ViewField>>;
    public addViewMetadataField(viewId: number, orgId: number, viewField: ViewField, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (viewId === null || viewId === undefined) {
            throw new Error('Required parameter viewId was null or undefined when calling addViewMetadataField.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling addViewMetadataField.');
        }
        if (viewField === null || viewField === undefined) {
            throw new Error('Required parameter viewField was null or undefined when calling addViewMetadataField.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ViewField>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/${encodeURIComponent(String(viewId))}/metadata/field`,
            viewField,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export a View by ID for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param viewId View ID number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportViewById(orgId: number, viewId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<Blob>;
    public exportViewById(orgId: number, viewId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<HttpResponse<Blob>>;
    public exportViewById(orgId: number, viewId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<HttpEvent<Blob>>;
    public exportViewById(orgId: number, viewId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling exportViewById.');
        }
        if (viewId === null || viewId === undefined) {
            throw new Error('Required parameter viewId was null or undefined when calling exportViewById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/${encodeURIComponent(String(viewId))}/export`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Views
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterDefinition A filter to filter templates by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllViews(orgId: number, filterDefinition: FilterDefinition, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<View>>;
    public getAllViews(orgId: number, filterDefinition: FilterDefinition, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<View>>>;
    public getAllViews(orgId: number, filterDefinition: FilterDefinition, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<View>>>;
    public getAllViews(orgId: number, filterDefinition: FilterDefinition, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getAllViews.');
        }
        if (filterDefinition === null || filterDefinition === undefined) {
            throw new Error('Required parameter filterDefinition was null or undefined when calling getAllViews.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<View>>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/all`,
            filterDefinition,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a User View by Name for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param viewName 
     * @param sortBy Property to sort by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserViewByName(orgId: number, viewName: string, sortBy?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<View>;
    public getUserViewByName(orgId: number, viewName: string, sortBy?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<View>>;
    public getUserViewByName(orgId: number, viewName: string, sortBy?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<View>>;
    public getUserViewByName(orgId: number, viewName: string, sortBy?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getUserViewByName.');
        }
        if (viewName === null || viewName === undefined) {
            throw new Error('Required parameter viewName was null or undefined when calling getUserViewByName.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (sortBy) {
            sortBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sortBy');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<View>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/user/name/${encodeURIComponent(String(viewName))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a View by ID for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param viewId View ID number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getViewById(orgId: number, viewId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<View>;
    public getViewById(orgId: number, viewId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<View>>;
    public getViewById(orgId: number, viewId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<View>>;
    public getViewById(orgId: number, viewId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getViewById.');
        }
        if (viewId === null || viewId === undefined) {
            throw new Error('Required parameter viewId was null or undefined when calling getViewById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<View>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/${encodeURIComponent(String(viewId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a View by Name for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param viewName 
     * @param sortBy Property to sort by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getViewByName(orgId: number, viewName: string, sortBy?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<View>;
    public getViewByName(orgId: number, viewName: string, sortBy?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<View>>;
    public getViewByName(orgId: number, viewName: string, sortBy?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<View>>;
    public getViewByName(orgId: number, viewName: string, sortBy?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getViewByName.');
        }
        if (viewName === null || viewName === undefined) {
            throw new Error('Required parameter viewName was null or undefined when calling getViewByName.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (sortBy) {
            sortBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sortBy');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<View>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/name/${encodeURIComponent(String(viewName))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Views for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterBy Property in data to filter by, e.g. &#x60;description&#x60; or &#x60;owner.firstName&#x60;
     * @param filterJoin Operator to match filterBy to filter value
     * @param filterMatch Operator to match filterBy to filter value
     * @param filterValue Value to match with in the filterBy
     * @param limit Length of the page
     * @param offset Start of the page
     * @param orderBy Order to sort by, ascending or descending
     * @param sortBy Property to sort by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getViews(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<View>>;
    public getViews(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<View>>>;
    public getViews(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<View>>>;
    public getViews(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getViews.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterBy) {
            filterBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterBy');
            })
        }
        if (filterJoin) {
            filterJoin.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterJoin');
            })
        }
        if (filterMatch) {
            filterMatch.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterMatch');
            })
        }
        if (filterValue) {
            filterValue.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterValue');
            })
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy');
            })
        }
        if (sortBy) {
            sortBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sortBy');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<View>>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Views for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterBy Property in data to filter by, e.g. &#x60;description&#x60; or &#x60;owner.firstName&#x60;
     * @param filterJoin Operator to match filterBy to filter value
     * @param filterMatch Operator to match filterBy to filter value
     * @param filterValue Value to match with in the filterBy
     * @param limit Length of the page
     * @param offset Start of the page
     * @param orderBy Order to sort by, ascending or descending
     * @param sortBy Property to sort by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getViewsMetadata(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<View>>;
    public getViewsMetadata(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<View>>>;
    public getViewsMetadata(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<View>>>;
    public getViewsMetadata(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getViewsMetadata.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterBy) {
            filterBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterBy');
            })
        }
        if (filterJoin) {
            filterJoin.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterJoin');
            })
        }
        if (filterMatch) {
            filterMatch.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterMatch');
            })
        }
        if (filterValue) {
            filterValue.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterValue');
            })
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy');
            })
        }
        if (sortBy) {
            sortBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sortBy');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<View>>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/metadata`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the Matrix for a View and Destination
     * This endpoint will update the matrix for a view and destination
     * @param destinationId A destination ID number, a.k.a Organization Id
     * @param orgId An Organization ID number used to indicate the current org context
     * @param viewName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchMatrixViewDestination(destinationId: number, orgId: number, viewName: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<View>;
    public patchMatrixViewDestination(destinationId: number, orgId: number, viewName: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<View>>;
    public patchMatrixViewDestination(destinationId: number, orgId: number, viewName: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<View>>;
    public patchMatrixViewDestination(destinationId: number, orgId: number, viewName: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (destinationId === null || destinationId === undefined) {
            throw new Error('Required parameter destinationId was null or undefined when calling patchMatrixViewDestination.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling patchMatrixViewDestination.');
        }
        if (viewName === null || viewName === undefined) {
            throw new Error('Required parameter viewName was null or undefined when calling patchMatrixViewDestination.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<View>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/matrix/${encodeURIComponent(String(viewName))}/destination/${encodeURIComponent(String(destinationId))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Overwrite an View by ID
     * This endpoint will replace a View
     * @param orgId 
     * @param viewId 
     * @param view 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchReferenceViewById(orgId: number, viewId: number, view?: View, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<View>;
    public patchReferenceViewById(orgId: number, viewId: number, view?: View, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<View>>;
    public patchReferenceViewById(orgId: number, viewId: number, view?: View, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<View>>;
    public patchReferenceViewById(orgId: number, viewId: number, view?: View, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling patchReferenceViewById.');
        }
        if (viewId === null || viewId === undefined) {
            throw new Error('Required parameter viewId was null or undefined when calling patchReferenceViewById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<View>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/reference/view/${encodeURIComponent(String(viewId))}`,
            view,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Overwrite an View by ID
     * This endpoint will replace a View
     * @param orgId 
     * @param viewId 
     * @param view 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchUserViewById(orgId: number, viewId: number, view?: View, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<View>;
    public patchUserViewById(orgId: number, viewId: number, view?: View, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<View>>;
    public patchUserViewById(orgId: number, viewId: number, view?: View, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<View>>;
    public patchUserViewById(orgId: number, viewId: number, view?: View, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling patchUserViewById.');
        }
        if (viewId === null || viewId === undefined) {
            throw new Error('Required parameter viewId was null or undefined when calling patchUserViewById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<View>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/user/view/${encodeURIComponent(String(viewId))}`,
            view,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Overwrite an View by ID
     * This endpoint will replace a View
     * @param orgId An Organization ID number used to indicate the current org context
     * @param viewId View ID number
     * @param view 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchViewById(orgId: number, viewId: number, view?: View, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<View>;
    public patchViewById(orgId: number, viewId: number, view?: View, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<View>>;
    public patchViewById(orgId: number, viewId: number, view?: View, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<View>>;
    public patchViewById(orgId: number, viewId: number, view?: View, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling patchViewById.');
        }
        if (viewId === null || viewId === undefined) {
            throw new Error('Required parameter viewId was null or undefined when calling patchViewById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<View>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/${encodeURIComponent(String(viewId))}`,
            view,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Overwrite an View by ID
     * This endpoint will replace a View
     * @param orgId An Organization ID number used to indicate the current org context
     * @param viewId View ID number
     * @param view 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchViewMetadataById(orgId: number, viewId: number, view?: View, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<View>;
    public patchViewMetadataById(orgId: number, viewId: number, view?: View, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<View>>;
    public patchViewMetadataById(orgId: number, viewId: number, view?: View, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<View>>;
    public patchViewMetadataById(orgId: number, viewId: number, view?: View, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling patchViewMetadataById.');
        }
        if (viewId === null || viewId === undefined) {
            throw new Error('Required parameter viewId was null or undefined when calling patchViewMetadataById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<View>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/${encodeURIComponent(String(viewId))}/metadata`,
            view,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Overwrite an View Field by ID
     * This endpoint will replace a View Field
     * @param viewFieldId 
     * @param viewId 
     * @param orgId An Organization ID number used to indicate the current org context
     * @param viewField 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchViewMetadataFieldById(viewFieldId: number, viewId: number, orgId: number, viewField?: ViewField, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ViewField>;
    public patchViewMetadataFieldById(viewFieldId: number, viewId: number, orgId: number, viewField?: ViewField, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ViewField>>;
    public patchViewMetadataFieldById(viewFieldId: number, viewId: number, orgId: number, viewField?: ViewField, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ViewField>>;
    public patchViewMetadataFieldById(viewFieldId: number, viewId: number, orgId: number, viewField?: ViewField, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (viewFieldId === null || viewFieldId === undefined) {
            throw new Error('Required parameter viewFieldId was null or undefined when calling patchViewMetadataFieldById.');
        }
        if (viewId === null || viewId === undefined) {
            throw new Error('Required parameter viewId was null or undefined when calling patchViewMetadataFieldById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling patchViewMetadataFieldById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<ViewField>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/${encodeURIComponent(String(viewId))}/metadata/field/${encodeURIComponent(String(viewFieldId))}`,
            viewField,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Overwrite an View by ID
     * This endpoint will replace a View
     * @param fieldName 
     * @param templateName 
     * @param destinationId A destination ID number, a.k.a Organization Id
     * @param orgId An Organization ID number used to indicate the current org context
     * @param viewId View ID number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchViewMetadataTemplate(fieldName: string, templateName: string, destinationId: number, orgId: number, viewId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<View>;
    public patchViewMetadataTemplate(fieldName: string, templateName: string, destinationId: number, orgId: number, viewId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<View>>;
    public patchViewMetadataTemplate(fieldName: string, templateName: string, destinationId: number, orgId: number, viewId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<View>>;
    public patchViewMetadataTemplate(fieldName: string, templateName: string, destinationId: number, orgId: number, viewId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (fieldName === null || fieldName === undefined) {
            throw new Error('Required parameter fieldName was null or undefined when calling patchViewMetadataTemplate.');
        }
        if (templateName === null || templateName === undefined) {
            throw new Error('Required parameter templateName was null or undefined when calling patchViewMetadataTemplate.');
        }
        if (destinationId === null || destinationId === undefined) {
            throw new Error('Required parameter destinationId was null or undefined when calling patchViewMetadataTemplate.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling patchViewMetadataTemplate.');
        }
        if (viewId === null || viewId === undefined) {
            throw new Error('Required parameter viewId was null or undefined when calling patchViewMetadataTemplate.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<View>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/${encodeURIComponent(String(viewId))}/metadata/${encodeURIComponent(String(destinationId))}/template/${encodeURIComponent(String(templateName))}/${encodeURIComponent(String(fieldName))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Overwrite an View by ID
     * This endpoint will replace a View
     * @param orgId An Organization ID number used to indicate the current org context
     * @param viewId View ID number
     * @param view 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putViewById(orgId: number, viewId: number, view?: View, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<View>;
    public putViewById(orgId: number, viewId: number, view?: View, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<View>>;
    public putViewById(orgId: number, viewId: number, view?: View, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<View>>;
    public putViewById(orgId: number, viewId: number, view?: View, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling putViewById.');
        }
        if (viewId === null || viewId === undefined) {
            throw new Error('Required parameter viewId was null or undefined when calling putViewById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<View>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/${encodeURIComponent(String(viewId))}`,
            view,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Overwrite an View by ID
     * This endpoint will replace a View
     * @param orgId An Organization ID number used to indicate the current org context
     * @param viewId View ID number
     * @param view 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putViewMetadataById(orgId: number, viewId: number, view?: View, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<View>;
    public putViewMetadataById(orgId: number, viewId: number, view?: View, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<View>>;
    public putViewMetadataById(orgId: number, viewId: number, view?: View, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<View>>;
    public putViewMetadataById(orgId: number, viewId: number, view?: View, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling putViewMetadataById.');
        }
        if (viewId === null || viewId === undefined) {
            throw new Error('Required parameter viewId was null or undefined when calling putViewMetadataById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<View>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/${encodeURIComponent(String(viewId))}/metadata`,
            view,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a View by ID from an Organization
     * This endpoint will remove a View
     * @param orgId An Organization ID number used to indicate the current org context
     * @param viewId View ID number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeView(orgId: number, viewId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public removeView(orgId: number, viewId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public removeView(orgId: number, viewId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public removeView(orgId: number, viewId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling removeView.');
        }
        if (viewId === null || viewId === undefined) {
            throw new Error('Required parameter viewId was null or undefined when calling removeView.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/${encodeURIComponent(String(viewId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a View by ID from an Organization
     * This endpoint will remove a View
     * @param orgId An Organization ID number used to indicate the current org context
     * @param viewId View ID number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeViewMetadata(orgId: number, viewId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public removeViewMetadata(orgId: number, viewId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public removeViewMetadata(orgId: number, viewId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public removeViewMetadata(orgId: number, viewId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling removeViewMetadata.');
        }
        if (viewId === null || viewId === undefined) {
            throw new Error('Required parameter viewId was null or undefined when calling removeViewMetadata.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/${encodeURIComponent(String(viewId))}/metadata`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a View by ID from an Organization
     * This endpoint will remove a View
     * @param viewFieldId 
     * @param viewId 
     * @param orgId An Organization ID number used to indicate the current org context
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeViewMetadataField(viewFieldId: number, viewId: number, orgId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public removeViewMetadataField(viewFieldId: number, viewId: number, orgId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public removeViewMetadataField(viewFieldId: number, viewId: number, orgId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public removeViewMetadataField(viewFieldId: number, viewId: number, orgId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (viewFieldId === null || viewFieldId === undefined) {
            throw new Error('Required parameter viewFieldId was null or undefined when calling removeViewMetadataField.');
        }
        if (viewId === null || viewId === undefined) {
            throw new Error('Required parameter viewId was null or undefined when calling removeViewMetadataField.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling removeViewMetadataField.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/view/${encodeURIComponent(String(viewId))}/metadata/field/${encodeURIComponent(String(viewFieldId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
