import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  FilterJoin,
  FilterMatch,
  Organization,
  OrgApiService,
  Suggestion,
} from '@app/core';
import {
  FilterMatchOption,
  InputType,
  QueryFilter,
  QueryFilterConfiguration,
  QueryFilterService,
  QueryFilterType,
  SearchFilter,
} from '../../../services/query-filter.service';
import * as moment from 'moment';
import { Store } from '@ngxs/store';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';

@Component({
  selector: 'app-query-filter-form',
  templateUrl: './query-filter-form.component.html',
  styleUrls: ['./query-filter-form.component.scss'],
})
export class QueryFilterFormComponent implements OnInit, OnDestroy {
  @Input() suggestions: { [key: string]: Array<Suggestion> } = {};
  @Input() queryFilters: Array<QueryFilter> = [];

  @Input() filters: Array<SearchFilter> = [];
  @Output() filtersChange: EventEmitter<Array<SearchFilter>> =
    new EventEmitter();

  @Input() loadProductTypeQueryFiltersAction: ({
    viewName,
  }: {
    viewName: string;
  }) => void;

  @Input() allowOrJoin: boolean = true;
  @Input() allowDateRange: boolean = true;

  productTypeSuggestions: Array<Suggestion> = [];
  areQueryFilterPropertiesLoading = false;
  areProductTypeSuggestionsLoading = false;
  areQueryFilterPropertiesFetched = false;
  filterForm: UntypedFormGroup = new UntypedFormGroup({
    type: new UntypedFormControl(''),
    by: new UntypedFormControl('', [Validators.required]),
    join: new UntypedFormControl(FilterJoin.AND, [Validators.required]),
    match: new UntypedFormControl('', [this.filterMatchValueValidator()]),
    value: new UntypedFormControl('', [Validators.required]),
  });
  currentFilterMatchOptions: Array<FilterMatchOption> = [];
  currentFilterJoinOptions: Array<string> = Object.keys(FilterJoin);
  currentFilterOptions: Array<string> = Object.keys(FilterMatch);
  activeInputType: InputType;
  activeQueryFilter: QueryFilter;
  selectedProductType: string;

  FilterMatch = FilterMatch;
  InputType = InputType;
  QueryFilterType = QueryFilterType;

  routerSub: Subscription;

  constructor(
    private queryFilterSvc: QueryFilterService,
    private orgApi: OrgApiService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.routerSub = this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => this.route.snapshot)
      )
      .subscribe(({ queryParams }: Params) =>
        this.setSelectedProductType(queryParams)
      );

    this.resetFilterMatchOptions();

    if (this.loadProductTypeQueryFiltersAction) {
      this.fetchProductTypeSuggestions();
    }
    const { queryParams } = this.route.snapshot;
    this.setSelectedProductType(queryParams);

    if (!this.allowOrJoin) {
      this.currentFilterJoinOptions = [...this.currentFilterJoinOptions].filter(
        (j) => j !== FilterJoin.OR
      );
    }
    if (!this.allowDateRange) {
      this.currentFilterMatchOptions = [
        ...this.currentFilterMatchOptions,
      ].filter((m) => m.filterMatch !== FilterMatch.ISBETWEEN);
    }
  }

  ngOnDestroy(): void {
    this.routerSub.unsubscribe;
  }

  private setSelectedProductType(queryParams: Params): void {
    if (queryParams?.type) {
      this.selectedProductType = queryParams.type;
      if (!this.areQueryFilterPropertiesFetched) {
        this.fetchProductTypeProperties(this.selectedProductType);
      }
    } else {
      this.selectedProductType = undefined;
    }
  }

  getQueryFilter(propertyName: string): QueryFilter {
    return this.queryFilters?.find((qf) => qf.propertyName === propertyName);
  }

  private resetFilterMatchOptions(): void {
    this.currentFilterMatchOptions = Object.keys(FilterMatch).map(
      (filterMatch: FilterMatch) =>
        this.queryFilterSvc.getFilterMatchOption(filterMatch)
    );
  }

  private filterMatchValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.filterForm) {
        if (
          control.value === FilterMatch.ISNULL ||
          control.value === FilterMatch.ISNOTNULL
        ) {
          this.filterForm.get('value').reset();
          this.filterForm.get('value').setErrors(null);
        } else if (!this.filterForm.get('value').value) {
          this.filterForm.get('value').setErrors({ required: true });
        }
      }
      if (!control.value && !this.activeQueryFilter?.suggestion) {
        return { required: true };
      }
      return null;
    };
  }

  onSelectedPropertyChange(selectedFilterBy: string): void {
    // Clear Match and Value form fields
    this.filterForm.patchValue({ match: '', value: '' });
    // Set list of Filter Options, all FilterMatch enums for now
    this.resetFilterMatchOptions();
    // Set form Value input type based on field metadata
    const filter = this.getQueryFilter(selectedFilterBy);
    this.activeQueryFilter = this.getQueryFilter(selectedFilterBy);
    if (filter.type === QueryFilterType.Date) {
      this.activeInputType = InputType.Date;
      this.currentFilterMatchOptions = [
        {
          displayName: 'Is',
          filterMatch: FilterMatch.IS,
        },
        {
          displayName: 'Is After',
          filterMatch: FilterMatch.ISAFTER,
        },
        {
          displayName: 'Is Before',
          filterMatch: FilterMatch.ISBEFORE,
        },
        {
          displayName: 'Is Not Null',
          filterMatch: FilterMatch.ISNOTNULL,
        },
        {
          displayName: 'Is Null',
          filterMatch: FilterMatch.ISNULL,
        },
      ];
    } else if (filter.type === QueryFilterType.Datetime) {
      this.activeInputType = InputType.Datetime;
      this.currentFilterMatchOptions = [
        {
          displayName: 'Is',
          filterMatch: FilterMatch.IS,
        },
        {
          displayName: 'Is After',
          filterMatch: FilterMatch.ISAFTER,
        },
        {
          displayName: 'Is Before',
          filterMatch: FilterMatch.ISBEFORE,
        },
        {
          displayName: 'Is Not Null',
          filterMatch: FilterMatch.ISNOTNULL,
        },
        {
          displayName: 'Is Null',
          filterMatch: FilterMatch.ISNULL,
        },
      ];
    } else if (filter.type === QueryFilterType.Time) {
      this.activeInputType = InputType.Time;
    } else if (filter.type === QueryFilterType.Integer) {
      this.activeInputType = InputType.Number;
    } else if (filter.type === QueryFilterType.Boolean) {
      this.activeInputType = InputType.Boolean;
      this.currentFilterMatchOptions = [
        {
          displayName: 'Is',
          filterMatch: FilterMatch.IS,
        },
      ];
      this.filterForm.patchValue({ match: FilterMatch.IS, value: true });
    } else {
      this.activeInputType = InputType.Text;
      this.currentFilterMatchOptions = [
        {
          displayName: 'Is',
          filterMatch: FilterMatch.IS,
        },
        {
          displayName: 'Is Not',
          filterMatch: FilterMatch.ISNOT,
        },
        {
          displayName: 'Is Not Null',
          filterMatch: FilterMatch.ISNOTNULL,
        },
        {
          displayName: 'Is Null',
          filterMatch: FilterMatch.ISNULL,
        },
        {
          displayName: 'Contains',
          filterMatch: FilterMatch.CONTAINS,
        },
        {
          displayName: 'In List',
          filterMatch: FilterMatch.INLIST,
        },
        {
          displayName: 'Is Not In List',
          filterMatch: FilterMatch.NOTINLIST,
        },
        {
          displayName: 'Starts With',
          filterMatch: FilterMatch.STARTSWITH,
        },
        {
          displayName: 'Does Not Start With',
          filterMatch: FilterMatch.DOESNOTSTARTSWITH,
        },
        {
          displayName: 'Ends With',
          filterMatch: FilterMatch.ENDSWITH,
        },
        {
          displayName: 'Is After',
          filterMatch: FilterMatch.ISAFTER,
        },
        {
          displayName: 'Is Before',
          filterMatch: FilterMatch.ISBEFORE,
        },
        {
          displayName: 'Is Less Than',
          filterMatch: FilterMatch.ISLESSTHAN,
        },
        {
          displayName: 'Is Less Than Equal',
          filterMatch: FilterMatch.ISLESSTHANEQUAL,
        },
        {
          displayName: 'Is More Than',
          filterMatch: FilterMatch.ISMORETHAN,
        },
        {
          displayName: 'Is More Than Equal',
          filterMatch: FilterMatch.ISMORETHANEQUAL,
        },
      ];
    }
    this.filterForm.get('match').updateValueAndValidity();
  }

  addFilter(): void {
    if (this.filterForm.invalid) {
      return;
    }
    const form = this.filterForm.value;
    const filter = this.getQueryFilter(form.by);

    let value: string;
    if (
      filter.type === QueryFilterType.Date ||
      filter.type === QueryFilterType.Datetime ||
      filter.type === QueryFilterType.Time
    ) {
      value = moment(form.value).valueOf().toString();
    } else {
      value = form.value;
    }

    const suggestionIndex = this.suggestions[filter.suggestion]?.findIndex(
      (s) => s.name === value
    );
    if (filter.filters?.length > 0 && suggestionIndex && suggestionIndex > -1) {
      const filters: Array<SearchFilter> = filter.filters[suggestionIndex].map(
        ({ by, join, match, value }: QueryFilterConfiguration) => {
          return { by, join, match, value };
        }
      );
      this.filters = [...this.filters, ...filters];
    } else if (
      filter.type === QueryFilterType.Suggestion &&
      filter.type === QueryFilterType.MultiSuggestion
    ) {
      form.value.split(', ').forEach((value: string, index: number) => {
        this.filters = [
          ...this.filters,
          {
            by: filter.propertyName,
            join: index === 0 ? FilterJoin.AND : FilterJoin.OR,
            match: filter.multiSelectionFilterMatch
              ? filter.multiSelectionFilterMatch
              : form.match,
            value,
          },
        ];
      });
    } else {
      this.filters = [
        ...this.filters,
        {
          by: filter.propertyName,
          join: FilterJoin[form.join],
          match: form.match ? FilterMatch[form.match] : FilterMatch.IS,
          value,
        },
      ];
    }
    this.filtersChange.emit(this.filters);

    this.resetFilterForm();
  }

  resetFilterForm(): void {
    this.activeInputType = null;
    this.activeQueryFilter = null;
    this.filterForm.setValue({
      type: '',
      by: '',
      join: FilterJoin.AND,
      match: '',
      value: '',
    });
    this.areQueryFilterPropertiesFetched = false;
  }

  private fetchProductTypeSuggestions(): void {
    this.areProductTypeSuggestionsLoading = true;
    const organization: Organization = this.store.selectSnapshot(
      OrganizationState.getOrganization
    );
    this.orgApi
      .getSuggestions(['Product Types'], organization.id)
      .pipe(
        tap((suggestions: { [key: string]: Array<Suggestion> }) => {
          this.productTypeSuggestions = suggestions['Product Types'];
          this.areProductTypeSuggestionsLoading = false;
        }),
        catchError((error: HttpErrorResponse) => {
          this.areProductTypeSuggestionsLoading = false;
          return of(error);
        })
      )
      .subscribe();
  }

  fetchProductTypeProperties(viewName: string): void {
    this.selectedProductType = viewName;
    this.filterForm.patchValue({ by: '' });
    this.areQueryFilterPropertiesLoading = true;
    this.areQueryFilterPropertiesFetched = false;
    this.store
      .dispatch(new this.loadProductTypeQueryFiltersAction({ viewName }))
      .subscribe(
        () => {
          if(viewName) {
            const filter: SearchFilter = {by: "productType", join: "AND", match: "IS", value: viewName}
            this.filters = [...this.filters, filter];
            this.filtersChange.emit(this.filters)
          }
          
          this.areQueryFilterPropertiesLoading = false;
          this.areQueryFilterPropertiesFetched = true;
          this.cd.markForCheck();

        },
        () => {
          this.areQueryFilterPropertiesLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  resetProductType(): void {
    this.selectedProductType = undefined;
    this.resetFilterForm();
    this.filtersChange.emit([]);
  }
}
