import { Component, Host, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-body',
  templateUrl: './modal-body.component.html',
  styleUrls: ['./modal-body.component.scss']
})
export class ModalBodyComponent implements OnInit {
  @HostBinding('class.center') @Input() center = false;
  @HostBinding('class.overflow') @Input() overflow = true;

  constructor() { }

  ngOnInit(): void {
  }

}
