/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UrlReference } from './urlReference';
import { Comment } from './comment';
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';
import { Contact } from './contact';


export interface Contract { 
    cancelDate?: string;
    commentList?: Array<Comment>;
    contactList?: Array<Contact>;
    createdDate?: ModifiedDate;
    distributionType?: string;
    docUrls?: Array<UrlReference>;
    effectiveDate?: string;
    id?: number;
    lastUpdated?: ModifiedDate;
    newComment?: Comment;
    orgRef?: OrganizationReference;
    orgRefRelated?: OrganizationReference;
    rankingBrand?: string;
    rankingRetailer?: string;
    relationType?: string;
    supplierId?: number;
}

