/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserReference } from './userReference';
import { OrganizationReference } from './organizationReference';
import { WorkTaskType } from './workTaskType';


export interface WorkTask { 
    actions?: Array<any>;
    catagory?: string;
    dateStatus?: string;
    description?: string;
    eventDate?: string;
    eventType?: string;
    id?: number;
    orgRef?: OrganizationReference;
    priority?: number;
    status?: string;
    subCatagory?: string;
    title?: string;
    type?: WorkTaskType;
    userRef?: UserReference;
    userRefStatus?: UserReference;
}

