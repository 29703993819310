/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PluginTypes } from './pluginTypes';


export interface PluginSummary { 
    description?: string;
    displayName?: string;
    helpText?: string;
    helpUrl?: string;
    isConfigured?: boolean;
    name?: string;
    types?: Array<PluginTypes>;
    configured?: boolean;
}

