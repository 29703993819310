/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DrayCarrierAppointment } from './drayCarrierAppointment';
import { DrayCarrierEventDetails } from './drayCarrierEventDetails';


export interface DrayCarrierEvent { 
    appointment?: DrayCarrierAppointment;
    billOfLadingNumber?: string;
    bookingNumber?: string;
    eventDetails?: DrayCarrierEventDetails;
    houseBill?: string;
    importReferenceNumber?: string;
    masterBill?: string;
    orderReference?: string;
    originatorCode?: string;
    receiverCode?: string;
    shipmentReferenceNumber?: string;
    source?: string;
    stopName?: string;
    stopNumber?: string;
    stopType?: string;
    vendorOrderNumber?: string;
    workOrderNumber?: string;
}

