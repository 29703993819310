/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PublicLink } from './publicLink';
import { Owner } from './owner';
import { Filter } from './filter';
import { CatalogOrigin } from './catalogOrigin';
import { TPSpecs } from './tPSpecs';
import { ModifiedDate } from './modifiedDate';
import { CatalogExport } from './catalogExport';
import { OrganizationReference } from './organizationReference';
import { Template } from './template';


export interface Catalog { 
    cancelDate?: string;
    catalogExport?: CatalogExport;
    catalogType?: string;
    createdDate?: ModifiedDate;
    deliveredBy?: string;
    deliveryDate?: string;
    deliveryFinal?: boolean;
    deliverySource?: string;
    description?: string;
    destinations?: Array<OrganizationReference>;
    effectiveDate?: string;
    errorCount?: number;
    id?: number;
    image?: string;
    lastUpdated?: ModifiedDate;
    liveVerifyDate?: string;
    loaderOrganization?: OrganizationReference;
    name?: string;
    origin?: CatalogOrigin;
    owner?: Owner;
    parentId?: number;
    passCount?: number;
    publicLinks?: Array<PublicLink>;
    season?: string;
    seasonYear?: number;
    smartCollection?: boolean;
    smartFilter?: Filter;
    template?: Template;
    templateName?: string;
    totalProducts?: number;
    totalSkus?: number;
    totalSkusInventory?: number;
    totalSkusLive?: number;
    tpSpecs?: TPSpecs;
    uploadType?: string;
    uploadUserEmail?: string;
    warnCount?: number;
}

