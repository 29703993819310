/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationReference } from './organizationReference';
import { Location } from './location';
import { WorkflowStatus } from './workflowStatus';


export interface LegSearch { 
    acceptedDate?: string;
    assignDate?: string;
    assignedBy?: string;
    availableDate?: string;
    bilOfLading?: string;
    bobTail?: boolean;
    bridgeToll?: boolean;
    broker?: string;
    category?: string;
    chassisCost?: boolean;
    chassisNumber?: string;
    chassisSplit?: boolean;
    clientPriority?: string;
    comment?: string;
    containerId?: number;
    containerNumber?: string;
    containerUnitSizeType?: string;
    containerVolume?: number;
    containerWeight?: number;
    contributingMotorCarrier?: string;
    ctf?: boolean;
    customerMasterBLNumber?: string;
    customerReference?: string;
    customsBroker?: string;
    dcAppointmentNumber?: string;
    deliveryActualDate?: string;
    deliveryEnteredDate?: string;
    deliveryEnteredScheduleDate?: string;
    deliveryOverrideDate?: string;
    deliveryScheduleDate?: string;
    deliveryTransactionDate?: string;
    demurrage?: boolean;
    detention?: boolean;
    detentionConsignee?: boolean;
    detentionTerminal?: boolean;
    dropFee?: boolean;
    dryRun?: boolean;
    emptyNotifyDate?: string;
    fulfillmentMotorCarrier?: string;
    gateFee?: boolean;
    gateOutDate?: string;
    hazmat?: boolean;
    hazmatFlag?: boolean;
    lastFreeDayOffDock?: string;
    lastFreeDayTerminal?: string;
    latestLeg?: boolean;
    layover?: boolean;
    legId?: number;
    legNumber?: number;
    loadStatus?: string;
    locationDelivery?: Location;
    locationFinalDestination?: Location;
    locationPickup?: Location;
    locationPortOfDischarge?: Location;
    manualHold?: string;
    messageResponse?: string;
    messageStatus?: number;
    oceanCarrierScacCode?: string;
    orgRefAllocatedCarrier?: OrganizationReference;
    orgRefInterchangedCarrier?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    orgRefOceanCarrier?: OrganizationReference;
    orgRefShipper?: OrganizationReference;
    overweight?: boolean;
    perDiem?: boolean;
    pickupActualDate?: string;
    pickupEnteredDate?: string;
    pickupEnteredScheduleDate?: string;
    pickupNumber?: string;
    pickupOverrideDate?: string;
    pickupScheduleDate?: string;
    pickupTransactionDate?: string;
    pierPass?: boolean;
    portCongestion?: boolean;
    portOfLoading?: string;
    prePull?: boolean;
    priority?: string;
    privateChassis?: boolean;
    processing?: boolean;
    readyForDispatch?: boolean;
    readyForDispatchDate?: string;
    redeliveryFee?: boolean;
    rejectedLegCopied?: boolean;
    scale?: boolean;
    scheduledPickupReceiptDate?: string;
    sealNumber?: string;
    shipmentId?: number;
    shipmentReference?: string;
    shipmentReference2?: string;
    specialInstructions?: string;
    stopOff?: boolean;
    streetTurn?: boolean;
    triAxle?: boolean;
    vesselArrivalDate?: string;
    vesselETA?: string;
    vesselName?: string;
    voyageName?: string;
    workOrderNumber?: string;
    workflowStatus?: WorkflowStatus;
    yardStorage?: boolean;
}

