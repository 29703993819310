/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkflowTaskParam } from './workflowTaskParam';
import { WorkflowStatus } from './workflowStatus';


export interface WorkflowTask { 
    attributeList?: Array<WorkflowTaskParam>;
    description?: string;
    enabled?: boolean;
    failStepNumber?: number;
    forkType?: string;
    grade?: string;
    history?: Array<WorkflowStatus>;
    id?: number;
    inLine?: boolean;
    lastRuntime?: string;
    name?: string;
    passFail?: string;
    passStepNumber?: number;
    serviceId?: number;
    serviceType?: string;
    status?: string;
    stepName?: string;
    stepNumber?: number;
    timerAutoPass?: boolean;
    timerType?: string;
    timerValue?: number;
    workflowFork?: boolean;
}

