import { User, Organization } from '../api';
import { TokenData } from './auth.state';

export class LoadUserAction {
  static readonly type = '[Auth] Load User';
  constructor(public payload: { token: string, tokenData: TokenData,organizationId?: Organization['id'] }) { }
}

export class ReloadUserAction {
  static readonly type = '[Auth] Reload User';
  constructor(public payload?: { organizationId?: Organization['id'] }) { }
}

export class LoadEmailUserAction {
  static readonly type = '[Auth] Load Email User';
  constructor(public payload: { token: string, tokenData: TokenData }) { }
}

export class loadUserOnOrgChangeAction {
  static readonly type = '[Auth] update Users by Org';
  constructor(public payload: { organizationId?: Organization['id'] }) { }
}

export class UpdateUserAction {
  static readonly type = '[Auth] Update User';
  constructor(public payload: { user: User, organization: Organization }) { }
}

export class RefreshTokenAction {
  static readonly type = '[Auth] Refresh token';
  constructor() { }
}

export class AcceptUserTermsAction {
  static readonly type = '[Auth] Accept terms of use for User';
  constructor() { }
}

export class ResetTermsOfServiceAction {
  static readonly type = '[Auth] Reset Syndic8 User Terms of Service';
  constructor(public payload: { organization: Organization }) { }
}

export class LogoutUserAction {
  static readonly type = '[Auth] Logout User';
  constructor() { }
}
