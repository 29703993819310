import {Component, Input} from '@angular/core';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ITextFilterParams,
  RowModelType,
  SideBarDef
} from "@ag-grid-community/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { InfiniteScrollColumn, InfiniteScrollColumnSuggestionProperty, InfiniteScrollColumnType } from "@app/shared";
import { IRichCellEditorParams } from "ag-grid-enterprise";
import { Suggestion} from "@app/core";


@Component({
  selector: 'app-ag-scroll-table',
  templateUrl: './ag-scroll-table.component.html',
  styleUrls: ['./ag-scroll-table.component.scss']
})
export class AgScrollTableComponent {
  private _debug: boolean = false;
  /* Component specific variables */
  /* 'ID' of passed in rows TODO: Needs to be dynamic */
  private _rowId = 'id'
  /* 'Name'' property of a passed in Suggestion List */
  private _suggPropName = 'name'
  /* ID property of a passed in Suggestion List */
  private _suggPropId = 'id'
  /* Suggestion List for booleans */
  private _booleanColSuggestions = [ { id: "true", name: "true" }, { id: "false", name: "false" }];
  /* Map of Suggestion Lists to Column ID */
  private _listNameToColId = new Map();
  /* Original Column data passed to component */
  private _originalColumns: Array<InfiniteScrollColumn> | Array<ColDef> = null;
  /* Keeps track of the current lock boolean status */
  private _lockState: boolean = false;


  defaultFilterParams: ITextFilterParams = {
    buttons: ["clear", "apply"],
    filterOptions: ["contains", "equals"],
    debounceMs: 200,
    maxNumConditions: 1,
  };

  /* Amount of rows AG Grid should retrieve via Server Side Requests */
  @Input() cacheBlockSize: number = 50;
  @Input() gridOptions: GridOptions = {
      cacheBlockSize: this.cacheBlockSize,
      blockLoadDebounceMillis: 300,
      // suppressEnterpriseResetOnNewColumns: true,
      // onColumnVisible: this.onColumnVisibleEvent.bind(this),
      // onColumnMoved: this.columnMovedEvent.bind(this),
      onGridReady: this.onGridReady.bind(this),
      onRowClicked: this.onRowClick.bind(this),
      // Hides the right click menu
      suppressContextMenu: true,
      debug: false
  };
  @Input() columnType: 'InfiniteScrollColumn' | 'ColDef' = 'ColDef';
  /* Refresh AG Grid on successful data updates */
  @Input() refreshOnUpdateSuccess = true;
  /* Refresh AG Grid on lock toggle */
  @Input() reloadOnLockToggle: boolean = true;
  /* Enables Multi Select */
  @Input() selectionEnabled: boolean = false;
  /* Enables the use of a toggle for edit disable */
  @Input() enableEditLock: boolean = true;
  /* Columns for AG Grid to display */
  @Input() set columns(columns: Array<InfiniteScrollColumn> | Array<ColDef>) {
    if(this._debug) console.log("ag-scroll-table set columns=>", columns)
    this._originalColumns = columns;
  }
  @Input() columnDefs: Array<ColDef> = [{
    colId: "selectColumn",
    minWidth: 60,
    maxWidth: 60,
    tooltipField: null,
    tooltipComponentParams: null,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    filter: null,
    // Locks the CheckBox to the left
    lockPinned: true,
    lockPosition: true,
    suppressMovable: true
  },
  {
    field: "name",
    minWidth: 180,
    // tooltipField: y[i].propertyName,
    tooltipComponentParams: { type: "success" },
    cellEditorParams: { suppressTooltip: true },
    editable: true,
    sortable: false,
  },
  {
    field: "description",
    minWidth: 180,
    // tooltipField: y[i].propertyName,
    tooltipComponentParams: { type: "success" },
    cellEditorParams: { suppressTooltip: true },
    editable: true,
    sortable: false,
  }];

  // Actions
  // TODO: How many parameters does this actually need? -- Also clean this up
  @Input() loadAction: ({ pageLength, pageToGet, blockSize, request }) => void;
  @Input() updateAction: ({ rows }: { rows: Array<{ [key: string]: any }> }) => void;
  @Input() onRowClickAction: (event) => void;
  /* A list of provided Suggestions, must be above `columns` attribute */
  @Input() suggestions: { [key: string]: Array<Suggestion> } = {};


  // TODO: Can this be added as part of the GridOptions?
  rowModelType: RowModelType = "serverSide"
  rowSelection: "single" | "multiple" = "multiple";
  gridApi: GridApi = undefined;

  // _columnDefs: Array<ColDef> = [
  // ];
  // CheckBox column
  selectionColDef: ColDef =
    {
      colId: "selectColumn",
      minWidth: 60,
      maxWidth: 60,
      tooltipField: null,
      tooltipComponentParams: null,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      filter: null,
      hide: !this.selectionEnabled,
      // Locks the CheckBox to the left
      lockPinned: true,
      lockPosition: true,
      suppressMovable: true
    }

  dataSource: IServerSideDatasource = {
    getRows: (request: IServerSideGetRowsParams) => {
      request.context = {
        originalColumns: this._originalColumns
      }
      const startRow = request.request.startRow;
      const endRow = request.request.endRow
      const currentPageNumber = Math.floor(endRow / this.cacheBlockSize);
      this.store.dispatch(new this.loadAction({ pageLength: this.cacheBlockSize, pageToGet: currentPageNumber, blockSize: this.cacheBlockSize, request }))
    }
  }

  // TODO: Add a toggle to enable / disable and move this to GridOptions instead of a directive
  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: false,
          suppressColumnSelectAll: false,
          suppressColumnExpandAll: false,
        },
      },
    ],
    defaultToolPanel: ''
  };


  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private store: Store,
  ) {

    this.gridOptions = <GridOptions> {
      // TODO: Make default grid options
      ...this.gridOptions,
      // cacheBlockSize: this.cacheBlockSize,
      // blockLoadDebounceMillis: 300,
      // suppressEnterpriseResetOnNewColumns: true,
      // onColumnVisible: this.onColumnVisibleEvent.bind(this),
      // onColumnMoved: this.columnMovedEvent.bind(this),
      // onGridReady: this.onGridReady.bind(this),
      // debug: true
    };
  }

  /** Retrieves the ID of a row, used internally for AG Grid */
  getRowId(row) {
    if(this._debug) console.log("ag-scroll-table getRowId=>", row)
    if(row?.data) {
      return row?.data[this._rowId];
    }
  }

  /** Called when AG Grid is initialized */
  onGridReady(params: GridReadyEvent<any>) {
    if(this._debug) console.log('ag-scroll-table onGridReady=>', params)
    if(this.rowModelType === 'serverSide') {
      this.gridApi = params.api;
      this.gridApi.setGridOption("serverSideDatasource", this.dataSource);
      this._mapColumns(this._originalColumns, false);
    }
  }

  /* TODO: For now this will only work if locking is enabled */
  /** Called when a cell in a row has been clicked */
  onRowClick(row) {
    if(this._debug) console.log("onRowClick=>", row)
    if(this.onRowClickAction && this.enableEditLock && !this._lockState) {
      this.onRowClickAction(row);
    }
  }

  /** Called when a cell has been updated */
  onValueSet(col, params) {
    if (this._debug) console.log('ag-scroll-table onValueSet=>', params)
    let {newValue, oldValue} = params;

    const valuesEqual: boolean = newValue == oldValue;
    const colId = params.colDef.colId;

    const cellType = params.colDef.cellEditor;
    let updatedData = {
      ...params.data
    }


    // Don't need type of boolean to trigger this section of code
    if(cellType === 'agRichSelectCellEditor' && !(col.type === 'Boolean')) {
      // Updates two fields -- the display name and internal value
      const suggestionAlternateProperty = col.suggestionAlternateProperty;
      newValue = suggestionAlternateProperty?.parseInt ? parseInt(this.getUpdateSuggestionKey(col, params)) : this.getUpdateSuggestionKey(col, params)

      if(newValue) {
      } else {
        newValue = null;
      }

      const displayValue = this.getUpdateSuggestionValue(col, params);

      // TODO: Needs to work with complex objects (ex property.name, property.id, property1.propertya.id etc) in the future
      if(suggestionAlternateProperty?.propertyName) {
        // Maybe a headache? Should I just rely 100% on server refresh?

        // Update the display field first in case the two properties are the same..
        updatedData[params.colDef.field] = displayValue;
        updatedData[suggestionAlternateProperty?.propertyName] = newValue;
      } else {
        throw new Error("No propertyName found for suggestion updating")
      }

    } else {
      // Should only ever be a 1 to 1 field match
      updatedData[params.colDef.field] = newValue;
    }

    const updatedRowId = params?.data[this._rowId];

    if(!valuesEqual) {
      this.store.dispatch(new this.updateAction({rows: [updatedData]})).subscribe({
        error: error => {
          console.log('unhandled error on dispatch subscription: ', error);
          // Refreshes AG Grid if the request errors
          this.gridApi.refreshServerSide();
        },
        complete: () => {
          // Refreshes AG Grid if the request completes
          if(this.refreshOnUpdateSuccess) this.gridApi.refreshServerSide();

          this.gridApi.forEachNode((node) => {
            const rowId = this.getRowId(node);
            if (rowId === updatedRowId) {
              node.updateData(updatedData);
              this.gridApi!.flashCells({rowNodes: [node], columns: [params?.column?.colId]});
            }
          });
        }
      });
    }
    return false;
  }

  /** Map passed in columns to AG Grid */
  private _mapColumns(columns: Array<InfiniteScrollColumn> | Array<ColDef>, isEditable: boolean): void {
    // TODO: This will need a pass over to support complex objects, will implement when needed
    if (this._debug) console.log("ag-scroll-table _mapColumns=>", columns);

    if (this.columnType === 'InfiniteScrollColumn') {
      const _columns = columns.map((col) => {
        const isSuggestionType = col?.type === InfiniteScrollColumnType.Suggestion;
        const isBooleanType = col?.type === InfiniteScrollColumnType.Boolean;

        // Common ColDef attributes
        let partialColDef: ColDef = {
          colId: col.propertyName,
          field: col.propertyName,
          headerName: col.displayName ?? 'displayName not found',
          minWidth: col.width,
          filter: col.filterable ? 'agTextColumnFilter' : false,
          sortable: col.sortable,
          filterParams: { ...this.defaultFilterParams },
          menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
        };

        // Suggestion type specific attributes
        if (isSuggestionType) {
          partialColDef = this._applySuggestionAttributes(partialColDef, col);
          this._listNameToColId.set(col.propertyName, col.suggestion);
        }
        // Boolean type specific attributes
        else if (isBooleanType) {
          partialColDef = this._applyBooleanAttributes(partialColDef);
        }

        const colEditable = this._determineColEditable(col, isEditable);

        // Final ColDef with common and specific attributes
        return {
          ...partialColDef,
          editable: colEditable,
          valueSetter: (params) => this.onValueSet(col, params),
        };
      });

      if (this._debug) console.log("columnDefs=>", _columns);
      this.gridApi!.setGridOption("columnDefs", _columns);
    }
  }

  private _applySuggestionAttributes(partialColDef: ColDef, col: InfiniteScrollColumn): ColDef {
    return {
      ...partialColDef,
      filter: col.filterable ? 'agSetColumnFilter' : false,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: (params) => this.getSuggestionValues(col, params),
      } as IRichCellEditorParams,
      valueGetter: (p) => this._getSuggestionValue(col, p),
      filterParams: {
        ...this.defaultFilterParams,
        keyCreator: (params) => this.getSuggestionKey(col, params),
        valueFormatter: (params) => this.getSuggestionValue(col, params),
        values: (params) => params.success(this.suggestions[col.suggestion]),
        comparator: (a, b) => { return a > b; },
        context: { originalColumns: this._originalColumns },
      },
    };
  }

  private _applyBooleanAttributes(partialColDef: ColDef): ColDef {
    return {
      ...partialColDef,
      filter: partialColDef.filter ? 'agSetColumnFilter' : false,
      filterParams: {
        values: (params) => params.success(this._booleanColSuggestions.map(e=> e.id)),
      },
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: this._booleanColSuggestions.map(e => e.id),
      } as IRichCellEditorParams,
    };
  }

  private _determineColEditable(col: InfiniteScrollColumn, isEditable: boolean): boolean {
    if (this.enableEditLock && !isEditable) {
      return false;
    }
    return col.editable;
  }

  private _getSuggestionValue(col: InfiniteScrollColumn, p): any {
    const listOfSuggestions = this.suggestions[col.suggestion];
    const findEntry = listOfSuggestions?.find((e) => {
      return e[this._suggPropId]?.toString() === p.data[col.propertyName]?.toString();
    });
    return findEntry ? findEntry[this._suggPropName] : p.data[col.propertyName];
  }

  /** Retrieves the suggestion list related to the column */
  private getSuggestionValues(col: InfiniteScrollColumn, params) {
    return this.suggestions[col.suggestion].map((e)=> e[this._suggPropName]);
  }

  /** Retrieves a suggestion key from the suggestion list of the column */
  private getSuggestionKey(col, params) {
    const suggestionAlternateProperty = col.suggestionAlternateProperty;
    if(suggestionAlternateProperty) {
      const keyProp: InfiniteScrollColumnSuggestionProperty = suggestionAlternateProperty.valueProperty;
      if(keyProp) {
        return params.value[keyProp]
      }
    }

    // Fallback to ID as default
    return params.value[this._suggPropId]
  }

  /** Retrieves a suggestion value from the suggestion list of the column */
  private getSuggestionValue(col, params) {
    return params.value[this._suggPropName]
  }

  /** Retrieves the key (id hopefully) from the Selection Object used to Update the Data */
  private getUpdateSuggestionKey(col: InfiniteScrollColumn, params) {
    /* No need to look up boolean suggestions, it'll either be true or false */
    if(col.type === InfiniteScrollColumnType.Boolean) return params.newValue;

    const colId = params?.colDef?.colId;
    const suggestionAlternateProperty = col.suggestionAlternateProperty;

    if(suggestionAlternateProperty) {
      const keyProp: InfiniteScrollColumnSuggestionProperty = suggestionAlternateProperty.valueProperty;
      if(keyProp) {
        const suggestionOptions = this.suggestions[this._listNameToColId.get(colId)];
        if(suggestionOptions) {
          // TODO: Kinda ugly
          return suggestionOptions.filter((e)=> e[this._suggPropName] === params?.newValue).map((e1)=> e1[keyProp])?.pop();
        }
      }
    }

    if(this._debug) console.log("Error: Could not find sugg key to update with, returning old value")
    return params?.oldValue;
  }

  /** Retrieves the value (display name hopefully) from the Selection Object used to Update the Data */
  private getUpdateSuggestionValue(col: InfiniteScrollColumn, params) {
    /* No need to look up boolean suggestions, it'll either be true or false */
    if(col.type === InfiniteScrollColumnType.Boolean) return params.newValue;

    const colId = params?.colDef?.colId;
    const suggestionAlternateProperty = col?.suggestionValueProperty ? col?.suggestionValueProperty : 'name';

    if(suggestionAlternateProperty) {
        const suggestionOptions = this.suggestions[this._listNameToColId.get(colId)];
        if(suggestionOptions) {
          // TODO: Kinda ugly
          return suggestionOptions.filter((e)=> e[this._suggPropName] === params?.newValue).map((e1)=> e1[suggestionAlternateProperty])?.pop();
        }
    }

    if(this._debug) console.log("Error: Could not find sugg value to update with, returning old value")
    return params?.oldValue;
  }

  /** Toggles the lock -- which disables or enables editing */
  toggleLock(lock: boolean) {
    this._mapColumns(this._originalColumns, lock);
    this._lockState = lock;
    if(this.reloadOnLockToggle) {
      this.gridApi!.refreshServerSide();
    }
    /* Clears the cell from any edits ongoing */
    this.gridApi!.clearFocusedCell();
  }
}
