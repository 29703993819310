import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DataProcessBlock, Suggestion, Template} from '@app/core';
import {Store} from '@ngxs/store';

@Component({
  selector: 'app-template-messages',
  templateUrl: './template-messages.component.html',
  styleUrls: ['./template-messages.component.scss']
})
export class TemplateMessagesComponent implements OnInit, OnChanges {
  @HostBinding('class.open') @Input() open = false;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();
  @Input() internalFieldSuggestions: Array<Suggestion> = [];
  @Input() SaveMessagesAction: ({ type, table, block, key }: { type: string, table: string, block: string, key: string }) => void;
  @Input() template: Template;
  @Input() sheetSuggestions: Array<Suggestion> = [];
  @Input() internalSheetSuggestions: Array<Suggestion> = [];

  sheetSelected: String = null;
  fieldSelected: String = null;


  form: UntypedFormGroup = new UntypedFormGroup({
    type: new FormControl<string>('', [Validators.required]),
    table: new FormControl<string>('', [Validators.required]),
    block: new FormControl<string>('', [Validators.required]),
    key: new FormControl<string>('', [Validators.required])
  });

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.setForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.template) {
      this.setForm();
    }
  }

  private setForm(): void {
    if (this.template?.dataProcessTemplate?.templateMap) {
      const map = this.template?.dataProcessTemplate?.templateMap;
      if (map?.messageType && map?.messageTable && map?.messageBlock && map?.messageKey) {
        const { messageType, messageTable, messageBlock, messageKey } = map;

        if(messageBlock) {
          this.sheetSelected = messageBlock;
          this.fieldSelected = messageKey;
          this.sheetSelectedEvent(messageBlock);
        }

        this.form.setValue({
          type: messageType,
          table: messageTable,
          block: messageBlock,
          key: messageKey
        });
      }
    }
  }

  sheetSelectedEvent(event): void {
    if(event) {
      const bl: DataProcessBlock = this.template.dataProcessTemplate.blockList.filter(b => b.name === event).pop();
      if(bl) {
        this.internalFieldSuggestions = bl.fields.map((field) => {
          return {id: field.name, name: field.name};
        });
      }
    } else {
      this.internalFieldSuggestions = null;
    }
  }

  save(): void {
    if (this.form.invalid || !this.SaveMessagesAction) {
      return;
    }
    const { type, table, block, key } = this.form.value;
    this.store.dispatch(new this.SaveMessagesAction({ type, table, block, key }));
  }

}
