import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthState } from '@app/core';
import { Filter, FilterJoin, FilterMatch, OrderBy, User } from '@app/core/api';
import { RouterStateParams } from '@app/core/providers/custom-router-state-serializer';
import { RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export type InputType = 'date' | 'datetime-local' | 'time' | 'number' | 'text' | 'boolean';

export const InputType = {
  Date: 'date' as InputType,
  Datetime: 'datetime-local' as InputType,
  Time: 'time' as InputType,
  Number: 'number' as InputType,
  Text: 'text' as InputType,
  Boolean: 'boolean' as InputType
};

export type SuggestionProperty = 'id' | 'name';

export const SuggestionProperty = {
  Id: 'id' as SuggestionProperty,
  Name: 'name' as SuggestionProperty
};

export interface QueryFilterMeta {
  [key: string]: Array<QueryFilter>
};

export type QueryFilterType = 'date' | 'time' | 'datetime' | 'string' | 'integer' | 'decimal' | 'boolean' | 'suggestion' | 'multiSuggestion';

export const QueryFilterType = {
  Date: 'date' as QueryFilterType,
  Time: 'time' as QueryFilterType,
  Datetime: 'datetime' as QueryFilterType,
  String: 'string' as QueryFilterType,
  Integer: 'integer' as QueryFilterType,
  Decimal: 'decimal' as QueryFilterType,
  Boolean: 'boolean' as QueryFilterType,
  Suggestion: 'suggestion' as QueryFilterType,
  MultiSuggestion: 'MultiSuggestion' as QueryFilterType
};

export interface QueryFilter {
  displayName: string;
  propertyName: string;
  type: QueryFilterType; // Default is string
  hidden?: boolean; // Won't show in the filter form/picker or sort for selection
  obscured?: boolean; // Won't show in the filter list when loaded from router
  suggestion?: string; // Name of suggestions included in accompanied input
  suggestionProperty?: SuggestionProperty; // The suggestion property to use for filtering purposes
  suggestionDisplayName?: boolean; // Display the suggestion name, rather than the Id by default
  multiSelectionFilterMatch?: FilterMatch; // Fallback to `IS`
  filters?: Array<Array<QueryFilterConfiguration>>; // A filter for each `suggestion` in order, each must be unique
  // Radio button, then checkbox
  radio?: boolean;
  radioReset?: boolean;
  showSearch?: boolean;
}

export interface QueryFilterConfiguration {
  by?: string;
  join?: FilterJoin;
  match: FilterMatch;
  value?: string; // If undefined, use the suggestion
}

export interface MoreQueryFilter {
  displayName: string;
  propertyName: string;
  filters?: Array<MoreFilterConfiguration>;
}

export interface MoreFilterConfiguration {
  name?: string;
  match: FilterMatch;
  inputType: InputType;
  percentage?: boolean;
}

export interface FilterParams {
  id?: Array<string>;
  by: Array<string>;
  join: Array<FilterJoin>;
  match: Array<FilterMatch>;
  value: Array<string>;
}

export interface SearchFilter {
  by: string;
  join: FilterJoin;
  match: FilterMatch;
  value: string;
}

export interface FilterMatchOption {
  displayName: string;
  filterMatch: FilterMatch;
}

@Injectable()
export class QueryFilterService {

  private filtersSubject: BehaviorSubject<Array<SearchFilter>> = new BehaviorSubject([]);

  set filters(filters: Array<SearchFilter>) {
    const by: Array<string> = filters.map(f => f.by);
    const join: Array<FilterJoin> = filters.map(f => f.join);
    const match: Array<FilterMatch> = filters.map(f => f.match);
    const value: Array<string> = filters.map(f => f.value);
    this.applyFilters({ by, join, match, value });
  }

  get filters(): Array<SearchFilter> {
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);

    const filterBy: Array<string> = queryParams.filterBy && Array.isArray(queryParams.filterBy) ?
      [...queryParams.filterBy] : [queryParams.filterBy];
    const filterJoin: Array<string> = queryParams.filterJoin && Array.isArray(queryParams.filterJoin) ?
      [...queryParams.filterJoin] : [queryParams.filterJoin || FilterJoin.AND];
    const filterMatch: Array<string> = queryParams.filterMatch && Array.isArray(queryParams.filterMatch) ?
      [...queryParams.filterMatch] : [queryParams.filterMatch];
    const filterValue: Array<string> = queryParams.filterValue && Array.isArray(queryParams.filterValue) ?
      [...queryParams.filterValue] : [queryParams.filterValue];

    if (filterBy?.length > 0 && filterBy.findIndex(f => !!f) > -1) {
      return filterBy.reduce(
        (acc: Array<SearchFilter>, by: string, i: number) => {
          const join = FilterJoin[Object.keys(FilterJoin).find(jn => jn === filterJoin[i])];
          const match = FilterMatch[Object.keys(FilterMatch).find(mh => mh === filterMatch[i])];
          const value: string = filterValue[i];
          return [...acc, { by, join, match, value }];
        }, []
      );
    }
    return [];
  }

  get filters$(): Observable<Array<SearchFilter>> {
    return this.filtersSubject.asObservable();
  }

  constructor(private store: Store, private router: Router) {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => this.filters)
    ).subscribe(filters => this.filtersSubject.next(filters));

    this.filtersSubject.next(this.filters);
  }

  convertCurrentFiltersToFilterObject(filterName: string): Filter {
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);

    const sortBy = queryParams.sort || null;
    const orderBy = queryParams.order === OrderBy.DESC ? OrderBy.DESC : OrderBy.ASC;

    const filterBy: Array<string> = this.filters.map(f => f.by);
    const filterJoin: Array<FilterJoin> = this.filters.map(f => f.join);
    const filterMatch: Array<FilterMatch> = this.filters.map(f => f.match);
    const filterValue: Array<string> = this.filters.map(f => f.value);
    const user: User = this.store.selectSnapshot(AuthState.getUser);

    const filterObject: Filter = {
      filterEntries: filterBy.map(
        (by: string, i: number) => {
          return {
            filterBy: by,
            filterJoin: filterJoin[i],
            filterMatch: filterMatch[i],
            filterValue: filterValue[i]
          }
        }
      ),
      filterName,
      sortEntries: [{ orderBy, sortBy }],
      userRef: {
        id: user.id
      }
    };

    return filterObject;
  }

  addFilters(filter: FilterParams): Promise<boolean> {
    const by: Array<string> = [...this.filters.map(f => f.by), ...filter.by];
    const join: Array<FilterJoin> = [...this.filters.map(f => f.join), ...filter.join];
    const match: Array<FilterMatch> = [...this.filters.map(f => f.match), ...filter.match];
    const value: Array<string> = [...this.filters.map(f => f.value), ...filter.value];

    return this.applyFilters({ by, join, match, value });
  }

  applyFilters({ id, by, join, match, value }: FilterParams, productType?: string): Promise<boolean> {
    return this.router.navigate([],
      { queryParams: {
        filterId: id,
        filterBy: by,
        filterJoin: join,
        filterMatch: match,
        filterValue: value,
        type: productType ? productType : undefined
      },
      queryParamsHandling: 'merge'
    });
  }

  removeFilters(indexes: Array<number>): Promise<boolean> {
    let filters = this.filters;
    indexes.forEach(
      (index: number) => {
        // Make sure a series of matching filters always starts with an `and` join
        const filter = this.filters[index];
        if (filter.join === FilterJoin.AND) {
          let indexMap: Array<number> = [];
          const onlyMatchingFilters = this.filters.reduce(
            (acc: Array<SearchFilter>, f: SearchFilter, i: number) => {
              if (f.by === filter.by && f.match === filter.match) {
                indexMap = [...indexMap, i];
                return [...acc, f];
              }
              return acc;
            },
            []
          );
          // If there is more than 1 matching filter & the next filter is an `Or` join, update the next filter
          if (onlyMatchingFilters.length > 1 && onlyMatchingFilters[1]?.join === FilterJoin.OR) {
            filters = this.filters.map(
              (filter, i) => {
                if (i === indexMap[1]) {
                  return {
                    ...filter,
                    join: FilterJoin.AND
                  };
                }
                return filter;
              }
            );
          }
        }
      }
    );

    // Sort indexes highest to lowest so that lower index position don't change position when removing higher indexes
    indexes = indexes.sort((a, b) => b - a);
    let by: Array<string> = filters.map(f => f.by);
    let join: Array<FilterJoin> = filters.map(f => f.join);
    let match: Array<FilterMatch> = filters.map(f => f.match);
    let value: Array<string> = filters.map(f => f.value);

    indexes.forEach(
      (index: number) => {
        by = by.filter((f, i) => i !== index).length > 0 ? by.filter((f, i) => i !== index) : undefined;
        join = join.filter((f, i) => i !== index).length > 0 ? join.filter((f, i) => i !== index) : undefined;
        match = match.filter((f, i) => i !== index).length > 0 ? match.filter((f, i) => i !== index) : undefined;
        value = value.filter((f, i) => i !== index).length > 0 ? value.filter((f, i) => i !== index) : undefined;
      }
    );

    return this.applyFilters({ by, join, match, value });
  }

  resetFilters(): Promise<boolean> {
    return this.applyFilters({ by: undefined, join: undefined, match: undefined, value: undefined });
  }

  getFilterMatchOption(filterMatch: FilterMatch): FilterMatchOption {
    switch(filterMatch) {
      case FilterMatch.CONTAINS:
        return {
          displayName: 'Contains',
          filterMatch
        };
      case FilterMatch.DOESNOTSTARTSWITH:
        return {
          displayName: 'Does Not Start With',
          filterMatch
        };
      case FilterMatch.ENDSWITH:
        return {
          displayName: 'Ends With',
          filterMatch
        };
      case FilterMatch.INLIST:
        return {
          displayName: 'In List',
          filterMatch
        };
      case FilterMatch.IS:
        return {
          displayName: 'Is',
          filterMatch
        };
      case FilterMatch.ISAFTER:
        return {
          displayName: 'Is After',
          filterMatch
        };
      case FilterMatch.ISBEFORE:
        return {
          displayName: 'Is Before',
          filterMatch
        };
      case FilterMatch.ISBETWEEN:
        return {
          displayName: 'Is Between',
          filterMatch
        };
      case FilterMatch.ISLESSTHAN:
        return {
          displayName: 'Is Less Than',
          filterMatch
        };
      case FilterMatch.ISLESSTHANEQUAL:
        return {
          displayName: 'Is Less Than Equal',
          filterMatch
        };
      case FilterMatch.ISMORETHAN:
        return {
          displayName: 'Is More Than',
          filterMatch
        };
      case FilterMatch.ISMORETHANEQUAL:
        return {
          displayName: 'Is More Than Equal',
          filterMatch
        };
      case FilterMatch.ISNOT:
        return {
          displayName: 'Is Not',
          filterMatch
        };
      case FilterMatch.ISNOTNULL:
        return {
          displayName: 'Is Not Null',
          filterMatch
        };
      case FilterMatch.ISNULL:
        return {
          displayName: 'Is Null',
          filterMatch
        };
      case FilterMatch.NOTINLIST:
        return {
          displayName: 'Not In List',
          filterMatch
        };
      case FilterMatch.STARTSWITH:
        return {
          displayName: 'Starts With',
          filterMatch
        };
      default:
        return {
          displayName: filterMatch,
          filterMatch
        };
    }
  }
}
