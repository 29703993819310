import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Template} from "@app/core";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Store} from "@ngxs/store";
import {
  createGrid,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  RowDragEndEvent
} from "@ag-grid-community/core";
import {BehaviorSubject} from "rxjs";

export class Node {
  id: string;
  children: Node[];
}

@Component({
  selector: 'app-template-sheet-order',
  templateUrl: './template-sheet-order.component.html',
  styleUrls: ['./template-sheet-order.component.scss']
})
export class TemplateSheetOrderComponent implements OnInit, OnChanges, OnDestroy {
  rowSub: BehaviorSubject<any> = new BehaviorSubject<any>([])
  data = [];

  gridOptions: GridOptions = {
    columnDefs: [
      {
        field: 'pos',
        hide: true,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          if (valueA == valueB) return 0;
          return (valueA > valueB) ? 1 : -1;
        }
      }
    ],
    defaultColDef: {
      flex: 1,
      resizable: true,
    },
    treeData: true,
    animateRows: true,
    groupDefaultExpanded: -1,
    getDataPath: (data: any) => {
      return data.filePath;
    },
    getRowId: (params: GetRowIdParams) => {
      return params.data.id;
    },
    onGridReady: this.onGridReady.bind(this),
    autoGroupColumnDef: {
      rowDrag: true,
      headerName: 'Sheets',
      minWidth: 300,
      cellRendererParams: {
        suppressCount: true
      },
    },
    onRowDragEnd: this.onRowDragEnd.bind(this),
    // Hides the right click menu
    suppressContextMenu: true
  };

  gridApi: GridApi = null;
  @HostBinding('class.open') @Input() open = false;
  @Input() template: Template;
  @Input() saveTemplate;
  @Input() SaveSheetOrderAction: ({ blockList }: { blockList: any }) => void;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();

  dragging = false;
  rootBlock = null;
  restOfBlocks = null;
  blockListAsTree = null;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
  //   this.gridApi = createGrid();
  }


  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.rowSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    /** ngOnChanges as this component is created before the template is populated */
    if(this.template?.dataProcessTemplate?.blockList) {
      this.rootBlock = this.template.dataProcessTemplate.blockList[0];
      this.restOfBlocks = this.template.dataProcessTemplate.blockList.slice(1);

      let childToParent = new Map<string, string>();
      let treeMap = new Map<string, Node>();
      let rootEntry = new Node();
      rootEntry.id = "root"
      rootEntry.children = []

      /** Converts the blocklist into a format consumable by ag grid */
      this.restOfBlocks.forEach((entry) =>{
        childToParent.set(entry.name, entry.parent);

        const parent = entry.parent;
        const name = entry.name;

        let newChildNode = new Node();
        newChildNode.children = [];
        newChildNode.id = name;

        const parentNode = treeMap.get(parent);
        treeMap.set(name, newChildNode);

        if(parentNode) {
          parentNode.children.push(newChildNode);
        } else {
          // Make parent node
          let newParentNode = new Node();
          newParentNode.id = parent;
          newParentNode.children = [];
          newParentNode.children.push(newChildNode);
          treeMap.set(parent, newParentNode);
        }
      });
      this.blockListAsTree = treeMap.get("root");

      let rootMap = new Map<string, Array<string>>();
      let counterMap = new Map<Array<string>, number>();

      let lst = [{ id: 'root', filePath: ['root'], type: 'folder', pos: 0 }];

      rootMap.set("root", ["root"]);
      this.template?.dataProcessTemplate.blockList.forEach((entry) => {
        if(rootMap.get(entry.parent)) {
          let parentPath = rootMap.get(entry.parent);
          rootMap.set(entry.name, [...parentPath, entry.name])

          let idx = 0;
          if(counterMap.get(parentPath)) {
            idx = counterMap.get(parentPath);
          }

          lst = [...lst, {id: entry.name, filePath: [...parentPath, entry.name], type: 'folder', pos: ++idx}]
          counterMap.set(parentPath, idx);
        }
      })
      this.data = lst;
      this.rowSub.next(lst);
    }
  }


  arePathsEqual(path1, path2) {
    if (path1.length !== path2.length) {
      return false;
    }

    var equal = true;
    path1.forEach(function (item, index) {
      if (path2[index] !== item) {
        equal = false;
      }
    });

    return equal;
  }

  onGridReady(event) {
    this.gridApi = event?.api;
  }

  onRowDragEnd(event: RowDragEndEvent) {
    const shiftPressed = event.event.shiftKey;
    // this is the row the mouse is hovering over
    let overNode = event.overNode;
    if (!overNode) {
      return;
    }

    // folder to drop into is where we are going to move the file/folder to
    let folderToDropInto =
      overNode.data.type === 'folder'
        ? // if over a folder, we take the immediate row
        overNode
        : // if over a file, we take the parent row (which will be a folder)
        overNode.parent;

    // the data we want to move
    let movingData = event.node.data;

    // take new parent path from parent, if data is missing, means it's the root node,
    // which has no data.
    let newParentPath = folderToDropInto!.data ? folderToDropInto!.data.filePath : [];
    let needToChangeParent = !(this.arePathsEqual(newParentPath, movingData.filePath));

    // If shift is not pressed, Only allow re-ordering
    // If Shift is pressed, Only allow re-parenting.
    if(!shiftPressed) {
      let sameParent = folderToDropInto.parent === event.node.parent
      // For a "shift" move to be valid both the destination parent and the dragged even parent need to be the same.
      if(!sameParent)
        return;

      // Grab the pos of the object being dropped onto
      const newPos = folderToDropInto.data.pos;
      const children = event.node.parent.childrenAfterGroup;

      // Add all but the node being moved to the update list.
      let updatedRows: any[] = [];
      children.forEach((child) => {
        const isMovedNode = child.data.id === event.node.data.id;
        if(!isMovedNode)
          updatedRows.push({...child.data})
      });

      // Sorts the list by pos
      updatedRows.sort((a, b) => {
        return a.pos - b.pos;
      });

      // Inserts the node being moved into the list
      updatedRows = [...updatedRows.slice(0, newPos - 1), { ...event.node.data, pos: newPos }, ...updatedRows.slice(newPos - 1)]
      // Updates the positioning for each node, This starts at 1 for now good reason.
      let i = 1;
      updatedRows.forEach((e) => {
        e.pos = i++;
      })

      // Updates the rows
      this.gridApi.applyTransaction({
        update: updatedRows,
      });

      // 'columnApi' is deprecated. @deprecated — Since v31 api is no longer attached to GridOptions. See https://ag-grid.com/javascript-data-grid/grid-interface/#grid-api for how to access the api in your framework.
      this.gridApi.applyColumnState({state: [{ colId: 'pos', sort: 'asc', sortIndex: 0 }]})
      this.gridApi.clearFocusedCell();
      return;
    }

    // check we are not moving a folder into a child folder
    let invalidMode = this.isSelectionParentOfTarget(event.node, folderToDropInto);

    if (needToChangeParent && !invalidMode) {
      // This will only contain the node that is being updated.
      let updatedRows: any[] = [];
      let oldParentRows: any[] = [];
      let newParentRows: any[] = [];

      this.moveToPath(newParentPath, event.node, updatedRows);

      const oldParent = event.node.parent;
      const newParent = folderToDropInto;

      // Updates the old parent rows, Blocked off for neatness
      {
        oldParent.childrenAfterGroup.forEach((child) => {
          const isMovedNode = child.data.id === event.node.data.id;
          if (!isMovedNode)
            oldParentRows.push({...child.data})
        });

        // Sorts the list by pos
        oldParentRows.sort((a, b) => {
          return a.pos - b.pos;
        });

        let i = 1;
        oldParentRows.forEach((e) => {
          e.pos = i++;
        })

        this.gridApi.applyTransaction({
          update: oldParentRows,
        });
      }

      // Update the new parent with the additional node, Blocked off for neatness
      {
        newParent.childrenAfterGroup.forEach((child) => {
          const isMovedNode = child.data.id === event.node.data.id;
          if (!isMovedNode)
            newParentRows.push({...child.data})
        });

        // Sorts the list by pos
        newParentRows.sort((a, b) => {
          return a.pos - b.pos;
        });

        newParentRows.push(updatedRows[0])
        let i = 1;
        newParentRows.forEach((e) => {
          e.pos = i++;
        })
        this.gridApi.applyTransaction({
          update: newParentRows,
        });
      }
      // Applies the sort.
      this.gridApi.applyColumnState({state: [{ colId: 'pos', sort: 'asc', sortIndex: 0 }]})
      this.gridApi.clearFocusedCell();
    }
  }


// this updates the filePath locations in our data, we update the data
// before we send it to AG Grid
  moveToPath(
    newParentPath: string[],
    node: IRowNode,
    allUpdatedNodes: any[]
  ) {
    // last part of the file path is the file name
    let oldPath = node.data.filePath;
    let fileName = oldPath[oldPath.length - 1];
    let newChildPath = newParentPath.slice();
    newChildPath.push(fileName);

    node.data.filePath = newChildPath;

    allUpdatedNodes.push(node.data);

    if (node.childrenAfterGroup) {
      node.childrenAfterGroup.forEach(function (childNode) {
        this.moveToPath(newChildPath, childNode, allUpdatedNodes);
      });
    }
  }

  isSelectionParentOfTarget(
    selectedNode: IRowNode,
    targetNode: IRowNode | null
  ) {
    let children = [...(selectedNode.childrenAfterGroup || [])];

    if (!targetNode) {
      return false;
    }

    while (children.length) {
      const node = children.shift();
      if (!node) {
        continue;
      }

      if (node.key === targetNode.key) {
        return true;
      }

      if (node.childrenAfterGroup && node.childrenAfterGroup.length) {
        children.push(...node.childrenAfterGroup);
      }
    }

    return false;
  }

  onClick(): void {
    const agDataStruct = this.gridApi.getRowNode('root');
    const rootChildren = agDataStruct.allLeafChildren;

    const blockList = [...this.restOfBlocks]
    let updatedBlockList = [this.rootBlock];

    // Orders the list by filepath, This will create the parents before the children.
    let orderedStruct = [...rootChildren];
    orderedStruct.sort((a, b) => {
      return a.data.filePath.length - b.data.filePath.length;
    });

    let chunks = new Map();
    for(let i = 0; i < orderedStruct.length; i++) {
      let path = orderedStruct[i].data.filePath;
      let pathReal = path.slice(0, path.length - 1).join()

      if(!chunks.get(pathReal))
        chunks.set(pathReal, [])
      chunks.get(pathReal).push(orderedStruct[i]);
    }

    let orderedChunks = new Map();
    chunks.forEach((val, key) => {
      let valCopy = [...val];
      valCopy.sort((a, b) => {
        return a.data.pos - b.data.pos;
      });
      orderedChunks.set(key, valCopy);
    })

    orderedChunks.forEach((val, key)=> {
      val.forEach((entry)=> {
        blockList.forEach((bl) => {
          if(bl.name  === entry.id && (bl.name != 'root')) {
            const parent = entry.data.filePath[entry.data.filePath.length - 2]
            updatedBlockList.push({
              ...bl,
              parent: parent
            });
          }
        });
      });
    });

    if(this.SaveSheetOrderAction) {
      this.store.dispatch(new this.SaveSheetOrderAction({ blockList: updatedBlockList }));
    }
  }

  close(): void {
    this.open = false;
  }
}
