import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'BeautifyCamelCase' })
export class BeautifyCamelCasePipe implements PipeTransform {
  transform(input: string): string {
    input = input.charAt(0).toUpperCase() + input.slice(1);
    input = input.replace(/([a-z])([A-Z])/g, '$1 $2');
    input = input.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
    return input;
  }
}
