import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DataProcessField, Suggestion } from '@app/core';
import { Chip, ChipType } from '../components/template/destination-chip/destination-chip.component';

export type FunctionType = 'FUNC:CONCAT::' | 'FUNC:VALIDVALUE::' | 'FUNC:IMAGE::' | 'FUNC:IMAGETHUMB::' | 'FUNC:IMAGELIST::' | 'FUNC:IMAGELISTROW::' | 'FUNC:IMAGELISTPOS::' | 'FUNC:IMAGEMULTILIST::' | 'FUNC:CONCATDELIMITER::' | 'FUNC:TOLOWER:[ /-]::' | 'FUNC:TOUPPER:[ /-]::' | 'FUNC:TOPROPER:[ /-]::' | 'FUNC:TODAYTIME:' | 'FUNC:REFERENCETABLE::' | 'FUNC:IF_THEN:' | 'FUNC:SUBSTR:' | 'FUNC:XMLPARTYINFO::' | 'FUNC:XMLTYPEVALUE::' | 'FUNC:XMLDIMENSIONINFO::' | 'FUNC:XMLSHIPWINDOW::' | 'FUNC:XMLHARMONIZEDCODEINFO::' | 'FUNC:REPLACE:[/.]::' | 'FUNC:MATH:+::' | 'FUNC:MATH:-::' | 'FUNC:' | 'FUNC:GETLANGUAGE::' | 'FUNC:GETPRICING::';

export const FunctionType = {
  Concat: 'FUNC:CONCAT::' as FunctionType,
  ValidValue: 'FUNC:VALIDVALUE::' as FunctionType,
  Image: 'FUNC:IMAGE::' as FunctionType,
  ImageThumbnail: 'FUNC:IMAGETHUMB::' as FunctionType,
  ImageList: 'FUNC:IMAGELIST::' as FunctionType,
  ImageListRow: 'FUNC:IMAGELISTROW::' as FunctionType,
  ImageListPosition: 'FUNC:IMAGELISTPOS::' as FunctionType,
  ImageMultiList: 'FUNC:IMAGEMULTILIST::' as FunctionType,
  FormatMarketingDescription: 'FUNC:FORMATMARKETING::' as FunctionType,
  ConcatDelimiter: 'FUNC:CONCATDELIMITER::' as FunctionType,
  ToLower: 'FUNC:TOLOWER:[ /-]::' as FunctionType,
  ToUpper: 'FUNC:TOUPPER:[ /-]::' as FunctionType,
  ToProper: 'FUNC:TOPROPER:[ /-]::' as FunctionType,
  TodayDateTime: 'FUNC:TODAYTIME:' as FunctionType,
  ReferenceTable: 'FUNC:REFERENCETABLE::' as FunctionType,
  IfThenElse: 'FUNC:IF_THEN:' as FunctionType,
  Substr: 'FUNC:SUBSTR:' as FunctionType,
  XMLPartyInfo: 'FUNC:XMLPARTYINFO::' as FunctionType,
  XMLTypeValue: 'FUNC:XMLTYPEVALUE::' as FunctionType,
  XMLDimensionInfo: 'FUNC:XMLDIMENSIONINFO::' as FunctionType,
  XMLShipWindow: 'FUNC:XMLSHIPWINDOW::' as FunctionType,
  XMLHarmonizedCodeInfo: 'FUNC:XMLHARMONIZEDCODEINFO::' as FunctionType,
  Replace: 'FUNC:REPLACE:[/.]::' as FunctionType,
  MathAdd: 'FUNC:MATH:+::' as FunctionType,
  MathSubtract: 'FUNC:MATH:-::' as FunctionType,
  Language: 'FUNC:GETLANGUAGE::' as FunctionType,
  Pricing: 'FUNC:GETPRICING::' as FunctionType,
  Function: 'FUNC:' as FunctionType,
};

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  get caseFunctionSuggestions(): Array<Suggestion> {
    return [
      {
        id: FunctionType.ToLower,
        name: 'Lower Case',
        description: 'Convert product fields and free form text to lower case'
      },
      {
        id: FunctionType.ToUpper,
        name: 'Upper Case',
        description: 'Convert product fields and free form text to upper case'
      },
      {
        id: FunctionType.ToProper,
        name: 'Proper Case',
        description: 'Convert product fields and free form text to proper case'
      }
    ];
  };

  constructor() { }

  getFunctionType(field: DataProcessField): FunctionType {

    if (field?.convert?.length > 0) {
      for (const key in FunctionType) {
        if (field?.convert[0] && field.convert[0].startsWith(FunctionType[key])) {
          return FunctionType[key];
        }
      }
      return FunctionType.Function;
    }
  }

  getConvert(field: DataProcessField): string {
    if (field?.convert?.length > 0 && this.getFunctionType(field)) {
      return field.convert[0];
    }
    return "";
  }

  setConvert(field: DataProcessField, convert: string): void {
    if (this.getFunctionType(field)) {
      if (field?.convert?.length > 0) {
        field.convert[0] = convert;
      } else {
        field.convert = [convert];
      }
    }
  }

  getFunctionValidValueDisplay(field: DataProcessField, suggestions: Array<Suggestion>): string {
    if (!suggestions) {
      return;
    }
    const value = this.getFunctionValidValue(field);
    const index = suggestions.findIndex(s => s.id === `COLUMNNAME:${value}`);
    if (index > -1) {
      return suggestions[index].name;
    }
    return value;
  }

  getFunctionValue(field: DataProcessField, type: FunctionType): string {
    return this.getConvert(field).replace(type, '');
  }

  getFunctionValueChips(field: DataProcessField, type: FunctionType): Array<Chip> {
    return this.getFunctionValue(field, type).split(',').map(value => {
      return { value, type: ChipType.Variable };
    });
  }

  getFunctionValidValue(field: DataProcessField): string {
    const value = this.getFunctionValue(field, FunctionType.ValidValue).replace('$D{', '');
    return value.substring(0, value.length - 1);
  }

  getConcatCase(field: DataProcessField): string {
    if (field?.convert?.length > 1 && this.getFunctionType(field)) {
      const concatCase = this.caseFunctionSuggestions.find(s => s.id === field.convert[1]);
      if (concatCase) {
        return concatCase.name;
      }
    }
  }

  columnSelected(field: DataProcessField): boolean {
    if (field?.convert?.length > 0) {
      return true;
    }
    if (field?.fieldStartMatch && field.fieldStartMatch !== 'COLUMNNAME:NOCOLUMN' && field.fieldStartMatch !== 'COLUMNNAME:null') {
      return true;
    }
    if (field?.defaultValue && field.defaultValue !== '') {
      return true;
    }
    return false;
  }

  setRequired(formGroup: UntypedFormGroup): void {
    const required = formGroup.get('requiredDisplay');
    const recommendedDisplay = formGroup.get('recommendedDisplay');
    required.markAsDirty();
    required.setValue(true);
    recommendedDisplay.markAsDirty();
    recommendedDisplay.setValue(false);
  }

  setConditionallyRequired(formGroup: UntypedFormGroup): void {
    const required = formGroup.get('requiredDisplay');
    const recommendedDisplay = formGroup.get('recommendedDisplay');
    required.markAsDirty();
    required.setValue(false);
    recommendedDisplay.markAsDirty();
    recommendedDisplay.setValue(false);
  }

  setRecommended(formGroup: UntypedFormGroup): void {
    const required = formGroup.get('requiredDisplay');
    const recommendedDisplay = formGroup.get('recommendedDisplay');
    required.markAsDirty();
    required.setValue(false);
    recommendedDisplay.markAsDirty();
    recommendedDisplay.setValue(true);
  }

  convertValue(isXPath: boolean, value: string | Suggestion): string {
    if (!value) {
      return '';
    }
    if (isXPath) {
      return `$X{${value}}`;
    } else if (typeof value  === 'object' && 'name' in value && 'id' in value && value.id) {
      if (value.id.startsWith('COLUMNNAME:')) {
        return `$D{${value.id.split(':')[1]}}`;
      } else {
        return value.id;
      }
    }
    return value as string;
  }
}
