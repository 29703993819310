import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'positiveNumber'
})
export class PositiveNumberPipe implements PipeTransform {
  // Convert a negative number to positive (built for ee*dray's  leg numbers)
  transform(input: string): string {
    const inputAsNumber: number = parseInt(input, 10);

    if (isNaN(inputAsNumber)) {
      return input;
    } else {
      return inputAsNumber >= 0 ? input : (inputAsNumber * -1).toString();
    }
  }

}
