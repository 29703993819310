export interface Environment {
  color: string;
  production: boolean;
  logging: boolean;
  testing: boolean;
  analytics: boolean;
  prometheusUrl: string;
  apiUrl: string;
  apiVersion: string;
  swaggerApiKey: string;
  domain: string;
  clientId: string;
  audience: string;
  tokenNamespace: string;
  inriverConnection: string
}

export enum EnvironmentColor {
  None = 'None',
  Dev = 'Dev',
  Infrared = 'Infrared',
  Rapidmapred = 'Rapidmapred',
  Rapidmapinfrared = 'Rapidmapinfrared',
  Rapidmapgreen = 'Rapidmapgreen',
  Rapidmapwhite = 'Rapidmapwhite',
  Black = 'Black',
  Red = 'Red',
  Green = 'Green',
  Blue = 'Blue',
  Indigo = 'Indigo',
  Violet = 'Violet',
  Orange = 'Orange',
  Yellow = 'Yellow',
  UV = 'UV',
  White = 'White',
  Hydra = 'Hydra',
  Product = 'Product',
  Test = 'Test'
}
