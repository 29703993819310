<div class="chart-container">
  <!-- loop through categories -->
  <div class="step-container">
    <div class="bars-container">
      <div class="panes">
        <div class="left-side">
          <div class="bar warnings" *ngIf="step.warnings > 0"
            [style.width.%]="(step.warnings)/(step.warnings + step.errors + step.approvals + step.passes)*100"
            (click)="stepNameWarn(step)">
            {{ step.warnings }}
          </div>
          <div class="bar approvals" *ngIf="step.approvals > 0"
            [style.width.%]="(step.approvals)/(step.warnings + step.errors + step.approvals + step.passes)*100">
            {{ step.approvals }}
          </div>
          <div class="bar errors" *ngIf="step.errors > 0"
            [style.width.%]="(step.errors)/(step.warnings + step.errors + step.approvals + step.passes)*100"
            (click)="stepNameError(step)">
            {{ step.errors }}
          </div>
        </div>
        <div class="right-side">
          <div class="bar passes" *ngIf="step.passes > 0"
            [style.width.%]="(step.passes)/(step.warnings + step.errors + step.approvals + step.passes)*100"
            (click)="stepName(step)">
            {{ step.passes }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
