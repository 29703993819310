<div class="header">
  <div class="title">
    <i class="fas fa-question-circle question-icon"></i> {{title}}
    <div class="close-button" (click)="onCloseClicked.emit()">
      <i class="far fa-times"></i>
    </div>
  </div>
  <div class="search-container">
    <input type="text" class="search-input" placeholder="Search for a topic..." [formControl]="searchForm">
  </div>
</div>
