/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FilterEntry } from './filterEntry';
import { UserReference } from './userReference';
import { OrganizationReference } from './organizationReference';
import { SortEntry } from './sortEntry';


export interface Filter { 
    filterEntries?: Array<FilterEntry>;
    filterId?: number;
    filterName?: string;
    filterType?: string;
    internal?: boolean;
    orgRef?: OrganizationReference;
    sortEntries?: Array<SortEntry>;
    subCatalogId?: number;
    uriId?: number;
    userRef?: UserReference;
}

