import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { GetRowIdParams, GridOptions, GetContextMenuItemsParams, GridApi } from "@ag-grid-community/core";
import { Template } from "@app/core";
import { Store } from "@ngxs/store";


@Component({
  selector: 'app-json-path-modal',
  templateUrl: './json-path-modal.component.html',
  styleUrls: ['./json-path-modal.component.scss']
})
export class JsonPathModalComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class.open') @Input() open = false;

  @Output() openChange: EventEmitter<boolean> = new EventEmitter();

  @Input() template: Template;
  @Input() saveTemplate;

  loadSegments: boolean = true;
  @Input() jsonPathControlFormGroup: UntypedFormGroup;
  @Input() jsonPathBaseFile$: Observable<string>;
  @Input() segmentPaths$: Observable<Array<any>>;
  @Input() SaveJsonPath: ({ path }: { path: string })  => void;
  @Input() SaveLanguageMap: ({ path }: { path: string })  => void;
  @Input() LoadSegmentPaths: () => void;

  rowSub: BehaviorSubject<any> = new BehaviorSubject<any>([])
  subscription!: Subscription;
  form: UntypedFormGroup = new UntypedFormGroup({
    input: new UntypedFormControl(''),
    currentPath: new UntypedFormControl(''),
  });
  previousPathList: string[]
  gridOptions: GridOptions = {
    suppressContextMenu: false,
    onCellClicked: this.onCellClicked.bind(this),
    onRowDoubleClicked: this.onCellDoubleClicked.bind(this),
    isRowSelectable: this.isSelectable.bind(this),
    rowSelection: 'multiple',
    columnDefs: [
      {
        field: 'path',
        hide: true,
        filter: true,
        menuTabs: ['filterMenuTab']
      },
      {
        field: 'value',
        hide: false,
        menuTabs: ['filterMenuTab']
      }
    ],
    defaultColDef: {
      flex: 1,
      resizable: true,
      filter: true,
      menuTabs: ['filterMenuTab']
    },
    treeData: true,
    animateRows: true,
    // Starts un-expanded
    groupDefaultExpanded: -1,
    getDataPath: (data: any) => {
      return data.filePath;
    },
    getRowId: (params: GetRowIdParams) => {
      return params.data.id;
    },
    onGridReady: this.onGridReady.bind(this),
    autoGroupColumnDef: {
      rowDrag: false,
      headerName: 'Segment',
      minWidth: 300,
      cellRendererParams: {
        suppressCount: true,
        checkbox: true,
      },
    },
    getContextMenuItems: this.getContextMenuItems.bind(this)
  };

  gridApi: GridApi = null;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.subscription = this.segmentPaths$?.subscribe((segmentList) => {
      let lst = segmentList?.map((segment) => {
        if(segment.pathList[0].startsWith("[")) {
          let lst = ["", ...segment.pathList]
          return { id: segment.id, filePath: lst, path: segment.id, value: segment.value}
        }
        return { id: segment.id, filePath: segment.pathList, path: segment.id, value: segment.value}
      });

      if(lst.length > 0)
        this.loadSegments = false;
      this.rowSub.next(lst)
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.rowSub?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    /** ngOnChanges as this component is created before the template is populated */
  }

  onSelectionChanged(event) {
    console.log("onSelectionChanged", event)
    this.calculatePath()
  }

  isSelectable(event) {
    let isArray = false;
    if (event && event.childrenAfterGroup) {
      event.childrenAfterGroup.forEach((child) => {
        if (child.key.startsWith("[") && child.key.endsWith("]"))
          isArray = true
      });
    }
    return isArray;
  }

  onSaveClick(): void {
    if(this.SaveJsonPath) {
      const val = this.form.value.input;
      this.SaveJsonPath({ path: val });
    }
  }

  close(): void {
    this.open = false;
  }

  onCellDoubleClicked(event) {
    // console.log("Clicked", event)
  }

  onCellClicked(event) {
    let pathList = []
    let node = event.node;
    // Iterate through the parents and form the path off of their key
    while (node.id !== 'ROOT_NODE_ID') {
      pathList.push(node.key);
      node = node.parent;
    }
    pathList = pathList.reverse();
    this.previousPathList = pathList;

    this.calculatePath();
  }

  calculatePath(){
    if(this.previousPathList) {
      const pathList = this.previousPathList;
      const selectedNodes = this.gridApi.getSelectedNodes();
      pathList.forEach((v, idx)=> {
        selectedNodes.forEach((node, idx2)=> {
          if(node.level === idx && v === node.key) {
            pathList[idx + 1] = '[*]'
          }
        });
      });

      let path = "";
      pathList.forEach((segment, idx)=> {
        const isArrayPath = this._isArrayNode(segment);
        if(isArrayPath) {
          path = path + segment;
        } else {
          if(idx === 0)
            path = path + segment;
          else
            path = path + "." + segment;
        }
      });

      // Removes the first segment which is usually just the xpath provided in the info section
      let v = path.split(".");
      v.shift();
      this.form.patchValue({ input: v.join(".") })
    }
  }

  get fieldStartMatchModel(): string {
    const value = this.jsonPathControlFormGroup?.value.fieldStartMatch
    if (!value) {
      return;
    }

    if (value.includes("JSONPATH:"))
      return value.split('JSONPATH:')[1];
    return value.split('XPATH:')[1];
  }

  onGridReady(event) {
    this.gridApi = event?.api;
  }

  saveLanguage(primaryLanguage: string, path: Array<string>, isList: boolean) {
    const funcString: string = "FUNC:SAVELANGUAGEMAP::";
    const jsonPathPrefix: string = "JSONPATH:";

    // Should just be the primary language field, pop it away....
    const selectedElement = path?.pop();

    // if isList, the 2nd to last element needs to change the number to a wildcard
    if(isList && path.length >= 1) {
      path[path.length - 1] = path[path.length - 1].replace(/\[\d+\]/, '[*]');
    }

    const fullPath = path.join(".");

    if(this.SaveLanguageMap) {
      this.SaveLanguageMap({ path: "FUNC:SAVELANGUAGEMAP::" + selectedElement + ";" + fullPath });
    }

    /**
     *       "fieldStartMatch" : "JSONPATH:Attributes.StyleStyleDescription.en",
     *       "fieldEndOperator" : null,
     *       "fieldEndMatch" : null,
     *       "validate" : [ ],
     *       "convert" : [ "FUNC:SAVELANGUAGEMAP::en;Attributes.StyleStyleDescription.Values[*]" ],
     */


    /**
     *       "fieldStartMatch" : "JSONPATH:Attributes.MaterialColorArtType.en",
     *       "fieldEndOperator" : null,
     *       "fieldEndMatch" : null,
     *       "validate" : [ ],
     *       "convert" : [ "FUNC:SAVELANGUAGEMAP::en;Attributes.MaterialColorArtType" ],
     */
  }

  getContextMenuItems(params: GetContextMenuItemsParams) {
    const _node = params.node;
    const data = _node.data;
    const filePath = data?.path?.split(".");
    if (filePath) {
      // Remove the first element as this is just the start of the jpath
      const firstElement = filePath.length > 0 ? filePath?.shift() : null;
      const fp = [...filePath]

      const _selectedElement: string = fp.length > 0 ? fp.pop() : null;
      const _selectedElementParent: string = fp.length > 0 ? fp.pop() : null;
      // const _selectedElementGrandParent: string = fp.length > 0 ? fp.pop() : null;

      const _isSelectedArr: boolean = this._isArrayNode(_selectedElement);
      const _selectedElementParentArr: boolean = this._isArrayNode(_selectedElementParent);
      // const _selectedElementGrandParentArr: boolean = this._isArrayNode(_selectedElementGrandParent);

      const actionArr = []

      // Don't allow the menu option under these conditions
      if (_isSelectedArr)
        return [];


      // If a granpparent arr is present offer to save this as a list
      // May need to do a check for json path depth? As the first real node could just be the start of the jpath
      if (_selectedElementParentArr) {
        return [{
          name: "Save Language Map",
          action: () => {
            console.log("params=>", params)
            this.saveLanguage(_selectedElement, filePath, false);
          },
        },
          {
            name: "Save Language Map List",
            action: () => {
              console.log("params=>", params)
              this.saveLanguage(_selectedElement, filePath, true);
            },
          }];
      }

      if (!this._isArrayNode(_selectedElement)) {
        return [{
          name: "Save Language Map",
          action: () => {
            console.log("params=>", params)
            this.saveLanguage(_selectedElement, filePath, false);
          },
        }]
      }
    }
  }

  private _isArrayNode(path: string) {
    return path?.endsWith("]");
  }
}
