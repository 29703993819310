<div class="toast-wrap">
  <div class="header">
    <div class="icon-container">
      <i
        [ngClass]="
          notification.icon ?
            'far fa-' + notification.icon :
            'fal fa-spinner'
        "
      ></i>
    </div>
    <div class="text-container">
      <div class="title">{{ notification.title }}</div>
      <div class="message">{{ notification.message }}</div>
    </div>
  </div>
  <div class="loading">
    <div class="title">Current:</div>
    <div class="progress position-relative" [ngClass]="{'full': notification.progress.currentFinished >= 50}">
      <div class="progress-bar" role="progressbar" [style.width.%]="notification.progress.currentFinished"></div>
      <span class="progress-indicator justify-content-center d-flex position-absolute w-100">{{ notification.progress.currentFinished }}%</span>
    </div>
    <div class="title">Overall:</div>
    <div class="progress position-relative" [ngClass]="{'full': 100 * notification.progress.overallFinished / notification.progress.overallTotal >= 50}">
      <div class="progress-bar" role="progressbar" [style.width.%]="100 * notification.progress.overallFinished / notification.progress.overallTotal | number"></div>
      <span class="progress-indicator justify-content-center d-flex position-absolute w-100">{{ notification.progress.overallFinished }} / {{ notification.progress.overallTotal }}</span>
    </div>
  </div>
</div>