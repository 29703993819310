<div [ngClass]="{ unlocked: isEditing }">
  <div class="change-lock" *ngIf="permission.edit">
    <div *ngIf="isEditing; then unlocked else locked"></div>
    <ng-template #locked>
      <div class="edit-lock-container">
        <div class="flex-container" (click)="setEdit.emit(EditState.Edit)">
          <div class="label">Click to unlock editing</div>
          <div class="icon-container red">
            <i class="fas fa-lock-alt"></i>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #unlocked>
      <div class="edit-lock-container">
        <div class="warning-message">
          <div class="edit-actions">
            <div class="edit-action" (click)="setEdit.emit(EditState.Cancel)">
              <div class="icon-container red">
                <i class="fas fa-times"></i>
              </div>
              <div class="label persistent">Discard Changes</div>
            </div>
            <div class="edit-action" (click)="setEdit.emit(EditState.Save)">
              <div class="icon-container green">
                <i class="fas fa-check"></i>
              </div>
              <div class="label persistent">Save Changes</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
