import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { Select } from '@ngxs/store';
import { Observable, Subject, combineLatest, takeUntil } from 'rxjs';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss']
})
export class HelpButtonComponent implements OnInit, OnDestroy {
  @HostBinding('class.hidden') hidden = true;
  @Select(OrganizationState.isInriverServiceType) isInriver$: Observable<boolean>;
  @Select(OrganizationState.isAcquiaServiceType) isAcquia$: Observable<boolean>;
  unsubscribe$: Subject<void> = new Subject<void>();
  constructor() { }

  ngOnInit(): void {
    combineLatest([this.isAcquia$, this.isInriver$]).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(([isAcquia, isInriver]) => {
      this.hidden = isAcquia || isInriver
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
