import { Component, Input, OnInit } from '@angular/core';

export type ToolTipPlacement = 'left' | 'right';

export const ToolTipPlacement = {
    Left: 'left' as ToolTipPlacement,
    Right: 'right' as ToolTipPlacement
};

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input() text: string;
  @Input() showIcon = true;
  @Input() placement: ToolTipPlacement = ToolTipPlacement.Right;
  @Input() icon = 'question-circle';
  @Input() color = 'blue';

  ToolTipPlacement = ToolTipPlacement;

  constructor() { }

  ngOnInit(): void {
  }
}
