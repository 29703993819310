import { AfterViewInit, Component, ContentChildren, OnInit, QueryList } from '@angular/core';
import { RadioService } from '@app/shared/services/radio.service';
import { RadioComponent } from '../radio/radio.component';

@Component({
  selector: 'app-radio-parent',
  templateUrl: './radio-parent.component.html',
  styleUrls: ['./radio-parent.component.scss'],
  providers: [RadioService]
})
export class RadioParentComponent implements OnInit, AfterViewInit {
  @ContentChildren(RadioComponent, { descendants: true }) radioChildren!: QueryList<RadioComponent>;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.radioChildren.toArray().forEach(
      (radio: RadioComponent, index: number) => {
        radio.index = index;
      }
    );
  }

}
