/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserEventType } from './userEventType';
import { WorkflowTaskReference } from './workflowTaskReference';
import { OrganizationReference } from './organizationReference';
import { UserGroup } from './userGroup';


export interface UserEvent { 
    group?: UserGroup;
    id?: number;
    orgRefs?: Array<OrganizationReference>;
    type?: UserEventType;
    workflowTaskRef?: WorkflowTaskReference;
}

