import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-head',
  templateUrl: './modal-head.component.html',
  styleUrls: ['./modal-head.component.scss']
})
export class ModalHeadComponent implements OnInit {
  @HostBinding('class.center') @Input() center = false;

  constructor() { }

  ngOnInit(): void {
  }

}
