<a class="action" *ngIf="action" [title]="action.title"
  (click)="navigate($event, action)"
  [attr.href]="action.route && !action.selections ? '.' + action.route : null"
  [ngClass]="{ 'active': isActiveNavigation }">
  <div class="bullet">
    <div class="dot"></div>
  </div>
  <div class="icon" [ngClass]="{ 'text': !action.icon }">
    <!-- <ng-container *ngIf="(notificationCount$ | async) as count">
      <div class="notifications" *ngIf="count >= 1 && action.title === 'Release Notes'">
        <span class="count triple">
          <i class="fas fa-exclamation"></i>
        </span>
      </div>
    </ng-container> -->
    <ng-container *ngIf="action.icon">
      <i [ngClass]="'fas fa-'+ action.icon"></i>
    </ng-container>
    <ng-container *ngIf="!action.icon">
      <span>{{ action.title }}</span>
    </ng-container>
  </div>
  <div class="action-label">
    {{action.title }}
  </div>
</a>
