import { DataFlow, DataProcessBlock, DataProcessField, TradingPartnerAlignment } from '@app/core';

export class LoadDataFlowAction {
  static readonly type = '[Data Flow] Load Data Flow';
  constructor() { }
}

export class LoadDataFlowsAction {
  static readonly type = '[Data Flow] Load Data Flows';
  constructor() { }
}

export class LoadInboundDataFlowTemplateAction {
  static readonly type = '[Data Flow] Load Data Flow Inbound mapping Template';
  constructor() { }
}

export class LoadInboundAckDataFlowTemplateAction {
  static readonly type = '[Data Flow] Load Data Flow Inbound Ack mapping Template';
  constructor() { }
}

export class LoadOutboundDataFlowTemplateAction {
  static readonly type = '[Data Flow] Load Data Flow Outbound mapping Template';
  constructor() { }
}

export class LoadDestinationExportMappingAction {
  static readonly type = '[Data Flow] Load the Data Flow';
  constructor() { }
}

export class LoadDestinationExportInternalFieldSuggestionsAction {
  static readonly type = '[Data Flow] Load `Template Internal Fields` suggestions';
  constructor() { }
}

export class LoadSyndic8FieldSuggestionsAction {
  static readonly type = '[Data Flow] Load Syndicate Field suggestions';
  constructor() { }
}

export class SaveInboundTemplateFieldsAction {
  static readonly type = '[Data Flow] Save the Inbound Data Flow Template';
  constructor(public payload: { fields: Array<DataProcessField> }) { }
}

export class SaveInboundAckTemplateFieldsAction {
  static readonly type = '[Data Flow] Save the Inbound Ack Data Flow Template';
  constructor(public payload: { fields: Array<DataProcessField> }) { }
}

export class SaveOutboundTemplateFieldsAction {
  static readonly type = '[Data Flow] Save the Outbound Data Flow Template';
  constructor(public payload: { fields: Array<DataProcessField> }) { }
}

export class LoadDestinationExportMappingTPFieldListSuggestionsAction {
  static readonly type = '[Data Flow] Load the Data Flow Trading Partner Field List suggestions';
  constructor() { }
}


export class RemoveAllBrandTradingPartnerAlignmentsAction {
  static readonly type = '[Data Flow] Remove Brand TP Alignments';
  constructor(public payload: { columnName: string, internalColumnName: string }) { }
}

export class AddInboundTemplateRowAction {
  static readonly type = '[Data Flow] Add a new row after index to the current Export Mapping for Inbound';
  constructor(public payload: { index: number }) { }
}

export class AddInboundAckTemplateRowAction {
  static readonly type = '[Data Flow] Add a new row after index to the current Export Mapping for Inbound Ack';
  constructor(public payload: { index: number }) { }
}

export class AddOutboundTemplateRowAction {
  static readonly type = '[Data Flow] Add a new row after index to the current Export Mapping for Outbound';
  constructor(public payload: { index: number }) { }
}

export class AddOrSaveBrandTpAlignmentAction {
  static readonly type = '[Data Flow] Add or Save Brand TP Alignment';
  constructor(public payload: { alignment: TradingPartnerAlignment }) { }
}

export class RemoveBrandTpAlignmentAction {
  static readonly type = '[Data Flow] Remove a Brand TP Alignment';
  constructor(public payload: { tradingPartnerBrandId: number, columnName: string, internalColumnName: string }) { }
}

export class LoadBrandTpAlignmentsAction {
  static readonly type = '[Data Flow] Load Brand TP Alignments';
  constructor(public payload: { columnName: string, internalColumnName: string }) { }
}

export class ResetDataFlowsStateAction {
  static readonly type = '[Data Flow] Reset state';
  constructor() { }
}

export class UpdateDataFlowStatusAction {
  static readonly type = '[Data Flow] Update a status';
  constructor(public payload: { status?: string }) { }
}

export class LoadDataFlowAttachmentsAction {
  static readonly type = '[Data Flow] Load all attachments for the given Data Flow';
  constructor() { }
}

export class UploadDataFlowAttachmentAction {
  static readonly type = '[Data Flow] Upload an attachment';
  constructor(public payload: { file: File, fileName: string }) { }
}

export class DeleteDataFlowAttachmentAction {
  static readonly type = '[Data Flow] Delete an attachment';
  constructor(public payload: { docsId: number }) { }
}

export class UploadDataFlowFileAction {
  static readonly type = '[Data Flow] Upload a file for a Data Flow';
  constructor(public payload: { file: File, fileName: string }) { }
}

export class RunDataFlowInboundProcessAction {
  static readonly type = '[Data Flow] Run an inbound process';
  constructor() { }
}

export class RunDataFlowOutboundProcessAction {
  static readonly type = '[Data Flow] Run an outbound process';
  constructor() { }
}

export class AddDataFlowAction {
  static readonly type = '[Data Flow] Add a new dataflow';
  constructor(public payload: { dataFlow: any }) { }
}

export class UpdateDataFlowTemplateAction {
  static readonly type = '[Data Flow] Update the Data Flow template';

  constructor(public payload: {
    parentTemplateName: string,
    changeEntryList: any[],
    templateSheetName,
    currencyCodeOutput: string,
    languageCodePrimary: string,
    languageCodeOutput: string,
    inputDateFormat: string,
    forceParentAlignment: boolean,
    templateMap: {
      appendFile: string,
      clientKey: string,
      changeEntryList: string,
      fileExtension: string,
      fileKey: string,
      inputType: string,
      outputType: string,
      skipHeader: string,
      skipCatalogProduct: string,
      headerKey: string,
      messageType: string,
      messageTable: string,
      messageBlock: string,
      messageKey: string,
      messageFlow: string,
      sheetName: string,
      token: string,
    }
  }) {
  }
}

export class UpdateDataflowTemplateSheetAction {
  static readonly type = '[Data Flow] Update a Data Flow template sheet';
  constructor(public payload: { sheet: DataProcessBlock, index: number }) { }
}

export class AddDataFlowTemplateSheetAction {
  static readonly type = '[Data Flow] Add a new sheet to a Data Flow template';
  constructor(public payload: { fieldNames: Array<string>, sheetName: string }) { }
}

export class AddChangeEntryAction {
  static readonly type = '[Data Flow] Add a new change entry to a template';
  constructor(public payload: { changeEntry: string }) { }
}

export class RemoveDataFlowTemplateSheetAction {
  static readonly type = '[Data Flow] Remove a sheet from a Data Flow template';
  constructor() { }
}

export class LoadDataFlowInputDataAction {
  static readonly type = '[Data Flow] Load Data Flow Input';
  constructor() { }
}

export class LoadDataFlowInputAckDataAction {
  static readonly type = '[Data Flow] Load Data Flow Input';
  constructor() { }
}

export class MoveInboundTemplateRowAction {
  static readonly type = '[Data Flow] Move an Inbound Template row';
  constructor(public payload: { currentIndex: number, previousIndex: number }) { }
}

export class MoveInboundAckTemplateRowAction {
  static readonly type = '[Data Flow] Move an Inbound Template row';
  constructor(public payload: { currentIndex: number, previousIndex: number }) { }
}

export class MoveOutboundTemplateRowAction {
  static readonly type = '[Data Flow] Move an Outbound Template row';
  constructor(public payload: { currentIndex: number, previousIndex: number }) { }
}

export class SaveInboundMessagesAction {
  static readonly type = '[Data Flow] Save Inbound 3rd Party Messages';
  constructor(public payload: { type: string, table: string, block: string, key: string }) { }
}

export class SaveInboundSheetOrderAction {
  static readonly type = '[Data Flow] Save Inbound Sheet Order';
  constructor(public payload: { blockList: Array<DataProcessBlock>}) { }
}

export class SaveInboundAckMessagesAction {
  static readonly type = '[Data Flow] Save Inbound 3rd Party Messages';
  constructor(public payload: { type: string, table: string, block: string, key: string }) { }
}

export class SaveInboundAckSheetOrderAction {
  static readonly type = '[Data Flow] Save Inbound Ack Sheet Order';
  constructor(public payload: { blockList: Array<DataProcessBlock>}) { }
}

export class SaveOutboundMessagesAction {
  static readonly type = '[Data Flow] Save Outbound 3rd Party Messages';
  constructor(public payload: { type: string, table: string, block: string, key: string }) { }
}

export class SaveOutboundSheetOrderAction {
  static readonly type = '[Data Flow] Save Outbound Sheet Order';
  constructor(public payload: { blockList: Array<DataProcessBlock>}) { }
}

export class ToggleShowMessagesAction {
  static readonly type = '[Data Flow] Toggle state of Show Messages';
  constructor() { }
}

export class UpdateDataFlow {
  static readonly type = '[Data Flow] Update a Data Flow';
  constructor(public payload: { dataFlow?: DataFlow }) { }
}

export class ToggleShowSheetOrderAction {
  static readonly type = '[Data Flow] Toggle state of Show Sheet Order';
  constructor() { }
}

export class GetOutboundConnections {
  static readonly type = '[Data Flow] Get Outbound Connections';
  constructor() { }
}

export class SendConnectivityPassThruTestAction {
  static readonly type = '[Data Flow] Send Connectivity Pass Thru Test';
  constructor() { }
}

export class SendConnectivityTestAction {
  static readonly type = '[Data Flow] Send Connectivity Test';
  constructor() { }
}

export class UpdateTransferTypeAction {
  static readonly type = '[Data Flow] Update Transfer Type';
  constructor(public payload: { transferType: string }) { }
}

export class UpdateConnectionIdPasthru {
  static readonly type = '[Data Flow] Update Out Bound Connector';
  constructor(public payload: { connectionIdPassthru: number }) { }
}

export class CreateInboundTemplateAction {
  static readonly type = '[Data Flow] Create a new Inbound Template';
  constructor(public payload: { fieldNames: Array<string>, fileExtension: string, headerType: string, templateName: string, appendFile: string }) { }
}

export class AddInboundOutboundErrorRecord {
  static readonly type = '[Data Flow] Get error records of inbound/outbound process';
  constructor(public payload: { messageType?: string }) { }

}

export class ResetExternallyUpdatedFlag {
  static readonly type = '[Data Flow] Resets the flag that marks a map as being externally updated';
  constructor() { }
}
