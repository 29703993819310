<div class="segment">
  <div class="name">If</div>
  <app-suggestion *ngIf="!isConditionXPath" label="Enter or Choose an If value" [form]="form" field="condition" optionProp="id" (closed)="closedCondition()"
    (optionSelected)="selectCondition()" (onChange)="selectCondition($event)" [provided]="provided" [setTypeaheadIfNotFound]="true">
  </app-suggestion>
  <input *ngIf="isConditionXPath" placeholder="Enter an XPATH" [formControl]="form.get('condition')" (keyup)="selectCondition()" />
  <app-toggle leftText="Text/Variable" leftIcon="text" (selectedChange)="isConditionXPath = $event; selectCondition()" [selected]="isConditionXPath" rightText="XPATH" rightIcon="code"></app-toggle>
</div>
<div class="segment">
  <div class="name">==</div>
  <app-suggestion *ngIf="!isValueXPath" label="Enter or Choose an Equals value" [form]="form" field="value" optionProp="id" (closed)="closedValue()"
    (optionSelected)="selectValue()" (onChange)="selectValue($event)" [provided]="provided" [setTypeaheadIfNotFound]="true">
  </app-suggestion>
  <input *ngIf="isValueXPath" placeholder="Enter an XPATH" [formControl]="form.get('value')" (keyup)="selectValue()" />
  <app-toggle leftText="Text/Variable" leftIcon="text" (selectedChange)="isValueXPath = $event; selectValue()" [selected]="isValueXPath" rightText="XPATH" rightIcon="code"></app-toggle>
</div>
<div class="segment">
  <div class="name">Then</div>
  <app-suggestion *ngIf="!isIfThenXPath" label="Enter or Choose a Then value" [form]="form" field="ifThen" optionProp="id" (closed)="closedIfThen()"
    (optionSelected)="selectIfThen()" (onChange)="selectIfThen($event)" [provided]="provided" [setTypeaheadIfNotFound]="true">
  </app-suggestion>
  <input *ngIf="isIfThenXPath" placeholder="Enter an XPATH" [formControl]="form.get('ifThen')" (keyup)="selectIfThen()" />
  <app-toggle leftText="Text/Variable" leftIcon="text" (selectedChange)="isIfThenXPath = $event; selectIfThen()" [selected]="isIfThenXPath" rightText="XPATH" rightIcon="code"></app-toggle>
</div>
<div class="segment">
  <div class="name">Else</div>
  <app-suggestion *ngIf="!isIfElseXPath" label="Enter or Choose an Else value" [form]="form" field="ifElse" optionProp="id" (closed)="closedIfElse()"
    (optionSelected)="selectIfElse()" (onChange)="selectIfElse($event)" [provided]="provided" [setTypeaheadIfNotFound]="true">
  </app-suggestion>
  <input *ngIf="isIfElseXPath" placeholder="Enter an XPATH" [formControl]="form.get('ifElse')" (keyup)="selectIfElse()" />
  <app-toggle leftText="Text/Variable" leftIcon="text" (selectedChange)="isIfElseXPath = $event; selectIfElse()" [selected]="isIfElseXPath" rightText="XPATH" rightIcon="code"></app-toggle>
</div>
<app-if-then-else [wrap]="true" [convert]="convert"></app-if-then-else>
