import { Component, OnInit, Input } from '@angular/core';
import { Notification } from '@app/core/api';

@Component({
  selector: 'app-toast-progress',
  templateUrl: './toast-progress.component.html',
  styleUrls: ['./toast-progress.component.scss']
})
export class ToastProgressComponent implements OnInit {
  @Input() notification: Notification;

  constructor() { }

  ngOnInit() {
  }

}
