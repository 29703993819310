import { State, Action, StateContext, Actions, ofActionDispatched, Store, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { LogoutUserAction } from '@app/core';
import { ResetQueryStateAction, SetQueryAction } from './query-cache.actions';
import { patch } from '@ngxs/store/operators';
import { RouterStateSnapshot, Params } from '@angular/router';

export interface QueryCacheStateModel {
  queriesByRoute: { [key: string]: Params }; // Query Params by route URL
}

const defaults: QueryCacheStateModel = {
  queriesByRoute: {}
};

@State<QueryCacheStateModel>({
  name: 'queryCache',
  defaults
})
@Injectable()
export class QueryCacheState {

  constructor(
    private actions: Actions,
    private store: Store
  ) {
    // Reset Query state on User Logout
    this.actions.pipe(ofActionDispatched(LogoutUserAction)).subscribe(
      () => {
        this.store.dispatch(new ResetQueryStateAction());
      }
    );
  }

  static getQueries(route: RouterStateSnapshot) {
    return createSelector(
      [QueryCacheState],
      (state:  QueryCacheStateModel): { [key: string]: string } => {
        const url = route.url.toString().split('?')[0];
        const queries = state.queriesByRoute[url];
        if (queries) {
          return queries;
        }
        return undefined;
      }
    );
  }

  @Action(SetQueryAction)
  setQuery(
    { setState }: StateContext<QueryCacheStateModel>,
    { payload: { queryParams, routeUrl } }: SetQueryAction
  ) {
    if (queryParams?.state && queryParams?.selling_partner_id && queryParams?.mws_auth_token && queryParams?.spapi_oauth_code) {
      return;
    }
    const { page, ...withoutPage } = queryParams;
    const url = routeUrl.split('?')[0];
    setState(
      patch<QueryCacheStateModel>({
        queriesByRoute: patch({
          [url]: withoutPage
        })
      })
    );
  }

  @Action(ResetQueryStateAction)
  resetQueryState({ setState }: StateContext<QueryCacheStateModel>) {
    setState(defaults);
  }
}
