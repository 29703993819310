import { DataProcessBlock, DataProcessField, TradingPartnerAlignment } from "@app/core";
import { EditState } from "@app/shared/components/edit-lock/edit-lock.component";

export class LoadSyndic8FieldSuggestionsAction {
  static readonly type = '[Destination Export Mapping] Load Syndicate Field suggestions';
  constructor() { }
}

export class LoadDestinationExportMappingSuggestionsAction {
  static readonly type = '[Destination Export Mapping] Load the Destination Export Mapping suggestions';
  constructor() { }
}

export class LoadDestinationExportMappingTPFieldListSuggestionsAction {
  static readonly type = '[Destination Export Mapping] Load the Destination Export Mapping Trading Partner Field List suggestions';
  constructor() { }
}

export class LoadDestinationExportMappingTemplateParentListSuggestionsAction {
  static readonly type = '[Destination Export Mapping] Load the Destination Export Mapping Template Parent List suggestions';
  constructor() { }
}

export class LoadDestinationExportMappingAction {
  static readonly type = '[Destination Export Mapping] Load the Destination Export Mapping';
  constructor() { }
}

export class LoadDestinationExportMappingActionWithId {
  static readonly type = '[Destination Export Mapping] Load the Destination Export Mapping with provided TemplateId';
  constructor() { }
}

export class MoveDestinationExportMappingAction {
  static readonly type = '[Destination Export Mapping] Move a Destination Export Mapping row';
  constructor(public payload: { currentIndex: number, previousIndex: number }) { }
}

export class SaveDestinationExportMappingFieldsAction {
  static readonly type = '[Destination Export Mapping] Save the Destination Export Mapping to the backend';
  constructor(public payload: { fields: Array<DataProcessField> }) { }
}

export class ResetDestinationExportMappingAction {
  static readonly type = '[Destination Export Mapping] Reset state';
  constructor() { }
}

export class ResetDestinationExportMappingTemplateAction {
  static readonly type = '[Destination Export Mapping] Reset Export Mapping template';
  constructor() { }
}

export class CreateDestinationExportTemplateAction {
  static readonly type = '[Destination Export Mapping] Create a new Export Template';
  constructor(public payload: { fieldNames: Array<string>, comments: Array<string>, examples: Array<string>, fileExtension: string, headerType: string, templateName: string, parentTemplateName: string, appendFile: string, currencyCodeOutput: string, languageCodePrimary: string, languageCodeOutput: string  }) { }
}

export class DeleteDestinationExportMappingAction {
  static readonly type = '[Destination Export Mapping] Delete the Export Mapping for the current Destination';
  constructor() { }
}

export class SetDestinationExportMappingEditingAction {
  static readonly type = '[Destination Export Mapping] Set state of editing';
  constructor(public payload: { state: EditState }) { }
}

export class LoadDestinationExportInternalFieldSuggestionsAction {
  static readonly type = '[Destination Export Mapping] Load `Template Internal Fields` suggestions';
  constructor() { }
}

export class LoadBrandTpAlignmentsAction {
  static readonly type = '[Destination Export Mapping] Load Brand TP Alignments';
  constructor(public payload: { columnName: string, internalColumnName: string }) { }
}

export class ToggleShowMessagesAction {
  static readonly type = '[Destination Export Mapping] Toggle state of Show Messages';
  constructor() { }
}

export class ToggleShowOrderAction {
  static readonly type = '[Destination Export Mapping] Toggle state of Show Sheet Order Modal';
  constructor() { }
}

export class SaveMessagesAction {
  static readonly type = '[Destination Export Mapping] Save 3rd Party Messages';
  constructor(public payload: { type: string, table: string, block: string, key: string }) { }
}

export class SaveSheetOrderAction {
  static readonly type = '[Destination Export Mapping] Save Sheet Order Messages';
  constructor(public payload: { blockList: any }) { }
}

export class UpdateTemplateSheetAction {
  static readonly type = '[Destination Export Mapping] Update a Template sheet';
  constructor(public payload: { sheet: DataProcessBlock, index: number }) { }
}
export class RemoveAllBrandTradingPartnerAlignmentsAction {
  static readonly type = '[Destination Export Mapping] Remove Brand TP Alignments';
  constructor(public payload: { columnName: string, internalColumnName: string }) { }
}

export class AddOrSaveBrandTpAlignmentAction {
  static readonly type = '[Destination Export Mapping] Add or Save Brand TP Alignment';
  constructor(public payload: { alignment: TradingPartnerAlignment }) { }
}

export class RemoveBrandTpAlignmentAction {
  static readonly type = '[Destination Export Mapping] Remove a Brand TP Alignment';
  constructor(public payload: { tradingPartnerBrandId: number, columnName: string, internalColumnName: string }) { }
}
export class UpdateDestinationExportMappingTemplateAction {
  static readonly type = '[Destination Export Mapping] Update the Destination Export Mapping template';
  constructor(public payload: {
    parentTemplateName: string,
    changeEntryList: any[],
    templateSheetName,
    currencyCodeOutput: string,
    languageCodePrimary: string,
    languageCodeOutput: string,
    inputDateFormat : string,
    forceParentAlignment: boolean, 
    templateMap: {
      appendFile: string,
      clientKey: string,
      changeEntryList: string,
      fileExtension: string,
      fileKey: string,
      inputType: string,
      outputType: string,
      skipHeader: string,
      skipCatalogProduct: string,
      headerKey: string,
      messageType: string,
      messageTable: string,
      messageBlock: string,
      messageKey: string,
      messageFlow: string,
      sheetName: string,
      token: string,
    }
  }) {
  }
}

export class AddDestinationExportMappingTemplateSheetAction {
  static readonly type = '[Destination Export Mapping] Add a new sheet to a Destination Export Mapping template';
  constructor(public payload: { fieldNames: Array<string>, sheetName: string }) { }
}

export class RemoveDestinationExportMappingTemplateSheetAction {
  static readonly type = '[Destination Export Mapping] Remove a sheet from a Destination Export Mapping template';
  constructor() { }
}

export class UpdateDestinationExportMappingTemplateSheetAction {
  static readonly type = '[Data Flow] Update a Data Flow template sheet';
  constructor(public payload: { sheet: DataProcessBlock, index: number }) { }
}

export class AddDestinationExportMappingTemplateRowAction {
  static readonly type = '[Destination Export Mapping] Add a new row after index to the current Export Mapping';
  constructor(public payload: { index: number }) { }
}

export class RunAiBot {
  static readonly type = '[Destination Export Mapping] Run Auto Field Map for the selected template';
  constructor(public payload: { runAiBot: boolean }) { }
}
export class GobackToAISummary {
  static readonly type = '[Destination Export Mapping] show AI summary view';
  constructor(public payload: { goBackToAISummary: boolean }) { }
}

export class LoadCurrencyCodeSuggestions {
  static readonly type = '[Destination Export Mapping] Load Currency Code Suggestions';
  constructor() { }
}

export class LoadLanguageCodeSuggestions {
  static readonly type = '[Destination Export Mapping] Load Language Code Suggestions';
  constructor() { }
}
