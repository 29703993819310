<div class="product" [ngClass]="{'changed': changed}">
  <div class="flex aligned">
    <div class="grow"></div>
    <div class="drill-in">
      <div class="link" (click)="navigateToProduct()" *ngIf="showLinkToProduct">
        View details &#x2192;
      </div>
    </div>
  </div>
  <div class="changed-message" *ngIf="changed">
    <ng-container *ngIf="isStyle">Some SKUs/UPCs may have different dates of syndication</ng-container>
    <ng-container *ngIf="isSKU">Some UPCs may have different dates of syndication</ng-container>
  </div>
  <div class="hero">
    <img *ngIf="this.activeSku?.heroImage?.uriPath as imageUri; else noImage" [src]="imageUri">
    <ng-template #noImage>
      <i class="fad fa-image"></i>
    </ng-template>
  </div>

  <div class="style-name" *ngIf="product.productName">{{ product.productName }}</div>
  <div class="style-name" *ngIf="product.customGroup">{{ product.customGroup }}</div>
  <div class="style-name" *ngIf="product.destinationName">{{ product.destinationName }}</div>

  <div class="description">{{ product.productType?.name }}</div>
  <div class="description" *ngIf="activeAplus">A+ {{ activeAplus?.aplusStatus || 'Not Submitted' }}</div>
  {{ activeSku?.sku }}
  <div class="chip-list">
    <ng-container *ngFor="let sku of product.skus; index as i">
      <div class="chip" *ngIf="i < 8" (mouseover)="swatchMouseIn(sku)"
        [ngClass]="{'active': sku.sku === activeSku?.sku}">
        {{ sku.sku }} - {{ sku.skuData.color || 'N/A' }}
      </div>
    </ng-container>
    <div class="view-all" *ngIf="product?.skus?.length> 8">
      {{ product.skus?.length - 8 }} more &#x2192;
      <div class="hover-box">
        <ng-container *ngFor="let sku of product?.skus; index as i">
          <div class="chip" *ngIf="i >= 8" (mouseover)="swatchMouseIn(product, sku)" [ngClass]="{'active': sku.sku === activeSku?.sku}">
            {{ sku?.sku }} - {{ sku.skuData?.color || 'N/A' }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="size-list">
    <ng-container *ngFor="let size of activeSku?.sizes">
      <div class="size" *ngIf="size">
        {{ size }}
      </div>
    </ng-container>
  </div>

  <div class="price" *ngIf="getPriceRangeForSku() as pricing">
    <ng-container *ngIf="pricing.low; else noLowPrice">
        <div class="price-label">MSRP</div>
        ${{ pricing?.low }}
    </ng-container>
    <ng-template #noLowPrice>N/A </ng-template>
    <span *ngIf="pricing?.range">
      <ng-container *ngIf="pricing?.high; else noHighPrice">- ${{ pricing?.high }}</ng-container>
      <ng-template #noHighPrice>- N/A</ng-template>
    </span>
  </div>
</div>
