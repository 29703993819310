import { Component, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { WizardPageComponent } from '../wizard-page/wizard-page.component';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements AfterContentInit {
  @ContentChildren(WizardPageComponent) pages: QueryList<WizardPageComponent>;
  activePageNumber: number;

  ngAfterContentInit() {
    this.pages.forEach(pageInstance => console.log(pageInstance));
    const firstPage = this.pages.toArray().filter(page => page.pageNumber === 1)[0];
    firstPage.active = true;
    this.activePageNumber = 1;
  }

  showPage(page: WizardPageComponent) {
    this.pages.forEach(pageInstance => pageInstance.active = false);
    page.active = true;
    this.activePageNumber = page.pageNumber;
  }

  showNextPage() {
    if (this.activePageNumber < this.pages.length) {
      const nextPage = this.pages.toArray().filter(page => page.pageNumber === (this.activePageNumber + 1))[0];
      this.showPage(nextPage);
    }
  }

  showPreviousPage() {
    if (this.activePageNumber > 1) {
      const previousPage = this.pages.toArray().filter(page => page.pageNumber === (this.activePageNumber - 1))[0];
      this.showPage(previousPage);
    }
  }
}
