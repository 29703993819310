import { Component, OnInit, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { Suggestion } from '@app/core';
import { FunctionType } from '../../../services/template.service';
import { Chip, ChipType } from '../destination-chip/destination-chip.component';

@Component({
  selector: 'app-concatenation',
  templateUrl: './concatenation.component.html',
  styleUrls: ['./concatenation.component.scss']
})
export class ConcatenationComponent implements OnInit {
  @Input() set suggestions(suggestions: Array<Suggestion>) {
    this._suggestions = suggestions;
    if (this.chips && this.chips.length > 0 && suggestions && suggestions.length > 0) {
      this.chips = this.chips.map(c => {
        if (c.type === ChipType.Variable) {
          const functionName = this.functionNames.find(f => f === c.value.substring(0, f.length));
          const prefix = functionName ? functionName : '';
          const display: string = suggestions.find(s => s.id === `COLUMNNAME:${c.value.substring(prefix.length, c.value.length)}`)?.name;
          return { ...c, display: `${prefix}${display ? display : c.value}` };
        }
        return c;
      });
    }
  }
  @Input() set concatenation(s: string) {
    this.chips = [];
    if (!s) {
      return;
    }
    let str = s.replace(this.functionType, '');
    while (str.length > 0) {
      // Find the next instance of an inerpolated variable.
      let functionIndex = -1;
      let variableToken = this.variableTokens[0];
      let lastVariableToken = str.length - 1;
      this.variableTokens.forEach(token => {
        const index = str.indexOf(`$${token}{`);

        if (index > -1 && index < lastVariableToken) {
          variableToken = token;
        }
      });
      this.functionNames.forEach(name => {
        functionIndex = str.indexOf(`${name}$${variableToken}{`);
      });
      const nextVarStartIndex = str.indexOf(`$${variableToken}{`);
      // If there's plain text before it, add it to the chips array as a literal
      if (functionIndex !== 0 && (nextVarStartIndex > 0 || nextVarStartIndex === -1)) {
        const nextVar = functionIndex > -1 ? functionIndex : nextVarStartIndex;
        const value: string = str
          .slice(0, nextVar === -1 ? str.length : nextVar)
          .split('')
          .map(st => {
            if (st === ' ') {
              return '[space]';
            }
            return st;
          })
          .join('');
        this.chips.push({ type: ChipType.Literal, value });
        str = str.slice(nextVar, str.length + nextVar);
      } else {
        const nextVarEndIndex = str.indexOf('}');
        const functionName = this.functionNames.find(f => f === str.substring(0, f.length));
        const prefix = functionName ? functionName : '';
        const value = str.slice(nextVarStartIndex + 3, nextVarEndIndex);
        if (this._suggestions && this._suggestions.length > 0) {
          const display: string = this._suggestions.find(sugg => sugg.id === `COLUMNNAME:${value}`)?.name;
          this.chips.push({ type: ChipType.Variable, value: `${variableToken === 'X' ? 'XPATH:' : ''}${prefix}${value}`, display: `${variableToken === 'X' ? 'XPATH:' : ''}${prefix}${display ? display : value}` });
        } else {
          this.chips.push({ type: ChipType.Variable, value: `${variableToken === 'X' ? 'XPATH:' : ''}${prefix}${value}` });
        }
        str = str.slice(nextVarEndIndex + 1, str.length);
      }
    }
  }
  @Output() concatenationChange: EventEmitter<string> = new EventEmitter();
  @Input() showDeleteButtons = false;
  @Output() chipClick: EventEmitter<Chip> = new EventEmitter();
  @Input() functionType = FunctionType.Concat;

  @HostBinding('class.wrap') @Input() wrap = false;

  private functionNames: Array<string> = ['MAP:', 'VALIDVALUE:'];
  private variableTokens: Array<string> = ['D', 'X', 'V'];

  _suggestions: Array<Suggestion>;
  chips: Array<Chip> = [];

  ChipType = ChipType;

  constructor() { }

  ngOnInit(): void {
  }

  deleteSegment(index: number): void {
    this.chips = [...this.chips.slice(0, index), ...this.chips.slice(index + 1)];
    const newConcat: string = this.chips.reduce(
      (acc, seg) => {
        if (seg.type === ChipType.Variable) {
          return `${acc}\$D{${seg.value}\}`;
        }
        return `${acc}${seg.value}`;
      },
      this.functionType
    );
    this.concatenationChange.emit(newConcat);
  }
}
