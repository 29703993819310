import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateComponent), multi: true }]
})
export class DateComponent implements ControlValueAccessor {

  dateString: string;

  onChange: (date: number) => void;

  onTouched: (date: number) => void;

  writeValue(date: string): void {
    if (date) {
      if (!isNaN(parseInt(date, null))) {
        this.dateString = moment(parseInt(date, null)).utcOffset(0).format(moment.HTML5_FMT.DATE);
      } else {
        this.dateString = moment(date).utcOffset(0).format(moment.HTML5_FMT.DATE);
      }
    } else {
      this.dateString = date;
    }
  }

  registerOnChange(fn: (date: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (date: number) => void): void {
    this.onTouched = fn;
  }

  changed(): void {
    const date = moment(this.dateString).utcOffset(0).valueOf();
    this.onChange(date);
  }

  touch(): void {
    if (this.onTouched) {
      const date = moment(this.dateString).utcOffset(0).valueOf();
      this.onTouched(date);
    }
  }
}
