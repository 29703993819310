/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationReference } from './organizationReference';
import { WorkflowStatus } from './workflowStatus';


export interface Pricing { 
    active?: boolean;
    cancelDate?: string;
    cartOnlyAllowed?: boolean;
    clearance?: number;
    clearanceAllowed?: boolean;
    clearanceBase?: number;
    clearanceReporting?: number;
    contractId?: number;
    destinationRef?: OrganizationReference;
    directMailAllowed?: boolean;
    distributionType?: string;
    effectiveDate?: string;
    emailAllowed?: boolean;
    grossMargin?: number;
    grossMarginBase?: number;
    grossMarginReporting?: number;
    id?: number;
    isoCurrencyCode?: string;
    isoCurrencyCodeBase?: string;
    isoCurrencyCodeFrom?: string;
    isoCurrencyCodeReporting?: string;
    jsonData?: { [key: string]: any; };
    listPrice?: number;
    listPriceBase?: number;
    listPriceFuture1?: number;
    listPriceFuture2?: number;
    listPriceFutureDate1?: string;
    listPriceFutureDate2?: string;
    listPriceReporting?: number;
    map?: number;
    mapBase?: number;
    mapFuture1?: number;
    mapFuture2?: number;
    mapFutureDate1?: string;
    mapFutureDate2?: string;
    mapReporting?: number;
    mapWindowAllowed?: boolean;
    minMargin?: number;
    minMarginBase?: number;
    minMarginReporting?: number;
    msrp?: number;
    msrpBase?: number;
    msrpFuture1?: number;
    msrpFuture2?: number;
    msrpFutureDate1?: string;
    msrpFutureDate2?: string;
    msrpReporting?: number;
    netMargin?: number;
    netMarginBase?: number;
    netMarginReporting?: number;
    netRetailPrice?: number;
    netWholesaleCost?: number;
    platformCost?: number;
    platformCostBase?: number;
    platformCostReporting?: number;
    priceBand?: string;
    productCost?: number;
    productId?: number;
    productName?: string;
    profitAmount?: number;
    profitAmountStatus?: string;
    profitPercent?: number;
    profitPercentStatus?: string;
    promoType?: string;
    promotionalPrice?: number;
    retailCost?: number;
    retailCostBase?: number;
    retailCostReporting?: number;
    retailPrice?: number;
    retailPriceBase?: number;
    retailPriceLowest?: number;
    retailPriceLowestBase?: number;
    retailPriceLowestReporting?: number;
    retailPriceReporting?: number;
    seasonGroup?: string;
    siteWideAllowed?: boolean;
    sku?: string;
    subCatalogId?: number;
    upc?: string;
    wholesaleCost?: number;
    wholesaleCostBase?: number;
    wholesaleCostFuture1?: number;
    wholesaleCostFuture2?: number;
    wholesaleCostFutureDate1?: string;
    wholesaleCostFutureDate2?: string;
    wholesaleCostOrig?: number;
    wholesaleCostOverride?: number;
    wholesaleCostOverrideBase?: number;
    wholesaleCostOverrideReporting?: number;
    wholesaleCostReporting?: number;
    workflowStatus?: WorkflowStatus;
}

