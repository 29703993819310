import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import { InfiniteScrollService } from '../../../services/infinite-scroll.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-infinite-scroll-custom',
  templateUrl: './infinite-scroll-custom.component.html',
  styleUrls: ['./infinite-scroll-custom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfiniteScrollCustomComponent implements OnInit {
  @Input() row: { [key: string]: any };

  selected$: Observable<boolean> = of(false);

  @HostListener('click')
  click() {
    this.toggleSelect();
  }

  constructor(private scrollSvc: InfiniteScrollService) { }

  get selected(): boolean {
    return this.scrollSvc.isRowSelected(this.row);
  }

  ngOnInit(): void {
    this.selected$ = this.scrollSvc.isSelected$.call(this.scrollSvc, this.row);
  }

  toggleSelect(): void {
    if (this.scrollSvc.isRowSelected(this.row)) {
      this.scrollSvc.deselectRow(this.row);
    } else {
      this.scrollSvc.selectRow(this.row);
    }
  }
}
