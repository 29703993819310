import { Component, OnInit, Output, HostBinding, Input, EventEmitter, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements OnInit {
  @Input() showClose = false;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Input() height = 450;
  @Input() width = 600;
  @Input() wrap = 'nowrap';
  @Input() overflow = 'inherit';
  @Input() padding = '15px 0px';
  @Input() border_radius = '10px';
  @Input() darkTheme = false;
  @Input() dangerTheme = false;
  @Input() closeOnOutsideClick = true;
 
  @Output() closeModalMessage: EventEmitter<any> = new EventEmitter();


  @HostBinding('class.show') @Input() open = false;
  @HostBinding('class.backdrop') @Input() backdrop = true;

  get _height(): string {
    return this.height.toString().endsWith('%') ? this.height.toString() : `${this.height.toString()}px`;
  }

  get _width(): string {
    return this.width.toString().endsWith('%') ? this.width.toString() : `${this.width.toString()}px`;
  }

  insideClick = false;

  @HostListener('click')
  click() {
    if (this.closeOnOutsideClick) {
      if (!this.insideClick) {
        this.closeModal();
        this.closeModalMessage.emit(true);
      }
      this.insideClick = false;
    }
  }
  @HostListener('window:keydown', ['$event'])
  escape(event: KeyboardEvent) {
    if (this.open && (event.key === 'Esc' || event.key === 'Escape')) {
      this.closeModal();
    }
  }

  constructor() { }

  ngOnInit() {
  }

  closeModal() {
    this.openChange.emit(false);
    this.close.emit();
    this.closeModalMessage.emit(true);
  }
}
