import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExceptionService } from '@app/core/services/exception.service';
import { DynamicStyleLoaderService } from './dynamic-sytle-loader.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {
  private eventListener: (event: MessageEvent) => void;
  displayProperty: string = '';
  constructor(private router: Router, private route: ActivatedRoute, private exception: ExceptionService, private dynamicStyleLoader: DynamicStyleLoaderService,
    private ngZone: NgZone) { }

  ngOnInit() {
    /**
    Two different solutions : 
    1 - Css override using window.postMessage event across domains
    2 - I came across an alternative solution for our mutual goal which is to hide the 'Signing in' message when logging in, since Acquia only wants to hide that text, instead of doing the css override I can just detect if the platform is being loaded from an iframe or not basically manipulating the window object, I applied some logic inside the callback component that lets me know if the platform is being run from within contents of an iframe, if so , using the NgStyle directive, I can basically hide or show the element based upon that.
    */

    /* Acquia iframe alternative solution to hide text elements  [V2-8571] */

    /*************************************************/
    const iframeContent = this.inIframe();
    if (iframeContent == true) {
      this.displayProperty = 'none'
    } else {
      this.displayProperty = 'flex'
      // nothing, not coming from an iframe or frame
    }
    console.log('iframeContent=>', iframeContent)

    /*************************************************/

    /* Acquia iframe css override solution to hide login elements - [V2-8571] - Jira ticket] */

    /*************************************************/
    this.eventListener = (event: MessageEvent) => {

      // Checking the origin of the message for security
      if (event?.origin.indexOf("acquia") === -1 || event?.origin.indexOf("syndic8") === -1 ) {
        console.log('untrusted origins')
        return 'message from untrusted origins'; // Ignore messages from untrusted origins
      }
      // Assuming the message contains a URL to the CSS file, listening to the message in the window object
      if (event?.data) {
        console.log('event has data and got triggered')
        this.displayProperty = 'none'
        // Run inside Angular's zone to trigger change detection if necessary
        // this.ngZone.run(() => {
        //   this.dynamicStyleLoader.loadStyle(event.data.url);
        //   event.source.postMessage('css-added');
        // });
      } else {
        console.log('event has no data but got triggered')
      }
    };
    window?.addEventListener('message', this.eventListener);
    window?.parent?.postMessage("init-done", "*"); // Event Listener has been added

    /*************************************************/

    const { queryParams } = this.route.snapshot;
    if (queryParams['error']) {
      this.exception.add({
        name: 'CallbackComponent',
        info: queryParams['error_description'],
        error: queryParams['state'] ? queryParams['state'] : queryParams['error_description']
      });
      this.router.navigate(['/error']);
    } else if (!queryParams['code'] || !queryParams['state']) {
      this.router.navigate(['/'], { queryParams: { default: true } });
    }
  }

  /* window.self !== window.top returns true when run from within contents of frame, or iframe */
  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

}
