import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'beautifyUpperCase'
})
export class BeautifyUpperCasePipe implements PipeTransform {
  transform(input: string): string {
    input = input.toLowerCase();
    input = input.charAt(0).toUpperCase() + input.slice(1);
    return input;
  }
}
