<div class="options selected">
  <div *ngFor="let tab of categories"
    class="option"
    routerLink
    routerLinkActive="active"
    [queryParams]="{ 'category': tab.groupBy }">
      {{ tab.displayName }}
  </div>
</div>
<div class="options">
  <div *ngFor="let tab of categories"
    class="option"
    (click)="navigate()"
    routerLink="."
    routerLinkActive="active"
    [queryParams]="{ 'category': tab.groupBy }"
    queryParamsHandling="merge">
      {{ tab.displayName }}
  </div>
</div>
