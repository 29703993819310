<div class="segment">
  <div class="name">Table</div>
  <app-suggestion label="Choose a Table" (optionSelected)="selectTable($event)" include="Dynamic Tables" [filters]="{
    by: ['destinationId'],
    join: [FilterJoin.AND],
    match: [FilterMatch.IS],
    value: [destinationId]
  }"></app-suggestion>
</div>
<div class="segment">
  <div class="name">Rule</div>
  <app-suggestion label="Choose a Rule" (optionSelected)="selectRule($event)" [provided]="ruleSuggestions"></app-suggestion>
</div>
<div class="segment" *ngIf="rule">
  <div class="name">Keys</div>
  <div class="filler"></div>
</div>
<div class="segment" *ngIf="rule">
  <div class="key" *ngFor="let key of keySuggestions; index as i">
    <div class="name">{{ key.name }}</div>
    <app-suggestion *ngIf="!key.isXPath" label="Enter or Choose a Key" (optionSelected)="selectKey(key, $event, i)"
      [provided]="provided" (onChange)="selectKey(key, $event, i)">
    </app-suggestion>
    <input *ngIf="key.isXPath" placeholder="Enter an XPATH" (keyup)="selectKey(key, $event.target.value, i)" />
    <app-toggle leftText="Text/Variable" leftIcon="text" [selected]="key.isXPath" (selectedChange)="key.isXPath = $event; removeKey(i)" rightText="XPATH" rightIcon="code"></app-toggle>
  </div>
</div>
<app-reference-table [wrap]="true" [convert]="convert"></app-reference-table>
