<select class="sort-select" [ngClass]="{'placeholder': !sortByForm.value }" [formControl]="sortByForm"
  (change)="onSelectedSortChange(sortByForm.value)">
  <option value="" selected>Select sort</option>
  <ng-container *ngFor="let queryFilter of queryFilters">
    <option *ngIf="!queryFilter.hidden" [value]="queryFilter.propertyName">
      {{ queryFilter.displayName }}
    </option>
  </ng-container>
</select>
<div class="sort-direction" [ngClass]="{'ascending': sortAscending}" (click)="toggleSortDirection()"
  [title]="sortAscending ? 'Ascending' : 'Descending'">
  <i class="far fa-long-arrow-up"></i>
</div>
