import { RouteReuseStrategy, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: {}): void { }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): {} {
    return null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.data.noReuse) {
      return false;
    }
    return future.routeConfig === curr.routeConfig;
  }
}
