import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-help-panel',
  templateUrl: './help-panel.component.html',
  styleUrls: ['./help-panel.component.scss']
})
export class HelpPanelComponent implements OnInit, OnDestroy {

  @Input() open: boolean = false;
  @Input() options: any;
  @Output() onCloseClicked: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('videoPlayer') videoplayer: ElementRef;

  searchFormSub: Subscription;
  searchForm: FormControl = new FormControl();
  showVideoPlayer: boolean = false;
  videoName: string;
  videoType: string;

  allFAQs = [];
  filteredFAQs: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.allFAQs = this.options?.faqs || [];
    this.filteredFAQs = [...this.allFAQs];
    this.searchFormSub = this.searchForm.valueChanges.subscribe(v => {
      this.filteredFAQs = [...this.allFAQs].filter(f => {
        return f.keywords?.join(',').toLowerCase().includes(v.toLowerCase());
      })
    });
  }

  openVideoPlayer(videoName, videoType) {
    this.videoType = videoType || 'video/mp4';
    this.videoName = videoName;
    this.showVideoPlayer = true;
  }

  closeVideoPlayer() {
    this.videoplayer.nativeElement.pause();
    this.showVideoPlayer = false;
    this.videoName = null;
  }

  ngOnDestroy(): void {
    if (this.searchFormSub) {
      this.searchFormSub.unsubscribe();
    }
  }
}
