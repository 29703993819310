<ng-container *ngIf="chipString; else noChipString">
  <ng-container *ngFor="let chip of chips; index as i">
    <div class="chip" [ngClass]="{ 'literal': chip?.type === MediaChipType.Literal, 'variable': chip?.type === MediaChipType.Variable }">
      <ng-container *ngIf="chip?.value === ' '; else notSpace">[space]</ng-container>
      <ng-template #notSpace>{{ chip?.value }}</ng-template>
      <div class="remove" *ngIf="showRemove" (click)="removeChip(i)">
        <i class="far fa-times"></i>
      </div>
    </div>
    <div class="plus" *ngIf="i < chips.length - 1">+</div>
  </ng-container>
</ng-container>
<ng-template #noChipString>
  <i>None</i>
</ng-template>
