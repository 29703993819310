/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AuditUpdate } from '../model/models';
import { BatchLoadStatus } from '../model/models';
import { BulkStatus } from '../model/models';
import { BulkUpdate } from '../model/models';
import { FilterDefinition } from '../model/models';
import { FilterJoin } from '../model/models';
import { FilterMatch } from '../model/models';
import { GroupBy } from '../model/models';
import { InlineResponse200 } from '../model/models';
import { OrderBy } from '../model/models';
import { OrganizationReference } from '../model/models';
import { Product } from '../model/models';
import { ProductRelationship } from '../model/models';
import { Suggestion } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ProductApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Add a Product to an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param product A new Product to add
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProduct(orgId: number, product: Product, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Product>;
    public addProduct(orgId: number, product: Product, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Product>>;
    public addProduct(orgId: number, product: Product, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Product>>;
    public addProduct(orgId: number, product: Product, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling addProduct.');
        }
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling addProduct.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Product>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product`,
            product,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a Product Relationship to an Organization
     * @param groupBy Sort data response by Field, ascending or descending
     * @param orgId An Organization ID number used to indicate the current org context
     * @param productId A Product ID string
     * @param productRelationship A new Product Relationship to add
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProductRelById(groupBy: GroupBy, orgId: number, productId: string, productRelationship: ProductRelationship, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductRelationship>;
    public addProductRelById(groupBy: GroupBy, orgId: number, productId: string, productRelationship: ProductRelationship, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductRelationship>>;
    public addProductRelById(groupBy: GroupBy, orgId: number, productId: string, productRelationship: ProductRelationship, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductRelationship>>;
    public addProductRelById(groupBy: GroupBy, orgId: number, productId: string, productRelationship: ProductRelationship, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (groupBy === null || groupBy === undefined) {
            throw new Error('Required parameter groupBy was null or undefined when calling addProductRelById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling addProductRelById.');
        }
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling addProductRelById.');
        }
        if (productRelationship === null || productRelationship === undefined) {
            throw new Error('Required parameter productRelationship was null or undefined when calling addProductRelById.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (groupBy !== undefined && groupBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>groupBy, 'groupBy');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ProductRelationship>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product/${encodeURIComponent(String(productId))}/rel`,
            productRelationship,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export a Data Audit for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterDefinition A filter to filter Data Audit by
     * @param destinationTemplate 
     * @param from 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportDataAudit(orgId: number, filterDefinition: FilterDefinition, destinationTemplate?: string, from?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse200>;
    public exportDataAudit(orgId: number, filterDefinition: FilterDefinition, destinationTemplate?: string, from?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse200>>;
    public exportDataAudit(orgId: number, filterDefinition: FilterDefinition, destinationTemplate?: string, from?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse200>>;
    public exportDataAudit(orgId: number, filterDefinition: FilterDefinition, destinationTemplate?: string, from?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling exportDataAudit.');
        }
        if (filterDefinition === null || filterDefinition === undefined) {
            throw new Error('Required parameter filterDefinition was null or undefined when calling exportDataAudit.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (destinationTemplate !== undefined && destinationTemplate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>destinationTemplate, 'destinationTemplate');
        }
        if (from !== undefined && from !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>from, 'from');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<InlineResponse200>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/audit/data/export`,
            filterDefinition,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export a Data Audit for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterDefinition A filter to filter Data Audit by
     * @param destinationTemplate 
     * @param from 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportDataAuditDownload(orgId: number, filterDefinition: FilterDefinition, destinationTemplate?: string, from?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<Blob>;
    public exportDataAuditDownload(orgId: number, filterDefinition: FilterDefinition, destinationTemplate?: string, from?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<HttpResponse<Blob>>;
    public exportDataAuditDownload(orgId: number, filterDefinition: FilterDefinition, destinationTemplate?: string, from?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<HttpEvent<Blob>>;
    public exportDataAuditDownload(orgId: number, filterDefinition: FilterDefinition, destinationTemplate?: string, from?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling exportDataAuditDownload.');
        }
        if (filterDefinition === null || filterDefinition === undefined) {
            throw new Error('Required parameter filterDefinition was null or undefined when calling exportDataAuditDownload.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (destinationTemplate !== undefined && destinationTemplate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>destinationTemplate, 'destinationTemplate');
        }
        if (from !== undefined && from !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>from, 'from');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/audit/data/export/download`,
            filterDefinition,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download an exported list of Images
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterBy Property in data to filter by, e.g. &#x60;description&#x60; or &#x60;owner.firstName&#x60;
     * @param filterMatch Operator to match filterBy to filter value
     * @param filterValue Value to match with in the filterBy
     * @param orderBy Order to sort by, ascending or descending
     * @param sortBy Property to sort by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportImages(orgId: number, filterBy?: Array<string>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/json'}): Observable<Blob>;
    public exportImages(orgId: number, filterBy?: Array<string>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/json'}): Observable<HttpResponse<Blob>>;
    public exportImages(orgId: number, filterBy?: Array<string>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/json'}): Observable<HttpEvent<Blob>>;
    public exportImages(orgId: number, filterBy?: Array<string>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling exportImages.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterBy) {
            filterBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterBy');
            })
        }
        if (filterMatch) {
            filterMatch.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterMatch');
            })
        }
        if (filterValue) {
            filterValue.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterValue');
            })
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy');
            })
        }
        if (sortBy) {
            sortBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sortBy');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/csv',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/image/export`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Exports required and recommended fields
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterDefinition A filter to filter Data Audit by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportImportFields(orgId: number, filterDefinition: FilterDefinition, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<Blob>;
    public exportImportFields(orgId: number, filterDefinition: FilterDefinition, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<HttpResponse<Blob>>;
    public exportImportFields(orgId: number, filterDefinition: FilterDefinition, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<HttpEvent<Blob>>;
    public exportImportFields(orgId: number, filterDefinition: FilterDefinition, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling exportImportFields.');
        }
        if (filterDefinition === null || filterDefinition === undefined) {
            throw new Error('Required parameter filterDefinition was null or undefined when calling exportImportFields.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/audit/data/importfields/export`,
            filterDefinition,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download an exported list of Products
     * @param groupBy Sort data response by Field, ascending or descending
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterBy Property in data to filter by, e.g. &#x60;description&#x60; or &#x60;owner.firstName&#x60;
     * @param filterMatch Operator to match filterBy to filter value
     * @param filterValue Value to match with in the filterBy
     * @param orderBy Order to sort by, ascending or descending
     * @param sortBy Property to sort by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportProducts(groupBy: GroupBy, orgId: number, filterBy?: Array<string>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/json'}): Observable<Blob>;
    public exportProducts(groupBy: GroupBy, orgId: number, filterBy?: Array<string>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/json'}): Observable<HttpResponse<Blob>>;
    public exportProducts(groupBy: GroupBy, orgId: number, filterBy?: Array<string>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/json'}): Observable<HttpEvent<Blob>>;
    public exportProducts(groupBy: GroupBy, orgId: number, filterBy?: Array<string>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/json'}): Observable<any> {
        if (groupBy === null || groupBy === undefined) {
            throw new Error('Required parameter groupBy was null or undefined when calling exportProducts.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling exportProducts.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterBy) {
            filterBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterBy');
            })
        }
        if (filterMatch) {
            filterMatch.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterMatch');
            })
        }
        if (filterValue) {
            filterValue.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterValue');
            })
        }
        if (groupBy !== undefined && groupBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>groupBy, 'groupBy');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy');
            })
        }
        if (sortBy) {
            sortBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sortBy');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/csv',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product/export`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export a Schema Audit for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterDefinition A filter to filter Schema Audit by
     * @param from 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportSchemaAudit(orgId: number, filterDefinition: FilterDefinition, from?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse200>;
    public exportSchemaAudit(orgId: number, filterDefinition: FilterDefinition, from?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse200>>;
    public exportSchemaAudit(orgId: number, filterDefinition: FilterDefinition, from?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse200>>;
    public exportSchemaAudit(orgId: number, filterDefinition: FilterDefinition, from?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling exportSchemaAudit.');
        }
        if (filterDefinition === null || filterDefinition === undefined) {
            throw new Error('Required parameter filterDefinition was null or undefined when calling exportSchemaAudit.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (from !== undefined && from !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>from, 'from');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<InlineResponse200>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/audit/schema/export`,
            filterDefinition,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export a Schema Audit for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterDefinition A filter to filter Data Audit by
     * @param from 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportSchemaAuditDownload(orgId: number, filterDefinition: FilterDefinition, from?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<Blob>;
    public exportSchemaAuditDownload(orgId: number, filterDefinition: FilterDefinition, from?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<HttpResponse<Blob>>;
    public exportSchemaAuditDownload(orgId: number, filterDefinition: FilterDefinition, from?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<HttpEvent<Blob>>;
    public exportSchemaAuditDownload(orgId: number, filterDefinition: FilterDefinition, from?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling exportSchemaAuditDownload.');
        }
        if (filterDefinition === null || filterDefinition === undefined) {
            throw new Error('Required parameter filterDefinition was null or undefined when calling exportSchemaAuditDownload.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (from !== undefined && from !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>from, 'from');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/audit/schema/export/download`,
            filterDefinition,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * A list of Amazon product types
     * @param orgId An Organization ID number used to indicate the current org context
     * @param destinationId 
     * @param productType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAmazonProductTypeSuggestions(orgId: number, destinationId?: number, productType?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Suggestion>;
    public getAmazonProductTypeSuggestions(orgId: number, destinationId?: number, productType?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Suggestion>>;
    public getAmazonProductTypeSuggestions(orgId: number, destinationId?: number, productType?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Suggestion>>;
    public getAmazonProductTypeSuggestions(orgId: number, destinationId?: number, productType?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getAmazonProductTypeSuggestions.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (destinationId !== undefined && destinationId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>destinationId, 'destinationId');
        }
        if (productType !== undefined && productType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>productType, 'productType');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Suggestion>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/schema/productTypes`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * A XLSX or JSON of Amazon Schema
     * @param orgId An Organization ID number used to indicate the current org context
     * @param destinationId 
     * @param fileType 
     * @param listingType 
     * @param productType 
     * @param sellerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAmazonSchema(orgId: number, destinationId?: number, fileType?: string, listingType?: string, productType?: string, sellerId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Blob>;
    public getAmazonSchema(orgId: number, destinationId?: number, fileType?: string, listingType?: string, productType?: string, sellerId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Blob>>;
    public getAmazonSchema(orgId: number, destinationId?: number, fileType?: string, listingType?: string, productType?: string, sellerId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Blob>>;
    public getAmazonSchema(orgId: number, destinationId?: number, fileType?: string, listingType?: string, productType?: string, sellerId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getAmazonSchema.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (destinationId !== undefined && destinationId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>destinationId, 'destinationId');
        }
        if (fileType !== undefined && fileType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fileType, 'fileType');
        }
        if (listingType !== undefined && listingType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>listingType, 'listingType');
        }
        if (productType !== undefined && productType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>productType, 'productType');
        }
        if (sellerId !== undefined && sellerId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sellerId, 'sellerId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/schema/export`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Batch Loads for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterDefinition A filter to filter batch loads by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBatchLoadStats(orgId: number, filterDefinition: FilterDefinition, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<BatchLoadStatus>>;
    public getBatchLoadStats(orgId: number, filterDefinition: FilterDefinition, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<BatchLoadStatus>>>;
    public getBatchLoadStats(orgId: number, filterDefinition: FilterDefinition, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<BatchLoadStatus>>>;
    public getBatchLoadStats(orgId: number, filterDefinition: FilterDefinition, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getBatchLoadStats.');
        }
        if (filterDefinition === null || filterDefinition === undefined) {
            throw new Error('Required parameter filterDefinition was null or undefined when calling getBatchLoadStats.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<BatchLoadStatus>>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/batch/load/stats`,
            filterDefinition,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Bulk Status by ID for an Organization
     * @param statusId 
     * @param orgId An Organization ID number used to indicate the current org context
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrgBulkStatusById(statusId: number, orgId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BulkStatus>;
    public getOrgBulkStatusById(statusId: number, orgId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BulkStatus>>;
    public getOrgBulkStatusById(statusId: number, orgId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BulkStatus>>;
    public getOrgBulkStatusById(statusId: number, orgId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (statusId === null || statusId === undefined) {
            throw new Error('Required parameter statusId was null or undefined when calling getOrgBulkStatusById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getOrgBulkStatusById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<BulkStatus>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/bulk/status/${encodeURIComponent(String(statusId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Product by ID for an Organization
     * @param groupBy Sort data response by Field, ascending or descending
     * @param orgId An Organization ID number used to indicate the current org context
     * @param productId A Product ID string
     * @param destinationId 
     * @param subCatalogId 
     * @param tabName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductById(groupBy: GroupBy, orgId: number, productId: string, destinationId?: number, subCatalogId?: string, tabName?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Product>;
    public getProductById(groupBy: GroupBy, orgId: number, productId: string, destinationId?: number, subCatalogId?: string, tabName?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Product>>;
    public getProductById(groupBy: GroupBy, orgId: number, productId: string, destinationId?: number, subCatalogId?: string, tabName?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Product>>;
    public getProductById(groupBy: GroupBy, orgId: number, productId: string, destinationId?: number, subCatalogId?: string, tabName?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (groupBy === null || groupBy === undefined) {
            throw new Error('Required parameter groupBy was null or undefined when calling getProductById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getProductById.');
        }
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductById.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (destinationId !== undefined && destinationId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>destinationId, 'destinationId');
        }
        if (subCatalogId !== undefined && subCatalogId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>subCatalogId, 'subCatalogId');
        }
        if (tabName !== undefined && tabName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>tabName, 'tabName');
        }
        if (groupBy !== undefined && groupBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>groupBy, 'groupBy');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Product>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product/${encodeURIComponent(String(productId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of Destinations for a Product by ID
     * @param groupBy Sort data response by Field, ascending or descending
     * @param orgId An Organization ID number used to indicate the current org context
     * @param productId A Product ID string
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductDestinations(groupBy: GroupBy, orgId: number, productId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<OrganizationReference>>;
    public getProductDestinations(groupBy: GroupBy, orgId: number, productId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<OrganizationReference>>>;
    public getProductDestinations(groupBy: GroupBy, orgId: number, productId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<OrganizationReference>>>;
    public getProductDestinations(groupBy: GroupBy, orgId: number, productId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (groupBy === null || groupBy === undefined) {
            throw new Error('Required parameter groupBy was null or undefined when calling getProductDestinations.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getProductDestinations.');
        }
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductDestinations.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (groupBy !== undefined && groupBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>groupBy, 'groupBy');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<OrganizationReference>>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product/${encodeURIComponent(String(productId))}/destination`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Product by ID for an Organization with paging
     * @param groupBy Sort data response by Field, ascending or descending
     * @param orgId An Organization ID number used to indicate the current org context
     * @param productId A Product ID string
     * @param destinationId 
     * @param subCatalogId 
     * @param tabName 
     * @param filterBy Property in data to filter by, e.g. &#x60;description&#x60; or &#x60;owner.firstName&#x60;
     * @param filterJoin Operator to match filterBy to filter value
     * @param filterMatch Operator to match filterBy to filter value
     * @param filterValue Value to match with in the filterBy
     * @param limit Length of the page
     * @param offset Start of the page
     * @param orderBy Order to sort by, ascending or descending
     * @param sortBy Property to sort by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductDetails(groupBy: GroupBy, orgId: number, productId: string, destinationId?: number, subCatalogId?: string, tabName?: string, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Product>;
    public getProductDetails(groupBy: GroupBy, orgId: number, productId: string, destinationId?: number, subCatalogId?: string, tabName?: string, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Product>>;
    public getProductDetails(groupBy: GroupBy, orgId: number, productId: string, destinationId?: number, subCatalogId?: string, tabName?: string, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Product>>;
    public getProductDetails(groupBy: GroupBy, orgId: number, productId: string, destinationId?: number, subCatalogId?: string, tabName?: string, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (groupBy === null || groupBy === undefined) {
            throw new Error('Required parameter groupBy was null or undefined when calling getProductDetails.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getProductDetails.');
        }
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductDetails.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (destinationId !== undefined && destinationId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>destinationId, 'destinationId');
        }
        if (subCatalogId !== undefined && subCatalogId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>subCatalogId, 'subCatalogId');
        }
        if (tabName !== undefined && tabName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>tabName, 'tabName');
        }
        if (filterBy) {
            filterBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterBy');
            })
        }
        if (filterJoin) {
            filterJoin.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterJoin');
            })
        }
        if (filterMatch) {
            filterMatch.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterMatch');
            })
        }
        if (filterValue) {
            filterValue.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterValue');
            })
        }
        if (groupBy !== undefined && groupBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>groupBy, 'groupBy');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy');
            })
        }
        if (sortBy) {
            sortBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sortBy');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Product>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product/details/${encodeURIComponent(String(productId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Product by ID for an Organization
     * @param groupBy Sort data response by Field, ascending or descending
     * @param orgId An Organization ID number used to indicate the current org context
     * @param productId A Product ID string
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductRelById(groupBy: GroupBy, orgId: number, productId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductRelationship>;
    public getProductRelById(groupBy: GroupBy, orgId: number, productId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductRelationship>>;
    public getProductRelById(groupBy: GroupBy, orgId: number, productId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductRelationship>>;
    public getProductRelById(groupBy: GroupBy, orgId: number, productId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (groupBy === null || groupBy === undefined) {
            throw new Error('Required parameter groupBy was null or undefined when calling getProductRelById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getProductRelById.');
        }
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductRelById.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (groupBy !== undefined && groupBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>groupBy, 'groupBy');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ProductRelationship>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product/${encodeURIComponent(String(productId))}/rel`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Products for an Organization
     * @param groupBy Sort data response by Field, ascending or descending
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterBy Property in data to filter by, e.g. &#x60;description&#x60; or &#x60;owner.firstName&#x60;
     * @param filterJoin Operator to match filterBy to filter value
     * @param filterMatch Operator to match filterBy to filter value
     * @param filterValue Value to match with in the filterBy
     * @param limit Length of the page
     * @param offset Start of the page
     * @param orderBy Order to sort by, ascending or descending
     * @param sortBy Property to sort by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProducts(groupBy: GroupBy, orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Product>>;
    public getProducts(groupBy: GroupBy, orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Product>>>;
    public getProducts(groupBy: GroupBy, orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Product>>>;
    public getProducts(groupBy: GroupBy, orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (groupBy === null || groupBy === undefined) {
            throw new Error('Required parameter groupBy was null or undefined when calling getProducts.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getProducts.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterBy) {
            filterBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterBy');
            })
        }
        if (filterJoin) {
            filterJoin.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterJoin');
            })
        }
        if (filterMatch) {
            filterMatch.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterMatch');
            })
        }
        if (filterValue) {
            filterValue.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterValue');
            })
        }
        if (groupBy !== undefined && groupBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>groupBy, 'groupBy');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy');
            })
        }
        if (sortBy) {
            sortBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sortBy');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Product>>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Products for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterDefinition A filter to filter products by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductsPost(orgId: number, filterDefinition: FilterDefinition, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Product>>;
    public getProductsPost(orgId: number, filterDefinition: FilterDefinition, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Product>>>;
    public getProductsPost(orgId: number, filterDefinition: FilterDefinition, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Product>>>;
    public getProductsPost(orgId: number, filterDefinition: FilterDefinition, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getProductsPost.');
        }
        if (filterDefinition === null || filterDefinition === undefined) {
            throw new Error('Required parameter filterDefinition was null or undefined when calling getProductsPost.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<Product>>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/products`,
            filterDefinition,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Products for a Sub-Catalog by ID for an Organization and Catalog
     * @param catalogId A Catalog ID number
     * @param groupBy Sort data response by Field, ascending or descending
     * @param orgId An Organization ID number used to indicate the current org context
     * @param subCatalogId A Sub-Catalog ID number
     * @param limit Length of the page
     * @param offset Start of the page
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubCatalogProducts(catalogId: number, groupBy: GroupBy, orgId: number, subCatalogId: number, limit?: number, offset?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Product>>;
    public getSubCatalogProducts(catalogId: number, groupBy: GroupBy, orgId: number, subCatalogId: number, limit?: number, offset?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Product>>>;
    public getSubCatalogProducts(catalogId: number, groupBy: GroupBy, orgId: number, subCatalogId: number, limit?: number, offset?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Product>>>;
    public getSubCatalogProducts(catalogId: number, groupBy: GroupBy, orgId: number, subCatalogId: number, limit?: number, offset?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (catalogId === null || catalogId === undefined) {
            throw new Error('Required parameter catalogId was null or undefined when calling getSubCatalogProducts.');
        }
        if (groupBy === null || groupBy === undefined) {
            throw new Error('Required parameter groupBy was null or undefined when calling getSubCatalogProducts.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getSubCatalogProducts.');
        }
        if (subCatalogId === null || subCatalogId === undefined) {
            throw new Error('Required parameter subCatalogId was null or undefined when calling getSubCatalogProducts.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (groupBy !== undefined && groupBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>groupBy, 'groupBy');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Product>>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/catalog/${encodeURIComponent(String(catalogId))}/sub/${encodeURIComponent(String(subCatalogId))}/product`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch a set of Products in bulk for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param bulkUpdate A Bulk Update to a set of products with
     * @param streamId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProductBulk(orgId: number, bulkUpdate: BulkUpdate, streamId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BulkUpdate>;
    public patchProductBulk(orgId: number, bulkUpdate: BulkUpdate, streamId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BulkUpdate>>;
    public patchProductBulk(orgId: number, bulkUpdate: BulkUpdate, streamId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BulkUpdate>>;
    public patchProductBulk(orgId: number, bulkUpdate: BulkUpdate, streamId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling patchProductBulk.');
        }
        if (bulkUpdate === null || bulkUpdate === undefined) {
            throw new Error('Required parameter bulkUpdate was null or undefined when calling patchProductBulk.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (streamId !== undefined && streamId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>streamId, 'streamId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<BulkUpdate>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product/bulk`,
            bulkUpdate,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a Product by ID for an Organization
     * This endpoint will update a Product
     * @param orgId An Organization ID number used to indicate the current org context
     * @param productId A Product ID string
     * @param tabName 
     * @param subListFlag 
     * @param product 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProductById(orgId: number, productId: string, tabName?: string, subListFlag?: boolean, product?: Product, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Product>;
    public patchProductById(orgId: number, productId: string, tabName?: string, subListFlag?: boolean, product?: Product, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Product>>;
    public patchProductById(orgId: number, productId: string, tabName?: string, subListFlag?: boolean, product?: Product, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Product>>;
    public patchProductById(orgId: number, productId: string, tabName?: string, subListFlag?: boolean, product?: Product, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling patchProductById.');
        }
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling patchProductById.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (tabName !== undefined && tabName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>tabName, 'tabName');
        }
        if (subListFlag !== undefined && subListFlag !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>subListFlag, 'subListFlag');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<Product>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product/${encodeURIComponent(String(productId))}`,
            product,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch a set of Product Inventory in bulk for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param bulkUpdate A Bulk Update to a set of product inventory with
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProductInventoryBulk(orgId: number, bulkUpdate: BulkUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BulkUpdate>;
    public patchProductInventoryBulk(orgId: number, bulkUpdate: BulkUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BulkUpdate>>;
    public patchProductInventoryBulk(orgId: number, bulkUpdate: BulkUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BulkUpdate>>;
    public patchProductInventoryBulk(orgId: number, bulkUpdate: BulkUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling patchProductInventoryBulk.');
        }
        if (bulkUpdate === null || bulkUpdate === undefined) {
            throw new Error('Required parameter bulkUpdate was null or undefined when calling patchProductInventoryBulk.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<BulkUpdate>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product/inventory/bulk`,
            bulkUpdate,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch a set of Product Seo in bulk for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param bulkUpdate A Bulk Update to a set of product seo with
     * @param streamId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProductSeoBulk(orgId: number, bulkUpdate: BulkUpdate, streamId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BulkUpdate>;
    public patchProductSeoBulk(orgId: number, bulkUpdate: BulkUpdate, streamId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BulkUpdate>>;
    public patchProductSeoBulk(orgId: number, bulkUpdate: BulkUpdate, streamId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BulkUpdate>>;
    public patchProductSeoBulk(orgId: number, bulkUpdate: BulkUpdate, streamId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling patchProductSeoBulk.');
        }
        if (bulkUpdate === null || bulkUpdate === undefined) {
            throw new Error('Required parameter bulkUpdate was null or undefined when calling patchProductSeoBulk.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (streamId !== undefined && streamId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>streamId, 'streamId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<BulkUpdate>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product/seo/bulk`,
            bulkUpdate,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post a Data Audit for an Organization and a given destination
     * @param orgId An Organization ID number used to indicate the current org context
     * @param auditUpdate An Audit Update to a set of products
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAuditData(orgId: number, auditUpdate: AuditUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AuditUpdate>;
    public postAuditData(orgId: number, auditUpdate: AuditUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AuditUpdate>>;
    public postAuditData(orgId: number, auditUpdate: AuditUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AuditUpdate>>;
    public postAuditData(orgId: number, auditUpdate: AuditUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling postAuditData.');
        }
        if (auditUpdate === null || auditUpdate === undefined) {
            throw new Error('Required parameter auditUpdate was null or undefined when calling postAuditData.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<AuditUpdate>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/audit/data`,
            auditUpdate,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Overwrite a Product by ID for an Organization
     * This endpoint will replace a Product
     * @param orgId An Organization ID number used to indicate the current org context
     * @param productId A Product ID string
     * @param tabName 
     * @param subListFlag 
     * @param product 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putProductById(orgId: number, productId: string, tabName?: string, subListFlag?: boolean, product?: Product, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Product>;
    public putProductById(orgId: number, productId: string, tabName?: string, subListFlag?: boolean, product?: Product, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Product>>;
    public putProductById(orgId: number, productId: string, tabName?: string, subListFlag?: boolean, product?: Product, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Product>>;
    public putProductById(orgId: number, productId: string, tabName?: string, subListFlag?: boolean, product?: Product, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling putProductById.');
        }
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling putProductById.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (tabName !== undefined && tabName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>tabName, 'tabName');
        }
        if (subListFlag !== undefined && subListFlag !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>subListFlag, 'subListFlag');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<Product>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product/${encodeURIComponent(String(productId))}`,
            product,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a Product by ID from an Organization
     * This endpoint will remove a Product
     * @param orgId An Organization ID number used to indicate the current org context
     * @param productId A Product ID string
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeProductById(orgId: number, productId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public removeProductById(orgId: number, productId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public removeProductById(orgId: number, productId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public removeProductById(orgId: number, productId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling removeProductById.');
        }
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling removeProductById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product/${encodeURIComponent(String(productId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove a list of Products
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterDefinition The filter definition
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeProducts(orgId: number, filterDefinition: FilterDefinition, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<string>>;
    public removeProducts(orgId: number, filterDefinition: FilterDefinition, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<string>>>;
    public removeProducts(orgId: number, filterDefinition: FilterDefinition, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<string>>>;
    public removeProducts(orgId: number, filterDefinition: FilterDefinition, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling removeProducts.');
        }
        if (filterDefinition === null || filterDefinition === undefined) {
            throw new Error('Required parameter filterDefinition was null or undefined when calling removeProducts.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<string>>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/product/delete`,
            filterDefinition,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove a list of Products on Sub Catalog
     * @param orgId An Organization ID number used to indicate the current org context
     * @param subCatalogId A Sub-Catalog ID number
     * @param filterDefinition The filter definition
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeSubCatalogProducts(orgId: number, subCatalogId: number, filterDefinition: FilterDefinition, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<string>>;
    public removeSubCatalogProducts(orgId: number, subCatalogId: number, filterDefinition: FilterDefinition, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<string>>>;
    public removeSubCatalogProducts(orgId: number, subCatalogId: number, filterDefinition: FilterDefinition, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<string>>>;
    public removeSubCatalogProducts(orgId: number, subCatalogId: number, filterDefinition: FilterDefinition, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling removeSubCatalogProducts.');
        }
        if (subCatalogId === null || subCatalogId === undefined) {
            throw new Error('Required parameter subCatalogId was null or undefined when calling removeSubCatalogProducts.');
        }
        if (filterDefinition === null || filterDefinition === undefined) {
            throw new Error('Required parameter filterDefinition was null or undefined when calling removeSubCatalogProducts.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<string>>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/sub-catalog/${encodeURIComponent(String(subCatalogId))}/product/delete`,
            filterDefinition,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Run a workflow for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param bulkUpdated 
     * @param workflowName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public runWorkflow(orgId: number, bulkUpdated?: boolean, workflowName?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse200>;
    public runWorkflow(orgId: number, bulkUpdated?: boolean, workflowName?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse200>>;
    public runWorkflow(orgId: number, bulkUpdated?: boolean, workflowName?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse200>>;
    public runWorkflow(orgId: number, bulkUpdated?: boolean, workflowName?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling runWorkflow.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (bulkUpdated !== undefined && bulkUpdated !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>bulkUpdated, 'bulkUpdated');
        }
        if (workflowName !== undefined && workflowName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>workflowName, 'workflowName');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<InlineResponse200>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/workflow/run`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
