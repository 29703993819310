/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DataProcessField } from './dataProcessField';


export interface DataProcessBlock { 
    bulkOutputRows?: string;
    cloneSheetName?: string;
    currentLine?: number;
    currentPage?: number;
    customHeaderRows?: Array<number>;
    dataBufferName?: string;
    deleteAfterRun?: boolean;
    displayName?: string;
    dupValues?: Set<string>;
    duplicateFieldName?: string;
    endLineNumber?: number;
    fieldMap?: { [key: string]: DataProcessField; };
    fieldSameFlag?: boolean;
    fields?: Array<DataProcessField>;
    formatName?: string;
    fullPage?: string;
    headerRecordFlag?: boolean;
    headerRow?: number;
    inactive?: boolean;
    innerLoop?: boolean;
    interfaceId?: string;
    listFlag?: boolean;
    mappingRowNumber?: number;
    matchEnd?: string;
    matchNext?: string;
    matchNext2?: string;
    matchStart?: string;
    matchStart2?: string;
    minNumber?: number;
    name?: string;
    number?: number;
    occurrence?: string;
    outputName?: string;
    overrideFields?: Array<DataProcessField>;
    pageEnd?: number;
    pageList?: Array<string>;
    pageStart?: number;
    parent?: string;
    parentCurrentLine?: number;
    processEndMatch?: boolean;
    resetCurrentLine?: number;
    resetEdiCount?: boolean;
    rootBlockFlag?: boolean;
    sameFieldNameParent?: string;
    skipCount?: boolean;
    token?: string;
    useParentLine?: boolean;
    useSheetCount?: boolean;
    xPath?: string;
    fieldSame?: boolean;
    headerRecord?: boolean;
    rootBlock?: boolean;
}

