import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ExceptionService} from "@app/core/services/exception.service";
import {
  AmazonApiService,
  AmazonCallback,
  Callback,
  Configuration,
  NotificationType,
  PartnerApiService,
  ToastService
} from "@app/core";
import {catchError, tap} from "rxjs/operators";
import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {throwError} from "rxjs";
import axios from "axios";

@Component({
  selector: 'app-partner-callback',
  templateUrl: './partner-callback.component.html',
  styleUrls: ['./partner-callback.component.scss']
})
export class PartnerCallbackComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private configuration: Configuration
  ) { }

  ngOnInit() {
    const { queryParams } = this.route.snapshot;
    const url = `${this.configuration.basePath}/api/partner/consume/token?state=${queryParams.state}&selling_partner_id=${queryParams.selling_partner_id}&mws_auth_token=${queryParams.mws_auth_token}&spapi_oauth_code=${queryParams.spapi_oauth_code}`;
    
    fetch(url
      // 'http://localhost:8080/api/partner/consume/token?state=eyJyZWRpcmVjdFVyaSI6Imh0dHBzOi8vd3d3Lmdvb2dsZS5jb20iLCJ0b2tlbiI6IjMxNGZlN2U5ZTY4MDQ0ZDFiMTkzOWI4NzcwZGI5YTMzIiwiY29ubmVjdGlvbiI6IkFtYXpvbiJ9&sellingPartnerId=a&mwsAuthToken=b&spapiOauthCode=c'
      )
      .then(response => {
        if (response.ok) {
          return response.json(); // Parse the response data as JSON
        } else {
          throw new Error('API request failed');
        }
      })
      .then(data => {
        console.log(data);
        window.location.href = data.redirectUri;
      })
      .catch(error => {
        console.error(error);
        window.location.href = error.redirectUri;
      });
  }
}
