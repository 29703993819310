import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ApplicationService } from '@app/shared';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService implements OnDestroy {

  routerEvents: Subscription;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private appSvc: ApplicationService) {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    if (environment.analytics) {
      this.routerEvents = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        map(route => route.snapshot)
      ).subscribe(
        (route: ActivatedRouteSnapshot) => {
          const color = environment.color;
          const title = route.data.title;
          const path = this.appSvc.buildUrlFromSnapshot(route);
          const host = window.location.host;
          const layer = { title, path, color, host, event: 'page_change' };
          window.dataLayer.push(layer);
        }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.routerEvents) {
      this.routerEvents.unsubscribe();
    }
  }

}
