import { Injectable } from '@angular/core';
import { ToastService, NotificationType, AuthState, Notification, NotificationProgress, OrgApiService } from '@app/core';
import { Store } from '@ngxs/store';
import axios from 'axios';
import { first, tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private toast: ToastService,
              private orgApiService: OrgApiService,
              private store: Store) { }

  private mediaTypes: { [key: string]: string } = {
    'application/zip': 'zip',
    'text/csv': 'csv',
    'application/csv': 'csv',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/json': 'json'
  };

  blob(blob: Blob, filename: string, fileExtensionOverride?: string): void {
    if (!blob || !filename) {
      return;
    }
    if (fileExtensionOverride) {
      filename = `${filename}.${fileExtensionOverride}`;
    } else {
      if (blob.type && this.mediaTypes[blob.type]) {
        filename = `${filename}.${this.mediaTypes[blob.type]}`;
      }
    }
    this.downloadBlob(blob, filename);
  }

  fromUrl(url: string, filename: string): void {
    if (!url || !filename) {
      return;
    }
    const token: string = this.store.selectSnapshot(AuthState.getToken);
    const toast: Notification = {
      title: 'Downloading...',
      message: filename,
      icon: 'cloud-upload-alt',
      progress: {
        currentFinished: 0,
        currentTotal: 100,
      },
      type: NotificationType.PROGRESS
    };
    this.toast.add(toast);

    axios({
      url,
      method: 'get',
      responseType: 'blob',
      headers: { authorization: token ? `Bearer ${token}` : null },
      onDownloadProgress: event => {
        const progress: NotificationProgress = {
          currentFinished: Math.round(event.progress * 100)
        };
        this.toast.updateProgress(toast, progress);
      }
    })
    .then(res => {
      if (res.status !== 200) {
        throw res;
      }
      this.toast.remove(toast);
      return res.data;
    })
    .then(blob => this.downloadBlob(blob, filename))
    .catch(() => {
      this.toast.remove(toast);
      this.toast.add({
        expiration: 5000,
        title: 'Service Error',
        message: 'Unable to Download File',
        type: NotificationType.ERROR
      });
    });
  }

  private downloadBlob(blob: Blob, filename: string): void {
    const blobURL = URL.createObjectURL(blob);
    const element = document.createElement('a');
    element.setAttribute('href', blobURL);
    if (filename) {
      element.setAttribute('download', filename);
    }
    element.setAttribute('target', '_blank');

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    window.URL.revokeObjectURL(blobURL);
  }


  static getOrgIdFromSecurePath(filePath: string): number {
    if (filePath.startsWith("secure")) {
      const filePathSplit = filePath.split("/");
      const secureText = filePathSplit.shift();
      const parsedOrgId = parseInt(filePathSplit.shift());

      return parsedOrgId;
    }
    return null;
  }

  static stripSecureDirectory(filePath: string, orgId: number): string {
    if (filePath.startsWith("secure")) {
      const filePathSplit = filePath.split("/");
      const secureText = filePathSplit.shift();
      const parsedOrgId = parseInt(filePathSplit.shift());

      // if (orgId !== parsedOrgId && "secure" === secureText) {
      //   console.log("Mismatched Org Id or Path=> ", orgId, parsedOrgId)
      //   return filePath;
      // }
      return filePathSplit.join("/");
    }

    return filePath;
  }

  downloadFromSecureApi(filePath: string, fileName: string, orgId: number, overrideOrgId: boolean) {
    if(overrideOrgId) {
      orgId = DownloadService.getOrgIdFromSecurePath(filePath);
    }

    filePath = DownloadService.stripSecureDirectory(filePath, orgId);

    const toast: Notification = {
      title: 'Downloading...',
      message: fileName,
      icon: 'cloud-upload-alt',
      progress: {
        currentFinished: 0,
        currentTotal: 100,
      },
      type: NotificationType.PROGRESS
    };

    this.toast.add(toast);

    this.orgApiService.getSecureFile(filePath, orgId).pipe(
      first(),
      tap((blob) => {
        this.downloadBlob(blob, fileName);
      }),
    ).subscribe({
      error: () => {
        // this.toast.remove(toast);
        this.toast.remove(toast);
        this.toast.add({
          expiration: 5000,
          title: 'Service Error',
          message: 'Unable to Download File',
          type: NotificationType.ERROR
        });
      },
      complete: () =>{
        this.toast.remove(toast);
      }
    })
  }
}
