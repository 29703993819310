import { Component, OnInit, Input } from '@angular/core';
import { NavigationActionItem } from '../../../services/navigation.service';

@Component({
  selector: 'app-selection-search',
  templateUrl: './selection-search.component.html',
  styleUrls: ['./selection-search.component.scss']
})
export class SelectionSearchComponent implements OnInit {
  @Input() set action(selectedNav: NavigationActionItem) {
    if (selectedNav && selectedNav.title) {
      this.placeholder = 'search ' + selectedNav.title.toLowerCase() + '...';
    } else {
      this.placeholder = 'Search...';
    }
  }

  placeholder: string;

  constructor() { }

  ngOnInit() {
  }

}
