<div class="saved-filters-list" *ngIf="(savedFilters$ | async) as savedFilters">
  <div class="empty" *ngIf="savedFilters.length === 0">You have no saved views</div>
  <div class="saved-filter" *ngFor="let filter of savedFilters"
    [ngClass]="{'active': activeSavedFilterId === filter.filterId}">
    <span (click)="savedFilterSelected(filter)">{{ filter.filterName }}</span>
    <i class="fas fa-times" (click)="promptDelete(filter)"></i>
  </div>
</div>

<app-modal [(open)]="showDeleteViewModal" *ngIf="savedFilterPendingDelete" height="225" width="400">
  <app-modal-head></app-modal-head>
  <app-modal-body [center]="true">
    <div>Delete the <b>{{ savedFilterPendingDelete.filterName }}</b> view?</div>
  </app-modal-body>
  <app-modal-foot [center]="true">
    <app-button (click)="showDeleteViewModal = false" color="navy">Cancel</app-button>
    <div class="flex-fill"></div>
    <app-button (click)="deleteSavedFilter()" color="red">Delete</app-button>
  </app-modal-foot>
</app-modal>
