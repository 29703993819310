<div class="chart-container" *ngIf="categories.length > 0" id="product-lifecycle">

  <!-- loop through categories -->
  <div class="category-container" *ngFor="let category of categories">
    <ng-container *ngIf="category.name.toLowerCase()!=='taxonomy'">
      <!-- category header -->
      <div class="header">
        <div class="category-title"> {{ category.name }} </div>
        <div class="category-product-counts">
          <div class="figure passed" *ngIf="category.passes > 0">
            <i class="fal fa-check"></i>
            <span>{{ category.passes }}</span>
          </div>
          <div class="figure warnings" *ngIf="category.warnings > 0">
            <i class="fal fa-exclamation-triangle"></i>
            <span>{{ category.warnings }}</span>
          </div>
          <div class="figure errors" *ngIf="category.errors > 0">
            <i class="fal fa-times-circle"></i>
            <span>{{ category.errors }}</span>
          </div>
        </div>
      </div>

      <!-- loop through each of the category's steps and draw a divergent bar chart -->
      <div class="step-chart" *ngFor="let step of category.steps">
        <div class="step-name">{{ step.name }}</div>
        <div class="bar-container">
          <app-product-lifecycle-status-divergent-chart [step]="step"></app-product-lifecycle-status-divergent-chart>
        </div>
      </div>
    </ng-container>
  </div>
</div>
