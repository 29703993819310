/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';
import { Location } from './location';


export interface AllocationRule { 
    allocated?: number;
    allocationPercent?: number;
    allocationRuleId?: number;
    autoAllocate?: boolean;
    balance?: number;
    carrierScac?: string;
    containerType?: string;
    createdDate?: ModifiedDate;
    endDate?: string;
    expected?: number;
    lastUpdated?: ModifiedDate;
    locationDelivery?: Location;
    locationPickup?: Location;
    orgRefDrayCarrier?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    orgRefShipper?: OrganizationReference;
    startDate?: string;
    total?: number;
    useInterchangedCarrier?: boolean;
}

