import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { RadioService } from '@app/shared/services/radio.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding('class.checked') @Input() checked = false;

  index: number;

  activationSub: Subscription;

  constructor(private radio: RadioService) { }

  ngOnInit(): void {
    this.activationSub = this.radio.activation$.pipe(debounceTime(0)).subscribe(
      (index: number) => {
        if (index !== this.index) {
          this.checked = false;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.activationSub.unsubscribe();
  }

  ngOnChanges(): void {
    if (this.checked) {
      this.radio.activate(this.index);
    }
  }
}
