import { Params } from '@angular/router';

export class SetQueryAction {
  static readonly type = '[Query Cache] Add a Query cache by Route URL';
  constructor(public payload: { queryParams: Params, routeUrl: string }) { }
}

export class ResetQueryStateAction {
  static readonly type = '[Query Cache] Reset state';
  constructor() { }
}
