/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Comment } from './comment';
import { OrganizationReference } from './organizationReference';


export interface YardDriver { 
    bobtailReasonIn?: string;
    bobtailReasonOut?: string;
    changeLocation?: boolean;
    chassisNumber?: string;
    checkOut?: boolean;
    comment?: Comment;
    commentIn?: string;
    commentList?: Array<Comment>;
    commentOut?: string;
    driverInDate?: string;
    driverName?: string;
    driverOutDate?: string;
    driverReference?: string;
    emptyIn?: boolean;
    emptyOut?: boolean;
    equipmentNumberIn?: string;
    equipmentNumberOut?: string;
    equipmentTypeIn?: string;
    equipmentTypeOut?: string;
    inspectionIn?: boolean;
    inspectionOut?: boolean;
    locationId?: number;
    minutesInYard?: number;
    orgRef?: OrganizationReference;
    orgRefDrayCarrier?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    sealNumber?: string;
    timeZone?: string;
    timeZoneName?: string;
    yardDriverId?: number;
    yardLocation?: string;
}

