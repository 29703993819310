<app-modal [open]="_open" (openChange)="openChange.emit($event)">
  <app-modal-head>
    <app-modal-title>Media Naming Requirements</app-modal-title>
  </app-modal-head>
  <app-modal-body>
    <app-suggestion label="Select a Media Field or Enter Text" [provided]="suggestions" (optionSelected)="mediaFieldSelected($event)"
      [disableSetTypeahead]="true" [showClose]="false" [showConfirm]="true">
    </app-suggestion>
    <app-media-chip-list [(chipString)]="chipString" [suggestions]="suggestions"></app-media-chip-list>
  </app-modal-body>
  <app-modal-foot [center]="true">
    <app-button color="red" (click)="cancel()">Cancel</app-button>
    <div class="flex-fill"></div>
    <app-button color="red" *ngIf="chipString" (click)="clear()">Clear</app-button>
    <app-button color="green" *ngIf="control.value" (click)="add()">Update</app-button>
    <app-button color="green" *ngIf="!control.value" [disabled]="!chipString" (click)="add()">Add</app-button>
  </app-modal-foot>
</app-modal>
