import { Component, OnInit, Input, HostBinding, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Suggestion } from '@app/core';
import { Subscription } from 'rxjs';
import {
  InfiniteScrollService,
  InfiniteScrollColor,
  InfiniteScrollColumn,
  InfiniteScrollColumnType,
  InfiniteScrollColumnDetail,
  InfiniteScrollImage,
  InfiniteScrollTitle,
  InfiniteScrollChip,
  InfiniteScrollAction,
  InfiniteScrollStyle,
  InfiniteScrollColorTestFn,
  InfiniteScrollImageType,
  InfiniteScrollColumnSuggestionProperty
} from '../../../services/infinite-scroll.service';
import * as _ from 'lodash-es';
import { AOrAnPipe } from '@app/shared/pipes/aOrAn.pipe';

@Component({
  selector: 'app-infinite-scroll-row',
  templateUrl: './infinite-scroll-row.component.html',
  styleUrls: ['./infinite-scroll-row-default.component.scss', './infinite-scroll-row-logistics.component.scss', './infinite-scroll-row-product.component.scss', './infinite-scroll-row-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class InfiniteScrollRowComponent implements OnInit, OnDestroy {
  @Input() set row(row: { [key: string]: any }) {
    this._row = row;
    this.checkRowConsistency();
    this.updateRowForm();
    this.cd.markForCheck();
  }
  @Input() set columns(columns: Array<InfiniteScrollColumn>) {
    this._columns = columns;
    this.checkRowConsistency();
    this.updateRowForm();
    this.cd.markForCheck();
  }
  @Input() image: InfiniteScrollImage;
  @Input() titleWidth = 200;
  @Input() chips: Array<InfiniteScrollChip>;
  @Input() chipWidth: number;
  @Input() actions: Array<InfiniteScrollAction>;
  @Input() warning: boolean;
  @Input() processing: boolean;
  @Input() processings: Array<true>;
  @Input() suggestions: { [key: string]: Array<Suggestion> } = {};
  @Input() doubleClick = false;
  @Input() loading: boolean;

  @Input() rowColorTest: InfiniteScrollColorTestFn;

  @Input() showSelection = false;
  @Input() showNewRow = false;
  @Input() showEllipsis = false;
  @Output() ellipsisClick: EventEmitter<{ [key: string]: any }> = new EventEmitter();

  @Input() showDownloadButton = false; // Show or hide a download button
  @Output() downloadButtonClick: EventEmitter<{ [key: string]: any }> = new EventEmitter();

  @Input() emptyCellText = '';
  @Input() minWidths: Array<number> = [];
  // Not the most generic approach, but this is being used to color the rows on A+ content preview screen.
  @Input() isColorCoded = false;
  @Input() isColorCodeInverted = false;
  // Used to pass a long a set of rows that should be saved in memory, Used on the A+ content preview screen
  @Input() savedRows;

  @HostBinding('class.selected') _selected = false;
  @HostBinding('class.clickable') @Input() clickable = false;
  @HostBinding('class.title') @Input() title: InfiniteScrollTitle;

  @HostBinding('class.logistics') isLogisticsStyle = false;
  @HostBinding('class.product') isProductStyle = false;

  @HostBinding('class.yellow') yellow = false;
  @HostBinding('class.red') red = false;
  @HostBinding('class.green') green = false;
  @HostBinding('class.blue') blue = false;

  @ViewChild('valuesRef') valuesRef!: ElementRef<any>;

  @Input() set style(style: InfiniteScrollStyle) {
    this.isLogisticsStyle = false;
    this.isProductStyle = false;
    if (style) {
      this._style = style;
      if (style === InfiniteScrollStyle.Logistics) {
        this.isLogisticsStyle = true;
      } else if (style === InfiniteScrollStyle.Product) {
        this.isProductStyle = true;
      }
    } else {
      this._style = InfiniteScrollStyle.Default;
    }
  }

  _style: InfiniteScrollStyle;
  rowForm: UntypedFormGroup;
  _row: { [key: string]: any };
  _columns: Array<InfiniteScrollColumn>;
  _isEditable = false;
  showColumnFill$ = this.scrollSvc.showColumnFill$;
  showValueDetails = '';
  editable$ = this.scrollSvc.editable$;
  showActionsDrawer = false;
  showActionsDrawerClick = false;
  @Input() isSingleSelected = false;

  isEditableSub: Subscription;
  isSelectedSub: Subscription;

  InfiniteScrollColor = InfiniteScrollColor;
  InfiniteScrollColumnType = InfiniteScrollColumnType;
  InfiniteScrollImageType = InfiniteScrollImageType;
  InfiniteScrollColumnSuggestionProperty = InfiniteScrollColumnSuggestionProperty;

  @HostListener('document:click')
  clickOutside() {
    if (!this.showActionsDrawerClick) {
      this.showActionsDrawer = false;
    }
    this.showActionsDrawerClick = false;
  }

  constructor(
    private date: DatePipe,
    private scrollSvc: InfiniteScrollService,
    private cd: ChangeDetectorRef,
    private aOrAnPipe: AOrAnPipe
  ) { }

  ngOnInit(): void {
    this.isEditableSub = this.scrollSvc.editable$.subscribe(e => {
      this._isEditable = e;
      this.cd.markForCheck();
    });
    this.isSelectedSub = this.scrollSvc.isSelected$(this._row).subscribe(s => {
      this._selected = s;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.isEditableSub.unsubscribe();
    this.isSelectedSub.unsubscribe();
  }

  selectRow(): void {
    if (this._selected) {
      this.scrollSvc.deselectRow(this._row);
    } else {
      this.scrollSvc.selectRow(this._row);
    }
  }

  clickRow(): void {
    if (this._isEditable || this.doubleClick) {
      return;
    }
    this.scrollSvc.navigate(this._row);
  }

  clickValue(column: InfiniteScrollColumn): void {
    if (column.type === InfiniteScrollColumnType.Email) {
      const value = this.getCellValue(column);
      window.open(`mailto:${value}`);
    } else {
      this.clickRow();
    }
  }

  doubleClickRow(): void {
    if (this._isEditable || !this.doubleClick) {
      return;
    }
    this.scrollSvc.navigate(this._row);
  }

  doubleClickValue(): void {
    this.doubleClickRow();
  }

  ellipsisClickRow(): void {
    this.ellipsisClick.emit(this._row);
  }

  downloadButtonClickRow(): void {
    this.downloadButtonClick.emit(this._row);
  }

  actionClick(action: InfiniteScrollAction): void {
    action.action(this._row);
  }

  getCellValue({ propertyName, displayProperty, type, gmt, transform, emptyCellText }: InfiniteScrollColumn): any {
    if (!this._row) {
      return;
    }
    if (transform) {
      return transform(this._row);
    }
    const value = this.interpolatePropertyString(displayProperty ? displayProperty : propertyName);
    if (!value) {
      return emptyCellText ? emptyCellText : this.emptyCellText;
    }
    return this.transformCellValue(value, type, gmt);
  }

  getCellDetailValue({ propertyName, type }: InfiniteScrollColumnDetail): string {
    const value = this.interpolatePropertyString(propertyName);
    return this.transformCellValue(value, type);
  }

  private transformCellValue(value: string, type: InfiniteScrollColumnType, gmt?: boolean): string {
    if (type === InfiniteScrollColumnType.Boolean) {
      return value ? 'Yes' : 'No';
    }
    if (type === InfiniteScrollColumnType.Date) {
      if (gmt) {
        return this.date.transform(value, 'mediumDate', 'GMT');
      }
      return this.date.transform(value, 'mediumDate');
    }
    if (type === InfiniteScrollColumnType.Datetime) {
      if (gmt) {
        return this.date.transform(value, 'medium', 'GMT');
      }
      return this.date.transform(value, 'medium');
    }
    if (type === InfiniteScrollColumnType.Number) {
      const parse = parseFloat(value);
      if (!isNaN(parse) && parse % 1 > 0) {
        return parse.toFixed(4);
      }
    }
    if (type === InfiniteScrollColumnType.Currency) {
      const parse = parseFloat(value);
      if (!isNaN(parse)) {
        return parse.toFixed(2);
      }
    }
    return value;
  }

  interpolatePropertyString(str: string): any {
    return str.split('.').reduce((obj, propName) => obj && obj[propName], this._row);
  }

  getFormControl(column: InfiniteScrollColumn): UntypedFormControl {
    if (!this.rowForm) {
      return;
    }
    return this.rowForm.get(column.propertyName.split('.')) as UntypedFormControl;
  }

  private checkRowConsistency(): void {
    if (this._columns && this._row) {
      if (this.rowColorTest) {
        this.yellow = this.rowColorTest(this._row) === InfiniteScrollColor.Yellow;
        this.red = this.rowColorTest(this._row) === InfiniteScrollColor.Red;
        this.green = this.rowColorTest(this._row) === InfiniteScrollColor.Green;
        this.blue = this.rowColorTest(this._row) === InfiniteScrollColor.Blue;
      }

      const cloneRow = _.cloneDeep(this._row);
      this._columns.forEach(column => {
        const value = column.parseInt ?
          parseInt(this.interpolatePropertyString(column.propertyName), null) : this.interpolatePropertyString(column.propertyName);
        _.set(cloneRow, column.propertyName, value);
        if (column.suggestionAlternateProperty?.propertyName) {
          const altValue = column.suggestionAlternateProperty.parseInt ?
            parseInt(this.interpolatePropertyString(column.suggestionAlternateProperty.propertyName), null) :
            this.interpolatePropertyString(column.suggestionAlternateProperty.propertyName);
          _.set(cloneRow, column.suggestionAlternateProperty.propertyName, altValue);
        }
      });
      this._row = cloneRow;
    }
  }

  private updateRowForm(): void {
    if (!this._columns || this._columns.length === 0 || !this._row) {
      return;
    }
    this.rowForm = this.scrollSvc.buildValidationFormGroup(this._row, this._columns) as UntypedFormGroup;
  }

  private enforceNumber(event: KeyboardEvent): void {
    // Prevent input if not a number or a decimal point
    if (isNaN(Number(event.key)) && event.key !== '.') {
      event.preventDefault();
    }
    // Prevent input of more than one decimal point
    const target: HTMLElement = event.target as HTMLElement;
    if (event.key === '.' && target.innerText.indexOf('.') > -1) {
      event.preventDefault();
    }
  }

  private enforceDecimalPlaces(event: KeyboardEvent, places: number = 2): void {
    const target: HTMLElement = event.target as HTMLElement;
    const decimalIndex = target.innerText.indexOf('.');
    if (decimalIndex > -1 && target.innerText.length - decimalIndex > places) {
      event.preventDefault();
    }
  }

  enforceNumberInput(event: KeyboardEvent): void {
    this.enforceNumber(event);
    this.enforceDecimalPlaces(event, 4);
  }

  enforceCurrencyInput(event: KeyboardEvent): void {
    this.enforceNumber(event);
    this.enforceDecimalPlaces(event, 2);
  }

  getSuggestionLabel(displayName: string): string {
    return `Select ${this.aOrAnPipe.transform(displayName)} ${displayName}`;
  }

  /**
   * This is honestly a complete Hack to get color coded rows for A+ preview screen.
   * Could be made generic for future uses.
   */

  getRowActionIcon() {
    if (!this.actions[0].color)
      this.actions[0].color = InfiniteScrollColor.Blue;

    if (this.isColorCoded) {
      if (this.isSaved())
        return this.actions[0].icon + ' ' + this.actions[0].color;
      else
        return this.actions[0].altIcon + ' ' + this.actions[0].color;
    }
    return this.actions[0].icon + ' ' + this.actions[0].color
  }

  isSaved() {
    const row = this._row;
    let sku = row["sku"];
    let returnBool: boolean = false;


    if (this.savedRows) {
      const foundSku = this.savedRows.filter(x => x.sku === sku)
      if (foundSku.length > 0) {
        returnBool = true;
        // let tmp = this.actions[0].icon
        // let tmp2 = this.actions[0].altIcon

        // Flip the icons
        // this.actions[0].icon = tmp2
        // this.actions[0].altIcon = tmp
      }
    }

    if (this.isColorCodeInverted)
      returnBool = !returnBool;
    return returnBool;
  }
}
