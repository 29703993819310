import { Component, OnDestroy, OnInit } from '@angular/core';

import { User } from './core/api';
import { Subscription } from 'rxjs';
import { LogoutUserAction } from './core';
import { ExceptionService } from './core/services/exception.service';
import { Store } from '@ngxs/store';
import { concatMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

// Services
//
import { TitleService } from './core/services/title.service';
import { AnalyticsService } from './core/services/analytics.service';
import { HistoryService } from './shared';
import { PrometheusService } from './core/services/prometheus.service';
import { AuthService } from '@auth0/auth0-angular';

declare global {
  interface Window {
    gtagUser: User;
    dataLayer: Array<any>;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  authErrorSub: Subscription;

  // First three services included so they init on app load
  constructor(
    private title: TitleService,
    private analytics: AnalyticsService,
    private history: HistoryService,
    private prometheus: PrometheusService,
    private auth: AuthService,
    private exception: ExceptionService,
    private store: Store,
    private router:  Router
  ) {}

  ngOnInit(): void {
    this.authErrorSub = this.auth.error$.pipe(
      tap((error: Error) => {
        this.exception.add({
          name: 'AppComponent, Auth0 error$',
          info: 'The server is having trouble logging you in.',
          error: JSON.stringify(error)
        });
      }),
      concatMap(() => this.auth.getAccessTokenSilently())
    ).subscribe(
      (token: string) => {
        if (!token) {
          this.store.dispatch(new LogoutUserAction());
        } else {
          this.router.navigate(['/'], { queryParams: { default: true } });
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.authErrorSub.unsubscribe();
  }
}
