/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ClientProducts } from '../model/models';
import { FilterDefinition } from '../model/models';
import { GroupBy } from '../model/models';
import { ProductImportStatus } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ClientApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get a batch load status for an Organization
     * @param batchLoadId 
     * @param formatCode 
     * @param orgName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientBatchStart(batchLoadId: number, formatCode: string, orgName: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public getClientBatchStart(batchLoadId: number, formatCode: string, orgName: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public getClientBatchStart(batchLoadId: number, formatCode: string, orgName: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public getClientBatchStart(batchLoadId: number, formatCode: string, orgName: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (batchLoadId === null || batchLoadId === undefined) {
            throw new Error('Required parameter batchLoadId was null or undefined when calling getClientBatchStart.');
        }
        if (formatCode === null || formatCode === undefined) {
            throw new Error('Required parameter formatCode was null or undefined when calling getClientBatchStart.');
        }
        if (orgName === null || orgName === undefined) {
            throw new Error('Required parameter orgName was null or undefined when calling getClientBatchStart.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/client/v1/batch/status/${encodeURIComponent(String(formatCode))}/${encodeURIComponent(String(orgName))}/${encodeURIComponent(String(batchLoadId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Product by ID for an Organization
     * @param orgName 
     * @param groupBy Sort data response by Field, ascending or descending
     * @param productId A Product ID string
     * @param destinationId 
     * @param subCatalogId 
     * @param tabName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientProductById(orgName: string, groupBy: GroupBy, productId: string, destinationId?: number, subCatalogId?: string, tabName?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public getClientProductById(orgName: string, groupBy: GroupBy, productId: string, destinationId?: number, subCatalogId?: string, tabName?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public getClientProductById(orgName: string, groupBy: GroupBy, productId: string, destinationId?: number, subCatalogId?: string, tabName?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public getClientProductById(orgName: string, groupBy: GroupBy, productId: string, destinationId?: number, subCatalogId?: string, tabName?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgName === null || orgName === undefined) {
            throw new Error('Required parameter orgName was null or undefined when calling getClientProductById.');
        }
        if (groupBy === null || groupBy === undefined) {
            throw new Error('Required parameter groupBy was null or undefined when calling getClientProductById.');
        }
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getClientProductById.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (destinationId !== undefined && destinationId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>destinationId, 'destinationId');
        }
        if (subCatalogId !== undefined && subCatalogId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>subCatalogId, 'subCatalogId');
        }
        if (tabName !== undefined && tabName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>tabName, 'tabName');
        }
        if (groupBy !== undefined && groupBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>groupBy, 'groupBy');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/client/v1/products/${encodeURIComponent(String(orgName))}/get/${encodeURIComponent(String(productId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Product Import Status by ID for an Organization
     * @param orgName 
     * @param statsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientProductImportById(orgName: string, statsId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public getClientProductImportById(orgName: string, statsId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public getClientProductImportById(orgName: string, statsId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public getClientProductImportById(orgName: string, statsId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgName === null || orgName === undefined) {
            throw new Error('Required parameter orgName was null or undefined when calling getClientProductImportById.');
        }
        if (statsId === null || statsId === undefined) {
            throw new Error('Required parameter statsId was null or undefined when calling getClientProductImportById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/client/v1/products/${encodeURIComponent(String(orgName))}/import/status/${encodeURIComponent(String(statsId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Products for an Organization
     * @param orgName 
     * @param filterDefinition A filter to filter products by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientProducts(orgName: string, filterDefinition?: FilterDefinition, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public getClientProducts(orgName: string, filterDefinition?: FilterDefinition, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public getClientProducts(orgName: string, filterDefinition?: FilterDefinition, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public getClientProducts(orgName: string, filterDefinition?: FilterDefinition, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgName === null || orgName === undefined) {
            throw new Error('Required parameter orgName was null or undefined when calling getClientProducts.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/client/v1/products/${encodeURIComponent(String(orgName))}/get`,
            filterDefinition,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set a batch as complete for an Organization
     * @param formatCode 
     * @param orgName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postClientBatchFinish(formatCode: string, orgName: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public postClientBatchFinish(formatCode: string, orgName: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public postClientBatchFinish(formatCode: string, orgName: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public postClientBatchFinish(formatCode: string, orgName: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (formatCode === null || formatCode === undefined) {
            throw new Error('Required parameter formatCode was null or undefined when calling postClientBatchFinish.');
        }
        if (orgName === null || orgName === undefined) {
            throw new Error('Required parameter orgName was null or undefined when calling postClientBatchFinish.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/client/v1/batch/finish/${encodeURIComponent(String(formatCode))}/${encodeURIComponent(String(orgName))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new batch load start for an Organization
     * @param formatCode 
     * @param orgName 
     * @param partnerKey 
     * @param pushType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postClientBatchStart(formatCode: string, orgName: string, partnerKey: string, pushType: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public postClientBatchStart(formatCode: string, orgName: string, partnerKey: string, pushType: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public postClientBatchStart(formatCode: string, orgName: string, partnerKey: string, pushType: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public postClientBatchStart(formatCode: string, orgName: string, partnerKey: string, pushType: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (formatCode === null || formatCode === undefined) {
            throw new Error('Required parameter formatCode was null or undefined when calling postClientBatchStart.');
        }
        if (orgName === null || orgName === undefined) {
            throw new Error('Required parameter orgName was null or undefined when calling postClientBatchStart.');
        }
        if (partnerKey === null || partnerKey === undefined) {
            throw new Error('Required parameter partnerKey was null or undefined when calling postClientBatchStart.');
        }
        if (pushType === null || pushType === undefined) {
            throw new Error('Required parameter pushType was null or undefined when calling postClientBatchStart.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/client/v1/batch/start/${encodeURIComponent(String(formatCode))}/${encodeURIComponent(String(orgName))}/${encodeURIComponent(String(partnerKey))}/${encodeURIComponent(String(pushType))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates / Updates product data
     * This endpoint will update a Product Data
     * @param orgName 
     * @param clientProducts 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postClientProducts(orgName: string, clientProducts?: ClientProducts, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductImportStatus>;
    public postClientProducts(orgName: string, clientProducts?: ClientProducts, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductImportStatus>>;
    public postClientProducts(orgName: string, clientProducts?: ClientProducts, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductImportStatus>>;
    public postClientProducts(orgName: string, clientProducts?: ClientProducts, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgName === null || orgName === undefined) {
            throw new Error('Required parameter orgName was null or undefined when calling postClientProducts.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ProductImportStatus>(`${this.configuration.basePath}/api/client/v1/products/${encodeURIComponent(String(orgName))}`,
            clientProducts,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates / Updates Customer and Locations data
     * This endpoint will update a Customer Data
     * @param formatCode 
     * @param orgName 
     * @param batchLoadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFormatCodeCustomer(formatCode: string, orgName: string, batchLoadId?: number, body?: object, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductImportStatus>;
    public postFormatCodeCustomer(formatCode: string, orgName: string, batchLoadId?: number, body?: object, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductImportStatus>>;
    public postFormatCodeCustomer(formatCode: string, orgName: string, batchLoadId?: number, body?: object, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductImportStatus>>;
    public postFormatCodeCustomer(formatCode: string, orgName: string, batchLoadId?: number, body?: object, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (formatCode === null || formatCode === undefined) {
            throw new Error('Required parameter formatCode was null or undefined when calling postFormatCodeCustomer.');
        }
        if (orgName === null || orgName === undefined) {
            throw new Error('Required parameter orgName was null or undefined when calling postFormatCodeCustomer.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (batchLoadId !== undefined && batchLoadId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>batchLoadId, 'batchLoadId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ProductImportStatus>(`${this.configuration.basePath}/api/client/v1/customer/${encodeURIComponent(String(formatCode))}/${encodeURIComponent(String(orgName))}`,
            body,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates / Updates inventory data
     * This endpoint will update a inventory Data
     * @param formatCode 
     * @param orgName 
     * @param batchLoadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFormatCodeInventory(formatCode: string, orgName: string, batchLoadId?: number, body?: object, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductImportStatus>;
    public postFormatCodeInventory(formatCode: string, orgName: string, batchLoadId?: number, body?: object, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductImportStatus>>;
    public postFormatCodeInventory(formatCode: string, orgName: string, batchLoadId?: number, body?: object, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductImportStatus>>;
    public postFormatCodeInventory(formatCode: string, orgName: string, batchLoadId?: number, body?: object, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (formatCode === null || formatCode === undefined) {
            throw new Error('Required parameter formatCode was null or undefined when calling postFormatCodeInventory.');
        }
        if (orgName === null || orgName === undefined) {
            throw new Error('Required parameter orgName was null or undefined when calling postFormatCodeInventory.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (batchLoadId !== undefined && batchLoadId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>batchLoadId, 'batchLoadId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ProductImportStatus>(`${this.configuration.basePath}/api/client/v1/inventory/${encodeURIComponent(String(formatCode))}/${encodeURIComponent(String(orgName))}`,
            body,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates / Updates pricing data
     * This endpoint will update a pricing Data
     * @param formatCode 
     * @param orgName 
     * @param batchLoadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFormatCodePricing(formatCode: string, orgName: string, batchLoadId?: number, body?: object, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductImportStatus>;
    public postFormatCodePricing(formatCode: string, orgName: string, batchLoadId?: number, body?: object, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductImportStatus>>;
    public postFormatCodePricing(formatCode: string, orgName: string, batchLoadId?: number, body?: object, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductImportStatus>>;
    public postFormatCodePricing(formatCode: string, orgName: string, batchLoadId?: number, body?: object, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (formatCode === null || formatCode === undefined) {
            throw new Error('Required parameter formatCode was null or undefined when calling postFormatCodePricing.');
        }
        if (orgName === null || orgName === undefined) {
            throw new Error('Required parameter orgName was null or undefined when calling postFormatCodePricing.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (batchLoadId !== undefined && batchLoadId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>batchLoadId, 'batchLoadId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ProductImportStatus>(`${this.configuration.basePath}/api/client/v1/pricing/${encodeURIComponent(String(formatCode))}/${encodeURIComponent(String(orgName))}`,
            body,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates / Updates product data
     * This endpoint will update a Product Data
     * @param formatCode 
     * @param orgName 
     * @param productType 
     * @param batchLoadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFormatCodeProducts(formatCode: string, orgName: string, productType?: string, batchLoadId?: number, body?: object, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductImportStatus>;
    public postFormatCodeProducts(formatCode: string, orgName: string, productType?: string, batchLoadId?: number, body?: object, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductImportStatus>>;
    public postFormatCodeProducts(formatCode: string, orgName: string, productType?: string, batchLoadId?: number, body?: object, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductImportStatus>>;
    public postFormatCodeProducts(formatCode: string, orgName: string, productType?: string, batchLoadId?: number, body?: object, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (formatCode === null || formatCode === undefined) {
            throw new Error('Required parameter formatCode was null or undefined when calling postFormatCodeProducts.');
        }
        if (orgName === null || orgName === undefined) {
            throw new Error('Required parameter orgName was null or undefined when calling postFormatCodeProducts.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (productType !== undefined && productType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>productType, 'ProductType');
        }
        if (batchLoadId !== undefined && batchLoadId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>batchLoadId, 'batchLoadId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ProductImportStatus>(`${this.configuration.basePath}/api/client/v1/products/${encodeURIComponent(String(formatCode))}/${encodeURIComponent(String(orgName))}`,
            body,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates / Updates product data
     * This endpoint will update a Product Data
     * @param orgName 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postProducts(orgName: string, requestBody?: Array<{ [key: string]: any; }>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductImportStatus>;
    public postProducts(orgName: string, requestBody?: Array<{ [key: string]: any; }>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductImportStatus>>;
    public postProducts(orgName: string, requestBody?: Array<{ [key: string]: any; }>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductImportStatus>>;
    public postProducts(orgName: string, requestBody?: Array<{ [key: string]: any; }>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgName === null || orgName === undefined) {
            throw new Error('Required parameter orgName was null or undefined when calling postProducts.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ProductImportStatus>(`${this.configuration.basePath}/api/client/v1/products/list/${encodeURIComponent(String(orgName))}`,
            requestBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
