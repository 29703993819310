<!-- draw the frame and add a row for each workflow step -->
<div class="chart-container">

  <div class="legend">
    <div class="legend-item">
      <div class="figure errors"></div>
      <div class="legend-label">Errors</div>
    </div>
    <div class="legend-item">
      <div class="figure warnings"></div>
      <div class="legend-label">Warnings</div>
    </div>
    <div class="legend-item">
      <div class="figure approvals"></div>
      <div class="legend-label">Approvals</div>
    </div>
    <div class="legend-item">
      <div class="figure passed"></div>
      <div class="legend-label">Ready</div>
    </div>
  </div>

  <div class="chart-body">
    <div class="column labels">
      <div class="cell date-label title">
        Release Date
      </div>
      <div class="cell column-header label">
        # Not Ready
      </div>
      <div class="cell category-label" *ngFor="let category of workflow.categories">
        {{ category.name }}
      </div>
      <!-- <div class="cell date-label title">
      </div> -->
    </div>
    <div class="column" *ngFor="let date of dates">
      <div class="cell date-label">
        {{ date.date }}
      </div>
      <div class="cell column-header">
        {{ date.totalWarnings + date.totalErrors }}
      </div>
      <div class="cell value" *ngFor="let category of date.categories">
        <div class="figure errors orbit" *ngIf="(category.warnings + category.errors) > 0">
          {{ category.errors }}
        </div>

        <div class="figure total"
          [ngClass]="{'red': category.errors > 0, 'amber': category.errors === 0 && category.warnings > 0, 'blue': category.errors === 0 && category.warnings === 0 && category.approvals > 0}">
          {{ ((category.passes)/(category.warnings + category.errors + category.passes) | percent)}}
        </div>

        <div class="figure warnings orbit" *ngIf="(category.warnings + category.errors) > 0">
          {{ category.warnings }}
        </div>

      </div>
    </div>
  </div>
</div>
