import { Component, OnInit, Input, ViewChildren, QueryList, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FilterMatch, FilterJoin, Suggestion } from '@app/core/api';
import { Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { LoadOrganizationFiltersAction, AddOrganizationFilterAction } from '@app/features/organization/store/organization.actions';
import { Store, Select } from '@ngxs/store';
import { Filter } from '@app/core/api/model/filter';
import { SearchFilter, QueryFilterService, QueryFilter, MoreQueryFilter, QueryFilterConfiguration } from '../../../services/query-filter.service';
import { debounceTime, first } from 'rxjs/operators';
import { QueryFilterQuickComponent } from '../query-filter-quick/query-filter-quick.component';
import { QueryFilterPickerComponent } from '../query-filter-picker/query-filter-picker.component';

@Component({
  selector: 'app-query-filters',
  templateUrl: './query-filters.component.html',
  styleUrls: ['./query-filters.component.scss'],
  providers: [QueryFilterService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryFiltersComponent implements OnInit, OnDestroy {
  @Input() suggestions: { [key: string]: Array<Suggestion> } = {}; // A list of provided suggestions for the query filters
  @Input() queryFilters: Array<QueryFilter> = [];
  @Input() quickFilters: Array<QueryFilter> = [];
  @Input() moreFilters: Array<MoreQueryFilter> = [];

  @Input() loadProductTypeQueryFiltersAction: ({ viewName }: { viewName: string }) => void;

  @Input() hideSavedViews = false;
  @Input() hideFuzzySearch = false;

  @Select(OrganizationState.getFilters) savedFilters$: Observable<Array<Filter>>;

  @ViewChildren(QueryFilterQuickComponent) quickChildren!: QueryList<QueryFilterQuickComponent>;

  savedViewsExpanded = false;
  advancedFiltersExpanded = false;
  moreFiltersExpanded = false

  showAdvancedFiltersModal = false;
  showSaveViewModal = false;

  sortAscending = true;

  tempFilters: Array<SearchFilter> = [];

  filterViewNameControl: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  fuzzySearchForm: UntypedFormGroup = new UntypedFormGroup({
    input: new UntypedFormControl('')
  });

  filters$: Observable<Array<SearchFilter>> = this.queryFilterSvc.filters$;

  filtersSub: Subscription;

  constructor(
    private router: Router,
    private store: Store,
    public queryFilterSvc: QueryFilterService
  ) { }

  ngOnInit(): void {
    this.filtersSub = this.filters$.pipe(
      first()
    ).subscribe(
      (filters: Array<SearchFilter>) => {
        this.advancedFiltersExpanded = filters?.length > 0 ? true : false;
      }
    );

    this.store.dispatch(new LoadOrganizationFiltersAction());
  }

  ngOnDestroy(): void {
    this.filtersSub.unsubscribe();
  }

  removeFilter({ queryFilter, index }): void {
    let indexes: Array<number> = [];
    if (queryFilter.filters && queryFilter.filters.length > 0) {
      indexes = [
        ...indexes,
        ...queryFilter.filters.reduce(
          (acc: Array<number>, filters: Array<QueryFilterConfiguration>) => {
            const subIndexes: Array<number> = filters.reduce(
              (subAcc: Array<number>, filter: QueryFilterConfiguration) => {
                const index = this.queryFilterSvc.filters.findIndex(f => f.by === queryFilter.propertyName && f.match === filter.match);
                if (index > -1) {
                  return [...subAcc, index];
                }
                return subAcc;
              },
              []
            );
            return [...acc, ...subIndexes];
          },
          []
        )
      ];
    } else {
      indexes = [index]
    }
    this.queryFilterSvc.removeFilters(indexes);
  }

  resetFilters(): void {
    this.queryFilterSvc.resetFilters();
    this.fuzzySearchForm.reset();
  }

  resetTempFilters(filters: Array<SearchFilter>): void {
    this.tempFilters = [...filters];
  }

  applyAdvancedFilters(component: QueryFilterPickerComponent) {
    const { filters, queryFilterForm: { selectedProductType }, resetForm } = component;
    this.showAdvancedFiltersModal = false;
    this.queryFilterSvc.applyFilters({
      by: filters.map(f => f.by),
      join: filters.map(f => f.join),
      match: filters.map(f => f.match),
      value: filters.map(f => f.value)
    }, selectedProductType).then(() => resetForm());
  }

  saveView() {
    const filterObject = this.queryFilterSvc.convertCurrentFiltersToFilterObject(this.filterViewNameControl.value);
    this.filterViewNameControl.reset();
    this.store.dispatch(new AddOrganizationFilterAction({ filter: filterObject }));
    this.showSaveViewModal = false;
  }

  fuzzySearch() {
    const filterBy: Array<string> =
      ['productName', 'styleNumber', 'sku', 'upc'];
    let filterJoin: Array<FilterJoin> = [];
    let filterMatch: Array<FilterMatch> = [];
    let filterValue: Array<string> = [];

    filterBy.forEach(
      (v, i) => {
        filterJoin = [...filterJoin, i > 0 ? FilterJoin.OR : FilterJoin.AND];
        filterMatch = [...filterMatch, FilterMatch.CONTAINS];
        filterValue = [...filterValue, this.fuzzySearchForm.value.input];
      }
    );

    this.router.navigate([], {
      queryParams: {
        filterBy,
        filterJoin,
        filterMatch,
        filterValue
      },
      queryParamsHandling: 'merge'
    });
  }

  collapseAllQuickFilters(): void {
    this.savedViewsExpanded = false;
    this.advancedFiltersExpanded = false;
    this.moreFiltersExpanded = false;
    this.quickChildren.forEach(
      (quick: QueryFilterQuickComponent) => {
        quick.expanded = false;
      }
    );
  }

  get oneOrMoreExpanded$(): Observable<boolean> {
    return of(this.oneOrMoreExpanded).pipe(debounceTime(0));
  }

  get oneOrMoreExpanded(): boolean {
    const others = this.savedViewsExpanded || this.advancedFiltersExpanded || this.moreFiltersExpanded;
    if (!this.quickChildren) {
      return others;
    }
    const quickFilters = this.quickChildren.toArray().reduce(
      (acc: boolean, quick: QueryFilterQuickComponent) => {
        if (quick.expanded) {
          return true;
        }
        return acc;
      },
      false
    );
    return quickFilters || others;
  }

  queryFilterModalClosed(component: QueryFilterPickerComponent): void {
    component.resetForm();
    if (!this.queryFilterSvc.filters || this.queryFilterSvc.filters.length === 0) {
      component.queryFilterForm.selectedProductType = undefined;
    }
  }
}
