import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';

import {
  AddToastAction,
  RemoveToastAction,
  UpdateToastProgressAction,
  UpdateToastMessageAction,
  ResetToastAction
} from '../stores/toast.actions';

import { Notification, NotificationProgress, NotificationAction, NotificationType } from '../api';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private router: Router, private store: Store) { }

  // Add a new toast, pass in an `expiration` if you want it to disappear
  add(toast: Notification): Notification {
    if (!toast.id) {
      toast.id = Math.floor(Math.random() * 1000000);
    }
    if (!toast.sentTime) {
      toast.sentTime = moment().format();
    }
    if (!toast.icon) {
      if (toast.type === NotificationType.SUCCESS) {
        toast.icon = 'check-circle';
      } else if (toast.type === NotificationType.ERROR) {
        toast.icon = 'exclamation-circle';
      } else if (toast.type === NotificationType.WARN) {
        toast.icon = 'exclamation-triangle';
      } else {
        toast.icon = 'info-circle';
      }
    }
    this.store.dispatch(new AddToastAction(toast)).subscribe(
      () => {
        if (toast.expiration) {
          this.removeWhenExpired(toast);
        }
      }
    );
    return toast;
  }

  private removeWhenExpired(toast: Notification): void {
    const sentTime: number = moment(toast.sentTime).valueOf();
    const expirationTimeFromNow: number = moment().valueOf() + toast.expiration;
    setTimeout(
      () => {
        this.remove(toast);
      },
      expirationTimeFromNow - sentTime
    );
  }

  remove(toast: Notification): Observable<Notification> {
    this.store.dispatch(new RemoveToastAction(toast));
    return of(toast); // Return the passed Notification as an Observable
  }

  updateProgress(toast: Notification, progress: NotificationProgress): Observable<Notification> {
    this.store.dispatch(new UpdateToastProgressAction({ toast, progress }));
    return of(toast); // Return the toast as an Observable
  }

  updateMessage(toast: Notification, message: Notification['message']): Observable<Notification> {
     this.store.dispatch(new UpdateToastMessageAction({ toast, message }));
     return of(toast); 
  }

  action(toast: Notification, target?: NotificationAction): void {
    if (target) {
      this.router.navigateByUrl(target.route);
    } else if (toast.route) {
      this.router.navigateByUrl(toast.route);
    }
  }

  actionDismiss(toast: Notification, target?: NotificationAction) {
    this.action(toast, target);
    this.remove(toast);
  }

  reset(): Observable<Notification> {
    this.store.dispatch(new ResetToastAction());
    // Return an Observable with a default or placeholder Notification object
    const defaultNotification: Notification = {
      // Set default values as needed
    };
    return of(defaultNotification);
  }
}
