<!-- <div class="control">
  <div class="label">Match Start:</div>
  <input [formControl]="form.get('matchStart')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Match Start:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
          [formControl]="form.get('matchStart')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Match Next:</div>
  <input [formControl]="form.get('matchNext')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Match Next:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
          [formControl]="form.get('matchNext')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Match End:</div>
  <input [formControl]="form.get('matchEnd')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Match End:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
          [formControl]="form.get('matchEnd')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Name:</div>
  <input [formControl]="form.get('name')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Name:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
          [formControl]="form.get('name')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Display Name:</div>
  <input [formControl]="form.get('displayName')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Display Name:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
          [formControl]="form.get('displayName')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Xpath:</div>
  <input [formControl]="form.get('xpath')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Xpath:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
          [formControl]="form.get('xpath')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Output Name:</div>
  <input [formControl]="form.get('outputName')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Output Name:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
          [formControl]="form.get('outputName')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Parent:</div>
  <input [formControl]="form.get('parent')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Parent:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
          [formControl]="form.get('parent')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Token:</div>
  <input [formControl]="form.get('token')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Token:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
          [formControl]="form.get('token')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Use Parent Line:</div>
  <app-checkbox shape="square" (selectedChange)="form.get('useParentLine').setValue($event)"
    [selected]="form.get('useParentLine').value"></app-checkbox>
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Use Parent
      Line:</label>
    <div class="tw-mt-2">
      <div>
        <app-checkbox shape="square" (selectedChange)="form.get('useParentLine').setValue($event)"
          [selected]="form.get('useParentLine').value"></app-checkbox>
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Process End Match:</div>
  <app-checkbox shape="square" (selectedChange)="form.get('processEndMatch').setValue($event)"
    [selected]="form.get('processEndMatch').value"></app-checkbox>
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Process End
      Match:</label>
    <div class="tw-mt-2">
      <div>
        <app-checkbox shape="square" (selectedChange)="form.get('processEndMatch').setValue($event)"
          [selected]="form.get('processEndMatch').value"></app-checkbox>
      </div>
    </div>
  </div>
</div>

<!-- <div class="control">
  <div class="label">Bulk Output Rows:</div>
  <input [formControl]="form.get('bulkOutputRows')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Bulk Output
      Rows:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
          [formControl]="form.get('bulkOutputRows')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Current Line:</div>
  <input type="number" [formControl]="form.get('currentLine')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Current Line:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="number"
          [formControl]="form.get('currentLine')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Current Page:</div>
  <input type="number" [formControl]="form.get('currentPage')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Current Page:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="number"
          [formControl]="form.get('currentPage')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Reset Current Line:</div>
  <input type="number" [formControl]="form.get('resetCurrentLine')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Reset Current
      Line:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="number"
          [formControl]="form.get('resetCurrentLine')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Data Buffer Name:</div>
  <input [formControl]="form.get('dataBufferName')" />
</div> -->
<!-- <div class="control">
  <div class="label">Delete After Run:</div>
  <app-checkbox shape="square" (selectedChange)="form.get('deleteAfterRun').setValue($event)" [selected]="form.get('deleteAfterRun').value"></app-checkbox>
</div> -->

<!-- <div class="control">
  <div class="label">Duplicate Field Name:</div>
  <input [formControl]="form.get('duplicateFieldName')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Duplicate Field
      Name:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
          [formControl]="form.get('duplicateFieldName')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Same Field Name Parent:</div>
  <input [formControl]="form.get('sameFieldNameParent')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Same Field Name
      Parent:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
          [formControl]="form.get('sameFieldNameParent')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">End Line Number:</div>
  <input type="number" [formControl]="form.get('endLineNumber')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">End Line
      Number:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="number"
          [formControl]="form.get('endLineNumber')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Field Same:</div>
  <app-checkbox shape="square" (selectedChange)="form.get('fieldSame').setValue($event)" [selected]="form.get('fieldSame').value"></app-checkbox>
</div> -->
<!-- <div class="control">
  <div class="label">Format Name:</div>
  <input [formControl]="form.get('formatName')" />
</div> -->
<!-- <div class="control">
  <div class="label">Header Record:</div>
  <app-checkbox shape="square" (selectedChange)="form.get('headerRecord').setValue($event)"
    [selected]="form.get('headerRecord').value"></app-checkbox>
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Header Record:</label>
    <div class="tw-mt-2">
      <div>
        <app-checkbox shape="square" (selectedChange)="form.get('headerRecord').setValue($event)"
          [selected]="form.get('headerRecord').value"></app-checkbox>
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Header Row:</div>
  <input type="number" [formControl]="form.get('headerRow')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Header Row:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="number"
          [formControl]="form.get('headerRow')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Inactive:</div>
  <app-checkbox shape="square" (selectedChange)="form.get('inactive').setValue($event)"
    [selected]="form.get('inactive').value"></app-checkbox>
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Inactive:</label>
    <div class="tw-mt-2">
      <div>
        <app-checkbox shape="square" (selectedChange)="form.get('inactive').setValue($event)"
          [selected]="form.get('inactive').value"></app-checkbox>
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Interface Id:</div>
  <input [formControl]="form.get('interfaceId')" />
</div> -->

<!-- <div class="control">
  <div class="label">Match Next2:</div>
  <input [formControl]="form.get('matchNext2')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Match Next2:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
          [formControl]="form.get('matchNext2')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>

<!-- <div class="control">
  <div class="label">Match Start2:</div>
  <input [formControl]="form.get('matchStart2')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Match Start2:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
          [formControl]="form.get('matchStart2')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Min Number:</div>
  <input type="number" [formControl]="form.get('minNumber')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Min Number:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="number"
          [formControl]="form.get('minNumber')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>

<!-- <div class="control">
  <div class="label">Number:</div>
  <input type="number" [formControl]="form.get('number')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Number:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="number"
          [formControl]="form.get('number')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>

<!-- <div class="control">
  <div class="label">Occurrence:</div>
  <input [formControl]="form.get('occurrence')" />
</div> -->

<!-- <div class="control">
  <div class="label">Page End:</div>
  <input type="number" [formControl]="form.get('pageEnd')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Page End:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="number"
          [formControl]="form.get('pageEnd')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>

<!-- <div class="control">
  <div class="label">Page Start:</div>
  <input type="number" [formControl]="form.get('pageStart')" />
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Page Start:</label>
    <div class="tw-mt-2">
      <div
        class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
        <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="number"
          [formControl]="form.get('pageStart')"
          class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
      </div>
    </div>
  </div>
</div>

<!-- <div class="control">
  <div class="label">Parent Current Line:</div>
  <input type="number" [formControl]="form.get('parentCurrentLine')" />
</div> -->
<!-- <div class="control">
  <div class="label">Root Block:</div>
  <app-checkbox shape="square" (selectedChange)="form.get('rootBlock').setValue($event)"
    [selected]="form.get('rootBlock').value"></app-checkbox>
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Root Block:</label>
    <div class="tw-mt-2">
      <div>
        <app-checkbox shape="square" (selectedChange)="form.get('rootBlock').setValue($event)"
          [selected]="form.get('rootBlock').value"></app-checkbox>
      </div>
    </div>
  </div>
</div>
<!-- <div class="control">
  <div class="label">Skip Count:</div>
  <app-checkbox shape="square" (selectedChange)="form.get('skipCount').setValue($event)"
    [selected]="form.get('skipCount').value"></app-checkbox>
</div> -->
<div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2 tw-mb-6">
  <div class="sm:tw-col-span-4">
    <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Skip Count:</label>
    <div class="tw-mt-2">
      <div>
        <app-checkbox shape="square" (selectedChange)="form.get('skipCount').setValue($event)"
          [selected]="form.get('skipCount').value"></app-checkbox>
      </div>
    </div>
  </div>
</div>