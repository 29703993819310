import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FunctionType, TemplateService} from '@app/shared/services/template.service';
import {Catalog, DataProcessField, Organization, SuggestedMatch, Suggestion, TemplateApiService} from '@app/core';
import {ChipType} from '@app/shared/components/template/destination-chip/destination-chip.component';
import {TemplateColumn, TemplateColumnType, TemplateViewAs} from '../template/template.component';
import {of, Subject, Subscription} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {OrganizationState} from '@app/features/organization/store/organization.state';

@Component({
  selector: 'app-template-row',
  templateUrl: './template-row.component.html',
  styleUrls: ['./template-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateRowComponent implements OnInit, OnChanges, OnDestroy {
  @Input() index: number;
  @Input() fieldsLength: number;
  @Input() field: DataProcessField;
  @Input() isEditing = false;
  @Input() dataTypeSuggestions: Array<Suggestion> = [];
  @Input() syndic8FieldSuggestions: Array<Suggestion> = [];
  @Input() acsFieldSuggestions: Array<Suggestion> = [];
  @Input() destination: Catalog;
  @Input() form: UntypedFormGroup;
  @Output() editComment: EventEmitter<DataProcessField> = new EventEmitter();
  @Output() editExample: EventEmitter<DataProcessField> = new EventEmitter();
  @Output() editDefinition: EventEmitter<DataProcessField> = new EventEmitter();
  @Output() editFunction: EventEmitter<DataProcessField> = new EventEmitter();
  @Output() editJsonPath: EventEmitter<DataProcessField> = new EventEmitter();
  @Output() editFieldBlock: EventEmitter<DataProcessField> = new EventEmitter();
  @Output() remove: EventEmitter<DataProcessField> = new EventEmitter();
  @Output() insertRow: EventEmitter<number> = new EventEmitter();
  @Input() viewAs: TemplateViewAs = TemplateViewAs.Syndic8;
  @Input() columns: Array<TemplateColumn> = [];
  @Input() templateInputType = 'CSV';
  @Input() templateOutputType = 'CSV';
  @Input() isParent = false;
  @Output() blur: EventEmitter<InputEvent> = new EventEmitter();
  @Input() draggable = false;
  @Input() highlightRequired = false;

  @HostBinding('class.disabled') disabled = false;

  columnSelected = this.templateSvc.columnSelected.bind(this.templateSvc);
  getConcatCase = this.templateSvc.getConcatCase.bind(this.templateSvc);
  setRequired = this.templateSvc.setRequired.bind(this.templateSvc);
  setRecommended = this.templateSvc.setRecommended.bind(this.templateSvc);
  setConditionallyRequired = this.templateSvc.setConditionallyRequired.bind(this.templateSvc);
  getFunctionValue = this.templateSvc.getFunctionValue.bind(this.templateSvc);
  getFunctionType = this.templateSvc.getFunctionType.bind(this.templateSvc);
  getFunctionValueChips = this.templateSvc.getFunctionValueChips.bind(this.templateSvc);
  getConvert = this.templateSvc.getConvert.bind(this.templateSvc);
  getFunctionValidValueDisplay = this.templateSvc.getFunctionValidValueDisplay.bind(this.templateSvc);
  caseFunctionSuggestions = this.templateSvc.caseFunctionSuggestions;

  private nameChangeDebouncer: Subject<string> = new Subject();

  syndic8FieldMatchLoading = false;
  showTooltip = false;
  showAITooltip = false;
  showParentTemplate = false;

  ChipType = ChipType;
  FunctionType = FunctionType;
  TemplateViewAs = TemplateViewAs;
  TemplateColumnType = TemplateColumnType;

  nameChangeDebouncerSub: Subscription;

  constructor(
    private templateSvc: TemplateService,
    private templateApi: TemplateApiService,
    private store: Store,
    private cd: ChangeDetectorRef
  ) { }

  get fieldType(): FunctionType {
    return this.getFunctionType(this.form.value);
  }

  ngOnInit(): void {
    this.nameChangeDebouncerSub = this.nameChangeDebouncer
      .pipe(debounceTime(450), distinctUntilChanged())
      .subscribe(name => this.loadSyndic8FieldMatches(name));

    this.setDisabled();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isEditing && changes.isEditing.previousValue !== changes.isEditing.currentValue) {
      this.setDisabled();
    }
  }

  ngOnDestroy(): void {
    this.nameChangeDebouncerSub.unsubscribe();
  }

  trackByFn(index: number, column: TemplateColumn): any {
    return column.type;
  }

  nameChange(): void {
    const { value } = this.form.get('name');
    if (!value) {
      return;
    }
    this.nameChangeDebouncer.next(value);
  }

  public loadSyndic8FieldMatches(inputName: string): void {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.syndic8FieldMatchLoading = true;
    this.cd.markForCheck();
    this.templateApi.getSuggestionMatches(this.destination.destinations[0].id, organization.id, [{ inputName }]).pipe(
      tap((matches: Array<SuggestedMatch>) => {
        if (matches?.length > 0 && matches[0]?.id) {
          this.form.get('suggestedMatch').setValue(true);
          this.form.get('fieldStartMatch').setValue(matches[0].id);
        } else {
          this.form.get('suggestedMatch').setValue(false);
          this.form.get('fieldStartMatch').setValue(this.field?.fieldStartMatch);
        }
        this.syndic8FieldMatchLoading = false;
        this.cd.markForCheck();
      }),
      catchError(error => {
        this.form.get('suggestedMatch').setValue(false);
        this.form.get('fieldStartMatch').setValue(this.field?.fieldStartMatch);
        this.syndic8FieldMatchLoading = false;
        this.cd.markForCheck();
        return of(error);
      })
    ).subscribe();
  }

  private setDisabled(): void {
    this.disabled = this.isParent && this.field?.parentOverrideField === false && this.isEditing;
  }

  getConcatDelimiter(field: DataProcessField): string {
    return `${FunctionType.ConcatDelimiter}${this.getConvert(field)}`;
  }

  internalFieldSuggestionLookup(str: string, suggestions: Array<Suggestion>): string {
    if (!str || !suggestions) {
      return '';
    }
    return suggestions.find(s => s.id === str)?.name;
  }

  setFieldStartMatch(value: string): void {
    // This would prevent blanking out the form, we want to be able to do that.
    // if (!value) {
    //   return;
    // }
    this.form.markAsDirty();
    if (this.templateInputType === 'XML' || this.templateInputType === 'EDI' || this.templateInputType === 'JSON') {
      this.form.patchValue({ fieldStartMatch: `XPATH:${value}` });

      if (this.templateInputType === 'JSON')
        this.form.patchValue({ fieldStartMatch: `JSONPATH:${value}` });
    } else {
      this.form.patchValue({ fieldStartMatch: value });
    }
  }

  setDataType(value: string) {
    this.form.patchValue({ dataType: value });
    this.form.markAsDirty();
  }

  clearFieldStartMatch(): void {
    this.form.markAsDirty();
    this.form.patchValue({ fieldStartMatch: '' });
  }

  get selectedDataType(): string {
    const { value } = this.form.get('dataType');
    return value;
  }

  get selectedFieldStartMatch(): string {
    const { value } = this.form.get('fieldStartMatch');
    if (this.viewAs === TemplateViewAs.Syndic8) {
      return value === 'COLUMNNAME:NOCOLUMN' ? '' : this.internalFieldSuggestionLookup(value, this.syndic8FieldSuggestions);
    }
    return value === 'COLUMNNAME:NOCOLUMN' ? '' : value;
  }

  get fieldStartMatchModel(): string {
    const { value } = this.form.get('fieldStartMatch');
    if (!value) {
      return;
    }

    if (value) {
      if (value.includes("JSONPATH:"))
        return value.split('JSONPATH:')[1];
    }

    return value.split('XPATH:')[1];
  }

  getOutboundInputMessage(outboundType: string): string {
    if (outboundType === "JSON")
      return 'Enter an Outbound JPATH';
    else if (outboundType === "XML")
      return 'Enter an Outbound XPATH';
    else if (outboundType === "EDI")
      return 'Enter an Outbound EDI PATH';
    else
      return 'Enter an Outbound XPATH';
  }

  setParentOverrideField(value: boolean): void {
    this.disabled = !value;
    const control = this.form.get('parentOverrideField');
    control.markAsDirty();
    control.setValue(value);
  }

  get isFieldRequired(): boolean {
    return this.highlightRequired && this.form?.get('requiredDisplay')?.value
  }

  get hasJsonSelector(): boolean {
    return this.columns.filter((e) => e.type === TemplateColumnType.JsonPath).length > 0;
  }
}
