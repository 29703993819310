<div class="previous" *ngIf="page.start !== 1" routerLink="." [queryParams]="{ 'page': page.previous }" queryParamsHandling="merge">
  <i class="fal fa-chevron-left"></i>
</div>
<div class="current-view">
  <span class="showing">Showing</span><span>{{ page.start || 0 }}</span><span class="through">through</span><span>{{ page.end || 0 }}</span>
</div>
<div class="next" (click)="nextPage()" *ngIf="_entities.length === page.length">
  <i class="fal fa-chevron-right"></i>
</div>
<div class="next-placeholder" *ngIf="_entities.length < page.length"></div>
