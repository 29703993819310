/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Audit } from './audit';
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';


export interface DynamicTable { 
    auditList?: Array<Audit>;
    createdDate?: ModifiedDate;
    dynamicTableId?: number;
    modifyDate?: ModifiedDate;
    orgRef?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    rowData?: { [key: string]: any; };
    tableName?: string;
    jsonData?: string;
}

