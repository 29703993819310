import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Notification } from '@app/core/api';
import { Store } from '@ngxs/store';
import { MarkNotificationReadAction } from '../../../stores/notification.actions';
import { RemoveNotificationAction } from '../../../stores/notification.actions';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-notification-action',
  templateUrl: './notification-action.component.html',
  styleUrls: ['./notification-action.component.scss']
})
export class NotificationActionComponent implements OnInit {
  @Input() notification: Notification;

  @HostListener('click')
  onClick() {
    if (this.notification.route) {
      this.navigation.collapseSelection();
      this.router.navigateByUrl(this.notification.route);
    }
    this.store.dispatch(new MarkNotificationReadAction(this.notification));
  }

  constructor(private router: Router, private store: Store, private navigation: NavigationService) { }

  ngOnInit() {
  }

  dismissNotification(event: Event, notification: Notification) {
    event.stopPropagation();
    this.store.dispatch(new RemoveNotificationAction(notification));
  }

}
