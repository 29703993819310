import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterJoin, Suggestion } from '@app/core';
import { FilterMatchOption, QueryFilter, QueryFilterConfiguration, QueryFilterService, QueryFilterType, SearchFilter } from '../../../services/query-filter.service';

export interface QueryFilterCard {
  by: string;
  join: FilterJoin;
  match: FilterMatchOption;
  value: string;
  displayName: string;
  type: QueryFilterType;
  filters?: Array<QueryFilterConfiguration>;
}

@Component({
  selector: 'app-query-filter-list',
  templateUrl: './query-filter-list.component.html',
  styleUrls: ['./query-filter-list.component.scss']
})
export class QueryFilterListComponent implements OnInit {
  @Input() suggestions: { [key: string]: Array<Suggestion> } = {};
  @Input() set queryFilters(queryFilters: Array<QueryFilter>) {
    this._queryFilters = queryFilters;
    this.setCards();
  };
  @Input() set filters(filters: Array<SearchFilter>) {
    this._filters = filters;
    this.setCards();
  };
  @Input() noFiltersMessage = 'No filters yet';
  @Output() removeFilter: EventEmitter<{ queryFilter: QueryFilter, index: number }> = new EventEmitter();

  _filters: Array<SearchFilter>;
  _queryFilters: Array<QueryFilter>;
  cards: Array<QueryFilterCard> = [];

  QueryFilterType = QueryFilterType;
  FilterJoin = FilterJoin;

  constructor(private queryFilterSvc: QueryFilterService) { }

  ngOnInit(): void {
  }

  private setCards(): void {
    if (!this._filters) {
      return;
    }
    this.cards = this._filters.filter(filter => !this.getQueryFilter(filter)?.obscured).reduce(
      (acc, { by, join, match, value }) => {
        const queryFilter: QueryFilter = this.getQueryFilter({ by, join, match, value });
        if (queryFilter) {
          if (queryFilter?.filters?.length > 0) {
            const filters = queryFilter?.filters.find(filter => filter.findIndex(f => f.by === by && f.join === join && f.match === match && f.value === value) > -1);
            return [...acc, { by, join, match: this.queryFilterSvc.getFilterMatchOption(match), value,
              displayName: queryFilter.displayName,
              type: queryFilter.type,
              filters
            }];
          }
          return [...acc, { by, join, match: this.queryFilterSvc.getFilterMatchOption(match), value,
            displayName: queryFilter.displayName,
            type: queryFilter.type
          }];
        }
        return acc;
      },
      []
    );
  }

  getQueryFilter({ by, join, match, value }: SearchFilter): QueryFilter {
    return this._queryFilters?.find(queryFilter => {
      if (queryFilter.propertyName === by) {
        return true;
      }
      if (queryFilter?.filters?.length > 0) {
        return queryFilter.filters.findIndex(filter => filter.findIndex(f => f.by === by && f.join === join && f.match === match && f.value === value) > -1) > -1;
      }
      return false;
    });
  }

  getValue({ by, join, match, value }: QueryFilterCard): string {
    const queryFilter: QueryFilter = this.getQueryFilter({ by, join, match: match.filterMatch, value });
    if (queryFilter?.suggestionDisplayName && this.suggestions[queryFilter.suggestion]) {
      return this.suggestions[queryFilter.suggestion].find(s => s.id == value)?.name;
    }
    return value;
  }

  isFirstFilter(card: QueryFilterCard): boolean {
    if (card?.filters?.length > 1) {
      const filterIndex = card.filters.findIndex(filter => filter.by === card.by && filter.join === card.join && filter.match === card.match.filterMatch && filter.value ===  card.value);
      return filterIndex === 0;
    }
    return false;
  }

  isMiddleFilter(card: QueryFilterCard): boolean {
    if (card?.filters?.length > 1) {
      const filterIndex = card.filters.findIndex(filter => filter.by === card.by && filter.join === card.join && filter.match === card.match.filterMatch && filter.value ===  card.value);
      return filterIndex > 0 && filterIndex < card.filters.length - 1;
    }
    return false;
  }

  isLastFilter(card: QueryFilterCard): boolean {
    if (card?.filters?.length > 1) {
      const filterIndex = card.filters.findIndex(filter => filter.by === card.by && filter.join === card.join && filter.match === card.match.filterMatch && filter.value ===  card.value);
      return filterIndex === card.filters.length - 1;
    }
    return false;
  }

  isBooleanTruthy(bool: string): boolean {
    return bool === 'true' || <unknown>bool === true;
  }
}
