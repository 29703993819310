<div class="table"
  [ngSwitch]="layout"
  infiniteScroll
  [infiniteScrollDisabled]="disableInfiniteScroll"
  [ngStyle]="{ 'padding-right': rightSidePadding + 'px' }"
  [ngClass]="{ 'grid': layout === InfiniteScrollLayout.Grid, 'list': layout === InfiniteScrollLayout.List, 'center': layout === InfiniteScrollLayout.Grid && centerGrid }"
  [infiniteScrollDistance]="1"
  [infiniteScrollThrottle]="50"
  [scrollWindow]="false"
  (scrolled)="onScrollDown()"
  #scrollWindow>
  <ng-container *ngSwitchCase="InfiniteScrollLayout.List">
    <app-infinite-scroll-header
      *ngIf="showHeader"
      [appCopyAsTable]="_columns?.length"
      [fromHeader]="true"
      [suggestions]="suggestions"
      [columns]="_columns"
      [showNewRow]="showNewRow"
      [showSelection]="showSelection"
      [showMasterCheckbox]="showMasterCheckbox"
      [chips]="chips"
      [chipWidth]="chipWidth"
      [actions]="actions"
      [processings]="processings"
      [images]="images"
      [titles]="titles"
      [titleWidth]="titleWidth"
      [showEllipsis]="showEllipsis"
      [showDownloadButton]="showDownloadButton"
      [showFilter]="showFilter"
      [showSort]="showSort"
      [showHandles]="showHandles"
      [hasFilters]="hasFilters"
      (hasFiltersChange)="hasFiltersChange.emit($event)"
      [style]="_style"
      [(minWidths)]="minWidths"
      [loading]="loading">
    </app-infinite-scroll-header>
    <app-infinite-scroll-new-row
      *ngIf="!loading && showNewRow"
      [columns]="_columns"
      [suggestions]="suggestions"
      [minWidths]="minWidths"
      [addAction]="addAction">
    </app-infinite-scroll-new-row>
    <ng-container *ngIf="_rows && _rows.length > 0">
      <ng-container *ngFor="let row of _rows; trackBy trackByFn; index as i">
        <app-infinite-scroll-row
          [isSingleSelected] = "row[_rowIdPropertyName] === selectedRowId"
          [appCopyAsTable]="_columns?.length"
          [rowColorTest]="rowColorTest"
          [columns]="_columns"
          [row]="row"
          [savedRows]="savedRows"
          [image]="images && images[i] ? images[i] : null"
          [title]="titles && [i] ? titles[i] : null"
          [titleWidth]="titleWidth"
          [chips]="chips && chips[i] ? chips[i] : null"
          [chipWidth]="chipWidth"
          [isColorCoded]="isColorCoded"
          [isColorCodeInverted]="isColorCodeInverted"
          [actions]="actions && actions[i] ? actions[i] : null"
          [warning]="warnings && warnings[i] ? warnings[i] : false"
          [processings]="processings"
          [processing]="processings && processings[i] ? true : false"
          [suggestions]="suggestions"
          [showNewRow]="showNewRow"
          [showSelection]="showSelection"
          [showEllipsis]="showEllipsis"
          [showDownloadButton]="showDownloadButton"
          (ellipsisClick)="ellipsisClick.emit($event)"
          (downloadButtonClick)="downloadButtonClick.emit($event)"
          [clickable]="rowNavigated.observers.length > 0"
          [doubleClick]="doubleClick"
          [style]="_style"
          [emptyCellText]="emptyCellText"
          [minWidths]="minWidths"
          [loading]="loading">
        </app-infinite-scroll-row>
      </ng-container>
      <div class="table-fill" *ngIf="_columns && _columns.length > 0 && _style === InfiniteScrollStyle.Logistics && !images && !titles && !chips">
        <div class="checkbox-placeholder" *ngIf="showSelection"></div>
        <div class="column-fill" *ngFor="let column of _columns; index as i"
          [ngStyle]="{
            'min-width': minWidths[i] ? minWidths[i] + 'px' : column.width ? column.width + 'px' : 100 / _columns.length + '%',
            'max-width': minWidths[i] ? minWidths[i] + 'px' : column.width ? column.width + 'px' : 100 / _columns.length + '%'
          }">
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="InfiniteScrollLayout.Grid">
    <ng-container *ngIf="_rows && _rows.length > 0">
      <ng-container *ngFor="let cell of _rows; index as i">
        <app-infinite-scroll-cell *ngIf="!customViewTemplate" [cell]="cell" [columns]="_columns"></app-infinite-scroll-cell>
        <ng-container *ngIf="customViewTemplate">
          <ng-container *ngTemplateOutlet="customViewTemplate, context: { $implicit: cell, index: i }"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <div class="no-rows" *ngIf="!loading && _rows && _rows.length === 0">
    <div class="spacer"></div>
    <div class="title">{{ noRowsMessage }}</div>
    <div class="subtitle" (click)="noRowsMessageClick.emit()">{{ noRowsSubtitle }}</div>
    <div class="spacer"></div>
  </div>
</div>
<div class="loading" *ngIf="loading">
  <div class="spacer"></div>
  <app-spinner [bigger]="true"></app-spinner>
  <div class="spacer"></div>
</div>
<div class="floater" *ngIf="showFloater && loadAction && !disableFloater">
  <div class="back-to-top" (click)="backToTop()">
    <i class="far fa-arrow-to-top fa-fw" title="Back to top..."></i>
  </div>
</div>
