<div class="option"
  [ngClass]="{'active': layout === InfiniteScrollLayout.Grid}"
  [ngStyle]="{'margin-left': layout !== InfiniteScrollLayout.Grid ? '1px' : 0}"
  (click)="gridView()">
    <i class="fad fa-image"></i>
    Gallery view
</div>
<div class="option"
  [ngClass]="{'active': layout === InfiniteScrollLayout.List}"
  [ngStyle]="{'margin-right': layout !== InfiniteScrollLayout.List ? '1px' : 0}"
  (click)="listView()">
    <i class="fad fa-th-list"></i>
    List view
</div>
