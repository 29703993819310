import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '@app/shared';
import { Router } from '@angular/router';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {

  constructor(private appSvc: ApplicationService, private router: Router) { }

  ngOnInit() {
  }

  makeItSo(): void {
    this.appSvc.skipUserAgentCheck = true;
    this.router.navigate(['/'], { queryParams: { default: true } });
  }

}
