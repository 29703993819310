<div class="typeahead" [ngClass]="{'no-border': removeBorder, 'invalid': invalid}"
  [ngStyle]="{'max-width': fullWidth ? '100%' : '240px', 'background-color': backgroundColor ?? 'transparent', 'max-height': height ?? '100%'}">
  <input *ngIf="!readOnly" (click)="toggleList()" #testJAA [(ngModel)]="typeahead" (ngModelChange)="filterSelectionDebounce($event)"
    [placeholder]="label" autocomplete="off" data-lpignore="true" [disabled]="disabled"
    (keyup.enter)="selectFirstOption()" (keydown.tab)="openResults = false; true"
    [ngStyle]="{'background-color': backgroundColor ?? 'transparent'}" />
  <input *ngIf="readOnly"  [(ngModel)]="typeahead" readonly style="cursor:not-allowed" />
  <div class="flex-fill"></div>
  <div class="actions">
    <div class="action" (click)="selectFirstOption()" *ngIf="!loading && showConfirm">
      <i class="fad fa-check"></i>
    </div>
    <div class="action" (click)="close()" *ngIf="!loading && !!typeahead && showClose && !multiSelection">
      <i class="fad fa-times-circle red"></i>
    </div>
    <div class="action" (click)="toggleList()" *ngIf="!typeahead && (!loading || showList || multiSelection)">
      <i class="fal fa-list-ul"></i>
    </div>
    <div class="action loading" *ngIf="loading">
      <app-spinner class="tw-invisible" [smaller]="true"></app-spinner>
    </div>
  </div>
</div>
<div class="no-results" *ngIf="openResults && filteredSuggestions.length === 0 && !disableSetTypeahead && !loading"
  [ngClass]="alignment">
  <div class="fill"></div>
  <div>No Results</div>
  <div class="fill"></div>
</div>
<div class="options" *ngIf="openResults" [ngClass]="alignment" [ngStyle]="{'max-width': fullWidth ? '100%' : '240px'}"
  infiniteScroll #scrollWindow [infiniteScrollDisabled]="disableInfiniteScroll" [infiniteScrollThrottle]="50"
  [scrollWindow]="false" (scrolled)="onScrollDown()">
  <ng-container *ngIf="filteredSuggestions.length > 0">
    <ng-container *ngFor="let option of filteredSuggestions">
      <div class="option" *ngIf="!hideDisabled || !option.disabled" [ngClass]="{ 'disabled': option.disabled }"
        [title]="option.name" (click)="selectOption(option)">
        <div *ngIf="multiSelection && allowOnlyOne" class="checkmark">
          <i class="fal fa-check" *ngIf="_chosen.indexOf(option) > -1"></i>
        </div>
        <div class="name">{{ option.name }}</div>
      </div>
    </ng-container>
  </ng-container>
</div>
<div class="chips" *ngIf="_chosen.length > 0" [ngClass]="{'wrap': multiSelectionWrap}">
  <app-chip *ngFor="let chip of _chosen; index as i" [title]="chip?.name" (remove)="removeChip(i)"></app-chip>
</div>