/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PluginStatusError } from './pluginStatusError';
import { ErrorLevel } from './errorLevel';
import { UserReference } from './userReference';


export interface PluginStatus { 
    contentState?: ErrorLevel;
    endDate?: string;
    errorMessage?: string;
    errors?: Array<PluginStatusError>;
    failCount?: number;
    generalErrors?: Array<PluginStatusError>;
    organizationId?: number;
    passCount?: number;
    pluginName?: string;
    processType?: string;
    runStatus?: string;
    startDate?: string;
    statsId?: number;
    totalCount?: number;
    totalFailCount?: number;
    totalWarnCount?: number;
    uriPath?: string;
    user?: UserReference;
    warnCount?: number;
}

