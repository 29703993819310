import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { PluginConfig, PluginConfigProperty } from '@app/core';

@Component({
  selector: 'app-plugin',
  templateUrl: './plugin.component.html',
  styleUrls: ['./plugin.component.scss']
})
export class PluginComponent implements OnInit {
  @Input() properties: Array<PluginConfigProperty> = [];
  @Input() config: PluginConfig;
  @Input() propertyForms: UntypedFormArray;
  @Input() isWalmartMarketplace = false;
  @Output() save: EventEmitter<void> = new EventEmitter();
  @Output() discard: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
}
