/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FilterDefinition } from './filterDefinition';


export interface BulkUpdate { 
    action?: string;
    bulkUpdateByFilter?: boolean;
    bulkUpdateEdray?: boolean;
    dataBuffer?: { [key: string]: any; };
    destinationId?: number;
    fieldName?: string;
    fieldValue?: string;
    filterDefinition?: FilterDefinition;
    groupBy?: string;
    internalFieldName?: string;
    products?: Array<string>;
    replaceMatch?: string;
    runWorkflow?: boolean;
    screenName?: string;
    statusId?: number;
    subCatalogId?: number;
    tableName?: string;
}

