import { Component, OnInit, HostBinding, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-circle-button',
  templateUrl: './circle-button.component.html',
  styleUrls: ['./circle-button.component.scss']
})
export class CircleButtonComponent implements OnInit {
  @Input() icon: string;
  @Input() solid = false;
  @Input() href: string;
  @Input() color: string = 'navy';
  @Input() tooltip: string = null;

  @HostBinding('class.elongated') @Input() label = '';
  @HostBinding('class.disabled') @Input() disabled = false;
  @HostBinding('title') @Input() title = '';

  showToolTip: boolean = false;


  @HostListener('click') onClick() {
    if (this.href) {
      window.open(this.href, '_blank');
    }
  }

  constructor() { }

  ngOnInit() {
    if (!this.title) {
      this.title = this.label;
    }
  }

}
