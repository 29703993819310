import { Environment, EnvironmentColor } from "./environment.types";

export const environment: Environment = {
  color: EnvironmentColor.Black,
  production: false,
  logging: true,
  testing: false,
  analytics: false,
  prometheusUrl: '',
  apiUrl: 'https://api.infrared.commerce.syndic8.io',
  apiVersion: '',
  swaggerApiKey: '',
  domain: 'syndic8-prod.auth0.com',
  clientId: '037XVXtkfCHSMKXFh8MxqFOe7PM4GXPu',
  audience: 'http://prod.syndic8.io/api/v1',
  tokenNamespace: 'https://syndic8.io',
  inriverConnection: 'inriver-prod-openid'
};
