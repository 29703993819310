<div class="cell checkbox-container" (click)="selectRow()" *ngIf="showSelection" [ngClass]="{'loading': loading}">
  <div class="checkbox" [ngClass]="{ 'selected': _selected }" *ngIf="!loading">
    <i class="fas fa-check"></i>
  </div>
</div>
<div class="cell checkbox-container" *ngIf="showNewRow && !showSelection && !actions" [ngClass]="{'loading': loading}">
</div>
<div class="cell image" *ngIf="image" [attr.title]="image && image.title ? image.title : null" (click)="clickRow()"
  (dblclick)="doubleClickRow()">
  <i *ngIf="!image.src" class="fad" [ngClass]="image.altIcon ? 'fa-' + image.altIcon : 'fa-image'"></i>
  <ng-container *ngIf="image.src">
    <app-image *ngIf="image.type === InfiniteScrollImageType.Image" [src]="image.src"></app-image>
    <i *ngIf="image.type === InfiniteScrollImageType.Video" class="fad"
      [ngClass]="image.altIcon ? 'fa-' + image.altIcon : 'fa-camera-movie'"></i>
  </ng-container>
</div>
<div class="cell chips" *ngIf="chips && chipWidth > 0" (click)="clickRow()" (dblclick)="doubleClickRow()"
  [ngClass]="{ 'image-padding': image }" [ngStyle]="{
    'min-width': chipWidth ? chipWidth + 'px' : 'unset',
    'max-width': chipWidth ? chipWidth + 'px' : 'unset',
    'padding': chips.length > 0 ? '0 4px' : null
  }">
  <ng-container *ngIf="chips && chips.length > 0">
    <div class="icon-container" *ngFor="let chip of chips" [attr.title]="chip.title ? chip.title : null" [ngClass]="{
        yellow: chip.color === InfiniteScrollColor.Yellow,
        red: chip.color === InfiniteScrollColor.Red,
        blue: chip.color === InfiniteScrollColor.Blue,
        green: chip.color === InfiniteScrollColor.Green
      }">
      <i *ngIf="chip.icon && !chip.text" [ngClass]="'fad fa-' + chip.icon"></i>
      <ng-container *ngIf="chip.text">{{ chip.text }}</ng-container>
    </div>
  </ng-container>
</div>
<div class="cell title-subtitle" *ngIf="title" (click)="clickRow()" (dblclick)="doubleClickRow()" [ngStyle]="{
    'min-width': _columns ? titleWidth + 'px' : 'unset',
    'max-width': _columns ? titleWidth + 'px' : 'unset'
  }">
  <div class="title" [title]="title.title" [ngClass]="{ 'larger': !title.subtitle }">
    {{ title.title }}
  </div>
  <div class="subtitle" *ngIf="title.subtitle" [attr.title]="title.subtitle ? title.subtitle : null">
    {{ title.subtitle }}
  </div>
</div>
<div class="cell ellipsis" *ngIf="showEllipsis">
  <div class="ellipsis-wrapper" (click)="ellipsisClickRow()">
    <i class="far fa-ellipsis-h"></i>
  </div>
</div>
<div class="cell download-button" *ngIf="showDownloadButton">
  <div class="download-button-wrapper" (click)="downloadButtonClickRow()">
    <i style="color:rgb(79 70 229 )" class="far fa-paperclip"></i>
  </div>
</div>
<div class="cell processing" *ngIf="processings && processings.length > 0">
  <div [attr.title]="processing? 'Processing...' : null" class="spinner" *ngIf="processing"></div>
  <div [attr.title]="warning? 'Failed Syndictaion' : null">
    <i [ngClass]="{
      'fas fa-sharp fa-solid fa-exclamation': warning == true,
    }" [ngStyle]="{'color': warning == true?'#cd2323' : '#cd2323'}"></i>
  </div>
</div>
<div class="cell actions" *ngIf="actions" [ngClass]="{
        'green-bg' : isSaved() && isColorCoded,
        'red-bg' : !isSaved() && isColorCoded,
     }" (click)="actions.length > 1 && showActionsDrawerClick = true; showActionsDrawer = !showActionsDrawer">
  <div class="single-action" *ngIf="actions.length === 1" (click)="actionClick(actions[0])">
    <i [attr.class]="'fas fa-' + getRowActionIcon()" [title]="actions[0].title" [ngClass]="{
        'blue': !actions[0].color || actions[0].color === InfiniteScrollColor.Blue,
        'yellow': actions[0].color === InfiniteScrollColor.Yellow,
        'red': actions[0].color === InfiniteScrollColor.Red,
        'green': actions[0].color === InfiniteScrollColor.Green,
      }">
    </i>
  </div>
  <div class="chevron" *ngIf="actions.length > 1" [ngClass]="{'expanded': showActionsDrawer}">
    <i class="fas fa-chevron-down" title="Expand actions"></i>
  </div>
  <div class="drawer" *ngIf="showActionsDrawer">
    <div class="action" *ngFor="let action of actions" (click)="actionClick(action)">
      <i [attr.class]="'fas fa-' + action.icon" [ngClass]="{
          'blue': !action.color || action.color === InfiniteScrollColor.Blue,
          'yellow': action.color === InfiniteScrollColor.Yellow,
          'red': action.color === InfiniteScrollColor.Red,
          'green': action.color === InfiniteScrollColor.Green
        }">
      </i>
      <div class="title" [title]="action.title">{{ action.title }}</div>
    </div>
  </div>
</div>
<div #valuesRef class="values" *ngIf="_columns && _columns.length > 0">
  <div class="value-wrap" *ngFor="let column of _columns; index as i" [ngStyle]="{
      'min-width': minWidths[i] ? minWidths[i] + 'px' : column.width ? column.width + 'px' : 100 / _columns.length + '%',
      'max-width': minWidths[i] ? minWidths[i] + 'px' : column.width ? column.width + 'px' : 100 / _columns.length + '%'
    }" [ngClass]="{
      'green' : !isSaved() && isColorCoded,
      'selected' : isSingleSelected && isColorCoded,
      'red' : isSaved() && isColorCoded,
      'editable': _isEditable && column.editable !== false && !column.accessorialField,
      'not-editable': _isEditable && (column.editable === false || column.accessorialField),
      'invalid': getFormControl(column)?.invalid,
    }" (click)="clickValue(column)" (dblclick)="doubleClickValue()"
    (mouseenter)="showValueDetails = column.propertyName" (mouseleave)="showValueDetails = ''">
    <ng-container *ngIf="!_isEditable || _isEditable && (column.editable === false || column.accessorialField)">
      <div class="value" *ngIf="getCellValue(column) as value; else noValue"
        [attr.title]="!column.details && value ? value : null" [ngClass]="column.transformColor ? column.transformColor() : {
          yellow: column.color === InfiniteScrollColor.Yellow,
          red: column.color === InfiniteScrollColor.Red,
          blue: column.color === InfiniteScrollColor.Blue,
          green: column.color === InfiniteScrollColor.Green,
          email: column.type === InfiniteScrollColumnType.Email
        }">
        <ng-container [ngSwitch]="column.type">
          <ng-container *ngSwitchCase="InfiniteScrollColumnType.Currency">
            <ng-container
              *ngIf="column.currencyCodePropertyName && _row[column.currencyCodePropertyName] as currencyCode; else noCurrencyCode">
              {{ value | currency : currencyCode }}</ng-container>
            <ng-template #noCurrencyCode>{{ value | currency }}</ng-template>
          </ng-container>
          <ng-container *ngSwitchDefault>{{ value }}</ng-container>
        </ng-container>
      </div>
      <ng-template #noValue>
        <div class="value" [attr.title]="!column.details && column.defaultValue ? column.defaultValue : null" [ngClass]="column.transformColor ? column.transformColor() : {
            yellow: column.color === InfiniteScrollColor.Yellow,
            red: column.color === InfiniteScrollColor.Red,
            blue: column.color === InfiniteScrollColor.Blue,
            green: column.color === InfiniteScrollColor.Green
          }">
          <ng-container *ngIf="column.defaultValue || column.defaultValue === 0; else noDefaultValue">{{
            column.defaultValue }}</ng-container>
          <ng-template #noDefaultValue>
            <pre> </pre>
          </ng-template>
        </div>
      </ng-template>
    </ng-container>
    <div class="details" *ngIf="!_isEditable && column.details?.length > 0 && showValueDetails === column.propertyName"
      [ngStyle]="{'width': column.detailsWidth ? column.detailsWidth + 'px' : 'max-content' }">
      <ng-container *ngFor="let detail of column.details">
        <div *ngIf="detail.altValue || getCellDetailValue(detail) as detailValue"
          [attr.class]="detail.emphasis ? 'detail emphasis-' + detail.emphasis : 'detail'">
          <div class="detail-name" *ngIf="detail.displayName">{{ detail.displayName }}:</div>
          <div class="detail-value">{{ detailValue ? detailValue : detail.altValue }}</div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="_isEditable && column.editable !== false && !column.accessorialField" [ngSwitch]="column.type">
      <app-date class="value" *ngSwitchCase="InfiniteScrollColumnType.Date" [formControl]="getFormControl(column)">
      </app-date>
      <app-date-time class="value" *ngSwitchCase="InfiniteScrollColumnType.Datetime"
        [formControl]="getFormControl(column)"
        [defaultToCurrentDateTimeOnFirstClick]="column.defaultToCurrentDateTimeOnFirstClick || false"></app-date-time>
      <ng-container *ngSwitchCase="InfiniteScrollColumnType.Boolean">
        <select class="value" [formControl]="getFormControl(column)">
          <option [value]="" *ngIf="!column.notNull"></option>
          <option [value]="true">Yes</option>
          <option [value]="false">No</option>
        </select>
      </ng-container>
      <app-infinite-scroll-select *ngSwitchCase="InfiniteScrollColumnType.Suggestion" [row]="_row" [column]="column"
        [rowForm]="rowForm" [suggestions]="suggestions" [style]="_style">
      </app-infinite-scroll-select>
      <app-suggestion
        *ngSwitchCase="InfiniteScrollColumnType.SuggestionList || InfiniteScrollColumnType.MultiSuggestion"
        [multiSelection]="column.type === InfiniteScrollColumnType.MultiSuggestion"
        [include]="column?.suggestionInternalInclude ? column.suggestion : ''"
        [filters]="column?.suggestionFilters ? column.suggestionFilters : { by: [], join: [], match: [], value: [] }"
        [label]="getSuggestionLabel(column.displayName)" [provided]="suggestions[column.suggestion]" [form]="rowForm"
        [field]="column.propertyName" [exclude]="column?.suggestionExclude ? column.suggestionExclude : null"
        [setTypeaheadIfNotFound]="column?.suggestionSetTypeaheadIfNotFound" [altField]="column?.suggestionAltField"
        [showClose]="column.hasOwnProperty('suggestionShowClose') ? column.suggestionShowClose : true"
        [showList]="column?.suggestionShowList"
        [optionProp]="column.suggestionValueProperty ? column.suggestionValueProperty : InfiniteScrollColumnSuggestionProperty.Name"
        [removeBorder]="true">
      </app-suggestion>
      <input *ngSwitchCase="InfiniteScrollColumnType.Number" class="value"
        [contenteditable]="_isEditable && column.editable !== false && !column.accessorialField"
        [formControl]="getFormControl(column)" (keypress)="enforceNumberInput($event)">
      <input *ngSwitchCase="InfiniteScrollColumnType.Currency" class="value"
        [contenteditable]="_isEditable && column.editable !== false && !column.accessorialField"
        [formControl]="getFormControl(column)" (keypress)="enforceCurrencyInput($event)">
      <input *ngSwitchDefault class="value"
        [contenteditable]="_isEditable && column.editable !== false && !column.accessorialField"
        [formControl]="getFormControl(column)">

    </ng-container>
  </div>
  <div class="value-wrap flex-fill" *ngIf="showColumnFill$ | async"></div>
  <div *ngIf="(isSingleSelected && isColorCoded)" class="doubleArrow">
    <i style="vertical-align: middle; padding-top: 10px; " class="fas fa-chevron-double-right"></i>
  </div>
</div>
<div class="flex-fill" *ngIf="!_columns" (click)="clickRow()" (dblclick)="doubleClickRow()"></div>