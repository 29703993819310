import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ExceptionService} from "@app/core/services/exception.service";
import {ToastService} from "@app/core";
import * as url from "url";

@Component({
  selector: 'app-magento-callback',
  templateUrl: './magento-callback.component.html',
  styleUrls: ['./magento-callback.component.scss']
})
export class MagentoCallbackComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private exception: ExceptionService,
    private toast: ToastService
  ) { }

  redirectUrl = null;
  token = null;

  ngOnInit(): void {
    const { queryParams } = this.route.snapshot;
    this.redirectUrl = queryParams?.success_call_back;
    this.token = queryParams?.oauth_consumer_key
    console.log("Magento Callback Received ", queryParams);;
  }

  callRedirect() {;
    // <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
    // const forceHttps = this.redirectUrl.replace("http", "https");
    // console.log("forceHttps", forceHttps)
    fetch(this.redirectUrl
    )
      .then(response => {
        if (response.ok) {
          console.log("OK", response)
          return response.json(); // Parse the response data as JSON
        } else {
          console.log("Bad", response)
          throw new Error('API request failed');
        }
      })
      .then(data => {
        console.log("data", data);
        // window.location.href = data.redirectUri;
      })
      .catch(error => {
        console.error("error", error);
        // window.location.href = error.redirectUri;
      });
  }
}
