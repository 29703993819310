/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Connection } from './connection';
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';
import { Template } from './template';


export interface DataFlow { 
    autoSend?: boolean;
    blockSend?: boolean;
    configSettings?: { [key: string]: any; };
    connectionIdIn?: number;
    connectionIdOut?: number;
    connectionIdPassthru?: number;
    connectionIn?: Connection;
    connectionOut?: Connection;
    connectionPassthru?: Connection;
    createdDate?: ModifiedDate;
    dataFlowId?: number;
    dataFlowName?: string;
    ediId?: number;
    fileError?: string;
    fileExt?: string;
    fileInputAckUrl?: string;
    fileInputUrl?: string;
    fileMatch?: string;
    fileMdn?: string;
    fileOutputAckUrl?: string;
    fileOutputUrl?: string;
    flowType?: string;
    lastMessageSent?: string;
    messageStatus?: number;
    modifyDate?: ModifiedDate;
    orgRef?: OrganizationReference;
    orgRefDestination?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    orgRefOrigin?: OrganizationReference;
    outputFilter?: string;
    outputType?: string;
    runEndDateIn?: string;
    runEndDateOut?: string;
    runStatusIn?: string;
    runStatusInFailCount?: number;
    runStatusOut?: string;
    runStatusOutFailCount?: number;
    statsIdIn?: number;
    statsIdOut?: number;
    status?: string;
    templateInput?: Template;
    templateInputAck?: Template;
    templateOutput?: Template;
    templateOutputAck?: Template;
    transferId?: string;
    transferType?: string;
}

