import { Injectable } from '@angular/core';
import {
  HttpEventType,
  HttpResponse,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { last, catchError, tap, first } from 'rxjs/operators';
import { Subscription, of, Observable, Subject, throwError } from 'rxjs';
import { ToastService } from '@app/core';
import {
  Organization,
  UploadApiService,
  Upload,
  Notification,
  NotificationType,
  NotificationProgress,
  LocationApiService
} from '@app/core/api';
import { Store } from '@ngxs/store';
import { OrganizationState } from '@app/features/organization/store/organization.state';

export interface FileType {
  ext: Array<string>;
  mime: string;
  media?: boolean;
}

export interface FileUpload {
  file?: File;
  organizationId?: number;
  name?: string;
}

export interface UploadOptions {
  processId?: number;
  allowedExtensions?: Array<string>;
  successToast?: Notification;
}

export type UploadStatusStatus = 'Done' | 'InProgress' | 'Error';

export const UploadStatusStatus = {
  Done: 'Done' as UploadStatusStatus,
  InProgress: 'InProgress' as UploadStatusStatus,
  Error: 'Error' as UploadStatusStatus
}

export interface UploadStatus {
  upload: FileUpload,
  status: UploadStatusStatus,
  fileId: number,
  activeToast: Notification,
  progress?: NotificationProgress
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private files: Array<FileUpload> = [];
  private failedFiles: Array<FileUpload> = [];
  private statsId: number = null;
  private activeNotification: Notification;
  private currentTotalUploaded = 0;
  private totalFilesToUpload = 0;
  private trackImportProgress = false;

  private supportedFileTypes: Array<FileType> = [
    {
      ext: ['xls'],
      mime: 'application/vnd.ms-excel'
    },
    {
      ext: ['xlsx'],
      mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    {
      ext: ['xlsm'],
      mime: 'application/vnd.ms-excel.sheet.macroEnabled.12'
    },
    {
      ext: ['json'],
      mime: 'application/json'
    },
    {
      ext: ['csv'],
      mime: 'text/csv'
    },
    {
      ext: ['jpg', 'jpeg'],
      mime: 'image/jpeg',
      media: true
    },
    {
      ext: ['png'],
      mime: 'image/png',
      media: true
    },
    {
      ext: ['gif'],
      mime: 'image/gif',
      media: true
    },
    {
      ext: ['tif', 'tiff'],
      mime: 'image/tiff',
      media: true
    },
    {
      ext: ['svg'],
      mime: 'image/svg+xml',
      media: true
    },
    {
      ext: ['jp2'],
      mime: 'image/jp2',
      media: true
    },
    {
      ext: ['bmp'],
      mime: 'image/bmp',
      media: true
    },
    {
      ext: ['heic'],
      mime: 'image/heic',
      media: true
    },
    {
      ext: ['heif'],
      mime: 'image/heif',
      media: true
    },
    {
      ext: ['3gp'],
      mime: 'audio/3gpp',
      media: true
    },
    {
      ext: ['aac'],
      mime: 'audio/aac',
      media: true
    },
    {
      ext: ['avi'],
      mime: 'video/x-msvideo',
      media: true
    },
    {
      ext: ['flv'],
      mime: 'video/x-flv',
      media: true
    },
    {
      ext: ['mov'],
      mime: 'video/quicktime',
      media: true
    },
    {
      ext: ['mp2'],
      mime: 'video/mpeg',
      media: true
    },
    {
      ext: ['mp4'],
      mime: 'video/mp4',
      media: true
    },
    {
      ext: ['webm'],
      mime: 'video/webm',
      media: true
    },
    {
      ext: ['jasper'],
      mime: 'application/pdf',
      media: true
    },
    {
      ext: ['xml'],
      mime: 'xml',
      media: true
    },
    {
      ext: ['edi'],
      mime: 'edi',
      media: true
    }
  ];
  private statusMessageSubject: Subject<UploadStatus>;

  toastControlledExternally = false;
  isActive = false;

  get supportedMediaFileTypes(): Array<FileType> {
    return this.supportedFileTypes.filter(type => type.media);
  }

  get status$(): Observable<UploadStatus> {
    return this.statusMessageSubject.asObservable();
  }

  constructor(
    private toast: ToastService,
    private uploadApiService: UploadApiService,
    private store: Store,
    private locationApiService: LocationApiService
  ) { }

  addFiles(files: FileList, organizationId: Organization['id'], options: UploadOptions = { allowedExtensions: [] }): Observable<UploadStatus> {
    if (!files || (files && files.length === 0)) {
      return throwError('No files to upload');
    }

    this.statusMessageSubject = new Subject();
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    if (this.isImageFile(files[0])) {
      this.locationApiService?.getImageUploadId(files.length, organization.id).pipe(first()).subscribe(res => {
        const processId = res['statsId'];
        this.statsId = processId
        this.isActive = true;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          file['uploadId'] = processId || null;
          //  If file types is unsupported
          if (options.allowedExtensions.length > 0) {
            if (options.allowedExtensions.reduce((acc, ext) => this.fileTypeChecker(file, null, ext) ? true : acc, false)) {
              this.addFileToQueue(file, organizationId);
            } else {
              this.toast.add({
                expiration: 5000,
                title: 'Unsupported file type...',
                message: 'Must be a Media file',
                type: NotificationType.ERROR
              });
            }
          } else {
            if (this.fileSupportCheck(file.type, file.name)) {
              this.addFileToQueue(file, organizationId);
            } else {
              this.toast.add({
                expiration: 5000,
                title: 'Unsupported file type...',
                message: 'Must be a Media file',
                type: NotificationType.ERROR
              });
            }
          }
        }

        // If files were added to the queue, start the upload, else reset it
        if (this.totalFilesToUpload > 0) {
          this.flightCheck(options);
        } else {
          this.clearQueue();
        }
      });
      return this.statusMessageSubject.asObservable();
    } else {
      this.isActive = true;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        //  If file types is unsupported
        if (options.allowedExtensions.length > 0) {
          if (options.allowedExtensions.reduce((acc, ext) => this.fileTypeChecker(file, null, ext) ? true : acc, false)) {
            this.addFileToQueue(file, organizationId);
          } else {
            this.toast.add({
              expiration: 5000,
              title: 'Unsupported file type...',
              message: `Must be a ${options.allowedExtensions[0] === 'json' ? 'JSON file' : options.allowedExtensions[0] === 'xlsx' || options.allowedExtensions[0] === 'xls' ? 'Spreadsheet' : 'Supported file type'}`,
              type: NotificationType.ERROR
            });
          }
        } else {
          if (this.fileSupportCheck(file.type, file.name)) {
            this.addFileToQueue(file, organizationId);
          } else {
            this.toast.add({
              expiration: 5000,
              title: 'Unsupported file type...',
              message: 'Must be a Spreadsheet or JSON',
              type: NotificationType.ERROR
            });
          }
        }
      }

      // If files were added to the queue, start the upload, else reset it
      if (this.totalFilesToUpload > 0) {
        this.flightCheck(options);
      } else {
        this.clearQueue();
      }
      return this.statusMessageSubject.asObservable();
    }
  }

  private addFileToQueue(file: File, organizationId: Organization['id']): void {
    this.files.push({
      file,
      name: file.name,
      organizationId
    });
    this.totalFilesToUpload++;
  }

  private flightCheck(options: UploadOptions): void {
    // If currently not uploading, add a new persistent Notification, then check the Queue
    if (this.totalFilesToUpload > 0) {
      this.activeNotification = {
        title: 'Uploading...',
        message: this.files[0].name,
        icon: 'cloud-upload-alt',
        progress: {
          overallFinished: 0,
          overallTotal: this.totalFilesToUpload,
          currentFinished: 0,
          currentTotal: 100,
        },
        type: NotificationType.PROGRESS
      };
      this.toast.add(this.activeNotification);

      // Go flight.
      this.checkQueue(options);
    } else {
      // Else upload in progress, update progress information with new file total
      const newNotificationProgress: NotificationProgress = {
        overallTotal: this.files.length
      };
      this.toast.updateProgress(this.activeNotification, newNotificationProgress);
    }
  }

  private checkQueue(options: UploadOptions = { processId: null }): Subscription {
    const upload: FileUpload = this.files[this.currentTotalUploaded];
    this.toast.updateMessage(this.activeNotification, upload.name);

    this.statusMessageSubject.next({ upload, fileId: -1, activeToast: this.activeNotification, status: UploadStatusStatus.InProgress });
    if (this.isImageFile(upload.file)) {
      return this.uploadApiService.uploadById(options.processId || upload['uploadId'] || upload.file['uploadId'] || 0, upload.organizationId, upload.file, upload.name, upload.organizationId, 'events', true).pipe(
        tap(
          (event: HttpEvent<Upload>) => {
            if (event.type === HttpEventType.UploadProgress) {
              const progress: NotificationProgress = {
                currentFinished: Math.round(100 * event.loaded / event.total)
              };
              this.statusMessageSubject.next({ upload, fileId: -1, activeToast: this.activeNotification, status: UploadStatusStatus.InProgress, progress });
              this.toast.updateProgress(this.activeNotification, progress);
            }
          }
        ),
        last(),
        catchError(
          (error: HttpErrorResponse) => {
            this.statusMessageSubject.next({ upload: this.files[this.files.length - 1], fileId: -1, activeToast: this.activeNotification, status: UploadStatusStatus.Error });
            // this.clearQueue();;
            this.toast.add({
              expiration: 5000,
              title: 'Upload Error',
              message: upload.name + ' failed',
              type: NotificationType.ERROR
            });
            return of(error);
          }
        )
      ).subscribe(
        (uploadResponse: HttpResponse<Upload>) => {
          this.currentTotalUploaded++;
          this.totalFilesToUpload--;

          const progress: NotificationProgress = {
            overallFinished: this.currentTotalUploaded
          };
          this.statusMessageSubject.next({ upload, fileId: uploadResponse.body.id, activeToast: this.activeNotification, status: UploadStatusStatus.Done, progress });
          this.toast.updateProgress(this.activeNotification, progress);

          if (this.totalFilesToUpload <= 0) {
            if (uploadResponse.status === 200) {
              this.sendSuccessToast(options, upload);
            }
            this.clearQueue();
          } else {
            this.checkQueue(options);
          }
        }
      );
    } else {
      return this.uploadApiService.upload(upload.organizationId, upload.file, upload.name, upload.organizationId, 'events', true).pipe(
        tap(
          (event: HttpEvent<Upload>) => {
            if (event.type === HttpEventType.UploadProgress) {
              const newNotificationProgress: NotificationProgress = {
                currentFinished: Math.round(100 * event.loaded / event.total)
              };
              this.toast.updateProgress(this.activeNotification, newNotificationProgress);
            }
          }
        ),
        last(),
        catchError(
          (error: HttpErrorResponse) => {
            this.statusMessageSubject.next({ upload: this.files[this.files.length - 1], fileId: -1, activeToast: this.activeNotification, status: UploadStatusStatus.Error });
            // this.clearQueue();;
            this.failedFiles.push(upload);
            this.toast.add({
              expiration: 5000,
              title: 'Upload Error',
              message: upload.name + ' failed',
              type: NotificationType.ERROR
            });
            return of(error);
          }
        )
      ).subscribe(
        (uploadResponse: HttpResponse<Upload>) => {
          this.currentTotalUploaded++;
          this.totalFilesToUpload--;

          const progress: NotificationProgress = {
            overallFinished: this.currentTotalUploaded
          };

          this.statusMessageSubject.next({ upload, fileId: uploadResponse?.body?.id, activeToast: this.activeNotification, status: UploadStatusStatus.Done, progress });
          this.toast.updateProgress(this.activeNotification, progress);

          if (this.totalFilesToUpload <= 0) {
            if (uploadResponse.status === 200) {
              this.sendSuccessToast(options, upload);
            }
            this.clearQueue();
          } else {
            this.checkQueue(options);
          }
        }
      );
    }
  }

  private sendSuccessToast(options: UploadOptions, upload: FileUpload): void {
    const filePluralCheck = ' file' + (this.currentTotalUploaded > 1 ? 's' : '');

    if (options.successToast) {
      this.toast.add({
        expiration: 5000,
        icon: 'check',
        title: 'Success',
        type: NotificationType.SUCCESS,
        ...options.successToast
      });
    } else {
      if (this.isImageFile(upload.file)) {
        this.toast.add({
          expiration: 5000,
          title: this.currentTotalUploaded + ' images uploaded',
          message: 'Click to view images',
          icon: 'check',
          type: NotificationType.SUCCESS,
          route: 'org/' + upload.organizationId + '/image'
        });

        const failedUploads: Array<string> = this.failedFiles.map((e)=>{
          return e.name;
        });
        this.locationApiService?.postImageUploadIdStats(this.statsId, this.currentTotalUploaded, upload.organizationId, failedUploads).pipe(first())
          .subscribe((e)=>{
        });

      } else {
        this.toast.add({
          expiration: 5000,
          title: 'Upload finished, ' + this.currentTotalUploaded + filePluralCheck + ' uploaded',
          message: 'Click to view import history',
          icon: 'check',
          type: NotificationType.SUCCESS,
          route: 'org/' + upload.organizationId + '/import-history'
          // route: 'org/' + upload.organizationId + '/lifecycle/import/product'
        });
      }
    }
  }

  private clearQueue(): void {
    this.isActive = false;
    this.files = [];
    this.failedFiles = [];
    this.statsId = null;
    this.currentTotalUploaded = 0;
    this.totalFilesToUpload = 0;
    if (this.activeNotification) {
      if(!this.toastControlledExternally)
      this.toast.remove(this.activeNotification);
    }
    this.statusMessageSubject.complete();
  }

  private fileSupportCheck(type: string, name: string): boolean {
    return !!this.supportedFileTypes.find(t => t.ext.indexOf(name.slice(name.lastIndexOf('.') + 1)) > -1 || t.mime === type);
  }

  private fileTypeChecker(file: File, mime: string, ext: string): boolean {
    return file.type === mime || file.name.slice(file.name.lastIndexOf('.') + 1) === ext;
  }

  private isImageFile(file: File): boolean {
    return file.type?.toLowerCase()?.includes('jpeg') || file.type?.toLowerCase()?.includes('jpg') || file.type?.toLowerCase()?.includes('png');
  }
}
