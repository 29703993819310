/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Audit } from './audit';
import { DataProcessTemplate } from './dataProcessTemplate';
import { UserReference } from './userReference';
import { OrganizationReference } from './organizationReference';


export interface Template { 
    active?: boolean;
    auditList?: Array<Audit>;
    changedBy?: UserReference;
    changedDate?: string;
    comments?: string;
    createdBy?: UserReference;
    createdDate?: string;
    dataProcessTemplate?: DataProcessTemplate;
    destinationRef?: OrganizationReference;
    fieldNames?: Array<string>;
    fileInfo?: string;
    isLive?: boolean;
    jsonFileDate?: string;
    objectUrl?: string;
    orgRef?: OrganizationReference;
    productSubType?: string;
    productSubType2?: string;
    productType?: string;
    seedFile?: string;
    seedFileDate?: string;
    seedHeaderFields?: string;
    seedHeaderRow?: number;
    seedSheetName?: string;
    status?: string;
    syndicationType?: string;
    templateDate?: string;
    templateDisplayName?: string;
    templateHeader?: string;
    templateId?: number;
    templateName?: string;
}

