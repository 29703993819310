import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GroupBy, Organization, Product, ProductSku, APlusTemplate } from '@app/core/api';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { Store } from '@ngxs/store';
import * as moment from 'moment';

export interface SkuPriceRange {
  range: boolean;
  low: number;
  high: number;
}

@Component({
  selector: 'app-gallery-card',
  templateUrl: './gallery-card.component.html',
  styleUrls: ['./gallery-card.component.scss']
})
export class GalleryCardComponent implements OnInit {
  @Input() product: Product;
  @Input() showLinkToProduct = true;

  @HostBinding('class.selected') @Input() selected = false;

  activeSku: ProductSku;
  activeAplus: APlusTemplate;

  constructor(public route: ActivatedRoute, private router: Router, private store: Store) { }

  ngOnInit(): void {
    if (this.product?.skus?.length > 0) {
      this.activeSku = this.product.skus[0];
    } else {
      this.activeSku = this.product;
    }

    if (this.product?.aplusTemplateList?.length > 0) {
      this.activeAplus = this.product.aplusTemplateList[0];
    }
  }

  get changed(): boolean {
    if (this.activeSku?.lastUpdatedDate && this.activeSku?.lastSyndicationDate) {
      return moment(this.activeSku.lastUpdatedDate).isAfter(this.activeSku.lastSyndicationDate);
    }
    return false;
  }

  get isStyle(): boolean {
    if (this.route.snapshot.queryParams.category === GroupBy.NAME) {
      return true;
    }
    return false;
  }


  get isSKU(): boolean {
    if (this.route.snapshot.queryParams.category === GroupBy.SKU) {
      return true;
    }
    return false;
  }

  swatchMouseIn(sku: ProductSku): void {
    this.activeSku = sku;
  }

  getPriceRangeForSku(): SkuPriceRange {
    if(this.activeSku) {
      const prices = this.activeSku.msrpPrices;
      const uniquePrices = Array.from(new Set(prices)).sort((a: number, b: number) => {
        return a < b ? -1 : 1;
      });

      return {
        range: uniquePrices.length === 1 ? false : true,
        low: uniquePrices[0],
        high: uniquePrices[uniquePrices.length - 1]
      };
    }

  }

  navigateToProduct(): void {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const category = this.route.snapshot.queryParams['category'];
    let queryParams: Params;
    let identifier = '';
    let subRoute = '/variants';
    // Check for custom assortment
    if (category === GroupBy.CUSTOM) {
      identifier = this.product.customGroup;
      //       identifier = this.product.skus[0].skuData.productName;//.customGroup;
      queryParams = { category: GroupBy.CUSTOM };
    } else if (category === GroupBy.DESTINATION) {
      identifier = this.product.destinationName;
      queryParams = { category: GroupBy.DESTINATION };
    } else {
      identifier = this.product.productName;
      queryParams = { category: GroupBy.NAME };
    }

    const navId = 'org/' + organization.id.toString() + '/product/' + encodeURIComponent(identifier);

    this.router.navigate([navId + subRoute], {queryParams});
  }
}
