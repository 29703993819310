import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from '../stores/auth.state';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MissingGuard  {

  constructor(private router: Router, private store: Store) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.selectOnce(AuthState.isLoaded).pipe(
      map(
        (isLoaded: boolean) => {
          if (!isLoaded) {
            const urlTree: UrlTree = this.router.createUrlTree(['/org']);
            return urlTree;
          }
          return true;
        }
      )
    );
  }
}
