/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ImageClassification } from './imageClassification';
import { Filter } from './filter';


export interface Image { 
    backgroundColor?: string;
    backgroundPercent?: number;
    changedDate?: string;
    classifications?: Array<ImageClassification>;
    contentBlockCopy1?: string;
    contentBlockCopy2?: string;
    contentBlockTitle1?: string;
    contentBlockTitle2?: string;
    height?: number;
    hero?: boolean;
    horizontalDPI?: number;
    imageAngle?: string;
    imageColors?: string;
    imageNumber?: string;
    imageType?: string;
    jsonData?: { [key: string]: any; };
    matchCount?: number;
    mediaGroup?: string;
    modeOpenCV?: string;
    name?: string;
    numberOfColors?: string;
    orderNumber?: number;
    propertyNames?: string;
    publishDate?: string;
    sku?: string;
    smartFilter?: Filter;
    smartImage?: boolean;
    transparency?: string;
    uriId?: number;
    uriName?: string;
    uriParentId?: number;
    uriPath?: string;
    uriPathPublic?: string;
    uriPathThumb?: string;
    uriPathThumbPublic?: string;
    uriType?: string;
    url?: string;
    verticalDPI?: number;
    width?: number;
}

