import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  skipUserAgentCheck = false;

  constructor(private formBuilder: UntypedFormBuilder, private store: Store) { }

  stringToUpperCaseArray(param: string): Array<any> {
    const newArray = [];
    if (!param) {
      return newArray;
    }
    const splitArray = this.splitArray(param);
    for (let i = 0; i < splitArray.length; i++) {
      if (splitArray[i] && typeof splitArray[i] === 'string') {
        newArray.push(splitArray[i].toUpperCase());
      } else {
        newArray.push(splitArray[i]);
      }
    }
    return newArray;
  }

  splitArray(param: string): Array<string> {
    if (!param) {
      return [];
    }
    return param.split(',');
  }

  toUpperCaseArray(array: Array<string>): Array<any> {
    if (!array) {
      return [];
    }
    if (!Array.isArray(array) && typeof array === 'string') {
      const str = array as string;
      return [str.toUpperCase()];
    }
    if (!array[0] || array.length === 0) {
      return [];
    }
    const newArray = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i] && typeof array[i] === 'string') {
        newArray.push(array[i].toUpperCase());
      } else {
        newArray.push(array[i]);
      }
    }
    return newArray;
  }

  buildFormGroup(object: object | Array<any>): UntypedFormGroup | UntypedFormArray {
    if (Array.isArray(object)) {
      const array = this.formBuilder.array([]);
      for (let i = 0; i < object.length; i++) {
        const value = object[i];
        if (value && typeof value === 'object') {
          array.push(this.buildFormGroup(value));
        } else {
          array.push(this.formBuilder.control(value, []));
        }
      }
      return array;
    }
    const group = this.formBuilder.group({});
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const value = object[key];
        if (value && typeof value === 'object') {
          group.addControl(key, this.buildFormGroup(value));
        } else {
          group.addControl(key, this.formBuilder.control(value, []));
        }
      }
    }
    return group;
  }

  buildUrlFromSnapshot(snapshot: ActivatedRouteSnapshot, fallback?: number): string {
    let route = snapshot;
    let url = '';
    while (route) {
      if (route.url.length) {
        url = '/' + route.url[0].path + url;
      }
      route = route.parent;
    }
    if (fallback) {
      url = this.fallbackUrl(url, fallback);
    }
    return url;
  }

  fallbackUrl(url: string, fallback: number): string {
    let normal: string;
    if (!url) {
      normal = this.getUrlSnaphot();
    } else {
      normal = this.normalizeUrl(url);
    }

    // Drop Url Segments by number of fallbacks
    const split = normal.split('/');
    split.splice(normal.split('/').length - fallback, normal.split('/').length);
    return split.join('/');
  }

  getUrlSnaphot(): string {
    const url = this.store.selectSnapshot(RouterState.url);
    return this.normalizeUrl(url);
  }

  normalizeUrl(url: string): string {
    return url.split('?')[0];
  }

  mapToObj(inputMap: Map<string, any>): any {
    const obj = {};

    inputMap.forEach((value, key) => {
      obj[key] = value;
    });

    return obj;
  }

  generateGuid() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  createFileReaderObservable(blob: Blob): Observable<FileReader> {
    if (!(blob instanceof Blob)) {
      return throwError(new Error('`blob` must be an instance of File or Blob.'));
    }

    return new Observable(observer => {
      const reader: FileReader = new FileReader();

      reader.onerror = err => observer.error(err);
      reader.onabort = err => observer.error(err);
      reader.onload = () => observer.next(reader);
      reader.onloadend = () => observer.complete();

      return reader.readAsBinaryString(blob);
    });
  }

  groupByGetter(items: any[], keyGetterFunction: any) {
    // example usage

    /*const pets = [
      { type: "Dog", name: "Spot" },
      { type: "Cat", name: "Tiger" },
      { type: "Dog", name: "Rover" },
      { type: "Cat", name: "Leo" }
    ];
  
    const grouped = groupBy(pets, pet => pet.type);
    console.log(grouped.get("Dog")); // -> [{type:"Dog", name:"Spot"}, {type:"Dog", name:"Rover"}]
    console.log(grouped.get("Cat")); // -> [{type:"Cat", name:"Tiger"}, {type:"Cat", name:"Leo"}]
  */
    const map = new Map();
    items.forEach((item) => {
      const key = keyGetterFunction(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

}
