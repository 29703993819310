import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthState } from '../stores/auth.state';
import { environment } from '@env/environment';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import prom from 'promjs';
import { Registry } from 'promjs/registry';
import { catchError, filter, map, mergeMap, retry } from 'rxjs/operators';
import { User } from '../api'
import { NavigationEnd, Router } from '@angular/router';
import { RouterState } from '@ngxs/router-plugin';
import { RouterStateParams } from '../providers/custom-router-state-serializer';
import { Observable, throwError, timer } from 'rxjs';
import * as moment from 'moment';
import { Counter } from 'promjs/counter';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoadEmailUserAction, LoadUserAction } from '../stores/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class PrometheusService {

  private _apiTimestamps: { [key: string]: number };

  private _registry: Registry;

  private _pageViewCounter: Counter;
  private _apiSuccessCounter: Counter;
  private _apiErrorCounter: Counter;
  private _apiDurationCounter: Counter;
  private _userAgentCounter: Counter;

  constructor(
    private http: HttpClient,
    private store: Store,
    private actions: Actions,
    private router: Router,
    private device: DeviceDetectorService
  ) {
    this._reset();

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => this.store.selectSnapshot<RouterStateParams>(RouterState.state))
    ).subscribe(route => this.pageView(route));

    // Log User Agent after User data is loaded
    this.actions.pipe(ofActionCompleted(LoadUserAction)).subscribe(() => this._userAgent());
    this.actions.pipe(ofActionCompleted(LoadEmailUserAction)).subscribe(() => this._userAgent());

    timer(20000, 20000).pipe(
      mergeMap(this._sendMetrics.bind(this)),
      catchError(error => {
        this._reset.call(this)
        return throwError(error);
      })
    ).subscribe(this._reset.bind(this));
  }

  private _reset(): void {
    this._registry = prom();
    this._pageViewCounter = this._registry.create('counter', 'pageView', 'Log a page view');
    this._apiSuccessCounter = this._registry.create('counter', 'apiSuccess', 'Log a successful API request');
    this._apiErrorCounter = this._registry.create('counter', 'apiError', 'Log a failed API request');
    this._apiDurationCounter = this._registry.create('counter', 'apiDuration', 'Log duration of API request');
    this._userAgentCounter = this._registry.create('counter', 'userAgent', 'Log User Agent data');
  }

  private _sendMetrics(): Observable<any> {
    if (!environment.prometheusUrl) {
      return throwError('Prometheus URL not configured');
    }
    if (!this._registry) {
      return throwError('Prometheus Registry object not ready');
    }
    const url: string = environment.prometheusUrl;
    const metrics: string = this._registry.metrics();
    const headers: { [key: string]: string } = {
      'Content-Type' : 'text/plain;charset=UTF-8'
    };
    return this.http.post(url, metrics, { headers }).pipe(retry(2));
  }

  private _userAgent(): void {
    const user: User = this.store.selectSnapshot(AuthState.getUser);
    const { userAgent } = this.device;
    this._userAgentCounter.add(1, { name: user ? `${user.firstName} ${user.lastName}` : 'N/A', email: user ? user.email : 'N/A', userAgent });
  }

  pageView(params: RouterStateParams): void {
    if (params.url.startsWith('/callback')) {
      return;
    }
    const user: User = this.store.selectSnapshot(AuthState.getUser);
    this._pageViewCounter.add(1, { name: user ? `${user.firstName} ${user.lastName}` : 'N/A', email: user ? user.email : 'N/A', page: params.url });
  }

  startApiRequest(url: string): void {
    this._apiTimestamps = { ...this._apiTimestamps, [url]: moment().valueOf() };
  }

  private _apiDuration(url: string): void {
    const user: User = this.store.selectSnapshot(AuthState.getUser);
    const timestamp: number = this._apiTimestamps[url];
    const duration: number = moment().diff(moment(timestamp), 'milliseconds', true);
    this._apiDurationCounter.add(1, { name: user ? `${user.firstName} ${user.lastName}` : 'N/A', email: user ? user.email : 'N/A', url, duration: `${duration}ms` });
    delete this._apiTimestamps[url];
  }

  apiSuccess(url: string): void {
    const user: User = this.store.selectSnapshot(AuthState.getUser);
    this._apiDuration(url);
    this._apiSuccessCounter.add(1, { name: user ? `${user.firstName} ${user.lastName}` : 'N/A', email: user ? user.email : 'N/A', url });
  }

  apiError(error: any, url: string): void {
    const user: User = this.store.selectSnapshot(AuthState.getUser);
    this._apiDuration(url);
    this._apiErrorCounter.add(1, { name: user ? `${user.firstName} ${user.lastName}` : 'N/A', email: user ? user.email : 'N/A', url, error: JSON.stringify(error).replace(/\"/g, '\'') });
  }
}
