import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-readiness-backlog-chart',
  templateUrl: './product-readiness-backlog-chart.component.html',
  styleUrls: ['./product-readiness-backlog-chart.component.scss']
})
export class ProductReadinessBacklogChartComponent implements OnInit {

  workflow: any = {
    id: 1,
    name: 'Product Optimization',
    categories: [
      {
        name: 'Data Quality'
      },
      {
        name: 'Syndication'
      },
      {
        name: 'Taxonomy'
      },
      {
        name: 'SEO'
      },
      {
        name: 'Image Verification'
      }
    ]
  };
  date1: any = {
    date: '>30',
    totalPasses: 100,
    totalWarnings: 1,
    totalErrors: 3,
    categories: [
      {
        name: 'Data Quality',
        passes: '45',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Syndication',
        passes: '40',
        warnings: 1,
        errors: 0
      },
      {
        name: 'Taxonomy',
        passes: '33',
        warnings: 2,
        errors: 1
      },
      {
        name: 'SEO',
        passes: '30',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Image Verification',
        passes: '5',
        warnings: 0,
        errors: 0
      }
    ]
  };
  date2: any = {
    totalPasses: 100,
    totalWarnings: 18,
    totalErrors: 7,
    date: '>20',
    categories: [
      {
        name: 'Data Quality',
        passes: '45',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Syndication',
        passes: '40',
        warnings: 18,
        errors: 7
      },
      {
        name: 'Taxonomy',
        passes: '33',
        warnings: 0,
        errors: 0
      },
      {
        name: 'SEO',
        passes: '30',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Image Verification',
        passes: '5',
        warnings: 0,
        errors: 0
      }
    ]
  };
  date3: any = {
    totalPasses: 100,
    totalWarnings: 6,
    totalErrors: 2,
    date: '>10',
    categories: [
      {
        name: 'Data Quality',
        passes: '45',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Syndication',
        passes: '40',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Taxonomy',
        passes: '33',
        warnings: 0,
        errors: 0
      },
      {
        name: 'SEO',
        passes: '30',
        warnings: 3,
        errors: 1
      },
      {
        name: 'Image Verification',
        passes: '5',
        warnings: 3,
        errors: 1
      }
    ]
  };
  date4: any = {
    totalPasses: 100,
    totalWarnings: 0,
    totalErrors: 0,
    date: '>5',
    categories: [
      {
        name: 'Data Quality',
        passes: '45',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Syndication',
        passes: '40',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Taxonomy',
        passes: '33',
        warnings: 0,
        errors: 0
      },
      {
        name: 'SEO',
        passes: '30',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Image Verification',
        passes: '5',
        warnings: 0,
        errors: 0
      }
    ]
  };
  date5: any = {
    totalPasses: 100,
    totalWarnings: 27,
    totalErrors: 0,
    date: '>1',
    categories: [
      {
        name: 'Data Quality',
        passes: '45',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Syndication',
        passes: '40',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Taxonomy',
        passes: '33',
        warnings: 27,
        errors: 0
      },
      {
        name: 'SEO',
        passes: '30',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Image Verification',
        passes: '5',
        warnings: 0,
        errors: 0
      }
    ]
  };

  dates: any[] = [this.date1, this.date2, this.date3, this.date4, this.date5];

  constructor() { }

  ngOnInit() {
  }

}
