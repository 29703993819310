import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Catalog, FilterJoin, FilterMatch, OrderBy, Organization, OrgApiService, SubcatalogApiService, Suggestion, View, ViewApiService, ViewField } from '@app/core';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { AOrAnPipe } from '@app/shared/pipes/aOrAn.pipe';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export type BulkUpdateFieldType = 'string' | 'date' | 'datetime' | 'number' | 'suggestion' | 'multiSuggestion' | 'boolean';

export const BulkUpdateFieldType = {
  String: 'string' as BulkUpdateFieldType,
  Date: 'date' as BulkUpdateFieldType,
  Datetime: 'datetime' as BulkUpdateFieldType,
  Number: 'number' as BulkUpdateFieldType,
  Suggestion: 'suggestion' as BulkUpdateFieldType,
  MultiSuggestion: 'multiSuggestion' as BulkUpdateFieldType,
  Boolean: 'boolean' as BulkUpdateFieldType
};

export type BulkUpdateType = 'add' | 'remove' | 'replace' | 'clear' | 'replaceMatch';
export const BulkUpdateType = {
  Add: 'add' as BulkUpdateType,
  Remove: 'remove' as BulkUpdateType,
  Replace: 'replace' as BulkUpdateType,
  Clear: 'clear' as BulkUpdateType
}

export interface BulkUpdateField {
  displayName: string;
  fieldName: string;
  type?: BulkUpdateFieldType; // Defaults to 'String'
  suggestion?: string;
  updateType?: BulkUpdateType,
  hideUpdateType?:boolean
}

@Component({
  selector: 'app-bulk-update',
  templateUrl: './bulk-update.component.html',
  styleUrls: ['./bulk-update.component.scss']
})
export class BulkUpdateComponent implements OnInit {
  @Input() set fields(fields: Array<BulkUpdateField>) {
    if (fields) {
      if (!this.showProductTypes) {
        this.activeFields = [...fields];
      }
      this._fields = [...fields];
    } else {
      if (!this.showProductTypes) {
        this.activeFields = [];
      }
      this._fields = [];
    }
  };
  @Input() set productType(productType: string) {
    this._productType = productType;
    this.fetchProductTypeProperties(productType);
  };
  @Output() submit: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Input() showProductTypes = false;
  @Input() showDestinations = false;
  hideTypeInput = false;

  @Input() disableSubmit = false;
  @Output() disableSubmitChange: EventEmitter<boolean> = new EventEmitter();

  _fields: Array<BulkUpdateField> = [];
  _productType: string;
  _actions: Suggestion[] = [
    {
      id: 'add',
      name: 'Add'
    },
    {
      id: 'remove',
      name: 'Remove'
    },
    {
      id: 'replace',
      name: 'Replace'
    },
    {
      id: 'replaceMatch',
      name: 'Find & Replace'
    },
    {
      id: 'clear',
      name: 'Clear'
    },
  ];

  activeFields: Array<BulkUpdateField> = [];
  form: UntypedFormGroup = new UntypedFormGroup({
    destinationId: new UntypedFormControl('-1'),
    type: new UntypedFormControl(''),
    fieldName: new UntypedFormControl('', [Validators.required]),
    fieldValue: new UntypedFormControl(''),
    action: new UntypedFormControl(BulkUpdateType.Add),
    replaceMatch: new UntypedFormControl(''),
  });
  trueSelected = false;
  falseSelected = false;
  loading = false;
  areProductTypePropertiesFetched = false;
  productTypeSuggestions: Array<Suggestion> = [];
  destinations: Array<Catalog> = [];
  lastFetchedProductTypeProperties: string;
  fieldsToHideUpdateType: Set<String> = new Set();

  BulkUpdateFieldType = BulkUpdateFieldType;

  constructor(
    private store: Store,
    private viewApi: ViewApiService,
    private orgApi: OrgApiService,
    private subCatalogApi: SubcatalogApiService,
    private aOrAnPipe: AOrAnPipe
  ) { }

  ngOnInit(): void {
    this.activeFields.map((field) => {
        if (field.hideUpdateType) {
            if (!this.fieldsToHideUpdateType.has(field.fieldName)) {
                this.fieldsToHideUpdateType.add(field.fieldName);
            }
        }
    });
    this.reset();

    if (this.showDestinations) {
      this.fetchDestinations();
    }
    if (this.showProductTypes) {
      this.fetchProductTypeSuggestions();
    }
  }

  get activeFieldSuggestions(): Array<Suggestion> {
    const { action } = this.form.value;
    if (action && action === 'replaceMatch') {
      return this.activeFields.filter(af =>
        af?.type ?
        af.type === BulkUpdateFieldType.String ||
        af.type === BulkUpdateFieldType.Suggestion ||
        af.type === BulkUpdateFieldType.MultiSuggestion : true
      ).map(af => ({ id: af.fieldName, name: af.displayName}));
    }
    return this.activeFields.map(af => ({ id: af.fieldName, name: af.displayName  }));
  }

  get destinationsSuggestions(): Array<Suggestion> {
    return [{ id: '-1', name: 'Default' }, ...this.destinations.map(d => {
      return { id: d.destinations[0]?.id.toString(), name: d.name };
    })];
  }

  get actionSuggestions(): Array<Suggestion> {
    if (
      this.activeFieldType &&
      this.activeFieldType !== BulkUpdateFieldType.String &&
      this.activeFieldType !== BulkUpdateFieldType.Suggestion &&
      this.activeFieldType !== BulkUpdateFieldType.MultiSuggestion
    ) {
      return this._actions.filter(a => a.id !== 'replaceMatch');
    }
    return this._actions;
  }

  get activeFieldType(): BulkUpdateFieldType {
    const { fieldName } = this.form.value;
    return this.getFieldByFieldName(fieldName)?.type;
  }

  getFieldSuggestionLabel(fieldName: string): string {
    const field = this.getFieldByFieldName(fieldName);
    return `Select ${this.aOrAnPipe.transform(field.displayName)} ${field.displayName}`;
  }

  getFieldByFieldName(fieldName: string): BulkUpdateField {
    return this.activeFields.find(f => f.fieldName === fieldName);
  }

  selectTrue(): void {
    this.falseSelected = false;
    this.trueSelected = true;
    this.form.get('fieldValue').setValue('true');
  }

  selectFalse(): void {
    this.trueSelected = false;
    this.falseSelected = true;
    this.form.get('fieldValue').setValue('false');
  }

  filedSelected(e){
    if(this.fieldsToHideUpdateType.has(e)) this.hideTypeInput = true
    else this.hideTypeInput = false
  }


  reset(): void {
    this.disableSubmitChange.emit(false);
    this.form.reset();
    this.form.patchValue({ destinationId: '-1' });
    if (this.activeFields.length === 1) {
      this.form.patchValue({ fieldName: this.activeFields[0].fieldName });
    }
    this.trueSelected = false;
    this.falseSelected = false;
    this.areProductTypePropertiesFetched = false;
    this.fetchProductTypeProperties(this._productType);
  }

  resetFieldValue(): void {
    this.form.get('fieldValue').reset();
    this.trueSelected = false;
    this.falseSelected = false;
  }

  resetAction(): void {
    const { action } = this.form.value;
    if (this.activeFieldType && this.activeFieldType !== BulkUpdateFieldType.String && action === 'replaceMatch') {
      this.form.get('action').reset();
    }
  }

  private fetchDestinations(): void {
    this.loading = true;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.subCatalogApi.getAllSubCatalogDestinations(
      organization.id,
      ['uploadType'],
      [FilterJoin.AND],
      [FilterMatch.IS],
      ['User Created'],
      500,
      1,
      [OrderBy.ASC],
      ['name']
    ).pipe(
      tap(
        (destinations: Array<Catalog>) => {
          this.destinations = destinations;
          this.loading = false;
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.loading = false;
          return of(error);
        }
      )
    ).subscribe();
  }

  private fetchProductTypeSuggestions(): void {
    this.loading = true;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.orgApi.getSuggestions(['Product Types'], organization.id).pipe(
      tap(
        (suggestions: { [key: string]: Array<Suggestion> }) => {
          this.productTypeSuggestions = suggestions['Product Types'];
          this.loading = false;
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.activeFields = [...this._fields];
          this.loading = false;
          return of(error);
        }
      )
    ).subscribe();
  }

  fetchProductTypePropertiesForSeo(destinationId: string): void {
    if (destinationId === '-1') {
      this.fetchProductTypeProperties(this._productType);
    } else {
      this.fetchProductTypeProperties('Product SEO');
    }
  }

  fetchProductTypeProperties(type: string): void {
    if (!type || this.lastFetchedProductTypeProperties === type) {
      return;
    }
    this.lastFetchedProductTypeProperties = type;
    this.loading = true;
    this.form.patchValue({ fieldName: '' });
    this.resetFieldValue();
    this.areProductTypePropertiesFetched = false;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.viewApi.getViewByName(organization.id, type).pipe(
      tap(
        (view: View) => {
          this.activeFields = view.viewFields.map(
            (field: ViewField) => {
              let type: BulkUpdateFieldType = BulkUpdateFieldType.String;
              if (field.type === 'BulkUpdateFieldType.String') {
                type = BulkUpdateFieldType.String;
              } else if (field.type === 'BulkUpdateFieldType.Date') {
                type = BulkUpdateFieldType.Date;
              } else if (field.type === 'BulkUpdateFieldType.Datetime') {
                type = BulkUpdateFieldType.Datetime;
              } else if (field.type === 'BulkUpdateFieldType.Number') {
                type = BulkUpdateFieldType.Number;
              } else if (field.type === 'BulkUpdateFieldType.Suggestion') {
                if (field.multiSelection) {
                  type = BulkUpdateFieldType.MultiSuggestion;
                } else {
                  type = BulkUpdateFieldType.Suggestion;
                }
              } else if (field.type === 'BulkUpdateFieldType.Boolean') {
                type = BulkUpdateFieldType.Boolean;
              }
              return {
                displayName: field.displayName,
                fieldName: field.propertyName,
                type,
                suggestion: field.suggestion
              };
            }
          );
          this.areProductTypePropertiesFetched = true;
          this.loading = false;
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.activeFields = [...this._fields];
          this.areProductTypePropertiesFetched = true;
          this.loading = false;
          this.lastFetchedProductTypeProperties = undefined;
          return of(error);
        }
      )
    ).subscribe();
  }
}
