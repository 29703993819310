/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkflowErrorType } from './workflowErrorType';


export interface PluginStatusError { 
    color?: string;
    errorMessage?: string;
    errorRow?: number;
    errorType?: WorkflowErrorType;
    productId?: number;
    productName?: string;
    size?: string;
    sku?: string;
    statsErrorId?: number;
    statsId?: number;
    styleNumber?: string;
    syndic8Id?: string;
    upc?: string;
    width?: string;
}

