/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FilterDefinition } from './filterDefinition';
import { Frequency } from './frequency';


export interface ScheduleSyndication { 
    comments?: string;
    daysOfWeek?: Array<string>;
    deltasOnly?: boolean;
    destinationId?: number;
    endDate?: string;
    exportType?: string;
    filterDefinition?: FilterDefinition;
    frequency?: Frequency;
    orgId?: number;
    pluginName?: string;
    scheduledId?: number;
    startDate?: string;
    subCatalogId?: number;
    taskName?: string;
    templateName?: string;
    timeZone?: string;
    daysOfWeekStr?: string;
    filterDefinitionStr?: string;
}

