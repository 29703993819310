/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NotificationProgress } from './notificationProgress';
import { NotificationAction } from './notificationAction';
import { NotificationType } from './notificationType';


export interface Notification { 
    actions?: Array<NotificationAction>;
    /**
     * milliseconds until expiration
     */
    expiration?: number;
    icon?: string;
    id?: number;
    message?: string;
    progress?: NotificationProgress;
    read?: boolean;
    received?: boolean;
    route?: string;
    sentTime?: string;
    title?: string;
    type?: NotificationType;
}

