<a class="action" *ngIf="action" [title]="action.title"
  (click)="navigate($event, action)"
  [attr.href]="action.route && !action.selections ? '.' + action.route : null"
  [ngClass]="{ 'active': isActiveNavigation }">
  <div class="bullet">
    <div class="dot"></div>
  </div>
  <div class="icon">
    <i *ngIf="action.icon" [ngClass]="'fas fa-'+ action.icon"></i>
  </div>
  <div class="action-label">
    {{action.title }}
  </div>
</a>
