/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DrayCarrierEquipment { 
    associatedUnitId?: string;
    equipmentNumber?: string;
    equipmentTypeCode?: string;
    grossWeight?: number;
    pieceCount?: number;
    shipmentNumber?: string;
    weightUOM?: string;
}

