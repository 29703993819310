import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DynamicStyleLoaderService {
    private renderer: Renderer2;
    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }
    loadStyle(styleName: string) {
        const link = this.renderer.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = styleName;
        this.renderer.appendChild(document.head, link);
    }
}