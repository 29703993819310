import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { PanelService } from '../../../services/panel.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  providers: [PanelService]
})
export class PanelComponent implements OnInit {
  @Input() name: string;

  @ContentChild('header', { static: true }) headerTemplate: TemplateRef<any>;
  @ContentChild('content', { static: true }) contentTemplate: TemplateRef<any>;

  editable$ = this.panelSvc.editable$;

  constructor(private panelSvc: PanelService) { }

  ngOnInit(): void {
  }
}
