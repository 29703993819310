<div class="logo rapid-map" *ngIf="!!(isFreightForwardOrganization$ | async)">
  <i></i>
</div>
<ng-container *ngIf="!(isFreightForwardOrganization$ | async)">
  <div class="logo" *ngIf="!(isInriver$ | async) && !(isAcquia$ | async)">
    <i></i>
  </div>
  <div class="logo inriver" *ngIf="!!(isInriver$ | async)">
  </div>
  <div class="logo acquia" *ngIf="!!(isAcquia$ | async)">
    <i></i>
  </div>
</ng-container>
<!-- <app-selection-search [action]="action"></app-selection-search> -->
<ng-container *ngIf="action && action.selections">
  <div *ngFor="let selection of action.selections; index as i">
    <app-selection-action [selection]="selection" [id]="'selection-' + i"></app-selection-action>
    <div *ngIf="selection.route === '/logout' && (user$ | async) as user" class="profile-info">
      Hello, {{ user.firstName }}!
    </div>
    <div *ngIf="selection.route === '/logout' && (organization$ | async) as organization" class="organization-info">
      Your organization is <b>{{ organization.name }}</b>
    </div>
  </div>
</ng-container>
