/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Frequency = 'Daily' | 'Minute' | 'Monthly' | 'Once' | 'Weekly';

export const Frequency = {
    DAILY: 'Daily' as Frequency,
    MINUTE: 'Minute' as Frequency,
    MONTHLY: 'Monthly' as Frequency,
    ONCE: 'Once' as Frequency,
    WEEKLY: 'Weekly' as Frequency
};

