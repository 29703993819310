/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DataProcessField } from './dataProcessField';


export interface AGGridHeader { 
    aliasName?: Array<string>;
    convert?: Array<string>;
    dataProcessField?: DataProcessField;
    dataType?: string;
    defaultValue?: string;
    exportFlag?: boolean;
    field?: string;
    format?: string;
    headerName?: string;
    internalField?: string;
    internalFieldDisplay?: string;
    override?: boolean;
    recommended?: boolean;
    required?: boolean;
    templateId?: number;
}

