import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  HostListener,
  ElementRef,
  HostBinding,
  ViewChildren,
  QueryList,
  TemplateRef,
  ContentChild
} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { interval, of, Subject, Subscription, throwError } from 'rxjs';
import { filter, debounceTime, catchError, tap, switchMap, first } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { Suggestion, Organization, OrgApiService, ToastService, NotificationType } from '@app/core';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { HttpErrorResponse } from '@angular/common/http';
import {
  InfiniteScrollService,
  InfiniteScrollColumn,
  InfiniteScrollImage,
  InfiniteScrollChip,
  InfiniteScrollAction,
  InfiniteScrollTitle,
  InfiniteScrollPage,
  InfiniteScrollColor,
  InfiniteScrollColumnType,
  InfiniteScrollStyle,
  InfiniteScrollColorTestFn,
  InfiniteScrollLayout
} from '../../../services/infinite-scroll.service';
import { InfiniteScrollRowComponent } from '../infinite-scroll-row/infinite-scroll-row.component';

@Component({
  selector: 'app-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll-default.component.scss', './infinite-scroll-logistics.component.scss', './infinite-scroll-product.component.scss'],
  providers: [InfiniteScrollService]
})
export class InfiniteScrollComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() // List of key value objects, required
  set rows(rows: Array<{ [key: string]: any }>) {

    this.showFloater = rows ? this.lastRowsLength > this.initialRows : false;
    console.log("showFloater", this.showFloater)
    this._rows = rows;
    this.scrollSvc.rows = rows;
    this.disableInfiniteScroll = false;
  }

  @Input() savedRows;
  @Input() set rowIdPropertyName(propName: string) {
    this._rowIdPropertyName = propName;
    this.scrollSvc.rowIdPropertyName = propName;
  }
  @Input() pageLength: number;
  @Input() suggestions: { [key: string]: Array<Suggestion> } = {}; // A list of provided Suggestions, must be above `columns` attribute
  @Input() set columns(columns: Array<InfiniteScrollColumn>) {
    this._columns = columns;
    // Fetch any Suggestions attached to each column
    this.fetchSuggestions();
  } // List of columns that correspond to each key of each `row`
  @Input() images: Array<InfiniteScrollImage>; // List of images in the same order as `rows`
  @Input() titles: Array<InfiniteScrollTitle>; // List of titles in the same order as `rows`
  @Input() titleWidth = 200;
  @Input() chips: Array<Array<InfiniteScrollChip>>; // List of chips in the same order as each `row`
  @Input() actions: Array<Array<InfiniteScrollAction>>; // List of action buttons in the same order as each `row`
  @Input() warnings: Array<boolean> // List of warnings in the same order as each `row`
  @Input() processings: Array<boolean>; // List of booleans in the same order as each `row`
  @Input() showEllipsis = false; // Show or hide actions in an ellipsis menu
  @Output() ellipsisClick: EventEmitter<{ [key: string]: any }> = new EventEmitter();
  @Input() showDownloadButton = false; // Show or hide a download button
  @Output() downloadButtonClick: EventEmitter<{ [key: string]: any }> = new EventEmitter();
  @Output() rowNavigated: EventEmitter<{ [key: string]: any }> = new EventEmitter(); // Emit the `row` in context with a navigation click

  @Input() loading: boolean;
  @Input() noRowsMessage = 'Nothing here yet...'; // A message to be shown when `rows` is empty
  @Input() noRowsSubtitle = '';
  @Output() noRowsMessageClick: EventEmitter<void> = new EventEmitter();

  @Input() showHeader = false;
  @Input() showFilter = false;
  @Input() showSort = false;
  @Input() showNewRow = false;
  @Input() showHandles = false;

  @Input() rowColorTest: InfiniteScrollColorTestFn;
  // These two inputs are used for the coloring on the A+ Preview
  @Input() isColorCoded = false;
  @Input() isColorCodeInverted = false;

  @Input() showSelection = false; // Show or hide selection checkboxes
  @Input() showSelectionChange: EventEmitter<boolean> = new EventEmitter();
  @Input() showMasterCheckbox = false;

  @Input() rightSidePadding = 0;

  @Input() doubleClick = false; // A row must be double clicked to activate navigation

  @Input() hasFilters = false;
  @Output() hasFiltersChange: EventEmitter<boolean> = new EventEmitter();

  @Input() loadAction: ({ pageLength, pageToGet }: InfiniteScrollPage) => void;
  @Input() loadingAction: () => void;
  @Input() resetAction: () => void;
  @Input() updateAction: ({ rows }: { rows: Array<{ [key: string]: any }> }) => void;
  @Input() addAction: ({ row }: { row: { [key: string]: any } }) => void;

  // TODO: Test this as disabled across app before flipping to a `false` default
  @Input() navigateAfterUpdateAction = true;

  @Input() emptyCellText = '';

  @Input() layout: InfiniteScrollLayout = InfiniteScrollLayout.List; // Describes how to layout view (List or Grid)
  @Input() centerGrid = false;
  @Input() disableFloater = false;
  @Input() selectedRowId = null
  @ContentChild('customView', { static: true }) customViewTemplate: TemplateRef<any>;

  @HostBinding('class.logistics') isLogisticsStyle = false;
  @HostBinding('class.product') isProductStyle = false;

  @Input() set style(style: InfiniteScrollStyle) {
    this.isLogisticsStyle = false;
    this.isProductStyle = false;
    if (style) {
      this._style = style;
      if (style === InfiniteScrollStyle.Logistics) {
        this.isLogisticsStyle = true;
      } else if (style === InfiniteScrollStyle.Product) {
        this.isProductStyle = true;
      }
    } else {
      this._style = InfiniteScrollStyle.Default;
    }
  }

  masterCheckbox$ = this.scrollSvc.masterCheckbox$;
  masterCheckbox = this.scrollSvc.masterCheckbox.bind(this.scrollSvc);
  selected$ = this.scrollSvc.selected$;
  deselectAllRows = this.scrollSvc.deselectAllRows.bind(this.scrollSvc);
  editable$ = this.scrollSvc.editable$;
  editable = this.scrollSvc.editable.bind(this.scrollSvc);

  get selected(): Array<{ [key: string]: any }> {
    return this.scrollSvc.selectedRows.call(this.scrollSvc);
  }
  initialRows: number
  minWidths: Array<number> = [];
  _style: InfiniteScrollStyle;
  _rows: Array<{ [key: string]: any }>;
  _columns: Array<InfiniteScrollColumn>;
  _rowIdPropertyName: string;
  showFloater = false;
  disableInfiniteScroll = false;
  activeColumnDetail: string;
  private navigateSub: Subscription;
  private editableSub: Subscription;
  private _isEditable = false;
  private fetchedSuggestions: Array<string> = [];

  private lastRowsLength = 0;
  private lastScrollWindowHeight = 0;

  private resizeSubject: Subject<boolean> = new Subject();
  private resizeSubjectSub: Subscription;
  private routerEventsSub: Subscription;

  InfiniteScrollColor = InfiniteScrollColor;
  InfiniteScrollColumnType = InfiniteScrollColumnType;
  InfiniteScrollStyle = InfiniteScrollStyle;
  InfiniteScrollLayout = InfiniteScrollLayout;

  private get visibleRows(): number {
    if (this.pageLength) {
      return this.pageLength;
    }
    if (!this.scrollWindow) {
      return 1;
    }
    const scrollWindowHeight: number = this.scrollWindow.nativeElement.offsetHeight;
    let rowHeight = 34;
    if (this.titles && this.titles.length > 0) {
      rowHeight = 52;
    } else if (this._style === InfiniteScrollStyle.Logistics) {
      rowHeight = 20;
    }
    return Math.ceil(scrollWindowHeight / rowHeight) + 1;
  }

  private get longestChip(): Array<InfiniteScrollChip> {
    if (!this.chips || this.chips && this.chips.length === 0) {
      return [];
    }
    return this.chips.reduce(
      (acc, chip) => {
        if (acc.length < chip.length) {
          return chip;
        }
        return acc;
      }
    );
  }

  get chipWidth(): number {
    // Width of all chips + a space separator between each chip + an overall padding
    let width = 16 * this.longestChip.length;
    if (this.longestChip.length > 1) {
      width += 4 * this.longestChip.length - 1;
    }
    if (width) {
      return width + 8;
    }
    return 0;
  }

  @ViewChild('scrollWindow') scrollWindow!: ElementRef;
  @ViewChildren(InfiniteScrollRowComponent) childRows!: QueryList<InfiniteScrollRowComponent>;

  @HostListener('window:resize')
  onResize() {
    const newScrollHeight = this.scrollWindow?.nativeElement?.offsetHeight;
    // If scroll window height changed and its scrollbar dropped off, then it has been resized
    if (newScrollHeight !== this.lastScrollWindowHeight && this.scrollWindow.nativeElement.scrollHeight === newScrollHeight) {
      this.resizeSubject.next(true);
    }
    this.lastScrollWindowHeight = newScrollHeight;
  }

  constructor(
    private router: Router,
    private store: Store,
    private route: ActivatedRoute,
    private orgApi: OrgApiService,
    private toast: ToastService,
    private scrollSvc: InfiniteScrollService
  ) { }

  ngOnInit(): void {
    this.routerEventsSub = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => this.reloadRows());
    this.resizeSubjectSub = this.resizeSubject.pipe(
      debounceTime(800)
    ).subscribe(() => this.reloadRows());
    this.navigateSub = this.scrollSvc.navigate$.subscribe((row: { [key: string]: any }) => {
      this.rowNavigated.emit(row);
      this.selectedRowChanged(row);
    });
    this.editableSub = this.scrollSvc.editable$.subscribe(
      (editable: boolean) => {
        if (!editable && this._isEditable !== editable && this.updateAction) {
          this.lockEditing();
        }
        this._isEditable = editable;
      }
    );

    // FIXME: Causing race condition with `loading` input observable only on Valid Values scroll table
    // Setting state prop to true by default fixes this
    if (this.loadingAction) {
      this.loading = true;
    }
    if (this.loading === undefined) {
      this.loading = false;
    }
  }

  ngAfterViewInit(): void {
    this.lastScrollWindowHeight = this.scrollWindow.nativeElement.offsetHeight;

    interval(100).pipe(
      switchMap(() => of(this.scrollWindow)),
      filter(scrollWindow => scrollWindow instanceof ElementRef),
      first()
    ).subscribe(() => this.initialStoreActions());
    this.initialRows = this.visibleRows
    console.log("pageLength", this.pageLength)
    this.initialRows = this.visibleRows
    console.log("visibleRows AfterView", this.visibleRows)
    console.log("lastRowsLength AfterView", this.lastRowsLength)

  }

  ngOnDestroy(): void {
    this.routerEventsSub.unsubscribe();
    this.resizeSubjectSub.unsubscribe();
    this.navigateSub.unsubscribe();
    this.editableSub.unsubscribe();
  }

  private initialStoreActions(): void {
    let storeActions: Array<() => void> = [];
    if (this.loadingAction) {
      storeActions = [...storeActions, new this.loadingAction()];
    }
    if (this.resetAction) {
      this.disableInfiniteScroll = true;
      storeActions = [...storeActions, new this.resetAction()];
    }
    if (this.loadAction) {
      this.disableInfiniteScroll = true;
      this.cancelEditing();
      const pageToGet = 1;
      const pageLength = this.visibleRows;
      this.lastRowsLength = pageToGet * pageLength;
      storeActions = [...storeActions, new this.loadAction({ pageLength, pageToGet })];
    }

    if (storeActions.length > 0) {
      this.store.dispatch(storeActions);
    }
  }

  private fetchSuggestions(): void {
    if (!this._columns) {
      return;
    }
    for (let i = 0; i < this._columns.length; i++) {
      const column = this._columns[i];
      if ( // Fetch the Suggestion list if...
        !column?.suggestionInternalInclude &&
        column.suggestion && // The Column has a Suggestion config
        (!this.suggestions || !this.suggestions[column.suggestion]) && // And hasn't been provided
        this.fetchedSuggestions.findIndex(fs => fs === column.suggestion) === -1 // And hasn't already been fetched
      ) {
        this.fetchedSuggestions = [...this.fetchedSuggestions, column.suggestion];
        const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
        this.orgApi.getSuggestions([column.suggestion], organization.id).pipe(
          tap(
            (suggestions: { [key: string]: Array<Suggestion> }) => {
              this.suggestions[column.suggestion] = [...suggestions[column.suggestion]];

            }
          ),
          catchError(
            (error: HttpErrorResponse) => {
              this.toast.add({
                expiration: 5000,
                title: 'Service Error',
                message: 'Unable to Load Suggestions',
                type: NotificationType.ERROR
              });
              return throwError(error);
            }
          )
        ).subscribe();
      }
    }
  }

  cancelEditing(): void {
    if (this._isEditable) {
      this.childRows.forEach(
        (row: InfiniteScrollRowComponent) => {
          Object.keys(row.rowForm.controls).forEach(
            (key: string) => {
              const control = row.rowForm.get(key);
              if (control.dirty) {
                control.markAsPristine();
                control.setValue(row.interpolatePropertyString(key));
              }
            }
          );
          row.rowForm.markAsPristine();
        }
      );
      this.editable(false);
    }
  }

  private reloadRows(): void {
    this.scrollSvc.editable.call(this.scrollSvc, false);
    this.scrollSvc.deselectAllRows();
    this.lastRowsLength = 0;
    this.scrollWindow.nativeElement.scrollTo(0, 0);

    this.initialStoreActions();
  }

  refreshRows(): void {
    this.reloadRows();
  }

  trackByFn(index: number, row: { [key: string]: any }): any {
    if (this._rowIdPropertyName) {
      return row[this._rowIdPropertyName];
    }
    return row;
  }

  onScrollDown(): void {
    const pageLength = this.visibleRows;
    const pageToGet = Math.ceil(this._rows.length / pageLength) + 1;
    const lastPageToGet = Math.floor(this.lastRowsLength / pageLength);
    if (pageToGet > lastPageToGet) {
      let storeActions: Array<() => void> = [];
      if (this.loadingAction) {
        storeActions = [...storeActions, new this.loadingAction()];
      }
      if (this.loadAction) {
        this.disableInfiniteScroll = true;
        this.cancelEditing();
        console.log("lastRowsLength onScroll", this.lastRowsLength)
        this.lastRowsLength = pageToGet * pageLength;

        console.log("lastRowsLength reset onScroll", this.lastRowsLength)

        storeActions = [...storeActions, new this.loadAction({ pageLength, pageToGet })];
      }

      if (storeActions.length > 0) {
        this.store.dispatch(storeActions);
      }
    }
  }

  backToTop(): void {
    this.reloadRows();
  }

  removeFilters(): void {
    const { queryParams } = this.route.snapshot;
    if (queryParams.filterBy || queryParams.filterJoin || queryParams.filterMatch || queryParams.filterValue) {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParamsHandling: 'merge',
        queryParams: { filterBy: null, filterJoin: null, filterMatch: null, filterValue: null }
      });
    }
  }

  private lockEditing(): void {

    const rows = this.childRows.reduce(
      (acc, { rowForm: { value, valid, dirty } }) => {
        if (valid && dirty) {
          return [...acc, value];
        }
        return acc;
      },
      []
    );

    // If no valid and dirty rows to update, then return without reset
    if (rows.length === 0) {
      return;
    }

    const navigationEnd = () => {
      if (this.navigateAfterUpdateAction) {
        this.router.navigate(['.'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
      } else {
        this.reloadRows();
      }
    };

    let storeActions: Array<() => void> = [];
    if (this.loadingAction) {
      storeActions = [...storeActions, new this.loadingAction()];
    }
    if (this.updateAction) {
      storeActions = [...storeActions, new this.updateAction({ rows })];
    }

    if (storeActions.length > 0) {
      this.store.dispatch(storeActions).subscribe(navigationEnd, navigationEnd);
    }
  }

  selectedRowChanged(row) {
    // console.log("selectedRowChanged")
    if (this.rowIdPropertyName) {
      // const prev =
    }
  }
}
