import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthState,
  DataProcessBlock,
  Suggestion,
  Template
} from '@app/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  LoadCurrencyCodeSuggestions,
  LoadDestinationExportMappingTemplateParentListSuggestionsAction, LoadLanguageCodeSuggestions
} from "@app/features/destination/stores/destination-export-mapping.actions";
import { DestinationExportMappingState } from "@app/features/destination/stores/destination-export-mapping.state";

@Component({
  selector: 'app-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.scss']
})
export class TemplateEditComponent implements OnInit, OnDestroy {
  @Input() template$: Observable<Template>;
  @Input() loading$: Observable<boolean>;
  @Input() sheet$: Observable<string>;
  @Input() rootSheet$: Observable<DataProcessBlock>;
  @Input() LoadTemplate: () => void;
  @Input() UpdateTemplate: (payload: { templateMap: any, templateSheetName: string, parentTemplateName: string, changeEntryList: any[], currencyCodeOutput: string, languageCodePrimary: string, languageCodeOutput: string, inputDateFormat: string, forceParentAlignment?:boolean }) => void;
  @Input() AddSheet: (payload: { fieldNames: Array<string>, sheetName: string }) => void;
  @Input() RemoveSheet: () => void;
  @Input() UpdateRootSheet: (payload: { sheet: DataProcessBlock, index: number }) => void;

  showAddSheetModal = false;
  showRemoveSheetModal = false;

  /** Template Map part of a Template */
  form: UntypedFormGroup = new UntypedFormGroup({
    appendFile: new UntypedFormControl(''),
    sheetName: new UntypedFormControl('', [Validators.required]),
    clientKey: new UntypedFormControl('', [Validators.required]),
    fileExtension: new UntypedFormControl('', [Validators.required]),
    inputType: new UntypedFormControl(''),
    outputType: new UntypedFormControl(''),
    fileKey: new UntypedFormControl('', [Validators.required]),
    skipEmpty: new UntypedFormControl(''),
    skipHeader: new UntypedFormControl('', [Validators.required]),
    skipCatalogProduct: new UntypedFormControl(''),
    headerKey: new UntypedFormControl('', [Validators.required]),
    messageType: new UntypedFormControl(''),
    messageTable: new UntypedFormControl(''),
    messageBlock: new UntypedFormControl(''),
    messageKey: new UntypedFormControl(''),
    messageFlow: new UntypedFormControl(''),
    token: new UntypedFormControl('', [Validators.required]),
  });

  templateForm: UntypedFormGroup = new UntypedFormGroup({
    parentTemplateName: new UntypedFormControl(''),
    sheetName: new UntypedFormControl('', [Validators.required]),
    changeEntryList: new FormControl([]),
    currencyCodeOutput: new UntypedFormControl(),
    languageCodePrimary: new UntypedFormControl(),
    languageCodeOutput: new UntypedFormControl(),
    inputDateFormat: new UntypedFormControl(),
    forceParentAlignment: new UntypedFormControl(false),
  });

  rootSheetForm: UntypedFormGroup = new UntypedFormGroup({
    bulkOutputRows: new UntypedFormControl(),
    currentLine: new UntypedFormControl(),
    currentPage: new UntypedFormControl(),
    dataBufferName: new UntypedFormControl(),
    deleteAfterRun: new UntypedFormControl(),
    displayName: new UntypedFormControl(),
    duplicateFieldName: new UntypedFormControl(),
    sameFieldNameParent: new UntypedFormControl(),
    endLineNumber: new UntypedFormControl(),
    fieldSame: new UntypedFormControl(),
    formatName: new UntypedFormControl(),
    headerRecord: new UntypedFormControl(),
    headerRow: new UntypedFormControl(),
    inactive: new UntypedFormControl(),
    interfaceId: new UntypedFormControl(),
    matchEnd: new UntypedFormControl(),
    matchNext: new UntypedFormControl(),
    matchNext2: new UntypedFormControl(),
    matchStart: new UntypedFormControl(),
    matchStart2: new UntypedFormControl(),
    minNumber: new UntypedFormControl(),
    name: new UntypedFormControl(),
    number: new UntypedFormControl(),
    occurrence: new UntypedFormControl(),
    outputName: new UntypedFormControl(),
    pageEnd: new UntypedFormControl(),
    pageStart: new UntypedFormControl(),
    parent: new UntypedFormControl(),
    parentCurrentLine: new UntypedFormControl(),
    processEndMatch: new UntypedFormControl(),
    resetCurrentLine: new UntypedFormControl(),
    rootBlock: new UntypedFormControl(),
    skipCount: new UntypedFormControl(),
    token: new UntypedFormControl(),
    useParentLine: new UntypedFormControl(),
    xpath: new UntypedFormControl()
  });

  sheetForm: UntypedFormGroup = new UntypedFormGroup({
    fieldNames: new UntypedFormControl('', [Validators.required]),
    sheetName: new UntypedFormControl('', [Validators.required]),
  });

  logs: any[] = [];
  sub: Subscription;
  rootSheetSub: Subscription;

  headerKeysSuggestions: Suggestion[] = [{
    id: 'ExportImage',
    name: 'ExportImage'
  },
  {
    id: 'ExportProduct',
    name: 'ExportProduct'
  }];
  fileTypeSuggestions: Suggestion[] = [
    {
      id: 'csv',
      name: 'csv'
    },
    {
      id: 'json',
      name: 'json'
    },
    {
      id: 'xlsm',
      name: 'xlsm'
    },
    {
      id: 'xlsx',
      name: 'xlsx'
    }
  ];

  @Select(DestinationExportMappingState.loadParentTemplateSuggestionsForEditTemplate) templateParentSuggestions$: Observable<Array<Suggestion>>;
  @Select(DestinationExportMappingState.loadLanguageCodeSuggestionList) languageCodeSuggestionList$: Observable<Array<Suggestion>>;
  @Select(DestinationExportMappingState.loadCurrencyCodeSuggestionList) currencyCodeSuggestionList$: Observable<Array<Suggestion>>;
  // Query all input elements
  @ViewChildren('input') inputs!: QueryList<ElementRef>;

  logInputForm: FormControl = new FormControl('', [Validators.required]);
  changedDate: any;
  createdDate: any;

  constructor(private store: Store, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.sub = combineLatest([
      this.template$,
      this.sheet$
    ]).subscribe(([template, sheet]) => {
      console.log('template *=>', template)
      this.changedDate =  template?.changedDate
      this.createdDate = template?.createdDate
      console.log('sheet *=>', sheet)
      this.resetForm(template, sheet);
      if (template?.dataProcessTemplate) {
          this.templateForm.patchValue({...template?.dataProcessTemplate})
        this.logs = template?.dataProcessTemplate['changeEntryList'] || [];
      }
    });

    this.rootSheetSub = this.rootSheet$.subscribe(sheet => this.rootSheetForm.patchValue(sheet))
    this.store.dispatch([new LoadDestinationExportMappingTemplateParentListSuggestionsAction(), new LoadLanguageCodeSuggestions(), new LoadCurrencyCodeSuggestions()])

    combineLatest([this.currencyCodeSuggestionList$, this.languageCodeSuggestionList$]).subscribe(() => {
      this.store.dispatch(new this.LoadTemplate());
    })
  }


  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.rootSheetSub.unsubscribe();
  }
  // Move focus to the next input
  moveFocus(event: KeyboardEvent) {
    const currentInputIndex = this.inputs?.toArray()?.findIndex(
      (input) => input?.nativeElement === event?.target
    );

    // If the current input is found and it is not the last one
    if (currentInputIndex >= 0 && currentInputIndex < this?.inputs?.length - 1) {
      // Set focus to the next input
      setTimeout(() => {
        const nextInput = this.inputs?.toArray()[currentInputIndex + 1];
        nextInput.nativeElement?.focus();
      }, 0);
    }
  }


  resetForm(template: Template, firstSheetName: string): void {
    if (template && template.dataProcessTemplate && template.dataProcessTemplate.templateMap) {
      this.form.reset();
      // TODO:
      this.templateForm.reset();
      const { parentTemplateName, currencyCodeOutput, languageCodePrimary, languageCodeOutput, inputDateFormat, forceParentAlignment, templateMap: { appendFile, changeEntryList, sheetName, clientKey, fileExtension, inputType, outputType, fileKey, skipEmpty, skipHeader, skipCatalogProduct, headerKey, messageType, messageTable, messageBlock, messageKey, messageFlow, token } } = template.dataProcessTemplate;


      this.form.patchValue({ appendFile, sheetName, clientKey, fileExtension, inputType, outputType, fileKey, skipEmpty, skipHeader, skipCatalogProduct, headerKey, messageType, messageTable, messageBlock, messageKey, messageFlow, token, parentTemplateName });
      this.templateForm.patchValue({ parentTemplateName, sheetName: firstSheetName, changeEntryList: changeEntryList || [], currencyCodeOutput, languageCodePrimary, languageCodeOutput,inputDateFormat, forceParentAlignment });
    }
  }

  updateTemplateProperties(): void {
    // TODO:
    const { appendFile, sheetName, clientKey, fileExtension, inputType, outputType, fileKey, skipEmpty, skipHeader, skipCatalogProduct, headerKey, messageType, messageTable, messageBlock, messageKey, messageFlow, token } = this.form.value;
    const { currencyCodeOutput, languageCodePrimary, languageCodeOutput, parentTemplateName, changeEntryList, forceParentAlignment } = this.templateForm.value;
    const templateSheetName = this.templateForm?.value?.sheetName;
    const inputDateFormat = this.templateForm?.value?.inputDateFormat;
    // The suggestion list uses the display name, we need the .json name in order to update the template
    const tmpArr = []
    this.templateParentSuggestions$.
      subscribe((sugg) => {
        sugg?.forEach((x) => tmpArr.push(x));
      });
      debugger
    const selectedSugg = tmpArr.filter((sugg) => sugg.name === parentTemplateName).pop();
    const actualParentName = selectedSugg ? selectedSugg.id : undefined;

    const templateMap = {
      appendFile,
      clientKey,
      fileExtension,
      fileKey,
      inputType,
      outputType,
      skipEmpty,
      skipHeader,
      skipCatalogProduct,
      headerKey,
      messageType,
      messageTable,
      messageBlock,
      messageKey,
      messageFlow,
      token,
      sheetName
    }

    this.store.dispatch(new this.UpdateTemplate({ templateMap, templateSheetName, parentTemplateName: actualParentName, changeEntryList, currencyCodeOutput, languageCodePrimary, languageCodeOutput, inputDateFormat, forceParentAlignment }));
  }

  cancel() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  get formattedFieldNames(): Array<string> {
    const { fieldNames } = this.sheetForm.value;
    if (!fieldNames) {
      return [];
    }
    const array = fieldNames.split('\t');
    if (!array[array.length - 1]) {
      array.pop();
    }
    return array
  }

  addNewSheet(): void {
    if (this.sheetForm.invalid || this.formattedFieldNames.length === 0) {
      return;
    }
    const fieldNames: Array<string> = this.formattedFieldNames;
    const { sheetName } = this.sheetForm.value;
    this.store.dispatch(new this.AddSheet({ fieldNames, sheetName })).subscribe(() => this.showAddSheetModal = false);
  }

  removeSheet(): void {
    this.store.dispatch(new this.RemoveSheet()).subscribe({
      complete: () => {
        this.showRemoveSheetModal = false;
        this.sheetForm.reset();
        this.router.navigate([], { relativeTo: this.route.parent });
      }
    });
  }

  updateRootSheet(): void {
    const sheet = this.rootSheetForm.value;
    const index = 0;
    this.store.dispatch(new this.UpdateRootSheet({ sheet, index }))
  }

  addLog() {
    const author = this.store.selectSnapshot(AuthState.getUser)?.email;
    const { appendFile, sheetName, clientKey, fileExtension, inputType, outputType, fileKey, skipEmpty, skipHeader, skipCatalogProduct, headerKey, messageType, messageTable, messageBlock, messageKey, messageFlow, token } = this.form.value;
    const { parentTemplateName, changeEntryList, currencyCodeOutput, languageCodePrimary, languageCodeOutput, inputDateFormat, forceParentAlignment } = this.templateForm.value;
    const templateSheetName = this.templateForm?.value?.sheetName;

    const newLog = {
      author,
      changeDate: new Date().getTime(),
      notes: this.logInputForm.value
    };
    const updatedChangeEntryList = [...changeEntryList, newLog];

    const templateMap = {
      appendFile,
      clientKey,
      fileExtension,
      fileKey,
      inputType,
      outputType,
      skipEmpty,
      skipHeader,
      skipCatalogProduct,
      headerKey,
      messageType,
      messageTable,
      messageBlock,
      messageKey,
      messageFlow,
      token,
      sheetName
    }
    this.store.dispatch(new this.UpdateTemplate({ templateMap, templateSheetName, parentTemplateName, changeEntryList: updatedChangeEntryList, currencyCodeOutput, languageCodePrimary, languageCodeOutput,inputDateFormat, forceParentAlignment }));
  }
}
