/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ChatGPTData { 
    destinationId?: number;
    excludeKeywords?: Array<string>;
    imageUrlList?: Array<string>;
    includeKeywords?: Array<string>;
    inputLanguage?: string;
    modelName?: string;
    numberOfTokens?: number;
    outputLanguage?: string;
    personas?: Array<string>;
    productName?: string;
    prompt?: string;
    promptResposeList?: Array<string>;
    selectedField?: string;
    targetProfiles?: Array<string>;
}

