/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ScheduledSyndication { 
    activeFlag?: boolean;
    changedBy?: number;
    comments?: string;
    companyId?: number;
    createdBy?: number;
    daysOfWeek?: string;
    deltasOnly?: boolean;
    destinationId?: number;
    endDate?: string;
    filterDefinition?: string;
    frequency?: string;
    lastRunStartTime?: string;
    nextCatId?: number;
    nextStatsId?: number;
    pluginName?: string;
    scheduledId?: number;
    startDate?: string;
    subCatalogId?: number;
    taskName?: string;
    templateName?: string;
    timeZone?: string;
}

