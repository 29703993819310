<div class="pages-wrapper">
  <ng-content></ng-content>
</div>
<div class="controls">
  <div class="nav-button">
    <span *ngIf="activePageNumber > 1" (click)="showPreviousPage()">Previous</span>
  </div>
  <div class="page-circles">
    <div class="page-circle" *ngFor="let page of pages" (click)="showPage(page)" [ngClass]="{active: page.active, completed: page.completed}">
      <i class="fal fa-check"></i>
    </div>
  </div>
  <div class="nav-button">
    <span *ngIf="activePageNumber < pages.length" (click)="showNextPage()">Next</span>
  </div>
</div>
