/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VesselVoyage } from './vesselVoyage';
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';
import { Location } from './location';


export interface Validation { 
    bilOfLading?: string;
    comment?: string;
    containerId?: number;
    containerNumber?: string;
    containerUnitSizeType?: string;
    containerWeight?: number;
    createdDate?: ModifiedDate;
    customerLocationDischarge?: Location;
    customerVesselETA?: string;
    customerVesselName?: string;
    customerVoyageName?: string;
    locationFinalCity?: Location;
    locationFinalDestination?: Location;
    orgRefShipper?: OrganizationReference;
    sealNumber?: string;
    shipmentId?: number;
    vesselVoyage?: VesselVoyage;
}

