import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-selection',
  templateUrl: './modal-selection.component.html',
  styleUrls: ['./modal-selection.component.scss']
})
export class ModalSelectionComponent implements OnInit {
  @Input() options: Array<any> = [];
  @Input() key = 'id';
  @Input() value = 'name';
  @Input() selected: Array<any> = [];
  @Output() selectedChange: EventEmitter<Array<any>> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  select(option: any): void {
    const index = this.selected.findIndex(s => s[this.key] === option[this.key]);
    // If found, remove
    if (index > -1) {
      this.selected = [...this.selected.slice(0, index), ...this.selected.slice(index + 1)];
    } else {
      // Else append
      this.selected = [...this.selected, option];
    }
    this.selectedChange.emit(this.selected);
  }

  isSelected(option: any): boolean {
    return this.selected.findIndex(s => s[this.key] === option[this.key]) > -1;
  }

}
