import { Component, OnInit, Input, Output, EventEmitter, HostListener, HostBinding } from '@angular/core';
import { InfiniteScrollColumn } from '@app/shared';

@Component({
  selector: 'app-active-columns-panel',
  templateUrl: './active-columns-panel.component.html',
  styleUrls: ['./active-columns-panel.component.scss']
})
export class ActiveColumnsPanelComponent implements OnInit {
  @Input() initialColumns: Array<string>;
  @Input() allColumns: Array<InfiniteScrollColumn> = [];
  @Input() columns: Array<InfiniteScrollColumn>;
  @Output() columnsChange: EventEmitter<Array<InfiniteScrollColumn>> = new EventEmitter(true);
  @Input() localStorageKey: string;

  @HostBinding('class.open') @Input() open = false;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();

  insideClick = false;
  outsideClick = false;

  @HostListener('click')
  onClick() {
    this.insideClick = true;
  }

  @HostListener('document:click')
  clickOutside() {
    if (!this.insideClick && !this.outsideClick) {
      this.openChange.emit(false);
      this.open = false;
    }
    this.insideClick = false;
    this.outsideClick = false;
  }

  constructor() { }

  ngOnInit(): void {
    if (this.activeColumns && this.activeColumns.length > 0) {
      this.columns = this.activeColumns.reduce(
        (acc, active) => {
          const column = this.allColumns.find(c => c.propertyName === active);
          if (!column) {
            return acc;
          }
          return [...acc, column];
        }, []
      );
      // Reset storage if any Active Columns went unfound in All Columns
      if (this.columns.length < this.activeColumns.length) {
        localStorage.setItem(this.localStorageKey, this.columns.map(c => c.propertyName).join(','));
      }
    } else {
      if (this.initialColumns && this.initialColumns.length > 0) {
        const columns = this.initialColumns.reduce(
          (acc, ic) => {
            const index = this.allColumns.findIndex(c => c.propertyName === ic);
            return index > -1 ? [...acc, this.allColumns[index]] : acc;
          },
          []
        );
        localStorage.setItem(this.localStorageKey, columns.map(c => c.propertyName).join(','));
        this.columns = [...columns];
      } else {
        localStorage.setItem(this.localStorageKey, this.allColumns.map(c => c.propertyName).join(','));
        this.columns = [...this.allColumns];
      }
    }

    this.columnsChange.emit(this.columns);
  }

  get activeColumns(): Array<string> {
    if (!localStorage.getItem(this.localStorageKey)) {
      return [];
    }
    return localStorage.getItem(this.localStorageKey).split(',');
  }

  toggleColumn(column: InfiniteScrollColumn) {
    if (this.activeColumns.findIndex(c => c === column.propertyName) > -1) {
      this.columns = this.columns.filter(c => c.propertyName !== column.propertyName);
    } else {
      this.columns = [...this.columns, column];
    }

    localStorage.setItem(this.localStorageKey, this.columns.map(c => c.propertyName).join(','));
    this.columnsChange.emit(this.columns);
  }

  isColumnActive(column: InfiniteScrollColumn): boolean {
    return this.activeColumns.findIndex(c => c === column.propertyName) > -1;
  }
}
