import { Component, OnInit, Input, Output, EventEmitter, HostListener, HostBinding, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InfiniteScrollColumn } from '../../services/infinite-scroll.service';

@Component({
  selector: 'app-active-columns',
  templateUrl: './active-columns.component.html',
  styleUrls: ['./active-columns.component.scss']
})
export class ActiveColumnsComponent implements OnInit, OnDestroy {
  @Input() set allColumns(allColumns: Array<InfiniteScrollColumn>) {
    this._allColumns = allColumns;
    this.filterFields();
  };
  @Input() activeColumns: Array<InfiniteScrollColumn> = [];
  @Input() showFilter = false;

  @HostBinding('class.open') @Input() open = false;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();

  @Output() updateColumn: EventEmitter<{ column: InfiniteScrollColumn, show: boolean }> = new EventEmitter();

  insideClick = false;
  outsideClick = false;

  _allColumns: Array<InfiniteScrollColumn> = [];
  filteredColumns: Array<InfiniteScrollColumn> = [];
  filterControl: UntypedFormControl = new UntypedFormControl('');
  filterControlSub: Subscription;

  @HostListener('click')
  onClick() {
    this.insideClick = true;
  }

  @HostListener('document:click')
  clickOutside() {
    if (!this.insideClick && !this.outsideClick) {
      this.openChange.emit(false);
      this.open = false;
      this.filterControl.reset();
    }
    this.insideClick = false;
    this.outsideClick = false;
  }

  constructor() { }

  ngOnInit(): void {
    this.filterControlSub = this.filterControl.valueChanges.subscribe(() => this.filterFields());
  }

  ngOnDestroy(): void{
    this.filterControlSub.unsubscribe();
  }

  private filterFields(): void {
    const value = this.filterControl.value;
    if (!value) {
      this.filteredColumns = [...this._allColumns];
    } else {
      this.filteredColumns = this._allColumns.filter(c => c.displayName.toLowerCase().includes(value.toLowerCase()));
    }
  }

  toggleColumn(column: InfiniteScrollColumn): void {
    if (column?.validation.required === true) {
      return;
    }
    this.updateColumn.emit({ column, show: !this.isColumnActive(column) });
  }

  isColumnActive(column: InfiniteScrollColumn): boolean {
    return this.activeColumns.findIndex(c => c.propertyName === column.propertyName) > -1;
  }
}
