import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Suggestion } from '@app/core';

@Component({
  selector: 'app-media-naming-builder',
  templateUrl: './media-naming-builder.component.html',
  styleUrls: ['./media-naming-builder.component.scss']
})
export class MediaNamingBuilderComponent implements OnInit {
  @Input() set open(open: boolean) {
    this._open = open;
    if (this.control) {
      const { value } = this.control;
      if (open && value) {
        this.chipString = value;
      }
    }
  };
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();
  @Input() control: FormControl<string>;
  @Input() suggestions: Array<Suggestion>;

  chipString = '';
  _open = false;

  constructor() { }

  ngOnInit(): void {
  }

  private convertValue(value: Suggestion): string {
    if (!value) {
      return '';
    }
    if (value.id.startsWith('InternalName:')) {
      return '${'+value.id.split(':')[1]+'}';
    } else {
      return value.id;
    }
  }

  mediaFieldSelected(value: Suggestion): void {
    if (!value.id && !value.name) {
      return;
    }
    this.chipString += this.convertValue(value);
  }

  add(): void {
    this.control.setValue(this.chipString);
    this.control.markAsDirty();
    this._open = false;
    this.openChange.emit(false);
  }

  cancel(): void {
    this._open = false;
    this.openChange.emit(false);
  }


  clear(): void {
    this.chipString = '';
  }

}
