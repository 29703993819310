<div class="navigation-actions">
    <div class="logos">
        <div class="wrap">
            <div class="icon" (click)="goHome()">
                <i [ngClass]="{ 'color': !navigationOpen && !selectionOpen }"></i>
            </div>
            <div class="text">
                <i></i>
            </div>
        </div>
    </div>
    <div class="upper navigation-action-list">

        <!-- Org Select -->
        <div class="navigation-group-header" *ngIf="orgSelectAction">
            <div class="navigation-group-header-icon"></div>
            <span>Select Customers</span>
        </div>
        <app-navigation-action *ngIf="orgSelectAction" [action]="orgSelectAction">
        </app-navigation-action>

        <!-- Onboarding -->
        <div class="navigation-group-header" *ngIf="allDataFlowsAction">
            <div class="navigation-group-header-icon"></div>
            <span>Onboarding</span>
        </div>
        <app-navigation-action *ngIf="allDataFlowsAction" [action]="allDataFlowsAction">
        </app-navigation-action>
        <app-navigation-action *ngIf="newDataFlowAction" [action]="newDataFlowAction">
        </app-navigation-action>
        <app-navigation-action *ngIf="ftpConfigAction" [action]="ftpConfigAction">
        </app-navigation-action>

        <!-- Mapping -->
        <div class="navigation-group-header" *ngIf="referenceTableListAction">
            <div class="navigation-group-header-icon"></div>
            <span>Mapping References</span>
        </div>
        <app-navigation-action *ngIf="referenceTableListAction" [action]="referenceTableListAction">
        </app-navigation-action>
        <app-navigation-action *ngIf="freightForwardLocationsAction" [action]="freightForwardLocationsAction">
        </app-navigation-action>
        <!-- <app-navigation-action *ngIf="importListAction" [action]="importListAction">
        </app-navigation-action>
        <app-navigation-action *ngIf="exportAction" [action]="exportAction">
        </app-navigation-action> -->

        <!-- Tracking -->
        <div class="navigation-group-header" *ngIf="purchaseOrdersListAction">
            <div class="navigation-group-header-icon"></div>
            <span>Tracking</span>
        </div>
        <app-navigation-action *ngIf="purchaseOrdersListAction" [action]="purchaseOrdersListAction">
        </app-navigation-action>
        <app-navigation-action *ngIf="containersListAction" [action]="containersListAction">
        </app-navigation-action>
        <!-- <app-navigation-action *ngIf="inboxOutboxAction" [action]="inboxOutboxAction">
        </app-navigation-action> -->
        <!-- <app-navigation-action *ngIf="purchaseOrdersListAction" [action]="purchaseOrdersListAction">
        </app-navigation-action>
        <app-navigation-action *ngIf="purchaseOrdersMappingRequiredAction"
            [action]="purchaseOrdersMappingRequiredAction">
        </app-navigation-action>
        <app-navigation-action *ngIf="purchaseOrdersReadyToSendAction" [action]="purchaseOrdersReadyToSendAction">
        </app-navigation-action>
        <app-navigation-action *ngIf="purchaseOrdersSentAction" [action]="purchaseOrdersSentAction">
        </app-navigation-action>
        <app-navigation-action *ngIf="purchaseOrdersAcknowledgedAction" [action]="purchaseOrdersAcknowledgedAction">
        </app-navigation-action> -->

        <!-- Imports -->
        <!-- <div class="navigation-group-header">
            <div class="navigation-group-header-icon"></div>
            <span>Mapping</span>
        </div>
        <app-navigation-action *ngIf="importListAction" [action]="importListAction">
        </app-navigation-action>
        <app-navigation-action *ngIf="importHistoryAction" [action]="importHistoryAction">
        </app-navigation-action>
        <app-navigation-action *ngIf="referenceTableListAction" [action]="referenceTableListAction">
        </app-navigation-action> -->


        <!-- Reference Tables -->
        <!-- <div class="navigation-group-header">
            <div class="navigation-group-header-icon"></div>
            <span>Reference Tables</span>
        </div>
        <app-navigation-action *ngIf="referenceTableListAction" [action]="referenceTableListAction">
        </app-navigation-action>
        <app-navigation-action *ngIf="referenceTableCreateAction" [action]="referenceTableCreateAction">
        </app-navigation-action>
        <app-navigation-action *ngIf="referenceTableUploadAction" [action]="referenceTableUploadAction">
        </app-navigation-action> -->


        <!-- Export -->
        <!-- <div class="navigation-group-header">
            <div class="navigation-group-header-icon"></div>
            <span>Export</span>
        </div>
        <app-navigation-action *ngIf="exportAction" [action]="exportAction">
        </app-navigation-action> -->
    </div>

    <div class="flex-fill"></div>
    <div class="lower navigation-action-list">
        <app-navigation-action *ngIf="dashboardAction" [action]="dashboardAction"></app-navigation-action>
        <app-navigation-action *ngIf="adminAction" [action]="adminAction"></app-navigation-action>
        <app-navigation-action [action]="accountNavigationActionItem"></app-navigation-action>
    </div>
</div>

<!-- <div class="testing-banner" *ngIf="showTestingBanner">
    You are in the ACS mapping & testing environment.
</div> -->
