import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterMatch, FilterJoin } from '@app/core';

@Component({
  selector: 'app-product-lifecycle-status-divergent-chart',
  templateUrl: './product-lifecycle-status-divergent-chart.component.html',
  styleUrls: ['./product-lifecycle-status-divergent-chart.component.scss']
})
export class ProductLifecycleStatusDivergentChartComponent implements OnInit {
  @Input() step: any = {
    name: 'Data Quality',
    passes: 45,
    warnings: 2,
    approvals: 0,
    errors: 3
  };

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }

  stepNameWarn(step: any): void {
    if (!step.name) {
      return;
    }
    const { queryParams } = this.route.snapshot;
    this.router.navigate(['../product'], {
      relativeTo: this.route,
      queryParams: {
        groupBy: queryParams.category ? queryParams.category : 'name',
        filterBy: queryParams.brand ? ['stepNameWarn', 'brandRef.id'] : 'stepNameWarn',
        filterMatch: queryParams.brand ? [FilterMatch.IS, FilterMatch.IS] : FilterMatch.IS,
        filterValue: queryParams.brand ? [step.name, queryParams.brand] : step.name,
        filterJoin: queryParams.brand ? [FilterJoin.AND, FilterJoin.AND] : FilterJoin.AND
      }
    });
  }

  stepNameError(step: any): void {
    if (!step.name) {
      return;
    }
    const { queryParams } = this.route.snapshot;
    this.router.navigate(['../product'], {
      relativeTo: this.route,
      queryParams: {
        groupBy: queryParams.category ? queryParams.category : 'name',
        filterBy: queryParams.brand ? ['stepNameError', 'brandRef.id'] : 'stepNameError',
        filterMatch: queryParams.brand ? [FilterMatch.IS, FilterMatch.IS] : FilterMatch.IS,
        filterValue: queryParams.brand ? [step.name, queryParams.brand] : step.name,
        filterJoin: queryParams.brand ? [FilterJoin.AND, FilterJoin.AND] : FilterJoin.AND
      }
    });
  }

  stepName(step: any): void {
    if (!step.name) {
      return;
    }
    const { queryParams } = this.route.snapshot;
    this.router.navigate(['../product'], {
      relativeTo: this.route,
      queryParams: {
        groupBy: queryParams.category ? queryParams.category : 'name',
        filterBy: queryParams.brand ? ['stepName', 'brandRef.id'] : 'stepName',
        filterMatch: queryParams.brand ? [FilterMatch.IS, FilterMatch.IS] : FilterMatch.IS,
        filterValue: queryParams.brand ? [step.name, queryParams.brand] : step.name,
        filterJoin: queryParams.brand ? [FilterJoin.AND, FilterJoin.AND] : FilterJoin.AND
      }
    });
  }
}
