import { NgModule } from '@angular/core';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@env/environment';

// Guards
import { AuthGuard } from './core/guards/auth.guard';
import { LoadUserGuard } from './core/guards/load-user.guard';
import { MissingGuard } from './core/guards/missing.guard';
import { BrowserGuard } from './core/guards/browser.guard';
import { DeviceGuard } from './core/guards/device.guard';
import { AgentGuard } from './core/guards/agent.guard';
import { TermsGuard } from './core/guards/terms.guard';

// Pages
import { LogoutComponent } from './core/pages/logout/logout.component';
import { ErrorComponent } from './core/pages/error/error.component';
import { MissingComponent } from './core/pages/missing/missing.component';
import { BrowserComponent } from './core/pages/browser/browser.component';
import { DeviceComponent } from './core/pages/device/device.component';
import { CallbackComponent } from './core/pages/callback/callback.component';
import { AmazonCallbackComponent } from './core/pages/amazon-callback/amazon-callback.component';
import { TermsAndPrivacyComponent } from './core/pages/terms-and-privacy/terms-and-privacy.component';
import {MagentoCallbackComponent} from "@app/core/pages/magento-callback/magento-callback.component";
import { PartnerCallbackComponent } from "@app/core/pages/partner-callback/partner-callback/partner-callback.component";
import { NgxsModule } from '@ngxs/store';

const routes: Routes = [
  {
    path: '',
    canActivate: [AgentGuard, AuthGuard, LoadUserGuard],
    children: [
      {
        path: 'org',
        loadChildren: () => import('./features/organization/organization.module').then(m => m.OrganizationModule)
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'org'
      }
    ]
  },
  {
    path: 'pub',
    canActivate: [AgentGuard, AuthGuard, LoadUserGuard],
    loadChildren: () => import('./features/public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'amazon-callback',
    component: AmazonCallbackComponent
  },
  {
    path: 'magento-callback',
    component: MagentoCallbackComponent
  },
  {
    path: 'partner-callback',
    component: PartnerCallbackComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'terms',
    canActivate: [TermsGuard],
    component: TermsAndPrivacyComponent,
    data: {
      title: 'Privacy and Terms'
    }
  },
  {
    path: 'browser',
    canActivate: [BrowserGuard],
    component: BrowserComponent,
    data: {
      title: 'Unsupported Browser'
    }
  },
  {
    path: 'device',
    canActivate: [DeviceGuard],
    component: DeviceComponent,
    data: {
      title: 'Device Unsupported'
    }
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: {
      title: 'Oops...'
    }
  },
  {
    path: 'missing',
    component: MissingComponent,
    canActivate: [MissingGuard],
    data: {
      title: 'Not Found'
    }
  },
  { path: '**', redirectTo: 'missing' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        enableTracing: environment.logging,
        onSameUrlNavigation: 'reload'
      }
    ),
    NgxsModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    LoadUserGuard,
    MissingGuard,
    BrowserGuard,
    DeviceGuard,
    AgentGuard
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
