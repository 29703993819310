import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LogisticsApiService, NotificationType, Organization, OrgApiService, Suggestion, ToastService } from '@app/core';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { ApplicationService } from '@app/shared/services/application.service';
import { InfiniteScrollColumn, InfiniteScrollService, InfiniteScrollStyle } from '../../../services/infinite-scroll.service';
import { SuggestionList } from '@app/shared/typings/suggestion-list.types';
import { Store } from '@ngxs/store';
import { Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'app-infinite-scroll-select',
  templateUrl: './infinite-scroll-select.component.html',
  styleUrls: ['./infinite-scroll-select-default.component.scss', './infinite-scroll-select-logistics.component.scss', './infinite-scroll-select-product.component.scss']
})
export class InfiniteScrollSelectComponent implements OnInit, OnDestroy {
  @Input() row: { [key: string]: any };
  @Input() column: InfiniteScrollColumn;
  @Input() rowForm: UntypedFormGroup;
  @Input() suggestions: Array<Suggestion>;
  @Input() emptyCellText: string = 'None';

  @HostBinding('class.logistics') isLogisticsStyle = false;
  @HostBinding('class.product') isProductStyle = false;
  @ViewChild('selectTest') selectTest: any;
  @Input() set style(style: InfiniteScrollStyle) {
    this.isLogisticsStyle = false;
    this.isProductStyle = false;
    if (style) {
      this._style = style;
      if (style === InfiniteScrollStyle.Logistics) {
        this.isLogisticsStyle = true;
      } else if (style === InfiniteScrollStyle.Product) {
        this.isProductStyle = true;
      }
    } else {
      this._style = InfiniteScrollStyle.Default;
    }
  }

  @HostListener('document:click')
  clickOutside() {
    if (!this.openClick) {
      this.open = false;
      this.cd.detectChanges();
    }
    this.openClick = false;
  }

  _style: InfiniteScrollStyle;
  fetchedSuggestionsByRowIndex: { [key: string]: Array<Suggestion> } = {};
  filledSuggestions: { [key: string]: Array<Suggestion> } = {};
  fetching = false;
  open = false;
  openClick = false

  rowFormChangesSub: Subscription;
  editableSub: Subscription;

  constructor(
    private orgApi: OrgApiService,
    private logisticsApi: LogisticsApiService,
    private store: Store,
    private appSvc: ApplicationService,
    private toast: ToastService,
    private cd: ChangeDetectorRef,
    private scrollSvc: InfiniteScrollService
  ) { }

  ngOnInit(): void {
    const propName = this.column.displayProperty ? this.column.displayProperty.split('.') : this.column.propertyName.split('.');
    this.rowFormChangesSub = this.rowForm.get(propName).statusChanges.subscribe(() => {
      this.cd.detectChanges();
    });
    this.editableSub = this.scrollSvc.editable$.subscribe(() => {
      this.cd.detectChanges();
    });

    this.cd.detach();
    //console.log('helloooooo=>', this.filledSuggestions)
  }

  ngOnDestroy(): void {
    this.rowFormChangesSub.unsubscribe();
    this.editableSub.unsubscribe();
  }

  resetValue(formField){
    this.rowForm.get(formField).patchValue('')
    this.rowForm.markAsDirty()
  }
  
  focus() {
    //console.log('hello  uu')
    this.selectTest.focus()

  }

  private interpolatePropertyString(str: string): any {
    return str.split('.').reduce((obj, propName) => obj && obj[propName], this.row);
  }

  fetchSuggestions(): void {
    if (
      this.column.suggestionQueryFilter &&
      this.column.suggestionQueryFilter[this.column.suggestion] &&
      !this.fetchedSuggestionsByRowIndex[this.column.propertyName]
    ) {
      this.fetching = true;
      this.openClick = true;
      this.open = true;
      this.cd.detectChanges();

      const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
      const { filterBy, filterJoin, filterMatch, filterValuePropertyName } = this.column.suggestionQueryFilter[this.column.suggestion];
      const request = this._style === InfiniteScrollStyle.Logistics ?
        this.logisticsApi.getLogisticSuggestions(
          [this.column.suggestion],
          organization.id,
          filterBy,
          this.appSvc.toUpperCaseArray(filterJoin),
          this.appSvc.toUpperCaseArray(filterMatch),
          filterValuePropertyName.map(fv => this.interpolatePropertyString(fv))
        ) :
        this.orgApi.getSuggestions(
          [this.column.suggestion],
          organization.id
        );

      request.pipe(
        tap(
          (suggestions: SuggestionList) => {
            this.fetchedSuggestionsByRowIndex[this.column.propertyName] = suggestions[this.column.suggestion];
            this.fetching = false;
            this.cd.detectChanges();
          }
        ),
        catchError(
          (error: HttpErrorResponse) => {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Load Suggestions',
              type: NotificationType.ERROR
            });
            this.fetchedSuggestionsByRowIndex[this.column.propertyName] = [];
            this.fetching = false;
            this.cd.detectChanges();
            return throwError(error);
          }
        )
      ).subscribe();
    } else {
      if (!this.open) {
        this.openClick = true;
        this.open = true;
        this.cd.detectChanges();
      }
    }
  }

  fillSuggestions(): void {
    if (!this.filledSuggestions[this.column.propertyName]) {
      this.openClick = true;
      this.open = true;
      if (this.suggestions[this.column.suggestion]) {
        this.filledSuggestions[this.column.propertyName] = [...this.suggestions[this.column.suggestion]];
      } else {
        this.filledSuggestions[this.column.propertyName] = [];
      }
      this.cd.detectChanges();
    } else {
      if (!this.open) {
        this.openClick = true;
        this.open = true;
        this.cd.detectChanges();
      }
    }
  }

  get selectedValue(): string {
    const propName = this.column.displayProperty ? this.column.displayProperty.split('.') : this.column.propertyName.split('.');
    return this.rowForm.get(propName)?.value;
  }

  setSuggestionValue(suggestion?: any): void {
    //console.log('hello=>>>',suggestion)
    if (this.column.suggestionAlternateProperty) {
      const altValue = suggestion ? suggestion[this.column.suggestionAlternateProperty.valueProperty] : '';
      const altControl = this.rowForm.get(this.column.suggestionAlternateProperty.propertyName.split('.')) as UntypedFormControl;
      if (altControl.value) { // If last alt value was a number or column alt is marked for parsing, parse the new value to a number
        altControl.setValue(typeof altControl.value === 'number' ? parseInt(altValue, null) : altValue);
      } else {
        altControl.setValue(this.column.suggestionAlternateProperty.parseInt ? parseInt(altValue, null) : altValue);
      }
    }
    const value = suggestion ? this.column.suggestionValueProperty ? suggestion[this.column.suggestionValueProperty] : suggestion.name : '';
    const control = this.rowForm.get(this.column.propertyName.split('.')) as UntypedFormControl;
    if (control.value) { // If last value was a number or column is marked for parsing, parse the new value to a number
      control.setValue(typeof control.value === 'number' ? parseInt(value, null) : value);
    } else {
      control.setValue(this.column.parseInt ? parseInt(value, null) : value);
    }
    control.markAsDirty();
  }
}
