/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { DataMapping } from '../model/models';
import { FilterMatch } from '../model/models';
import { OrderBy } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class MappingApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Add a Data Mapping to an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param dataMapping A new Data Mapping to add
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDataMapping(orgId: number, dataMapping: DataMapping, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DataMapping>;
    public addDataMapping(orgId: number, dataMapping: DataMapping, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DataMapping>>;
    public addDataMapping(orgId: number, dataMapping: DataMapping, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DataMapping>>;
    public addDataMapping(orgId: number, dataMapping: DataMapping, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling addDataMapping.');
        }
        if (dataMapping === null || dataMapping === undefined) {
            throw new Error('Required parameter dataMapping was null or undefined when calling addDataMapping.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DataMapping>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/mapping`,
            dataMapping,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download an exported list of Data Mappings
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterBy Property in data to filter by, e.g. &#x60;description&#x60; or &#x60;owner.firstName&#x60;
     * @param filterMatch Operator to match filterBy to filter value
     * @param filterValue Value to match with in the filterBy
     * @param orderBy Order to sort by, ascending or descending
     * @param sortBy Property to sort by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportDataMappings(orgId: number, filterBy?: Array<string>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<Blob>;
    public exportDataMappings(orgId: number, filterBy?: Array<string>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<HttpResponse<Blob>>;
    public exportDataMappings(orgId: number, filterBy?: Array<string>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<HttpEvent<Blob>>;
    public exportDataMappings(orgId: number, filterBy?: Array<string>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling exportDataMappings.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterBy) {
            filterBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterBy');
            })
        }
        if (filterMatch) {
            filterMatch.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterMatch');
            })
        }
        if (filterValue) {
            filterValue.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterValue');
            })
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy');
            })
        }
        if (sortBy) {
            sortBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sortBy');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/csv',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/mapping/export`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return a Data Mapping by ID for an Organization
     * @param dataMappingId A data Mapping ID number
     * @param orgId An Organization ID number used to indicate the current org context
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDataMappingById(dataMappingId: number, orgId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DataMapping>;
    public getDataMappingById(dataMappingId: number, orgId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DataMapping>>;
    public getDataMappingById(dataMappingId: number, orgId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DataMapping>>;
    public getDataMappingById(dataMappingId: number, orgId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (dataMappingId === null || dataMappingId === undefined) {
            throw new Error('Required parameter dataMappingId was null or undefined when calling getDataMappingById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getDataMappingById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DataMapping>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/mapping/${encodeURIComponent(String(dataMappingId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Data Mappings for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param limit Length of the page
     * @param offset Start of the page
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDataMappings(orgId: number, limit?: number, offset?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<DataMapping>>;
    public getDataMappings(orgId: number, limit?: number, offset?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<DataMapping>>>;
    public getDataMappings(orgId: number, limit?: number, offset?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<DataMapping>>>;
    public getDataMappings(orgId: number, limit?: number, offset?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getDataMappings.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<DataMapping>>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/mapping`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a Data Mapping by ID for an Organization
     * This endpoint will update a Data Mapping
     * @param dataMappingId A data Mapping ID number
     * @param orgId An Organization ID number used to indicate the current org context
     * @param dataMapping 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchDataMappingById(dataMappingId: number, orgId: number, dataMapping?: DataMapping, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DataMapping>;
    public patchDataMappingById(dataMappingId: number, orgId: number, dataMapping?: DataMapping, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DataMapping>>;
    public patchDataMappingById(dataMappingId: number, orgId: number, dataMapping?: DataMapping, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DataMapping>>;
    public patchDataMappingById(dataMappingId: number, orgId: number, dataMapping?: DataMapping, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (dataMappingId === null || dataMappingId === undefined) {
            throw new Error('Required parameter dataMappingId was null or undefined when calling patchDataMappingById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling patchDataMappingById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<DataMapping>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/mapping/${encodeURIComponent(String(dataMappingId))}`,
            dataMapping,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Overwrite a Data Mapping by ID for an Organization
     * This endpoint will replace a Data Mapping
     * @param dataMappingId A data Mapping ID number
     * @param orgId An Organization ID number used to indicate the current org context
     * @param dataMapping 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putDataMappingById(dataMappingId: number, orgId: number, dataMapping?: DataMapping, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DataMapping>;
    public putDataMappingById(dataMappingId: number, orgId: number, dataMapping?: DataMapping, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DataMapping>>;
    public putDataMappingById(dataMappingId: number, orgId: number, dataMapping?: DataMapping, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DataMapping>>;
    public putDataMappingById(dataMappingId: number, orgId: number, dataMapping?: DataMapping, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (dataMappingId === null || dataMappingId === undefined) {
            throw new Error('Required parameter dataMappingId was null or undefined when calling putDataMappingById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling putDataMappingById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<DataMapping>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/mapping/${encodeURIComponent(String(dataMappingId))}`,
            dataMapping,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a Data Mapping by ID from an Organization
     * This endpoint will remove a Data Mapping
     * @param dataMappingId A data Mapping ID number
     * @param orgId An Organization ID number used to indicate the current org context
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeDataMappingById(dataMappingId: number, orgId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public removeDataMappingById(dataMappingId: number, orgId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public removeDataMappingById(dataMappingId: number, orgId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public removeDataMappingById(dataMappingId: number, orgId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (dataMappingId === null || dataMappingId === undefined) {
            throw new Error('Required parameter dataMappingId was null or undefined when calling removeDataMappingById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling removeDataMappingById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/mapping/${encodeURIComponent(String(dataMappingId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
