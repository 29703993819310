<div class="chip" *ngIf="chip.value && chip.type" [ngClass]="{
  'literal': chip.type === ChipType.Literal,
  'variable': chip.type === ChipType.Variable,
  'missing': chip.type === ChipType.Missing
}" [ngStyle]="{ 'background-color': backgroundColor, 'color' : color }">
  <i class="param-name" *ngIf="chip.type === ChipType.Missing">{{ chip.value }}</i>
  <span class="param-name" *ngIf="chip.type !== ChipType.Missing"
    [ngClass]="{ 'no-right-margin': showDelete || chip.delete }">{{ chip.display ? chip.display : chip.value }}</span>
  <!-- Delete button -->
  <div class="delete-button" *ngIf="showDelete || chip.delete" (click)="removeSegment.emit(chip)">
    <i class="far fa-times"></i>
  </div>
</div>