/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PluginPropertyType = 'MULTI' | 'PASSWORD' | 'TEXT';

export const PluginPropertyType = {
    MULTI: 'MULTI' as PluginPropertyType,
    PASSWORD: 'PASSWORD' as PluginPropertyType,
    TEXT: 'TEXT' as PluginPropertyType
};

