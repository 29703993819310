/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationReference } from './organizationReference';


export interface ProductSeo { 
    aplusStatus?: string;
    availabilityDate?: string;
    availabilityDateFuture1?: string;
    availabilityDateFuture2?: string;
    badge?: string;
    benefits?: string;
    brandType?: string;
    bullet1?: string;
    bullet10?: string;
    bullet11?: string;
    bullet12?: string;
    bullet2?: string;
    bullet3?: string;
    bullet4?: string;
    bullet5?: string;
    bullet6?: string;
    bullet7?: string;
    bullet8?: string;
    bullet9?: string;
    buyBoxWin?: boolean;
    categorization?: string;
    categorizationItemType?: string;
    categorizationKeyWordType?: string;
    categorizationProductSubType?: string;
    categorizationProductType?: string;
    changedDate?: string;
    clearance?: number;
    destinationGroup?: string;
    destinationRef?: OrganizationReference;
    features?: string;
    isoCurrencyCode?: string;
    jsonData?: { [key: string]: any; };
    keywordRecommended?: string;
    keywordTargets?: string;
    lastSyndicationBy?: string;
    lastSyndicationDate?: string;
    launchDate?: string;
    listPrice?: number;
    listPriceFuture1?: number;
    listPriceFuture2?: number;
    listPriceFutureDate1?: string;
    listPriceFutureDate2?: string;
    listingEndDate?: string;
    listingStartDate?: string;
    map?: number;
    mapCompliant?: boolean;
    metaDescription?: string;
    metaTags?: string;
    metaTitle?: string;
    msrp?: number;
    offMapDate?: string;
    onSiteDate?: string;
    priceBand?: string;
    productAttributeName1?: string;
    productAttributeName2?: string;
    productAttributeName3?: string;
    productAttributeName4?: string;
    productAttributeName5?: string;
    productAttributeName6?: string;
    productAttributeValue1?: string;
    productAttributeValue2?: string;
    productAttributeValue3?: string;
    productAttributeValue4?: string;
    productAttributeValue5?: string;
    productAttributeValue6?: string;
    productDescription?: string;
    productId?: number;
    productName?: string;
    productSeoId?: number;
    productTitle?: string;
    promoType?: string;
    promotionalPrice?: number;
    seasonGroup?: string;
    sellerSku?: string;
    shortDescription?: string;
    siteDisplayPrice?: number;
    siteUrl?: string;
    sku?: string;
    subClass?: string;
    supplierCode?: string;
    supplierName?: string;
    tradingPartnerCollection?: string;
    tradingPartnerId?: string;
    tradingPartnerInventoryId?: string;
    tradingPartnerShelf?: string;
    tradingPartnerStatus?: string;
    tradingPartnerVariantId?: string;
    upc?: string;
    verificationDate?: string;
    wholesaleCost?: number;
    wholesaleCostOverride?: number;
}

