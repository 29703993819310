import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Filter, FilterJoin, FilterMatch, OrderBy } from '@app/core/api';
import { DeleteOrganizationFilterAction } from '@app/features/organization/store/organization.actions';
import { Store, Select } from '@ngxs/store';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { Observable } from 'rxjs';
import { QueryFilterService } from '@app/shared/services/query-filter.service';

@Component({
  selector: 'app-query-filter-views',
  templateUrl: './query-filter-views.component.html',
  styleUrls: ['./query-filter-views.component.scss'],
  providers:[QueryFilterService]
})
export class QueryFilterViewsComponent implements OnInit {
  @Select(OrganizationState.getFilters) savedFilters$: Observable<Array<Filter>>;

  savedFilterPendingDelete: Filter;

  showSavedViewsList = false;
  showDeleteViewModal = false;

  constructor(private router: Router, private route: ActivatedRoute, private store: Store, private queryFilterSvc: QueryFilterService ) { }

  ngOnInit(): void {
  }

  get activeSavedFilterId(): number {
    const { queryParams } = this.route.snapshot;
    if (!queryParams.filterId) {
      return -1;
    }
    return parseInt(queryParams.filterId, null);
  }

  savedFilterSelected(savedFilter: Filter) {
    const filterId = savedFilter.filterId
    if(filterId === this.activeSavedFilterId){
      return this.queryFilterSvc.resetFilters()
    }
    const filterBy: Array<string> = [];
    const filterJoin: Array<FilterJoin> = [];
    const filterMatch: Array<FilterMatch> = [];
    const filterValue: Array<string> = [];

    const sort = savedFilter?.sortEntries && savedFilter?.sortEntries[0] ? savedFilter?.sortEntries[0]?.sortBy : null;
    const order = savedFilter?.sortEntries && savedFilter?.sortEntries[0] ? savedFilter?.sortEntries[0]?.orderBy === OrderBy.DESC || savedFilter?.sortEntries[0]?.orderBy === OrderBy.DESC.toUpperCase() ? OrderBy.DESC : OrderBy.ASC : OrderBy.ASC;

    savedFilter.filterEntries.forEach(f => {
      filterBy.push(f.filterBy);
      filterJoin.push(FilterJoin[f.filterJoin]);
      filterMatch.push(FilterMatch[f.filterMatch]);
      filterValue.push(f.filterValue);
    });

    this.router.navigate([], {
      queryParams: {
        filterId: savedFilter.filterId,
        filterBy,
        filterJoin,
        filterMatch,
        filterValue,
        sort,
        order
      },
      queryParamsHandling: 'merge'
    });
  }

  promptDelete(savedFilterToDelete: Filter) {
    this.savedFilterPendingDelete = savedFilterToDelete;
    this.showDeleteViewModal = true;
  }

  deleteSavedFilter() {
    this.store.dispatch(new DeleteOrganizationFilterAction({ filter: this.savedFilterPendingDelete })).subscribe(() => {
    });
    this.showDeleteViewModal = false;
  }
}
