import { DecimalPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { FilterJoin, FilterMatch } from '@app/core';
import { MoreQueryFilter, MoreFilterConfiguration, QueryFilterService, SearchFilter } from '@app/shared/services/query-filter.service';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-query-filter-more',
  templateUrl: './query-filter-more.component.html',
  styleUrls: ['./query-filter-more.component.scss']
})
export class QueryFilterMoreComponent implements OnInit, OnDestroy {
  @Input() set moreFilters(filters: Array<MoreQueryFilter>) {
    if (!filters || filters.length === 0) {
      return;
    }
    this.filters = filters;
    filters.forEach(
      (more: MoreQueryFilter) => {
        if (more.filters && more.filters.length > 0) {
          return more.filters.map(
            (filter: MoreFilterConfiguration) => {
              const name = filter.name ? filter.name + more.propertyName : more.propertyName;
              this.form.addControl(name, new UntypedFormControl(''));
            }
          )
        }
      }
    );
  };

  form: UntypedFormGroup = new UntypedFormGroup({});
  filters: Array<MoreQueryFilter>

  routerEventsSub: Subscription;

  constructor(private router: Router, private queryFilterSvc: QueryFilterService, private decimalPipe: DecimalPipe) { }

  ngOnInit(): void {
    this.routerEventsSub = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => this.queryFilterSvc.filters)
    ).subscribe(
      (filters: Array<SearchFilter>) => {
        this.form.reset();
        filters.forEach(
          ({ by, match, value }: SearchFilter) => {
            this.setFormValue(by, match, value);
          }
        );
      }
    );

    this.queryFilterSvc.filters.forEach(
      ({ by, match, value }: SearchFilter) => {
        this.setFormValue(by, match, value);
      }
    );
  }

  ngOnDestroy(): void {
    this.routerEventsSub.unsubscribe();
  }

  private setFormValue(filterBy: string, filterMatch: FilterMatch, filterValue: any): void {
    const index = this.filters.findIndex(more => more.filters?.length > 0 && more.propertyName === filterBy && more.filters.findIndex(filter => filter.match === filterMatch) > -1);
    if (index > -1) {
      const filter = this.filters[index].filters.find(filter => filter.match === filterMatch);
      const propertyName = this.filters[index].propertyName;
      const name = filter?.name ? filter.name + propertyName : propertyName;
      const value = filter.percentage ? this.transformPercentageToForm(filterValue) : filterValue;
      if (!this.form.get(name).value) {// Only keep the first seen value
        this.form.patchValue({ [name]: value });
      }
    }
  }

  removeAllFilters(): Promise<boolean> {
    let filterIndexes: Array<number> = [];
    this.filters.forEach(
      (more: MoreQueryFilter) => {
        if (more.filters && more.filters.length > 0) {
          filterIndexes = [...filterIndexes, ...this.findMoreFilterIndexes(more.filters, more.propertyName)];
        }
      }
    );
    if (filterIndexes.length > 0) {
      return this.queryFilterSvc.removeFilters(filterIndexes);
    } else {
      return new Promise((resolve) => resolve(true))
    }
  }

  findMoreFilterIndexes(filters: Array<MoreFilterConfiguration>, propertyName: string): Array<number> {
    let indexes: Array<number> = [];
    indexes = [
      ...indexes,
      ...filters.reduce(
        (acc: Array<number>, filter: MoreFilterConfiguration) => {
          const index = this.queryFilterSvc.filters.findIndex(f => f.by === propertyName && f.match === filter.match);
          if (index > -1) {
            return [...acc, index];
          }
          return acc;
        },
        []
      )
    ];
    return indexes;
  }

  submitFilter(): void {
    const formValue = this.form.value;
    this.removeAllFilters().then(() => {
      let by: Array<string> = [];
      let join: Array<FilterJoin> = [];
      let match: Array<FilterMatch> = [];
      let value: Array<string> = [];
      this.filters.forEach(
        (more: MoreQueryFilter) => {
          if (more.filters && more.filters.length > 0) {
            more.filters.forEach(
              (filter: MoreFilterConfiguration) => {
                const name = filter?.name ? filter.name + more.propertyName : more.propertyName;

                if (formValue[name]) {
                  by = [...by, more.propertyName]
                  join = [...join, FilterJoin.AND]
                  match = [...match, filter.match]
                  value = [...value, filter.percentage ? this.transformPercentageToFilter(formValue[name]) : formValue[name]]
                }
              }
            );
          }
        }
      );

      if (by.length > 0) {
        this.queryFilterSvc.addFilters({ by, join, match, value });
      }
    });
  }

  filterIsActive(moreFilter: MoreQueryFilter): boolean {
    return this.findMoreFilterIndexes(moreFilter.filters, moreFilter.propertyName).length > 0;
  }

  clearFilter(moreFilter: MoreQueryFilter): void {
    const indexes = this.findMoreFilterIndexes(moreFilter.filters, moreFilter.propertyName);
    if (indexes.length > 0) {
      this.queryFilterSvc.removeFilters(indexes);
    }
  }

  private transformPercentageToForm(value: string): string {
    return this.decimalPipe.transform(parseFloat(value) * 100, '2.0-2');
  }

  private transformPercentageToFilter(value: number): string {
    return (value * .01).toFixed(2).toString();
  }
}
