/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DrayCarrierEquipment } from './drayCarrierEquipment';


export interface DrayCarrierEventDetails { 
    comments?: string;
    damage?: string;
    equipment?: DrayCarrierEquipment;
    eventAction?: string;
    eventType?: string;
    latitude?: string;
    locationCode?: string;
    locationCodeQualifier?: string;
    longitude?: string;
    reportedBy?: string;
    reportedDate?: string;
    reportedPieceCount?: string;
    signedBy?: string;
}

