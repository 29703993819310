import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PluginConfigProperty } from '@app/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

export type Syndic8FormlyTypeMap = 'TEXT' | 'PASSWORD';

export const Syndic8FormlyTypeMap = {
  TEXT: 'input' as Syndic8FormlyTypeMap,
  PASSWORD: 'password' as Syndic8FormlyTypeMap
};

@Component({
  selector: 'app-panel-form',
  templateUrl: './panel-form.component.html',
  styleUrls: ['./panel-form.component.scss']
})
export class PanelFormComponent implements OnInit {
  @Input() property: PluginConfigProperty;
  @Input() form: UntypedFormGroup;
  @Output() save: EventEmitter<void> = new EventEmitter();

  options: FormlyFormOptions = {};
  fields: Array<FormlyFieldConfig> = [];
  model: any = {};

  constructor() { }

  ngOnInit(): void {
    const { name, value, type, helpText, description, options } = this.property;
    this.model = { [name]: value };
    this.fields = [{
      key: this.property.name,
      type: options && options.length > 0 ? 'select' : 'input',
      templateOptions: {
        // label: displayName,
        type: Syndic8FormlyTypeMap[type],
        placeholder: options && options.length > 0 ? '' : helpText,
        description,
        options: options.map(opt => {
          return { label: opt.displayName, value: opt.name };
        })
      },
    }];
  }

  get propertyValue(): string {
    if (this.property.value) {
      if (Syndic8FormlyTypeMap[this.property.type] === Syndic8FormlyTypeMap.PASSWORD) {
        return '*****';
      }
      return this.property.value;
    }
    return '';
  }
}
