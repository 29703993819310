import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  OnDestroy,
  HostBinding,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ElementRef,
  ViewChild
} from '@angular/core';
import {
  QueryFilters,
  InputType,
  InfiniteScrollColumn,
  InfiniteScrollColumnType,
  InfiniteScrollImage,
  InfiniteScrollTitle,
  InfiniteScrollAction,
  InfiniteScrollChip,
  MasterCheckbox,
  InfiniteScrollService,
  InfiniteScrollColumnSuggestionProperty,
  InfiniteScrollStyle,
  InfiniteScrollFilterCheckbox
} from '../../../services/infinite-scroll.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { OrderBy, FilterJoin, FilterMatch, Suggestion } from '@app/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { filter, first, switchMap } from 'rxjs/operators';
import { interval, of, Subscription } from 'rxjs';
import { AOrAnPipe } from '@app/shared/pipes/aOrAn.pipe';
import * as moment from 'moment';

@Component({
  selector: 'app-infinite-scroll-header',
  templateUrl: './infinite-scroll-header.component.html',
  styleUrls: ['./infinite-scroll-header-default.component.scss', './infinite-scroll-header-logistics.component.scss', './infinite-scroll-header-product.component.scss', './infinite-scroll-header-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfiniteScrollHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() set columns(columns: Array<InfiniteScrollColumn>) {
    this._columns = columns;
    this.updateColumnsFilterForm();
    this.updateShowColumnFill();
  }

  @Input() showSelection = false;
  @Input() showNewRow = false;
  @Input() showMasterCheckbox = false;
  @Input() chips: Array<InfiniteScrollChip>;
  @Input() chipWidth: number;
  @Input() actions: Array<Array<InfiniteScrollAction>>;
  @Input() processings: Array<boolean>;
  @Input() showProcessing: boolean;
  @Input() images: Array<InfiniteScrollImage>;
  @Input() titleWidth = 200;
  @Input() showEllipsis = false;
  @Input() showDownloadButton = false;
  @Input() loading: boolean;

  @Input() set suggestions(suggestions: { [key: string]: Array<Suggestion> }) {
    this._suggestions = suggestions;
    this.updateColumnsFilterForm();
  }

  @Input() showFilter = false;
  @Input() showSort = false;
  @Input() showHandles = false;

  @Input() hasFilters = false;
  @Output() hasFiltersChange: EventEmitter<boolean> = new EventEmitter(true);

  @Input() minWidths: Array<number> = [];
  @Output() minWidthsChange: EventEmitter<Array<number>> = new EventEmitter(true);

  @HostBinding('class.title') @Input() titles: Array<InfiniteScrollTitle>;

  @HostBinding('class.logistics') isLogisticsStyle = false;
  @HostBinding('class.product') isProductStyle = false;

  @Input() set style(style: InfiniteScrollStyle) {
    this.isLogisticsStyle = false;
    this.isProductStyle = false;
    if (style) {
      this._style = style;
      if (style === InfiniteScrollStyle.Logistics) {
        this.isLogisticsStyle = true;
      } else if (style === InfiniteScrollStyle.Product) {
        this.isProductStyle = true;
      }
    } else {
      this._style = InfiniteScrollStyle.Default;
    }
  }

  private routerEventsSub: Subscription;
  private masterCheckSub: Subscription;
  private rowsSub: Subscription;

  private lastWindowWidth = window.innerWidth;

  _style: InfiniteScrollStyle;
  _columns: Array<InfiniteScrollColumn>;
  _suggestions: { [key: string]: Array<Suggestion> };
  columnsFilterForm: UntypedFormGroup = new UntypedFormGroup({});
  showFilterMenu: InfiniteScrollColumn = null;
  showFilterMenuClick = false;
  _rows: Array<{ [key: string]: any }> = [];
  _masterCheck: MasterCheckbox = { checked: false, directly: false };
  masterCheckbox$ = this.scrollSvc.masterCheckbox$;
  mouseDownColumnIndex: number = null;
  columnMinWidths: Array<number> = [];

  InfiniteScrollColumnType = InfiniteScrollColumnType;
  InfiniteScrollSuggProp = InfiniteScrollColumnSuggestionProperty;

  @HostListener('document:click')
  clickOutside() {
    if (!this.showFilterMenuClick) {
      this.showFilterMenu = null;
    }
    this.showFilterMenuClick = false;

    // Reset cursor and mouseDownColumnIndex
    document.body.style.cursor = 'auto';
    this.mouseDownColumnIndex = null;
  }
  @HostListener('window:resize', ['$event.target'])
  onResize(window: Window) {
    if (this.lastWindowWidth !== window.innerWidth) {
      this.updateMinWidths();
    }
    if (window.innerWidth > this.lastWindowWidth) {
      this.updateShowColumnFill();
    }
    this.lastWindowWidth = window.innerWidth;
  }
  @HostListener('document:mousemove', ['$event'])
  mouseMove(event: MouseEvent) {
    if (this.mouseDownColumnIndex === null) {
      return;
    }

    document.body.style.cursor = 'col-resize';
    const columnWidths = this.columnChildren.toArray().map(ref => ref.nativeElement.clientWidth);
    this.minWidths = columnWidths.map(
      (width, i) => {
        if (i !== this.mouseDownColumnIndex || this._columns[i]?.width > width + event.movementX) {
          return width;
        }
        return width + event.movementX;
      }
    );
    this.columnMinWidths = [...this.minWidths];
    this.minWidthsChange.emit(this.minWidths);

    // If the widths of all columns is less than the width of the columns wrapper, show the column filler
    if (columnWidths.reduce((a, b) => a + b, 0) < this.columnsRef.nativeElement.clientWidth) {
      this.scrollSvc.showColumnFill = true;
    }
    this.cd.markForCheck();
  }

  mouseDown(event: MouseEvent, columnIndex: number): void {
    if (event.button !== 0) {
      return;
    }
    event.preventDefault();
    this.mouseDownColumnIndex = columnIndex;
  }

  @ViewChildren('columnRef') columnChildren!: QueryList<ElementRef<any>>;
  @ViewChild('columnsRef') columnsRef!: ElementRef<any>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private scrollSvc: InfiniteScrollService,
    private cd: ChangeDetectorRef,
    private aOrAnPipe: AOrAnPipe
  ) { }

  ngOnInit(): void {
    this.routerEventsSub = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.checkHasFilters();
      this.updateColumnsFilterForm();
    });
    this.masterCheckSub = this.scrollSvc.masterCheckbox$.subscribe(m => this._masterCheck = m);
    this.rowsSub = this.scrollSvc.rows$.subscribe(r => {
      this._rows = r;
      this.cd.markForCheck();
    });

    this.checkHasFilters();
  }

  ngAfterViewInit(): void {
    this.updateShowColumnFill();

    interval(100).pipe(
      switchMap(() => of(this.columnChildren)),
      filter(columnChildren => columnChildren && columnChildren.length > 0),
      first()
    ).subscribe(() => this.updateMinWidths());
  }

  ngOnDestroy(): void {
    this.routerEventsSub.unsubscribe();
    this.masterCheckSub.unsubscribe();
    this.rowsSub.unsubscribe();
  }

  get showColumnFill(): boolean {
    return this.scrollSvc.showColumnFill;
  }

  private updateMinWidths(): void {
    if (!this._columns) {
      return;
    }

    // Auto update minWidths if at least one column header has a `fill: true`
    if (this._columns.findIndex(c => c.fill === true) > -1) {
      this.minWidthsChange.emit(this.columnChildren.toArray().map(ref => ref.nativeElement.clientWidth));
    }
  }

  trackByFn(index: number, column: InfiniteScrollColumn): any {
    if (column) {
      return column.propertyName;
    }
    return column;
  }

  checkMasterCheckbox(): void {
    if (this._rows && this._rows.length > 0) {
      this.scrollSvc.masterCheckbox.call(this.scrollSvc, { checked: !this._masterCheck.checked, directly: true });
    }
  }

  private checkHasFilters(): void {
    const { queryParams } = this.route.snapshot;
    this.hasFiltersChange.emit(queryParams.filterJoin || queryParams.filterBy || queryParams.filterMatch || queryParams.filterValue);
  }

  private getQueryFilterValue(column: InfiniteScrollColumn, fromIndex: number = 0, checkbox?: InfiniteScrollFilterCheckbox): any {
    const filterByFromIndex = [...this.currentQueryFilters.by.slice(fromIndex)];
    const filterByIndex = filterByFromIndex.findIndex(f => f === column.propertyName) + fromIndex;
    if (filterByIndex > -1) {
      if (checkbox) {
        if (this.currentQueryFilters.match[filterByIndex] === checkbox.filter.match && this.currentQueryFilters.value[filterByIndex] === checkbox.filter.value) {
          return this.currentQueryFilters.value[filterByIndex];
        } else {
          return '';
        }
      } else if (column.multiFilter) {
        let value = '';
        this.currentQueryFilters.by.forEach((by, i) => by === column.propertyName && this.currentQueryFilters.value[i] ? value += `${this.currentQueryFilters.value[i]}${i < this.currentQueryFilters.by.length - 1 ? ' ' : ''}` : null);
        return value;
      } else if (column.type === InfiniteScrollColumnType.Boolean) {
        if (this.currentQueryFilters.value[filterByIndex] === 'true') {
          return true;
        } else if (this.currentQueryFilters.value[filterByIndex] === 'false') {
          return false;
        } else if (this.currentQueryFilters.value[filterByIndex] === 'null') {
          return 'null';
        }
      } else if (
        column.type === InfiniteScrollColumnType.Suggestion &&
        column.suggestionValueProperty === InfiniteScrollColumnSuggestionProperty.Id
      ) {
        const value = this.currentQueryFilters.value[filterByIndex];
        if (this._suggestions && this._suggestions[column.suggestion]) {
          const suggestion = this._suggestions[column.suggestion].find(s => s.id === value);
          if (suggestion) {
            return column.suggestionValueProperty === InfiniteScrollColumnSuggestionProperty.Id ? suggestion.id : suggestion.name;
          }
        }
        return value;

      } else if (column.type === InfiniteScrollColumnType.Date || column.type === InfiniteScrollColumnType.Datetime) {
        return this.currentQueryFilters.value
      }
       else {
        return this.currentQueryFilters.value[filterByIndex];
      }
    }
    return '';
  }


  private updateColumnsFilterForm(): void {
    if (!this._columns) {
      return;
    }
    this._columns.forEach((column, index) => {
      if (column.type === InfiniteScrollColumnType.Date || column.type === InfiniteScrollColumnType.Datetime) {
        const startDateControl = this.columnsFilterForm.get([`${column.propertyName}Start`]);
        if (startDateControl) {
          let startDateValue = this.getQueryFilterValue(column,index)[0];
           startDateValue = parseInt(startDateValue, 10);
          console.log("startDateValue", startDateValue)
          startDateControl.setValue(startDateValue === 'null' ? '' : startDateValue);
          console.log("startDateValue", startDateValue)
        } else {
          this.columnsFilterForm.addControl(`${column.propertyName}Start`, new UntypedFormControl('', [Validators.required]));
        }

        const endDateControl = this.columnsFilterForm.get([`${column.propertyName}End`]);
        if (endDateControl) {
          let endDateValue = this.getQueryFilterValue(column, index)[1];
          endDateValue = parseInt(endDateValue, 10);
          console.log("endDateValue", endDateValue)
          endDateControl.setValue(endDateValue === 'null' ? '' : endDateValue);
          console.log("endDateValue", endDateValue)
        } else {
          this.columnsFilterForm.addControl(`${column.propertyName}End`, new UntypedFormControl('', [Validators.required]));
        }

        const nullDateControl = this.columnsFilterForm.get([`${column.propertyName}Null`]);
        if (nullDateControl) {
          nullDateControl.setValue(this.getQueryFilterValue(column) === 'null' ? true : false);
        } else {
          this.columnsFilterForm.addControl(`${column.propertyName}Null`, new UntypedFormControl(false));
        }
      }
      else if (column.type === InfiniteScrollColumnType.MultiSuggestion) {
        let selections: Array<string | boolean> = [];
        this.currentQueryFilters.by.forEach((fb: string, index: number) => {
          if (fb === column.propertyName) {
            selections = [...selections, this.getQueryFilterValue(column, index)];
          }
        });
        const control = this.columnsFilterForm.get([column.propertyName]);
        if (control) {
          control.setValue(selections.join(', '));
        } else {
          this.columnsFilterForm.addControl(
            column.propertyName,
            new UntypedFormControl(selections.join(', '))
          );
        }
      } else {
        const control = this.columnsFilterForm.get([column.propertyName]);
        if (control) {
          control.setValue(this.getQueryFilterValue(column));
        } else {
          const validators: Validators = column.type === InfiniteScrollColumnType.Boolean ? [] : [Validators.required];
          this.columnsFilterForm.addControl(
            column.propertyName,
            new UntypedFormControl(this.getQueryFilterValue(column), validators)
          );
        }
      }

      if (column.checkboxes && column.checkboxes?.length > 0) {
        column.checkboxes.forEach(checkbox => {
          const checkboxValue = this.getQueryFilterValue(column, 0, checkbox);
          this.columnsFilterForm.addControl(`${column.propertyName}${checkbox.title}`, new UntypedFormControl(checkboxValue ? true : false));
        })
      }
    });
  }

  private updateShowColumnFill(): void {
    if (!this._columns || !this.columnsRef) {
      return;
    }
    const totalWidth = this._columns.reduce(
      (acc, c) => {
        if (c.width) {
          return acc + c.width;
        }
        return acc;
      },
      0
    );
    const anyColumnFill = this._columns.reduce(
      (acc, c) => {
        if (c.fill === true) {
          return true;
        }
        return acc;
      },
      false
    );
    if (totalWidth > 0 && totalWidth < this.columnsRef.nativeElement.clientWidth && !anyColumnFill) {
      this.scrollSvc.showColumnFill = true;
    } else {
      this.scrollSvc.showColumnFill = false;
    }
  }

  private get currentQueryFilters(): QueryFilters {
    const { queryParams } = this.route.snapshot;
    return {
      by: queryParams.filterBy ? Array.isArray(queryParams.filterBy) ? queryParams.filterBy : [queryParams.filterBy] : [],
      join: queryParams.filterJoin ? Array.isArray(queryParams.filterJoin) ? queryParams.filterJoin : [queryParams.filterJoin] : [],
      match: queryParams.filterMatch ? Array.isArray(queryParams.filterMatch) ? queryParams.filterMatch : [queryParams.filterMatch] : [],
      value: queryParams.filterValue ? Array.isArray(queryParams.filterValue) ? queryParams.filterValue : [queryParams.filterValue] : [],
    };
  }

  sortColumn(column: InfiniteScrollColumn): void {
    if (!column || !this.showSort) {
      return;
    }
    const { queryParams } = this.route.snapshot;
    let order: OrderBy = OrderBy.ASC;
    if (queryParams.order === OrderBy.ASC) {
      order = OrderBy.DESC;
    } else if (queryParams.order === OrderBy.DESC) {
      order = OrderBy.ASC;
    }

    // Optional override for sort property - Needed for some suggestion/generated cells
    const sortPropertyName = column.sortPropertyName || column.propertyName;
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: { sort: sortPropertyName, order }
    });
  }

  hasActiveSort(column: InfiniteScrollColumn): boolean {
    if (!column) {
      return false;
    }
    const { queryParams } = this.route.snapshot;
    return queryParams.sort === column.propertyName;
  }

  hasActiveFilter(column: InfiniteScrollColumn): boolean {
    if (!column) {
      return false;
    }
    return this.currentQueryFilters.by.findIndex(f => f === column.propertyName) > -1;
  }

  getFilterInputType(column: InfiniteScrollColumn): InputType {
    switch (column.type) {
      case InfiniteScrollColumnType.String:
        return InputType.Text;
      case InfiniteScrollColumnType.Number:
        return InputType.Number;
      case InfiniteScrollColumnType.Date:
        return InputType.Date;
      case InfiniteScrollColumnType.Datetime:
        return InputType.Datetime;
      case InfiniteScrollColumnType.Boolean:
        return InputType.Checkbox;
    }
  }

  addFilter(column: InfiniteScrollColumn, chosen?: Array<Suggestion>): void {
    const control = this.columnsFilterForm.get([column.propertyName]);
    if (column.type === InfiniteScrollColumnType.Date || column.type === InfiniteScrollColumnType.Datetime) {
      if (
        this.columnsFilterForm.get([`${column.propertyName}Start`])?.value !== '' ||
          this.columnsFilterForm.get([`${column.propertyName}End`])?.value !== '' ?
          this.columnsFilterForm.get([`${column.propertyName}Start`])?.invalid ||
          this.columnsFilterForm.get([`${column.propertyName}End`])?.invalid :
          this.columnsFilterForm.get([`${column.propertyName}Null`])?.invalid
      ) {
        return;
      }
    } else if (control?.invalid && !column.checkboxes || column.checkboxes?.length === 0) {
      return;
    }

    let filterValue: Array<string | number | boolean> = [];
    let filterBy: Array<string> = [];
    let filterJoin: Array<FilterJoin> = [];
    let filterMatch: Array<FilterMatch> = [];
    if (column.type === InfiniteScrollColumnType.Date || column.type === InfiniteScrollColumnType.Datetime) {
      const nullDateControl = this.columnsFilterForm.get([`${column.propertyName}Null`]);
      const startDateControl = this.columnsFilterForm.get([`${column.propertyName}Start`]);
      const endDateControl = this.columnsFilterForm.get([`${column.propertyName}End`]);
      if (nullDateControl.value === true) {
        filterValue = ['null'];
        filterBy = [column.propertyName];
        filterJoin = [FilterJoin.AND];
        filterMatch = [FilterMatch.IS];
      } else if (startDateControl.value === '' && endDateControl.value === '') {
        this.removeFilter(column);
        return;
      } else {
        if (startDateControl.invalid && endDateControl.invalid) {
          return;
        }
        filterValue = [
          startDateControl.value !== '' ? startDateControl.value : 'null',
          endDateControl.value !== '' ? endDateControl.value : 'null'
        ];
        filterBy = [column.propertyName, column.propertyName];
        filterJoin = [FilterJoin.AND, FilterJoin.AND];
        filterMatch = [FilterMatch.ISAFTER, FilterMatch.ISBEFORE];
      }
    } else {
      if (column.checkboxes && column.checkboxes?.length > 0) {
        column.checkboxes.forEach(checkbox => {
          const checkboxControl = this.columnsFilterForm.get([`${column.propertyName}${checkbox.title}`]);
          if (checkboxControl.value === true) {
            filterValue = [...filterValue, checkbox.filter.value];
            filterBy = [...filterBy, column.propertyName];
            filterJoin = [...filterJoin, FilterJoin.AND];
            filterMatch = [...filterMatch, checkbox.filter.match];
          }
        });
      }
      if (column.type === InfiniteScrollColumnType.MultiSuggestion && chosen) {
        chosen.forEach(
          (value: Suggestion, index: number) => {
            filterBy = [...filterBy, column.propertyName];
            filterJoin = [...filterJoin, index === 0 ? FilterJoin.AND : FilterJoin.OR];
            filterMatch = [...filterMatch, column.multiSelectionFilterMatch ? column.multiSelectionFilterMatch : FilterMatch.IS];
            filterValue = [...filterValue, column.suggestionValueProperty ? value[column.suggestionValueProperty] : value.name];
          }
        );
      }
      if (column.multiFilter) {
        // Separate values by spaces/newlines, then remove special characters
        // filterValue = control.value.match(/\S+/g).map(
        //   (str: string) => {
        //     return str
        //       .replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '')
        //       .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        //   }
        // );

        // IN-LIST SEARCH - Replace spaces with commas
        // filterValue = [control.value.toString().split(/[ ,]+/).filter(function (v) { return v !== ''; }).join(',')];

        // IN-LIST SEARCH: Separate container numbers by spaces, then remove special characters
        filterValue = [control.value.toString().replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '')
          .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')];
        if (filterValue.length === 0) {
          return;
        }

        if (column.checkboxes && column.checkboxes?.length > 0) {
          const isCheckboxFilter = column.checkboxes.find(c =>
            c.filter?.value?.toLowerCase() === filterValue[0].toString().toLowerCase()
          );
          if (!isCheckboxFilter) {
            filterMatch = filterValue.map(() => FilterMatch.IS);
          }
        }
      } else if (filterValue.length === 0) {
        filterValue = [control.value !== '' ? control.value : 'null'];
      }
      if (filterBy.length === 0) {
        filterBy = filterValue.map(() => column.propertyName);
      }
      if (filterJoin.length === 0) {
        filterJoin = filterValue.map((f, i) => i === 0 ? FilterJoin.AND : FilterJoin.OR);
      }
      if (column.type === InfiniteScrollColumnType.Boolean) {
        filterMatch = filterValue.map(() => FilterMatch.IS);
      } else if (column.multiFilter) {
        filterMatch = filterValue.map(() => FilterMatch.INLIST);
      } else {
        if (column.checkboxes && column.checkboxes.length > 0) {
          // check if the applied filter is one of the checkboxes
          const isCheckboxFilter = column.checkboxes.find(c =>
            c.filter?.value?.toLowerCase() === filterValue[0].toString().toLowerCase()
          );

          if (!isCheckboxFilter) {
            filterMatch = filterValue.map(() => FilterMatch.IS);
          } else if (column.removeFilterOnCheckboxUncheck) {
            const filterBy = this.currentQueryFilters.by.filter(f => f === column.propertyName)[0];
            if (filterBy) {
              this.removeQueryFilters(column);
              const filterBys = this.currentQueryFilters.by.filter(f => f === column.propertyName);
              const { by, join, match, value } = filterBys.length > 0 ? this.removeQueryFilters(column) : this.currentQueryFilters;
              this.showFilterMenu = null;

              this.router.navigate(['.'], {
                relativeTo: this.route,
                queryParamsHandling: 'merge',
                queryParams: {
                  filterBy: [...by],
                  filterJoin: [...join],
                  filterMatch: [...match],
                  filterValue: [...value],
                }
              });
              return
            }
          }
        } else if (filterMatch.length === 0) {
          filterMatch = filterValue.map(() => FilterMatch.CONTAINS);
        }
      }
    }

    // If any filterBys already exist, remove them before adding new ones
    const filterBys = this.currentQueryFilters.by.filter(f => f === column.propertyName);
    const { by, join, match, value } = filterBys.length > 0 ? this.removeQueryFilters(column) : this.currentQueryFilters;
    this.showFilterMenu = null;

    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: {
        filterBy: [...by, ...filterBy],
        filterJoin: [...join, ...filterJoin],
        filterMatch: [...match, ...filterMatch],
        filterValue: [...value, ...filterValue],
      }
    });
  }

  private removeQueryFilters(column: InfiniteScrollColumn): QueryFilters {
    let { by, join, match, value } = this.currentQueryFilters;
    while (by.findIndex(f => f === column.propertyName) > -1) {
      const index = by.findIndex(f => f === column.propertyName);
      by = [...by.slice(0, index), ...by.slice(index + 1)];
      join = [...join.slice(0, index), ...join.slice(index + 1)].map((j, i) => i === 0 ? FilterJoin.AND : j);
      match = [...match.slice(0, index), ...match.slice(index + 1)];
      value = [...value.slice(0, index), ...value.slice(index + 1)];
    }
    return { by, join, match, value };
  }

  private removeQueryFilter(index: number): QueryFilters {
    let { by, join, match, value } = this.currentQueryFilters;
    by = [...by.slice(0, index), ...by.slice(index + 1)];
    join = [...join.slice(0, index), ...join.slice(index + 1)].map((j, i) => i === 0 ? FilterJoin.AND : j);
    match = [...match.slice(0, index), ...match.slice(index + 1)];
    value = [...value.slice(0, index), ...value.slice(index + 1)];
    return { by, join, match, value };
  }

  removeFilter(column: InfiniteScrollColumn, filterValue?: number | string): void {
    if (!this.hasActiveFilter(column)) {
      if (column.type === InfiniteScrollColumnType.Date || column.type === InfiniteScrollColumnType.Datetime) {
        this.columnsFilterForm.get([`${column.propertyName}Start`]).setValue('');
        this.columnsFilterForm.get([`${column.propertyName}End`]).setValue('');
        this.columnsFilterForm.get([`${column.propertyName}Null`]).setValue(false);
      } else {
        this.columnsFilterForm.get([column.propertyName]).setValue('');
      }
      return;
    } else {
      if (column.checkboxes && column.checkboxes.length > 0) {
        column.checkboxes.forEach(checkbox => {
          this.columnsFilterForm.get([`${column.propertyName}${checkbox.title}`]).setValue(false);
        });
      }
    }
    if (column.type === InfiniteScrollColumnType.MultiSuggestion && filterValue) {
      const index = this.currentQueryFilters.value.findIndex(v => v == filterValue);
      const { by, join, match, value } = this.removeQueryFilter(index);
      this.showFilterMenu = null;
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParamsHandling: 'merge',
        queryParams: {
          filterBy: by.length > 0 ? by : null,
          filterJoin: join.length > 0 ? join : null,
          filterMatch: match.length > 0 ? match : null,
          filterValue: value.length > 0 ? value : null
        }
      });
    } else {
      const { by, join, match, value } = this.removeQueryFilters(column);
      this.showFilterMenu = null;
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParamsHandling: 'merge',
        queryParams: {
          filterBy: by.length > 0 ? by : null,
          filterJoin: join.length > 0 ? join : null,
          filterMatch: match.length > 0 ? match : null,
          filterValue: value.length > 0 ? value : null
        }
      });
    }
  }

  setCheckboxFilter(column: InfiniteScrollColumn, value: boolean): void {
    const control = this.columnsFilterForm.get([column.propertyName]);
    if (control.value === value || value === undefined && control.value === null) {
      return;
    }
    if (value === true) {
      this.columnsFilterForm.get([column.propertyName]).setValue(true);
    } else if (value === false) {
      this.columnsFilterForm.get([column.propertyName]).setValue(false);
    } else {
      this.columnsFilterForm.get([column.propertyName]).setValue('');
    }
    this.addFilter(column);
  }

  checkboxFilterSelected(column: InfiniteScrollColumn, checkbox: InfiniteScrollFilterCheckbox) {
    column?.checkboxes.forEach(c => {
      if (c.title !== checkbox.title) {
        this.columnsFilterForm.get([`${column.propertyName}${c.title}`]).setValue(false);
      }
    });
  }

  isAnyCheckboxSelected(column: InfiniteScrollColumn): boolean {
    let anySelected = false;
    if (column.checkboxes && column.checkboxes.length > 0) {
      column?.checkboxes.forEach(c => {
        const checked = this.columnsFilterForm.get([`${column.propertyName}${c.title}`]).value;
        if (checked) {
          anySelected = true;
        }
      });
    }
    return anySelected;
  }

  getSuggestionLabel(displayName: string): string {
    return `Select ${this.aOrAnPipe.transform(displayName)} ${displayName}`;
  }
}
