/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type GroupBy = 'CUSTOM' | 'DESTINATION' | 'NAME' | 'SKU' | 'UPC';

export const GroupBy = {
    CUSTOM: 'CUSTOM' as GroupBy,
    DESTINATION: 'DESTINATION' as GroupBy,
    NAME: 'NAME' as GroupBy,
    SKU: 'SKU' as GroupBy,
    UPC: 'UPC' as GroupBy
};

