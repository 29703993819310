/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface APlus { 
    aPlusContentId?: number;
    active?: boolean;
    comment?: string;
    companyDestinatonId?: number;
    companyId?: number;
    contentModel?: string;
    contentName?: string;
    contentUrl?: string;
    internalChangedDate?: string;
    internalCreatedDate?: string;
    jsonStr?: string;
    jsonStrPreview?: string;
    status?: string;
    tags?: string;
}

