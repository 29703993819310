<div class="box-wrap">
  <div class="box">
    <div class="message">Please us one of our preferred browsers:</div>
    <div class="browsers">
      <div class="chrome">
        <app-circle-button label="Google Chrome" color="green" icon="chrome" [solid]="true" href="https://www.google.com/chrome"></app-circle-button>
      </div>
      <div class="firefox">
        <app-circle-button label="Mozilla Firefox" color="amber" icon="firefox-browser" [solid]="true" href="https://www.mozilla.org/en-US/firefox/new"></app-circle-button>
      </div>
      <div class="edge">
        <app-circle-button label="Microsoft Edge" color="blue" icon="edge" [solid]="true" href="https://www.microsoft.com/en-us/windows/microsoft-edge"></app-circle-button>
      </div>
    </div>
  </div>
</div>
<div class="do-it-anyway" (click)=makeItSo()></div>
