/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserReference } from './userReference';


export interface WorkflowStatus { 
    approved?: boolean;
    changedBy?: UserReference;
    changedTime?: string;
    endTime?: string;
    jobId?: string;
    manualApproval?: boolean;
    manualApprovalAccess?: boolean;
    name?: string;
    products?: Array<string>;
    reasonCodeId?: number;
    rulePassed?: boolean;
    startTime?: string;
    status?: string;
    stepNumber?: number;
}

