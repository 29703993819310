import { State, Action, Selector, StateContext, Actions, ofActionDispatched, Store, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';

import {
  LoadOrganizationAction,
  ResetOrganizationAction,
  LoadOrganizationFiltersAction,
  AddOrganizationFilterAction,
  DeleteOrganizationFilterAction, ToggleOrganizationEditModeAction
} from './organization.actions';

import {
  OrgApiService,
  Organization,
  Permission,
  Filter,
  FilterApiService,
  Navigation,
  NotificationType,
  User
} from '@app/core/api';
import { AuthState, LogoutUserAction, ReloadUserAction, ToastService } from '@app/core';

import { tap, catchError } from 'rxjs/operators';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { RouterStateSnapshot } from '@angular/router';
import { of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { patch, append, removeItem } from '@ngxs/store/operators';
import { ImportFlowStateModel } from '@app/features/product-import/modules/import-flow/stores/import-flow.state';

export interface OrganizationStateModel {
  organization: Organization;
  filters: Filter[];
  editMode: boolean;
}

const defaults = {
  organization: null,
  filters: [],
  editMode: false
};

@State<OrganizationStateModel>({
  name: 'organization',
  defaults
})
@Injectable()
export class OrganizationState {
  constructor(
    private store: Store,
    private actions: Actions,
    private orgApi: OrgApiService,
    private filterApi: FilterApiService,
    private toast: ToastService,
  ) {
    // Reset Organization state on User Logout
    this.actions.pipe(ofActionDispatched(LogoutUserAction)).subscribe(
      () => {
        this.store.dispatch(new ResetOrganizationAction());
      }
    );


  }

  @Selector()
  static getOrganization( state: OrganizationStateModel): Organization {
    console.log('navigation object=> ', state.organization)
    return state.organization;
  }

  @Selector()
  static getUpperNavigation(state: OrganizationStateModel): Array<Navigation> {
    if (state.organization?.upperNavigation) {
      return state.organization.upperNavigation;
    }
    return [];
  }

  @Selector([AuthState.getTokenMsLevel, AuthState.getUser])
  static getLowerNavigation(state: OrganizationStateModel, tokenLevel: number, user:User): Array<Navigation> {
    if (state.organization?.lowerNavigation) {
      if (state.organization.serviceType?.toLowerCase() !== 'inriver' && state.organization.serviceType?.toLowerCase() !== "acquia") {
        return state.organization.lowerNavigation.map(nav => {
          if (nav.title === 'Admin' && nav.selections?.length > 0) {
            return {
              ...nav,
              selections: nav.selections.filter(s => {
                if (s.title === 'Configuration' && state.organization.id !== 28) {
                  return false;
                }
                return true;
              })
            }
          }
          return nav;
        });
      } else {
        return state.organization.lowerNavigation.map(nav => {
          if (nav?.selections?.length > 0) {
            return {
              ...nav,
              selections: nav.selections.filter(s =>
                 s.permission.read
                //  pervious code:
                // s.title === 'Properties' || s.title === 'Third Party Messages' || (s.title === 'Users' && tokenLevel >= 70)
              )
            }
          }
          return nav;
        });
      }
    }
    return [];
  }

  @Selector()
  static getAccountNavigation(state: OrganizationStateModel): Navigation {
    if (state.organization?.accountNavigation) {
      if (state.organization.serviceType?.toLowerCase() === 'inriver') {
        return {
          ...state.organization.accountNavigation,
          selections: [
            ...(state.organization.accountNavigation.selections ? state.organization.accountNavigation.selections?.filter(s => s.title === 'Change Organization') : []),
            {
              title: 'Logout',
              route: '/logout'
            }
          ]
        }
      }
      else {
        const currentUrl = window.location.href;
        const supportUrl = currentUrl?.toLowerCase()?.includes('rapidmap') ? 'https://support.rapidmap.io' : 'https://support.syndic8.io'
        //console.log('accountNav selections=>', state.organization.accountNavigation.selections)
        return {
          ...state.organization.accountNavigation,
          selections: [
            // ...(state.organization.accountNavigation.selections ? state.organization.accountNavigation.selections : []),
            state.organization.accountNavigation.selections[0],
            state.organization.accountNavigation.selections[1],
            state.organization.accountNavigation.selections[2],
            {
              title: 'Support',
              action: () => {
                window.open(supportUrl, '_blank');
              }
            },
            {
              title: 'Logout',
              route: '/logout'
            }
          ]
        };
      }
    }
  }

  @Selector()
  static isEdrayOrgLoggedIn(state: OrganizationStateModel): boolean {
    return state.organization?.id === 28;
  }

  @Selector()
  static isACSOrgLoggedIn(state: OrganizationStateModel): boolean {
    return state.organization?.name?.toLowerCase() === 'allport cargo services usa';
  }

  @Selector()
  static getFilters(state: OrganizationStateModel): Array<Filter> {
    return state.filters;
  }

  @Selector()
  static isLogisticsOrganization(state: OrganizationStateModel): boolean {
    if (
      state.organization.serviceType?.toLowerCase() === 'logistics'
    ) {
      return true;
    }
    return false;
  }

  @Selector()
  static isAcquiaServiceType(state: OrganizationStateModel): boolean {
    if (
      state.organization.serviceType?.toLowerCase() === 'acquia'
    ) {
      return true;
    }
    return false;
  }

  @Selector()
  static isInriverServiceType(state: OrganizationStateModel): boolean {
    if (
      state.organization.serviceType?.toLowerCase() === 'inriver'
    ) {
      return true;
    }
    return false;
  }

  @Selector()
  static isImportWizardEnabled(state: OrganizationStateModel): boolean {
    if (
      state.organization.importWizardFlag
    ) {
      return true;
    }
    return false;
  }

  @Selector()
  static getNetworkConnectionsNavigation(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Network Connections',
      icon: 'plug',
      selections: [
        {
          title: 'Ocean Terminal Connections',
          route: '/org/' + id + '/logistics/network-connections/ocean-terminals'
        },
        {
          title: 'Rail Connections',
          route: '/org/' + id + '/logistics/network-connections/rail'
        },
        {
          title: 'Dray Carriers',
          route: '/org/' + id + '/logistics/network-connections/dray-carriers'
        }
      ]
    };
  }

  @Selector()
  static getProductsActionMsLevel100(state: OrganizationStateModel): Navigation {
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'products')
  }
  @Selector()
  static getCollectionsActionMsLevel100(state: OrganizationStateModel): Navigation {
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'collections')
  }
  @Selector()
  static getMediaActionMsLevel100(state: OrganizationStateModel): Navigation {
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'media management')
  }
  @Selector()
  static getImportActionMsLevel100(state: OrganizationStateModel): Navigation {
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'product content imports')
  }
  @Selector()
  static getImportFlowActionMsLevel100(state: OrganizationStateModel): Navigation {
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'product import flow')
  }
  @Selector()
  static getTemplateAdminActionMsLevel100(state: OrganizationStateModel): Navigation {
    return state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'template admin')
  }
  @Selector()
  static getMetaDataViewActionMsLevel100(state: OrganizationStateModel): Navigation {
    return state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'metadata matrices')
  }
  @Selector()
  static getVerificationActionMsLevel100(state: OrganizationStateModel): Navigation {
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'verification')
  }
  @Selector()
  static getDataAuditActionMsLevel100(state: OrganizationStateModel): Navigation {
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'data audit')
  }
  @Selector()
  static getNotificationsActionMsLevel100(state: OrganizationStateModel): Navigation {
    return state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'release notes')
  }
  @Selector()
  static getTradingPartnersActionMsLevel100(state: OrganizationStateModel): Navigation {
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'trading partners')
  }
  @Selector()
  static getDashboardActionMsLevel100(state: OrganizationStateModel): Navigation {
    return state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'dashboard');
  }
  @Selector()
  static getAdminActionMsLevel100(state: OrganizationStateModel): Navigation {
    return state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'admin');
  }
  @Selector()
  static getTableListActionLevel100(state: OrganizationStateModel): Navigation {
    console.log(state.organization?.lowerNavigation)
    return state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'reference tables');
  }


  @Selector()
  static getProductsAction(state: OrganizationStateModel): Navigation {
    let productAction = state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'products')
    if (productAction.permission?.read == true) {
      return productAction;
    }
    if (productAction.permission?.read == false) {
      return null;
    }
    if (productAction.permission == null) {
      const id: number = state.organization?.id;
      return {
        title: 'Products',
        icon: 'list',
        route: '/org/' + id + '/product',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }

    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'products')

  }


  @Selector()
  static getCollectionsAction(state: OrganizationStateModel): Navigation {

    let collectionsAction = state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'collections')
    if (collectionsAction.permission?.read == true) {
      return collectionsAction;
    }
    if (collectionsAction.permission?.read == false) {
      return null;
    }
    if (collectionsAction.permission == null) {
      const id: number = state.organization?.id;
      return {
        title: 'Collections',
        icon: 'book-open',
        route: '/org/' + id + '/catalog',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'collections')

  }

  @Selector()
  static getMediaAction(state: OrganizationStateModel): Navigation {
    let mediaAction = state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'media management')
    if (mediaAction.permission?.read == true) {

      return mediaAction;
    }
    if (mediaAction.permission?.read == false) {

      return null;
    }
    if (mediaAction.permission == null) {
      const id: number = state.organization?.id;
      return {
        title: 'Media Management',
        icon: 'images',
        route: '/org/' + id + '/image',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'media management')
  }

  @Selector()
  static getImportAction(state: OrganizationStateModel): Navigation {
    let importAction = state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'product content imports')
    if (importAction.permission?.read == true) {
      return importAction;
    }
    if (importAction.permission?.read == false) {
      return null;
    }
    if (importAction.permission == null) {
      const id: number = state.organization?.id;
      return {
        title: 'Product Content Imports',
        icon: 'infinity',
        route: '/org/' + id + '/lifecycle',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'product content imports')
  }

  @Selector()
  static getImportFlowAction(state: OrganizationStateModel): Navigation {
    let importFlowAction = state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'product import flow')
    if (importFlowAction.permission?.read == true) {

      return importFlowAction;
    }
    if (importFlowAction.permission?.read == false) {

      return null;
    }
    if (importFlowAction.permission == null) {

      const id: number = state.organization?.id;
      return {
        title: 'Product Import Flow',
        icon: 'forward',
        route: '/org/' + id + '/product-import/import-flow',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'product import flow')
  }

  @Selector()
  static getTemplateAdminAction(state: OrganizationStateModel): Navigation {
    console.log('lowerNavigation object=>', state.organization?.lowerNavigation)

    let templateAdminAction = state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'template admin')
    if (templateAdminAction.permission?.read == true) {

      return templateAdminAction;
    }
    if (templateAdminAction.permission?.read == false) {

      return null;
    }
    if (templateAdminAction.permission == null) {
      const id: number = state.organization?.id;
      return {
        title: 'Template Admin',
        icon: 'file',
        route: '/org/' + id + '/template',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }
    return state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'template admin')
  }
  @Selector()
  static getSubscriptions(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Subscriptions',
      icon: 'list',
      route: `/org/${id}/subscriptions`,
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      }
    };
  }

  @Selector()
  static getMetaDataViewAction(state: OrganizationStateModel): Navigation {
    let metadataViewAction = state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'metadata matrices')
    if (metadataViewAction.permission?.read == true) {
      return metadataViewAction;
    }
    if (metadataViewAction.permission?.read == false) {
      return null;
    }
    if (metadataViewAction.permission == null) {
      const id: number = state.organization?.id;
      return {
        title: 'Metadata Matrices',
        icon: 'random',
        route: '/org/' + id + '/metadata',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }
    return state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'metadata matrices')
  }

  @Selector()
  static getVerificationAction(state: OrganizationStateModel): Navigation {
    let verificationAction = state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'verification')
    if (verificationAction.permission?.read == true) {
      return verificationAction;
    }
    if (verificationAction.permission?.read == false) {
      return null;
    }
    if (verificationAction.permission == null) {
      const id: number = state.organization?.id;
      return {
        title: 'Verification',
        icon: 'tasks',
        route: '/org/' + id + '/verification',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'verification')
  }

  @Selector()
  static getDataAuditAction(state: OrganizationStateModel): Navigation {
    let dataAuditAction = state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'data audit')
    if (dataAuditAction.permission?.read == true) {
      return dataAuditAction;
    }
    if (dataAuditAction.permission?.read == false) {
      return null;
    }
    if (dataAuditAction.permission == null) {
      const id: number = state.organization?.id;
      return {
        title: 'Data Audit',
        icon: 'tasks',
        route: '/org/' + id + '/data-audit',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'data audit')

  }

  @Selector()
  static getInventoryAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Inventory',
      icon: 'inventory',
      route: '/org/' + id + '/inventory',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }

  @Selector()
  static getVideoTutorialsAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Video Tutorials',
      icon: 'video',
      route: '/org/' + id + '/video-tutorials',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }

  @Selector()
  static getNotificationsAction(state: OrganizationStateModel): Navigation {
    let notificationsAction = state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'release notes')
    if (notificationsAction.permission?.read == true) {
      return notificationsAction;
    }
    if (notificationsAction.permission?.read == false) {
      return null;
    }
    if (notificationsAction.permission == null) {
      const id: number = state.organization?.id;
      return {
        title: 'Release Notes',
        icon: 'bell',
        route: '/org/' + id + '/release-notes',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }
    return state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'release notes')
  }

  @Selector()
  static getTradingPartnersAction(state: OrganizationStateModel): Navigation {

    let tradingPartnerAction = state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'trading partners')
    if (tradingPartnerAction.permission?.read == true) {
      return tradingPartnerAction;
    }
    if (tradingPartnerAction.permission?.read == false) {
      return null;
    }
    if (tradingPartnerAction.permission == null) {
      const id: number = state.organization?.id;
      return {
        title: 'Trading Partners',
        icon: 'exchange',
        route: '/org/' + id + '/channel',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }
    return state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'trading partners')
  }

  @Selector()
  static getDashboardAction(state: OrganizationStateModel): Navigation {

    let dashboardAction = state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'dashboard');
    if (dashboardAction.permission?.read == true) {
      return dashboardAction;
    }
    if (dashboardAction.permission?.read == false) {
      return null;
    }
    if (dashboardAction.permission == null) {
      const id: number = state.organization?.id;
      return {
        title: 'Dashboard',
        icon: 'home',
        route: '/org/' + id + '/dashboard',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }
    return state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'dashboard');
  }


  @Selector()
  static getAdminAction(state: OrganizationStateModel): Navigation {
    let adminAction = state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'admin');
    if (adminAction.permission?.read == true) {
      const selections = adminAction.selections.filter(selection => {
        return selection.permission.read
    });
    const newAdminAction = {...adminAction, selections:selections}
      return newAdminAction;
    }
    if (adminAction.permission?.read == false) {
      return null;
    }
    if (adminAction.permission == null) {
      const id: number = state.organization?.id;
      return {
        title: 'Admin',
        icon: 'cog',
        route: '/org/' + id + '/admin',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }
    return state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'admin');
  }

  // Freight Forwarding
  @Selector()
  static isFreightForwardOrganization(state: OrganizationStateModel): boolean {
    return state.organization.serviceType?.toLowerCase() === 'freight forward'
  }

  @Selector()
  static getFreightForwardDashboardAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Dashboard',
      icon: 'home',
      route: '/org/' + id + '/freight-forwarding/dashboard',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }

  // Reference Tables
  @Selector()
  static getReferenceTableListAction(state: OrganizationStateModel): Navigation {
    let referenceTablesAction = state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'reference tables');
    if (referenceTablesAction.permission?.read == true) {
      console.log(referenceTablesAction)
      return referenceTablesAction;
    }
    if (referenceTablesAction.permission?.read == false) {
      return null;
    }
    if (referenceTablesAction.permission == null) {
      const id: number = state.organization?.id;
      return {
        title: 'Reference Tables',
        icon: 'table',
        route: '/org/' + id + '/freight-forwarding/reference-table',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }
    return state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'reference tables');

  }

  // Reference Tables for FreightForward
  @Selector()
  static getFreightForwardReferenceTableListAction(state: OrganizationStateModel): Navigation {
    let referenceTablesAction = state.organization?.upperNavigation.find(a => a.title?.toLowerCase() === 'reference tables');
    if (referenceTablesAction.permission?.read == true) {
      return referenceTablesAction;
    }
    if (referenceTablesAction.permission?.read == false) {
      return null;
    }
    if (referenceTablesAction.permission == null) {
      const id: number = state.organization?.id;
      return {
        title: 'Reference Tables',
        icon: 'table',
        route: '/org/' + id + '/freight-forwarding/reference-table',
        permission: {
          create: true,
          read: true,
          edit: true,
          remove: true,
          license: null
        },
        selections: null
      };
    }
    return state.organization?.lowerNavigation.find(a => a.title?.toLowerCase() === 'reference tables');

  }


  @Selector()
  static getFreightForwardReferenceTableCreateAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Create',
      icon: 'plus',
      route: '/org/' + id + '/freight-forwarding/reference-tables/create',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }

  @Selector()
  static getFreightForwardReferenceTableUploadAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Upload',
      icon: 'cloud-upload',
      route: '/org/' + id + '/freight-forwarding/reference-tables/upload',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }

  //Import
  @Selector()
  static getFreightForwardImportListAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Import Mapping',
      icon: 'file-import',
      route: '/org/' + id + '/freight-forwarding/import',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }

  @Selector()
  static getFreightForwardImportHistoryAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Export Maps',
      icon: 'file-export',
      route: '/org/' + id + '/freight-forwarding/import/history',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }

  @Selector()
  static getFreightForwardImportUploadAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Upload File',
      icon: 'cloud-upload',
      route: '/org/' + id + '/freight-forwarding/import/upload',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }

  // Containers
  @Selector()
  static getFreightForwardContainersListAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'All Containers',
      icon: 'list',
      route: '/org/' + id + '/freight-forwarding/containers',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }
  // Purchase Orders
  @Selector()
  static getFreightForwardPurchaseOrdersListAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'All POs',
      icon: 'list',
      route: '/org/' + id + '/freight-forwarding/purchase-order',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }
  @Selector()
  static getFreightForwardPurchaseOrdersOutboxAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Outbox',
      icon: 'inbox-out',
      route: '/org/' + id + '/freight-forwarding/purchase-orders/outbox',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }
  @Selector()
  static getFreightForwardPurchaseOrdersMappingRequiredAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Import Mapping Required',
      icon: 'exclamation-triangle',
      route: '/org/' + id + '/freight-forwarding/purchase-orders/exclamation-triangle',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }
  @Selector()
  static getFreightForwardPurchaseOrdersReadyToSendAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Ready to send',
      icon: 'hourglass-half',
      route: '/org/' + id + '/freight-forwarding/purchase-orders/outbox',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }
  @Selector()
  static getFreightForwardPurchaseOrdersSentAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Sent',
      icon: 'inbox-out',
      route: '/org/' + id + '/freight-forwarding/purchase-orders/outbox',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }
  @Selector()
  static getFreightForwardPurchaseOrdersAcknowledgedAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Acknowledged',
      icon: 'ballot-check',
      route: '/org/' + id + '/freight-forwarding/purchase-orders/outbox',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }

  // Export
  @Selector()
  static getFreightForwardExportAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Export Mapping',
      icon: 'file-export',
      route: '/org/' + id + '/freight-forwarding/export',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }

  // Data Flows
  @Selector()
  static getNewDataFlowAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'New Data Flow',
      icon: 'forward',
      route: '/org/' + id + '/freight-forwarding/data-flow/new',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }
  @Selector()
  static getAllDataFlowsAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Data Flows',
      icon: 'forward',
      route: '/org/' + id + '/freight-forwarding/data-flow',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }

  @Selector()
  static getFtpConfigAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Connection Setup',
      icon: 'tools',
      route: '/org/' + id + '/freight-forwarding/connection-setup',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }
  @Selector()
  static getFreightForwardOrgSelectAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Select a Customer',
      icon: 'th',
      route: '/org/' + id + '/freight-forwarding',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }

  @Selector()
  static getFreightForwardLocationAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Locations',
      icon: 'map-marker-alt',
      route: '/org/' + id + '/freight-forwarding/location',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }

  @Selector()
  static getInboxOutboxAction(state: OrganizationStateModel): Navigation {
    const id: number = state.organization?.id;
    return {
      title: 'Inbox/Outbox',
      icon: 'signal-stream',
      route: '/org/' + id + '/freight-forwarding/messaging',
      permission: {
        create: true,
        read: true,
        edit: true,
        remove: true,
        license: null
      },
      selections: null
    };
  }

  @Selector()
  static getEditMode(state: OrganizationStateModel): any {
    return state.editMode;
  }

  private static getPerms(url: string, state: OrganizationStateModel): Permission {
    for (const upper of state.organization?.upperNavigation) {

      if (url.indexOf(upper.route) > -1 && upper.permission) {
        return upper.permission;
      }
    }
    for (const lower of state.organization?.lowerNavigation) {
      if (url.indexOf(lower.route) > -1 && lower.permission) {
        return lower.permission;
      }
    }
    const account = state.organization?.accountNavigation;
    if (url.indexOf(account.route) > -1 && account.permission) {
      return account.permission;
    }

    // Else give full access
    return {
      create: true,
      read: true,
      edit: true,
      remove: true
    };
  }

  static getPagePermission() {
    return createSelector(
      [OrganizationState, RouterState.state],
      (state: OrganizationStateModel, route: RouterStateSnapshot): Permission => {
        return this.getPerms(route.url, state);
      }
    );
  }

  static getPermission(url: string) {
    return createSelector(
      [OrganizationState],
      (state: OrganizationStateModel): Permission => {
        return this.getPerms(url, state);
      }
    );
  }
  @Action(LoadOrganizationAction)
  loadOrganization(
    { patchState }: StateContext<OrganizationStateModel>,
    { payload: { organizationId } }: LoadOrganizationAction
  ) {
    return this.orgApi.getOrgById(organizationId, organizationId).pipe(
      tap(
        (organization: Organization) => {
          // Prevent server from providing `null` object
          if (organization) {
            patchState({ organization });
            this.store.dispatch(new ReloadUserAction({ organizationId }))
          }
        }
      )
    );
  }

  @Action(ResetOrganizationAction)
  resetOrganization({ setState }: StateContext<OrganizationStateModel>) {
    setState(defaults);
  }

  @Action(LoadOrganizationFiltersAction)
  loadOrganizationFilters(
    { patchState, getState }: StateContext<OrganizationStateModel>
  ) {
    const organization: Organization = getState().organization;
    this.filterApi.getFilters(organization.id).pipe(
      tap(
        (filters: Array<Filter>) => {
          patchState({ filters });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          if (error.status !== 404) {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Load Saved Views',
              type: NotificationType.ERROR
            });
          }
          patchState({ filters: defaults.filters });
          return of(error);
        }
      )
    ).subscribe();
  }

  @Action(AddOrganizationFilterAction)
  addOrganizationFilter(
    { setState, getState, dispatch }: StateContext<OrganizationStateModel>,
    { payload: { filter } }: AddOrganizationFilterAction
  ) {
    const organization: Organization = getState().organization;
    return this.filterApi.addFilter(organization.id, filter).pipe(
      tap(
        (addedFilter: Filter) => {
          setState(
            patch<OrganizationStateModel>({
              filters: append([addedFilter])
            })
          );
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'View saved',
            type: NotificationType.SUCCESS
          });
          dispatch(new Navigate([], { filterId: addedFilter.filterId }, { queryParamsHandling: 'merge' }));
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to save view',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(DeleteOrganizationFilterAction)
  deleteOrganizationFilter(
    { setState, getState }: StateContext<OrganizationStateModel>,
    { payload: { filter } }: DeleteOrganizationFilterAction
  ) {
    const organization: Organization = getState().organization;
    return this.filterApi.removeFilter(filter.filterId, organization.id).pipe(
      tap(
        () => {
          setState(
            patch<OrganizationStateModel>({
              filters: removeItem(f => f.filterId === filter.filterId)
            })
          );
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'View deleted',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to delete view',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(ToggleOrganizationEditModeAction)
  toggleOrganizationEditModeAction(
    { patchState, getState }: StateContext<OrganizationStateModel>
  ) {
    const currentValue = getState().editMode;
    patchState({
      editMode: !currentValue
    });
  }
}
