<div class="wrapper">
  <div class="header">
    <div class="title">
      <div class="name">{{ config.displayName }}</div>
      <ng-content></ng-content>
    </div>
    <div class="description" [innerHTML]="config.description | sanitizeHtml"></div>
    <a *ngIf="config.helpUrl" [href]="config.helpUrl" target="_blank">For more information see our help page</a>

    <!-- <div class="how-to">
      <a *ngIf="config.helpUrl" [href]="config.helpUrl" target="_blank">How to Guide</a>
      <a *ngIf="!config.helpUrl" href="https://support.syndic8.io/hc/en-us/articles/360056222394" target="_blank">How to Guide</a>
    </div> -->
<!--    <div class="walmart-help" *ngIf="isWalmartMarketplace">-->
<!--      If you are new to selling on Walmart, you may need to configure a Shipping Template before you begin syndicating products. <a href="https://support.syndic8.io/hc/en-us/articles/4411142354323" target="_blank">Click here for more info.</a>-->
<!--    </div>-->
  </div>
  <div class="properties" *ngIf="propertyForms && propertyForms.length > 0">
    <app-panel-form *ngFor="let property of properties; index as i" [property]="property" [form]="propertyForms.at(i)" (save)="save.emit()"></app-panel-form>
  </div>
  <div class="buttons">
    <app-circle-button color="green" label="Save" icon="save" (click)="save.emit()" [disabled]="propertyForms.invalid"></app-circle-button>
    <app-circle-button color="red" label="Discard" icon="trash" (click)="discard.emit()"></app-circle-button>
  </div>
</div>
