import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { Event as NavigationEvent } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private _loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private router: Router) {
    this.router.events.subscribe(
      (event: NavigationEvent) => {
        if (event instanceof NavigationStart) {
          this._loadingSubject.next(true);
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this._loadingSubject.next(false);
        }
      }
    );
  }

  isLoading$(): Observable<boolean> {
    return this._loadingSubject.asObservable();
  }
}
