import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OrderBy } from '@app/core';
import { QueryFilter } from '@app/shared/services/query-filter.service';
import { MetadataField } from '@app/shared/typings/metadata.types';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-query-filter-sort',
  templateUrl: './query-filter-sort.component.html',
  styleUrls: ['./query-filter-sort.component.scss']
})
export class QueryFilterSortComponent implements OnInit, OnDestroy {
  @Input() queryFilters: Array<QueryFilter> = [];
  @Input() sortAscending = false;
  @Output() sortAscendingChange: EventEmitter<boolean> = new EventEmitter(true);

  sortByForm: UntypedFormControl = new UntypedFormControl('');

  routerEventsSub: Subscription;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.routerEventsSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => this.setSort());

    this.setSort();
  }

  ngOnDestroy(): void {
    this.routerEventsSub.unsubscribe();
  }

  setSort(): void {
    const { queryParams } = this.route.snapshot;
    if (queryParams.order) {
      if (this.queryFilters) {
        for (let i = 0; i < this.queryFilters.length; i++) {
          const queryFilter = this.queryFilters[i];
          if (queryFilter.propertyName === queryParams.sort) {
            this.sortByForm.setValue(queryFilter.propertyName);
          }
        }
      }
    }
    this.sortAscending = queryParams.order === OrderBy.ASC;
    this.sortAscendingChange.emit(this.sortAscending);
  }

  toggleSortDirection(): void {
    this.sortAscending = !this.sortAscending;
    this.sortAscendingChange.emit(this.sortAscending);
    const direction = this.sortAscending ? OrderBy.ASC : OrderBy.DESC;

    this.router.navigate([], {
      queryParams: { order: direction },
      queryParamsHandling: 'merge'
    });
  }

  onSelectedSortChange(sort: string): void {
    const queryParams = { sort: sort ? sort : undefined };
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }
}
