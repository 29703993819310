import { Component, HostBinding, OnInit, HostListener } from '@angular/core';
import { UploadService } from '../../services/upload.service';
import { DataFlow, Organization } from '@app/core/api';
import { ApplicationService } from '../../services/application.service';
import { Select, Store } from '@ngxs/store';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { LoadDataFlowsAction } from '@app/features/freight-forwarding/modules/data-flow/stores/data-flow.actions';
import { ImportFlowState } from '@app/features/product-import/modules/import-flow/stores/import-flow.state';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-upload-overlay',
  templateUrl: './upload-overlay.component.html',
  styleUrls: ['./upload-overlay.component.scss']
})
export class UploadOverlayComponent implements OnInit {
  @HostBinding('class.dropping') dropping = false;
  excludedRoutes: Array<string> = [];
  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(event: BeforeUnloadEvent) {
    // Prevent navigating away while uploading is active
    if (this.upload.isActive) {
      this.stopEvent(event);
      event.returnValue = '';
    }
    return;
  }

  disableUpload = false;

  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent) {
    const snapShot = this.appSvc.getUrlSnaphot().split('/').slice(0, 4).join('/')
    console.log("This is the snap =>", snapShot)

    if (!event || this.upload.isActive || this.excludedRoutes.includes(snapShot)) {
      this.disableUpload = true
      return;
    }
    this.stopEvent(event);

    this.dropping = false;

    const data: DataTransfer = event.dataTransfer;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.upload.addFiles(data.files, organization.id);
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(event: DragEvent) {
    if (
      !event ||
      (event.dataTransfer.items && event.dataTransfer.items.length === 0) ||
      event.dataTransfer.types.reduce(
        (acc, type) => {
          if (type === 'Files' || type === 'application/x-moz-file') {
            return acc;
          }
          return true;
        },
        false
      ) ||
      this.upload.isActive ||
      this.excludedRoutes.indexOf(this.appSvc.getUrlSnaphot()) > -1
    ) {
      return;
    }
    this.stopEvent(event);

    this.dropping = true;
  }

  constructor(public upload: UploadService, private appSvc: ApplicationService, private store: Store) {

  }

  ngOnInit(): void {
      this.store.dispatch(new LoadDataFlowsAction());
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.excludedRoutes = [
      '/org/' + organization.id + '/import',
      '/org/' + organization.id + '/product-import'
     ];
     const snapShot = this.appSvc.getUrlSnaphot().split('/').slice(0, 4).join('/')
    console.log("This is the snap =>", snapShot)


    if (this.excludedRoutes.includes(snapShot)) {
      this.disableUpload = true
      return;
    }
  }

  dragLeave(event: DragEvent): void {
    if (!event) {
      return;
    }
    this.stopEvent(event);
    this.dropping = false;
  }

  private stopEvent(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }
}
