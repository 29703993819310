import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {
  private defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    // url = url.replace(/\%/g, '%25');
    return this.defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree): string {
    // Replaces '%25' with '%' to revert double encoding of url params by angular router
    return this.defaultUrlSerializer.serialize(tree).replace(/%25/g, '%');
  }
}
