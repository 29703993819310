<app-modal [open]="open" (openChange)="openChange.emit($event)" (close)="bulkUpdateComponent.reset()" [height]="height" [width]="width">
  <app-modal-head>
    <app-modal-title>{{ modalTitle }}</app-modal-title>
  </app-modal-head>
  <app-modal-body [overflow]="false">
    <app-bulk-update #bulkUpdateComponent [(disableSubmit)]="isDisabled" [showDestinations]="showDestinations" [productType]="productType" [fields]="fields" (submit)="buttonClick(bulkUpdateComponent)"></app-bulk-update>
  </app-modal-body>
  <app-modal-foot [center]="true">
    <div class="flex-fill"></div>
    <app-button (click)="buttonClick(bulkUpdateComponent)" [disabled]="bulkUpdateComponent.form?.invalid || isDisabled" color="green">{{ buttonTitle }}</app-button>
  </app-modal-foot>
</app-modal>

<app-modal [(open)]="showConfirmModal" height="200" width="380" [closeOnOutsideClick]="false">
  <app-modal-head>
    <app-modal-title>Reset?</app-modal-title>
  </app-modal-head>
  <app-modal-body [center]="true">
    <div>This will reset your chosen field for all selected rows. Are you sure?</div>
  </app-modal-body>
  <app-modal-foot [center]="true">
    <app-button (click)="showConfirmModal = false" color="red">Cancel</app-button>
    <div class="flex-fill"></div>
    <app-button (click)="clearClick(bulkUpdateComponent)" color="green">Confirm</app-button>
  </app-modal-foot>
</app-modal>
