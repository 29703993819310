import { Component, OnInit, Input, HostBinding, HostListener, OnDestroy } from '@angular/core';
import { NavigationService, NavigationActionItem } from '../../../services/navigation.service';
import { Subscription, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { Organization } from '@app/core/api';
import { AuthState } from '@app/core';

@Component({
  selector: 'app-navigation-logistics-list',
  templateUrl: './navigation-logistics-list.component.html',
  styleUrls: ['./navigation-logistics-list.component.scss']
})
export class NavigationLogisticsListComponent implements OnInit, OnDestroy {
  @Input() upperNavigationActionItems: NavigationActionItem[];
  @Input() lowerNavigationActionItems: NavigationActionItem[];
  @Input() accountNavigationActionItem: NavigationActionItem;
  @Input() networkConnectionsNavigation: NavigationActionItem;

  @Select(OrganizationState.getOrganization) organization$: Observable<Organization>;
  organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);

  dashboardActions: Array<NavigationActionItem> = [];
  lspDashboardActions: Array<NavigationActionItem> = [];
  drayCarrierDashboardActions: Array<NavigationActionItem> = [];
  visibilityActions: Array<NavigationActionItem> = [];
  drayCarrierVisibilityActions: Array<NavigationActionItem> = [];
  executionActions: Array<NavigationActionItem> = [];
  customerExecutionActions: Array<NavigationActionItem> = [];
  adminActions: Array<NavigationActionItem> = [];
  pluginActionItem: NavigationActionItem;
  chassisManagementActionItem: NavigationActionItem = {
    title: 'Chassis Management',
    icon: 'trailer',
    route: null,
    selections: null
  }

  selectionOpen = false;
  organizationSub: Subscription;
  activeNavigationSub: Subscription;
  activeSelectionSub: Subscription;

  @HostBinding('class.expanded') navigationOpen = false;
  @HostListener('mouseenter')
  mouseEnter() {
    this.navigation.expandNavigation(undefined);
  }
  @HostListener('mouseleave')
  mouseLeave() {
    this.navigation.collapseNavigation();
  }

  constructor(private navigation: NavigationService, private router: Router, private store: Store) { }

  ngOnInit(): void {
    this.activeNavigationSub = this.navigation.activeNavigation.subscribe(
      (action: NavigationActionItem) => {
        if (!action) {
          this.navigationOpen = false;
        } else {
          this.navigationOpen = true;
        }
      }
    );
    this.activeSelectionSub = this.navigation.activeSelection.subscribe(
      (action: NavigationActionItem) => {
        if (!action) {
          this.selectionOpen = false;
        } else {
          this.selectionOpen = true;
        }
      }
    );
    this.organizationSub = this.store.select(OrganizationState.getOrganization).subscribe(
      ({ id }: Organization) => {
        this.dashboardActions = [
          {
            id: 1,
            title: 'Terminal Overview',
            icon: 'boxes-alt',
            route: '/org/' + id + '/logistics/terminal'
          },
          {
            id: 2,
            title: 'DC Overview',
            icon: 'warehouse-alt',
            route: '/org/' + id + '/logistics/distribution-center'
          },
          {
            id: 3,
            title: 'DC Specific Overview',
            icon: 'warehouse-alt',
            route: '/org/' + id + '/logistics/dc-dashboard'
          },
          {
            id: 4,
            title: 'Detention Overview',
            icon: 'money-bill',
            route: '/org/' + id + '/logistics/detention-dashboard'
          },
          {
            id: 5,
            title: 'Dray Dashboard',
            icon: 'truck',
            route: '/org/' + id + '/logistics/dray-carrier-dashboard'
          },
          {
            id: 6,
            title: 'Network Connections: Dray Carriers',
            icon: 'truck',
            route: '/org/' + id + '/logistics/network-connections/dray-carriers'
          },
          {
            id: 7,
            title: 'Program Overview',
            icon: 'tag',
            route: '/org/' + id + '/logistics/program-overview'
          },
          {
            id: 8,
            title: 'Item Overview',
            icon: 'box',
            route: '/org/' + id + '/logistics/item-overview'
          }
        ];
        this.lspDashboardActions = [
          {
            id: 1,
            title: 'Terminal Overview',
            icon: 'boxes-alt',
            route: '/org/' + id + '/logistics/terminal'
          },
          {
            id: 2,
            title: 'DC Overview',
            icon: 'warehouse-alt',
            route: '/org/' + id + '/logistics/distribution-center'
          },
          {
            id: 4,
            title: 'Detention Overview',
            icon: 'money-bill',
            route: '/org/' + id + '/logistics/detention-dashboard'
          },
          {
            id: 5,
            title: 'Dray Dashboard',
            icon: 'truck',
            route: '/org/' + id + '/logistics/dray-carrier-dashboard'
          },
          {
            id: 6,
            title: 'Carrier Overview',
            icon: 'truck',
            route: '/org/' + id + '/logistics/carrier-overview'
          },
          {
            id: 7,
            title: 'Program Overview',
            icon: 'tag',
            route: '/org/' + id + '/logistics/program-overview'
          },
          {
            id: 8,
            title: 'Ops Dashboard',
            icon: 'chart-bar',
            route: '/org/' + id + '/logistics/ops-dashboard'
          },
          this.networkConnectionsNavigation
        ];
        this.drayCarrierDashboardActions = [
          {
            id: 1,
            title: 'Ops Dashboard',
            icon: 'truck',
            route: '/org/' + id + '/logistics/dray-carrier-dashboard'
          },
          {
            id: 2,
            title: 'Terminal Overview',
            icon: 'boxes-alt',
            route: '/org/' + id + '/logistics/terminal'
          },
          {
            id: 3,
            title: 'DC Overview',
            icon: 'warehouse-alt',
            route: '/org/' + id + '/logistics/distribution-center'
          },
          {
            id: 4,
            title: 'Network Connections: Dray Carriers',
            icon: 'truck',
            route: '/org/' + id + '/logistics/network-connections/dray-carriers/details'
          },
        ];
        this.visibilityActions = [
          {
            id: 1,
            title: 'Current Vessels',
            icon: 'ship',
            route: '/org/' + id + '/logistics/current-vessels',
          },
          {
            id: 2,
            title: 'Voyages',
            icon: 'map-marker-alt',
            route: '/org/' + id + '/logistics/voyage',
          },
          {
            id: 3,
            title: 'Shipments',
            icon: 'container-storage',
            route: '/org/' + id + '/logistics/shipment',
          },
          {
            id: 4,
            title: 'Legs',
            icon: 'route',
            route: '/org/' + id + '/logistics/leg',
          },
          {
            id: 5,
            title: 'Documents',
            icon: 'paperclip',
            route: '/org/' + id + '/logistics/documents',
          }
        ];
        this.drayCarrierVisibilityActions = [
          {
            id: 4,
            title: 'Legs',
            icon: 'route',
            route: '/org/' + id + '/logistics/leg',
          },
          {
            id: 5,
            title: 'Documents',
            icon: 'paperclip',
            route: '/org/' + id + '/logistics/documents',
          }
        ];
        this.executionActions = [
          {
            id: 1,
            title: 'Validation',
            icon: 'check',
            route: '/org/' + id + '/logistics/validation',
          },
          {
            id: 2,
            title: 'Tracing',
            icon: 'map-marked-alt',
            route: '/org/' + id + '/logistics/tracing',
          },
          {
            id: 3,
            title: 'Allocation',
            icon: 'expand-arrows',
            route: '/org/' + id + '/logistics/allocation',
          },
          {
            id: 4,
            title: 'Detention/Demurrage Rules',
            icon: 'money-bill',
            route: '/org/' + id + '/logistics/detention-demurrage-rules',
          },
          {
            id: 5,
            title: 'Empty Notify',
            icon: 'flag-alt',
            route: '/org/' + id + '/logistics/empty-notify',
          }
        ];
        this.customerExecutionActions = [
          {
            id: 1,
            title: 'Empty Notify',
            icon: 'flag-alt',
            route: '/org/' + id + '/logistics/empty-notify',
          },
          {
            id: 2,
            title: 'Gate Management',
            icon: 'trailer',
            route: '/org/' + id + '/logistics/gate-management?filterBy=driverOutDate&filterJoin=AND&filterMatch=IS&filterValue=null',
          },
          {
            id: 2,
            title: 'Yard Inventory',
            icon: 'pallet-alt',
            route: '/org/' + id + '/logistics/yard-inventory?filterBy=gateOutDate&filterJoin=AND&filterMatch=IS&filterValue=null',
          },
          {
            id: 3,
            title: 'Detention/Demurrage Rules',
            icon: 'money-bill',
            route: '/org/' + id + '/logistics/detention-demurrage-rules',
          },
        ];
        this.adminActions = [
          {
            id: 1,
            title: 'All Vessels',
            icon: 'ship',
            route: '/org/' + id + '/logistics/vessel',
          },
          {
            id: 6,
            title: 'Carrier Maintenance',
            icon: 'truck',
            route: '/org/' + id + '/logistics/carrier-maintenance',
          },
          {
            id: 2,
            title: 'Customer References',
            icon: 'user-cog',
            route: '/org/' + id + '/logistics/customer-reference'
          },
          {
            id: 3,
            title: 'Location References',
            icon: 'map-signs',
            route: '/org/' + id + '/logistics/location-reference'
          },
          {
            id: 4,
            title: 'Locations',
            icon: 'location-arrow',
            route: '/org/' + id + '/logistics/locations'
          },
          {
            id: 5,
            title: 'Container Types',
            icon: 'container-storage',
            route: '/org/' + id + '/logistics/container-type'
          },
          {
            id: 6,
            title: 'Terminal Schedules',
            icon: 'clock',
            route: '/org/' + id + '/logistics/terminal-schedule'
          }
        ];
        this.pluginActionItem = {
          id: 1,
          title: 'Plugins',
          icon: 'plug',
          route: '/org/' + id + '/logistics/plugin'
        };
      }
    );
  }

  ngOnDestroy(): void {
    this.activeNavigationSub.unsubscribe();
    this.activeSelectionSub.unsubscribe();
    this.organizationSub.unsubscribe();
    this.navigation.collapseBoth();
  }

  goHome(): void {
    this.navigation.collapseBoth();
    this.router.navigate(['/'], { queryParams: { default: true } });
  }

  goToChassisManagement(): void {
    // const tokenData = this.store.selectSnapshot(AuthState.getToken);
    window.open(`https://chassisiq.com`, '_blank');
    // window.open(`https://chassisiq.com?token=${tokenData}`, '_blank');
  }
}
