import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { FilterJoin, FilterMatch, Suggestion } from '@app/core';
import { map } from 'rxjs/operators';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SuggestionFilter } from '../components/input/suggestion/suggestion.component';

export interface InfiniteScrollPage {
  pageLength: number;
  pageToGet: number;
}

export type InfiniteScrollImageType = 'Image' | 'Video';

export const InfiniteScrollImageType = {
  Image: 'Image' as InfiniteScrollImageType,
  Video: 'Video' as InfiniteScrollImageType
};

export interface InfiniteScrollImage {
  src: string;
  title?: string;
  altIcon?: string;
  type?: InfiniteScrollImageType;
}

export interface InfiniteScrollTitle {
  title: string;
  subtitle?: string;
}

export type InfiniteScrollColor = 'yellow' | 'red' | 'green' | 'blue';

export const InfiniteScrollColor = {
  Yellow: 'yellow' as InfiniteScrollColor,
  Red: 'red' as InfiniteScrollColor,
  Green: 'green' as InfiniteScrollColor,
  Blue: 'blue' as InfiniteScrollColor
};

export type InfiniteScrollColorTestFn = (row: { [key: string]: any }) => InfiniteScrollColor;

export interface InfiniteScrollChip {
  title?: string;
  icon?: string;
  text?: string;
  color: InfiniteScrollColor;
}

export interface InfiniteScrollAction {
  title: string;
  icon?: string;
  altIcon?: string;
  color?: InfiniteScrollColor;
  action: (row: { [key: string]: any }) => void;
}

export type InfiniteScrollColumnType = 'String' | 'Number' | 'Date' | 'Datetime' | 'Boolean' | 'Suggestion' | 'SuggestionList' | 'MultiSuggestion' | 'Currency' | 'Email';

export const InfiniteScrollColumnType = {
  String: 'String' as InfiniteScrollColumnType,
  Number: 'Number' as InfiniteScrollColumnType,
  Date: 'Date' as InfiniteScrollColumnType,
  Datetime: 'Datetime' as InfiniteScrollColumnType,
  Boolean: 'Boolean' as InfiniteScrollColumnType,
  Suggestion: 'Suggestion' as InfiniteScrollColumnType,
  SuggestionList: 'SuggestionList' as InfiniteScrollColumnType,
  MultiSuggestion: 'MultiSuggestion' as InfiniteScrollColumnType,
  Currency: 'Currency' as InfiniteScrollColumnType,
  Email: 'Email' as InfiniteScrollColumnType
};

export interface InfiniteScrollColumnDetail {
  propertyName: string;
  displayName: string;
  type?: InfiniteScrollColumnType;
  altValue?: string;
  emphasis?: number; // Emphasis of text in the tooltip, levels 1 - 3
}

export type InfiniteScrollColumnSuggestionProperty = 'id' | 'name';

export const InfiniteScrollColumnSuggestionProperty = {
  Id: 'id' as InfiniteScrollColumnSuggestionProperty,
  Name: 'name' as InfiniteScrollColumnSuggestionProperty,
};

export interface InfiniteScrollColumnValidation {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
}

export interface InfiniteScrollColumn {
  propertyName: string;
  displayProperty?: string; // If set, use this property value in the UI. Useful for showing one property but updating another
  displayName: string;
  friendlyName?: string; // If set, this name is to be used in the `active columns panel`
  type?: InfiniteScrollColumnType;
  gmt?: boolean; // Date/Datetime should be transformed to GMT rather than local by default
  color?: InfiniteScrollColor;
  headerColor?: InfiniteScrollColor;
  width?: number; // A pixel width for this column
  fill?: boolean; // Fill the remaining column space equally
  multiFilter?: boolean; // Should the filter string be split by spaces to make multiple filter queries?
  freeFormFilter?: boolean; // Ignore the type and always filter by free form text
  parseInt?: boolean; // Parse input value from form to an integer
  suggestion?: string; // The Suggestion list `include` param for the request
  suggestionFilters?: SuggestionFilter; // A `filter` object to input with the Suggestion list
  suggestionQueryFilter?: { // A filter applied when lazy fetching a Suggestion list that depends on another property value on each row
    [key: string]: {
      filterBy: Array<string>,
      filterJoin: Array<FilterJoin>,
      filterMatch: Array<FilterMatch>,
      filterValuePropertyName: Array<string> // The name of the property to use as the value to filter by in context with each row
    }
  };
  suggestionAlternateProperty?: { // Another property from the row to set a value for from the selected Suggestion
    /* (comments on my best guess for usage) Pulls Either from the name or Id field of a suggestion */
    valueProperty: InfiniteScrollColumnSuggestionProperty;
    /* (comments on my best guess for usage) What field of the data to edit */
    propertyName: string;
    parseInt?: boolean;
  };
  suggestionExclude?: Array<string>; // A list of Suggestions to exclude
  suggestionSetTypeaheadIfNotFound?: boolean; // Set display value even if suggestion value isn't found
  suggestionAltField?: string; // Alternative form field to display
  suggestionShowClose?: boolean;
  suggestionShowList?: boolean;
  suggestionInternalInclude?: boolean;
  multiSelectionFilterMatch?: FilterMatch; // Fallback to `IS`
  checkboxes?: Array<InfiniteScrollFilterCheckbox>;
  // Choose which Suggestion prop to use as the value for the select.
  // Fallback to `name`
  suggestionValueProperty?: InfiniteScrollColumnSuggestionProperty;
  // Fallback to `true`
  editable?: boolean;
  multiLingual?: boolean;
  // Fallback to `true`
  filterable?: boolean;
  // Fallback to `true`
  sortable?: boolean;
  sortPropertyName?: string;
  notNull?: boolean;
  details?: Array<InfiniteScrollColumnDetail>;
  detailsWidth?: number;
  transform?: (row: { [key: string]: any }) => string; // Transform shown value with a function, overrides any `type` driven transformers
  defaultValue?: string | number;
  validation?: InfiniteScrollColumnValidation;
  transformColor?: (row: { [key: string]: any }) => InfiniteScrollColor;
  currencyCodePropertyName?: string; // A property name to get the value for a currency code to use with the Currency pipe when displaying this cell value
  emptyCellText?: string;
  defaultToCurrentDateTimeOnFirstClick?: boolean;
  required?: boolean;
  removeFilterOnCheckboxUncheck?: boolean;
  accessorialField?: boolean;
}

export interface InfiniteScrollFilterCheckbox {
  title: string,
  filter: {
    match: FilterMatch,
    value: string
  }
}

export type InputType = 'Text' | 'Number' | 'Date' | 'Datetime' | 'Checkbox';

export const InputType = {
  Text: 'text' as InputType,
  Number: 'number' as InputType,
  Date: 'date' as InputType,
  Datetime: 'datetime-local' as InputType,
  Checkbox: 'checkbox' as InputType
};

export interface QueryFilters {
  by: Array<string>;
  join: Array<FilterJoin>;
  match: Array<FilterMatch>;
  value: Array<string>;
}

export interface MasterCheckbox {
  checked: boolean;
  directly: boolean;
}

export type InfiniteScrollStyle = 'Default' | 'Logistics' | 'Product';

export const InfiniteScrollStyle = {
  Default: 'Default' as InfiniteScrollStyle,
  Logistics: 'Logistics' as InfiniteScrollStyle,
  Product: 'Product' as InfiniteScrollStyle
};

export type InfiniteScrollLayout = 'List' | 'Grid';

export const InfiniteScrollLayout = {
  List: 'List' as InfiniteScrollLayout,
  Grid: 'Grid' as InfiniteScrollLayout
};

@Injectable()
export class InfiniteScrollService {

  private editableSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private navigateSubject: Subject<{ [any: string]: any }> = new Subject();
  private selectedSubject: BehaviorSubject<Array<{ [any: string]: any }>> = new BehaviorSubject([]);
  private masterCheckboxSubject: BehaviorSubject<MasterCheckbox> = new BehaviorSubject({ checked: false, directly: false });
  private rowsSubject: BehaviorSubject<Array<{ [key: string]: any }>> = new BehaviorSubject([]);
  private showColumnFillSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _selectedRows: Array<{ [any: string]: any }> = [];
  private _rowIdPropertyName: string;
  private _rows: Array<{ [key: string]: any }> = [];
  private _masterCheckbox: MasterCheckbox = { checked: false, directly: false };
  private _showColumnFill = false;

  constructor(private formBuilder: UntypedFormBuilder) { }

  set rows(rows: Array<{ [key: string]: any }>) {
    this._rows = [...rows];
    this.rowsSubject.next(this._rows);
    if (this._masterCheckbox.checked && this._masterCheckbox.directly) {
      this.selectAllRows();
    }
  }

  get rows(): Array<{ [key: string]: any }> {
    return this._rows;
  }

  get rows$(): Observable<Array<{ [key: string]: any }>> {
    return this.rowsSubject.asObservable();
  }

  set rowIdPropertyName(propName: string) {
    this._rowIdPropertyName = propName;
  }

  get editable$(): Observable<boolean> {
    return this.editableSubject.asObservable();
  }

  editable(state: boolean): void {
    this.editableSubject.next(state);
  }

  get navigate$(): Observable<{ [any: string]: any }> {
    return this.navigateSubject.asObservable();
  }

  navigate(row: { [any: string]: any }): void {
    this.navigateSubject.next(row);
  }

  get masterCheckbox$(): Observable<MasterCheckbox> {
    return this.masterCheckboxSubject.asObservable();
  }

  masterCheckbox(state: MasterCheckbox): void {
    if (state.checked) {
      this._selectedRows = [...this._rows];
    } else {
      this._selectedRows = [];
    }
    this.selectedSubject.next(this._selectedRows);

    this._masterCheckbox = { checked: state.checked, directly: state.directly };
    this.masterCheckboxSubject.next(state);
  }

  get selected$(): Observable<Array<{ [any: string]: any }>> {
    return this.selectedSubject.asObservable();
  }

  isSelected$(row: { [key: string]: any }): Observable<boolean> {
    return this.selectedSubject.asObservable().pipe(
      map(
        (selectedRows: Array<{ [key: string]: any }>) => {
          if (selectedRows.findIndex(sr => sr[this._rowIdPropertyName] === row[this._rowIdPropertyName]) === -1) {
            return false;
          }
          return true;
        }
      )
    );
  }

  private get selected(): Array<{ [any: string]: any }> {
    return this._selectedRows;
  }

  private set selected(rows: Array<{ [any: string]: any }>) {
    this._selectedRows = [...rows];
    this.selectedSubject.next(this._selectedRows);

    if (this._rows.length > 0 && this._rows.length === this._selectedRows.length) {
      this._masterCheckbox = { checked: true, directly: this._masterCheckbox.directly ? true : false };
      this.masterCheckboxSubject.next(this._masterCheckbox);
    } else if (this._masterCheckbox.checked) {
      this._masterCheckbox = { checked: false, directly: false };
      this.masterCheckboxSubject.next(this._masterCheckbox);
    }
  }

  selectedRows(): Array<{ [key: string]: any }> {
    return this._selectedRows;
  }

  selectRow(row: { [any: string]: any }): void {
    if (!row) {
      return;
    }
    this.selected = [...this._selectedRows, row];
  }

  deselectRow(row: { [any: string]: any }): void {
    if (!row) {
      return;
    }
    const index = this._selectedRows.findIndex(sr => sr[this._rowIdPropertyName] === row[this._rowIdPropertyName]);
    this.selected = [
      ...this._selectedRows.slice(0, index),
      ...this._selectedRows.slice(index + 1)
    ];
  }

  selectAllRows(): void {
    this.selected = this._rows;
  }

  deselectAllRows(): void {
    this.selected = [];
  }

  isRowSelected(row: { [key: string]: any }): boolean {
    for (let i = 0; i < this._selectedRows.length; i++) {
      if (this._selectedRows[i][this._rowIdPropertyName] === row[this._rowIdPropertyName]) {
        return true;
      }
    }
    return false;
  }

  buildValidationFormGroup(object: object | Array<any>, columns: Array<InfiniteScrollColumn>): UntypedFormGroup | UntypedFormArray {
    if (Array.isArray(object)) {
      const array = this.formBuilder.array([]);
      for (let i = 0; i < object.length; i++) {
        const value = object[i];
        if (value && typeof value === 'object') {
          array.push(this.buildValidationFormGroup(value, columns));
        } else {
          array.push(this.formBuilder.control(value, []));
        }
      }
      return array;
    }
    const group = this.formBuilder.group({});
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const value = object[key];
        if (value && typeof value === 'object') {
          group.addControl(key, this.buildValidationFormGroup(value, columns));
        } else {
          const column: InfiniteScrollColumn = columns.find(c => c.propertyName === key);
          group.addControl(key, this.formBuilder.control(value, this.getColumnValidation(column)));
        }
      }
    }
    return group;
  }

  private getColumnValidation(column: InfiniteScrollColumn): Array<ValidatorFn> {
    let validations: Array<ValidatorFn> = [];
    if (column && column.validation) {
      if (column.type === InfiniteScrollColumnType.Email) {
        validations = [...validations, Validators.email];
      }
      if (column.validation.required) {
        validations = [...validations, Validators.required];
      }
      if (column.validation.minLength) {
        validations = [...validations, Validators.minLength(column.validation.minLength)];
      }
      if (column.validation.maxLength) {
        validations = [...validations, Validators.maxLength(column.validation.maxLength)];
      }
    }
    return validations;
  }

  set showColumnFill(fill: boolean) {
    this._showColumnFill = fill;
    this.showColumnFillSubject.next(fill);
  }

  get showColumnFill(): boolean {
    return this._showColumnFill;
  }

  get showColumnFill$(): Observable<boolean> {
    return this.showColumnFillSubject.asObservable();
  }
}
