import { Component, OnInit, Input, Output, EventEmitter, HostBinding, HostListener, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Permission, OrgApiService, Organization, FilterJoin, FilterMatch, OrderBy, LogisticsApiService, GroupBy, SqlCount, FilterDefinitionUnPaged } from '@app/core';
import { Store } from '@ngxs/store';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { ApplicationService } from '@app/shared/services/application.service';
import { tap, filter } from 'rxjs/operators';
import { MasterCheckbox } from '../../services/infinite-scroll.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

export type ProductView = 'product-list' | 'catalog-product-list' | 'destination-product-list';

export const ProductView = {
  ProductList: 'product-list' as ProductView,
  CatalogProductList: 'catalog-product-list' as ProductView,
  DestinationProductList: 'destination-product-list' as ProductView
};

export type CountScreenName = 'product' | 'product-name' | 'product-sku' | 'product-upc' | 'product-custom' | 'catalog' | 'org' | 'sub-catalog' | 'sub-catalog-destinations' | 'channel' | 'import-product' | 'import-image' | 'images' | 'cost' | 'location' | 'locationReference' | 'purchase-order' | 'container';

export const CountScreenName = {
  ProductName: 'product-name' as CountScreenName,
  ProductSku: 'product-sku' as CountScreenName,
  ProductUpc: 'product-upc' as CountScreenName,
  ProductCustom: 'product-custom' as CountScreenName,
  ProductDestination: 'product-destination' as CountScreenName,
  Catalog: 'catalog' as CountScreenName,
  Org: 'org' as CountScreenName,
  SubCatalog: 'sub-catalog' as CountScreenName,
  SubCatalogDestinations: 'sub-catalog-destinations' as CountScreenName,
  Channel: 'channel' as CountScreenName,
  ImportProduct: 'import-product' as CountScreenName,
  ImportImage: 'import-image' as CountScreenName,
  Images: 'images' as CountScreenName,
  Cost: 'cost' as CountScreenName,
  Location: 'location' as CountScreenName, // Not being in `CountLogisticsScreeName` is a mistake of the API
  LocationReference: 'locationReference' as CountScreenName, // Not being in `CountLogisticsScreeName` is a mistake of the API
  PurchaseOrder: 'purchase-order' as CountScreenName,
  Container: 'container' as CountScreenName
};

export type CountLogisticsScreeName = 'shipment' | 'validations' | 'shipmenttracking' | 'vessel' | 'containertype' | 'vesselvoyage' | 'legs' | 'customerreference' | 'costrule' | 'documentrepository';

export const CountLogisticsScreeName = {
  Shipment: 'shipment' as CountLogisticsScreeName,
  Validations: 'validations' as CountLogisticsScreeName,
  ShipmentTracking: 'shipmenttracking' as CountLogisticsScreeName,
  Vessel: 'vessel' as CountLogisticsScreeName,
  ContainerType: 'containertype' as CountLogisticsScreeName,
  VesselVoyage: 'vesselvoyage' as CountLogisticsScreeName,
  Legs: 'legs' as CountLogisticsScreeName,
  CustomerReference: 'customerreference' as CountLogisticsScreeName,
  CostRule: 'costrule' as CountLogisticsScreeName,
  DocumentRepository: 'documentrepository' as CountLogisticsScreeName
};

export type SelectedActionColor = 'Red' | 'Green' | 'Blue' | 'Yellow' | 'Navy' | 'Purple';

export const SelectedActionColor = {
  Red: 'Red' as SelectedActionColor,
  Green: 'Green' as SelectedActionColor,
  Blue: 'Blue' as SelectedActionColor,
  Yellow: 'Yellow' as SelectedActionColor,
  Navy: 'Navy' as SelectedActionColor,
  Purple: 'Purple' as SelectedActionColor
};

export interface SelectedAction {
  title: string;
  hidden?: boolean;
  hiddenFn?: (selected: Array<any>) => boolean;
  // disabled?: boolean;
  action?: () => void;
  bottomBorder?: boolean;
  topBorder?: boolean;
  requiredPermissions?: Array<Permission>;
  icon?: string;
  iconColor?: SelectedActionColor;
  iconPrefix?: string;
}

@Component({
  selector: 'app-selected-actions',
  templateUrl: './selected-actions.component.html',
  styleUrls: ['./selected-actions.component.scss']
})
export class SelectedActionsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() masterChecked: MasterCheckbox = { checked: false, directly: false };
  @Input() selectedRows: Array<{ [key: string]: any }>;
  @Input() actions: Array<SelectedAction> = [];
  @Input() disabled = false;
  @Input() hideAllDisplay = false;
  @Input() open = false;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();
  @Input() productView: ProductView;
  @Input() screenName: CountScreenName | CountLogisticsScreeName;

  openClick = false;
  totalRowCount = 0;
  rowCountLoading: boolean;
  lastRowCountRequested: number;
  routerEventsSub: Subscription;

  SelectedActionColor = SelectedActionColor;

  @HostListener('document:click')
  clickOutside() {
    if (!this.openClick) {
      this.open = false;
      this.openChange.emit(false);
    }
    this.openClick = false;
    this.cd.detectChanges();
  }

  @HostBinding('class.disabled') get disabledClass() { return this.selectedRows.length === 0 || this.disabled; }

  constructor(
    private orgApi: OrgApiService,
    private logisticsApi: LogisticsApiService,
    private store: Store,
    private appSvc: ApplicationService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.cd.detach();

    this.routerEventsSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      // Used to prevent previous running SQL Counts from overwriting the latest
      const lastRowCountRequested = new Date().getTime();
      this.lastRowCountRequested = lastRowCountRequested;
      this.fetchRowCount(lastRowCountRequested)
    });

    this.fetchRowCount();
  }

  ngOnDestroy(): void {
    this.routerEventsSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        const change = changes[propName];
        switch (propName) {
          case 'selectedRows': {
            if (change.currentValue && change.currentValue.length === 0) {
              this.open = false;
              this.openChange.emit(false);
            }
            this.cd.detectChanges();
            break;
          }
          case 'actions': {
            if (change.currentValue && !change.previousValue) {
              this.cd.detectChanges();
            }
            break;
          }
          default: {
            this.cd.detectChanges();
          }
        }
      }
    }
  }

  openActions(): void {
    if (this.selectedRows.length === 0 || this.disabled) {
      return;
    }
    if (this.open) {
      this.open = false;
      this.openChange.emit(false);
    } else {
      this.openClick = true;
      this.open = true;
      this.openChange.emit(true);
    }
    this.cd.detectChanges();
  }

  isPermissible(action: SelectedAction): boolean {
    if (action.requiredPermissions && action.requiredPermissions.length > 0) {
      return action.requiredPermissions.every(rp => rp);
    }
    return true;
  }

  actionClick(event: MouseEvent, action: SelectedAction): void {
    event.stopPropagation();
    this.open = false;
    this.openChange.emit(false);
    if (action.action) {
      action.action();
    }
    this.cd.detectChanges();
  }

  get logistics(): boolean {
    return Object.values(CountLogisticsScreeName).findIndex(value => value === this.screenName) > -1;
  }

  private fetchRowCount(lastRowCountRequested?: number): void {
    this.rowCountLoading = true;
    let screenName: CountScreenName | CountLogisticsScreeName = this.screenName;
    if (this.productView) {
      const category = this.route.snapshot.queryParams['category'];
      if (category) {
        if (category === GroupBy.SKU) {
          screenName = CountScreenName.ProductSku;
        } else if (category === GroupBy.UPC) {
          screenName = CountScreenName.ProductUpc;
        } else if (category === GroupBy.CUSTOM) {
          screenName = CountScreenName.ProductCustom;
        } else if (category === GroupBy.DESTINATION) {
          screenName = CountScreenName.ProductDestination;
        } else {
          screenName = CountScreenName.ProductName;
        }
      } else {
        screenName = CountScreenName.ProductName;
      }
    }
    if (!screenName) {
      return;
    }

    const { queryParams, parent: { params } } = this.route.snapshot;
    let filterBy: Array<string> = queryParams.filterBy ? Array.isArray(queryParams.filterBy) ?
      queryParams.filterBy : [queryParams.filterBy] : [];
    let filterJoin: Array<FilterJoin> = queryParams.filterJoin ? Array.isArray(queryParams.filterJoin) ?
      queryParams.filterJoin : [queryParams.filterJoin] : [];
    let filterMatch: Array<FilterMatch> = queryParams.filterMatch ? Array.isArray(queryParams.filterMatch) ?
      this.appSvc.toUpperCaseArray(queryParams.filterMatch) : [queryParams.filterMatch.toUpperCase() as FilterMatch] : [];
    let filterValue: Array<string> = queryParams.filterValue ? Array.isArray(queryParams.filterValue) ?
      queryParams.filterValue : [queryParams.filterValue] : [];
    const sortBy: Array<string> = queryParams.sort ? Array.isArray(queryParams.sort) ?
      queryParams.sort : [queryParams.sort] : [];
    const orderBy: Array<OrderBy> = queryParams.order ? Array.isArray(queryParams.order) ?
      this.appSvc.toUpperCaseArray(queryParams.order) : [queryParams.order.toUpperCase() as OrderBy] : [];

    if (this.productView === ProductView.CatalogProductList) {
      const catalogId = params.catalogId;
      filterBy = [...filterBy, 'subCatalogId'];
      filterJoin = [...this.appSvc.toUpperCaseArray(filterJoin), FilterJoin.AND];
      filterMatch = [...filterMatch, FilterMatch.IS];
      filterValue = [...filterValue, catalogId.toString()];
    } else if (this.productView === ProductView.DestinationProductList) {
      const destinationId: number = parseInt(this.route.snapshot.parent.params['destinationId'], null);
      filterBy = [...filterBy, 'subCatalogId'];
      filterJoin = [...this.appSvc.toUpperCaseArray(filterJoin), FilterJoin.AND];
      filterMatch = [...filterMatch, FilterMatch.IS];
      filterValue = [...filterValue, destinationId.toString()];
    }
    const filterDefinition: FilterDefinitionUnPaged = {
      filterEntries: filterBy.map(
        (by: string, i: number) => {
          const join = filterJoin[i];
          const match = filterMatch[i];
          const value = filterValue[i];
          return {
            filterBy: by,
            filterJoin: join,
            filterMatch: match,
            filterValue: value
          };
        }
      ),
      sortEntries: sortBy.map(
        (by: string, i: number) => {
          return { sortBy: by, orderBy: orderBy[i] };
        }
      )
    };

    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    if (this.logistics) {
      this.logisticsApi.getSqlCountPost(
        screenName.toUpperCase(),
        organization.id,
        filterDefinition
      ).pipe(
        tap(
          ({ sqlCount }: SqlCount) => {
            if (!this.lastRowCountRequested || lastRowCountRequested === this.lastRowCountRequested) {
              this.totalRowCount = sqlCount;
              this.rowCountLoading = false;
            }
            this.cd.detectChanges();
          }
        )
      ).subscribe();
    } else {
      this.orgApi.getSqlCountOrgPost(
        screenName.toUpperCase(),
        organization.id,
        filterDefinition
      ).pipe(
        tap(
          ({ sqlCount }: SqlCount) => {
            this.totalRowCount = sqlCount;
            this.cd.detectChanges();
          }
        )
      ).subscribe();
    }
  }
}
