<ng-container *ngIf="selection && selection?.action">
  <div class="selection" (click)="selection?.action()">
    <span class="title">
      {{ selection?.title }}
    </span>
  </div>
</ng-container>
<ng-container *ngIf="selection && !selection?.action">
  <a class="selection"
    [ngClass]="{ 'active': isSelectionActive }"
    (click)="navigateToSelection($event, selection?.route)"
    [attr.href]="selection?.route && !selection?.selections ? '.' + selection?.route : null">
    <span class="title">
      {{ selection?.title }}
    </span>
  </a>
</ng-container>
