import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() shape = 'circle';
  @Input() color = 'green';
  @Output() selectedChange: EventEmitter<boolean> = new EventEmitter();
  @Input() radio = false;

  @HostBinding('class.disabled') @Input() disabled = false;
  @HostBinding('class.selected') @Input() selected = false;

  @HostListener('click') click() { this.check(); }

  constructor() { }

  ngOnInit(): void {
  }

  check(): void {
    if (this.selected && this.radio) {
      return;
    }
    this.selected = !this.selected;
    this.selectedChange.emit(this.selected);
  }
}
