<div class="flex-container" [ngClass]="{
  'red': notification.type === 'ERROR',
  'amber': notification.type === 'WARN',
  'blue': notification.type === 'INFO',
  'green': notification.type === 'SUCCESS'
}">
  <div class="status-icon">
    <i *ngIf="notification.type === 'ERROR'" class="fal fa-exclamation-circle red"></i>
    <i *ngIf="notification.type === 'WARN'" class="fal fa-exclamation-triangle amber"></i>
    <i *ngIf="notification.type === 'SUCCESS'" class="fal fa-check-circle green"></i>
    <i *ngIf="notification.type === 'INFO'" class="fal fa-info-circle blue"></i>
  </div>
  <div class="notification-content">
    <div class="title" *ngIf="notification.title">
      {{ notification.title }}</div>
    <div class="message" *ngIf="notification.message">{{ notification.message }}</div>
    <div class="time" *ngIf="notification.message">{{ notification.sentTime | date: 'shortTime'}}</div>
    <div class="dismiss" (click)="dismissNotification($event, notification)">
      <span>Dismiss</span>
    </div>
  </div>
  <div class="read-status-container">
    <div class="read-status" [ngClass]="{'read': notification.read}">
    </div>
  </div>
</div>
