import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HistoryService } from '@app/shared';
import { ExceptionService, Exception } from '../../services/exception.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {

  in = false;
  showDebug = false;
  info = '';
  error = '';
  supportUrl: string = 'https://support.syndic8.io';
  tokenSub: Subscription;

  constructor(
    private router: Router,
    private history: HistoryService,
    private exception: ExceptionService
  ) { }

  ngOnInit() {
    setTimeout(() => this.in = true, 500);
    const currentUrl = window.location.href;
    this.supportUrl = currentUrl?.toLowerCase()?.includes('rapidmap') ? 'https://support.rapidmap.io' : 'https://support.syndic8.io'
    const exception: Exception = this.exception.getLast();
    this.info = exception && exception.info ? exception.info : '';
    this.error = exception && exception.error ? exception.error : '';
  }

  ngOnDestroy(): void {
    if (this.tokenSub) {
      this.tokenSub.unsubscribe();
    }
  }

  navigateBack(): void {
    this.router.navigateByUrl(this.history.getUrl());
  }
}
