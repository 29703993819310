/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DataProcessField } from './dataProcessField';
import { DataProcessSql } from './dataProcessSql';
import { ChangeEntry } from './changeEntry';
import { TradingPartner } from './tradingPartner';
import { DataProcessBlock } from './dataProcessBlock';


export interface DataProcessTemplate { 
    blocks?: { [key: string]: DataProcessBlock; };
    blocksList?: Array<DataProcessBlock>;
    categoryMap?: Array<{ [key: string]: any; }>;
    changeEntryList?: Array<ChangeEntry>;
    currencyCodeOutput?: string;
    dataFlowName?: string;
    destinationList?: Array<string>;
    disableSavingToOutputName?: boolean;
    forceParentAlignment?: boolean;
    inputDateFormat?: string;
    inputFileType?: string;
    languageCodeOutput?: string;
    languageCodeOutputPrimary?: boolean;
    languageCodePrimary?: string;
    mapLoaded?: Set<string>;
    mapSql?: { [key: string]: DataProcessSql; };
    mappingTable?: { [key: string]: any; };
    newTemplate?: boolean;
    outputFileName?: string;
    parentRowList?: Array<DataProcessField>;
    parentTemplateList?: Array<string>;
    parentTemplateName?: string;
    sqlMap?: { [key: string]: DataProcessSql; };
    templateComment?: string;
    templateMap?: { [key: string]: string; };
    tradingPartner?: TradingPartner;
    useInputDateFormatOnExport?: boolean;
    variableList?: Array<DataProcessField>;
    variableMap?: { [key: string]: DataProcessField; };
    blockList?: Array<DataProcessBlock>;
    blockMappingTable?: { [key: string]: any; };
    blockSql?: Array<DataProcessSql>;
    blockVariables?: Array<DataProcessField>;
    headersToFile?: { [key: string]: number; };
}

