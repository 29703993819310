import { Injectable } from '@angular/core';
import { HistoryService } from '@app/shared';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { Observable, of, throwError, timer } from 'rxjs';
import { concatMap, retry } from 'rxjs/operators';
import { AuthState, TokenData } from '../stores/auth.state';
import { TelemetryService } from './telemetry.service';

export interface Exception {
  name: string;
  info: string;
  error: string;
}

@Injectable({
  providedIn: 'root'
})
export class ExceptionService {

  private _exceptionHistroy: Array<Exception> = [];

  constructor(private telemetry: TelemetryService, private store: Store, private history: HistoryService) {
    timer(10000, 20000).pipe(
    ).subscribe(this.clear.bind(this));
  }

  add(e: Exception): void {
    if (!environment.production) {
      console.error(e.name +
        `\nRoute: ${this.history.getUrl()}` +
        `\nInfo: ${e.info}`
      );
    }
    this._exceptionHistroy = [...this._exceptionHistroy, e];
  }

  getAll(): Array<Exception> {
    return this._exceptionHistroy;
  }

  getLast(): Exception {
    if (this._exceptionHistroy.length >= 2) {
      return this._exceptionHistroy[this._exceptionHistroy.length - 1];
    } else if (this._exceptionHistroy.length === 1) {
      return this._exceptionHistroy[0];
    }
    return undefined;
  }

  clear(): void {
    this._exceptionHistroy = [];
  }
}
