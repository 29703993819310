import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';

import { Store } from '@ngxs/store';
import { Observable, throwError, of } from 'rxjs';

import { catchError, mergeMap } from 'rxjs/operators';
import { AuthState } from '../stores/auth.state';

@Injectable({
  providedIn: 'root',
})
export class TermsGuard  {

  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.selectOnce(AuthState.isLoaded).pipe(
      mergeMap(loaded => loaded ? of(true) : throwError('User not loaded')),
      mergeMap(() => this.store.selectOnce(AuthState.getUser)),
      mergeMap(user => !user.termOfService ? of(true) : throwError('Terms already accepted')),
      catchError(() => of(this.router.createUrlTree(['/'], { queryParams: { default: true } })))
    );
  }
}
