/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Audit } from './audit';
import { APlus } from './aPlus';
import { APlusContent } from './aPlusContent';
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';


export interface APlusTemplate { 
    active?: boolean;
    aplusTemplateId?: number;
    comment?: string;
    companyDestinatonId?: number;
    companyId?: number;
    contentList?: Array<APlus>;
    internalChangedDate?: string;
    internalCreatedDate?: string;
    jsonStr?: string;
    templateModel?: string;
    templateName?: string;
    aplusStatus?: string;
    auditList?: Array<Audit>;
    contentModelList?: Array<APlusContent>;
    createdDate?: ModifiedDate;
    destinationRef?: OrganizationReference;
    lastUpdated?: ModifiedDate;
    orgRef?: OrganizationReference;
    saveContentModels?: boolean;
}

