/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { FilterJoin } from '../model/models';
import { FilterMatch } from '../model/models';
import { Location } from '../model/models';
import { LocationReference } from '../model/models';
import { OrderBy } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class LocationApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Add a Location
     * @param orgId An Organization ID number used to indicate the current org context
     * @param location A new Location to add
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addLocation(orgId: number, location: Location, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Location>;
    public addLocation(orgId: number, location: Location, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Location>>;
    public addLocation(orgId: number, location: Location, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Location>>;
    public addLocation(orgId: number, location: Location, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling addLocation.');
        }
        if (location === null || location === undefined) {
            throw new Error('Required parameter location was null or undefined when calling addLocation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Location>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/location`,
            location,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a Location Reference
     * @param orgId An Organization ID number used to indicate the current org context
     * @param locationReference A new Location Reference to add
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addLocationReference(orgId: number, locationReference: LocationReference, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LocationReference>;
    public addLocationReference(orgId: number, locationReference: LocationReference, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LocationReference>>;
    public addLocationReference(orgId: number, locationReference: LocationReference, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LocationReference>>;
    public addLocationReference(orgId: number, locationReference: LocationReference, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling addLocationReference.');
        }
        if (locationReference === null || locationReference === undefined) {
            throw new Error('Required parameter locationReference was null or undefined when calling addLocationReference.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<LocationReference>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/locationReference`,
            locationReference,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a new Image Upload ID for an Organization
     * @param totalCount 
     * @param orgId An Organization ID number used to indicate the current org context
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getImageUploadId(totalCount: number, orgId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Location>;
    public getImageUploadId(totalCount: number, orgId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Location>>;
    public getImageUploadId(totalCount: number, orgId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Location>>;
    public getImageUploadId(totalCount: number, orgId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (totalCount === null || totalCount === undefined) {
            throw new Error('Required parameter totalCount was null or undefined when calling getImageUploadId.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getImageUploadId.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Location>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/image/upload/id/${encodeURIComponent(String(totalCount))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Location by ID for an Organization
     * @param locationId Location ID for logistics
     * @param orgId An Organization ID number used to indicate the current org context
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLocationById(locationId: number, orgId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Location>;
    public getLocationById(locationId: number, orgId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Location>>;
    public getLocationById(locationId: number, orgId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Location>>;
    public getLocationById(locationId: number, orgId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (locationId === null || locationId === undefined) {
            throw new Error('Required parameter locationId was null or undefined when calling getLocationById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getLocationById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Location>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/location/${encodeURIComponent(String(locationId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Location Reference by ID for an Organization
     * @param locationReferenceId Location Reference ID for logistics
     * @param orgId An Organization ID number used to indicate the current org context
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLocationReferenceById(locationReferenceId: number, orgId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LocationReference>;
    public getLocationReferenceById(locationReferenceId: number, orgId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LocationReference>>;
    public getLocationReferenceById(locationReferenceId: number, orgId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LocationReference>>;
    public getLocationReferenceById(locationReferenceId: number, orgId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (locationReferenceId === null || locationReferenceId === undefined) {
            throw new Error('Required parameter locationReferenceId was null or undefined when calling getLocationReferenceById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getLocationReferenceById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<LocationReference>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/locationReference/${encodeURIComponent(String(locationReferenceId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Location References for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterBy Property in data to filter by, e.g. &#x60;description&#x60; or &#x60;owner.firstName&#x60;
     * @param filterJoin Operator to match filterBy to filter value
     * @param filterMatch Operator to match filterBy to filter value
     * @param filterValue Value to match with in the filterBy
     * @param limit Length of the page
     * @param offset Start of the page
     * @param orderBy Order to sort by, ascending or descending
     * @param sortBy Property to sort by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLocationReferences(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<LocationReference>>;
    public getLocationReferences(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<LocationReference>>>;
    public getLocationReferences(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<LocationReference>>>;
    public getLocationReferences(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getLocationReferences.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterBy) {
            filterBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterBy');
            })
        }
        if (filterJoin) {
            filterJoin.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterJoin');
            })
        }
        if (filterMatch) {
            filterMatch.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterMatch');
            })
        }
        if (filterValue) {
            filterValue.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterValue');
            })
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy');
            })
        }
        if (sortBy) {
            sortBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sortBy');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<LocationReference>>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/locationReference`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Locations for an Organization
     * @param orgId An Organization ID number used to indicate the current org context
     * @param filterBy Property in data to filter by, e.g. &#x60;description&#x60; or &#x60;owner.firstName&#x60;
     * @param filterJoin Operator to match filterBy to filter value
     * @param filterMatch Operator to match filterBy to filter value
     * @param filterValue Value to match with in the filterBy
     * @param limit Length of the page
     * @param offset Start of the page
     * @param orderBy Order to sort by, ascending or descending
     * @param sortBy Property to sort by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLocations(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Location>>;
    public getLocations(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Location>>>;
    public getLocations(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Location>>>;
    public getLocations(orgId: number, filterBy?: Array<string>, filterJoin?: Array<FilterJoin>, filterMatch?: Array<FilterMatch>, filterValue?: Array<string>, limit?: number, offset?: number, orderBy?: Array<OrderBy>, sortBy?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling getLocations.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterBy) {
            filterBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterBy');
            })
        }
        if (filterJoin) {
            filterJoin.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterJoin');
            })
        }
        if (filterMatch) {
            filterMatch.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterMatch');
            })
        }
        if (filterValue) {
            filterValue.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterValue');
            })
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy');
            })
        }
        if (sortBy) {
            sortBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sortBy');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Location>>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/location`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Overwrite an Location by ID
     * This endpoint will replace a Location
     * @param locationId Location ID for logistics
     * @param orgId An Organization ID number used to indicate the current org context
     * @param location 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchLocationById(locationId: number, orgId: number, location?: Location, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Location>;
    public patchLocationById(locationId: number, orgId: number, location?: Location, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Location>>;
    public patchLocationById(locationId: number, orgId: number, location?: Location, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Location>>;
    public patchLocationById(locationId: number, orgId: number, location?: Location, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (locationId === null || locationId === undefined) {
            throw new Error('Required parameter locationId was null or undefined when calling patchLocationById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling patchLocationById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<Location>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/location/${encodeURIComponent(String(locationId))}`,
            location,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Overwrite an Location Reference by ID
     * This endpoint will replace a Location Reference
     * @param locationReferenceId Location Reference ID for logistics
     * @param orgId An Organization ID number used to indicate the current org context
     * @param locationReference 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchLocationReferenceById(locationReferenceId: number, orgId: number, locationReference?: LocationReference, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LocationReference>;
    public patchLocationReferenceById(locationReferenceId: number, orgId: number, locationReference?: LocationReference, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LocationReference>>;
    public patchLocationReferenceById(locationReferenceId: number, orgId: number, locationReference?: LocationReference, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LocationReference>>;
    public patchLocationReferenceById(locationReferenceId: number, orgId: number, locationReference?: LocationReference, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (locationReferenceId === null || locationReferenceId === undefined) {
            throw new Error('Required parameter locationReferenceId was null or undefined when calling patchLocationReferenceById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling patchLocationReferenceById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<LocationReference>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/locationReference/${encodeURIComponent(String(locationReferenceId))}`,
            locationReference,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an upload with failed the uploads
     * @param statsId 
     * @param totalCount 
     * @param orgId An Organization ID number used to indicate the current org context
     * @param requestBody A list of failed file uploads
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postImageUploadIdStats(statsId: number, totalCount: number, orgId: number, requestBody: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<string>;
    public postImageUploadIdStats(statsId: number, totalCount: number, orgId: number, requestBody: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<string>>;
    public postImageUploadIdStats(statsId: number, totalCount: number, orgId: number, requestBody: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<string>>;
    public postImageUploadIdStats(statsId: number, totalCount: number, orgId: number, requestBody: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (statsId === null || statsId === undefined) {
            throw new Error('Required parameter statsId was null or undefined when calling postImageUploadIdStats.');
        }
        if (totalCount === null || totalCount === undefined) {
            throw new Error('Required parameter totalCount was null or undefined when calling postImageUploadIdStats.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling postImageUploadIdStats.');
        }
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling postImageUploadIdStats.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<string>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/image/upload/${encodeURIComponent(String(totalCount))}/${encodeURIComponent(String(statsId))}`,
            requestBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Overwrite an Location by ID
     * This endpoint will replace a Location
     * @param locationId Location ID for logistics
     * @param orgId An Organization ID number used to indicate the current org context
     * @param location 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putLocationById(locationId: number, orgId: number, location?: Location, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Location>;
    public putLocationById(locationId: number, orgId: number, location?: Location, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Location>>;
    public putLocationById(locationId: number, orgId: number, location?: Location, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Location>>;
    public putLocationById(locationId: number, orgId: number, location?: Location, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (locationId === null || locationId === undefined) {
            throw new Error('Required parameter locationId was null or undefined when calling putLocationById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling putLocationById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<Location>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/location/${encodeURIComponent(String(locationId))}`,
            location,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Overwrite an Location Reference by ID
     * This endpoint will replace a Location Reference
     * @param locationReferenceId Location Reference ID for logistics
     * @param orgId An Organization ID number used to indicate the current org context
     * @param locationReference 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putLocationReferenceById(locationReferenceId: number, orgId: number, locationReference?: LocationReference, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LocationReference>;
    public putLocationReferenceById(locationReferenceId: number, orgId: number, locationReference?: LocationReference, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LocationReference>>;
    public putLocationReferenceById(locationReferenceId: number, orgId: number, locationReference?: LocationReference, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LocationReference>>;
    public putLocationReferenceById(locationReferenceId: number, orgId: number, locationReference?: LocationReference, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (locationReferenceId === null || locationReferenceId === undefined) {
            throw new Error('Required parameter locationReferenceId was null or undefined when calling putLocationReferenceById.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling putLocationReferenceById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<LocationReference>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/locationReference/${encodeURIComponent(String(locationReferenceId))}`,
            locationReference,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a Location by ID from an Organization
     * This endpoint will remove a Location
     * @param locationId Location ID for logistics
     * @param orgId An Organization ID number used to indicate the current org context
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeLocation(locationId: number, orgId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public removeLocation(locationId: number, orgId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public removeLocation(locationId: number, orgId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public removeLocation(locationId: number, orgId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (locationId === null || locationId === undefined) {
            throw new Error('Required parameter locationId was null or undefined when calling removeLocation.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling removeLocation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/location/${encodeURIComponent(String(locationId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a Location Reference by ID from an Organization
     * This endpoint will remove a Location Reference
     * @param locationReferenceId Location Reference ID for logistics
     * @param orgId An Organization ID number used to indicate the current org context
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeLocationReference(locationReferenceId: number, orgId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public removeLocationReference(locationReferenceId: number, orgId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public removeLocationReference(locationReferenceId: number, orgId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public removeLocationReference(locationReferenceId: number, orgId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (locationReferenceId === null || locationReferenceId === undefined) {
            throw new Error('Required parameter locationReferenceId was null or undefined when calling removeLocationReference.');
        }
        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling removeLocationReference.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        // authentication (openApi) required
        credential = this.configuration.lookupCredential('openApi');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/org/${encodeURIComponent(String(orgId))}/locationReference/${encodeURIComponent(String(locationReferenceId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
