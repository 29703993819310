import { Component, Input, OnInit } from '@angular/core';
import { FunctionType } from '@app/shared/services/template.service';
import { Chip, ChipType } from '../destination-chip/destination-chip.component';

@Component({
  selector: 'app-if-then-else',
  templateUrl: './if-then-else.component.html',
  styleUrls: ['./if-then-else.component.scss']
})
export class IfThenElseComponent implements OnInit {
  @Input() set convert(c: string) {
    const convert = c.split(FunctionType.IfThenElse)[1];
    const vert = convert.split('::')[0].split('==');
    const condition = vert ? vert[0] ? vert[0].trim() : '' : '';
    const value = vert ? vert[1] ? vert[1].trim().slice(1, -1) : ''  : '';
    const ifThen = convert.split('::')[1];
    const ifElse = convert.split('::')[2];
    this.chips = [
      { value: condition, missing: 'None', preSign: 'If', sign: '==' },
      { value, missing: 'None', sign: 'then' },
      { value: ifThen, missing: 'None' ,sign: 'else' },
      { value: ifElse, missing: 'None' }
    ].map(chip => {
      if (chip.value?.startsWith(`'$X{`)) {
        return {...chip, value: `XPATH:${chip.value.split(`'$X{`)[1].slice(0, -2)}`, type: ChipType.Variable };
      } else if (chip.value?.startsWith(`$X{`)) {
        return {...chip, value: `XPATH:${chip.value.split(`$X{`)[1].slice(0, -1)}`, type: ChipType.Variable };
      } else if (chip.value?.startsWith(`'$D{`)) {
        return {...chip, value: `${chip.value.split(`'$D{`)[1].slice(0, -2)}`, type: ChipType.Variable };
      } else if (chip.value?.startsWith(`$D{`)) {
        return {...chip, value: `${chip.value.split(`$D{`)[1].slice(0, -1)}`, type: ChipType.Variable };
      }
      return {...chip, type: ChipType.Literal };
    });
  }
  @Input() wrap = false;

  chips: Array<Chip> = [];

  constructor() { }

  ngOnInit(): void {
  }

}
