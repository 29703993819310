import { Injectable, ErrorHandler } from '@angular/core';
import { environment } from '@env/environment';
import { ExceptionService } from '../services/exception.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private exception: ExceptionService) { }

  handleError(error: any): void {
    const chunkFailedMessage = /ChunkLoadError: Loading chunk [\w\d\-]+ failed./;

    // If Angular failed to load a chunk, try to hard reload the page
    if (chunkFailedMessage.test(error.message)) {
      window.location.href = '/';
    }
    if (error instanceof Error) {
      this.exception.add({
        name: 'GlobalErrorHandler',
        info: 'There was a client-side script error',
        error: JSON.stringify(error, Object.getOwnPropertyNames(error))
      });
    }
    if (!environment.production) {
      console.error(error);
    }
  }
}
