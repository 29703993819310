import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type ChipType = 'literal' | 'variable' | 'missing';

export const ChipType = {
  Literal: 'literal' as ChipType,
  Variable: 'variable' as ChipType,
  Missing: 'missing' as ChipType
};

export interface Chip {
  type: ChipType;
  value: string;
  display?: string;
  preSign?: string;
  sign?: string;
  alt?: string;
  delete?: boolean;
}

@Component({
  selector: 'app-destination-chip',
  templateUrl: './destination-chip.component.html',
  styleUrls: ['./destination-chip.component.scss']
})
export class DestinationChipComponent implements OnInit {
  @Input() chip: Chip;
  @Input() showDelete = false;
  @Output() removeSegment: EventEmitter<Chip> = new EventEmitter();
  @Input() backgroundColor: 'inherit';
  @Input() color: 'inherit';
  ChipType = ChipType;

  constructor() { }

  ngOnInit(): void {
  }
}
