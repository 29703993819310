import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ExceptionService } from '../services/exception.service';

@Injectable()
export class EmptyResponseInterceptor implements HttpInterceptor {

  constructor(private exception: ExceptionService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Throw an error notification on any empty response, then return to the previous route
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (!event.body && event.status === 200 && request.headers.get('Content-Type') === 'application/json') {
              this.exception.add({
                name: 'EmptyResponseInterceptor',
                info: 'Empty response, please try again',
                error: JSON.stringify(request)
              });
            }
          }
        }
      )
    );
  }
}
