/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationReference } from './organizationReference';


export interface YardInventory { 
    changeLocation?: boolean;
    changeYardLocation?: boolean;
    chassisNumber?: string;
    checkOut?: boolean;
    claimedEmptyDate?: string;
    comment?: string;
    contaierId?: number;
    containerEmptyStatus?: boolean;
    containerSealNumber?: string;
    emptyIn?: boolean;
    emptyNotifyDate?: string;
    emptyOut?: boolean;
    equipmentNumber?: string;
    equipmentType?: string;
    gateInDate?: string;
    gateOutDate?: string;
    inSource?: string;
    inspectionIn?: boolean;
    inspectionOut?: boolean;
    lastFreeDayOffDock?: string;
    legIdIn?: number;
    legIdOut?: number;
    locationId?: number;
    orgRef?: OrganizationReference;
    orgRefDrayCarrierIn?: OrganizationReference;
    orgRefDrayCarrierOut?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    outSource?: string;
    sealNumber?: string;
    shipmentId?: number;
    yardDeriverIdIn?: number;
    yardDeriverIdOut?: number;
    yardInventoryId?: number;
    yardLocation?: string;
}

