import { Component, Input, OnInit } from '@angular/core';

export enum ModalTitleWeight {
  Light,
  Normal,
  Semibold,
  Bold
};

@Component({
  selector: 'app-modal-title',
  templateUrl: './modal-title.component.html',
  styleUrls: ['./modal-title.component.scss']
})
export class ModalTitleComponent implements OnInit {
  @Input() subtitle: string;
  @Input() titleWeight: ModalTitleWeight = ModalTitleWeight.Semibold;

  ModalTitleWeight = ModalTitleWeight

  constructor() { }

  ngOnInit(): void {
  }

}
