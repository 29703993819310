import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wizard-page',
  templateUrl: './wizard-page.component.html',
  styleUrls: ['./wizard-page.component.scss']
})
export class WizardPageComponent implements OnInit {
  @Input() active = false;
  @Input() pageNumber;
  @Input() completed;

  constructor() { }

  ngOnInit() {
  }

}
