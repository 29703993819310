import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationService, NavigationActionItem } from '../../../services/navigation.service';
import { Select, Store } from '@ngxs/store';
import { NotificationState } from '../../../stores/notification.state';
import { Observable, Subscription } from 'rxjs';
import { LoadNotificationsAction, LoadNotificationCountAction } from '../../../stores/notification.actions';
import { filter } from 'rxjs/operators';
import { RouterState } from '@ngxs/router-plugin';

@Component({
  selector: 'app-navigation-action',
  templateUrl: './navigation-action.component.html',
  styleUrls: ['./navigation-action.component.scss']
})
export class NavigationActionComponent implements OnInit, OnDestroy {
  @Input() action: NavigationActionItem;
  @Select(NotificationState.getNotificationCount) notificationCount$: Observable<number>;

  isActiveNavigation = false;
  wasActiveRoute = false;
  routerEvents: Subscription;
  activeSelectionSub: Subscription;

  constructor(private navigation: NavigationService, private router: Router, private store: Store) { }

  ngOnInit() {
    this.routerEvents = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
    ).subscribe(() => this.setActiveNavigation());
    this.activeSelectionSub = this.navigation.activeSelection.subscribe(
      (action: NavigationActionItem) => {
        if (action && action.route !== this.action.route) {
          this.isActiveNavigation = false;
        }
        if (!action && this.wasActiveRoute || action && action.route === this.action.route) {
          this.isActiveNavigation = true;
        } else {
          this.isActiveNavigation = false;
        }
      }
    );

    this.setActiveNavigation();
  }

  ngOnDestroy(): void {
    this.activeSelectionSub.unsubscribe();
  }

  private setActiveNavigation(): void {
    const url: string = this.store.selectSnapshot(RouterState.url).split('?')[0];
    if (this.action?.route && (url === '/org' && this.action.title === 'Account' || this.navigation.isMatchedRouteBySegments(url, this.action.route))) {
      this.isActiveNavigation = true;
      this.wasActiveRoute = true;
    } else {
      this.isActiveNavigation = false;
      this.wasActiveRoute = false;
    }
  }

  navigate(event: Event, action: NavigationActionItem) {
    event.preventDefault();
    this.navigation.collapseBoth();
    if (this.action.title === 'Notifications') {
      this.store.dispatch([
        new LoadNotificationCountAction(),
        new LoadNotificationsAction()
      ]);
      this.navigation.expandSelection(action);
    } else if (action.selections && action.selections.length > 0) {
      this.navigation.expandSelection(action);
    } else if (action.route) {
      this.router.navigateByUrl(action.route);
    }
  }
}
