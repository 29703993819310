<div class="navigation-actions">
  <div class="logos">
    <div class="wrap">
      <div class="icon" (click)="goHome()">
        <i></i>
      </div>
      <div class="text">
        <i></i>
      </div>
    </div>
  </div>

  <div class="upper navigation-action-list" *ngIf="(organization$ | async) as organization">
    <ng-container *ngIf="organization.type.toLowerCase() !== 'vendor'">
      <div class="header">
        <div class="header-icon">
        </div>
        <span>Dashboards</span>
      </div>

      <!-- Non LSP actions -->
      <ng-container
        *ngIf="organization.type.toLowerCase() !== 'logistics' && organization.type.toLowerCase() !== 'dray carrier'">
        <app-navigation-logistics-action *ngFor="let action of dashboardActions" [action]="action">
        </app-navigation-logistics-action>
      </ng-container>

      <!-- LSP Actions -->
      <ng-container *ngIf="organization.type.toLowerCase() === 'logistics'">
        <ng-container *ngFor="let action of lspDashboardActions">
          <app-navigation-logistics-action [action]="action"></app-navigation-logistics-action>
        </ng-container>
        <!-- Chassis Management Link -->
        <app-navigation-logistics-action [action]="chassisManagementActionItem" (click)="goToChassisManagement()"></app-navigation-logistics-action>
      </ng-container>

      <!-- Dray Carrier actions -->
      <ng-container *ngIf="organization.type.toLowerCase() === 'dray carrier'">
        <app-navigation-logistics-action *ngFor="let action of drayCarrierDashboardActions" [action]="action">
        </app-navigation-logistics-action>
      </ng-container>

      <div class="spacer"></div>
    </ng-container>


    <div class="header">
      <div class="header-icon">
      </div>
      <span>Visibility</span>
    </div>
    <!-- Non Dray Carrier -->
    <ng-container *ngIf="organization.type.toLowerCase() !== 'dray carrier'">
      <app-navigation-logistics-action *ngFor="let action of visibilityActions" [action]="action">
      </app-navigation-logistics-action>
    </ng-container>
    <!-- Dray Carrier -->
    <ng-container *ngIf="organization.type.toLowerCase() === 'dray carrier'">
      <app-navigation-logistics-action *ngFor="let action of drayCarrierVisibilityActions" [action]="action">
      </app-navigation-logistics-action>
    </ng-container>
    <div class="spacer"></div>



    <ng-container
      *ngIf="organization.type.toLowerCase() !== 'customer' && organization.type.toLowerCase() !== 'vendor' && organization.type.toLowerCase() !== 'dray carrier'">
      <div class="header">
        <div class="header-icon">
        </div>
        <span>Execution</span>
      </div>
      <app-navigation-logistics-action *ngFor="let action of executionActions" [action]="action">
      </app-navigation-logistics-action>

      <div class="spacer"></div>
    </ng-container>

    <ng-container *ngIf="organization.type.toLowerCase() === 'customer'">
      <div class="header">
        <div class="header-icon">
        </div>
        <span>Execution</span>
      </div>
      <app-navigation-logistics-action *ngFor="let action of customerExecutionActions" [action]="action">
      </app-navigation-logistics-action>

      <div class="spacer"></div>
    </ng-container>

    <ng-container *ngIf="organization.name === 'E*Dray'">
      <div class="header">
        <div class="header-icon">
        </div>
        <span>Administration</span>
      </div>
      <app-navigation-logistics-action *ngFor="let action of adminActions" [action]="action">
      </app-navigation-logistics-action>
    </ng-container>
    <div class="spacer"></div>
  </div>
  <div class="flex-fill"></div>

  <div class="lower navigation-action-list">
    <ng-container *ngFor="let upperActionItem of upperNavigationActionItems">
      <app-navigation-logistics-action *ngIf="upperActionItem.title === 'Lifecycle Management'"
        [action]="upperActionItem"></app-navigation-logistics-action>
    </ng-container>
    <ng-container *ngFor="let lowerActionItem of lowerNavigationActionItems">
      <app-navigation-logistics-action *ngIf="lowerActionItem.title === 'Admin'" [action]="lowerActionItem">
      </app-navigation-logistics-action>
    </ng-container>
    <app-navigation-logistics-action [action]="accountNavigationActionItem"></app-navigation-logistics-action>

    <div class="footer">
      <span>Powered by</span>
      <img src="../../../../../assets/logos/syndic8.png">
    </div>
  </div>
</div>