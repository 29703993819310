import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shadow-button',
  templateUrl: './shadow-button.component.html',
  styleUrls: ['./shadow-button.component.scss']
})
export class ShadowButtonComponent implements OnInit {
  @Input() icon = 'circle';
  @Input() color = 'light-blue';
  @Input() tooltip;
  constructor() { }

  ngOnInit() {
  }

}
