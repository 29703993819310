import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AcceptUserTermsAction } from '@app/core/stores/auth.actions';
import { mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ExceptionService } from '@app/core/services/exception.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';

@Component({
  selector: 'app-terms-and-privacy',
  templateUrl: './terms-and-privacy.component.html',
  styleUrls: ['./terms-and-privacy.component.scss']
})
export class TermsAndPrivacyComponent implements OnInit {

  private privacyUrl = `${environment.tokenNamespace}/privacy/`;
  private termsURL = `${environment.tokenNamespace}/terms/`;
  private cancelUrl = `${environment.tokenNamespace}/`;

  privacyRead = false;
  termsRead = false;

  constructor(private router: Router, private store: Store, private exception: ExceptionService) { }

  ngOnInit() {
  }

  viewPrivacyPolicy() {
    window.open(this.privacyUrl, '_blank');
    this.privacyRead = true;
  }

  viewTerms() {
    window.open(this.termsURL, '_blank');
    this.termsRead = true;
  }

  continueToApp() {
    if (!this.privacyRead || !this.termsRead) {
      return;
    }
    this.store.dispatch(new AcceptUserTermsAction()).pipe(
      mergeMap(() => this.router.navigate(['/'], { queryParams: { default: true } })),
      catchError(
        (error: HttpErrorResponse) => {
          this.exception.add({
            name: 'TermsAndPrivacyComponent',
            info: 'The server is having trouble recording your acceptance',
            error: JSON.stringify(error)
          });
          return of(this.router.navigate(['/'], { queryParams: { default: true } }));
        }
      )
    ).subscribe();
  }

  cancel() {
    window.location.href = this.cancelUrl;
  }
}
