import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {AuthState, DataProcessField} from "@app/core";
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup
} from "@angular/forms";
import {cloneDeep} from "lodash-es";
import {Select} from "@ngxs/store";
import {Observable} from "rxjs";

@Component({
  selector: 'app-template-field-edit',
  templateUrl: './template-field-edit.component.html',
  styleUrls: ['./template-field-edit.component.scss']
})
export class TemplateFieldEditComponent {
  @HostBinding('class.open') @Input() open = false;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();
  @Input() SaveField: ({ field }: { field: DataProcessField })  => void;

  @Select(AuthState.getTokenMsLevel) userTokenMsLevel$: Observable<number>;

  tabNumber: number = 1;

  jsonTextControlForm: UntypedFormControl = new UntypedFormControl('');
  fieldForm: FormGroup = new FormGroup({
    aiGenerated: new FormControl<boolean>(false),
    // Java BigDecimal on BE
    aiPercent: new FormControl<number>(null),
    // aliasName: new FormControl(field.aliasName), // Array<String>
    columnOutput: new FormControl<number>(0),
    columnWidth: new FormControl<number>(0),
    comments: new FormControl<string>(null),
    // convert: new FormControl(field.convert), // Array<String>
    dataType: new FormControl<string>(null),
    defaultValue: new FormControl<string>(null),
    definition: new FormControl<string>(null),
    example: new FormControl<string>(null),
    fieldEnd: new FormControl<number>(0),
    fieldEndMatch: new FormControl<string>(null),
    fieldEndOperator: new FormControl<string>(null),
    fieldStart: new FormControl<number>(0),
    fieldStartMatch: new FormControl<string>('COLUMNNAME:NOCOLUMN'),
    fieldStartOperator: new FormControl<string>(null),
    format: new FormControl<string>(null),
    inactive: new FormControl<boolean>(false),
    lineNumber: new FormControl<number>(0),
    lineOffset: new FormControl<string>(''),
    matchLine: new FormControl<string>(null),
    matchOperator: new FormControl<string>(null),
    maxCharacterLength: new FormControl<number>(0),
    minCharacterLength: new FormControl<number>(0),
    name: new FormControl<string>(''),
    newField: new FormControl<boolean>(false),
    outputName: new FormControl<string>(''),
    pageNumber: new FormControl<number>(0),
    parentName: new FormControl<string>(null),
    parentOverrideField: new FormControl<boolean>(false),
    recommendedDisplay: new FormControl<boolean>(false),
    required: new FormControl<boolean>(false),
    requiredBrandDisplay: new FormControl<boolean>(false),
    requiredConditionalDisplay: new FormControl<boolean>(false),
    requiredDisplay: new FormControl<boolean>(false),
    reqularExpression: new FormControl<string>(null),
    rowHeight: new FormControl<number>(0),
    saveEmpty: new FormControl<boolean>(false),
    rowOutput: new FormControl<number>(0),
    skipTrim: new FormControl<boolean>(false),
    skipXmlConversion: new FormControl<boolean>(false),
    // stackSize: new FormControl(field.stackSize),
    suggestedMatch: new FormControl<boolean>(false),
    // validate: new FormControl(field.validate), // Array<String>
    xmlAttribute: new FormControl<boolean>(false),
  });

  activeField: DataProcessField = null;


  setActiveField(field: DataProcessField) {
    console.log('setActiveField=>', field)
    this.activeField = cloneDeep(field);

    const formPatch = {
      aiGenerated: field.aiGenerated,
      aiPercent: field.aiPercent,
      // aliasName: field.aliasName, // Array<String>
      columnOutput: field.columnOutput,
      columnWidth: field.columnWidth,
      comments: field.comments,
      // convert: field.convert, // Array<String>
      dataType: field.dataType,
      defaultValue: field.defaultValue,
      definition: field.definition,
      example: field.example,
      fieldEnd: field.fieldEnd,
      fieldEndMatch: field.fieldEndMatch,
      fieldEndOperator: field.fieldEndOperator,
      fieldStart: field.fieldStart,
      fieldStartMatch: field.fieldStart,
      fieldStartOperator: field.fieldStartOperator,
      format: field.format,
      inactive: field.inactive,
      lineNumber: field.lineNumber,
      lineOffset: field.lineOffset,
      matchLine: field.matchLine,
      matchOperator: field.matchOperator,
      maxCharacterLength: field.maxCharacterLength,
      minCharacterLength: field.minCharacterLength,
      name: field.name,
      newField: field.newField,
      outputName: field.outputName,
      pageNumber: field.pageNumber,
      parentName: field.parentName,
      parentOverrideField: field.parentOverrideField,
      recommendedDisplay: field.recommendedDisplay,
      required: field.required,
      requiredBrandDisplay: field.requiredBrandDisplay,
      requiredConditionalDisplay: field.requiredConditionalDisplay,
      requiredDisplay: field.requiredDisplay,
      reqularExpression: field.reqularExpression,
      rowHeight: field.rowHeight,
      saveEmpty: field.saveEmpty,
      rowOutput: field.rowOutput,
      skipTrim: field.skipTrim,
      skipXmlConversion: field.skipXmlConversion,
      // stackSize: field.stackSize,
      suggestedMatch: field.suggestedMatch,
      // validate: field.validate, // Array<String>
      xmlAttribute: field.xmlAttribute,
    };

    this.fieldForm.patchValue(formPatch);
    this.jsonTextControlForm.setValue(JSON.stringify(field, null, '\t'))
  }

  editField() {
  }

  saveField() {
    if(this.tabNumber === 1) {
      const field = this.fieldForm.value;
      this.SaveField(field);
    } else if( this.tabNumber === 2) {
      const field = JSON.parse(this.jsonTextControlForm.value);
      this.SaveField(field);
    }
  }

  swapTabs(tab: number) {
    this.tabNumber = tab;
  }

  close(): void {
    this.open = false;
  }
}
