import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AmazonApiService, AmazonCallback, NotificationType } from '@app/core/api';
import { ExceptionService } from '@app/core/services/exception.service';
import { ToastService } from '@app/core/services/toast.service';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'app-amazon-callback',
  templateUrl: './amazon-callback.component.html',
  styleUrls: ['./amazon-callback.component.scss']
})
export class AmazonCallbackComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private exception: ExceptionService,
    private amazonApi: AmazonApiService,
    private toast: ToastService
  ) { }

  ngOnInit() {
    const { queryParams } = this.route.snapshot;
    if (!queryParams.selling_partner_id || !queryParams.spapi_oauth_code || !queryParams.state) {
      this.exception.add({
        name: 'AmazonCallbackComponent',
        info: 'Amazon callback missing query params',
        error: JSON.stringify(queryParams)
      });
      this.router.navigate(['/'], { queryParams: { default: true } });
    } else {
      this.amazonApi.consumeAmazonLwaRedirect(
        queryParams.mws_auth_token,
        queryParams.selling_partner_id,
        queryParams.spapi_oauth_code,
        queryParams.state
      ).pipe(
        tap(
          ({ orgId, subcatalogId }: AmazonCallback) => {
            this.toast.add({
              expiration: 5000,
              title: 'Success',
              message: 'Logged into Amazon',
              type: NotificationType.SUCCESS
            });
            this.router.navigateByUrl(`/org/${orgId}/channel/${subcatalogId}/product`);
          }
        ),
        catchError(
          (error: HttpErrorResponse) => {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to login to Amazon Seller Central',
              type: NotificationType.ERROR
            });
            this.router.navigate(['/'], { queryParams: { default: true } });
            return throwError(error);
          }
        )
      ).subscribe();
    }
  }

}
