<div class="header">
  <app-button (click)="openChange.emit(false)">Close</app-button>
  <div class="flex-fill"></div>
  <div class="title">3rd Party Messages</div>
</div>
<div class="field">
  <div class="title">Message Type</div>
  <input [formControl]="form.get('type')" />
</div>
<div class="field">
  <div class="title">Message Table</div>
  <input [formControl]="form.get('table')" />
</div>
<div class="field">
  <div class="title">Message Block</div>
  <app-suggestion [provided]="sheetSuggestions"
                  label="Select a Message Key" optionProp="id"
                  [form]="form" field="block"
                  [selected] ="[sheetSelected]"
                  (optionSelected)="sheetSelectedEvent($event)">
  </app-suggestion>
</div>
<div class="field">
  <div class="title">Message Key</div>
  <app-suggestion [provided]="internalFieldSuggestions"
    label="Select a Message Key" optionProp="id"
    [form]="form" field="key"
    [selected] ="[fieldSelected]">
  </app-suggestion>
</div>
<app-button class="save" (click)="save()" [disabled]="form.invalid" color="green">Save</app-button>
