export enum PropertyType {
    String = 1,
    Number = 2,
    Date = 3,
    Array = 4,
    Unrecognized = 5
}

export enum QueryOperator {
    Is = 1,
    IsNot = 2,
    IsLessThan = 3,
    IsMoreThan = 4,
    IsBetween = 5,
    Contains = 6,
    IsBefore = 7,
    IsAfter = 8
}

export interface Query {
    propertyName: string;
    operator: QueryOperator;
    value: any;
}
