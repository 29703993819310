import { State, Action, Selector, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import {
  LoadUserAction,
  LogoutUserAction,
  RefreshTokenAction,
  UpdateUserAction,
  AcceptUserTermsAction,
  LoadEmailUserAction,
  ResetTermsOfServiceAction,
  ReloadUserAction
} from './auth.actions';
import { User, UserApiService, OrgApiService, NotificationType, Organization, Suggestion } from '../api';

import { tap, catchError, mergeMap } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@env/environment';

import jwt_decode from 'jwt-decode';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { ToastService } from '../services/toast.service';
import { CookieService } from '@app/shared/services/cookie.service';
import { patch } from '@ngxs/store/operators';

export interface TokenData {
  'https://syndic8.io/email'?: string;
  'https://syndic8.io/org'?: Array<number>;
  'https://syndic8.io/roles'?: Array<string>;
  'https://syndic8.io/upn'?: string;
  'https://syndic8.io/groups'?: Array<string>;
  'https://syndic8.io/jti'?: string;
  'https://syndic8.io/appEnv'?: string;
  'https://syndic8.io/appEnvMeta'?: {
    do: number; // Default Organization
    ms: number;  // Maximum Security level
    os: { [key: string]: number }; // Organization Security level
  };
  'https://rapidmap.io/email'?: string;
  'https://rapidmap.io/org'?: Array<number>;
  'https://rapidmap.io/roles'?: Array<string>;
  'https://rapidmap.io/upn'?: string;
  'https://rapidmap.io/groups'?: Array<string>;
  'https://rapidmap.io/jti'?: string;
  'https://rapidmap.io/appEnv'?: string;
  'https://rapidmap.io/appEnvMeta'?: {
    do: number; // Default Organization
    ms: number;  // Maximum Security level
    os: { [key: string]: number }; // Organization Security level
  };
  iss: string;
  sub: string;
  aud: Array<string>;
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  permissions: Array<any>;
}

export interface AuthStateModel {
  user: User;
  token: string;
  tokenData: TokenData;
  loaded: boolean;
  loading: boolean;
}

const defaults: AuthStateModel = {
  user: null,
  token: '',
  tokenData: {
    'https://syndic8.io/email': '',
    'https://syndic8.io/org': [],
    'https://syndic8.io/roles': [],
    'https://syndic8.io/upn': '',
    'https://syndic8.io/groups': [],
    'https://syndic8.io/jti': '',
    'https://syndic8.io/appEnv': '',
    'https://syndic8.io/appEnvMeta': {
      do: -1, // Default Organization
      ms: -1, // Maximum Security level
      os: {}, // Organization Security level
    },
    'https://rapidmap.io/email': '',
    'https://rapidmap.io/org': [],
    'https://rapidmap.io/roles': [],
    'https://rapidmap.io/upn': '',
    'https://rapidmap.io/groups': [],
    'https://rapidmap.io/jti': '',
    'https://rapidmap.io/appEnv': '',
    'https://rapidmap.io/appEnvMeta': {
      do: -1, // Default Organization
      ms: -1, // Maximum Security level
      os: {}, // Organization Security level
    },
    iss: '',
    sub: '',
    aud: [],
    iat: -1,
    exp: -1,
    azp: '',
    scope: '',
    permissions: []
  },
  loaded: false,
  loading: false
};

@State<AuthStateModel>({
  name: 'auth',
  defaults
})
@Injectable()
export class AuthState {

  constructor(
    private orgApi: OrgApiService,
    private userApi: UserApiService,
    private auth: AuthService,
    private toast: ToastService,
    private cookie: CookieService
  ) { }

  @Selector()
  static getUser(state: AuthStateModel): User {
    return state.user;
  }

  @Selector()
  static getOrganizations(state: AuthStateModel): Array<Organization> {
    return state.user.organizations;
  }

  @Selector()
  static getMatrixDestinations(state: AuthStateModel): Array<Organization> {
    return state.user.organizations?.filter(o => o.matrixFieldName);
  }

  @Selector()
  static getOrganizationTypes(state: AuthStateModel): Array<string> {
    return [...new Set(state.user.organizations.map(o => o.type))].sort((a, b) => (a > b) ? 1 : -1);
  }

  @Selector()
  static getOrganizationSuggestions(state: AuthStateModel): Array<Suggestion> {
    return state.user.organizations.map(org => {
      return { id: org.id.toString(), name: org.name };
    });
  }

  @Selector()
  static getMatrixDestinationsSuggestions(state: AuthStateModel): Array<any> {
    return state.user.organizations?.filter(o => o.matrixFieldName).map(org => {
      return { id: org.id.toString(), name: org.name, matrixFieldName: org.matrixFieldName };
    });
  }


  @Selector()
  static getBrandSuggestions(state: AuthStateModel): Array<Suggestion> {
    return state.user.organizations?.filter(o => o.type?.toLowerCase() === 'brand').map(org => {
      return { id: org.id.toString(), name: org.name };
    });
  }

  @Selector()
  static getTokenData(state: AuthStateModel): TokenData {
    return state.tokenData;
  }

  @Selector()
  static getTokenRoles(state: AuthStateModel): Array<string> {
    return state.tokenData[`${environment.tokenNamespace}/roles`];
  }

  @Selector()
  static getToken(state: AuthStateModel): string {
    return state.token;
  }

  @Selector()
  static getTokenMsLevel(state: AuthStateModel): number {
    return state.tokenData[`${environment.tokenNamespace}/appEnvMeta`]?.ms || 0;
  }

  @Selector()
  static isLoading(state: AuthStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static isLoaded(state: AuthStateModel): boolean {
    return state.loaded;
  }

  @Selector()
  static isNotEmailUser(state: AuthStateModel): boolean {
    return state.tokenData.sub.startsWith('auth0|');
  }

  @Selector()
  static isSyndic8User(state: AuthStateModel): boolean {
    return state.user?.email?.toLowerCase().includes('@syndic8.io');
  }

  @Selector()
  static isEdrayUser(state: AuthStateModel): boolean {
    return state.user?.email?.toLowerCase().includes('@edraycpl.com');
  }

  @Selector()
  static getACSOrg(state: AuthStateModel): Organization {
    return state?.user?.organizations?.find(o => o?.name?.toLowerCase() === 'allport cargo services usa') || null;
  }

  @Selector()
  static getACSOrgId(state: AuthStateModel): number {
    return state?.user?.organizations?.find(o => o?.name?.toLowerCase() === 'allport cargo services usa')?.id || null;
  }

  @Selector()
  static getGTNexusOrg(state: AuthStateModel): Organization {
    return state?.user?.organizations?.find(o => o?.name?.toLowerCase() === 'gt nexus') || null;
  }

  @Selector()
  static getGTNexusOrgId(state: AuthStateModel): number {
    return state?.user?.organizations?.find(o => o?.name?.toLowerCase() === 'gt nexus')?.id || null;
  }

  @Selector()
  static isAppAmin(state: AuthStateModel): boolean {
    return state.user?.email?.toLowerCase() === 'app-admin@syndic8.io';
  }

  @Selector()
  static isEdrayCreatedUser(state: AuthStateModel): boolean {
    return state.user?.edrayUser;
  }

  @Action(LoadUserAction)
  loadUser(
    { patchState }: StateContext<AuthStateModel>,
    { payload: { token, tokenData } }: LoadUserAction
  ) {
    patchState({ loading: true });
    // this.cookie.set('authorization', token, 7); // Cookie for Image authorization header
    this.orgApi.configuration.credentials['openApi'] = token;
    // If the token has no default Organization, request orgId `-1`
    const defaultOrganization = tokenData[`${environment.tokenNamespace}/appEnvMeta`].do ? tokenData[`${environment.tokenNamespace}/appEnvMeta`].do : -1;
    return this.userApi.getUser(defaultOrganization).pipe(
      tap(
        (user: User) => {
          if (environment.analytics) {
            const { id, email, firstName, lastName } = user;
            const layer = { id, email, firstName, lastName, event: 'track_user' };
            window.dataLayer.push(layer);
          }
          patchState({ user, token, tokenData, loaded: true, loading: false });
        }
      ),
      catchError(error => {
        patchState({ loading: false });
        return throwError(error);
      })
    );
  }

  @Action(ReloadUserAction)
  reloadUser(
    { getState, dispatch }: StateContext<AuthStateModel>
  ) {
    const { token, tokenData } = getState();
    return dispatch(new LoadUserAction({ token, tokenData }));
  }

  @Action(LoadEmailUserAction)
  loadEmailUser(
    { patchState }: StateContext<AuthStateModel>,
    { payload: { token, tokenData } }: LoadEmailUserAction
  ) {

    // this.cookie.set('authorization', token, 7); // Cookie for Image authorization header
    this.orgApi.configuration.credentials['openApi'] = token;
    patchState({ token, tokenData, loaded: true });
  }

  @Action(UpdateUserAction)
  updateUser(
    { getState, patchState }: StateContext<AuthStateModel>,
    { payload: { user, organization } }: UpdateUserAction
  ) {
    return this.userApi.patchUser(organization.id, { ...getState().user, ...user }).pipe(
      tap(
        (user: User) => {
          patchState({ user });
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'User Updated',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Update User',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(RefreshTokenAction)
  refreshToken(
    { getState, patchState }: StateContext<AuthStateModel>
  ) {
    const { scope }: TokenData = getState().tokenData;
    const audience = environment.audience;
    return this.auth.getAccessTokenSilently({ authorizationParams: { ignoreCache: true, scope, audience }, cacheMode: "off" }).pipe(
      tap(
        (token: string) => {
          const tokenData: TokenData = jwt_decode(token);

          // this.cookie.set('authorization', token, 7); // Cookie for Image authorization header
          this.orgApi.configuration.credentials['openApi'] = token;
          patchState({ token, tokenData });
        }
      )
    );
  }

  @Action(AcceptUserTermsAction)
  acceptTerms(
    { getState, dispatch, setState }: StateContext<AuthStateModel>
  ) {
    const defaultOrgId = getState().tokenData[`${environment.tokenNamespace}/appEnvMeta`].do;
    if (!defaultOrgId) {
      return throwError('Missing default Organization');
    }
    const user: User = getState().user;
    return this.userApi.syndic8Tos(defaultOrgId, user.id).pipe(
      mergeMap(() => {
        const { tokenData, token } = getState();
        if (tokenData.sub.startsWith('auth0|')) {
          return dispatch(new LoadUserAction({ token, tokenData }));
        } else if (tokenData.sub.startsWith('email|')) {
          return dispatch(new LoadEmailUserAction({ token, tokenData }));
        }
      }),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Accept Terms',
            type: NotificationType.WARN
          });
          setState(patch({ user: patch({ termOfService: 'temp' }) }))
          return throwError(error);
        }
      )
    );
  }

  @Action(ResetTermsOfServiceAction)
  resetTermsOfService(
    { getState, patchState }: StateContext<AuthStateModel>,
    { payload: { organization } }: ResetTermsOfServiceAction
  ) {
    const user: User = {
      ...getState().user,
      termOfService: null
    };
    return this.userApi.patchUser(organization.id, user).pipe(
      tap(
        (user: User) => {
          patchState({ user });
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Terms of Service Reset',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Reset Terms of Service',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(LogoutUserAction)
  logout(
    { patchState }: StateContext<AuthStateModel>
  ) {
    patchState({ user: defaults.user, loaded: defaults.loaded });
    this.cookie.remove('authorization');
    this.auth.logout({ logoutParams: { returnTo: `${window.location.origin}` } });
  }
}
