<div (click)="insideClick = true" class="modal"
  [ngStyle]="{ 'width': _width, 'height': _height, 'padding': padding , 'flex-wrap': wrap , 'overflow': overflow, 'border-radius': border_radius}"
  [ngClass]="{ 'dark': darkTheme, 'red': dangerTheme}">
  <div class="close-modal" *ngIf="showClose" (click)="closeModal()">
    <i class="fal fa-times"></i>
  </div>
  <ng-content select="app-modal-head"></ng-content>
  <ng-content select="app-modal-body"></ng-content>
  <ng-content select="app-modal-foot"></ng-content>
  <ng-content></ng-content>
</div>