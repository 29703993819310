/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';
import { Location } from './location';


export interface CostRule { 
    calculateOn?: string;
    calculationType?: string;
    calendarType?: string;
    carrierScac?: string;
    chargeAmount?: number;
    containerType?: string;
    costRuleId?: number;
    createdDate?: ModifiedDate;
    endCalculation?: number;
    endDate?: string;
    lastUpdated?: ModifiedDate;
    locationDc?: Location;
    locationTerminal?: Location;
    orgRef?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    ruleDateMilestone?: string;
    startCalculation?: number;
    startDate?: string;
    stopClockAdder?: number;
    stopClockMilestone?: string;
    stopClockProvision?: boolean;
}

