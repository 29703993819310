import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

import { Store } from '@ngxs/store';
import { LogoutUserAction } from '../../stores/auth.actions';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(private store: Store) { }

  ngOnInit(): void {
    if (!environment.testing) {
      this.store.dispatch(new LogoutUserAction());
    }
  }

}
