import { Component, OnInit, Input, HostBinding, OnDestroy } from '@angular/core';
import { NavigationService, NavigationSelectionItem, NavigationActionItem } from '../../../services/navigation.service';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { User, Organization } from '@app/core/api';
import { AuthState } from '@app/core/stores/auth.state';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { first } from "rxjs/operators";

@Component({
  selector: 'app-selection-list',
  templateUrl: './selection-list.component.html',
  styleUrls: ['./selection-list.component.scss']
})
export class SelectionListComponent implements OnInit, OnDestroy {
  @HostBinding('class.show') show = false;
  @HostBinding('class.acquia') acquiaShow = false;


  @Input() action: NavigationSelectionItem;

  @Select(AuthState.getUser) user$: Observable<User>;
  @Select(OrganizationState.getOrganization) organization$: Observable<Organization>;
  @Select(OrganizationState.isInriverServiceType) isInriver$: Observable<boolean>;
  @Select(OrganizationState.isAcquiaServiceType) isAcquia$: Observable<boolean>;
  @Select(OrganizationState.isFreightForwardOrganization) isFreightForwardOrganization$: Observable<boolean>;

  activeSelectionSub: Subscription;
  isAcquiaSub: Subscription;

  constructor(private navigation: NavigationService) { }

  ngOnInit() {
    this.isAcquiaSub = this.isAcquia$.subscribe((e) => { this.acquiaShow = e });
    this.activeSelectionSub = this.navigation.activeSelection.subscribe(
      (action: NavigationActionItem) => {

        if (!action || this.action?.route !== action?.route) {
          this.show = false;
        } else {
          this.show = true;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.activeSelectionSub.unsubscribe();
    this.isAcquiaSub.unsubscribe();
  }

}
