import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InfiniteScrollLayout } from '../../services/infinite-scroll.service';

@Component({
  selector: 'app-layout-select',
  templateUrl: './layout-select.component.html',
  styleUrls: ['./layout-select.component.scss']
})
export class LayoutSelectComponent implements OnInit {
  @Input() layout: InfiniteScrollLayout = InfiniteScrollLayout.Grid;
  @Output() layoutChange: EventEmitter<InfiniteScrollLayout> = new EventEmitter();

  InfiniteScrollLayout = InfiniteScrollLayout;

  constructor() { }

  ngOnInit(): void {
  }

  gridView(): void {
    this.layout = this.InfiniteScrollLayout.Grid;
    this.layoutChange.emit(InfiniteScrollLayout.Grid);
  }

  listView(): void {
    this.layout = this.InfiniteScrollLayout.List;
    this.layoutChange.emit(InfiniteScrollLayout.List);
  }
}
