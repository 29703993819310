<div class="cell handle" *ngIf="isEditing && draggable" [ngClass]="{'required': isFieldRequired}">
  <ng-content></ng-content>
</div>
<ng-container *ngFor="let column of columns; trackBy: trackByFn">
  <div *ngIf="column.type === TemplateColumnType.Action && isEditing" class="cell action" [ngClass]="{'required': isFieldRequired}">
    <app-circle-button icon="trash" color="red" (click)="remove.emit(field)" title="Remove"></app-circle-button>
    <div class="insert">
      <i class="fas fa-angle-up up" (click)="insertRow.emit(index)" title="Insert row above"></i>
      <i class="fas fa-plus add"></i>
      <i class="fas fa-angle-down down" (click)="insertRow.emit(index + 1)" title="Insert row below"></i>
    </div>
  </div>
  <div *ngIf="column.type === TemplateColumnType.Icon" class="cell icon" [ngClass]="{'required': isFieldRequired}">
    <i class="fas fa-check-circle green" *ngIf="columnSelected(field)"></i>
    <i class="fas fa-times-circle red" *ngIf="!columnSelected(field)"></i>
  </div>
  <div *ngIf="column.type === TemplateColumnType.Parent" class="cell parent" [ngClass]="{'required': isFieldRequired}">
    <app-checkbox shape="square" [selected]="form.get('parentOverrideField').value"
      (selectedChange)="setParentOverrideField($event)" [disabled]="!isEditing"></app-checkbox>
  </div>

  <div *ngIf="column.type === TemplateColumnType.Name" class="cell field sticky-name" [ngClass]="{'required': isFieldRequired}">
    <ng-container *ngIf="viewAs === TemplateViewAs.Syndic8">
      <input [placeholder]="destination?.name + ' Field name'" [formControl]="form.get('name')" *ngIf="isEditing"
        [attr.disabled]="disabled ? '' : null" (ngModelChange)="nameChange()"
        (blur)="nameChange(); blur.emit($event)" />
      <div *ngIf="!isEditing">{{ field?.name }}</div>
    </ng-container>
    <ng-container *ngIf="viewAs === TemplateViewAs.AllPortCargoServicesInbound">
      <ng-container
        *ngIf="templateOutputType === 'XML' || templateOutputType === 'EDI' || templateOutputType === 'JSON'; else notInboundNameXml">
        <input [formControl]="form.get('name')" placeholder="Enter an Inbound field"
          [attr.disabled]="disabled ? '' : null" (blur)="blur.emit($event)" />
      </ng-container>
      <ng-template #notInboundNameXml>
        <div>{{ field?.name }}</div>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="viewAs === TemplateViewAs.AllPortCargoServicesOutbound">
      <ng-container
        *ngIf="templateOutputType === 'XML' || templateOutputType === 'EDI' || templateOutputType === 'JSON'; else notOutboundNameXml">
        <input [formControl]="form.get('name')" placeholder="Enter an Outbound field"
          [attr.disabled]="disabled ? '' : null" (blur)="blur.emit($event)" />
      </ng-container>
      <ng-template #notOutboundNameXml>
        <div>{{ field?.name }}</div>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="viewAs === TemplateViewAs.Syndic8Inbound">
      <ng-container
        *ngIf="templateOutputType === 'XML' || templateOutputType === 'EDI' || templateOutputType === 'JSON'; else notInboundNameXml">
        <input [formControl]="form.get('name')" placeholder="Enter an Inbound field"
          [attr.disabled]="disabled ? '' : null" (blur)="blur.emit($event)" />
      </ng-container>
      <ng-template #notInboundNameXml>
        <div>{{ field?.name }}</div>
      </ng-template>
    </ng-container>
  </div>

  <div *ngIf="column.type === TemplateColumnType.JsonPath" class="cell arrow" [ngClass]="{'required': isFieldRequired}">
      <app-circle-button icon="wave-square" color="green" label="" *ngIf="isEditing" tooltip="Json Path Selector"
                         (click)="editJsonPath.emit(form.value)" [disabled]="disabled"></app-circle-button>
  </div>

  <div *ngIf="column.type === TemplateColumnType.Arrow" class="cell arrow sticky-arrow" [ngClass]="{
  'required': isFieldRequired,
  'left-shift' : hasJsonSelector}">
    <i class="far fa-long-arrow-right"></i>
  </div>
  <div *ngIf="column.type === TemplateColumnType.Required" class="cell checkbox" [ngClass]="{'required': isFieldRequired}">
    <div class="checkboxes" *ngIf="isEditing">
      <div class="chckbx">
        <div class="title" title="Required">Required</div>
        <div class="flex-fill"></div>
        <app-checkbox color="blue" [radio]="true" [disabled]="disabled" [selected]="form.get('requiredDisplay').value"
          (selectedChange)="setRequired(form)">
        </app-checkbox>
      </div>
      <div class="chckbx">
        <div class="title" title="Conditionally Required">Conditionally Required</div>
        <div class="flex-fill"></div>
        <app-checkbox color="blue" [radio]="true" [disabled]="disabled"
          [selected]="!form.get('recommendedDisplay').value && !form.get('requiredDisplay').value"
          (selectedChange)="setConditionallyRequired(form)">
        </app-checkbox>
      </div>
      <div class="chckbx">
        <div class="title" title="Recommended">Recommended</div>
        <div class="flex-fill"></div>
        <app-checkbox color="blue" [radio]="true" [disabled]="disabled"
          [selected]="form.get('recommendedDisplay').value" (selectedChange)="setRecommended(form)">
        </app-checkbox>
      </div>
    </div>
    <div class="checkboxes" *ngIf="!isEditing">
      <div class="chckbx">
        <div class="title" title="Required">Required</div>
        <div class="flex-fill"></div>
        <app-checkbox [disabled]="true" color="blue" [selected]="field?.requiredDisplay">
        </app-checkbox>
      </div>
      <div class="chckbx">
        <div class="title" title="Conditionally Required">Conditionally Required</div>
        <div class="flex-fill"></div>
        <app-checkbox [disabled]="true" color="blue" [selected]="!field?.recommendedDisplay && !field?.requiredDisplay">
        </app-checkbox>
      </div>
      <div class="chckbx">
        <div class="title" title="Recommended">Recommended</div>
        <div class="flex-fill"></div>
        <app-checkbox [disabled]="true" color="blue" [selected]="field?.recommendedDisplay">
        </app-checkbox>
      </div>
      <app-tooltip *ngIf="field?.required && field?.requiredDisplay" style="position: absolute;left:1rem"
        [showIcon]="false"
        [text]="'This field is required by '+(destination?.name || 'the destinaion')+ ' and cannot be disabled'">
      </app-tooltip>
    </div>
  </div>
  <div *ngIf="column.type === TemplateColumnType.Length" class="cell length" [ngClass]="{'required': isFieldRequired}">
    <ng-container *ngIf="isEditing">
      <input class="min" placeholder="min" [formControl]="form.get('minCharacterLength')"
        [attr.disabled]="disabled ? '' : null" (blur)="blur.emit($event)" />
      <input class="max" placeholder="max" [formControl]="form.get('maxCharacterLength')"
        [attr.disabled]="disabled ? '' : null" (blur)="blur.emit($event)" />
    </ng-container>
    <ng-container *ngIf="!isEditing">
      <div class="min">Min: {{ field?.minCharacterLength }}</div>
      <div class="max">Max: {{ field?.maxCharacterLength }}</div>
    </ng-container>
  </div>
  <div *ngIf="column.type === TemplateColumnType.Default" class="cell default" [ngClass]="{'required': isFieldRequired}">
    <input placeholder="Default Value" [formControl]="form.get('defaultValue')" *ngIf="isEditing"
      [attr.disabled]="disabled ? '' : null" (blur)="blur.emit($event)" />
    <div *ngIf="!(isEditing)">{{ field?.defaultValue }}</div>
  </div>
  <div *ngIf="column.type === TemplateColumnType.Type" class="cell type" [ngClass]="{'required': isFieldRequired}">
<!--    Suggestions need to be very specifically set up as below or it will not save.. this component needs a future remake-->
    <app-suggestion 
      *ngIf="isEditing"
      [resetAfterNavigation]="false"
      label="Select a Data Type"
      optionProp="id"
      [selected]="[selectedDataType]"
      [showList]="true"
      [provided]="dataTypeSuggestions"
      (optionSelected)="setDataType($event)"
      [disabled]="disabled"
      (blur)="blur.emit($event)">
    </app-suggestion>

    <div *ngIf="!(isEditing)">{{ field?.dataType }}</div>
  </div>
  <div *ngIf="column.type === TemplateColumnType.Format" class="cell format" [ngClass]="{'required': isFieldRequired}">
    <input placeholder="Data format" [formControl]="form.get('format')" *ngIf="isEditing"
      [attr.disabled]="disabled ? '' : null" (blur)="blur.emit($event)" />
    <div *ngIf="!(isEditing)">{{ field?.format }}</div>
  </div>


  <div *ngIf="column.type === TemplateColumnType.Field" class="cell sticky-field field "
    [ngClass]="{ 'loading': syndic8FieldMatchLoading,
    'required': isFieldRequired }">
    <div class="parent-template" (mouseover)="showParentTemplate = true" (mouseout)="showParentTemplate = false">
      <div class="parent-tooltip">
        <div *ngIf="showParentTemplate && isEditing && field?.parentName" class="tooltip-body">{{
          field?.parentName.replace(".json", "") }}</div>
      </div>
    </div>
    <ng-container *ngIf="viewAs === TemplateViewAs.Syndic8">
      <app-spinner *ngIf="syndic8FieldMatchLoading" [smaller]="true"></app-spinner>
      <div class="AImatch" *ngIf="fieldType === FunctionType.ValidValue" [ngClass]="{ 'stretch': showAITooltip }">
        <div class="AItooltip">
          <div class="AIicon" (mouseover)="showAITooltip = true" (mouseout)="showAITooltip = false">
            <i class="fas fa-sparkles"></i>
          </div>
          <div class="body" *ngIf="showAITooltip">AI Valid Value Pairing</div>
        </div>
      </div>
      <div class="matched" *ngIf="form.get('suggestedMatch').value == true && fieldType !== FunctionType.ValidValue"
        [ngClass]="{ 'stretch': showTooltip }">
        <div class="tooltip">
          <div class="icon" (mouseover)="showTooltip = true" (mouseout)="showTooltip = false">
            <i class="fas fa-lightbulb"></i>
          </div>
          <div class="body" *ngIf="showTooltip">AI Suggested Field</div>
        </div>
      </div>
      <ng-container *ngIf="!syndic8FieldMatchLoading && isEditing">
        <input placeholder="Enter an internal field"
          *ngIf="templateInputType === 'XML' || templateInputType === 'EDI'; else notOutboundFieldXml"
          [ngModel]="fieldStartMatchModel" (ngModelChange)="setFieldStartMatch($event)"
          [attr.disabled]="disabled ? '' : null" (blur)="blur.emit($event)" />
        <ng-template #notOutboundFieldXml>
          <app-suggestion 
          label="Select an Internal Field" 
          [resetAfterNavigation]="false"
          [disabled]="disabled" 
          optionProp="id"
          [selected]="[selectedFieldStartMatch]" 
          [showList]="true" 
          [provided]="syndic8FieldSuggestions"
          (optionSelected)="setFieldStartMatch($event)" 
          (blur)="blur.emit($event)"
          (closed)="clearFieldStartMatch(); form.patchValue({ suggestedMatch: false });">
          </app-suggestion>
        </ng-template>
      </ng-container>
      <div *ngIf="!(isEditing)">{{ internalFieldSuggestionLookup(field?.fieldStartMatch, syndic8FieldSuggestions) }}
      </div>
    </ng-container>
    <ng-container *ngIf="viewAs === TemplateViewAs.AllPortCargoServicesInbound">
      <input placeholder="Enter an Inbound XPATH"
        *ngIf="templateInputType === 'XML' || templateInputType === 'EDI' || templateInputType === 'JSON'; else notInboundFieldXml"
        [ngModel]="fieldStartMatchModel" (ngModelChange)="setFieldStartMatch($event)"
        [attr.disabled]="disabled ? '' : null" (blur)="blur.emit($event)" />
      <ng-template #notInboundFieldXml>
        <app-suggestion label="Select an Inbound Field" 
        [disabled]="disabled" 
        optionProp="id"
        [resetAfterNavigation]="false"
        [selected]="[selectedFieldStartMatch]" 
        [showList]="true" 
        [provided]="acsFieldSuggestions"
        (optionSelected)="setFieldStartMatch($event)" 
        (blur)="blur.emit($event)" 
        (closed)="clearFieldStartMatch()">
        </app-suggestion>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="viewAs === TemplateViewAs.AllPortCargoServicesOutbound">
      <input placeholder="Enter an Outbound XPATH"
        *ngIf="templateInputType === 'XML' || templateInputType === 'EDI' || templateInputType === 'JSON'; else notOutboundFieldXml"
        [ngModel]="fieldStartMatchModel" (ngModelChange)="setFieldStartMatch($event)"
        [attr.disabled]="disabled ? '' : null" (blur)="blur.emit($event)" />
      <ng-template #notOutboundFieldXml>
        <app-suggestion 
          label="Select an Outbound Field" 
          [resetAfterNavigation]="false"
          [disabled]="disabled" 
          optionProp="id"
          [selected]="[selectedFieldStartMatch]" 
          [showList]="true" 
          [provided]="syndic8FieldSuggestions"
          (optionSelected)="setFieldStartMatch($event)" 
          (blur)="blur.emit($event)" 
          (closed)="clearFieldStartMatch()">
        </app-suggestion>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="viewAs === TemplateViewAs.Syndic8Inbound">
      <input [placeholder]="getOutboundInputMessage(templateInputType)"
        *ngIf="templateInputType === 'XML' || templateInputType === 'EDI' || templateInputType === 'JSON'; else notOutboundFieldXml"
        [ngModel]="fieldStartMatchModel" (ngModelChange)="setFieldStartMatch($event)"
        [attr.disabled]="disabled ? '' : null" (blur)="blur.emit($event)" />
      <ng-template #notOutboundFieldXml>
        <app-suggestion 
          label="Select an Outbound Field" 
          [resetAfterNavigation]="false"
          [disabled]="disabled" 
          optionProp="id"
          [selected]="[selectedFieldStartMatch]" 
          [showList]="true" 
          [provided]="acsFieldSuggestions"
          (optionSelected)="setFieldStartMatch($event)" 
          (blur)="blur.emit($event)" 
          (closed)="clearFieldStartMatch()">
        </app-suggestion>
      </ng-template>
    </ng-container>
  </div>


  <div *ngIf="column.type === TemplateColumnType.Comment" class="cell comment" [ngClass]="{'required': isFieldRequired}">
    <app-circle-button icon="comment" color="green" label="Edit" *ngIf="isEditing"
      (click)="editComment.emit(form.value)" [disabled]="disabled"></app-circle-button>
    <div class="comments">{{ form.get('comments').value }}</div>
  </div>
  <div *ngIf="column.type === TemplateColumnType.Example" class="cell comment" [ngClass]="{'required': isFieldRequired}">
    <app-circle-button icon="comment" color="green" label="Edit" *ngIf="isEditing"
      (click)="editExample.emit(form.value)" [disabled]="disabled"></app-circle-button>
    <div class="comments">{{ form.get('example').value }}</div>
  </div>
  <div *ngIf="column.type === TemplateColumnType.Definition" class="cell comment" [ngClass]="{'required': isFieldRequired}">
    <app-circle-button icon="comment" color="green" label="Edit" *ngIf="isEditing"
      (click)="editDefinition.emit(form.value)" [disabled]="disabled"></app-circle-button>
    <div class="comments">{{ form.get('definition').value }}</div>
  </div>
  <div *ngIf="column.type === TemplateColumnType.Edit" class="cell icon" [ngClass]="{'required': isFieldRequired}">
    <div *ngIf="isEditing" (click)="editFieldBlock.emit(form.value)">
      <i class="tw-font-extrabold tw-cursor-pointer fas fa-ellipsis-v"></i>
    </div>
  </div>
  <div *ngIf="column.type === TemplateColumnType.Function" class="cell function"
    [ngStyle]="{ 'overflow': column.width === 'auto' ? null : 'hidden' }" [ngClass]="{'required': isFieldRequired }">
    <app-circle-button icon="pencil" color="blue" label="Edit" *ngIf="isEditing" (click)="editFunction.emit(form.value)"
      [disabled]="disabled"></app-circle-button>
    <ng-container *ngIf="fieldType === FunctionType.Concat">
      <div class="function-name">Concat:</div>
      <app-concatenation [suggestions]="syndic8FieldSuggestions" [concatenation]="getConvert(form.value)">
      </app-concatenation>
      <ng-container *ngIf="getConcatCase(form.value) as concatCase">
        <div class="function-name">Case:</div>
        <div class="function-case">{{ concatCase }}</div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.ConcatDelimiter">
      <div class="function-name">Concat Delimiter:</div>
      <app-concatenation [suggestions]="syndic8FieldSuggestions" [functionType]="FunctionType.ConcatDelimiter"
        [concatenation]="getConcatDelimiter(form.value)"></app-concatenation>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.ValidValue">
      <div class="function-name">Valid Value:</div>
      <app-destination-chip [backgroundColor]="'#845bcb'" [color]="'white'"
        [chip]="{ value: getFunctionValidValueDisplay(form.value, syndic8FieldSuggestions), type: ChipType.Variable }">
      </app-destination-chip>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.Image">
      <div class="function-name">Image:</div>
      <app-destination-chip [chip]="{ value: getFunctionValue(form.value, fieldType), type: ChipType.Variable }">
      </app-destination-chip>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.ImageThumbnail">
      <div class="function-name">Image Thumbnail:</div>
      <app-destination-chip [chip]="{ value: getFunctionValue(form.value, fieldType), type: ChipType.Variable }">
      </app-destination-chip>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.FormatMarketingDescription">
      <div class="function-name">Description Format:</div>
      <app-destination-chip [chip]="{ value: getFunctionValue(form.value, fieldType), type: ChipType.Variable }">
      </app-destination-chip>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.ImageList">
      <div class="function-name">Image List:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.ImageListRow">
      <div class="function-name">Image List Row:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.ImageListPosition">
      <div class="function-name">Image List Position:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.ImageMultiList">
      <div class="function-name">Image Multi-List:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.TodayDateTime">
      <div class="function-name">Current Date Time:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.ToLower">
      <div class="function-name">To Lower:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.ToUpper">
      <div class="function-name">To Upper:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.ToProper">
      <div class="function-name">To Proper:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.ReferenceTable">
      <div class="function-name">Reference Table:</div>
      <app-reference-table [convert]="getConvert(form.value)"></app-reference-table>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.IfThenElse">
      <div class="function-name">If Then Else:</div>
      <app-if-then-else [convert]="getConvert(form.value)"></app-if-then-else>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.Substr">
      <div class="function-name">Substr:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.XMLPartyInfo">
      <div class="function-name">XML Party Info:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.XMLTypeValue">
      <div class="function-name">XML Type Value:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.XMLDimensionInfo">
      <div class="function-name">XML Dimension Info:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.XMLShipWindow">
      <div class="function-name">XML Ship Window:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.XMLHarmonizedCodeInfo">
      <div class="function-name">XML Harmonized Code Info:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.Replace">
      <div class="function-name">Replace:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.MathAdd">
      <div class="function-name">Math Add:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.MathSubtract">
      <div class="function-name">Math Subtract:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.Language">
      <div class="function-name">Language:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.Pricing">
      <div class="function-name">Pricing:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="fieldType === FunctionType.Function">
      <div class="function-name">Function:</div>
      <app-destination-chip-list [chips]="getFunctionValueChips(form.value, fieldType)"></app-destination-chip-list>
    </ng-container>
    <ng-container *ngIf="!getFunctionType(form.value) && form.value?.convert?.length > 0">
      <i>Custom function: {{form.value?.convert | json}}</i>
    </ng-container>
    <ng-container *ngIf="!getFunctionType(form.value) && (form.value?.convert?.length === 0 || !form.value?.convert)">
      <i>None</i>
    </ng-container>
  </div>
</ng-container>
