import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Catalog, FilterJoin, FilterMatch, Organization, Suggestion, View, ViewApiService } from '@app/core';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { FunctionType } from '@app/shared/services/template.service';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ChipType } from '../destination-chip/destination-chip.component'
import { TemplateService } from '../../../services/template.service';

export interface ReferenceTableKey {
  name: string;
  value: string;
  isXPath: boolean;
}

@Component({
  selector: 'app-reference-table-form',
  templateUrl: './reference-table-form.component.html',
  styleUrls: ['./reference-table-form.component.scss']
})
export class ReferenceTableFormComponent implements OnInit {
  @Input() convert = '';
  @Output() convertChange: EventEmitter<string> = new EventEmitter();
  @Input() valid = false;
  @Output() validChange: EventEmitter<boolean> = new EventEmitter();
  @Input() provided: Array<Suggestion> = [];
  @Input() syndic8FieldSuggestions: Array<Suggestion> = [];
  @Input() destination: Catalog;

  convertValue = this.templateSvc.convertValue.bind(this.templateSvc);

  ruleSuggestions: Array<Suggestion> = [];
  keySuggestions: Array<Suggestion> = [];

  ChipType = ChipType;
  FilterMatch = FilterMatch;
  FilterJoin = FilterJoin;

  get con(): string {
    return this.convert ? this.convert.split(FunctionType.ReferenceTable)[1] : '';
  }

  get table(): string {
    return this.con ? this.con.split(';')[0] : '';
  }

  get rule(): string {
    return this.con ? this.con.split(';')[1] : '';
  }

  get keys(): Array<ReferenceTableKey> {
    return this.con ? this.con.split(';').slice(2).filter(c => c !== '=').map(i => {
      const split = i.split('=');
      const name = split && split[0] ? split[0] : '';
      const value = split && split[1] ? split[1] : '';
      const isXPath = value ? value.startsWith('$X{') : false;
      return { name, value, isXPath };
    }) : [];
  }

  get destinationId(): string {
    return this.destination.destinations[0].id.toString();
  }

  constructor(private viewApi: ViewApiService, private store: Store, private templateSvc: TemplateService) { }

  ngOnInit(): void {
  }

  private checkValidity(table: string, rule: string, keys: Array<ReferenceTableKey>): void {
    this.valid = !table || !rule || !keys || keys.length === 0;
    this.validChange.emit(this.valid);
  }

  private buildConvert(table: string = '', rule: string = '', keys: Array<ReferenceTableKey> = []): string {
    return `${table};${rule};${keys.map(k => `${k.name}=${k.value}`).join(';')}`;
  }

  selectTable(table: Suggestion): void {
    const tableName = table?.name;
    this.convertChange.emit(FunctionType.ReferenceTable +
      this.buildConvert(tableName, this.rule, this.keys)
    );
    this.checkValidity(tableName, this.rule, this.keys);
    this.loadTable(tableName);
  }

  private loadTable(tableName: string): void {
    if (!tableName) {
      return;
    }
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.viewApi.getViewByName(organization.id, tableName).pipe(
      tap((view: View) => {
        if (!view.viewFields) {
          return;
        }
        this.ruleSuggestions = view.viewFields.filter(field => !field.keyField).map(field => ({ id: field.internalName, name: field.displayName }));
        this.keySuggestions = view.viewFields.filter(field => field.keyField).map(field => ({ id: field.internalName, name: field.displayName }));
      }),
      catchError(error => {
        return of(error);
      })
    ).subscribe();
  }

  selectRule(rule: Suggestion): void {
    if (!rule || !rule.id) {
      return;
    }
    this.convertChange.emit(FunctionType.ReferenceTable +
      this.buildConvert(this.table, rule.id, this.keys)
    );
    this.checkValidity(this.table, rule.id, this.keys);
  }

  selectKey(key: ReferenceTableKey, v: string | Suggestion, index: number): void {
    let k = {...key, name: key.name.replace(/ /g, "_")}
    if (!k) {
      return;
    }
    const value = this.convertValue(k.isXPath, v);
    if (!value) {
      return;
    }
    const keys = [...this.keys.filter((k, i) => i !== index), { ...k, value }];
    this.convertChange.emit(FunctionType.ReferenceTable +
      this.buildConvert(this.table, this.rule, keys)
    );
    this.checkValidity(this.table, this.rule, keys);
  }

  removeKey(index: number): void {
    const keys = [...this.keys.filter((k, i) => i !== index)];
    this.convertChange.emit(FunctionType.ReferenceTable +
      this.buildConvert(this.table, this.rule, keys)
    );
    this.checkValidity(this.table, this.rule, keys);
  }

}
