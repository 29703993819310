<app-modal width="525" height="525" [open]="open" (openChange)="openChange.emit($event)" (close)="close()">
<app-modal-body style="overflow:hidden;">
    <div class="tw-flex tw-flex-col tw-h-full">
      <div *ngIf="(userTokenMsLevel$ | async) >= 70" class="tabs">
        <div class="tab" [ngClass]="{
            active: 1 === tabNumber}" (click)="swapTabs(1)"> Form Edit</div>
        <div *ngIf="true" class="tab" [ngClass]="{
            active: 2 === tabNumber}" (click)="swapTabs(2)"> Text Edit</div>
      </div>

      <ng-container *ngIf="tabNumber === 1">
          <div style="height:100%" class="tw-overflow-y-scroll tw-flex tw-flex-col">
            <form [formGroup]="fieldForm" (ngSubmit)="editField()">
              <div class="control">
                <div class="label">aiGenerated</div>
                <input id="aiGenerated" formControlName="aiGenerated" />
              </div>

              <div class="control">
                <div class="label">aiPercent</div>
                <input id="aiPercent" formControlName="aiPercent" />
              </div>

              <div class="control">
                <div class="label">columnOutput</div>
                <input id="columnOutput" formControlName="columnOutput" />
              </div>

              <div class="control">
                <div class="label">columnWidth</div>
                <input id="columnWidth" formControlName="columnWidth" />
              </div>

              <div class="control">
                <div class="label">comments</div>
                <input id="comments" formControlName="comments" />
              </div>

              <div class="control">
                <div class="label">dataType</div>
                <input id="dataType" formControlName="dataType" />
              </div>

              <div class="control">
                <div class="label">defaultValue</div>
                <input id="defaultValue" formControlName="defaultValue" />
              </div>

              <div class="control">
                <div class="label">definition</div>
                <input id="definition" formControlName="definition" />
              </div>

              <div class="control">
                <div class="label">example</div>
                <input id="example" formControlName="example" />
              </div>

              <div class="control">
                <div class="label">fieldEnd</div>
                <input id="fieldEnd" formControlName="fieldEnd" />
              </div>

              <div class="control">
                <div class="label">fieldEndMatch</div>
                <input id="fieldEndMatch" formControlName="fieldEndMatch" />
              </div>

              <div class="control">
                <div class="label">fieldEndOperator</div>
                <input id="fieldEndOperator" formControlName="fieldEndOperator" />
              </div>

              <div class="control">
                <div class="label">fieldStart</div>
                <input id="fieldStart" formControlName="fieldStart" />
              </div>

              <div class="control">
                <div class="label">fieldStartMatch</div>
                <input id="fieldStartMatch" formControlName="fieldStartMatch" />
              </div>

              <div class="control">
                <div class="label">fieldStartOperator</div>
                <input id="fieldStartOperator" formControlName="fieldStartOperator" />
              </div>

              <div class="control">
                <div class="label">format</div>
                <input id="format" formControlName="format" />
              </div>

              <div class="control">
                <div class="label">inactive</div>
                <input id="inactive" formControlName="inactive" />
              </div>

              <div class="control">
                <div class="label">lineNumber</div>
                <input id="lineNumber" formControlName="lineNumber" />
              </div>

              <div class="control">
                <div class="label">lineOffset</div>
                <input id="lineOffset" formControlName="lineOffset" />
              </div>

              <div class="control">
                <div class="label">matchLine</div>
                <input id="matchLine" formControlName="matchLine" />
              </div>

              <div class="control">
                <div class="label">matchOperator</div>
                <input id="matchOperator" formControlName="matchOperator" />
              </div>

              <div class="control">
                <div class="label">maxCharacterLength</div>
                <input id="maxCharacterLength" formControlName="maxCharacterLength" />
              </div>

              <div class="control">
                <div class="label">minCharacterLength</div>
                <input id="minCharacterLength" formControlName="minCharacterLength" />
              </div>

              <div class="control">
                <div class="label">name</div>
                <input id="name" formControlName="name" />
              </div>

              <div class="control">
                <div class="label">newField</div>
                <input id="newField" formControlName="newField" />
              </div>

              <div class="control">
                <div class="label">outputName</div>
                <input id="outputName" formControlName="outputName" />
              </div>

              <div class="control">
                <div class="label">pageNumber</div>
                <input id="pageNumber" formControlName="pageNumber" />
              </div>

              <div class="control">
                <div class="label">parentName</div>
                <input id="parentName" formControlName="parentName" />
              </div>

              <div class="control">
                <div class="label">parentOverrideField</div>
                <input id="parentOverrideField" formControlName="parentOverrideField" />
              </div>

              <div class="control">
                <div class="label">recommendedDisplay</div>
                <input id="recommendedDisplay" formControlName="recommendedDisplay" />
              </div>

              <div class="control">
                <div class="label">required</div>
                <input id="required" formControlName="required" />
              </div>

              <div class="control">
                <div class="label">requiredBrandDisplay</div>
                <input id="requiredBrandDisplay" formControlName="requiredBrandDisplay" />
              </div>

              <div class="control">
                <div class="label">requiredConditionalDisplay</div>
                <input id="requiredConditionalDisplay" formControlName="requiredConditionalDisplay" />
              </div>

              <div class="control">
                <div class="label">requiredDisplay</div>
                <input id="requiredDisplay" formControlName="requiredDisplay" />
              </div>

              <div class="control">
                <div class="label">reqularExpression</div>
                <input id="reqularExpression" formControlName="reqularExpression" />
              </div>

              <div class="control">
                <div class="label">rowHeight</div>
                <input id="rowHeight" formControlName="rowHeight" />
              </div>

              <div class="control">
                <div class="label">saveEmpty</div>
                <input id="saveEmpty" formControlName="saveEmpty" />
              </div>

              <div class="control">
                <div class="label">rowOutput</div>
                <input id="rowOutput" formControlName="rowOutput" />
              </div>

              <div class="control">
                <div class="label">skipTrim</div>
                <input id="skipTrim" formControlName="skipTrim" />
              </div>

              <div class="control">
                <div class="label">skipXmlConversion</div>
                <input id="skipXmlConversion" formControlName="skipXmlConversion" />
              </div>

              <div class="control">
                <div class="label">suggestedMatch</div>
                <input id="suggestedMatch" formControlName="suggestedMatch" />
              </div>

              <div class="control">
                <div class="label">xmlAttribute</div>
                <input id="xmlAttribute" formControlName="xmlAttribute" />
              </div>
            </form>
          </div>
      </ng-container>
      <ng-container *ngIf="tabNumber === 2 && (userTokenMsLevel$ | async) >= 70">
        <div class="tw-h-full tw-w-full">
          <textarea class="tw-bg-gray-100 tw-h-full tw-w-full" [formControl]="jsonTextControlForm"></textarea>
        </div>
      </ng-container>
    </div>
  </app-modal-body>
  <app-modal-foot>
    <app-button color="red" (click)="openChange.emit(false)">Cancel</app-button>
    <div class="flex-fill"></div>
    <app-button (click)="saveField()">Save</app-button>
  </app-modal-foot>
</app-modal>
