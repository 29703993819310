import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() color = 'blue';

  @HostBinding('class.disabled') @Input() disabled = false;
  @HostBinding('class.grow') @Input() grow = false;

  constructor() { }

  ngOnInit() {
  }

}
