<div class="close-modal" (click)="closeModal()">
  <i class="fal fa-times"></i>
</div>
<div class="info">
  <!-- Image info -->
  <div class="image-container" *ngIf="image.uriPath">
    <ng-container *ngIf="image.imageType === 'Video'; else notVideo">
      <video [src]="image.uriPath" controls></video>
    </ng-container>
    <ng-template #notVideo>
      <img [src]="image.uriPath" />
    </ng-template>
  </div>
  <div class="attributes-container">
    <div class="block">
      <div class="label">File Name</div>
      <div class="value">{{ image.uriName }}</div>
      <div class="loading" *ngIf="!image.uriName">
        <div class="message">-</div>
      </div>
    </div>

    <div class="block">
      <div class="label">Hero</div>
      <div class="value">{{ image.hero ? 'Yes' : 'No'}}</div>
      <div class="loading" *ngIf="!image.hero">
        <div class="message">-</div>
      </div>
    </div>

    <div class="block">
      <div class="label">Height</div>
      <div class="value">{{ image.height }}</div>
      <div class="loading" *ngIf="!image.height">
        <div class="message">-</div>
      </div>
    </div>

    <div class="block">
      <div class="label">Width</div>
      <div class="value">{{ image.width }}</div>
      <div class="loading" *ngIf="!image.width">
        <div class="message">-</div>
      </div>
    </div>

    <div class="block">
      <div class="label">Horizontal DPI</div>
      <div class="value" *ngIf="image.horizontalDPI !== -1">{{ image.horizontalDPI }}</div>
      <div class="value" *ngIf="image.horizontalDPI === -1"><i>Unable to parse horizontal DPI</i></div>
      <div class="loading" *ngIf="!image.horizontalDPI">
        <div class="message">-</div>
      </div>
    </div>

    <div class="block">
      <div class="label">Vertical DPI</div>
      <div class="value" *ngIf="image.verticalDPI !== -1">{{ image.verticalDPI }}</div>
      <div class="value" *ngIf="image.verticalDPI === -1"><i>Unable to parse vertical DPI</i></div>
      <div class="loading" *ngIf="!image.verticalDPI">
        <div class="message">-</div>
      </div>
    </div>

    <div class="block">
      <div class="label">Background Color</div>
      <div class="value">{{ image.backgroundColor }}</div>
      <div class="loading" *ngIf="!image.backgroundColor">
        <div class="message">-</div>
      </div>
    </div>

    <div class="block">
      <div class="label">Background Color Percentage</div>
      <div class="value" *ngIf="image.backgroundPercent">{{ image.backgroundPercent * 100 | number:'2.0-2' }}%</div>
      <div class="loading" *ngIf="!image.backgroundPercent">
        <div class="message">-</div>
      </div>
    </div>

    <div class="block">
      <div class="label">Media URL</div>
      <div class="value">
        <a [href]="image.uriPath" target="_blank">{{ image.uriPath }}</a>
      </div>
      <div class="loading" *ngIf="!image.uriPath">
        <div class="message">-</div>
      </div>
    </div>

    <div class="block">
      <div class="label">Media Group</div>
      <div class="value">{{ image.mediaGroup }}</div>
      <div class="loading" *ngIf="!image.mediaGroup">
        <div class="message">-</div>
      </div>
    </div>

    <div class="block">
      <div class="label">Publish Date</div>
      <div class="value">{{ image.publishDate | date:'mediumDate'}}</div>
      <div class="loading" *ngIf="!image.publishDate">
        <div class="message">-</div>
      </div>
    </div>


    <div class="block full-width" *ngIf="image.smartFilter?.filterEntries as filters">
      <div class="label">Smart Filter</div>
      <div class="value" *ngFor="let f of filters; first as first">
        <span class="filter-part" *ngIf="!first">{{ f.filterJoin }}</span>
        <span class="filter-part">{{ f.filterBy }}</span>
        <span class="filter-part">{{ f.filterMatch }}</span>
        <span class="filter-part">{{ f.filterValue }}</span>
      </div>
      <div class="loading" *ngIf="!image.uriPath">
        <div class="message">-</div>
      </div>
    </div>

    <div class="block full-width">
      <div class="label">Detected Colors </div>
      <div class="loading" *ngIf="!image.imageColors">
        <div class="message">-</div>
      </div>

      <div *ngIf="image.imageColors">
        <ng-container *ngIf="showAllColors; then allColors; else threeColors"></ng-container>

        <ng-template #threeColors>
          <div class="value">{{ (image.imageColors | beautifyComma | BeautifyCamelCase).split(",", 3) }}</div>
          <div class="color-toggle" (click)="showAllColors = true">Show all colors</div>
        </ng-template>

        <ng-template #allColors>
          <div class="value all-colors">{{ (image.imageColors | beautifyComma | BeautifyCamelCase) }}</div>
          <div class="color-toggle" (click)="showAllColors = false">Show dominant colors</div>
        </ng-template>
      </div>
    </div>

    <div class="block" *ngIf="image.propertyNames">
      <div class="message">-</div>
    </div>
  </div>
</div>