/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StatDataPoint } from './statDataPoint';


export interface ReleaseStat { 
    brandName?: string;
    catalogId?: number;
    catalogName?: string;
    categories?: Array<StatDataPoint>;
    date?: string;
    daysFromNow?: number;
    name?: string;
    totalApprovals?: number;
    totalErrors?: number;
    totalPasses?: number;
    totalWarnings?: number;
}

