import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { environment } from '@env/environment';
import { catchError, tap } from 'rxjs/operators';
import { PrometheusService } from '../services/prometheus.service';

@Injectable()
export class PrometheusInterceptor implements HttpInterceptor {

  constructor(private prom: PrometheusService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!environment.prometheusUrl || request.url === environment.prometheusUrl) {
      return next.handle(request);
    }
    this.prom.startApiRequest(request.url);
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.prom.apiSuccess(request.url)
        }
      }),
      catchError(
        (error: HttpErrorResponse) => {
          this.prom.apiError(error, request.url);
          return throwError(error);
        }
      )
    );
  }
}
