import { State, Action, Selector, StateContext, ofActionDispatched, Actions, Store, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';

import {
  AGGridHeader,
  Organization,
  NotificationType,
  Catalog,
  SubcatalogApiService,
  FilterMatch,
  ProductApiService,
  Product,
  CatalogExport,
  GroupBy,
  FilterJoin,
  Suggestion,
  FilterDefinition,
  FilterEntry,
  GridApiService,
  ImportApiService,
  SortEntry,
  OrderBy,
  PluginConfig,
  PluginsApiService,
  PluginConfigProperty,
  OrgApiService,
  OrganizationReference,
  Contact,
  ChannelApiService,
  Contract,
  ViewApiService,
  View,
  ViewField,
  ReportApiService,
  PluginStatus,
  PluginStatusError,
  ProductImportStatus,
  BulkUpdate,
  CreateSseAPIService,
  StreamToken,
  DestinationExport,
  APlusContentApiService,
  ConnectorApiService,
  Connection,
  SyndicationApiService
} from '@app/core/api';
import {
  ResetDestinationDetailAction,
  LoadDestinationAction,
  AppendDestinationProductsAction,
  DownloadDestinationExportAction,
  SyndicateDestinationWithFiltersAction,
  SyndicateDestinationWithIdsAction,
  StartDestinationExportWithFiltersAction,
  StartDestinationExportWithIdsAction,
  StartDestinationImageExportWithFiltersAction,
  StartDestinationImageExportWithIdsAction,
  DownloadDestinationImagesWithFiltersAction,
  DownloadDestinationImagesWithIdsAction,
  LoadDestinationExportsAction,
  SetDestinationProductsLoadingAction,
  ResetDestinationProductsAction,
  RemoveProductsFromDestinationWithIdsAction,
  LoadCollectionSuggestionsAction,
  AddCollectionsToDestinationAction,
  SetSyndicationsLoadingAction,
  AppendSyndicationsAction,
  LoadPluginConfigurationAction,
  ResetSyndicationsAction,
  RemoveProductsFromDestinationWithFiltersAction,
  RefreshSyndicationAction,
  StartFeedPushToPluginWithFiltersAction,
  StartFeedPushToPluginWithIdsAction,
  SavePluginConfigurationAction,
  LoadBrandSuggestionsAction,
  BulkUpdateProductsAction,
  ReloadSyndicationAction,
  LoadDestinationContactsAction,
  StartProductVerifyLiveExportWithIdsAction,
  StartProductVerifyLiveExportWithFiltersAction,
  LoadExportProductTemplateSuggestionsAction,
  LoadExportImageTemplateSuggestionsAction,
  GenerateLinesheetAction,
  LoadDestinationSyndicationAction,
  AppendDestinationSyndicationErrorsAction,
  SetDestinationSyndicationErrorsLoadingAction,
  ResetDestinationSyndicationErrorsAction,
  ShowPluginConfigModalAction,
  LoadPreflightColumnsAction,
  StartDestinationExportStreamWithIdsAction,
  StartDestinationExportStreamWithFiltersAction,
  LoadDestinationExportErrorsAction,
  CreateStreamTokenAction,
  ConnectToStreamAction,
  DownloadStreamUrlAction,
  UpdateStreamRowAction,
  LoadDestinationProductViewAction,
  LoadSyndic8FieldSuggestionsAction,
  DownloadSyndicationErrorsAction,
  SyndicatePreflightWithIdsAction,
  SyndicatePreflightWithFiltersAction,
  SendEmailSyndicationAction,
  LoadEmailHistoryAction,
  RecordStreamEventAction,
  UpdateSyndicationCommentAction,
  ScheduleFeedPushToPluginWithIdsAction,
  ScheduleFeedPushToPluginWithFiltersAction,
  CancelScheduledSyndicationAction,
  UpdateProductsTemplatesAction,
  RemoveProductsAction,
  LoadPreflightColumnsByTemplateNameAction,
  CreateSseStreamAction,
  ConnectToStreamAction2,
  StartDestinationExportStreamForProductsWithFiltersAction,
  LoadImagePreflightColumnsAction,
  StartDestinationExportStreamWithFiltersAction2,
  CancelSyndicationAction,
  LoadScheduledSyndications,
  CreateScheduledTask,
  UpdateScheduledTask,
  DeleteScheduledTask
} from './destination-detail.actions';
import { LoadOrganizationAction } from '@app/features/organization/store/organization.actions';
import { tap, catchError, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { of, from, throwError } from 'rxjs';
import { AuthState, LogoutUserAction, ToastService } from '@app/core';
import { OrganizationState, OrganizationStateModel } from '@app/features/organization/store/organization.state';
import { RouterStateParams } from '@app/core/providers/custom-router-state-serializer';
import { RouterState } from '@ngxs/router-plugin';
import { DownloadService, ApplicationService } from '@app/shared';
import { append, patch, updateItem } from '@ngxs/store/operators';
import * as moment from 'moment';
import { InfiniteScrollImage, InfiniteScrollChip, InfiniteScrollColor } from '@app/shared';
import { QueryFilter, QueryFilterMeta, QueryFilterType, SuggestionProperty } from '@app/shared/services/query-filter.service';
import { CellClassParams, ColDef, ColGroupDef, ITooltipParams } from '@ag-grid-community/core';
import { PreflightTooltip, PreflightTooltipComponent } from '../components/preflight-tooltip/preflight-tooltip.component';
import { MessageEventType, ServerSentEventService } from '@app/shared/services/server-sent-event.service';
import { environment } from '@env/environment';
import {
  PreflightCustomHeaderComponent
} from "@app/features/destination/components/preflight-custom-header/preflight-custom-header.component";
import { Router } from "@angular/router";


export type FileFormat = 'MINNETONKA' | 'NORDSTROM' | 'HSN' | 'QVC' | 'BBB' | 'BLUESTEM' | 'DILLARDS' | 'MACYS' | 'AMAZON_VENDOR_CENTRAL';

export const FileFormat = {
  'Minnetonka': 'MINNETONKA' as FileFormat,
  'Nordstrom': 'NORDSTROM' as FileFormat,
  'Nordstrom Rack': 'NORDSTROM' as FileFormat,
  'Home Shopping Network': 'HSN' as FileFormat,
  'QVC': 'QVC' as FileFormat,
  'Bed Bath & Beyond': 'BBB' as FileFormat,
  'Bluestem Brands': 'BLUESTEM' as FileFormat,
  'Dillard\'s Inc.': 'DILLARDS' as FileFormat,
  'Macy\'s': 'MACYS' as FileFormat,
  'Amazon Vendor Central': 'AMAZON_VENDOR_CENTRAL' as FileFormat
};

export interface PreflightError {
  severity: string;
  type: string;
  message: string;
}

export interface Preflight {
  sheetName: string;
  processedId: number;
  row: any;
  productRow: {
    productId: string;
    style: string;
    sku: string;
    upc: string;
    action: string;
  };
  errors: { [key: string]: Array<PreflightError> };
}

export interface StreamProgress {
  total: number;
  current: number;
}

export interface Stream {
  type: MessageEventType;
  data: any;
}

export interface DestinationDetailStateModel {
  destination: Catalog;
  destinationLoading: boolean;
  products: Array<Product>;
  productsLoading: boolean;
  exports: Array<CatalogExport>;
  collectionSuggestions: Array<Suggestion>;
  quickFilters: QueryFilterMeta;
  productView: View;
  syndicationsLoading: boolean;
  syndications: Array<Catalog>;
  syndicationRefresh: { [key: string]: boolean }; // Index by Syndication ID
  syndicationRefreshCount: { [key: string]: number }; // Index by Syndication ID
  loadingPluginConfig: boolean;
  connection: Connection;
  pluginConfig: PluginConfig;
  showPluginModal: boolean;
  brandSuggestions: Array<Suggestion>;
  scheduledSyndications: Array<Suggestion>;
  exportProductTemplateSuggestions: Array<Suggestion>;
  exportApiTemplateSuggestions: Array<Suggestion>;
  exportImageTemplateSuggestions: Array<Suggestion>;
  contacts: Array<Contact>;
  contactsLoading: boolean;
  syndication: PluginStatus;
  syndicationErrors: Array<PluginStatusError>;
  syndicationErrorsLoading: boolean;
  preflightColumns: Array<AGGridHeader>;

  preflightColumnsLoading: boolean;
  preflightStatuses: ProductImportStatus;
  preflightStatusesLoading: boolean;
  stream: Array<Stream>;
  streamToken: StreamToken
  streamRows: Array<any>;
  streamDownloadUrl: string;
  streamLoading: boolean;
  syndic8FieldSuggestions: Array<Suggestion>;
  emailHistory: Array<Suggestion>;
}


const defaults: DestinationDetailStateModel = {
  destination: {},
  destinationLoading: true,
  products: [],
  productsLoading: true,
  exports: [],
  collectionSuggestions: [],
  quickFilters: {
    [GroupBy.NAME]: [
      {
        displayName: 'Collection',
        propertyName: 'collectionId',
        type: QueryFilterType.Suggestion,
        suggestion: 'Collections',
        showSearch: true,
        suggestionDisplayName: true,
        suggestionProperty: SuggestionProperty.Id
      },
      {
        displayName: 'Brand',
        propertyName: 'brandRef.id',
        type: QueryFilterType.Suggestion,
        suggestion: 'Brands',
        suggestionDisplayName: true,
        hidden: true
      },
      {
        displayName: 'Since Last Syndication',
        propertyName: 'changedSinceLastSyndication',
        type: QueryFilterType.Boolean,
        suggestion: 'SinceLastSyndication'
      }
    ],
    [GroupBy.SKU]: [
      {
        displayName: 'Collection',
        propertyName: 'collectionId',
        type: QueryFilterType.Suggestion,
        suggestion: 'Collections',
        showSearch: true,
        suggestionDisplayName: true,
        suggestionProperty: SuggestionProperty.Id
      },
      {
        displayName: 'Brand',
        propertyName: 'brandRef.id',
        type: QueryFilterType.Suggestion,
        suggestion: 'Brands',
        suggestionDisplayName: true,
        hidden: true
      },
      {
        displayName: 'Since Last Syndication',
        propertyName: 'changedSinceLastSyndication',
        type: QueryFilterType.Boolean,
        suggestion: 'SinceLastSyndication'
      }
    ],
    [GroupBy.UPC]: [
      {
        displayName: 'Collection',
        propertyName: 'collectionId',
        type: QueryFilterType.Suggestion,
        suggestion: 'Collections',
        showSearch: true,
        suggestionDisplayName: true,
        suggestionProperty: SuggestionProperty.Id
      },
      {
        displayName: 'Brand',
        propertyName: 'brandRef.id',
        type: QueryFilterType.Suggestion,
        suggestion: 'Brands',
        suggestionDisplayName: true,
        hidden: true
      },
      {
        displayName: 'Since Last Syndication',
        propertyName: 'changedSinceLastSyndication',
        type: QueryFilterType.Boolean,
        suggestion: 'SinceLastSyndication'
      }
    ],
    [GroupBy.CUSTOM]: [
      {
        displayName: 'Collection',
        propertyName: 'collectionId',
        type: QueryFilterType.Suggestion,
        suggestion: 'Collections',
        suggestionDisplayName: true,
        showSearch: true,
        suggestionProperty: SuggestionProperty.Id
      },
      {
        displayName: 'Brand',
        propertyName: 'brandRef.id',
        type: QueryFilterType.Suggestion,
        suggestion: 'Brands',
        suggestionDisplayName: true,
        hidden: true
      },
      {
        displayName: 'Since Last Syndication',
        propertyName: 'changedSinceLastSyndication',
        type: QueryFilterType.Boolean,
        suggestion: 'SinceLastSyndication'
      }
    ],
    [GroupBy.DESTINATION]: [
      {
        displayName: 'Collection',
        propertyName: 'collectionId',
        type: QueryFilterType.Suggestion,
        suggestion: 'Collections',
        suggestionDisplayName: true,
        showSearch: true,
        suggestionProperty: SuggestionProperty.Id
      },
      {
        displayName: 'Brand',
        propertyName: 'brandRef.id',
        type: QueryFilterType.Suggestion,
        suggestion: 'Brands',
        suggestionDisplayName: true,
        hidden: true
      },
      {
        displayName: 'Since Last Syndication',
        propertyName: 'changedSinceLastSyndication',
        type: QueryFilterType.Boolean,
        suggestion: 'SinceLastSyndication'
      }
    ]
  },
  productView: undefined,
  syndicationsLoading: true,
  syndications: [],
  syndicationRefresh: {},
  syndicationRefreshCount: {},
  loadingPluginConfig: true,
  connection: null,
  pluginConfig: null,
  showPluginModal: true,
  brandSuggestions: [],
  scheduledSyndications: null,
  exportProductTemplateSuggestions: [],
  exportApiTemplateSuggestions: [],
  exportImageTemplateSuggestions: [],
  contacts: [],
  contactsLoading: false,
  syndication: null,
  syndicationErrors: [],
  syndicationErrorsLoading: false,
  preflightColumns: [],

  preflightColumnsLoading: true,
  preflightStatuses: null,
  preflightStatusesLoading: true,
  stream: [],
  streamRows: [],
  streamDownloadUrl: undefined,
  streamLoading: false,
  streamToken: null,
  syndic8FieldSuggestions: [],
  emailHistory: []
};

@State<DestinationDetailStateModel>({
  name: 'destinationDetail',
  defaults
})
@Injectable()
export class DestinationDetailState {

  constructor(
    private orgApi: OrgApiService,
    private productApi: ProductApiService,
    private subCatalogApi: SubcatalogApiService,
    private syndicationApi: SyndicationApiService,
    private actions: Actions,
    private store: Store,
    private toast: ToastService,
    private download: DownloadService,
    private appSvc: ApplicationService,
    private pluginApi: PluginsApiService,
    private connectorApi: ConnectorApiService,
    private channelApi: ChannelApiService,
    private viewApi: ViewApiService,
    private reportApi: ReportApiService,
    private gridApi: GridApiService,
    private importApi: ImportApiService,
    private sse: ServerSentEventService,
    private sseStreamingApi: CreateSseAPIService,
    private aplusApi: APlusContentApiService,
    public sseApiService: CreateSseAPIService,
    private router: Router

  ) {
    // Reset Destination Detail state on User Logout and Organization Load
    this.actions.pipe(ofActionDispatched(LogoutUserAction)).subscribe(
      () => {
        this.store.dispatch(new ResetDestinationDetailAction());
      }
    );
    this.actions.pipe(ofActionDispatched(LoadOrganizationAction)).subscribe(
      () => {
        this.store.dispatch(new ResetDestinationDetailAction());
      }
    );
  }

  @Selector()
  static getDestination(state: DestinationDetailStateModel): Catalog {
    return state.destination;
  }

  @Selector()
  static getChannel(state: DestinationDetailStateModel): OrganizationReference {
    return state.destination.destinations[0];
  }

  @Selector()
  static isDestinationLoading(state: DestinationDetailStateModel): boolean {
    return state.destinationLoading;
  }

  @Selector()
  static getProducts(state: DestinationDetailStateModel): Array<Product> {
    return state.products;
  }

  @Selector()
  static getProductsGroupedByTemplate(state: DestinationDetailStateModel): any {

    const uniqueTemplates = [...new Set([...state.products].filter(p => p['template']).map(p => p['template']))];
    const groups = [];
    groups.push({
      template: 'No template',
      products: [...state.products].filter(p => !p['template'])
    })
    uniqueTemplates.forEach(t => {
      groups.push({
        template: t.name,
        products: [...state.products].filter(p => p['template'] && p['template']['id'] === t.id)
      })
    })
    return groups
  }

  @Selector()
  static getQueryFilters(state: DestinationDetailStateModel): Array<QueryFilter> {
    return state.productView?.viewFields ? state.productView.viewFields.map(
      ({ type, displayName, propertyName, multiSelection, suggestion }: ViewField) => {
        const t = QueryFilterType.String;
        if (type === 'BulkUpdateFieldType.String') {
          type = QueryFilterType.String;
        } else if (type === 'BulkUpdateFieldType.Date') {
          type = QueryFilterType.Date;
        } else if (type === 'BulkUpdateFieldType.Datetime') {
          type = QueryFilterType.Datetime;
        } else if (type === 'BulkUpdateFieldType.Number') {
          type = QueryFilterType.Decimal;
        } else if (type === 'BulkUpdateFieldType.Suggestion') {
          if (multiSelection) {
            type = QueryFilterType.MultiSuggestion;
          } else {
            type = QueryFilterType.Suggestion;
          }
        } else if (type === 'BulkUpdateFieldType.Boolean') {
          type = QueryFilterType.Boolean;
        }

        return { displayName, propertyName, multiSelection, suggestion, type: t };
      }
    ) : [];
  }

  static getQuickFilters() {
    return createSelector(
      [DestinationDetailState, RouterState.state],
      (state: DestinationDetailStateModel, router: RouterStateParams): Array<QueryFilter> => {
        const category = router?.queryParams['category'];
        const fields = [...state?.quickFilters[category]];
        return fields.sort((a, b) => a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0);
      }
    );
  }

  @Selector()
  static areProductsLoading(state: DestinationDetailStateModel): boolean {
    return state.productsLoading;
  }

  @Selector()
  static getExports(state: DestinationDetailStateModel): Array<CatalogExport> {
    return state.exports;
  }

  @Selector()
  static getCollectionSuggestions(state: DestinationDetailStateModel): Array<Suggestion> {
    return state.collectionSuggestions;
  }

  @Selector()
  static getSinceLastSyndicationSuggestions(state: DestinationDetailStateModel): Array<Suggestion> {
    return [{
      id: '1',
      name: 'Since Last Syndication'
    }];
  }

  @Selector()
  static getSyndications(state: DestinationDetailStateModel): Array<Catalog> {
    return state.syndications.map(s => {
      if (s.uploadType === 'Line Sheet' && s.publicLinks && s.publicLinks.length > 0) {
        return {
          ...s,
          recipients: s.publicLinks.reduce(
            (acc, l) => {
              if (l.email) {
                return [...acc, l.email];
              }
              return acc;
            },
            []
          ).join('; ')
        };
      }
      return s;
    });
  }

  @Selector()
  static getScheduledSyndications(state: DestinationDetailStateModel): Array<any> {
    return state.scheduledSyndications;
  }

  @Selector()
  static getSyndicationProcessings(state: DestinationDetailStateModel): Array<boolean> {
    return state.syndications.map(s => {
      return s?.catalogExport?.runStatus === 'In Queue' || s?.catalogExport?.runStatus === 'In Progress';
    });
  }

  @Selector()
  static areSyndicationsLoading(state: DestinationDetailStateModel): boolean {
    return state.syndicationsLoading;
  }

  @Selector()
  static getProductImages(state: DestinationDetailStateModel): Array<InfiniteScrollImage> {
    return state.products.map(p => {
      let src: string = null;
      if (p.images && p.images[0]) {
        if (p.images[0].uriPathThumb) {
          src = p.images[0].uriPathThumb;
        } else if (p.images[0].uriPath) {
          src = p.images[0].uriPath;
        } else if (p.images[0].url) {
          src = p.images[0].url;
        }
      }
      return { src, title: p.productName };
    });
  }

  @Selector()
  static getProductChips(state: DestinationDetailStateModel): Array<Array<InfiniteScrollChip>> {
    return state.products.map(
      p => {
        let chips = [];
        if (p.errorCount && p.errorCount > 0) {
          chips = [
            ...chips,
            {
              color: InfiniteScrollColor.Red,
              icon: 'exclamation'
            }
          ];
        }
        if (p.warnCount && p.warnCount > 0) {
          chips = [
            ...chips,
            {
              color: InfiniteScrollColor.Yellow,
              icon: 'exclamation'
            }
          ];
        }
        return chips;
      }
    );
  }

  @Selector()
  static isPluginConfigLoading(state: DestinationDetailStateModel): boolean {
    return state.loadingPluginConfig;
  }

  @Selector()
  static getPluginConfig(state: DestinationDetailStateModel): PluginConfig {
    return state.pluginConfig;
  }

  @Selector()
  static getPluginConfigName(state: DestinationDetailStateModel): string {
    return state.pluginConfig?.name;
  }

  @Selector()
  static getPluginConfigProperties(state: DestinationDetailStateModel): Array<PluginConfigProperty> {
    return state.pluginConfig.properties;
  }

  @Selector()
  static isWalmartMarketplace(state: DestinationDetailStateModel): boolean {
    if (state.destination) {
      return state.destination.name === 'Walmart Marketplace';
    }
    return false;
  }

  @Selector()
  static isAmazon(state: DestinationDetailStateModel): boolean {
    if (state.destination) {
      // return state.destination.name === 'Amazon' || state.destination.name === 'Amazon Seller Central - FBA' || state.destination.name === 'Amazon Seller Central - FBM' || state.destination.name === 'Amazon Vendor Central';
      return state.destination.name.toLowerCase().includes("amazon");
    }
    return false;
  }

  @Selector()
  static isPluginDestination(state: DestinationDetailStateModel): boolean {
    return !!state.pluginConfig;
  }

  static showPluginModal() {
    return createSelector(
      [DestinationDetailState, OrganizationState],
      ({ pluginConfig, showPluginModal }: DestinationDetailStateModel, { organization }: OrganizationStateModel): boolean => {
        const name: string = pluginConfig?.name;
        if (name && showPluginModal) {
          return !pluginConfig?.configured && localStorage.getItem(`stopAskingToConfigure${name.charAt(0).toUpperCase() + name.slice(1)}APIOrg${organization.id}`) === null;
        }
        return false;
      }
    );
  }

  @Selector()
  static isPluginConfigured(state: DestinationDetailStateModel): boolean {
    return state.pluginConfig?.configured;
  }

  @Selector()
  static getBrandSuggestions(state: DestinationDetailStateModel): Array<Suggestion> {
    return state.brandSuggestions;
  }

  @Selector()
  static getExportProductTemplateSuggestions(state: DestinationDetailStateModel): Array<Suggestion> {
    return state.exportProductTemplateSuggestions;
  }

  @Selector()
  static getExportApiTemplateSuggestions(state: DestinationDetailStateModel): Array<Suggestion> {
    return state.exportApiTemplateSuggestions;
  }


  @Selector()
  static getExportImageTemplateSuggestions(state: DestinationDetailStateModel): Array<Suggestion> {
    return state.exportImageTemplateSuggestions;
  }

  @Selector()
  static areContactsLoading(state: DestinationDetailStateModel): boolean {
    return state.contactsLoading;
  }

  @Selector()
  static getContacts(state: DestinationDetailStateModel): Array<Contact> {
    return state.contacts;
  }

  static getLastEmail(syndication: Catalog) {
    return createSelector(
      [DestinationDetailState],
      (state: DestinationDetailStateModel): string => {
        if (state.emailHistory?.length > 0 && syndication.catalogExport.emailList?.length > 0) {
          const lastEmail = state.emailHistory[state.emailHistory.length - 1]?.email;
          if (syndication.catalogExport.emailList.findIndex(c => c.email === lastEmail) > -1) {
            return lastEmail;
          }
        }
      }
    );
  }

  @Selector()
  static getEmailSuggestions(state: DestinationDetailStateModel): Array<Suggestion> {
    return state.contacts.map(
      (contact: Contact, index: number) => {
        return {
          id: contact?.contractLinkId ? contact.contractLinkId.toString() : index.toString(),
          name: contact.email
        };
      }
    );
  }

  @Selector()
  static getAutoSendEmails(state: DestinationDetailStateModel): Array<string> {
    return state.contacts.filter(e => e.autosend).map(e => e.email);
  }

  @Selector()
  static getSyndication(state: DestinationDetailStateModel): PluginStatus {
    return state.syndication;
  }

  @Selector()
  static getSyndicationErrors(state: DestinationDetailStateModel): Array<PluginStatusError> {
    return state.syndicationErrors;
  }

  @Selector()
  static areSyndicationErrorsLoading(state: DestinationDetailStateModel): boolean {
    return state.syndicationErrorsLoading;
  }

  @Selector()
  // fix amber color when its required and has an empty value, should be red, Ask Keith tomorrow.
  static getsubmissionCheckPreflightColumnDefs(state: DestinationDetailStateModel): Array<any> {
    return state.preflightColumns.map(({ override, required, recommended, headerName, field, convert, internalField }): any => {
      const severityCheck = (errors: { [key: string]: Array<PreflightError> }, severity: string): boolean => {
        return errors && errors[field] ? errors[field].findIndex((e: PreflightError) => e.severity === severity) > -1 : false;
      };
      return {
        headerName,
        field,
        editable: override,
        cellClassRules: {
          amber: (params: CellClassParams): boolean => severityCheck(params.data._errors, 'WARNING') && !required && recommended,
          red: (params: CellClassParams): boolean => severityCheck(params.data._errors, 'WARNING') && required && !recommended,
          grey: (): boolean => !override
        },

        tooltipValueGetter: (params: ITooltipParams): string =>
          severityCheck(params.data._errors, 'ERROR') ||
            severityCheck(params.data._errors, 'WARNING') ||
            severityCheck(params.data._errors, 'INFO') ||
            !override ? field : undefined,
        tooltipComponent: PreflightTooltipComponent,
        headerTooltip: field,
        tooltipComponentParams: (params: ITooltipParams): PreflightTooltip => {
          let tooltip: PreflightTooltip = {
            color: 'white',
            messages: [],
            syndic8FieldSuggestions: state.syndic8FieldSuggestions,
            field: {
              definition: 'definition',
              example: 'example',
              convert,
              internalField: state.syndic8FieldSuggestions.length > 0 ? state.syndic8FieldSuggestions.find(s => s.id === `COLUMNNAME:${internalField}`)?.name : undefined,
            }
          };
          if (!override) {
            console.log('hey params=>', params)
            tooltip = { ...tooltip, color: 'grey', messages: [...tooltip.messages, `<b>${headerName}</b> is a Core field.`] };
          }
          if (params?.data?._errors) {
            console.log('hey params=>', params)
            const error: Array<PreflightError> = params.data._errors[field];
            if (error && error.length > 0) {
              tooltip = { ...tooltip, messages: [...tooltip.messages, ...error.map(e => e.message)] };
              if (error[0].severity === 'WARNING') {
                tooltip = { ...tooltip, color: 'red' };
              }
              else if (error[0].severity === 'INFO') {
                tooltip = { ...tooltip, color: 'white' };
              }
              // else if (error[0].severity === 'WARNING') {
              //   tooltip = { ...tooltip, color: 'amber' };
              // }
            }
          }
          return tooltip;
        },
        valueSetter: () => false

      }
    })
  }

  @Selector()
  static getPreflightColumnDefs(state: DestinationDetailStateModel): Array<ColGroupDef> {
    return state.preflightColumns.map(({ override, headerName, field, convert, internalField }): ColGroupDef => {
      const severityCheck = (errors: { [key: string]: Array<PreflightError> }, severity: string): boolean => {
        return errors && errors[field] ? errors[field].findIndex((e: PreflightError) => e.severity === severity) > -1 : false;
      };
      return {
        headerName: internalField,
        headerGroupComponent: PreflightCustomHeaderComponent,
        headerGroupComponentParams: {
          dataProcessField: {
            outputName: "outputName",
            name: "name",
            dataType: "dataType",
            convert: "convert"
          }
        },
        children: [
          {
            headerName,
            field,
            editable: override,
            cellClassRules: {
              red: (params: CellClassParams): boolean => severityCheck(params.data._errors, 'ERROR'),
              amber: (params: CellClassParams): boolean => severityCheck(params.data._errors, 'WARNING'),
              grey: (): boolean => !override
            },
            tooltipValueGetter: (params: ITooltipParams): string =>
              severityCheck(params.data._errors, 'ERROR') ||
                severityCheck(params.data._errors, 'WARNING') ||
                severityCheck(params.data._errors, 'INFO') ||
                !override ? field : undefined,
            tooltipComponent: PreflightTooltipComponent,
            headerTooltip: field,
            tooltipComponentParams: (params: ITooltipParams): PreflightTooltip => {
              let tooltip: PreflightTooltip = {
                color: 'white',
                messages: [],
                syndic8FieldSuggestions: state.syndic8FieldSuggestions,
                field: {
                  convert,
                  example: 'example',
                  definition: "definition",
                  internalField: state.syndic8FieldSuggestions.length > 0 ? state.syndic8FieldSuggestions.find(s => s.id === `COLUMNNAME:${internalField}`)?.name : undefined,
                }
              };
              if (!override) {
                tooltip = { ...tooltip, color: 'grey', messages: [...tooltip.messages, `<b>${headerName}</b> is a Core field.`] };
              }
              if (params?.data?._errors) {
                const error: Array<PreflightError> = params.data._errors[field];
                if (error && error.length > 0) {
                  tooltip = { ...tooltip, messages: [...tooltip.messages, ...error.map(e => e.message)] };
                  if (error[0].severity === 'ERROR') {
                    tooltip = { ...tooltip, color: 'red' };
                  } else if (error[0].severity === 'WARNING') {
                    tooltip = { ...tooltip, color: 'amber' };
                  } else if (error[0].severity === 'INFO') {
                    tooltip = { ...tooltip, color: 'white' };
                  }
                }
              }
              return tooltip;
            },
            valueSetter: () => false
          }
        ]
      };
    });
  }

  @Selector()
  static arePreflightColumnsLoading(state: DestinationDetailStateModel): boolean {
    return state.preflightColumnsLoading;
  }

  @Selector()
  static getPreflightStatuses(state: DestinationDetailStateModel): ProductImportStatus {
    return state.preflightStatuses;
  }

  @Selector()
  static arePreflightStatusesLoading(state: DestinationDetailStateModel): boolean {
    return state.preflightStatusesLoading;
  }

  @Selector()
  static getStream(state: DestinationDetailStateModel): Array<Stream> {
    return state.stream;
  }

  @Selector()
  static getImagePreflightColumns(state: DestinationDetailStateModel): Array<ColDef> {
    return state.stream;
  }

  @Selector()
  static getLastStreamEvent(state: DestinationDetailStateModel): Stream {
    return state.stream[state.stream.length - 1];
  }

  @Selector()
  static getStreamUUID(state: DestinationDetailStateModel): string {
    return state.stream.slice().reverse().find(s => s.type === MessageEventType.UUID)?.data;
  }

  @Selector()
  static getStreamProgress(state: DestinationDetailStateModel): StreamProgress {
    return state.stream.slice().reverse().find(s => s.type === MessageEventType.Progress)?.data;
  }

  @Selector()
  static getStreamRows(state: DestinationDetailStateModel): Array<any> {
    return state.streamRows;
  }

  @Selector()
  static getStreamToken(state: DestinationDetailStateModel): StreamToken {
    return state.streamToken;
  }

  @Selector()
  static getStreamDownloadUrl(state: DestinationDetailStateModel): string {
    return state.streamDownloadUrl;
  }

  @Selector()
  static isStreamLoading(state: DestinationDetailStateModel): boolean {
    return state.streamLoading;
  }

  @Selector()
  static getSyndic8FieldSuggestions(state: DestinationDetailStateModel): Array<Suggestion> {
    return state.syndic8FieldSuggestions;
  }

  @Action(LoadDestinationAction)
  loadDestination(
    { patchState }: StateContext<DestinationDetailStateModel>,
    { payload: { destinationId } }: LoadDestinationAction
  ) {
    patchState({ destinationLoading: true });

    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.subCatalogApi.getSubCatalog(organization.id, destinationId).pipe(
      tap(
        (destination: Catalog) => {
          patchState({ destination, destinationLoading: false });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Load',
            type: NotificationType.ERROR
          });
          patchState({ destinationLoading: false });
          return of(false);
        }
      )
    );
  }

  @Action(AppendDestinationProductsAction, { cancelUncompleted: true })
  loadCatalogProducts(
    { getState, setState, patchState }: StateContext<DestinationDetailStateModel>,
    { payload: { pageLength, pageToGet } }: AppendDestinationProductsAction
  ) {

    const id = getState().destination?.destinations[0]?.id;
    const { queryParams, params: { destinationId } } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const filterBy: Array<string> = queryParams.filterBy ? Array.isArray(queryParams.filterBy) ?
      ['subCatalogId', 'destinationId', ...queryParams.filterBy] :
      ['subCatalogId', 'destinationId', queryParams.filterBy] :
      ['subCatalogId', 'destinationId'];
    const filterJoin: Array<FilterJoin> = queryParams.filterJoin ? Array.isArray(queryParams.filterJoin) ?
      [FilterJoin.AND, FilterJoin.AND, ...queryParams.filterJoin] :
      [FilterJoin.AND, FilterJoin.AND, queryParams.filterJoin] :
      [FilterJoin.AND, FilterJoin.AND];
    const filterMatch: Array<FilterMatch> = queryParams.filterMatch ? Array.isArray(queryParams.filterMatch) ?
      [FilterMatch.IS, FilterMatch.IS, ...this.appSvc.toUpperCaseArray(queryParams.filterMatch)] :
      [FilterMatch.IS, FilterMatch.IS, queryParams.filterMatch.toUpperCase() as FilterMatch] :
      [FilterMatch.IS, FilterMatch.IS];
    const filterValue: Array<string> = queryParams.filterValue ? Array.isArray(queryParams.filterValue) ?
      [destinationId.toString(), id.toString(), ...queryParams.filterValue] :
      [destinationId.toString(), id.toString(), queryParams.filterValue] :
      [destinationId.toString(), id.toString()];
    const sortBy: string = queryParams.sort ? queryParams.sort : undefined;
    const orderBy: OrderBy = queryParams.order ? queryParams.order.toUpperCase() as OrderBy : undefined;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const groupBy = queryParams.category ? queryParams.category.toUpperCase() as GroupBy : GroupBy.NAME;
    const filterDefinition: FilterDefinition = {
      groupBy,
      filterEntries: filterBy.map((f, i) => {
        return { filterBy: f, filterJoin: filterJoin[i], filterMatch: filterMatch[i], filterValue: filterValue[i] };
      }),
      sortEntries: [{ orderBy, sortBy }],
      limit: pageLength,
      offset: pageToGet
    };

    return this.productApi.getProductsPost(organization.id, filterDefinition).pipe(
      tap(
        (products: Array<Product>) => {
          setState(patch<DestinationDetailStateModel>({ products: append(products), productsLoading: false }));
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          if (error.status !== 404) {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Load Products',
              type: NotificationType.ERROR
            });
            patchState({ products: defaults.products });
          }
          patchState({ productsLoading: false });
          return throwError(error);
        }
      )
    );
  }

  @Action(SetDestinationProductsLoadingAction)
  setLoading(
    { patchState }: StateContext<DestinationDetailStateModel>
  ) {
    patchState({ productsLoading: true });
  }

  @Action(LoadDestinationExportsAction)
  loadExports(
    { patchState }: StateContext<DestinationDetailStateModel>,
    { payload: { destinationId } }: LoadDestinationExportsAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.subCatalogApi.getCatalogExports(organization.id, destinationId).pipe(
      tap(
        (exports: Array<CatalogExport>) => {
          patchState({ exports });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          if (error.status !== 404) {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Load Exports',
              type: NotificationType.ERROR
            });
          }
          patchState({ exports: defaults.exports });
          return of(error);
        }
      )
    );
  }

  @Action(LoadCollectionSuggestionsAction)
  loadCollectionSuggestions(
    { getState, patchState }: StateContext<DestinationDetailStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = getState().destination;
    const subCatalogId: string = destination?.id?.toString();
    this.orgApi.getSuggestions(
      ['Destination Subcatalog List'],
      organization.id,
      ['subCatalogId'],
      [FilterJoin.AND],
      [FilterMatch.IS],
      [subCatalogId]
    ).pipe(
      tap(
        (suggestions: { [key: string]: Array<Suggestion> }) => {
          patchState({ collectionSuggestions: suggestions['Destination Subcatalog List'] });
        }
      )
    ).subscribe();
  }

  @Action(AddCollectionsToDestinationAction)
  addCollectionsToDestination(
    { }: StateContext<DestinationDetailStateModel>,
    { payload: { collections, destination } }: AddCollectionsToDestinationAction
  ) {
    const toast = this.toast.add({
      title: 'Please wait',
      message: `Adding Collection${collections.length > 1 ? 's' : ''} to ${destination.name}`,
      type: NotificationType.INFO
    });
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return from(collections).pipe(
      mergeMap(
        (collection: Catalog) => {
          return this.subCatalogApi.copySubCatalogProducts(organization.id, destination.id, collection.id).pipe(
            tap(
              () => {
                this.toast.remove(toast);
                this.toast.add({
                  expiration: 5000,
                  title: 'Success',
                  message: `Products added to ${destination.name}`,
                  type: NotificationType.SUCCESS
                });
              }
            ),
            catchError(
              (error: HttpErrorResponse) => {
                this.toast.remove(toast);
                this.toast.add({
                  expiration: 5000,
                  title: 'Service Error',
                  message: `Unable to add Products to ${destination.name}`,
                  type: NotificationType.ERROR
                });
                return throwError(error);
              }
            )
          );
        }
      )
    );
  }

  @Action(DownloadDestinationExportAction)
  downloadDestination(
    { getState }: StateContext<DestinationDetailStateModel>,
    { payload: { productIds } }: DownloadDestinationExportAction
  ) {
    const toast = this.toast.add({
      title: 'Please wait',
      message: 'Downloading Products',
      type: NotificationType.INFO
    });
    const destination = getState().destination;
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const groupBy = queryParams['category'].toUpperCase();
    let by = 'productName';
    if (groupBy === 'SKU') {
      by = 'sku';
    } else if (groupBy === 'UPC') {
      by = 'upc';
    }
    const filterBy = [];
    const filterMatch = [];
    const filterValue = [];
    productIds.forEach(
      (id: string) => {
        filterBy.push(by);
        filterMatch.push(FilterMatch.IS);
        filterValue.push(id);
      });
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.subCatalogApi.exportSubCatalogProducts(
      groupBy,
      organization.id,
      destination.id,
      filterBy,
      filterMatch,
      filterValue
    ).pipe(
      tap(
        (products: Blob) => {
          const destinationName = destination.name.replace(/ /g, '_');
          this.download.blob(products, `${destinationName}_products_${moment().format('MMMM-D-YYYY_hh-mm-ss')}`);
          this.toast.remove(toast);
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Export Products',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(StartDestinationExportWithFiltersAction)
  startExportWithFilters(
    { }: StateContext<DestinationDetailStateModel>,
    { payload: {
      destination,
      groupBy,
      sortBy,
      orderBy,
      filterBy,
      filterJoin,
      filterMatch,
      filterValue,
      templateName,
      comments
    } }: StartDestinationExportWithFiltersAction
  ) {

    const toast = this.toast.add({
      title: 'Please wait',
      message: 'Exporting to Spreadsheet',
      type: NotificationType.INFO
    });
    const filterEntries: Array<FilterEntry> = filterBy.map(
      (by: string, i: number) => {
        return {
          filterBy: by,
          filterJoin: filterJoin[i],
          filterMatch: filterMatch[i],
          filterValue: filterValue[i]
        };
      }
    );

    const sortEntries: Array<SortEntry> = sortBy.map(
      (by: OrderBy, i: number) => {
        return {
          sortBy: by,
          orderBy: orderBy[i]
        };
      }
    );
    const filterDefinition: FilterDefinition = {
      filterEntries,
      groupBy,
      sortEntries
    };

    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destinationId = destination.destinations[0].id;
    const destinationExport: DestinationExport = {
      comments: comments || null,
      filter: filterDefinition
    }
    return this.subCatalogApi.startCatalogExport(
      destinationId,
      organization.id,
      destination.id,
      templateName,
      destinationExport
    ).pipe(
      tap(
        () => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Export Started',
            route: '/org/' + organization.id + '/channel/' + destination.id + '/outbox',
            type: NotificationType.SUCCESS
          });
          this.router.navigateByUrl('/org/' + organization.id + '/channel/' + destination.id + '/outbox');
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          return of(error);
        }
      )
    );
  }

  @Action(StartDestinationExportWithIdsAction)
  startExportWithIds(
    { }: StateContext<DestinationDetailStateModel>,
    { payload: {
      destination,
      groupBy,
      productIds,
      templateName,
      comments
    } }: StartDestinationExportWithIdsAction
  ) {
    const toast = this.toast.add({
      title: 'Please wait',
      message: 'Exporting to Spreadsheet',
      type: NotificationType.INFO
    });
    const filterDefinition: FilterDefinition = {
      keys: productIds,
      groupBy
    };
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destinationId = destination.destinations[0].id;
    const destinationExport: DestinationExport = {
      comments: comments || null,
      filter: filterDefinition
    }

    return this.subCatalogApi.startCatalogExport(
      destinationId,
      organization.id,
      destination.id,
      templateName,
      destinationExport
    ).pipe(
      tap(
        () => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Export Started',
            route: '/org/' + organization.id + '/channel/' + destination.id + '/outbox',
            type: NotificationType.SUCCESS
          });
          this.router.navigateByUrl('/org/' + organization.id + '/channel/' + destination.id + '/outbox');
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          return of(error);
        }
      )
    );
  }

  @Action(StartDestinationImageExportWithFiltersAction)
  startImageExportWithFilters(
    { }: StateContext<DestinationDetailStateModel>,
    { payload: {
      destination,
      groupBy,
      sortBy,
      orderBy,
      filterBy,
      filterJoin,
      filterMatch,
      filterValue,
      templateName
    } }: StartDestinationImageExportWithFiltersAction
  ) {
    const filterEntries: Array<FilterEntry> = filterBy.map(
      (by: string, i: number) => {
        return {
          filterBy: by,
          filterJoin: filterJoin[i],
          filterMatch: filterMatch[i],
          filterValue: filterValue[i]
        };
      }
    );

    const sortEntries: Array<SortEntry> = sortBy.map(
      (by: OrderBy, i: number) => {
        return {
          sortBy: by,
          orderBy: orderBy[i]
        };
      }
    );
    const filterDefinition: FilterDefinition = {
      filterEntries,
      groupBy,
      sortEntries
    };

    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destinationId = destination.destinations[0].id;
    return this.subCatalogApi.startCatalogImageExport(
      destinationId,
      organization.id,
      destination.id,
      templateName,
      filterDefinition
    ).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Image Export Started',
            route: '/org/' + organization.id + '/channel/' + destination.id + '/outbox',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Export Images',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(StartDestinationImageExportWithIdsAction)
  startImageExportWithIds(
    { }: StateContext<DestinationDetailStateModel>,
    { payload: {
      destination,
      groupBy,
      productIds,
      templateName
    } }: StartDestinationImageExportWithIdsAction
  ) {
    const filterDefinition: FilterDefinition = {
      keys: productIds,
      groupBy
    };
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destinationId = destination.destinations[0].id;
    return this.subCatalogApi.startCatalogImageExport(
      destinationId,
      organization.id,
      destination.id,
      templateName,
      filterDefinition
    ).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Image Export Started',
            route: '/org/' + organization.id + '/channel/' + destination.id + '/outbox',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Export Images',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(SyndicateDestinationWithFiltersAction)
  syndicateDestinationWithFilters(
    { }: StateContext<DestinationDetailStateModel>,
    { payload: {
      destination,
      groupBy,
      sortBy,
      orderBy,
      filterBy,
      filterJoin,
      filterMatch,
      filterValue
    } }: SyndicateDestinationWithFiltersAction
  ) {
    const toast = this.toast.add({
      title: 'Please wait',
      message: 'Syndicating...',
      type: NotificationType.INFO
    });
    const filterEntries: Array<FilterEntry> = filterBy.map(
      (by: string, i: number) => {
        return {
          filterBy: by,
          filterJoin: filterJoin[i],
          filterMatch: filterMatch[i],
          filterValue: filterValue[i]
        };
      }
    );

    const sortEntries: Array<SortEntry> = sortBy.map(
      (by: OrderBy, i: number) => {
        return {
          sortBy: by,
          orderBy: orderBy[i]
        };
      }
    );
    const filterDefinition: FilterDefinition = {
      filterEntries,
      groupBy,
      sortEntries
    };
    const destinationId = destination.destinations[0].id;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.subCatalogApi.syndicateSubCatalogToDestination(destinationId, organization.id, destination.id, filterDefinition).pipe(
      tap(
        () => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Syndicated Successfully',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Syndicate',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }


  @Action(SyndicateDestinationWithIdsAction)
  syndicateDestinationWithIds(
    { }: StateContext<DestinationDetailStateModel>,
    { payload: { destination, groupBy, productIds } }: SyndicateDestinationWithIdsAction
  ) {
    const toast = this.toast.add({
      title: 'Please wait',
      message: 'Syndicating...',
      type: NotificationType.INFO
    });
    const filterDefinition: FilterDefinition = {
      keys: productIds,
      groupBy
    };
    const destinationId = destination.destinations[0].id;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.subCatalogApi.syndicateSubCatalogToDestination(destinationId, organization.id, destination.id, filterDefinition).pipe(
      tap(
        () => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Syndicated Successfully',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Syndicate',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(DownloadDestinationImagesWithFiltersAction)
  downloadImagesWithFilters(
    { }: StateContext<DestinationDetailStateModel>,
    { payload: {
      destination,
      groupBy,
      sortBy,
      orderBy,
      filterBy,
      filterJoin,
      filterMatch,
      filterValue
    } }: DownloadDestinationImagesWithFiltersAction
  ) {
    const toast = this.toast.add({
      title: 'Please wait',
      message: 'Downloading Images',
      type: NotificationType.INFO
    });
    const filterEntries: Array<FilterEntry> = filterBy.map(
      (by: string, i: number) => {
        return {
          filterBy: by,
          filterJoin: filterJoin[i],
          filterMatch: filterMatch[i],
          filterValue: filterValue[i]
        };
      }
    );

    const sortEntries: Array<SortEntry> = sortBy.map(
      (by: OrderBy, i: number) => {
        return {
          sortBy: by,
          orderBy: orderBy[i]
        };
      }
    );
    const filterDefinition: FilterDefinition = {
      filterEntries,
      groupBy,
      sortEntries
    };
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const fileFormat: FileFormat = FileFormat[destination.destinations[0].name];
    return this.subCatalogApi.getSubCatalogImagesZip(organization.id, destination.id, filterDefinition, fileFormat).pipe(
      tap(
        () => {
          const { params } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Product Media export started...',
            message: 'Redirecting to outbox..',
            type: NotificationType.SUCCESS,
            //route: `/org/${organization.id}/channel/${params.destinationId}/outbox`
          });
          this.router.navigate([`/org/${organization.id}/channel/${params.destinationId}/outbox`]);
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          if (error.status === 404) {
            this.toast.add({
              expiration: 5000,
              message: 'There are no Products with Images',
              type: NotificationType.WARN
            });
          } else {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Download Images',
              type: NotificationType.ERROR
            });
          }
          return throwError(error);
        }
      )
    );
  }

  @Action(DownloadDestinationImagesWithIdsAction)
  downloadImagesWithIds(
    { }: StateContext<DestinationDetailStateModel>,
    { payload: { destination, groupBy, productIds } }: DownloadDestinationImagesWithIdsAction
  ) {
    const toast = this.toast.add({
      title: 'Please wait',
      expiration: 5000,
      message: 'Downloading Images...',
      type: NotificationType.INFO
    });
    const filterDefinition: FilterDefinition = {
      keys: productIds,
      groupBy
    };
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const fileFormat: FileFormat = FileFormat[destination.destinations[0].name];
    return this.subCatalogApi.getSubCatalogImagesZip(organization.id, destination.id, filterDefinition, fileFormat).pipe(
      tap(
        () => {
          const { params } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
          this.toast.remove(toast);
          this.toast.add({
            expiration: 3000,
            title: 'Product Media export started...',
            message: 'Redirecting to outbox..',
            type: NotificationType.SUCCESS,

          });
          this.router.navigate([`/org/${organization.id}/channel/${params.destinationId}/outbox`]);
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          if (error.status === 404) {
            this.toast.add({
              expiration: 5000,
              message: 'No Products with Images',
              type: NotificationType.WARN
            });
          } else {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Download Images',
              type: NotificationType.ERROR
            });
          }
          return throwError(error);
        }
      )
    );
  }

  @Action(RemoveProductsFromDestinationWithIdsAction)
  removeProductsFromDestinationWithIds(
    { getState, patchState }: StateContext<DestinationDetailStateModel>,
    { payload: { productIds, destination, groupBy } }: RemoveProductsFromDestinationWithIdsAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const filterDefinition: FilterDefinition = {
      groupBy,
      keys: productIds
    };
    return this.productApi.removeSubCatalogProducts(organization.id, destination.id, filterDefinition).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Products Removed',
            type: NotificationType.SUCCESS
          });
          let idProperty = 'productName';
          if (groupBy === 'SKU') {
            idProperty = 'sku';
          } else if (groupBy === 'UPC') {
            idProperty = 'id';
          }
          patchState({ products: getState().products.filter(p => productIds.indexOf(p[idProperty]) === -1) });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Remove Products',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  // Delete a product from A+plus

  @Action(RemoveProductsAction)
  removeProducts(
    { }: StateContext<DestinationDetailStateModel>,
    {
      payload: {
        catalog,
        groupBy,
        sortBy,
        orderBy,
        filterBy,
        filterJoin,
        filterMatch,
        filterValue,
      },
    }: RemoveProductsAction
  ) {
    const filterEntries: Array<FilterEntry> = filterBy.map(
      (by: string, i: number) => {
        return {
          filterBy: by,
          filterJoin: filterJoin[i],
          filterMatch: filterMatch[i],
          filterValue: filterValue[i],
        };
      }
    );
    const sortEntries: Array<SortEntry> = sortBy.map(
      (by: OrderBy, i: number) => {
        return {
          sortBy: by,
          orderBy: orderBy[i],
        };
      }
    );
    const filterDefinition: FilterDefinition = {
      filterEntries,
      groupBy,
      sortEntries,
    };
    const organization: Organization = this.store.selectSnapshot(
      OrganizationState.getOrganization
    );
    return this.aplusApi
      .removeAPlusTemplateProducts(organization.id, catalog.id, filterDefinition)
      .pipe(
        tap(() => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Product removed',
            type: NotificationType.SUCCESS,
          });
        }),
        catchError((error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to remove product',
            type: NotificationType.ERROR,
          });
          return throwError(error);
        })
      );
  }



  @Action(RemoveProductsFromDestinationWithFiltersAction)
  removeProductsFromDestinationWithFilters(
    { }: StateContext<DestinationDetailStateModel>,
    { payload: {
      destination,
      groupBy,
      sortBy,
      orderBy,
      filterBy,
      filterJoin,
      filterMatch,
      filterValue
    } }: RemoveProductsFromDestinationWithFiltersAction
  ) {
    const filterEntries: Array<FilterEntry> = filterBy.map(
      (by: string, i: number) => {
        return {
          filterBy: by,
          filterJoin: filterJoin[i],
          filterMatch: filterMatch[i],
          filterValue: filterValue[i]
        };
      }
    );

    const sortEntries: Array<SortEntry> = sortBy.map(
      (by: OrderBy, i: number) => {
        return {
          sortBy: by,
          orderBy: orderBy[i]
        };
      }
    );
    const filterDefinition: FilterDefinition = {
      filterEntries,
      groupBy,
      sortEntries
    };
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.productApi.removeSubCatalogProducts(organization.id, destination.id, filterDefinition).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Products Removed',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Remove Products',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(SetSyndicationsLoadingAction)
  setSyndicationsLoading(
    { patchState }: StateContext<DestinationDetailStateModel>
  ) {
    patchState({ syndicationsLoading: true });
  }

  @Action(AppendSyndicationsAction, { cancelUncompleted: true })
  loadSyndications(
    { setState, patchState, getState }: StateContext<DestinationDetailStateModel>,
    { payload: { pageLength, pageToGet } }: AppendSyndicationsAction
  ) {
    const { id } = getState().destination.destinations[0];
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const filterBy: Array<string> = queryParams.filterBy ? Array.isArray(queryParams.filterBy) ?
      queryParams.filterBy : [queryParams.filterBy] : [];
    const filterJoin: Array<FilterJoin> = queryParams.filterJoin ? Array.isArray(queryParams.filterJoin) ?
      queryParams.filterJoin : [queryParams.filterJoin] : [];
    const filterMatch: Array<FilterMatch> = queryParams.filterMatch ? Array.isArray(queryParams.filterMatch) ?
      this.appSvc.toUpperCaseArray(queryParams.filterMatch) : [queryParams.filterMatch.toUpperCase() as FilterMatch] : [];
    const filterValue: Array<string> = queryParams.filterValue ? Array.isArray(queryParams.filterValue) ?
      queryParams.filterValue : [queryParams.filterValue] : [];
    const sortBy: Array<string> = queryParams.sort ? Array.isArray(queryParams.sort) ?
      queryParams.sort : [queryParams.sort] : [];
    const orderBy: Array<OrderBy> = queryParams.order ? Array.isArray(queryParams.order) ?
      this.appSvc.toUpperCaseArray(queryParams.order) : [queryParams.order.toUpperCase() as OrderBy] : [];
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);

    return this.subCatalogApi.getAllSubCatalogDestinations(
      organization.id,
      [...filterBy, 'uploadType', 'destinationId'],
      [...filterJoin, FilterJoin.AND, FilterJoin.AND],
      [...filterMatch, FilterMatch.ISNOT, FilterMatch.IS],
      [...filterValue, 'User Created', id.toString()],
      pageLength,
      pageToGet,
      orderBy,
      sortBy
    ).pipe(
      tap(
        (syndications: Array<Catalog>) => {
          setState(patch<DestinationDetailStateModel>({ syndications: append(syndications), syndicationsLoading: false }));
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          if (error.status !== 404) {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Load Syndications',
              type: NotificationType.ERROR
            });
            patchState({ syndications: defaults.syndications });
          }
          patchState({ syndicationsLoading: false });
          return throwError(error);
        }
      )
    );
  }

  @Action(RefreshSyndicationAction)
  refreshDestination(
    { setState, getState }: StateContext<DestinationDetailStateModel>,
    { payload: { syndicationId } }: RefreshSyndicationAction
  ) {
    const index = getState().syndications.findIndex(s => s.id === syndicationId);
    if (index === -1) {
      return;
    }
    const syn = getState().syndications[index];
    if (!syn || !syn.catalogExport || !syn.catalogExport.runStatus) {
      return;
    }
    if (syn.catalogExport.runStatus !== 'In Queue' && syn.catalogExport.runStatus !== 'In Progress' && syn.catalogExport.runStatus !== 'Scheduled') {
      return;
    }
    if (getState().syndicationRefresh[syndicationId]) {
      return;
    }
    if (getState().syndicationRefreshCount[syndicationId] >= 30) {
      setState(patch<DestinationDetailStateModel>({
        syndications: updateItem(s => s.id === syndicationId, {
          ...syn,
          catalogExport: {
            ...syn.catalogExport,
            runStatus: 'Unknown'
          }
        })
      }));
      return;
    }
    setState(
      patch<DestinationDetailStateModel>({
        syndicationRefresh: patch({ [syndicationId]: true })
      })
    );
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const counts = getState().syndicationRefreshCount;
    const count = counts[syndicationId] !== undefined ? counts[syndicationId] + 1 : 1;
    of(syndicationId).pipe(
      mergeMap(id => this.subCatalogApi.getSubCatalog(organization.id, id).pipe(
        tap(
          (synidcation: Catalog) => {
            setState(patch<DestinationDetailStateModel>({
              syndicationRefreshCount: patch({ [id]: count }),
              syndicationRefresh: patch({ [id]: false }),
              syndications: updateItem(s => s.id === id, synidcation)
            }));
          }
        ),
        catchError(
          (error: HttpErrorResponse) => {
            setState(patch<DestinationDetailStateModel>({
              syndicationRefreshCount: patch({ [id]: count }),
              syndicationRefresh: patch({ [id]: false })
            }));
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Refresh Syndication',
              type: NotificationType.ERROR
            });
            return throwError(error);
          }
        )
      ))
    ).subscribe();
  }

  @Action(CancelSyndicationAction)
  cancelSyndication(
    { patchState, getState }: StateContext<DestinationDetailStateModel>,
    { payload: { syndicationId } }: CancelSyndicationAction
  ) {
    const index = getState().syndications.findIndex(s => s.id === syndicationId);
    if (index === -1) {
      return;
    }
    const syn = getState().syndications[index];
    if (!syn || !syn.catalogExport || !syn.catalogExport.runStatus) {
      return;
    }
    if (syn.catalogExport.runStatus === 'Complete' || syn.catalogExport.runStatus === 'Failed' || syn.catalogExport.runStatus === 'Cancelled') {
      return;
    }
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.importApi.postExportCancelById(syn.catalogExport.id, organization.id).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Cancelation request',
            message: 'Syndication Cancelled',
            type: NotificationType.SUCCESS,
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: `Unable to Cancel syndication`,
            type: NotificationType.ERROR
          });
          patchState({ productsLoading: false });
          return throwError(error);
        }
      )
    )
  }

  @Action(StartFeedPushToPluginWithFiltersAction)
  startFeedPushToPlugin(
    { patchState, getState }: StateContext<DestinationDetailStateModel>,
    { payload: { templateName, exportType, productIds } }: StartFeedPushToPluginWithFiltersAction
  ) {
    patchState({ productsLoading: true });
    const destination: Catalog = getState().destination;
    const toast = this.toast.add({
      title: 'Please wait',
      message: `Syndicating to ${destination.name}`,
      type: NotificationType.INFO
    });

    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    let pluginName: string = '';
    // if (destination.name === 'Amazon' || destination.name === 'Amazon Seller Central - FBA' || destination.name === 'Amazon Seller Central - FBM' || destination.name === 'Amazon Vendor Central') {
    if (destination.name.toLowerCase().includes("amazon")) {
      pluginName = 'amazon-sp';
    } else if (destination.name === 'Walmart Marketplace') {
      pluginName = 'walmart3p';
    } else if (destination.name === 'Shopify') {
      pluginName = 'shopify';
    } else if (destination.name === 'BOL') {
      pluginName = 'bol-com';
    } else if (destination.name === 'Magento 2') {
      pluginName = "magento";
    } else if (destination.name === 'Google') {
      pluginName = "sftp";
    } else if (destination.name === 'Best Buy') {
      pluginName = "bestbuy";
    } else if (destination.name === 'Syndic8 Dummy Plugin') {
      pluginName = "dummy";
    } else {
      return;
    }

    const subCatalogId: Catalog['id'] = getState().destination.id;
    const destinationId: Catalog['id'] = getState().destination.destinations[0].id;

    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const filterBy: Array<string> = queryParams.filterBy ? Array.isArray(queryParams.filterBy) ?
      queryParams.filterBy : [queryParams.filterBy] : [];
    const filterJoin: Array<FilterJoin> = queryParams.filterJoin ? Array.isArray(queryParams.filterJoin) ?
      queryParams.filterJoin : [queryParams.filterJoin] : [];
    const filterMatch: Array<FilterMatch> = queryParams.filterMatch ? Array.isArray(queryParams.filterMatch) ?
      queryParams.filterMatch : [queryParams.filterMatch] : [];
    const filterValue: Array<string> = queryParams.filterValue ? Array.isArray(queryParams.filterValue) ?
      queryParams.filterValue : [queryParams.filterValue] : [];
    const sortBy: Array<string> = queryParams.sort ? Array.isArray(queryParams.sort) ?
      queryParams.sort : [queryParams.sort] : [];
    const orderBy: Array<OrderBy> = queryParams.order ? Array.isArray(queryParams.order) ?
      this.appSvc.toUpperCaseArray(queryParams.order) : [queryParams.order.toUpperCase()] : [];
    const groupBy: GroupBy = queryParams.category ? queryParams.category.toUpperCase() : GroupBy.NAME;


    const filterEntries: Array<FilterEntry> = filterBy.map(
      (by: string, i: number) => {
        return {
          filterBy: by,
          filterJoin: filterJoin[i],
          filterMatch: filterMatch[i],
          filterValue: filterValue[i]
        };
      }
    );
    const sortEntries: Array<SortEntry> = sortBy.map(
      (by: OrderBy, i: number) => {
        return {
          sortBy: by,
          orderBy: orderBy[i]
        };
      }
    );
    const filterDefinition: FilterDefinition = {
      filterEntries,
      sortEntries,
      keys: productIds,
      groupBy
    };
    const destinationExport: DestinationExport = {
      filter: filterDefinition
    }

    return this.subCatalogApi.startFeedPushToPlugin(
      pluginName,
      destinationId,
      organization.id,
      subCatalogId,
      exportType,
      templateName,
      destinationExport
    ).pipe(
      tap(
        () => {
          const { params } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Product Syndication started...',
            message: '',
            type: NotificationType.SUCCESS,
            route: `/org/${organization.id}/channel/${params.destinationId}/outbox`
          });
          patchState({ productsLoading: false });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: `Unable to Syndicate to ${destination.name}`,
            type: NotificationType.ERROR
          });
          patchState({ productsLoading: false });
          return throwError(error);
        }
      )
    );
  }

  @Action(ScheduleFeedPushToPluginWithFiltersAction)
  scheduleFeedPushToPlugin(
    { patchState, getState }: StateContext<DestinationDetailStateModel>,
    { payload: { templateName, timestamp } }: ScheduleFeedPushToPluginWithFiltersAction
  ) {
    patchState({ productsLoading: true });
    const destination: Catalog = getState().destination;
    const toast = this.toast.add({
      title: 'Please wait',
      message: `Syndicating to ${destination.name}`,
      type: NotificationType.INFO
    });

    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    let pluginName: string = '';
    if (destination.name.toLowerCase().includes("amazon")) {
      pluginName = 'amazon-sp';
    } else if (destination.name === 'Walmart Marketplace') {
      pluginName = 'walmart3p';
    } else if (destination.name === 'Shopify') {
      pluginName = 'shopify';
    } else if (destination.name === 'BOL') {
      pluginName = 'bol-com';
    } else if (destination.name === 'Magento 2') {
      pluginName = "magento";
    } else if (destination.name === 'Google') {
      pluginName = "sftp";
    } else if (destination.name === 'Best Buy') {
      pluginName = "bestbuy";
    } else if (destination.name === 'Syndic8 Dummy Plugin') {
      pluginName = "dummy";
    } else {
      return
    }

    const subCatalogId: Catalog['id'] = getState().destination.id;
    const destinationId: Catalog['id'] = getState().destination.destinations[0].id;

    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const filterBy: Array<string> = queryParams.filterBy ? Array.isArray(queryParams.filterBy) ?
      queryParams.filterBy : [queryParams.filterBy] : [];
    const filterJoin: Array<FilterJoin> = queryParams.filterJoin ? Array.isArray(queryParams.filterJoin) ?
      queryParams.filterJoin : [queryParams.filterJoin] : [];
    const filterMatch: Array<FilterMatch> = queryParams.filterMatch ? Array.isArray(queryParams.filterMatch) ?
      queryParams.filterMatch : [queryParams.filterMatch] : [];
    const filterValue: Array<string> = queryParams.filterValue ? Array.isArray(queryParams.filterValue) ?
      queryParams.filterValue : [queryParams.filterValue] : [];
    const sortBy: Array<string> = queryParams.sort ? Array.isArray(queryParams.sort) ?
      queryParams.sort : [queryParams.sort] : [];
    const orderBy: Array<OrderBy> = queryParams.order ? Array.isArray(queryParams.order) ?
      this.appSvc.toUpperCaseArray(queryParams.order) : [queryParams.order.toUpperCase()] : [];
    const groupBy: GroupBy = queryParams.category ? queryParams.category.toUpperCase() : GroupBy.NAME;

    const filterEntries: Array<FilterEntry> = filterBy.map(
      (by: string, i: number) => {
        return {
          filterBy: by,
          filterJoin: filterJoin[i],
          filterMatch: filterMatch[i],
          filterValue: filterValue[i]
        };
      }
    );
    const sortEntries: Array<SortEntry> = sortBy.map(
      (by: OrderBy, i: number) => {
        return {
          sortBy: by,
          orderBy: orderBy[i]
        };
      }
    );
    const filterDefinition: FilterDefinition = {
      filterEntries,
      groupBy,
      sortEntries
    };
    const destinationExport: DestinationExport = {
      filter: filterDefinition,
      scheduledExportDate: timestamp?.toString()
    }
    return this.subCatalogApi.startFeedPushToPlugin(
      pluginName,
      destinationId,
      organization.id,
      subCatalogId,
      null,
      templateName,
      destinationExport
    ).pipe(
      tap(
        () => {
          const { params } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Product Syndication started...',
            message: 'Redirecting to outbox..',
            type: NotificationType.SUCCESS,
            //route: `/org/${organization.id}/channel/${params.destinationId}/outbox`
          });
          this.router.navigate([`/org/${organization.id}/channel/${params.destinationId}/outbox`]);
          patchState({ productsLoading: false });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: `Unable to Syndicate to ${destination.name}`,
            type: NotificationType.ERROR
          });
          patchState({ productsLoading: false });
          return throwError(error);
        }
      )
    );
  }
  @Action(DeleteScheduledTask)
  deleteScheduledTask(
    { patchState, getState }: StateContext<DeleteScheduledTask>,
    { payload: { scheduledSyndicationModel } }: DeleteScheduledTask
  ) {
    console.log("deleteScheduledTask=>", scheduledSyndicationModel)
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);

    return this.syndicationApi.deleteScheduledSyndication(scheduledSyndicationModel?.scheduledId, organization.id)
      .pipe(
        tap(
          () => {
            this.toast.add({
              expiration: 5000,
              title: 'Success',
              message: `Syndication Scheduled Deleted`,
              type: NotificationType.SUCCESS
            });
          }
        ),
        catchError(
          (error: HttpErrorResponse) => {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: `Unable to Delete Scheduled Syndication`,
              type: NotificationType.ERROR
            });
            return throwError(error);
          }
        )
      );
  }
  @Action(UpdateScheduledTask)
  updateScheduledTask(
    { patchState, getState }: StateContext<UpdateScheduledTask>,
    { payload: { scheduledSyndicationModel } }: UpdateScheduledTask
  ) {
    console.log("UpdateScheduledTask=>", scheduledSyndicationModel)
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);

    return this.syndicationApi.updateScheduledSyndication(scheduledSyndicationModel?.scheduledId, organization.id, scheduledSyndicationModel)
      .pipe(
        tap(
          () => {
            this.toast.add({
              expiration: 5000,
              title: 'Success',
              message: `Syndication Scheduled Updated`,
              type: NotificationType.SUCCESS
            });
          }
        ),
        catchError(
          (error: HttpErrorResponse) => {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: `Unable to Update Scheduled Syndication`,
              type: NotificationType.ERROR
            });
            return throwError(error);
          }
        )
      );
  }

  @Action(CreateScheduledTask)
  createScheduledTask(
    { patchState, getState }: StateContext<CreateScheduledTask>,
    { payload: { scheduledSyndicationModel } }: CreateScheduledTask
  ) {
    console.log("createScheduledTask=>", scheduledSyndicationModel)
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);

    return this.syndicationApi.addScheduledSyndication(organization.id, scheduledSyndicationModel)
      .pipe(
        tap(
          () => {
            this.toast.add({
              expiration: 5000,
              title: 'Success',
              message: `Syndication Scheduled`,
              type: NotificationType.SUCCESS
            });
          }
        ),
        catchError(
          (error: HttpErrorResponse) => {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: `Unable to Schedule Syndication`,
              type: NotificationType.ERROR
            });
            return throwError(error);
          }
        )
      );
  }

  @Action(StartFeedPushToPluginWithIdsAction)
  StartFeedPushToPluginWithIds(
    { patchState, getState }: StateContext<DestinationDetailStateModel>,
    { payload: { productIds, templateName } }: StartFeedPushToPluginWithIdsAction
  ) {
    patchState({ productsLoading: true });
    const destination: Catalog = getState().destination;
    const toast = this.toast.add({
      title: 'Please wait',
      message: `Scheduling syndication to ${destination.name}`,
      type: NotificationType.INFO
    });
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const groupBy: GroupBy = queryParams.category ? queryParams.category.toUpperCase() : GroupBy.NAME;
    const filterDefinition: FilterDefinition = {
      keys: productIds,
      groupBy
    };
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    let pluginName: string = '';
    if (destination.name.toLowerCase().includes("amazon")) {
      pluginName = 'amazon-sp';
    } else if (destination.name === 'Walmart Marketplace') {
      pluginName = 'walmart3p';
    } else if (destination.name === 'Shopify') {
      pluginName = 'shopify';
    } else if (destination.name === 'BOL') {
      pluginName = 'bol-com';
    } else if (destination.name === 'Magento 2') {
      pluginName = "magento";
    } else if (destination.name === 'Google') {
      pluginName = "sftp";
    } else if (destination.name === 'Best Buy') {
      pluginName = "bestbuy";
    } else if (destination.name === 'Syndic8 Dummy Plugin') {
      pluginName = "dummy";
    } else {
      return;
    }
    const subCatalogId: Catalog['id'] = getState().destination.id;
    const destinationId: Catalog['id'] = getState().destination.destinations[0].id;
    const destinationExport: DestinationExport = {
      filter: filterDefinition
    }
    return this.subCatalogApi.startFeedPushToPlugin(
      pluginName,
      destinationId,
      organization.id,
      subCatalogId,
      null,
      templateName,
      destinationExport
    ).pipe(
      tap(
        () => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: `Products Syndicated to ${destination.name}`,
            type: NotificationType.SUCCESS
          });
          patchState({ productsLoading: false });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: `Unable to Syndicate to ${destination.name}`,
            type: NotificationType.ERROR
          });
          patchState({ productsLoading: false });
          return throwError(error);
        }
      )
    );
  }

  @Action(ScheduleFeedPushToPluginWithIdsAction)
  ScheduleFeedPushToPluginWithIds(
    { patchState, getState }: StateContext<DestinationDetailStateModel>,
    { payload: { productIds, templateName, timestamp } }: ScheduleFeedPushToPluginWithIdsAction
  ) {
    patchState({ productsLoading: true });
    const destination: Catalog = getState().destination;
    const toast = this.toast.add({
      title: 'Please wait',
      message: `Scheduling syndication to ${destination.name}`,
      type: NotificationType.INFO
    });
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const groupBy: GroupBy = queryParams.category ? queryParams.category.toUpperCase() : GroupBy.NAME;
    const filterDefinition: FilterDefinition = {
      keys: productIds,
      groupBy
    };
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    let pluginName: string = '';
    if (destination.name.toLowerCase().includes("amazon")) {
      pluginName = 'amazon-sp';
    } else if (destination.name === 'Walmart Marketplace') {
      pluginName = 'walmart3p';
    } else if (destination.name === 'Shopify') {
      pluginName = 'shopify';
    } else if (destination.name === 'BOL') {
      pluginName = 'bol-com';
    } else if (destination.name === 'Magento 2') {
      pluginName = "magento";
    } else if (destination.name === 'Google') {
      pluginName = "sftp";
    } else if (destination.name === 'Best Buy') {
      pluginName = "bestbuy";
    } else if (destination.name === 'Syndic8 Dummy Plugin') {
      pluginName = "dummy";
    } else {
      return;
    }

    const subCatalogId: Catalog['id'] = getState().destination.id;
    const destinationId: Catalog['id'] = getState().destination.destinations[0].id;
    const destinationExport: DestinationExport = {
      filter: filterDefinition,
      scheduledExportDate: timestamp?.toString()
    }
    return this.subCatalogApi.startFeedPushToPlugin(
      pluginName,
      destinationId,
      organization.id,
      subCatalogId,
      null,
      templateName,
      destinationExport
    ).pipe(
      tap(
        () => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: `Products Syndicated to ${destination.name}`,
            type: NotificationType.SUCCESS
          });
          patchState({ productsLoading: false });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: `Unable to Syndicate to ${destination.name}`,
            type: NotificationType.ERROR
          });
          patchState({ productsLoading: false });
          return throwError(error);
        }
      )
    );
  }

  @Action(LoadPluginConfigurationAction)
  loadDestinationPluginConfiguration(
    { getState, patchState }: StateContext<DestinationDetailStateModel>
  ) {
    patchState({loadingPluginConfig: true});
    const destination: Catalog = getState().destination;

    let pluginName: string = '';
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);

    return this.connectorApi.getConnectionDestination(destination.destinations[0].id, organization.id)
      .pipe(
        mergeMap((e) => {
          if (e.length > 0) {
            pluginName = e[0].pluginName;
            patchState({ connection: e[0] })
          }
          return this.pluginApi.getPluginConfigValuesForDestination(destination.destinations[0].id, organization.id, pluginName);
        }),
        catchError(
          (error: HttpErrorResponse) => {
            patchState({ pluginConfig: defaults.pluginConfig, loadingPluginConfig: false });
            return throwError(error);
          }
        ))
      .subscribe((pluginConfig) => {
        patchState({ pluginConfig, loadingPluginConfig: false });
      });
  }

  @Action(ResetDestinationDetailAction)
  resetDestination(
    { setState }: StateContext<DestinationDetailStateModel>
  ) {
    setState(defaults);
  }

  @Action(SavePluginConfigurationAction)
  savePluginConfig(
    { getState, patchState }: StateContext<DestinationDetailStateModel>,
    { payload: { pluginPropertyValues } }: SavePluginConfigurationAction
  ) {;
    patchState({ loadingPluginConfig: true });

    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = getState().destination;
    // let pluginName: string = '';
    const connection = getState().connection;

    // return this.pluginApi.savePluginConfig(organization.id, pluginName, pluginPropertyValues).pipe(
    return this.pluginApi.savePluginConfigForDestination(destination.destinations[0].id, organization.id, connection.pluginName, pluginPropertyValues).pipe(
      tap(
        (pluginConfig: PluginConfig) => {
          patchState({ pluginConfig, loadingPluginConfig: false });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Save Plugin Config',
            type: NotificationType.ERROR
          });
          patchState({ loadingPluginConfig: false });
          return throwError(error);
        }
      )
    );
  }

  @Action(ShowPluginConfigModalAction)
  showPluginConfigModal(
    { patchState }: StateContext<DestinationDetailStateModel>,
    { payload: { state } }: ShowPluginConfigModalAction
  ) {
    patchState({ showPluginModal: state });
  }

  @Action(ResetSyndicationsAction)
  resetSyndications(
    { patchState }: StateContext<DestinationDetailStateModel>
  ) {
    patchState({
      syndications: defaults.syndications,
      syndicationRefreshCount: defaults.syndicationRefreshCount,
      syndicationRefresh: defaults.syndicationRefresh
    });
  }

  @Action(ResetDestinationProductsAction)
  resetProducts(
    { patchState }: StateContext<DestinationDetailStateModel>
  ) {
    patchState({ products: defaults.products });
  }

  @Action(LoadScheduledSyndications)
  loadScheduledSyndications(
    { getState, setState }: StateContext<DestinationDetailStateModel>,
    { payload: { pageLength, pageToGet } }: LoadScheduledSyndications
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = getState().destination;
    const destinationId: string = destination.destinations[0].id.toString();

    // ['destinationId'], [FilterJoin.AND], [FilterMatch.IS], [destinationId]

    const fd: FilterDefinition = {
      limit: pageLength,
      offset: pageToGet,
      filterEntries: [
        {
          filterBy: 'destinationId',
          filterJoin: FilterJoin.AND,
          filterMatch: FilterMatch.IS,
          filterValue: destinationId
        }
      ]
    }

    // DESTINATIONID

    this.syndicationApi.getScheduledSyndication(organization.id, fd).pipe(
      tap(
        ((scheduledSyndications) => {
            setState(patch<DestinationDetailStateModel>({ scheduledSyndications: scheduledSyndications }));
        }
      )
    ),
      catchError(
        (error: HttpErrorResponse) => {
          setState(patch<DestinationDetailStateModel>({ scheduledSyndications: [] }));
          return throwError(error);
        }
      )
      ).subscribe();
  }

  @Action(LoadBrandSuggestionsAction)
  loadBrandSuggestions(
    { patchState }: StateContext<DestinationDetailStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.orgApi.getSuggestions(['ORGANIZATIONBRANDS'], organization.id).pipe(
      tap(
        ({ ORGANIZATIONBRANDS }) => {
          patchState({ brandSuggestions: ORGANIZATIONBRANDS });
        }
      )
    ).subscribe();
  }

  @Action(LoadExportProductTemplateSuggestionsAction)
  loadExportProductTemplateSuggestions(
    { patchState, getState }: StateContext<DestinationDetailStateModel>
  ) {
    patchState({ exportProductTemplateSuggestions: [] });
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = getState().destination;
    const destinationId: string = destination.destinations[0].id.toString();
    this.orgApi.getSuggestions(['Export Multi Preflight Templates'], organization.id, ['destinationId'], [FilterJoin.AND], [FilterMatch.IS], [destinationId]).pipe(
      tap(
        (suggestions: { [key: string]: Array<Suggestion> }) => {
          const allTemplates = suggestions['Export Multi Preflight Templates'];
          const apiTemplates = [...allTemplates].filter(t => t.syndicationType?.toLowerCase() === 'api')
          patchState({
            exportProductTemplateSuggestions: allTemplates,
            exportApiTemplateSuggestions: apiTemplates
          });
        }
      )
    ).subscribe();

    // patchState({ exportProductTemplateSuggestions: [] });
    // const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    // const destination: Catalog = getState().destination;
    // const destinationId: string = destination.destinations[0].id.toString();
    // this.orgApi.getSuggestions(
    //   ['Export Product Templates'],
    //   organization.id,
    //   ['destinationId', 'templateHeader'],
    //   [FilterJoin.AND, FilterJoin.AND],
    //   [FilterMatch.IS, FilterMatch.IS],
    //   [destinationId, 'ExportProduct']
    // ).pipe(
    //   tap(
    //     (suggestions: { [key: string]: Array<Suggestion> }) => {
    //       patchState({ exportProductTemplateSuggestions: suggestions['Export Product Templates'] });
    //     }
    //   )
    // ).subscribe();
  }

  @Action(LoadExportImageTemplateSuggestionsAction)
  loadExportImageTemplateSuggestions(
    { patchState, getState }: StateContext<DestinationDetailStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = getState().destination;
    const destinationId: string = destination.destinations[0].id.toString();
    this.orgApi.getSuggestions(
      ['Export Product Templates'],
      organization.id,
      ['destinationId', 'templateHeader'],
      [FilterJoin.AND, FilterJoin.AND],
      [FilterMatch.IS, FilterMatch.IS],
      [destinationId, 'ExportImage']
    ).pipe(
      tap(
        (suggestions: { [key: string]: Array<Suggestion> }) => {
          patchState({ exportImageTemplateSuggestions: suggestions['Export Product Templates'] });
        }
      )
    ).subscribe();
  }

  @Action(BulkUpdateProductsAction)
  bulkUpdateProducts(
    { }: StateContext<DestinationDetailStateModel>,
    { payload: { bulkUpdate } }: BulkUpdateProductsAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const groupBy = bulkUpdate.groupBy.toUpperCase() as GroupBy;
    return this.productApi.patchProductBulk(organization.id, { ...bulkUpdate, groupBy }).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Products Updated',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Update Products',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(SendEmailSyndicationAction)
  sendEmailSyndication(
    { getState }: StateContext<DestinationDetailStateModel>,
    { payload: { name, emails, statsId } }: SendEmailSyndicationAction
  ) {
    const destinationId: number = getState().destination.destinations[0]?.id;
    const subCatalogId = getState().destination?.id;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.subCatalogApi.publicSubCatalogToDestination(destinationId, emails, name, organization.id, subCatalogId, statsId).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Syndication Email Sent',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Send Syndication Email',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(ReloadSyndicationAction)
  reloadSyndication(
    { setState, getState }: StateContext<DestinationDetailStateModel>,
    { payload: { syndicationId } }: ReloadSyndicationAction
  ) {
    const { id } = getState().destination.destinations[0];
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);

    return this.subCatalogApi.getAllSubCatalogDestinations(
      organization.id,
      ['uploadType', 'destinationId', 'id'],
      [FilterJoin.AND, FilterJoin.AND, FilterJoin.AND],
      [FilterMatch.ISNOT, FilterMatch.IS, FilterMatch.IS],
      ['User Created', id.toString(), syndicationId.toString()],
      1,
      1
    ).pipe(
      tap(
        (syndications: Array<Catalog>) => {
          if (syndications && syndications.length === 1) {
            setState(patch<DestinationDetailStateModel>({ syndications: updateItem(s => s.id === syndicationId, syndications[0]) }));
          }
        }
      )
    );
  }

  @Action(LoadDestinationContactsAction)
  loadDestinationContacts(
    { getState, patchState }: StateContext<DestinationDetailStateModel>
  ) {
    patchState({ contactsLoading: true });

    const destination: Catalog = getState().destination;
    const destinationId = destination.destinations[0].id;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.channelApi.getContracts(destinationId, organization.id).pipe(
      tap(
        (contracts: Array<Contract>) => {
          if (contracts.length > 0) {
            if (contracts[0].contactList) {
              patchState({ contacts: contracts[0].contactList });
            }
          }
          patchState({ contactsLoading: false });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          patchState({ contactsLoading: false });
          return throwError(error);
        }
      )
    ).subscribe();
  }

  @Action(StartProductVerifyLiveExportWithIdsAction)
  startProductVerifyLiveExport(
    { getState }: StateContext<DestinationDetailStateModel>,
    { payload: { productIds } }: StartProductVerifyLiveExportWithIdsAction
  ) {
    const toast = this.toast.add({
      title: 'Please wait',
      message: 'Exporting Live Products',
      type: NotificationType.INFO
    });
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const groupBy: GroupBy = queryParams.category ? queryParams.category : GroupBy.NAME;
    const filterDefinition: FilterDefinition = {
      keys: productIds,
      groupBy
    };
    const destination: Catalog = getState().destination;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.subCatalogApi.startCatalogExportVerifyLive(
      destination.destinations[0].id,
      organization.id,
      destination.id,
      filterDefinition,
      'response'
    ).pipe(
      tap(
        (response: HttpResponse<Blob>) => {
          const destinationName = destination.name.replace(/ /g, '_');
          this.download.blob(response.body, `${destinationName}_live_products_${moment().format('MMMM-D-YYYY_hh-mm-ss')}`);
          this.toast.remove(toast);
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Export Live Products',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(StartProductVerifyLiveExportWithFiltersAction)
  startProductVerifyLiveExportWithFilters(
    { getState }: StateContext<DestinationDetailStateModel>
  ) {
    const toast = this.toast.add({
      title: 'Please wait',
      message: 'Exporting Live Products',
      type: NotificationType.INFO
    });
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const sortBy: Array<string> = queryParams.sortBy ? Array.isArray(queryParams.sortBy) ? queryParams.sortBy : [queryParams.sortBy] : [];
    const orderBy: Array<OrderBy> = queryParams.orderBy ? Array.isArray(queryParams.orderBy) ? queryParams.orderBy : [queryParams.orderBy] : [];
    const filterBy: Array<string> = queryParams.filterBy ? Array.isArray(queryParams.filterBy) ? queryParams.filterBy : [queryParams.filterBy] : [];
    const filterJoin: Array<FilterJoin> = queryParams.filterJoin ? Array.isArray(queryParams.filterJoin) ?
      queryParams.filterJoin : [queryParams.filterJoin] : [];
    const filterMatch: Array<FilterMatch> = queryParams.filterMatch ? Array.isArray(queryParams.filterMatch) ?
      queryParams.filterMatch : [queryParams.filterMatch] : [];
    const filterValue: Array<string> = queryParams.filterValue ? Array.isArray(queryParams.filterValue) ?
      queryParams.filterValue : [queryParams.filterValue] : [];
    const filterEntries: Array<FilterEntry> = filterBy.map(
      (by: string, i: number) => {
        return {
          filterBy: by,
          filterJoin: filterJoin[i],
          filterMatch: filterMatch[i],
          filterValue: filterValue[i]
        };
      }
    );
    const sortEntries: Array<SortEntry> = sortBy.map(
      (by: OrderBy, i: number) => {
        return {
          sortBy: by,
          orderBy: orderBy[i]
        };
      }
    );
    const groupBy: GroupBy = queryParams.category ? queryParams.category : GroupBy.NAME;
    const filterDefinition: FilterDefinition = {
      filterEntries,
      groupBy,
      sortEntries
    };
    const destination: Catalog = getState().destination;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.subCatalogApi.startCatalogExportVerifyLive(
      destination.destinations[0].id,
      organization.id,
      destination.id,
      filterDefinition,
      'response'
    ).pipe(
      tap(
        (response: HttpResponse<Blob>) => {
          const destinationName = destination.name.replace(/ /g, '_');
          this.download.blob(response.body, `${destinationName}_live_products_${moment().format('MMMM-D-YYYY_hh-mm-ss')}`);
          this.toast.remove(toast);
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Export Live Products',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(LoadDestinationProductViewAction)
  loadDestinationProductView(
    { patchState }: StateContext<DestinationDetailStateModel>,
    { payload: { viewName } }: LoadDestinationProductViewAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.viewApi.getViewByName(organization.id, viewName).pipe(
      tap(
        (productView: View) => {
          patchState({ productView });
        }
      )
    );
  }

  @Action(GenerateLinesheetAction)
  generateJasperLinesheet(
    { getState }: StateContext<DestinationDetailStateModel>,
    { payload: { productIds, reportName } }: GenerateLinesheetAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const tpName = getState().destination?.name;
    const destinationId = getState().destination?.destinations[0]?.id;
    const router = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const GroupBy = router.queryParams["category"];

    const requestBody = {
      ProductId_IN: productIds,
      DestinationId: destinationId,
      TitleName: 'Line Sheet',
      GroupBy: GroupBy
    };

    return this.reportApi.startReport(reportName, organization.id, 'pdf', requestBody, 'response').pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Line Sheet Export Started',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          // this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Generate PDF',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(LoadDestinationSyndicationAction)
  loadDestinationSyndication(
    { patchState, getState }: StateContext<DestinationDetailStateModel>
  ) {
    const { params } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const { id } = getState().destination.destinations[0];

    return this.pluginApi.getPluginHistoryDetail(id, organization.id, params.syndicationId, [], [], [], 1, 1).pipe(
      tap(
        (syndication: PluginStatus) => {
          patchState({ syndication });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          if (error.status !== 404) {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Load Syndication',
              type: NotificationType.ERROR
            });
            patchState({ syndication: defaults.syndication });
          }
          return throwError(error);
        }
      )
    );
  }

  @Action(AppendDestinationSyndicationErrorsAction, { cancelUncompleted: true })
  appendDestinationSyndicationErrors(
    { setState, patchState, getState }: StateContext<DestinationDetailStateModel>,
    { payload: { pageLength, pageToGet } }: AppendDestinationSyndicationErrorsAction
  ) {
    const { params, queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const filterBy: Array<string> = queryParams.filterBy ? Array.isArray(queryParams.filterBy) ?
      queryParams.filterBy : [queryParams.filterBy] : [];
    // const filterJoin: Array<FilterJoin> = queryParams.filterJoin ? Array.isArray(queryParams.filterJoin) ?
    //   queryParams.filterJoin : [queryParams.filterJoin] : [];
    const filterMatch: Array<FilterMatch> = queryParams.filterMatch ? Array.isArray(queryParams.filterMatch) ?
      this.appSvc.toUpperCaseArray(queryParams.filterMatch) : [queryParams.filterMatch.toUpperCase() as FilterMatch] : [];
    const filterValue: Array<string> = queryParams.filterValue ? Array.isArray(queryParams.filterValue) ?
      queryParams.filterValue : [queryParams.filterValue] : [];
    const sortBy: Array<string> = queryParams.sort ? Array.isArray(queryParams.sort) ?
      queryParams.sort : [queryParams.sort] : [];
    const orderBy: Array<OrderBy> = queryParams.order ? Array.isArray(queryParams.order) ?
      this.appSvc.toUpperCaseArray(queryParams.order) : [queryParams.order.toUpperCase() as OrderBy] : [];
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const { id } = getState().destination.destinations[0];

    return this.pluginApi.getPluginHistoryDetail(
      id,
      organization.id,
      params.syndicationId,
      filterBy,
      filterMatch,
      filterValue,
      pageLength,
      pageToGet,
      orderBy,
      sortBy
    ).pipe(
      tap(
        ({ errors }: PluginStatus) => {
          setState(patch<DestinationDetailStateModel>({ syndicationErrors: append(errors), syndicationErrorsLoading: false }));
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          if (error.status !== 404) {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Load Syndication Errors',
              type: NotificationType.ERROR
            });
            patchState({ syndicationErrors: defaults.syndicationErrors });
          }
          patchState({ syndicationErrorsLoading: false });
          return throwError(error);
        }
      )
    );
  }

  @Action(SetDestinationSyndicationErrorsLoadingAction)
  setDestinationSyndicationErrorsLoading(
    { patchState }: StateContext<DestinationDetailStateModel>
  ) {
    patchState({ syndicationErrorsLoading: true });
  }

  @Action(ResetDestinationSyndicationErrorsAction)
  resetDestinationSyndicationErrors(
    { patchState }: StateContext<DestinationDetailStateModel>
  ) {
    patchState({ syndicationErrors: defaults.syndicationErrors });
  }

  @Action(LoadPreflightColumnsAction)
  loadPreflightColumnDefs(
    { getState, patchState }: StateContext<DestinationDetailStateModel>,
    { payload: { templateName } }: LoadPreflightColumnsAction
  ) {
    patchState({ preflightColumnsLoading: true });

    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = getState().destination;
    const destinationId: number = destination?.destinations[0].id;
    return this.gridApi.getGridHeader('export', organization.id, destinationId, templateName).pipe(
      tap(
        (preflightColumns: Array<AGGridHeader>) => {
          patchState({ preflightColumns, preflightColumnsLoading: false });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          if (error.status !== 404) {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Load Headers',
              type: NotificationType.ERROR
            });
            patchState({ preflightColumns: defaults.preflightColumns });
          }
          patchState({ preflightColumnsLoading: false });
          return throwError(error);
        }
      )
    );
  }


  ////

  @Action(LoadImagePreflightColumnsAction)
  loadImagePreflightColumnDefs(
    { getState, patchState }: StateContext<DestinationDetailStateModel>,
    { payload: { header } }: LoadImagePreflightColumnsAction
  ) {
    patchState({ preflightColumnsLoading: true });

    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = getState().destination;
    const destinationId: number = destination?.destinations[0].id;
    return this.gridApi.getGridHeader(header, organization.id, destinationId).pipe(
      tap(
        (preflightColumns: Array<AGGridHeader>) => {
          patchState({ preflightColumns: preflightColumns, preflightColumnsLoading: false });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          if (error.status !== 404) {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Load Headers',
              type: NotificationType.ERROR
            });
            patchState({ preflightColumns: defaults.preflightColumns });
          }
          patchState({ preflightColumnsLoading: false });
          return throwError(error);
        }
      )
    );
  }



  @Action(LoadPreflightColumnsByTemplateNameAction)
  loadPreflightColumnDefsByTemplateName(
    { getState, patchState }: StateContext<DestinationDetailStateModel>,
    { payload: { header, templateName } }: LoadPreflightColumnsByTemplateNameAction
  ) {
    patchState({ preflightColumnsLoading: true });
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = getState().destination;
    const destinationId: number = destination?.destinations[0].id;
    return this.gridApi.getGridHeader(header, organization.id, destinationId, templateName).pipe(
      tap(
        (preflightColumns: Array<AGGridHeader>) => {
          patchState({ preflightColumns, preflightColumnsLoading: false });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {

          if (error.status !== 404) {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Load Headers',
              type: NotificationType.ERROR
            });
            patchState({ preflightColumns: defaults.preflightColumns });
          }
          patchState({ preflightColumnsLoading: false });
          return throwError(error);
        }
      )
    );
  }



  @Action(LoadSyndic8FieldSuggestionsAction)
  loadSyndic8FieldSuggestions(
    { patchState }: StateContext<DestinationDetailStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.orgApi.getSuggestions(['Template Fields'], organization.id).pipe(
      tap(
        (suggestions: { [key: string]: Array<Suggestion> }) => {
          patchState({ syndic8FieldSuggestions: suggestions['Template Fields'] });
        }
      )
    );
  }

  @Action(StartDestinationExportStreamWithIdsAction)
  startCatalogExportStreamWithIds(
    { patchState, getState, dispatch }: StateContext<DestinationDetailStateModel>,
    { payload: { uuid, keys, templateName } }: StartDestinationExportStreamWithIdsAction
  ) {
    patchState({ preflightStatusesLoading: true });

    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const groupBy = queryParams.category ? queryParams.category : GroupBy.NAME;
    const filterDefinition: FilterDefinition = { groupBy, keys };

    const orgId: number = this.store.selectSnapshot(OrganizationState.getOrganization)?.id;
    const destination: Catalog = getState().destination;
    const subCatalogId: number = destination?.id;
    const destinationId: number = destination?.destinations[0].id;
    return this.subCatalogApi.startCatalogExportStream(uuid, destinationId, orgId, subCatalogId, "Plugin", templateName, filterDefinition).pipe(
      mergeMap(({ statsId }) => dispatch(new LoadDestinationExportErrorsAction({ statsId }))),
      catchError(
        (error: HttpErrorResponse) => {
          patchState({ preflightStatusesLoading: false });
          return throwError(error);
        }
      )
    );
  }

  @Action(StartDestinationExportStreamWithFiltersAction)
  startCatalogExportStreamWithFilters(
    { patchState, getState, dispatch }: StateContext<DestinationDetailStateModel>,
    { payload: { uuid, templateName, aplusTemplateId } }: StartDestinationExportStreamWithFiltersAction
  ) {
    patchState({ preflightStatusesLoading: true });
    aplusTemplateId.toString()
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    // const groupBy: GroupBy = queryParams.category ? queryParams.category : GroupBy.NAME;
    // const sortBy: Array<string> = queryParams.sortBy ? Array.isArray(queryParams.sortBy) ? queryParams.sortBy : [queryParams.sortBy] : [];
    // const orderBy: Array<OrderBy> = queryParams.orderBy ? Array.isArray(queryParams.orderBy) ? queryParams.orderBy : [queryParams.orderBy] : [];
    // const filterBy: Array<string> = queryParams.filterBy ? Array.isArray(queryParams.filterBy) ? queryParams.filterBy : [queryParams.filterBy] : [];
    // const filterJoin: Array<FilterJoin> = queryParams.filterJoin ? Array.isArray(queryParams.filterJoin) ?
    //   queryParams.filterJoin : [queryParams.filterJoin] : [];
    // const filterMatch: Array<FilterMatch> = queryParams.filterMatch ? Array.isArray(queryParams.filterMatch) ?
    //   queryParams.filterMatch : [queryParams.filterMatch] : [];
    // const filterValue: Array<string> = queryParams.filterValue ? Array.isArray(queryParams.filterValue) ?
    //   queryParams.filterValue : [queryParams.filterValue] : [];
    // const filterEntries: Array<FilterEntry> = filterBy.map(
    //   (by: string, i: number) => {
    //     return {
    //       filterBy: by,
    //       filterJoin: filterJoin[i],
    //       filterMatch: filterMatch[i],
    //       filterValue: filterValue[i]
    //     };
    //   }
    // );
    const groupBy: GroupBy = "SKU";
    const sortBy: Array<string> = []
    const orderBy: Array<OrderBy> = []
    const filterBy: Array<string> = ["aplusTemplateId"]
    const filterJoin: Array<FilterJoin> = [FilterJoin.AND]
    const filterMatch: Array<FilterMatch> = [FilterMatch.IS]
    const filterValue: Array<string> = [aplusTemplateId]
    const filterEntries: Array<FilterEntry> = filterBy.map(
      (by: string, i: number) => {
        return {
          filterBy: by,
          filterJoin: filterJoin[i],
          filterMatch: filterMatch[i],
          filterValue: filterValue[i]
        };
      }
    );

    const sortEntries: Array<SortEntry> = sortBy.map(
      (by: OrderBy, i: number) => {
        return {
          sortBy: by,
          orderBy: orderBy[i]
        };
      }
    );
    const filterDefinition: FilterDefinition = {
      filterEntries,
      groupBy,
      sortEntries
    };

    const orgId: number = this.store.selectSnapshot(OrganizationState.getOrganization)?.id;
    const destination: Catalog = getState().destination;
    const subCatalogId: number = destination?.id;
    const destinationId: number = destination?.destinations[0].id;
    return this.subCatalogApi.startCatalogExportStream(uuid, destinationId, orgId, subCatalogId, 'PluginExportAPlus', templateName, filterDefinition).pipe(
      mergeMap(({ statsId }) => dispatch(new LoadDestinationExportErrorsAction({ statsId }))),
      catchError(
        (error: HttpErrorResponse) => {
          patchState({ preflightStatusesLoading: false });
          return throwError(error);
        }
      )
    );
  }

  ////
  @Action(StartDestinationExportStreamWithFiltersAction2)
  startCatalogExportStreamWithFilters2(
    { patchState, getState, dispatch }: StateContext<DestinationDetailStateModel>,
    { payload: { uuid, productIds } }: StartDestinationExportStreamWithFiltersAction2

  ) {
    patchState({ preflightStatusesLoading: true });
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const groupBy: GroupBy = queryParams.category ? queryParams.category : GroupBy.NAME;
    const sortBy: Array<string> = queryParams.sortBy ? Array.isArray(queryParams.sortBy) ? queryParams.sortBy : [queryParams.sortBy] : [];
    const orderBy: Array<OrderBy> = queryParams.orderBy ? Array.isArray(queryParams.orderBy) ? queryParams.orderBy : [queryParams.orderBy] : [];
    const filterBy: Array<string> = queryParams.filterBy ? Array.isArray(queryParams.filterBy) ? queryParams.filterBy : [queryParams.filterBy] : [];
    const filterJoin: Array<FilterJoin> = queryParams.filterJoin ? Array.isArray(queryParams.filterJoin) ?
      queryParams.filterJoin : [queryParams.filterJoin] : [];
    const filterMatch: Array<FilterMatch> = queryParams.filterMatch ? Array.isArray(queryParams.filterMatch) ?
      queryParams.filterMatch : [queryParams.filterMatch] : [];
    const filterValue: Array<string> = queryParams.filterValue ? Array.isArray(queryParams.filterValue) ?
      queryParams.filterValue : [queryParams.filterValue] : [];
    const filterEntries: Array<FilterEntry> = filterBy.map(
      (by: string, i: number) => {
        return {
          filterBy: by,
          filterJoin: filterJoin[i],
          filterMatch: filterMatch[i],
          filterValue: filterValue[i]
        };
      }
    );

    const sortEntries: Array<SortEntry> = sortBy.map(
      (by: OrderBy, i: number) => {
        return {
          sortBy: by,
          orderBy: orderBy[i]
        };
      }
    );
    const filterDefinition: FilterDefinition = {
      keys: productIds,
      filterEntries,
      groupBy,
      sortEntries
    };

    const orgId: number = this.store.selectSnapshot(OrganizationState.getOrganization)?.id;
    const destination: Catalog = getState().destination;
    const subCatalogId: number = destination?.id;
    const destinationId: number = destination?.destinations[0].id;
    return this.subCatalogApi.startCatalogExportStream(uuid, destinationId, orgId, subCatalogId, 'PluginExportImage', '', filterDefinition).pipe(
      mergeMap(({ statsId }) => dispatch(new LoadDestinationExportErrorsAction({ statsId }))),
      catchError(
        (error: HttpErrorResponse) => {
          patchState({ preflightStatusesLoading: false });
          return throwError(error);
        }
      )
    );
  }



  ///

  @Action(StartDestinationExportStreamForProductsWithFiltersAction)
  startDestinationExportStreamForProductsWithFiltersAction(
    { patchState, getState, dispatch }: StateContext<DestinationDetailStateModel>,
    { payload: { uuid, templateName } }: StartDestinationExportStreamWithFiltersAction
  ) {
    patchState({ preflightStatusesLoading: true });
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const groupBy: GroupBy = queryParams.category ? queryParams.category : GroupBy.NAME;
    const sortBy: Array<string> = queryParams.sortBy ? Array.isArray(queryParams.sortBy) ? queryParams.sortBy : [queryParams.sortBy] : [];
    const orderBy: Array<OrderBy> = queryParams.orderBy ? Array.isArray(queryParams.orderBy) ? queryParams.orderBy : [queryParams.orderBy] : [];
    const filterBy: Array<string> = queryParams.filterBy ? Array.isArray(queryParams.filterBy) ? queryParams.filterBy : [queryParams.filterBy] : [];
    const filterJoin: Array<FilterJoin> = queryParams.filterJoin ? Array.isArray(queryParams.filterJoin) ?
      queryParams.filterJoin : [queryParams.filterJoin] : [];
    const filterMatch: Array<FilterMatch> = queryParams.filterMatch ? Array.isArray(queryParams.filterMatch) ?
      queryParams.filterMatch : [queryParams.filterMatch] : [];
    const filterValue: Array<string> = queryParams.filterValue ? Array.isArray(queryParams.filterValue) ?
      queryParams.filterValue : [queryParams.filterValue] : [];
    const filterEntries: Array<FilterEntry> = filterBy.map(
      (by: string, i: number) => {
        return {
          filterBy: by,
          filterJoin: filterJoin[i],
          filterMatch: filterMatch[i],
          filterValue: filterValue[i]
        };
      }
    );

    const sortEntries: Array<SortEntry> = sortBy.map(
      (by: OrderBy, i: number) => {
        return {
          sortBy: by,
          orderBy: orderBy[i]
        };
      }
    );
    const filterDefinition: FilterDefinition = {
      filterEntries,
      groupBy,
      sortEntries
    };

    const orgId: number = this.store.selectSnapshot(OrganizationState.getOrganization)?.id;
    const destination: Catalog = getState().destination;
    const subCatalogId: number = destination?.id;
    const destinationId: number = destination?.destinations[0].id;
    return this.subCatalogApi.startCatalogExportStream(uuid, destinationId, orgId, subCatalogId, 'PluginProduct', templateName, filterDefinition).pipe(
      mergeMap(({ statsId }) => dispatch(new LoadDestinationExportErrorsAction({ statsId }))),
      catchError(
        (error: HttpErrorResponse) => {
          patchState({ preflightStatusesLoading: false });
          return throwError(error);
        }
      )
    );
  }

  @Action(SyndicatePreflightWithIdsAction)
  syndicatePreflightWithIds(
    { getState }: StateContext<DestinationDetailStateModel>,
    { payload: { keys, templateName } }: SyndicatePreflightWithIdsAction
  ) {
    const toast = this.toast.add({
      title: 'Please wait',
      message: 'Exporting Preflight',
      type: NotificationType.INFO
    });
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const groupBy = queryParams.category ? queryParams.category : GroupBy.NAME;
    const filterDefinition: FilterDefinition = { groupBy, keys };

    const orgId: number = this.store.selectSnapshot(OrganizationState.getOrganization)?.id;
    const destination: Catalog = getState().destination;
    const destinationId: number = destination?.destinations[0].id;
    const destinationExport: DestinationExport = {
      comments: null,
      filter: filterDefinition
    }
    return this.subCatalogApi.startCatalogExport(
      destinationId,
      orgId,
      destination.id,
      templateName,
      destinationExport
    ).pipe(
      tap(
        () => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Preflight Export Started',
            route: '/org/' + orgId + '/channel/' + destination.id + '/outbox',
            type: NotificationType.SUCCESS
          });

        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Export Preflight',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(SyndicatePreflightWithFiltersAction)
  syndicatePreflightWithFilters(
    { getState }: StateContext<DestinationDetailStateModel>,
    { payload: { templateName } }: SyndicatePreflightWithFiltersAction
  ) {
    const toast = this.toast.add({
      title: 'Please wait',
      message: 'Exporting Preflight',
      type: NotificationType.INFO
    });

    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const groupBy: GroupBy = queryParams.category ? queryParams.category : GroupBy.NAME;
    const sortBy: Array<string> = queryParams.sortBy ? Array.isArray(queryParams.sortBy) ? queryParams.sortBy : [queryParams.sortBy] : [];
    const orderBy: Array<OrderBy> = queryParams.orderBy ? Array.isArray(queryParams.orderBy) ? queryParams.orderBy : [queryParams.orderBy] : [];
    const filterBy: Array<string> = queryParams.filterBy ? Array.isArray(queryParams.filterBy) ? queryParams.filterBy : [queryParams.filterBy] : [];
    const filterJoin: Array<FilterJoin> = queryParams.filterJoin ? Array.isArray(queryParams.filterJoin) ?
      queryParams.filterJoin : [queryParams.filterJoin] : [];
    const filterMatch: Array<FilterMatch> = queryParams.filterMatch ? Array.isArray(queryParams.filterMatch) ?
      queryParams.filterMatch : [queryParams.filterMatch] : [];
    const filterValue: Array<string> = queryParams.filterValue ? Array.isArray(queryParams.filterValue) ?
      queryParams.filterValue : [queryParams.filterValue] : [];
    const filterEntries: Array<FilterEntry> = filterBy.map(
      (by: string, i: number) => {
        return {
          filterBy: by,
          filterJoin: filterJoin[i],
          filterMatch: filterMatch[i],
          filterValue: filterValue[i]
        };
      }
    );

    const sortEntries: Array<SortEntry> = sortBy.map(
      (by: OrderBy, i: number) => {
        return {
          sortBy: by,
          orderBy: orderBy[i]
        };
      }
    );
    const filterDefinition: FilterDefinition = {
      filterEntries,
      groupBy,
      sortEntries
    };

    const orgId: number = this.store.selectSnapshot(OrganizationState.getOrganization)?.id;
    const destination: Catalog = getState().destination;
    const destinationId: number = destination?.destinations[0].id;
    const destinationExport: DestinationExport = {
      comments: null,
      filter: filterDefinition
    }
    return this.subCatalogApi.startCatalogExport(
      destinationId,
      orgId,
      destination.id,
      templateName,
      destinationExport
    ).pipe(
      tap(
        () => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Preflight Export Started',
            route: '/org/' + orgId + '/channel/' + destination.id + '/outbox',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Export Preflight',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(LoadDestinationExportErrorsAction)
  loadDestinationExportErrors(
    { patchState }: StateContext<DestinationDetailStateModel>,
    { payload: { statsId } }: LoadDestinationExportErrorsAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.importApi.getProductImportById(organization.id, statsId, false).pipe(
      tap(
        (preflightStatuses: ProductImportStatus) => {
          patchState({ preflightStatuses, preflightStatusesLoading: false });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          patchState({ preflightStatusesLoading: false });
          return throwError(error);
        }
      )
    );
  }

  @Action(RecordStreamEventAction)
  recordStreamEvent(
    { setState }: StateContext<DestinationDetailStateModel>,
    { payload: { message } }: RecordStreamEventAction
  ) {
    const type = message?.type as MessageEventType;
    const data = message?.data[0] === '{' || message?.data[0] === '[' ?
      JSON.parse(message.data) : message?.data[0] === '"' ?
        message.data.slice(1, -1) : message?.data;
    setState(patch({ stream: append([{ data, type }]) }));
  }

  @Action(CreateStreamTokenAction)
  retrieveStreamToken({ patchState, setState, dispatch }: StateContext<DestinationDetailStateModel>
  ) {
    const orgId: number = this.store.selectSnapshot(OrganizationState.getOrganization)?.id;
    return this.sseStreamingApi.createStreamToken(orgId).pipe(tap((streamToken: StreamToken) => {
      patchState({ streamToken });
    }));
  }

  @Action(ConnectToStreamAction)
  connectToStream(
    { patchState, setState, dispatch }: StateContext<DestinationDetailStateModel>,
    { payload: { preflightType, productIds } }: ConnectToStreamAction
  ) {
    patchState({ stream: defaults.stream, streamLoading: true });
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const streamToken: StreamToken = this.store.selectSnapshot(DestinationDetailState.getStreamToken);
    const api = `${environment.apiUrl}`

    return this.sse.connect(`${environment.apiUrl}/api/org/${organization.id}/stream/sse/connect/?streamId=${streamToken.streamId}`, true).pipe(
      tap(message => {
        dispatch(new RecordStreamEventAction({ message }));
        if (message?.data && message?.type) {
          if (message.type === MessageEventType.UUID) {
            if (preflightType === "aplus")
              this.store.dispatch(new StartDestinationExportStreamWithFiltersAction({ uuid: streamToken.streamId, templateName: "", aplusTemplateId: '' }))
            if (preflightType === "image")
              this.store.dispatch(new StartDestinationExportStreamWithFiltersAction2({ uuid: streamToken.streamId, productIds: productIds }))

          }

          if (message.type === MessageEventType.Start) {
            patchState({
              streamRows: defaults.streamRows,
              streamDownloadUrl: defaults.streamDownloadUrl,
              streamLoading: true
            });
          } else if (message.type === MessageEventType.Export) {
            const data: Array<Preflight> = JSON.parse(message.data);
            if (!Array.isArray(data)) {
              return;
            }
            const streamRows: Array<Preflight> = data.map(({ row, errors, productRow, processedId }) => {
              return { ...row, _errors: errors, _product: { ...productRow, processedId } };
            });
            setState(patch({ streamRows: append(streamRows) }));
          } else if (message.type === MessageEventType.Finished) {
            patchState({ streamDownloadUrl: message.data.slice(1, -1), streamLoading: false });
          }
        }
      },
        catchError(error => {
          patchState({ streamLoading: false });
          return throwError(error);
        })
      ));
  }

  /* Same action but instead we're passing the A plus template name and id in the payload so
  that we can pass them as argument when dispatching StartDestinationExportStreamWithFiltersAction since we will be
  needing both of them, one for the filterEntries of that action and the other for when we invoke the api call (as a parameter) */
  @Action(ConnectToStreamAction2)
  connectToStream2(
    { patchState, setState, dispatch }: StateContext<DestinationDetailStateModel>,
    { payload: { templateName, aplusTemplateId } }: ConnectToStreamAction2
  ) {
    patchState({ stream: defaults.stream, streamLoading: true });
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const streamToken: StreamToken = this.store.selectSnapshot(DestinationDetailState.getStreamToken);
    const api = `${environment.apiUrl}`
    return this.sse.connect(`${environment.apiUrl}/api/org/${organization.id}/stream/sse/connect/?streamId=${streamToken.streamId}`, true).pipe(
      tap(message => {
        dispatch(new RecordStreamEventAction({ message }));
        if (message?.data && message?.type) {
          if (message.type === MessageEventType.UUID) {
            // dispatching action that allows us to populate the ag grid table for A+ content submission flow
            this.store.dispatch(new StartDestinationExportStreamWithFiltersAction({ uuid: streamToken.streamId, templateName: templateName, aplusTemplateId: aplusTemplateId }))
          }
          if (message.type === MessageEventType.Start) {
            patchState({
              streamRows: defaults.streamRows,
              streamDownloadUrl: defaults.streamDownloadUrl,
              streamLoading: true
            });
          } else if (message.type === MessageEventType.Export) {
            const data: Array<Preflight> = JSON.parse(message.data);
            if (!Array.isArray(data)) {
              return;
            }
            const streamRows: Array<Preflight> = data.map(({ row, errors, productRow, processedId }) => {
              return { ...row, _errors: errors, _product: { ...productRow, processedId } };
            });
            setState(patch({ streamRows: append(streamRows) }));
          } else if (message.type === MessageEventType.Finished) {
            patchState({ streamDownloadUrl: message.data.slice(1, -1), streamLoading: false });
          }
        }
      },
        catchError(error => {
          patchState({ streamLoading: false });
          return throwError(error);
        })
      ));
  }

  @Action(DownloadStreamUrlAction)
  downloadStreamUrl(
    { getState }: StateContext<DestinationDetailStateModel>
  ) {
    const streamDownloadUrl: string = getState().streamDownloadUrl;
    const destination: Catalog = getState().destination;
    this.download.fromUrl(streamDownloadUrl, `${destination?.name}_products_${moment().format('MMMM-D-YYYY_hh-mm-ss')}.xlsx`);
  }

  @Action(UpdateStreamRowAction)
  updatePreflightRow(
    { getState }: StateContext<DestinationDetailStateModel>,
    { payload: { field, fieldValue, productIds } }: UpdateStreamRowAction
  ) {
    const destinationId: number = getState().destination.destinations[0].id;
    const streamId: string = getState().stream.find(s => s.type === MessageEventType.UUID)?.data;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const fieldName: string = getState().preflightColumns.find(p => p.field === field)?.internalField;
    if (!fieldName) {
      throw new Error('Unable to find internalFieldName');
    }
    const bulkUpdate: BulkUpdate = {
      destinationId,
      fieldName,
      fieldValue,
      groupBy: GroupBy.UPC,
      products: productIds
    };
    return this.productApi.patchProductSeoBulk(organization.id, bulkUpdate, streamId).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Save Preflight Cell',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(DownloadSyndicationErrorsAction)
  downloadSyndicationErrors(
    { getState }: StateContext<DestinationDetailStateModel>
  ) {
    const destination: Catalog = getState().destination;
    const { params } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.channelApi.exportErrors(params.syndicationId, organization.id, {}).pipe(
      tap(blob => this.download.blob(blob, `${destination.name}_errors.csv`))
    ).subscribe();
  }

  @Action(LoadEmailHistoryAction)
  loadEmailHistory(
    { getState, patchState }: StateContext<DestinationDetailStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destinationId = getState().destination?.destinations[0]?.id;
    this.orgApi.getSuggestions(
      ['EXPORT EMAIL HISTORY'],
      organization.id,
      ['destinationId'],
      [FilterJoin.AND],
      [FilterMatch.IS],
      [destinationId.toString()]
    ).pipe(
      tap(
        (suggestions: { [key: string]: Array<Suggestion> }) => {
          patchState({ emailHistory: suggestions['EXPORT EMAIL HISTORY'] });
        }
      )
    ).subscribe();
  }

  @Action(UpdateSyndicationCommentAction)
  updateComment(
    { getState, patchState }: StateContext<DestinationDetailStateModel>,
    { payload: { catalog, comments } }: UpdateSyndicationCommentAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const catalogExport = { ...catalog.catalogExport, comments };
    const updatedCatalog = { ...catalog, catalogExport };
    return this.subCatalogApi.patchSubCatalog(
      organization.id,
      catalog.id,
      updatedCatalog
    ).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: `Comment added`,
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to save comment',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    )
  }

  @Action(CancelScheduledSyndicationAction)
  cancelScheduledSyndication(
    { getState, patchState }: StateContext<DestinationDetailStateModel>,
    { payload: { catalog } }: CancelScheduledSyndicationAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.subCatalogApi.stopCatalogExport(
      organization.id,
      catalog.catalogExport.id
    ).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Syndication cancelled',
            message: `The scheduled syndication was successfully cancelled`,
            type: NotificationType.SUCCESS
          });
          this.store.dispatch(new RefreshSyndicationAction({ syndicationId: catalog.id }));
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to cancel scheduled syndication',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    )
  }

  @Action(UpdateProductsTemplatesAction)
  updateProductsTemplates(
    { setState, getState }: StateContext<DestinationDetailStateModel>,
    { payload: { productIds, template } }: UpdateProductsTemplatesAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const products: Product[] = getState().products;

    productIds.forEach(id => {
      const index = products.findIndex(p => p.id?.toString() === id || p.sku?.toString() === id || p.productName?.toString() === id);
      const updated = { ...products[index], template };

      return setState(patch<DestinationDetailStateModel>({
        products: updateItem(index, updated)
      }))
    })
  }


  @Action(CreateSseStreamAction)
  createSseStream(
    { getState }: StateContext<DestinationDetailStateModel>,
    { payload: { streamToken } }: CreateSseStreamAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const streamId = streamToken;
    return this.sseApiService.createSseStream(organization.id, streamId).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Something happened, the connection to the server failed',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }
}

