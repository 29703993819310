<div class="option" *ngFor="let option of options; index as i">
  <div class="checkbox-container" (click)="select(option)">
    <div class="checkbox" [ngClass]="{'selected': isSelected(option) }">
      <i class="fas fa-check"></i>
    </div>
  </div>
  <label (click)="select(option)">{{ option[value] }}</label>
</div>








