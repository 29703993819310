/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionItemOrg } from './subscriptionItemOrg';
import { ModifiedDate } from './modifiedDate';


export interface SubscriptionItem { 
    createdDate?: ModifiedDate;
    deleteRecord?: boolean;
    externalId?: string;
    itemType?: string;
    lastSyncDate?: string;
    lastUpdated?: ModifiedDate;
    orgItemList?: Array<SubscriptionItemOrg>;
    subscriptionId?: number;
    subscriptionItemId?: number;
    subscriptionLimit?: number;
    syncFlag?: boolean;
}

