import { Injectable, NgZone } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LogoutUserAction } from '../stores/auth.actions';
import { ToastService } from '../services/toast.service';
import { NotificationType } from '../api';
import { HistoryService } from '@app/shared';
import { ResetQueryStateAction } from '@app/shared/stores/query-cache.actions';
import { ExceptionService } from '../services/exception.service';

@Injectable()
export class ExceptionInterceptor implements HttpInterceptor {

  exclusions = [
    '/upload'
  ];

  constructor(
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    private toast: ToastService,
    private history: HistoryService,
    private exception: ExceptionService
  ) { }

  private checkExclusion(requestUrl: string) {
    // Check if the end of `requestUrl` matches a string in the exclusion list
    for (let i = 0; i < this.exclusions.length; i++) {
      const exclusion = this.exclusions[i];
      if (requestUrl.indexOf(exclusion) === requestUrl.length - exclusion.length) {
        return true;
      }
    }
    return false;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Prevent global exception handling if the route ends with a string in the exclusion list
    if (this.checkExclusion(request.url)) {
      return next.handle(request);
    } else {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent || error.status <= 0) {
            // Client-side or network error
            this.exception.add({
              name: 'ExceptionInterceptor, client-side/network',
              info: 'The network is having trouble making your request.',
              error: JSON.stringify(error)
            });
          } else {
            // Server-side error
            if (error.status === 401) {
              // Not authorized
              this.store.dispatch(new LogoutUserAction());
              return of(); // Return an empty observable to match the expected type
            }

            if (error.status === 403) {
              // Forbidden
              this.toast.add({
                expiration: 5000,
                title: 'Access Denied',
                message: 'Your account role is not permitted to access this function.',
                type: NotificationType.ERROR
              });

              // Navigate the user back only if this is not a token refresh error response
              const refreshHeader = error.headers.get('x-token-refresh-required');
              if (!!refreshHeader) {
                this.ngZone.run(() => this.router.navigateByUrl(this.history.getPreviousUrl())).then();
                return of(); // Return an empty observable to match the expected type
              }
            }

            if (error.status === 498) {
              // Token expired
              this.store.dispatch(new LogoutUserAction());
              return of(); // Return an empty observable to match the expected type
            }

            if (error.status % 500 <= 99) {
              this.exception.add({
                name: 'ExceptionInterceptor, server-side',
                info: 'The server is having trouble processing your request.',
                error: JSON.stringify(error)
              });
              this.store.dispatch(new ResetQueryStateAction());
            }
          }
          return throwError(error); // Re-throw the error as an observable
        })
      );
    }
  }
}
