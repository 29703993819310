import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { ITooltipParams } from '@ag-grid-community/core';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Suggestion } from '@app/core';
import { TemplateService, FunctionType } from '@app/shared/services/template.service';
import { ChipType } from '@app/shared/components/template/destination-chip/destination-chip.component';

export interface PreflightTooltip {
  color: string;
  messages: Array<string>;
  syndic8FieldSuggestions?: Array<Suggestion>;
  field: PreflightTooltipField;
}

export interface PreflightTooltipField {
  convert: Array<string>;
  internalField: string;
  definition: string;
  example: string;
}


@Component({
  selector: 'app-preflight-tooltip',
  templateUrl: './preflight-tooltip.component.html',
  styleUrls: ['./preflight-tooltip.component.scss']
})
export class PreflightTooltipComponent implements OnInit, ITooltipAngularComp {
  private params: PreflightTooltip & ITooltipParams;

  messages: Array<string>;
  syndic8FieldSuggestions: Array<Suggestion> = [];
  field: PreflightTooltipField = {
    convert: null,
    internalField: null,
    definition: null,
    example: null
  };


  getFunctionValue = this.templateSvc.getFunctionValue.bind(this.templateSvc);
  getFunctionType = this.templateSvc.getFunctionType.bind(this.templateSvc);
  getFunctionValueChips = this.templateSvc.getFunctionValueChips.bind(this.templateSvc);
  getConvert = this.templateSvc.getConvert.bind(this.templateSvc);
  getFunctionValidValueDisplay = this.templateSvc.getFunctionValidValueDisplay.bind(this.templateSvc);

  ChipType = ChipType;
  FunctionType = FunctionType;

  @HostBinding('class') color: string;
  @HostBinding('class.show') show = false;

  constructor(private templateSvc: TemplateService) {
  }

  ngOnInit(): void {

  }

  get isColumnTooltip(): boolean {
    return this.params.location === 'cell';
  }

  get isTopTooltip(): boolean {
    return this.params.location === 'headerGroup';
  }

  get isBottomToolTip(): boolean {
    return this.params.location === 'header';
  }

  agInit(params: PreflightTooltip & ITooltipParams): void {
    this.params = params;

    this.messages = this.params?.messages;
    this.syndic8FieldSuggestions = this.params?.syndic8FieldSuggestions;
    this.field = this.params?.field;

    if (this.isTopTooltip) {
      if (this.field?.convert || this.field?.internalField) {
        this.show = true;
      }
    } else if (this.isColumnTooltip) {
      if (this.messages?.length > 0) {
        this.show = true;
      }
      this.color = this.params?.color;
    } else if(this.isBottomToolTip) {
      this.show = true;
    }
  }
}
