<app-modal width="600" height="600" [open]="open" (openChange)="openChange.emit($event)" (close)="close()">
  <app-modal-head>
    Field: {{ jsonPathControlFormGroup?.value.name }}
    <div style="display: flex;">
      <div style="display: flex; flex-direction: column;">
        <div class="path-header">Current Path:</div>
        <input class="path-box"
          placeholder="Current Path"  [disabled]="true" [readOnly]="true" [ngModel]="fieldStartMatchModel"/>
      </div>
      <div class="flex-fill"></div>
      <div style="display: flex; flex-direction: column;">
        <div class="path-header"> Selected Path: </div>
        <input class="path-box"
          placeholder="Selected Path" [formControl]="form.get('input')"/>
      </div>
    </div>
  </app-modal-head>
  <app-modal-body style="overflow-x: hidden;">
    <app-page-loading *ngIf="loadSegments"
      [loading]="loadSegments">
    </app-page-loading>
    <ag-grid-angular *ngIf="!loadSegments" class="ag-theme-alpine"
                     style="width: 100%;"
                     [rowHeight]="30"
                     [suppressMenuHide]="true"
                     [gridOptions]="gridOptions"
                     (selectionChanged)="onSelectionChanged($event)"
                     [rowData]="rowSub | async">
    </ag-grid-angular>
  </app-modal-body>
  <app-modal-foot>
    <app-button color="red" (click)="openChange.emit(false)">Cancel</app-button>
    <div class="flex-fill"></div>
    <app-button color="green" (click)="onSaveClick()">Save</app-button>
  </app-modal-foot>
</app-modal>
