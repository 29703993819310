import { Directive, Inject, OnDestroy, OnInit, Self } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[appVirtualScrollViewportResize]',
})
export class VirtualScrollViewportResizeDirective implements OnInit, OnDestroy {

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Self() @Inject(CdkVirtualScrollViewport) private readonly viewport: CdkVirtualScrollViewport,
    @Inject(DOCUMENT) private readonly document: Document
  ) { }

  ngOnInit(): void {
    if (this.document.defaultView) {
      fromEvent(this.document.defaultView, 'resize')
        .pipe(
          debounceTime(1000 / 60),
        )
        .pipe(
          tap(() => this.viewport.checkViewportSize()),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
