<div class="help-panel" [ngClass]="{
  'open': open
}">
  <app-help-panel-header [title]="options?.title || 'Help'" (onCloseClicked)="onCloseClicked.emit()"
    [searchForm]="searchForm">
  </app-help-panel-header>



  <div class="content faqs" *ngIf="options?.faqs?.length > 0">
    <div class="heading">
      FAQs
    </div>

    <!-- Search / Empty search -->
    <div class="section" *ngIf="searchForm.value?.length">
      <div class="heading search-results-heading">Showing results for "{{ searchForm.value }}"</div>
      <div class="paragraph" *ngIf="filteredFAQs?.length === 0">
        No FAQs found.
      </div>
    </div>

    <!-- Results -->
    <div class="section" *ngFor="let faq of filteredFAQs">
      <div class="heading">{{faq.title}}</div>
      <div class="paragraph" *ngFor="let p of faq.paragraphs">
        {{p}}
      </div>

      <!-- Video link -->
      <div *ngIf="faq.video" class="video-link" (click)="openVideoPlayer(faq.video, faq.videoType)">
        <i class="fas fa-video"></i>
        <span style="margin-left:0.5rem">{{faq.videoLabel || 'Watch the video'}}</span>
      </div>

      <!-- Support URL -->
      <div *ngIf="faq.showSupportURL" class="support-link">
        <i class="fas fa-question"></i>
        <span style="margin-left:0.5rem">
          <a href="https://support.syndic8.io/hc/en-us/requests/new" target="_blank" rel="noopener noreferrer">Open a support ticket</a>
        </span>
      </div>
    </div>
  </div>

</div>


<!-- Video -->
<!-- Video backdrop -->
<div class="video-backdrop" *ngIf="videoName" [ngClass]="{
  'open': !!showVideoPlayer
}">
  <div class="video">
    <video controls controlsList="nodownload noplaybackrate" disablepictureinpicture #videoPlayer>
      <source [src]="'../../../../../assets/videos/help-videos/'+videoName" [type]="videoType">
      Browser not supported
    </video>
    <!-- <div style="position: relative; padding-bottom: 62.5%; height: 0;">
      <iframe
        src="https://www.loom.com/embed/fa47fc5b639c4121bc0990f527d31603?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
        frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
    </div> -->
    <div class="close-video-button" (click)="closeVideoPlayer()">
      <i class="fal fa-times"></i>
    </div>
  </div>
</div>
