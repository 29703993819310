/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PurchaseOrderItem } from './purchaseOrderItem';
import { PurchaseOrder } from './purchaseOrder';
import { ModifiedDate } from './modifiedDate';


export interface PurchaseOrderContainer { 
    cartonQuantity?: number;
    cartonWeight?: number;
    createdDate?: ModifiedDate;
    lastUpdated?: ModifiedDate;
    purchaseOrder?: PurchaseOrder;
    purchaseOrderContainerId?: number;
    purchaseOrderId?: number;
    purchaseOrderItemList?: Array<PurchaseOrderItem>;
}

