/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Audit } from './audit';
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';


export interface Location { 
    address1?: string;
    address2?: string;
    address3?: string;
    address4?: string;
    address5?: string;
    address6?: string;
    auditList?: Array<Audit>;
    capacity?: number;
    city?: string;
    cityCode?: string;
    cityCodeQualifier?: string;
    countryName?: string;
    createdDate?: ModifiedDate;
    genericLocationFlag?: boolean;
    geographyGroup?: string;
    isoCountryCode?: string;
    lastUpdated?: ModifiedDate;
    latitude?: string;
    locationCode?: string;
    locationId?: number;
    locationName?: string;
    locationReferenceList?: Array<Location>;
    locationType?: string;
    longitude?: string;
    orgRef?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    origLocationId?: number;
    postalCode?: string;
    state?: string;
    timeZone?: string;
    timeZoneName?: string;
}

