/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Navigation } from './navigation';
import { Permission } from './permission';
import { TaxStatus } from './taxStatus';


export interface Organization { 
    accountNavigation?: Navigation;
    active?: boolean;
    addOrg?: boolean;
    aplusService?: boolean;
    badge?: string;
    chatGptService?: boolean;
    connectorId?: string;
    description?: string;
    endDate?: string;
    externalId?: string;
    id?: number;
    importWizardFlag?: boolean;
    inforNexusFlag?: boolean;
    isoCountryCode?: string;
    isoCurrencyCode?: string;
    isoLanguageCode?: string;
    lineSheets?: boolean;
    logo?: string;
    lowerNavigation?: Array<Navigation>;
    lspFlag?: boolean;
    matrixFieldName?: string;
    name?: string;
    noTradingPartnerAdd?: boolean;
    parentId?: number;
    parentName?: string;
    partnerId?: number;
    partnerName?: string;
    permissions?: Array<Permission>;
    removeOrg?: boolean;
    scacCode?: string;
    serviceType?: string;
    serviceTypeDefault?: string;
    startDate?: string;
    taxStatus?: TaxStatus;
    type?: string;
    upperNavigation?: Array<Navigation>;
    validValueAiFlag?: boolean;
    verificationService?: boolean;
}

