import { Component, OnInit, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @HostBinding('class.bigger') @Input() bigger = false;
  @HostBinding('class.smaller') @Input() smaller = false;
  @HostBinding('class.smallest') @Input() smallest = false;

  constructor() { }

  ngOnInit() {
  }

}
