/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Audit } from './audit';
import { Comment } from './comment';
import { PurchaseOrderContainer } from './purchaseOrderContainer';
import { VesselVoyage } from './vesselVoyage';
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';
import { Leg } from './leg';
import { Location } from './location';
import { WorkflowStatus } from './workflowStatus';


export interface Shipment { 
    arrivalDate?: string;
    auditList?: Array<Audit>;
    availableDate?: string;
    bilOfLading?: string;
    bobTail?: boolean;
    broker?: string;
    building?: string;
    businessUnit?: string;
    chassisNumber?: string;
    claimEmptyBy?: string;
    claimEmptyDate?: string;
    claimFullBy?: string;
    claimFullDate?: string;
    clientCategory?: string;
    clientPriority?: string;
    comment?: Comment;
    commentList?: Array<Comment>;
    containerDischargeDate?: string;
    containerId?: number;
    containerNumber?: string;
    containerStatus?: string;
    containerUnitSizeType?: string;
    containerVolume?: number;
    containerVolumeUnit?: string;
    containerWeight?: number;
    containerWeightUnit?: string;
    createdDate?: ModifiedDate;
    currentLocation?: string;
    customerBLNumber?: string;
    customerBLScac?: string;
    customerHBLNumber?: string;
    customerHBLScac?: string;
    customerMasterBLNumber?: string;
    customerMasterBLScac?: string;
    customerReference?: string;
    customerVesselVoyage?: VesselVoyage;
    customsBroker?: string;
    customsStatus?: string;
    daysEmpty?: number;
    daysOut?: number;
    demurrageAmount?: number;
    demurrageHold?: boolean;
    detentionAmount?: number;
    dischargeDate?: string;
    emptyNotifyDate?: string;
    emptyReturnDate?: string;
    emptyReturnDrayCarrierDate?: string;
    freeTimeOffDock?: number;
    freightHold?: boolean;
    freightType?: string;
    gateInDcOut?: string;
    gateOutDate?: string;
    gateOutDateTime?: string;
    gateOutDcDate?: string;
    hazmatFlag?: boolean;
    ladingQuantity?: number;
    lastFreeDay?: string;
    lastFreeDayOffDock?: string;
    lastFreeDayOffDockManual?: string;
    lastFreeDayTerminal?: string;
    lastFreeDayTerminalEst?: string;
    lastFreeDayTerminalManual?: string;
    lastSuccessDate?: string;
    lastTraceDate?: string;
    lastUpdated?: ModifiedDate;
    legList?: Array<Leg>;
    lineHold?: boolean;
    locationAlternatePickup?: Location;
    locationAlternateReturn?: Location;
    locationDelivery?: Location;
    locationFinalCity?: Location;
    locationFinalDestination?: Location;
    locationPortOfDischarge?: Location;
    manualHold?: string;
    mode?: string;
    nextTraceDate?: string;
    onRailDate?: string;
    orderReference?: string;
    orgRefInterchangeCarrier?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    orgRefOceanCarrier?: OrganizationReference;
    orgRefShipper?: OrganizationReference;
    originalVoyageName?: string;
    overWeight?: boolean;
    portOfLoading?: string;
    priority?: string;
    processing?: boolean;
    profitToolsId?: number;
    program?: string;
    ptAvailableForPickup?: string;
    pttCompleteDate?: string;
    purchaseOrderContainerList?: Array<PurchaseOrderContainer>;
    railArrivalDate?: string;
    railETA?: string;
    sealNumber?: string;
    searchEdit?: boolean;
    shipmentId?: number;
    shipmentReference?: string;
    shipmentReference2?: string;
    sourceAvailableDate?: string;
    sourceDischargeDate?: string;
    sourceGateOutDate?: string;
    sourceOnRailDate?: string;
    sourceRailArrivalDate?: string;
    sourceRailETA?: string;
    statsInterfaceId?: number;
    terminalAvailable?: boolean;
    terminalCustomsHold?: boolean;
    terminalFeeDue?: boolean;
    terminalFeePaid?: boolean;
    terminalHold?: boolean;
    traceSuccess?: boolean;
    transshipValidation?: boolean;
    usdaHold?: boolean;
    validate?: ModifiedDate;
    validated?: boolean;
    validationComment?: string;
    vesselVoyage?: VesselVoyage;
    workflowStatus?: WorkflowStatus;
    yardLocation?: string;
    yardStatus?: string;
}

