import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type MessageEventType = 'UUID' | 'Export' | 'Heartbeat' | 'Start' | 'Finished' | 'Progress' | 'BulkUpdate';

export const MessageEventType = {
  UUID: 'UUID' as MessageEventType,
  Export: 'Export' as MessageEventType,
  Heartbeat: 'Heartbeat' as MessageEventType,
  Start: 'Start' as MessageEventType,
  Stats: 'Stats' as MessageEventType,
  Finished: 'Finished' as MessageEventType,
  Progress: 'Progress' as MessageEventType,
  BulkUpdate: 'BulkUpdate' as MessageEventType
};

@Injectable({
  providedIn: 'root'
})
export class ServerSentEventService {

  constructor() { }

  connect(url: string, withCredentials: boolean = false): Observable<MessageEvent<any>> {
    if (!url) {
      return;
    }
    return new Observable(observer => {
      const eventSource: EventSource = new EventSource(url, { withCredentials });

      Object.keys(MessageEventType).forEach((type => {
        eventSource.addEventListener(type, (message: MessageEvent<any>) => {
          observer.next(message);
          // if (type === MessageEventType.Finished) {
          //   observer.complete();
          // }
        });
      }));

      eventSource.onerror = error => {
        observer.error(error);
      };

      observer.add(() => {
        eventSource.close();
      });
    });
  }
}
