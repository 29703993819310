import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Validators, UntypedFormControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Catalog, FilterJoin, FilterMatch, NotificationType, OpenaiService, OrgApiService, Organization, SuggestedMatch, Suggestion, TemplateApiService, ToastService, TradingPartnerAlignment } from '@app/core';
import { ApplicationService } from '../../../services/application.service';
import { SuggestionFilter } from '@app/shared/components/input/suggestion/suggestion.component';
import { Store } from '@ngxs/store';
import { Observable, Subscription, from, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { SuggestionList } from '@app/shared/typings/suggestion-list.types';
import { GobackToAISummary, RunAiBot } from '@app/features/destination/stores/destination-export-mapping.actions';

@Component({
  selector: 'app-valid-value',
  templateUrl: './valid-value.component.html',
  styleUrls: ['./valid-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ValidValueComponent implements OnInit, OnChanges, OnDestroy {
  @Input() destination: Catalog;
  @Input() alignments$: Observable<Array<TradingPartnerAlignment>>;
  @Input() alignmentsLoading = false;
  @Input() activeInternalColumnName: string;
  @Input() internalFieldSuggestions: Array<Suggestion> = [];
  @Input() fieldListSuggestions: Array<Suggestion> = [];
  @Input() selectedSyndic8Field: string;
  @Input() readOnlyField: boolean = false;
  @Input() showCloseOption: boolean = true;
  @Input() contextReference: string;
  @Output() validValueOptionSelected: EventEmitter<string> = new EventEmitter();
  @Input() activeTemplateField: Suggestion;
  @Input() AddOrSaveBrandTpAlignmentAction: ({ alignment }: { alignment: TradingPartnerAlignment }) => void;
  @Input() RemoveBrandTpAlignmentAction: ({ tradingPartnerBrandId, columnName, internalColumnName }: { tradingPartnerBrandId: number, columnName: string, internalColumnName: string }) => void;
  @Input() LoadBrandTpAlignmentsAction: ({ columnName, internalColumnName }: { columnName: string, internalColumnName: string }) => void;
  @Output() newItemEvent = new EventEmitter<string>();

  /* Valid values AI values */
  uniqueInternalFields: any;
  visibleIndices = new Set<number>();
  visibleIndices_2 = new Set<number>();
  visibleIndices_3 = new Set<number>();
  alignmentsForm: UntypedFormArray = new UntypedFormArray([]);
  newAlignmentsForm: UntypedFormArray = new UntypedFormArray([]);
  selectedDefaultForm: UntypedFormGroup = new UntypedFormGroup({
    tradingPartnerId: new UntypedFormControl('', Validators.required)
  });
  activeTemplateFieldTemp: Suggestion;
  tradingPartnerFieldFilters: SuggestionFilter;
  alignmentsSub: Subscription;
  validValueBot: boolean = false;
  contextReference_value: string;
  selectedValue: any;
  AISuggestedMatches: SuggestedMatch[] = [];
  AISuggestionMatchesCompleted: any;
  noResults: boolean = false;
  suggestionList: { [key: string]: Suggestion[]; };
  acceptedAlignments: any = [];
  completedAlignments: any = [];
  alignmentsObject: any[];
  selectedTradingPartnerIdvalue: any = [];
  showSpinner: boolean = false;
  showAITooltipTPfield = false;
  enableBotButton: boolean = false;
  disableAcceptAllButton: boolean = false;
  showModalNewValidValueFromAligments: boolean = false;
  noResultsForNewValidValue: boolean = false;
  showResultsForNewValidValue: boolean = false;
  newValidValue: any[];
  activeColumnName: string;
  /* END -  Valid values AI values */
  constructor(private store: Store, private appSvc: ApplicationService, private openAIService: OpenaiService, private orgApi: OrgApiService, private templateApi: TemplateApiService, private toast: ToastService) {
  }
  ngOnInit(): void {
    this.selectedTradingPartnerIdvalue = []
    this.getUniqueInternalFieldsAndAImatches()
    this.alignmentsSub = this.alignments$.subscribe(
      (alignments: Array<TradingPartnerAlignment>) => {
        if (alignments && alignments.length > 0) {
          for (var item of Array.from(this.visibleIndices.values())) {
            this.visibleIndices.delete(item);
          }
          this.alignmentsObject = alignments
          //console.log('alignmemnts=>', this.alignmentsObject)
          // sorting alignments by aiPercent.
          let sortAlignments = [...this.alignmentsObject]
          let finalSortAlignments = sortAlignments?.sort((a, b) => a?.aiPercent - b?.aiPercent);
          //console.log('alignmemnts 2=>', finalSortAlignments)      
          this.alignmentsObject = finalSortAlignments
          this.alignmentsForm.clear();
          alignments.forEach(alignment => {
            this.alignmentsForm.push(this.appSvc.buildFormGroup(alignment));
          });
        }
        this.AISuggestionMatchesCompleted = alignments
        const newArrayOfObj = this.AISuggestionMatchesCompleted.map(({
          columnValueTradingPartner: name,
          ...rest
        }) => ({
          name,
          ...rest
        }));
        this.AISuggestionMatchesCompleted = newArrayOfObj
      }
    );
    this.tradingPartnerFieldFilters = {
      by: ['columnName', 'destinationId'],
      join: [FilterJoin.AND, FilterJoin.AND],
      match: [FilterMatch.IS, FilterMatch.IS],
      value: [this.activeTemplateField?.name, this.destinationId]
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedSyndic8Field && changes.selectedSyndic8Field.currentValue) {
      if (changes.selectedSyndic8Field.currentValue !== changes.selectedSyndic8Field.previousValue) {
        //console.log('hello=>',changes)
        let str = changes.activeTemplateField?.currentValue?.fieldStartMatch;
        let result = str.split(":");
        this.selectedSyndic8Field = result[1];
        this.contextReference_value = result[1];
        this.contextReference = result[1];
        this.activeTemplateFieldTemp = this.activeTemplateField;
        this.activeInternalColumnName = this.selectedSyndic8Field;
        this.loadBrandTpAlignments(this.activeTemplateField);
        this.enableBotButton = false
        this.getUniqueInternalFieldsAndAImatches()
        this.newAlignmentsForm.clear();
        this.validValueBot = false
      }
    }
  }

  ngOnDestroy(): void {

    this.store.dispatch(new RunAiBot({ runAiBot: false }));
    if (this.alignmentsSub) {
      this.alignmentsSub.unsubscribe();
    }
  }

  clearAlignmentValue(event, i) {

    const columnValueTradingPartner = null
    const aiPercent = null
    const tradingPartnerId = this.alignmentsObject[i].tradingPartnerId
    const columnValueBrand = this.alignmentsObject[i].columnValueBrand
    const internalColumnName = this.alignmentsObject[i].internalColumnName
    const alignment: TradingPartnerAlignment = { columnValueTradingPartner, tradingPartnerId: tradingPartnerId, columnValueBrand, internalColumnName, aiPercent };
    this.store.dispatch(new this.AddOrSaveBrandTpAlignmentAction({ alignment }));
    if (!this.visibleIndices.delete(i)) {
      this.visibleIndices.delete(i);
    }
  }
  getUniqueInternalFieldsAndAImatches() {
    if (this.contextReference) {
      this.contextReference_value = this.contextReference
      /** 1st - Let's retrieve the Unique Internal Fields */
      const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
      let includeString = 'Product Distinct Values_' + this.contextReference_value?.toUpperCase()
      this.orgApi.getSuggestions([includeString], organization.id).pipe(
        tap(
          (internalFields: any) => {
            this.uniqueInternalFields = internalFields[includeString]
            //console.log('unique internal fields=>', this.uniqueInternalFields)
            if (this.uniqueInternalFields?.length === 0 || this.uniqueInternalFields == null || this.uniqueInternalFields == undefined) {
              this.noResults = true;
            }
            let matchValues: Array<string> = [];
            for (let i = 0; i < this.uniqueInternalFields?.length; i++) {
              matchValues.push(this.uniqueInternalFields[i]?.name)
            }
            /** 2nd - Let's retrieve the AI matches for the Recommended Destination Values*/
            const fieldObject_2 = {
              contextName: 'Valid Values',
              contextReference: this.activeTemplateFieldTemp.name,
              destinationId: this.destination.destinations[0].id,
              matchValues: matchValues
            }
            /** AI endpoint call */
            this.openAIService.aiSuggestedMatch(organization.id, fieldObject_2).pipe(
              tap((matches: Array<SuggestedMatch>) => {
                this.AISuggestedMatches = matches
                this.enableBotButton = true
                console.log('AI matches=>', matches)
              }),
              catchError(error => {
                this.enableBotButton = true
                return of(error);

              })
            ).subscribe();
          }
        )
      ).subscribe();
    }
    this.tradingPartnerFieldFilters = {
      by: ['columnName', 'destinationId'],
      join: [FilterJoin.AND, FilterJoin.AND],
      match: [FilterMatch.IS, FilterMatch.IS],
      value: [this.activeTemplateField?.name, this.destinationId]
    };

    /* Getting suggestions list */

    const limit = 500;
    const pageToGet = null;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    let SuggestionFilter = {
      by: [],
      join: [],
      match: [],
      value: []
    };
    SuggestionFilter = this.tradingPartnerFieldFilters
    let by: Array<string> = [...(SuggestionFilter.by ? SuggestionFilter.by : [])];
    let join: Array<FilterJoin> = [...(SuggestionFilter.join ? SuggestionFilter.join : [])];
    let match: Array<FilterMatch> = [...(SuggestionFilter.match ? SuggestionFilter.match : [])];
    let value: Array<string> = [...(SuggestionFilter.value ? SuggestionFilter.value : [])];
    this.orgApi.getSuggestions(['Trading Partner Field Values'], organization.id, by, join, match, value).pipe(
      mergeMap(suggestions => {
        if (suggestions && suggestions['Trading Partner Field Values'] && Array.isArray(suggestions['Trading Partner Field Values'])) {
          this.suggestionList = suggestions
          //console.log('suggestion list =>', suggestions)
          return of(suggestions);
        }
        return throwError('Invalid Suggestion response');
      }),
      catchError(error => {
        return throwError(error);
      })
    ).subscribe(
      (suggestions: SuggestionList) => {
        this.suggestionList = suggestions
      }
    );
  }

  get destinationId(): string {
    return this.destination.destinations[0].id.toString();
  }

  get excludedSyndic8Values(): Array<string> {
    const alignments: Array<TradingPartnerAlignment> = this.newAlignmentsForm.value;
    return alignments.map(a => a.columnValueBrand);
  }

  selectSyndic8Field(suggestion: string): void {
    console.log('suggestion=>>', suggestion)
    this.newItemEvent.emit(suggestion);
    if (this.contextReference_value) {
      // we do nothing
    } else {
      this.contextReference_value = suggestion
    }
    this.contextReference_value = suggestion
    console.log('hello value 2 =>', this.selectedSyndic8Field)
    this.selectedSyndic8Field = suggestion;
    this.contextReference = suggestion
    this.enableBotButton = false;
    this.validValueBot = false
    this.getUniqueInternalFieldsAndAImatches()
    this.activeTemplateFieldTemp = this.activeTemplateField;
    if (this.activeTemplateField) {
      this.selecTradingPartnerField(this.activeTemplateField);
    }
  }

  selecTradingPartnerField(suggestion: Suggestion): void {
    this.validValueOptionSelected.emit(this.selectedSyndic8Field);
    this.activeTemplateFieldTemp = suggestion;
    this.loadBrandTpAlignments(suggestion);
  }

  private loadBrandTpAlignments(suggestion: Suggestion): void {
    if (!suggestion?.name) {
      return;
    }
    const columnName: string = suggestion.name;
    const internalColumnName: string = this.activeInternalColumnName;
    this.store.dispatch(new this.LoadBrandTpAlignmentsAction({ columnName, internalColumnName }));
  }
  /* Adds/saves AI automatches as alignments */
  addNewAlignments(): void {
    this.disableAcceptAllButton = false
    const internalColumnName: string = this.activeInternalColumnName;
    const aiPercent: string = null;
    const aiGenerated: boolean = true;
    const alignments: Array<any> = this.newAlignmentsForm.value.map(
      ({ columnValueBrand, tradingPartnerId }) => {
        return { tradingPartnerId: parseInt(tradingPartnerId), columnValueBrand, internalColumnName, aiPercent, aiGenerated };
      }
    );

    for (let i = 0; i < alignments.length; i++) {
      alignments[i].columnValueBrand = alignments[i].columnValueBrand.name
    }

    for (let i = 0; i < alignments.length; i++) {
      let newElement = this.suggestionList['Trading Partner Field Values'].find(f => f.name == alignments[i].columnValueBrand);
      let tpIdValue = this.selectedTradingPartnerIdvalue?.find(f => f.index == i);
      let aiPercentage = this.AISuggestedMatches.find(f => f.inputName == alignments[i].columnValueBrand);

      if (newElement && !tpIdValue) {
        alignments[i].tradingPartnerId = parseInt(newElement?.id)
        alignments[i].aiPercent = aiPercentage?.resultValue ?? 0

      } else if (tpIdValue) {
        alignments[i].tradingPartnerId = parseInt(tpIdValue?.element)
        alignments[i].aiPercent = aiPercentage?.resultValue ?? 0
        alignments[i].aiGenerated = false
      } else {
        let match = this.AISuggestedMatches.find(f => f.inputName == alignments[i].columnValueBrand);
        let nextElement = this.suggestionList['Trading Partner Field Values'].find(f => f.name == match.id);
        alignments[i].tradingPartnerId = parseInt(nextElement?.id)
        alignments[i].aiPercent = aiPercentage?.resultValue ?? 0

      }
    }

    from(alignments).pipe(
      mergeMap(alignment => this.store.dispatch(new this.AddOrSaveBrandTpAlignmentAction({ alignment })))
    ).subscribe(
    );

    this.toast.add({
      title: 'Success',
      expiration: 3000,
      message: 'Trading Partner Alignments added!',
      type: NotificationType.SUCCESS
    })

    this.validValueBot = false
    this.selectedTradingPartnerIdvalue = []
    this.alignmentsLoading = true

    setTimeout(() => {
      this.alignmentsLoading = false
    }, 700);
    setTimeout(() => {
      this.store.dispatch(new GobackToAISummary({ goBackToAISummary: true }));
    }, 2100);

  }

  removeAllAlignments() {
    this.selectedTradingPartnerIdvalue = []
    for (let i = 0; i < this.alignmentsObject.length; i++) {
      const tradingPartnerBrandId = this.alignmentsObject[i]?.tradingPartnerBrandId;
      const columnName: string = this.alignmentsObject[i]?.name;
      const internalColumnName: string = this.activeInternalColumnName;
      this.store.dispatch(new this.RemoveBrandTpAlignmentAction({ tradingPartnerBrandId, columnName, internalColumnName }));
    }
    this.newAlignmentsForm.clear();
    this.alignmentsObject = []
  }

  tradingPartnerValueOptionSelected({ id, name }: Suggestion, index: number): void {
    const form: UntypedFormGroup = this.alignmentsForm.at(index) as UntypedFormGroup;
    form.patchValue({ tradingPartnerId: id, columnValueTradingPartner: name });
    form.markAsDirty();

  }
  updateAlignmentValue(event, i) {
    if (!this.visibleIndices_2.delete(i)) {
      this.visibleIndices_2.add(i);
    }
    const columnValueTradingPartner = event.name
    const tradingPartnerId = event.id
    const columnValueBrand = this.alignmentsObject[i].columnValueBrand
    const internalColumnName = this.alignmentsObject[i].internalColumnName
    const alignment: TradingPartnerAlignment = { columnValueTradingPartner, tradingPartnerId: tradingPartnerId, columnValueBrand, internalColumnName };
    this.store.dispatch(new this.AddOrSaveBrandTpAlignmentAction({ alignment }));
    setTimeout(() => {
      if (!this.visibleIndices_2.delete(i)) {
        this.visibleIndices_2.delete(i);
      }
      this.visibleIndices.add(i);
    }, 1000);

    setTimeout(() => {
      this.visibleIndices.delete(i);
    }, 2300);

  }
  selectDefaultTradingPartnerId(): void {
    this.newAlignmentsForm.controls.forEach(control => control.patchValue({
      tradingPartnerId: this.selectedDefaultForm.value.tradingPartnerId
    }));
  }

  /* A valid value bot button runs the algorithm at a granular level */
  runAiBot(): void {
    this.selectedTradingPartnerIdvalue = []
    this.disableAcceptAllButton = false
    let tempAISuggestionMatches: any = [];
    for (let i = 0; i < this.uniqueInternalFields?.length; i++) {
      const test = this.AISuggestedMatches.find(a => a.inputName === this.uniqueInternalFields[i].name);
      if (test === undefined) {
        let noMatch =
        {
          id: null,
          inputName: this.uniqueInternalFields[i].name,
          matchList: null,
          name: null,
          resultValue: 0
        }
        tempAISuggestionMatches.push(noMatch)
      } else {
        tempAISuggestionMatches.push(test)
      }
    }
    this.AISuggestionMatchesCompleted = tempAISuggestionMatches
    //  sorting AI Automatches results (Sort by correlation from low to high)
    let sortSuggestions = this.AISuggestionMatchesCompleted.sort(({ resultValue: a }, { resultValue: b }) => a - b);
    let sorted = []
    sortSuggestions.forEach(k => {
      let n = this.uniqueInternalFields.filter(obj => {
        return obj.name === k.inputName
      })
      if (n.length > 0) {
        sorted.push(n[0]);
      }
    })
    this.uniqueInternalFields = sorted
    for (let i = 0; i < this.AISuggestionMatchesCompleted?.length; i++) {
      if (this.AISuggestionMatchesCompleted[i].name == null) {
        this.visibleIndices_3.delete(i)
      } else {
        this.visibleIndices_3.add(i)
      }
    }

    if (this.newAlignmentsForm.length == 0) {
      for (let i = 0; i < this.uniqueInternalFields?.length; i++) {
        this.newAlignmentsForm.push(new UntypedFormGroup({
          columnValueBrand: new UntypedFormControl(this.uniqueInternalFields[i], Validators.required),
          tradingPartnerId: new UntypedFormControl(this.AISuggestionMatchesCompleted[i], Validators.required)
        }));
      }
    }
    this.validValueBot = true
    for (let i = 0; i < this.newAlignmentsForm?.value.length; i++) {
      if (this.newAlignmentsForm?.value[i].tradingPartnerId.name == null) {
        // disabling Accept all button until all selects have a value
        this.disableAcceptAllButton = true
      } else {
        // button remains enabled
      }
    }
  }

  caseFunctionSelected(element: any, index?: any): void {
    const existingselectedTradingPartnerIdvalue = this.selectedTradingPartnerIdvalue.find(a => a.index === index);
    if (existingselectedTradingPartnerIdvalue) {
      const indexOfTheValue = this.selectedTradingPartnerIdvalue.indexOf(existingselectedTradingPartnerIdvalue);
      this.selectedTradingPartnerIdvalue.splice(indexOfTheValue, 1);
    }
    this.selectedTradingPartnerIdvalue.push({ element: element, index: index })
    this.visibleIndices_3.add(index);
    parseInt(element)
    const internalColumnName: string = this.activeInternalColumnName;
    const suggestionListValue = this.suggestionList['Trading Partner Field Values'].find(a => a.id === element);
    const resultValueForMatch = this.AISuggestedMatches.find(a => a.inputName === suggestionListValue.name);
    this.newAlignmentsForm.controls[index].value.tradingPartnerId = {
      id: element,
      inputName: element,
      matchList: null,
      name: element,
      resultValue: resultValueForMatch?.resultValue ?? 0,
    }
    this.newAlignmentsForm.value[index].tradingPartnerId = {
      id: element,
      inputName: element,
      matchList: null,
      name: element,
      resultValue: resultValueForMatch?.resultValue ?? 0,
    }
    for (let i = 0; i < this.newAlignmentsForm?.value.length; i++) {
      if (this.newAlignmentsForm?.value[i].tradingPartnerId.name !== null) {
        // disabling Accept all button until all selects have a value
        this.disableAcceptAllButton = false
      } else {
        this.disableAcceptAllButton = true
        // button remains enabled
      }
    }
  }

  caseFunctionClosed(element?: any, index?: any): void {
    this.newAlignmentsForm.value[index].tradingPartnerId = null
    for (let i = 0; i < this.newAlignmentsForm?.value.length; i++) {
      if (this.newAlignmentsForm?.value[i].tradingPartnerId?.name == null || this.newAlignmentsForm?.value[i].tradingPartnerId == null) {
        // disabling Accept all button until all selects have a value
        this.disableAcceptAllButton = true
      } else {
        // button remains enabled
      }
    }
    this.visibleIndices_3.delete(index);
    this.newAlignmentsForm.controls[index] = null
    this.newAlignmentsForm.controls[index] =
      new UntypedFormGroup({
        columnValueBrand: new UntypedFormControl(this.uniqueInternalFields[index], Validators.required),
        tradingPartnerId: new UntypedFormControl(null, Validators.required)
      })
  }

  checkForNewValidValue() {
    this.newValidValue = []
    this.activeColumnName = this.alignmentsObject[0]?.columnName
    this.showModalNewValidValueFromAligments = true
    // same length equals no new valid values matching available, all are mapped saved and included
    if (this.AISuggestedMatches?.length == this.alignmentsObject?.length) {
      setTimeout(() => {
        this.noResultsForNewValidValue = true
        this.showResultsForNewValidValue = true
      }, 2000);
    } else {
      // there's new mapping available
      setTimeout(() => {
        this.noResultsForNewValidValue = false
        this.showResultsForNewValidValue = true
      }, 2000);
      // comparing arrays and extracting the value that's found in the matches array but not found in the alignments array 
      const differenceFromArray1 = this.AISuggestedMatches.filter(x => !this.alignmentsObject.filter(y => y.columnValueBrand === x.inputName).length);
      this.newValidValue = differenceFromArray1

    }
    // compare the suggested matches to the saved alignments, if we're missing one because it's been recently added to the system, we have to
    // allow the user to add it without having to remove all alignments.

  }
  optionSelectedForNewValidValue(event, index) {
    const suggestionListValue = this.suggestionList['Trading Partner Field Values'].find(a => a.id === event);
    this.newValidValue[index].name = suggestionListValue?.name
    this.newValidValue[index].id = suggestionListValue?.id
  }

  addNewValidValue() {
    // now we can add the alignment to the already existing aligment view and mappings saved
    for (let i = 0; i < this.newValidValue.length; i++) {

      const newValidValueAsAlignment = {
        aiGenerated: true,
        aiPercent: this.newValidValue[i].resultValue,
        columnName: this.activeColumnName, // make this dynamic and extract it from somewhere
        columnValueBrand: this.newValidValue[i].inputName,
        columnValueTradingPartner: this.newValidValue[i].name,
        internalColumnName: this.selectedSyndic8Field,
        //tradingPartnerId: this.newValidValue[i].id
      }
      this.store.dispatch(new this.AddOrSaveBrandTpAlignmentAction({ alignment: newValidValueAsAlignment })
      ).subscribe(
      );
    }
    this.showModalNewValidValueFromAligments = false
    this.noResultsForNewValidValue = true
    this.toast.add({
      title: 'Success',
      expiration: 3000,
      message: 'Trading Partner Alignment added!',
      type: NotificationType.SUCCESS
    })
  }
}