<ag-grid-angular #agGrid class="ag-theme-alpine"
                 [rowModelType]="rowModelType"
                 [gridOptions]="gridOptions"

                 [sideBar]="sideBar"
                 [rowSelection]="rowSelection"
                 [getRowId]="getRowId.bind(this)"
>
</ag-grid-angular>


<!--[columnDefs]="columnDefs"-->
<!--[gridOptions]="gridOptions"
[maintainColumnOrder]="maintainColumnOrder"
[rowModelType]="rowModelType"-->
<!--[getRowId]="getRowId" [defaultColDef]="defaultColDef" [tooltipShowDelay]="tooltipShowDelay" [class]="themeClass"-->
<!--[tooltipInteraction]="true" [rowSelection]="rowSelection" [suppressRowClickSelection]="true"-->
<!--(rowSelected)="onRowSelected($event)" (selectionChanged)="onSelectionChanged($event)"-->
<!--(cellClicked)='onCellClicked($event)' (cellEditingStarted)="onCellEditStart($event)"-->
<!--(cellEditingStopped)="onCellEditStopped($event)" (cellValueChanged)="onCellValueChanged($event)"-->
<!--(cellMouseOver)="onCellMouseOver($event)" [sideBar]="sideBar"-->
