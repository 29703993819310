/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Audit } from './audit';
import { Comment } from './comment';
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';
import { Location } from './location';
import { WorkflowStatus } from './workflowStatus';


export interface Leg { 
    acceptedDate?: string;
    assignedBy?: string;
    assignedDate?: string;
    auditList?: Array<Audit>;
    billedDate?: string;
    blumeFlag?: boolean;
    bobTail?: boolean;
    category?: string;
    chassisNumber?: string;
    comment?: Comment;
    commentList?: Array<Comment>;
    containerId?: number;
    contributingMotorCarrier?: string;
    createdDate?: ModifiedDate;
    dcAppointmentNumber?: string;
    deliveryAck?: boolean;
    deliveryActualDate?: string;
    deliveryEnteredDate?: string;
    deliveryOverrideDate?: string;
    deliveryScheduleDate?: string;
    deliverySource?: string;
    deliveryTransactionDate?: string;
    fulfillmentMotorCarrier?: string;
    lastUpdated?: ModifiedDate;
    latestLeg?: boolean;
    legId?: number;
    legNumber?: number;
    loadStatus?: string;
    locationDelivery?: Location;
    locationPickup?: Location;
    message?: string;
    messageResponse?: string;
    messageStatus?: number;
    orgRefAllocatedCarrier?: OrganizationReference;
    orgRefDispatch?: OrganizationReference;
    orgRefInterchangedCarrier?: OrganizationReference;
    pickupAck?: boolean;
    pickupActualDate?: string;
    pickupEnteredDate?: string;
    pickupEnteredScheduleDate?: string;
    pickupNumber?: string;
    pickupOverrideDate?: string;
    pickupScheduleDate?: string;
    pickupSource?: string;
    pickupTransactionDate?: string;
    readyForDispatch?: boolean;
    rejectedCopied?: boolean;
    shipmentId?: number;
    specialInstructions?: string;
    statsInterfaceId?: number;
    stopType1?: string;
    stopType2?: string;
    streetTurn?: boolean;
    workOrderNumber?: string;
    workflowStatus?: WorkflowStatus;
}

