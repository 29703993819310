import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Suggestion } from '@app/core';

export type MediaChipType = 'Literal' | 'Variable';

export const MediaChipType = {
    Literal: 'Literal' as MediaChipType,
    Variable: 'Variable' as MediaChipType
};

export interface MediaChip {
  value: string;
  type: MediaChipType;
};

@Component({
  selector: 'app-media-chip-list',
  templateUrl: './media-chip-list.component.html',
  styleUrls: ['./media-chip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaChipListComponent implements OnInit {
  @Input() showRemove = true;
  @Input() chipString: string;
  @Output() chipStringChange: EventEmitter<string> = new EventEmitter();
  @Input() suggestions: Array<Suggestion> = [];

  MediaChipType = MediaChipType;

  constructor() { }

  ngOnInit(): void {
  }

  removeChip(index: number): void {
    const chips = [...this.chips.slice(0, index), ...this.chips.slice(index + 1)];
    this.chipString = '';
    chips.forEach(chip => {
      if (chip.type === MediaChipType.Literal) {
        this.chipString += chip.value;
      } else if (chip.type === MediaChipType.Variable) {
        const value = this.suggestions?.find(s => s.name === chip.value)?.id;
        if (value) {
          this.chipString += '${'+value.replace('InternalName:', '')+'}';
        }
      }
    })
    this.chipStringChange.emit(this.chipString);
  }

  get chips(): Array<MediaChip> {
    let chips: Array<MediaChip> = [];
    if (this.chipString) {
      this.chipString.split('${').filter(c => c).forEach(str => {
        const endOfVarIndex = str.indexOf('}')
        if (endOfVarIndex > -1) {
          const value = this.suggestions?.find(s => s.id === `InternalName:${str.slice(0, endOfVarIndex)}`)?.name;
          if (value) {
            chips = [...chips, {
              type: MediaChipType.Variable,
              value
            }];
          } else {
            chips = [...chips, {
              type: MediaChipType.Variable,
              value: str.slice(0, endOfVarIndex)
            }];
          }
          if (str.slice(endOfVarIndex + 1)) {
            chips = [...chips, {
              type: MediaChipType.Literal,
              value: str.slice(endOfVarIndex + 1)
            }];
          }
        } else {
          chips = [...chips, {
            type: MediaChipType.Literal,
            value: str
          }];
        }
      });
    }
    return chips;
  }

}
