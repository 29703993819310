/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Location } from './location';


export interface PurchaseOrderDetail { 
    cityOfOrigin?: string;
    countryOfOrigin?: string;
    cubicMeters?: number;
    endDate?: string;
    etaDate?: string;
    finalDestination?: string;
    finalDestinationQualifier?: string;
    firstCostCurreny?: string;
    firstCostPrice?: number;
    htsCode?: string;
    jsonData?: { [key: string]: any; };
    landedCostCurreny?: string;
    landedCostPrice?: number;
    lineCost?: number;
    locationDestination?: Location;
    masterBLNumber?: string;
    packQuantity?: number;
    packageCount?: number;
    poNumber?: string;
    portOfLoading?: string;
    portOfLoadingQualifier?: string;
    pricePerUnit?: number;
    proNumber?: string;
    productCode?: string;
    productId?: number;
    productName?: string;
    productNumber?: string;
    purchaseOrderDetailId?: number;
    purchaseOrderId?: number;
    quantity?: number;
    quantityUOM?: string;
    retailCostCurreny?: string;
    retailCostPrice?: number;
    shipWindowEndDate?: string;
    shipWindowStartDate?: string;
    sku?: string;
    startDate?: string;
    transportationMode?: string;
    upc?: string;
    weight?: number;
}

