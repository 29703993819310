/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Email } from './email';
import { UserReference } from './userReference';
import { CatalogExportType } from './catalogExportType';
import { OrganizationReference } from './organizationReference';


export interface CatalogExport { 
    cancelFlag?: boolean;
    cancelUser?: string;
    comments?: string;
    createdBy?: UserReference;
    destination?: OrganizationReference;
    emailList?: Array<Email>;
    endDate?: string;
    failCount?: number;
    fileErrorMessage?: string;
    id?: number;
    name?: string;
    objectUrl?: string;
    passCount?: number;
    pending?: boolean;
    runStatus?: string;
    startDate?: string;
    timestamp?: string;
    totalCount?: number;
    type?: CatalogExportType;
}

