<ng-container *ngIf="!loading">
  <ng-container *ngIf="showDestinations">
    <div class="label">Across Trading Partner</div>
    <app-suggestion [provided]="destinationsSuggestions" label="Select a trading partner" [showClose]="true"
      [showList]="true" [selected]="['Default']" (optionSelected)="fetchProductTypePropertiesForSeo($event)"
      [resetAfterNavigation]="false" [form]="form" field="destinationId" optionProp="id">
    </app-suggestion>
  </ng-container>

  <ng-container *ngIf="showProductTypes">
    <div class="label">Product Type</div>
    <app-suggestion [provided]="productTypeSuggestions" label="Select a product type" [showClose]="true"
      [showList]="true" (optionSelected)="fetchProductTypeProperties($event)" [form]="form" field="type"
      optionProp="name">
    </app-suggestion>
  </ng-container>

  <div class="label">Field to Update</div>
  <app-suggestion [provided]="activeFieldSuggestions" label="Select a field" [showClose]="true" [showList]="true"
    (optionSelected)="resetFieldValue(); resetAction() ; filedSelected($event)" (closed)="hideTypeInput = false" [disabled]="showProductTypes && !areProductTypePropertiesFetched" [form]="form"
    field="fieldName" optionProp="id">
  </app-suggestion>
  <ng-container *ngIf="!hideTypeInput">
      <div class="label">Update Type</div>
  <app-suggestion [provided]="actionSuggestions" label="Select an update type" [showClose]="true" [showList]="true"
    (optionSelected)="resetFieldValue()" [form]="form" field="action" optionProp="id">
  </app-suggestion>
  </ng-container>


  <!-- Only show for 'Clear' -->
  <ng-container *ngIf="form.value?.action === 'clear'">
    <div class="clear-warning">
      This will clear your chosen field for all selected rows. Are you sure?
    </div>
  </ng-container>
  <!-- Only show if not a 'Clear' -->
  <ng-container *ngIf="form.value?.action !== 'clear'">

    <!-- Find -->
    <ng-container *ngIf="form.value?.action === 'replaceMatch'">
      <div class="label">Find</div>
      <input type="text" [formControl]="form.get('replaceMatch')" />
    </ng-container>

    <div class="label" *ngIf="form.value?.action !== 'remove'; else notRemove">New Value</div>
    <ng-template #notRemove>
      <div class="label">Remove Value</div>
    </ng-template>
    <ng-container [ngSwitch]="getFieldByFieldName(form.value.fieldName)?.type">
      <ng-container *ngSwitchCase="BulkUpdateFieldType.Date">
        <app-date [formControl]="form.get('fieldValue')"></app-date>
      </ng-container>
      <ng-container *ngSwitchCase="BulkUpdateFieldType.Datetime">
        <app-date-time [formControl]="form.get('fieldValue')"></app-date-time>
      </ng-container>
      <ng-container *ngSwitchCase="BulkUpdateFieldType.Number">
        <input type="number" [formControl]="form.get('fieldValue')" (keyup.enter)="this.submit.emit()" />
      </ng-container>
      <ng-container *ngSwitchCase="BulkUpdateFieldType.Suggestion">
        <app-suggestion [include]="getFieldByFieldName(form.value.fieldName)?.suggestion"
          [label]="getFieldSuggestionLabel(form.value.fieldName)" [showList]="true" [form]="form" field="fieldValue"
          optionProp="name">
        </app-suggestion>
      </ng-container>
      <ng-container *ngSwitchCase="BulkUpdateFieldType.MultiSuggestion">
        <app-suggestion [include]="getFieldByFieldName(form.value.fieldName)?.suggestion"
          [label]="getFieldSuggestionLabel(form.value.fieldName)" [showList]="true" [form]="form" field="fieldValue"
          optionProp="name" [multiSelection]="true">
        </app-suggestion>
      </ng-container>
      <ng-container *ngSwitchCase="BulkUpdateFieldType.Boolean">
        <div class="boolean">
          <div class="checkbox">
            <app-checkbox #trueCheckbox [selected]="trueSelected" (selectedChange)="selectTrue()"></app-checkbox>
            <div class="label" (click)="trueCheckbox.check()">True</div>
          </div>
          <div class="checkbox">
            <app-checkbox #falseCheckbox [selected]="falseSelected" (selectedChange)="selectFalse()"></app-checkbox>
            <div class="label" (click)="falseCheckbox.check()">False</div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <input type="text" [formControl]="form.get('fieldValue')" (keyup.enter)="this.submit.emit()" />
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<div class="spinner" *ngIf="loading">
  <app-spinner></app-spinner>
</div>
