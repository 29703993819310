<app-panel [name]="property.displayName">
  <ng-template #header let-editable>
    <!-- <div class="flex-fill"></div>
    <app-panel-edit-lock (save)="save.emit()" (discard)="options.resetModel()"></app-panel-edit-lock> -->
  </ng-template>
  <ng-template #content let-editable>
    <form [formGroup]="form">
      <div class="description" *ngIf="property.description">
        <div class="name">Description:</div>
        <div class="value">{{ property.description }}</div>
      </div>
      <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
      <!-- <div *ngIf="!editable" class="property-value">{{ propertyValue }}</div> -->
    </form>
  </ng-template>
</app-panel>
