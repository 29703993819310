import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, ActivatedRoute, Data } from '@angular/router';

import { filter, map, mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService implements OnDestroy {

  routerEvents: Subscription;

  constructor(private title: Title, private router: Router, private activatedRoute: ActivatedRoute) {
    this.routerEvents = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe(
      (event: Data) => {
        if (event['title']) {
          this.setTitle(event['title']);
        } else {
          this.resetTitle();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.routerEvents.unsubscribe();
  }

  setTitle(title: String): void {
    this.title.setTitle(title + ' | Syndic8');
  }

  resetTitle(): void {
    this.title.setTitle('Syndic8');
  }

  getTitle(): string {
    return this.title.getTitle();
  }
}
