export * from './aPlusContentApi.service';
import { APlusContentApiService } from './aPlusContentApi.service';
export * from './adminApi.service';
import { AdminApiService } from './adminApi.service';
export * from './amazonApi.service';
import { AmazonApiService } from './amazonApi.service';
export * from './authAPI.service';
import { AuthAPIService } from './authAPI.service';
export * from './brandTpAlignmentApi.service';
import { BrandTpAlignmentApiService } from './brandTpAlignmentApi.service';
export * from './brandTpSpecApi.service';
import { BrandTpSpecApiService } from './brandTpSpecApi.service';
export * from './calendarApi.service';
import { CalendarApiService } from './calendarApi.service';
export * from './catalogApi.service';
import { CatalogApiService } from './catalogApi.service';
export * from './channelApi.service';
import { ChannelApiService } from './channelApi.service';
export * from './chartApi.service';
import { ChartApiService } from './chartApi.service';
export * from './clientApi.service';
import { ClientApiService } from './clientApi.service';
export * from './connectorApi.service';
import { ConnectorApiService } from './connectorApi.service';
export * from './containerApi.service';
import { ContainerApiService } from './containerApi.service';
export * from './costApi.service';
import { CostApiService } from './costApi.service';
export * from './createSseAPI.service';
import { CreateSseAPIService } from './createSseAPI.service';
export * from './dataflowApi.service';
import { DataflowApiService } from './dataflowApi.service';
export * from './docApi.service';
import { DocApiService } from './docApi.service';
export * from './dynamicTableApi.service';
import { DynamicTableApiService } from './dynamicTableApi.service';
export * from './edilogisticsApi.service';
import { EdilogisticsApiService } from './edilogisticsApi.service';
export * from './filterApi.service';
import { FilterApiService } from './filterApi.service';
export * from './gridApi.service';
import { GridApiService } from './gridApi.service';
export * from './imageApi.service';
import { ImageApiService } from './imageApi.service';
export * from './importApi.service';
import { ImportApiService } from './importApi.service';
export * from './languageApi.service';
import { LanguageApiService } from './languageApi.service';
export * from './locationApi.service';
import { LocationApiService } from './locationApi.service';
export * from './logisticsApi.service';
import { LogisticsApiService } from './logisticsApi.service';
export * from './logisticsDocApi.service';
import { LogisticsDocApiService } from './logisticsDocApi.service';
export * from './lookupGroupApi.service';
import { LookupGroupApiService } from './lookupGroupApi.service';
export * from './mappingApi.service';
import { MappingApiService } from './mappingApi.service';
export * from './messageApi.service';
import { MessageApiService } from './messageApi.service';
export * from './metricApi.service';
import { MetricApiService } from './metricApi.service';
export * from './notificationApi.service';
import { NotificationApiService } from './notificationApi.service';
export * from './openai.service';
import { OpenaiService } from './openai.service';
export * from './orgApi.service';
import { OrgApiService } from './orgApi.service';
export * from './parentApi.service';
import { ParentApiService } from './parentApi.service';
export * from './partnerApi.service';
import { PartnerApiService } from './partnerApi.service';
export * from './pluginsApi.service';
import { PluginsApiService } from './pluginsApi.service';
export * from './pricingApi.service';
import { PricingApiService } from './pricingApi.service';
export * from './productApi.service';
import { ProductApiService } from './productApi.service';
export * from './publicApi.service';
import { PublicApiService } from './publicApi.service';
export * from './publiclinkApi.service';
import { PubliclinkApiService } from './publiclinkApi.service';
export * from './purchaseOrderApi.service';
import { PurchaseOrderApiService } from './purchaseOrderApi.service';
export * from './reportApi.service';
import { ReportApiService } from './reportApi.service';
export * from './roleApi.service';
import { RoleApiService } from './roleApi.service';
export * from './sseApi.service';
import { SseApiService } from './sseApi.service';
export * from './subcatalogApi.service';
import { SubcatalogApiService } from './subcatalogApi.service';
export * from './subscriptionApi.service';
import { SubscriptionApiService } from './subscriptionApi.service';
export * from './syndicationApi.service';
import { SyndicationApiService } from './syndicationApi.service';
export * from './teamApi.service';
import { TeamApiService } from './teamApi.service';
export * from './templateApi.service';
import { TemplateApiService } from './templateApi.service';
export * from './testApi.service';
import { TestApiService } from './testApi.service';
export * from './tpAlignmentApi.service';
import { TpAlignmentApiService } from './tpAlignmentApi.service';
export * from './tpSpecApi.service';
import { TpSpecApiService } from './tpSpecApi.service';
export * from './uploadApi.service';
import { UploadApiService } from './uploadApi.service';
export * from './userApi.service';
import { UserApiService } from './userApi.service';
export * from './verificationApi.service';
import { VerificationApiService } from './verificationApi.service';
export * from './viewApi.service';
import { ViewApiService } from './viewApi.service';
export * from './workTaskApi.service';
import { WorkTaskApiService } from './workTaskApi.service';
export * from './workflowApi.service';
import { WorkflowApiService } from './workflowApi.service';
export * from './yardManagementApi.service';
import { YardManagementApiService } from './yardManagementApi.service';
export const APIS = [APlusContentApiService, AdminApiService, AmazonApiService, AuthAPIService, BrandTpAlignmentApiService, BrandTpSpecApiService, CalendarApiService, CatalogApiService, ChannelApiService, ChartApiService, ClientApiService, ConnectorApiService, ContainerApiService, CostApiService, CreateSseAPIService, DataflowApiService, DocApiService, DynamicTableApiService, EdilogisticsApiService, FilterApiService, GridApiService, ImageApiService, ImportApiService, LanguageApiService, LocationApiService, LogisticsApiService, LogisticsDocApiService, LookupGroupApiService, MappingApiService, MessageApiService, MetricApiService, NotificationApiService, OpenaiService, OrgApiService, ParentApiService, PartnerApiService, PluginsApiService, PricingApiService, ProductApiService, PublicApiService, PubliclinkApiService, PurchaseOrderApiService, ReportApiService, RoleApiService, SseApiService, SubcatalogApiService, SubscriptionApiService, SyndicationApiService, TeamApiService, TemplateApiService, TestApiService, TpAlignmentApiService, TpSpecApiService, UploadApiService, UserApiService, VerificationApiService, ViewApiService, WorkTaskApiService, WorkflowApiService, YardManagementApiService];
