import {
  Catalog,
  GroupBy,
  OrderBy,
  FilterMatch,
  FilterJoin,
  PluginPropertyValue,
  BulkUpdate,
  PublicLink,
  CatalogExport,
  ScheduleSyndication,
  FilterDefinition
} from '@app/core';
import { InfiniteScrollPage } from '@app/shared';

export class LoadDestinationAction {
  static readonly type = '[Destination Detail] Load a Destination by ID';
  constructor(public payload: { destinationId: Catalog['id'] }) { }
}

export class AppendDestinationProductsAction {
  static readonly type = '[Destination Detail] Append a list of Products for a Destination';
  constructor(public payload: InfiniteScrollPage) { }
}

export class SetDestinationProductsLoadingAction {
  static readonly type = '[Destination Detail] Set Products to loading state';
  constructor() { }
}

export class LoadDestinationExportsAction {
  static readonly type = '[Destination Detail] Load Destination Exports';
  constructor(public payload: { destinationId: Catalog['id'] }) { }
}

export class LoadCollectionSuggestionsAction {
  static readonly type = '[Destination Detail] Load all Collection suggestions for current Destination';
  constructor() { }
}

export class AddCollectionsToDestinationAction {
  static readonly type = '[Destination Detail] Add all Products from a list of Collections to a Destination';
  constructor(public payload: { collections: Array<Catalog>, destination: Catalog }) { }
}

export class DownloadDestinationExportAction {
  static readonly type = '[Destination Detail] Download Destination export';
  constructor(public payload: { productIds: Array<string> }) { }
}

export class StartDestinationExportWithFiltersAction {
  static readonly type = '[Destination Detail] Start a Destination Product export with query filters';
  constructor(public payload: {
    destination: Catalog,
    groupBy: GroupBy,
    sortBy: Array<string>,
    orderBy: Array<OrderBy>,
    filterBy: Array<string>,
    filterJoin: Array<FilterJoin>,
    filterMatch: Array<FilterMatch>,
    filterValue: Array<string>,
    templateName: string,
    comments?: string
  }) { }
}
///// RemoveProductsAction

export class RemoveProductsAction {
  static readonly type = '[Destination Detail] Remove product/s from A+ template ';
  constructor(public payload: {
    catalog: Catalog,
    groupBy: GroupBy,
    sortBy: Array<string>,
    orderBy: Array<OrderBy>,
    filterBy: Array<string>,
    filterJoin: Array<FilterJoin>,
    filterMatch: Array<FilterMatch>,
    filterValue: Array<string>,
  }) { }
}

////////////
export class StartDestinationExportWithIdsAction {
  static readonly type = '[Destination Detail] Start a Destination Product export with Product IDs';
  constructor(public payload: {
    destination?: Catalog,
    groupBy?: GroupBy,
    productIds: Array<string>,
    templateName?: string,
    comments?: string
  }) { }
}

export class StartDestinationImageExportWithFiltersAction {
  static readonly type = '[Destination Detail] Start a Destination Image export with query filters';
  constructor(public payload: {
    destination: Catalog,
    groupBy: GroupBy,
    sortBy: Array<string>,
    orderBy: Array<OrderBy>,
    filterBy: Array<string>,
    filterJoin: Array<FilterJoin>,
    filterMatch: Array<FilterMatch>,
    filterValue: Array<string>,
    templateName: string
  }) { }
}

export class StartDestinationImageExportWithIdsAction {
  static readonly type = '[Destination Detail] Start a Destination Image export with Product IDs';
  constructor(public payload: {
    destination: Catalog,
    groupBy: GroupBy,
    productIds: Array<string>,
    templateName: string
  }) { }
}

export class SyndicateDestinationWithFiltersAction {
  static readonly type = '[Destination Detail] Syndicate a Destination with query filters';
  constructor(public payload: {
    destination: Catalog,
    groupBy: GroupBy,
    sortBy: Array<string>,
    orderBy: Array<OrderBy>,
    filterBy: Array<string>,
    filterJoin: Array<FilterJoin>,
    filterMatch: Array<FilterMatch>,
    filterValue: Array<string>
  }) { }
}

export class SyndicateDestinationWithIdsAction {
  static readonly type = '[Destination Detail] Syndicate a Destination with Product IDs';
  constructor(public payload: {
    destination: Catalog,
    groupBy: GroupBy,
    productIds: Array<string>
  }) { }
}

export class DownloadDestinationImagesWithFiltersAction {
  static readonly type = '[Destination Detail] Download all Destination Images with query filters';
  constructor(public payload: {
    destination: Catalog
    groupBy: GroupBy,
    sortBy: Array<string>,
    orderBy: Array<OrderBy>,
    filterBy: Array<string>,
    filterJoin: Array<FilterJoin>,
    filterMatch: Array<FilterMatch>,
    filterValue: Array<string>
  }) { }
}

export class DownloadDestinationImagesWithIdsAction {
  static readonly type = '[Destination Detail] Download all Destination Images with Product IDs';
  constructor(public payload: {
    destination: Catalog,
    groupBy: GroupBy,
    productIds: Array<string>
  }) { }
}

export class RemoveProductsFromDestinationWithIdsAction {
  static readonly type = '[Destination Detail] Remove Products from Destination with Product IDs';
  constructor(public payload: { productIds: Array<string>, destination: Catalog, groupBy: GroupBy }) { }
}

export class RemoveProductsFromDestinationWithFiltersAction {
  static readonly type = '[Destination Detail] Remove Products from Destination with query filters';
  constructor(public payload: {
    destination: Catalog,
    groupBy: GroupBy,
    sortBy: Array<string>,
    orderBy: Array<OrderBy>,
    filterBy: Array<string>,
    filterJoin: Array<FilterJoin>,
    filterMatch: Array<FilterMatch>,
    filterValue: Array<string>
  }) { }
}

export class ResetDestinationProductsAction {
  static readonly type = '[Destination Detail] Reset only products';
  constructor() { }
}

export class SetSyndicationsLoadingAction {
  static readonly type = '[Destination Detail] Set Syndications to loading state';
  constructor() { }
}

export class AppendSyndicationsAction {
  static readonly type = '[Destination Detail] Append a list of Syndicated Catalogs';
  constructor(public payload: InfiniteScrollPage) { }
}

export class RefreshSyndicationAction {
  static readonly type = '[Destination Detail] Refresh a single Syndication in the list';
  constructor(public payload: { syndicationId: Catalog['id'] }) { }
}
export class CancelSyndicationAction {
  static readonly type = '[Destination Detail] Cancel Syndication in the list';
  constructor(public payload: { syndicationId: Catalog['id']}) { }
}

export class StartFeedPushToPluginWithFiltersAction {
  static readonly type = '[Destination Detail] Start Plugin Product Syndication with query filters';
  constructor(public payload: { templateName: string, exportType: string, productIds: Array<string> }) { }
}

export class LoadScheduledSyndications {
  static readonly type = '[Destination Detail] Load Scheduled Syndications';
  constructor(public payload: InfiniteScrollPage) { }
}

export class CreateScheduledTask {
  static readonly type = '[Destination Detail] Create Scheduled Task';
  constructor(public payload: { scheduledSyndicationModel: ScheduleSyndication }) { }
}

export class UpdateScheduledTask {
  static readonly type = '[Destination Detail] Update Scheduled Task';
  constructor(public payload: { scheduledSyndicationModel: ScheduleSyndication }) { }
}

export class DeleteScheduledTask {
  static readonly type = '[Destination Detail] Delete Scheduled Task';
  constructor(public payload: { scheduledSyndicationModel: ScheduleSyndication }) { }
}

export class StartFeedPushToPluginWithIdsAction {
  static readonly type = '[Destination Detail] Start Plugin Product Syndication with Product IDs';
  constructor(public payload: { productIds: Array<string>, templateName: string }) { }
}

export class ScheduleFeedPushToPluginWithFiltersAction {
  static readonly type = '[Destination Detail] Schedule Plugin Product Syndication with query filters';
  constructor(public payload: { templateName: string, timestamp: number }) { }
}

export class ScheduleFeedPushToPluginWithIdsAction {
  static readonly type = '[Destination Detail] Schedule Plugin Product Syndication with Product IDs';
  constructor(public payload: { productIds: Array<string>, templateName: string, timestamp: number }) { }
}

export class LoadPluginConfigurationAction {
  static readonly type = '[Destination Detail] Load a Configuration for a Plugin';
  constructor() { }
}

export class SavePluginConfigurationAction {
  static readonly type = '[Destination Detail] Save a Plugin config';
  constructor(public payload: { pluginPropertyValues: Array<PluginPropertyValue> }) { }
}

export class ShowPluginConfigModalAction {
  static readonly type = '[Destination Detail] Show Plugin Config modal';
  constructor(public payload: { state: boolean }) { }
}

export class ResetSyndicationsAction {
  static readonly type = '[Destination Detail] Reset only Syndications';
  constructor() { }
}

export class ResetDestinationDetailAction {
  static readonly type = '[Destination Detail] Reset state';
  constructor() { }
}

export class LoadBrandSuggestionsAction {
  static readonly type = '[Destination Detail] Load Brand suggestions';
  constructor() { }
}

export class LoadExportProductTemplateSuggestionsAction {
  static readonly type = '[Destination Detail] Load Export Product Template suggestions';
  constructor() { }
}

export class LoadExportImageTemplateSuggestionsAction {
  static readonly type = '[Destination Detail] Load Export Image Template suggestions';
  constructor() { }
}

export class BulkUpdateProductsAction {
  static readonly type = '[Destination Detail] Bulk update a list of Products';
  constructor(public payload: { bulkUpdate: BulkUpdate }) { }
}

export class SendEmailSyndicationAction {
  static readonly type = '[Destination Detail] Send a Syndication to a list of emails';
  constructor(public payload: { name: string, emails: Array<string>, statsId: number }) { }
}

export class ReloadSyndicationAction {
  static readonly type = '[Destination Detail] Reload a Syndication by ID';
  constructor(public payload: { syndicationId: number }) { }
}

export class LoadDestinationContactsAction {
  static readonly type = '[Destination Detail] Load Contacts for the current Destination';
  constructor() { }
}

export class StartProductVerifyLiveExportWithIdsAction {
  static readonly type = '[Destination Detail] Start a Live Products Export with Product IDs';
  constructor(public payload: { productIds: Array<string> }) { }
}

export class StartProductVerifyLiveExportWithFiltersAction {
  static readonly type = '[Destination Detail] Start a Live Products Export with query filters';
  constructor() { }
}

export class LoadDestinationProductViewAction {
  static readonly type = '[Destination Detail] Load Product view';
  constructor(public payload: { viewName: string }) { }
}

export class GenerateLinesheetAction {
  static readonly type = '[Destination Detail] Generate Jasper Report Linesheet';
  constructor(public payload: { productIds: any[], reportName: string }) { }
}

export class LoadDestinationSyndicationAction {
  static readonly type = '[Destination Detail] Load a Destination Syndication';
  constructor() { }
}

export class AppendDestinationSyndicationErrorsAction {
  static readonly type = '[Destination Detail] Load errors for a Destination Syndication';
  constructor(public payload: InfiniteScrollPage) { }
}

export class SetDestinationSyndicationErrorsLoadingAction {
  static readonly type = '[Destination Detail] Set Destination Syndication errors loading state';
  constructor() { }
}

export class ResetDestinationSyndicationErrorsAction {
  static readonly type = '[Destination Detail] Reset only Destination Syndication errors';
  constructor() { }
}

export class LoadPreflightColumnsAction {
  static readonly type = '[Destination Detail] Load Preflight Columns';
  constructor(public payload: { templateName: string }) { }
}

export class LoadImagePreflightColumnsAction {
  static readonly type = '[Destination Detail] Load Image Preflight Columns';
  constructor(public payload: { header: string }) { }
}
export class LoadPreflightColumnsByTemplateNameAction {
  static readonly type = '[Destination Detail] Load Preflight Columns by Template Name';
  constructor(public payload: { header: string, templateName: string }) { }
}

export class LoadSyndic8FieldSuggestionsAction {
  static readonly type = '[Destination Detail] Load Syndicate Field suggestions';
  constructor() { }
}

export class StartDestinationExportStreamWithIdsAction {
  static readonly type = '[Destination Detail] Start a Catalog Export stream with Product IDs';
  constructor(public payload: { uuid: string, keys: Array<string>, templateName: string, exportType: string }) { }
}

export class StartDestinationExportStreamWithFiltersAction {
  static readonly type = '[Destination Detail] Start a Catalog Export stream with query filters for A+';
  constructor(public payload: { uuid: string, templateName: string, aplusTemplateId: any }) { }
}
export class StartDestinationExportStreamWithFiltersAction2 {
  static readonly type = '[Destination Detail] Start a Catalog Export stream for Image Preflight';
  constructor(public payload: { uuid: string, productIds?: any[] }) { }
}

export class StartDestinationExportStreamForProductsWithFiltersAction {
  static readonly type = '[Destination Detail] Start a Catalog Export stream with query filters for Export Templates';
  constructor(public payload: { uuid: string, templateName: string }) { }
}

export class SyndicatePreflightWithIdsAction {
  static readonly type = '[Destination Detail] Start a Preflight export with Product IDs';
  constructor(public payload: { keys: Array<string>, templateName: string }) { }
}

export class SyndicatePreflightWithFiltersAction {
  static readonly type = '[Destination Detail] Start a Preflight export with query filters';
  constructor(public payload: { templateName: string }) { }
}

export class LoadDestinationExportErrorsAction {
  static readonly type = '[Destination Detail] Load all errors for a Destination export';
  constructor(public payload: { statsId: number }) { };
}

export class CreateStreamTokenAction {
  static readonly type = '[Destination Detail] Create token for Preflight SSE stream';
  constructor() { }
}

export class ConnectToStreamAction {
  static readonly type = '[Destination Detail] Connect to Preflight SSE stream';
  constructor(public payload: { preflightType: string, productIds?: any[] }) { }
}

export class ConnectToStreamAction2 {
  static readonly type = '[Destination Detail] Connect to Preflight SSE stream passing a templateName and aplus Template ID';
  constructor(public payload: { templateName: string, aplusTemplateId: any }) { }
}

export class RecordStreamEventAction {
  static readonly type = '[Destination Detail] Record an SSE stream event';
  constructor(public payload: { message: MessageEvent<any> }) { }
}

export class DownloadStreamUrlAction {
  static readonly type = '[Destination Detail] Download Preflight stream URL';
  constructor() { }
}

export class UpdateStreamRowAction {
  static readonly type = '[Destination Detail] Update Preflight stream row';
  constructor(public payload: { field: string, fieldValue: string, productIds: Array<string> }) { }
}

export class CreateSseStreamAction {
  static readonly type = '[Destination Detail] Connects to a Server Sent Event stream. First message written will contain a UUID';
  constructor(public payload: { streamToken: any }) { }
}

export class DownloadSyndicationErrorsAction {
  static readonly type = '[Destination Detail] Download Errors for current Syndication';
  constructor() { }
}

export class LoadEmailHistoryAction {
  static readonly type = '[Destination Detail] Load Email History';
  constructor() { }
}

export class UpdateSyndicationCommentAction {
  static readonly type = '[Destination Detail] Update Syndication Comment';
  constructor(public payload: { catalog: Catalog, comments: string }) { }
}

export class CancelScheduledSyndicationAction {
  static readonly type = '[Destination Detail] Cancel Scheduled Syndication';
  constructor(public payload: { catalog: Catalog }) { }
}

export class UpdateProductsTemplatesAction {
  static readonly type = '[Destination Detail] Update A+ Templates';
  constructor(public payload: { productIds: any[], template: any }) { }
}
