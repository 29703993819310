/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SupplierReference } from './supplierReference';


export interface OrganizationReference { 
    active?: boolean;
    aplusService?: boolean;
    badge?: string;
    chatGptService?: boolean;
    connected?: boolean;
    connectorId?: string;
    description?: string;
    id?: number;
    importWizardFlag?: boolean;
    inforNexusFlag?: boolean;
    isoCountryCode?: string;
    isoCurrencyCode?: string;
    isoLanguageCode?: string;
    lineSheets?: boolean;
    logo?: string;
    lspFlag?: boolean;
    matrixFieldName?: string;
    name?: string;
    noTradingPartnerAdd?: boolean;
    parentId?: number;
    parentName?: string;
    partnerName?: string;
    scacCode?: string;
    serviceType?: string;
    serviceTypeDefault?: string;
    supplier?: SupplierReference;
    type?: string;
    validValueAiFlag?: boolean;
    verificationService?: boolean;
}

