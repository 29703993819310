import { Component, OnInit, Input } from '@angular/core';
import { ToastService } from '@app/core/services/toast.service';
import { Notification } from '@app/core/api';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss']
})
export class ToastMessageComponent implements OnInit {
  @Input() notification: Notification;

  constructor(private toast: ToastService) { }

  ngOnInit() {
    console.log('notification=>', this.notification)
  }

  dismissToast(notification: Notification): void {
    this.toast.actionDismiss(notification);
  }

}
