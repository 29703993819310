import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'beautifyComma'
})
export class BeautifyCommaPipe implements PipeTransform {

  transform(input: string): string {
    const regex = new RegExp(',', 'g');
    return input.replace(regex, ', ');
  }

}
