import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-readiness-chart',
  templateUrl: './product-readiness-chart.component.html',
  styleUrls: ['./product-readiness-chart.component.scss']
})

export class ProductReadinessChartComponent implements OnInit {
  workflow: any = {
    id: 1,
    name: 'Product Optimization',
    categories: [
      {
        name: 'Data Quality'
      },
      {
        name: 'Syndication'
      },
      {
        name: 'Taxonomy'
      },
      {
        name: 'SEO'
      },
      {
        name: 'Image Verification'
      }
    ]
  };
  date1: any = {
    date: 'Oct 1',
    release: {
      id: 1,
      status: 3,
      date: '08/01/2019',
      dayOfMonth: '1',
      month: 'Oct',
      year: '2019',
      dayOfWeek: 'Thursday'
    },
    totalPasses: 100,
    totalWarnings: 1,
    totalErrors: 3,
    totalApprovals: 5,
    categories: [
      {
        name: 'Data Quality',
        passes: '45',
        warnings: 0,
        approvals: 0,
        errors: 0
      },
      {
        name: 'Syndication',
        passes: '40',
        warnings: 1,
        approvals: 0,
        errors: 0
      },
      {
        name: 'Taxonomy',
        passes: 33,
        warnings: 2,
        approvals: 0,
        errors: 1
      },
      {
        name: 'SEO',
        passes: 30,
        warnings: 0,
        approvals: 0,
        errors: 0
      },
      {
        name: 'Image Verification',
        passes: 5,
        warnings: 0,
        approvals: 5,
        errors: 0
      }
    ]
  };
  date2: any = {
    totalPasses: 100,
    totalWarnings: 18,
    totalErrors: 7,
    date: 'Oct 12',
    release: {
      id: 1,
      status: 3,
      date: '08/01/2019',
      dayOfMonth: '12',
      month: 'Oct',
      year: '2019',
      dayOfWeek: 'Thursday'
    },
    categories: [
      {
        name: 'Data Quality',
        passes: '45',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Syndication',
        passes: '40',
        warnings: 18,
        errors: 7
      },
      {
        name: 'Taxonomy',
        passes: '33',
        warnings: 0,
        errors: 0
      },
      {
        name: 'SEO',
        passes: '30',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Image Verification',
        passes: '5',
        warnings: 0,
        errors: 0
      }
    ]
  };
  date3: any = {
    totalPasses: 100,
    totalWarnings: 6,
    totalErrors: 2,
    date: 'Oct 31',
    release: {
      id: 1,
      status: 3,
      date: '08/01/2019',
      dayOfMonth: '31',
      month: 'Oct',
      year: '2019',
      dayOfWeek: 'Thursday'
    },
    categories: [
      {
        name: 'Data Quality',
        passes: '45',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Syndication',
        passes: '40',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Taxonomy',
        passes: '33',
        warnings: 0,
        errors: 0
      },
      {
        name: 'SEO',
        passes: '30',
        warnings: 3,
        errors: 1
      },
      {
        name: 'Image Verification',
        passes: '5',
        warnings: 3,
        errors: 1
      }
    ]
  };
  date4: any = {
    totalPasses: 100,
    totalWarnings: 0,
    totalErrors: 0,
    date: 'Dec 15',
    release: {
      id: 1,
      status: 1,
      date: '08/01/2019',
      dayOfMonth: '15',
      month: 'Dec',
      year: '2019',
      dayOfWeek: 'Thursday'
    },
    categories: [
      {
        name: 'Data Quality',
        passes: '45',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Syndication',
        passes: '40',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Taxonomy',
        passes: '33',
        warnings: 0,
        errors: 0
      },
      {
        name: 'SEO',
        passes: '30',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Image Verification',
        passes: '5',
        warnings: 0,
        errors: 0
      }
    ]
  };
  date5: any = {
    totalPasses: 100,
    totalWarnings: 27,
    totalErrors: 0,
    date: 'Jan 1',
    release: {
      id: 1,
      status: 2,
      date: '08/01/2019',
      dayOfMonth: '1',
      month: 'Jan',
      year: '2020',
      dayOfWeek: 'Thursday'
    },
    categories: [
      {
        name: 'Data Quality',
        passes: '45',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Syndication',
        passes: '40',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Taxonomy',
        passes: '33',
        warnings: 27,
        errors: 0
      },
      {
        name: 'SEO',
        passes: '30',
        warnings: 0,
        errors: 0
      },
      {
        name: 'Image Verification',
        passes: '5',
        warnings: 0,
        errors: 0
      }
    ]
  };

  dates: any[] = [this.date1, this.date2, this.date3, this.date4, this.date5];

  constructor() { }

  ngOnInit() {
  }
}
