import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { switchMap, catchError } from 'rxjs/operators';
import { RefreshTokenAction } from '../stores/auth.actions';
import { AuthState, TokenData } from '../stores/auth.state';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  tryCount = 0;

  constructor(private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          // Refresh the User's JWT token if we see the `x-token-refresh-required` header set to `true`
          if (error.headers && error.status === 403) {
            const headers = error.headers;
            const refreshHeader = headers.get('x-token-refresh-required');
            if (refreshHeader === 'true' && this.tryCount < 4) {
              this.tryCount++;
              return this.store.dispatch(new RefreshTokenAction()).pipe(
                switchMap(
                  () => {
                    const tokenData: TokenData = this.store.selectSnapshot(AuthState.getTokenData);
                    const api = environment.apiUrl;
                    const version = environment.apiVersion;
                    const defaultOrg = tokenData[`${environment.tokenNamespace}/appEnvMeta`].do.toString();
                    // If this is a user data request,
                    // With or without an API version number,
                    // Replace the Organization ID context with the default Organization ID from the User's token
                    const withVersion = request.url.indexOf(api + '/api/' + environment.apiVersion + '/user/-1');
                    const withouthVersion = request.url.indexOf(api + '/api/user/-1');
                    if (withVersion > -1) {
                      return next.handle(request.clone({
                        url: request.url.replace(
                          api + '/api/' + version + '/user/-1',
                          api + '/api/' + version + '/user/' + defaultOrg
                        )
                      }));
                    } else if (withouthVersion > -1) {
                      return next.handle(request.clone({
                        url: request.url.replace(
                          api + '/api/user/-1',
                          api + '/api/user/' + defaultOrg
                        )
                      }));
                    }
                    // Otherwise make the same request again
                    return next.handle(request);
                  }
                )
              );
            }
            this.tryCount = 0;
          }
          return throwError(error);
        }
      )
    );
  }
}
