/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';


export interface Cost { 
    cancelDate?: string;
    commissionPercent?: number;
    createdDate?: ModifiedDate;
    creditCardFee?: number;
    creditCardFeeType?: string;
    damageCost?: number;
    damageCostType?: string;
    dayLimit?: number;
    dayLimitCost?: number;
    dayLimitCostType?: string;
    distributionType?: string;
    distributionTypeId?: number;
    dropshipFee?: number;
    dropshipFeeType?: string;
    effectiveDate?: string;
    handlingCost?: number;
    handlingCostType?: string;
    id?: number;
    inboundFreight?: number;
    inboundFreightType?: string;
    isoCurrencyCode?: string;
    lastUpdated?: ModifiedDate;
    marginPercent?: number;
    minMarginPercent?: number;
    orgRef?: OrganizationReference;
    orgRefBrand?: OrganizationReference;
    orgRefPlatform?: OrganizationReference;
    otherCost?: number;
    otherCostType?: string;
    outboundFreight?: number;
    outboundFreightType?: string;
    platformCost?: number;
    platformCostType?: string;
    processingCost?: number;
    processingCostType?: string;
    profitAmountRed?: number;
    profitAmountYellow?: number;
    profitPercentRed?: number;
    profitPercentYellow?: number;
    promotionalDiscountPercent?: number;
    promotionalDiscountType?: string;
    restockCost?: number;
    restockCostType?: string;
    returnCost?: number;
    returnCostType?: string;
    shippingCost?: number;
    shippingCostType?: string;
    slaServiceCost?: number;
    slaServiceCostType?: string;
    storageCost?: number;
    storageCostType?: string;
    wholesaleDiscount?: number;
    wholesaleDiscountType?: string;
}

