/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Organization } from './organization';
import { SupplierReference } from './supplierReference';
import { Contract } from './contract';
import { Cost } from './cost';
import { OrganizationReference } from './organizationReference';
import { Contact } from './contact';


export interface Channel { 
    contacts?: Array<Contact>;
    contracts?: Array<Contract>;
    costs?: Array<Cost>;
    destinationOrganization?: OrganizationReference;
    destinations?: Array<OrganizationReference>;
    organization?: Organization;
    pending?: boolean;
    supplierReference?: SupplierReference;
}

