import { Component, Input, OnInit } from '@angular/core';
import { InfiniteScrollColumn } from '../../../services/infinite-scroll.service';

@Component({
  selector: 'app-infinite-scroll-cell',
  templateUrl: './infinite-scroll-cell.component.html',
  styleUrls: ['./infinite-scroll-cell.component.scss']
})
export class InfiniteScrollCellComponent implements OnInit {
  @Input() cell: { [key: string]: any } = {};
  @Input() columns: Array<InfiniteScrollColumn> = [];

  constructor() { }

  ngOnInit(): void {
  }

}
