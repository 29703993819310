<ul *ngIf="isColumnTooltip">
  <li *ngFor="let message of messages" [innerHtml]="message | sanitizeHtml"></li>
</ul>
<ng-container *ngIf="isTopTooltip">
  <div class="field" *ngIf="field?.internalField">
    <div class="type">Field</div>
    <div>{{ field?.internalField || 'N/A' }}</div>
  </div>
  <div class="function" *ngIf="field?.convert">
    <div class="type">Function</div>
    <ng-container *ngIf="getFunctionType(field) as fieldType">
      <ng-container *ngIf="fieldType === FunctionType.Concat">
        <div class="function-name">Concat:</div>
        <app-concatenation [wrap]="true" [suggestions]="syndic8FieldSuggestions" [concatenation]="getConvert(field)"></app-concatenation>
      </ng-container>
      <ng-container *ngIf="fieldType === FunctionType.ValidValue">
        <div class="function-name">Valid Value:</div>
        <app-destination-chip [chip]="{ value: getFunctionValidValueDisplay(field, syndic8FieldSuggestions), type: ChipType.Variable }"></app-destination-chip>
      </ng-container>
      <ng-container *ngIf="fieldType === FunctionType.Image">
        <div class="function-name">Image:</div>
        <app-destination-chip [chip]="{ value: getFunctionValue(field, fieldType), type: ChipType.Variable }"></app-destination-chip>
      </ng-container>
      <ng-container *ngIf="fieldType === FunctionType.ImageThumbnail">
        <div class="function-name">Image Thumbnail:</div>
        <app-destination-chip [chip]="{ value: getFunctionValue(field, fieldType), type: ChipType.Variable }"></app-destination-chip>
      </ng-container>
      <ng-container *ngIf="fieldType === FunctionType.FormatMarketingDescription">
        <div class="function-name">Description Format:</div>
        <app-destination-chip [chip]="{ value: getFunctionValue(field, fieldType), type: ChipType.Variable }"></app-destination-chip>
      </ng-container>
      <ng-container *ngIf="fieldType === FunctionType.ImageList">
        <div class="function-name">Image List:</div>
        <app-destination-chip-list [wrap]="true" [chips]="getFunctionValueChips(field, fieldType)"></app-destination-chip-list>
      </ng-container>
      <ng-container *ngIf="fieldType === FunctionType.ImageListRow">
        <div class="function-name">Image List Row:</div>
        <app-destination-chip-list [wrap]="true" [chips]="getFunctionValueChips(field, fieldType)"></app-destination-chip-list>
      </ng-container>
      <ng-container *ngIf="fieldType === FunctionType.ImageListPosition">
        <div class="function-name">Image List Position</div>
        <app-destination-chip-list [wrap]="true" [chips]="getFunctionValueChips(field, fieldType)"></app-destination-chip-list>
      </ng-container>
      <ng-container *ngIf="fieldType === FunctionType.ImageMultiList">
        <div class="function-name">Image Multi-List:</div>
        <app-destination-chip-list [wrap]="true" [chips]="getFunctionValueChips(field, fieldType)"></app-destination-chip-list>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!getFunctionType(field)">
      <i>None</i>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="isBottomToolTip">
  <div class="type">Definition</div>
    <div>{{ field?.definition || 'N/A' }}</div>
</ng-container>
