import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateTimeComponent), multi: true }]
})
export class DateTimeComponent implements ControlValueAccessor {

  @Input() defaultToCurrentDateTimeOnFirstClick: boolean = false;
  dateString: string;

  onChange: (date: number) => void;

  onTouched: (date: number) => void;

  writeValue(date: string): void {
    if (date) {
      if (!isNaN(parseInt(date, null))) {
        this.dateString = moment(date).utcOffset(0).format(moment.HTML5_FMT.DATETIME_LOCAL);
      } else {
        this.dateString = moment().utcOffset(0).format(moment.HTML5_FMT.DATETIME_LOCAL).valueOf();
      }
    } else {
      this.dateString = date;
    }
  }

  registerOnChange(fn: (date: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (date: number) => void): void {
    this.onTouched = fn;
  }

  changed(): void {
    const offset = moment(this.dateString).utcOffset();
    const date = moment(this.dateString).utcOffset(0).add(offset, 'minutes').valueOf();
    this.onChange(date);
  }

  touch(): void {
    if (this.defaultToCurrentDateTimeOnFirstClick && !this.dateString) {
      this.dateString = moment().format(moment.HTML5_FMT.DATETIME_LOCAL).valueOf();
    }
    if (this.onTouched) {
      const offset = moment(this.dateString).utcOffset();
      const date = moment(this.dateString).utcOffset(0).add(offset, 'minutes').valueOf();
      this.onChange(date);
    }
  }
}
