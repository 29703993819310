/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type WorkTaskType = 'ACTION' | 'APPROVAL' | 'ERROR' | 'INFORMATIONAL' | 'SUCCESS' | 'WARNING';

export const WorkTaskType = {
    ACTION: 'ACTION' as WorkTaskType,
    APPROVAL: 'APPROVAL' as WorkTaskType,
    ERROR: 'ERROR' as WorkTaskType,
    INFORMATIONAL: 'INFORMATIONAL' as WorkTaskType,
    SUCCESS: 'SUCCESS' as WorkTaskType,
    WARNING: 'WARNING' as WorkTaskType
};

