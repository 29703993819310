import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BulkUpdateComponent, BulkUpdateField } from '../bulk-update/bulk-update.component';

@Component({
  selector: 'app-bulk-update-modal',
  templateUrl: './bulk-update-modal.component.html',
  styleUrls: ['./bulk-update-modal.component.scss']
})
export class BulkUpdateModalComponent implements OnInit {
  @Input() open = false;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();
  @Input() height: number = 540;
  @Input() width: number = 500;
  @Input() modalTitle: string;
  @Input() showDestinations = false;
  @Input() productType: string;
  @Input() fields: Array<BulkUpdateField> = [];
  @Output() bulkUpdate: EventEmitter<BulkUpdateComponent> = new EventEmitter();
  @Input() buttonTitle = 'Update';


  showConfirmModal = false;
  isDisabled = false;

  constructor() { }

  ngOnInit(): void {
  }

  buttonClick(bulkUpdateComponent: BulkUpdateComponent): void {
    const { fieldName, fieldValue } = bulkUpdateComponent.form.value;

    if (fieldName && !fieldValue) {
      this.showConfirmModal = true;
    } else {
      this.isDisabled = true;
      this.bulkUpdate.emit(bulkUpdateComponent);
    }
  }

  clearClick(bulkUpdateComponent: BulkUpdateComponent): void {
    this.showConfirmModal = false;
    bulkUpdateComponent.form.patchValue({ fieldValue: null });
    this.bulkUpdate.emit(bulkUpdateComponent);
  }
}
