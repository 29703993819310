import { Component, OnInit, Input, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';
import { ImageImportStatus, Image } from '@app/core/api';

@Component({
  selector: 'app-image-detail-modal',
  templateUrl: './image-detail-modal.component.html',
  styleUrls: ['./image-detail-modal.component.scss']
})
export class ImageDetailModalComponent implements OnInit {
  @Input() image: Image & ImageImportStatus;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();

  @HostBinding('class.show') @Input() open = false;

  @HostListener('window:keydown', ['$event'])
  escape(event: KeyboardEvent) {
    if (this.open && (event.key === 'Esc' || event.key === 'Escape')) {
      this.closeModal();
    }
  }

  showAllColors = true;

  constructor() { }

  ngOnInit() {
  }

  closeModal() {
    this.openChange.emit(false);
    this.close.emit();
  }
}
