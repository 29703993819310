/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';


export interface Connection { 
    connectionAction?: string;
    connectionDirectory?: string;
    connectionDirectoryArchive?: string;
    connectionExtensionFilter?: string;
    connectionId?: number;
    connectionInFlag?: boolean;
    connectionName?: string;
    connectionOutFlag?: boolean;
    connectionType?: string;
    connectionUser?: string;
    connectorId?: string;
    createdDate?: ModifiedDate;
    ipAddress?: string;
    ipPort?: number;
    lastUpdated?: ModifiedDate;
    newVaultPath?: boolean;
    orgRef?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    orgRefPartner?: OrganizationReference;
    password?: string;
    pluginName?: string;
    defError?: number;
}

