import { State, Action, Actions, Store, ofActionDispatched, Selector, StateContext, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { LoadOrganizationAction } from '@app/features/organization/store/organization.actions';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { tap, catchError, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { OrgApiService, ToastService, LogoutUserAction, Organization, NotificationType, Suggestion, Template, DataProcessField, Catalog, DataProcessBlock, TemplateApiService, FilterJoin, FilterMatch, BrandTpAlignmentApiService, TradingPartnerAlignment } from '@app/core';
import {
  ResetDestinationExportMappingAction,
  LoadSyndic8FieldSuggestionsAction,
  LoadDestinationExportMappingAction,
  DeleteDestinationExportMappingAction,
  LoadDestinationExportMappingSuggestionsAction,
  CreateDestinationExportTemplateAction,
  SetDestinationExportMappingEditingAction,
  LoadDestinationExportInternalFieldSuggestionsAction,
  LoadBrandTpAlignmentsAction,
  AddOrSaveBrandTpAlignmentAction,
  ResetDestinationExportMappingTemplateAction,
  UpdateDestinationExportMappingTemplateAction,
  SaveDestinationExportMappingFieldsAction,
  AddDestinationExportMappingTemplateSheetAction,
  RemoveDestinationExportMappingTemplateSheetAction,
  LoadDestinationExportMappingTPFieldListSuggestionsAction,
  RemoveBrandTpAlignmentAction,
  RemoveAllBrandTradingPartnerAlignmentsAction,
  AddDestinationExportMappingTemplateRowAction,
  UpdateDestinationExportMappingTemplateSheetAction,
  ToggleShowMessagesAction,
  SaveMessagesAction,
  MoveDestinationExportMappingAction,
  LoadDestinationExportMappingTemplateParentListSuggestionsAction,
  ToggleShowOrderAction,
  SaveSheetOrderAction,
  RunAiBot,
  GobackToAISummary,
  UpdateTemplateSheetAction,
  LoadCurrencyCodeSuggestions,
  LoadLanguageCodeSuggestions
} from './destination-export-mapping.actions';
import { DestinationDetailState } from './destination-detail.state';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { RouterStateParams } from '@app/core/providers/custom-router-state-serializer';
import { EditState } from '@app/shared/components/edit-lock/edit-lock.component';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { moveItemInArray } from '@angular/cdk/drag-drop';

export interface DestinationExportMappingStateModel {
  template: Template;
  templateLoading: boolean;
  syndic8FieldSuggestions: Array<Suggestion>;
  exportMappingSuggestions: Array<Suggestion>;
  tradingPartnerFieldListSuggestions: Array<Suggestion>;
  parentTemplateSuggestionList: Array<Suggestion>;
  languageCodeSuggestionList: Array<Suggestion>;
  currencyCodeSuggestionList: Array<Suggestion>;
  internalFieldSuggestions: Array<Suggestion>;
  brandTpAlignments: Array<TradingPartnerAlignment>;
  brandTpAlignmentsLoading: boolean;
  editing: EditState;
  showMessages: boolean;
  showSheetReorder: boolean;
  runAiBot: boolean;
  goBackToAISummary: boolean;
}

const defaults: DestinationExportMappingStateModel = {
  template: undefined,
  templateLoading: false,
  syndic8FieldSuggestions: [],
  exportMappingSuggestions: [],
  tradingPartnerFieldListSuggestions: [],
  parentTemplateSuggestionList: [],
  languageCodeSuggestionList: [],
  currencyCodeSuggestionList: [],
  internalFieldSuggestions: [],
  brandTpAlignments: [],
  brandTpAlignmentsLoading: false,
  editing: EditState.NotEdit,
  showMessages: false,
  showSheetReorder: false,
  runAiBot: false,
  goBackToAISummary: false
};

@State<DestinationExportMappingStateModel>({
  name: 'destinationExportMapping',
  defaults
})
@Injectable()
export class DestinationExportMappingState {

  constructor(
    private orgApi: OrgApiService,
    private templateApi: TemplateApiService,
    private actions: Actions,
    private store: Store,
    private toast: ToastService,
    private brandTpApi: BrandTpAlignmentApiService
  ) {
    // Reset Destination Export Mapping state on User Logout and Organization Load
    this.actions.pipe(ofActionDispatched(LogoutUserAction)).subscribe(
      () => {
        this.store.dispatch(new ResetDestinationExportMappingAction());
      }
    );
    this.actions.pipe(ofActionDispatched(LoadOrganizationAction)).subscribe(
      () => {
        this.store.dispatch(new ResetDestinationExportMappingAction());
      }
    );
  }

  @Selector()
  static getTemplate(state: DestinationExportMappingStateModel): Template {
    return state.template;
  }
  @Selector()
  static getRunAiBot(state: DestinationExportMappingStateModel): any {
    return state?.runAiBot;
  }
  @Selector()
  static goBackToAISummaryValue(state: DestinationExportMappingStateModel): any {
    return state?.goBackToAISummary;
  }

  static getTemplateFields() {
    return createSelector(
      [DestinationExportMappingState, RouterState.state],
      (state: DestinationExportMappingStateModel, { queryParams }: RouterStateParams): Array<DataProcessField> => {
        const sheet = queryParams?.sheet ? parseInt(queryParams.sheet, null) : 1;
        if (state.template?.dataProcessTemplate?.blockList && state.template.dataProcessTemplate.blockList?.length > 1) {
          return state.template.dataProcessTemplate.blockList[sheet].fields;
        }
        return [];
      }
    );
  }

  @Selector()
  static getSheetSuggestions(state: DestinationExportMappingStateModel): Array<Suggestion> {
    if (state.template?.dataProcessTemplate?.blockList && state.template.dataProcessTemplate.blockList?.length > 1) {
      return state.template.dataProcessTemplate.blockList.filter(b => b.name !== 'root').map(
        ({ name }: DataProcessBlock) => {
          return { id: name, name };
        }
      );
    }
    return [];
  }

  static getCurrentSheetName() {
    return createSelector(
      [DestinationExportMappingState, RouterState.state],
      (state: DestinationExportMappingStateModel, { queryParams }: RouterStateParams): Array<string> => {
        const sheet = queryParams?.sheet ? parseInt(queryParams.sheet, null) : 1;
        if (state.template?.dataProcessTemplate?.blockList && state.template.dataProcessTemplate.blockList?.length > 1) {
          const { name } = state.template.dataProcessTemplate.blockList[sheet];
          return [name];
        }
        return [];
      }
    );
  }

  static getCurrentSheet() {
    return createSelector(
      [DestinationExportMappingState, RouterState.state],
      (state: DestinationExportMappingStateModel, { queryParams }: RouterStateParams): DataProcessBlock => {
        const sheet = queryParams?.sheet ? parseInt(queryParams.sheet, null) : 1;
        if (state.template?.dataProcessTemplate?.blockList && state.template.dataProcessTemplate.blockList?.length > 1) {
          // const { name } = state.template.dataProcessTemplate.blockList[sheet];
          return state.template.dataProcessTemplate.blockList[sheet];
        }
        // return [];
      }
    );
  }

  static activeSheet() {
    return createSelector(
      [DestinationExportMappingState, RouterState.state],
      (state: DestinationExportMappingStateModel, { queryParams }: RouterStateParams): string => {
        const sheet = queryParams?.sheet ? parseInt(queryParams.sheet, null) : 1;
        if (state.template?.dataProcessTemplate?.blockList && state.template.dataProcessTemplate.blockList?.length > 1) {
          const { name } = state.template.dataProcessTemplate.blockList[sheet];
          return name;
        }
      }
    );
  }

  @Selector()
  static getRootSheet(state: DestinationExportMappingStateModel): DataProcessBlock {
    if (state.template?.dataProcessTemplate?.blockList && state.template.dataProcessTemplate.blockList?.length > 1) {
      return state.template.dataProcessTemplate.blockList[0];
    }
  }

  static getCurrentTemplate() {
    return createSelector(
      [RouterState.state],
      ({ params }: RouterStateParams): Array<string> => {
        if (params.templateId) {
          return [params.templateId];
        }
        return [];
      }
    );
  }

  @Selector()
  static isTemplateLoading(state: DestinationExportMappingStateModel): boolean {
    return state.templateLoading;
  }

  @Selector()
  static isTemplateParent(state: DestinationExportMappingStateModel): boolean {
    return !!state?.template?.dataProcessTemplate.parentTemplateName;
  }

  @Selector()
  static getSyndic8FieldSuggestions(state: DestinationExportMappingStateModel): Array<Suggestion> {
    return state.syndic8FieldSuggestions;
  }

  @Selector()
  static getExportMappingSuggestions(state: DestinationExportMappingStateModel): Array<Suggestion> {
    return state.exportMappingSuggestions;
  }

  @Selector()
  static getTradingPartnerFieldListSuggestions(state: DestinationExportMappingStateModel): Array<Suggestion> {
    return state.tradingPartnerFieldListSuggestions;
  }

  @Selector()
  static getInternalFieldSuggestions(state: DestinationExportMappingStateModel): Array<Suggestion> {
    return state.internalFieldSuggestions;
  }

  @Selector()
  static isEditing(state: DestinationExportMappingStateModel): boolean {
    if (state.editing === EditState.Edit) {
      return true;
    }
    return false;
  }

  @Selector()
  static showMessages(state: DestinationExportMappingStateModel): boolean {
    return state.showMessages;
  }

  @Selector()
  static showSheetReorder(state: DestinationExportMappingStateModel): boolean {
    return state.showSheetReorder;
  }

  @Selector()
  static getEditState(state: DestinationExportMappingStateModel): EditState {
    return state.editing;
  }

  @Selector()
  static getBrandTpAlignments(state: DestinationExportMappingStateModel): Array<TradingPartnerAlignment> {
    return state.brandTpAlignments;
  }

  @Action(LoadSyndic8FieldSuggestionsAction)
  loadSyndic8FieldSuggestions(
    { patchState }: StateContext<DestinationExportMappingStateModel>
  ) {
    const filterBy: Array<string> = ["viewType"];
    const filterJoin: Array<FilterJoin> = ["AND"];
    const filterMatch: Array<FilterMatch> = ["ISNOT"];
    const filterValue: Array<string> = ["Dynamic Table"]
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.orgApi.getSuggestions(
      ['Template Fields'],
      organization.id,
      filterBy,
      filterJoin,
      filterMatch,
      filterValue
    ).pipe(
      tap(
        (suggestions: { [key: string]: Array<Suggestion> }) => {
          patchState({ syndic8FieldSuggestions: suggestions['Template Fields'] });
        }
      )
    );
  }

  @Selector()
  static loadParentTemplateSuggestionsForEditTemplate(state: DestinationExportMappingStateModel): Array<Suggestion> {
    return state.parentTemplateSuggestionList
      .filter((x) => x.name !== state.template.templateName)
      .map((sugg) => { return { id: sugg.name, name: sugg.altName, altName: sugg.id } });
  }

  @Selector()
  static loadParentTemplateSuggestionsForCreateTemplate(state: DestinationExportMappingStateModel): Array<Suggestion> {
    return state.parentTemplateSuggestionList
      .map((sugg) => { return { id: sugg.id, name: sugg.altName, altName: sugg.name,seedFile: sugg.seedFile } });
  }

  @Selector()
  static loadLanguageCodeSuggestionList(state: DestinationExportMappingStateModel): Array<Suggestion> {
    return state.languageCodeSuggestionList;
  }

  @Selector()
  static loadCurrencyCodeSuggestionList(state: DestinationExportMappingStateModel): Array<Suggestion> {
    return state.currencyCodeSuggestionList;
  }

  @Selector()
  static areBrandTpAlignmentsLoading(state: DestinationExportMappingStateModel): boolean {
    return state.brandTpAlignmentsLoading;
  }

  @Action(LoadDestinationExportMappingSuggestionsAction)
  loadDestinationExportMappingSuggestion(
    { patchState }: StateContext<DestinationExportMappingStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = this.store.selectSnapshot(DestinationDetailState.getDestination);
    const destinationId: string = destination.destinations[0].id.toString();
    this.orgApi.getSuggestions(['Export Multi Templates'], organization.id, ['destinationId'], [FilterJoin.AND], [FilterMatch.IS], [destinationId]).pipe(
      tap(
        (suggestions: { [key: string]: Array<Suggestion> }) => {
          patchState({ exportMappingSuggestions: suggestions['Export Multi Templates'] });
        }
      )
    ).subscribe();
  }

  @Action(LoadDestinationExportMappingAction, { cancelUncompleted: true })
  loadDestinationExportMappingById(
    { patchState }: StateContext<DestinationExportMappingStateModel>
  ) {
    patchState({ templateLoading: true });

    const { params } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const destination: Catalog = this.store.selectSnapshot(DestinationDetailState.getDestination);
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.templateApi.getTemplateById(destination.destinations[0].id, organization.id, params.templateId).pipe(
      tap(
        (template: Template) => {
          patchState({ template, templateLoading: false });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          if (error.status !== 404) {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Load Export Template',
              type: NotificationType.ERROR
            });
          }
          patchState({ template: defaults.template, templateLoading: false });
          return throwError(error);
        }
      )
    );
  }

  @Action(MoveDestinationExportMappingAction)
  moveDestinationExportMapping(
    { getState, patchState }: StateContext<DestinationExportMappingStateModel>,
    { payload: { currentIndex, previousIndex } }: MoveDestinationExportMappingAction
  ) {
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const sheet: number = queryParams?.sheet ? parseInt(queryParams.sheet, null) : 1;
    const template: Template = {
      ...getState().template,
      dataProcessTemplate: {
        ...getState().template.dataProcessTemplate,
        blockList: getState().template.dataProcessTemplate.blockList.map((block, i) => {
          if (i === sheet) {
            const fields = [...block.fields];
            moveItemInArray(fields, previousIndex, currentIndex);
            return {
              ...block,
              fields
            }
          }
          return block;
        })
      }
    };
    patchState({ template });
  }
  //
  @Action(SaveDestinationExportMappingFieldsAction)
  saveDestinationExportMappingFields(
    { patchState, getState, dispatch }: StateContext<DestinationExportMappingStateModel>,
    { payload: { fields } }: SaveDestinationExportMappingFieldsAction
  ) {
    console.log('fieldsssssss=>', fields)
    patchState({ templateLoading: true });
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const destination = this.store.selectSnapshot(DestinationDetailState.getDestination);
    const sheet: number = queryParams?.sheet ? parseInt(queryParams.sheet, null) : 1; // Offset by one b/c first block is root
    const disableOutputSaving = this.store.selectSnapshot(DestinationExportMappingState.getTemplate)?.dataProcessTemplate?.disableSavingToOutputName;

    const blockList: Array<DataProcessBlock> = getState().template.dataProcessTemplate.blockList.map((item, index) => {
      if (index === sheet) {
        return {
          ...getState().template.dataProcessTemplate.blockList[sheet],
          fields: fields.map((mapping: DataProcessField) => {
            if (mapping?.convert?.length === 0) {
              mapping.convert = null;
            }
            if (!mapping.fieldStartMatch) {
              return { ...mapping, fieldStartMatch: 'COLUMNNAME:NOCOLUMN' };
            }
            if (disableOutputSaving) {
              return {
                ...mapping,
                name: mapping?.name
              };
            } else {
              return {
                ...mapping,
                outputName: mapping?.name
              };
            }
          })
        };
      }
      return item;
    });
    const template: Template = {
      ...getState().template,
      dataProcessTemplate: {
        ...getState().template.dataProcessTemplate,
        blockList
      }
    };

    return this.templateApi.patchTemplateById(organization.id, template.templateId, template).pipe(
      tap(
        (response) => {
          patchState({ templateLoading: false }),
            dispatch([
              new LoadDestinationExportMappingAction(),
              new LoadDestinationExportMappingSuggestionsAction(),
              new Navigate([`/org/${organization.id}/channel/${destination.id}/field-mapping/${response['templateId']}`])
            ]);
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Export Template Saved',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          patchState({ templateLoading: false });
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Save Export Template',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(ResetDestinationExportMappingAction)
  resetDestinationExportMapping(
    { setState }: StateContext<DestinationExportMappingStateModel>
  ) {
    setState(defaults);
  }

  @Action(DeleteDestinationExportMappingAction)
  deleteDestinationExportMappingAction(
    { dispatch, getState }: StateContext<DestinationExportMappingStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination = this.store.selectSnapshot(DestinationDetailState.getDestination);
    const template: Template = getState().template;

    return this.templateApi.removeTemplate(organization.id, template.templateId).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Export Template Deleted',
            type: NotificationType.SUCCESS
          });
        }
      ),
      mergeMap(() => dispatch([
        // Loading suggestion list so lest the deleted templateId persists after the navigation - RP
        new LoadDestinationExportMappingSuggestionsAction(),
        new Navigate([`/org/${organization.id}/channel/${destination.id}/field-mapping`])
      ])),
      catchError(
        (error: HttpErrorResponse) => {
          if (error.status === 404) {
            this.toast.add({
              expiration: 5000,
              title: 'No Change',
              message: 'Cannot Delete Default Template',
              type: NotificationType.ERROR
            });
          } else {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Delete Export Template',
              type: NotificationType.ERROR
            });
          }
          return throwError(error);
        }
      )
    );
  }

  @Action(CreateDestinationExportTemplateAction)
  createDestinationExportTemplate(
    { dispatch }: StateContext<DestinationExportMappingStateModel>,
    { payload: { fieldNames, comments, examples, fileExtension, headerType, templateName, parentTemplateName, appendFile, currencyCodeOutput, languageCodePrimary, languageCodeOutput } }: CreateDestinationExportTemplateAction
  ) {
    const destination = this.store.selectSnapshot(DestinationDetailState.getDestination);
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);

    const body = {
      fieldNames,
      comments,
      examples,
      currencyCodeOutput: [currencyCodeOutput],
      languageCodePrimary: [languageCodePrimary],
      languageCodeOutput: [languageCodeOutput]
    }

    this.templateApi.createTemplate(destination.name, fileExtension, headerType, templateName, organization.id, body, appendFile, parentTemplateName).pipe(
      // this.templateApi.createTemplate(destination.name, fileExtension, headerType, templateName, organization.id, fieldNames, appendFile, parentTemplateName).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: templateName + ' Added',
            type: NotificationType.SUCCESS
          });
        }
      ),
      mergeMap(
        (template: Template) => {
          const { params } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
          return dispatch([
            new LoadDestinationExportMappingSuggestionsAction(),
            new Navigate([`/org/${params.organizationId}/channel/${params.destinationId}/field-mapping/${template.templateId}`])
          ]);
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Create Export Template',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    ).subscribe();
  }

  @Action(LoadLanguageCodeSuggestions)
  loadLanguageCodeSuggestions(
    { patchState }: StateContext<DestinationExportMappingStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = this.store.selectSnapshot(DestinationDetailState.getDestination);
    this.orgApi.getSuggestions(
      ['LANGUAGE LIST'],
      organization.id
    ).pipe(
      tap(
        (suggestions: { [key: string]: Array<Suggestion> }) => {
          patchState({ languageCodeSuggestionList: suggestions['LANGUAGE LIST'] });
        }
      )
    ).subscribe();
  }

  @Action(LoadCurrencyCodeSuggestions)
  loadCurrencyCodeSuggestions(
    { patchState }: StateContext<DestinationExportMappingStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.orgApi.getSuggestions(
      ['CurrencyCodeList'],
      organization.id
    ).pipe(
      tap(
        (suggestions: { [key: string]: Array<Suggestion> }) => {
          patchState({ currencyCodeSuggestionList: suggestions['CurrencyCodeList'] });
        }
      )
    ).subscribe();
  }

  @Action(SetDestinationExportMappingEditingAction)
  setDestinationExportMappingEditing(
    { patchState }: StateContext<DestinationExportMappingStateModel>,
    { payload: { state } }: SetDestinationExportMappingEditingAction
  ) {
    patchState({ editing: state });
  }

  @Action(LoadDestinationExportInternalFieldSuggestionsAction)
  loadDestinationExportInternalFields(
    { patchState }: StateContext<DestinationExportMappingStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = this.store.selectSnapshot(DestinationDetailState.getDestination);
    const destinationId: string = destination.destinations[0].id.toString();
    this.orgApi.getSuggestions(
      ['Template Internal Fields'],
      organization.id,
      ['destinationId'],
      [FilterJoin.AND],
      [FilterMatch.IS],
      [destinationId]
    ).pipe(
      tap(
        (suggestions: { [key: string]: Array<Suggestion> }) => {
          patchState({ internalFieldSuggestions: suggestions['Template Internal Fields'] });
        }
      )
    ).subscribe();
  }

  @Action(LoadDestinationExportMappingTPFieldListSuggestionsAction)
  loadDestinationExportMappingTPFieldListSuggestions(
    { patchState }: StateContext<DestinationExportMappingStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = this.store.selectSnapshot(DestinationDetailState.getDestination);
    const destinationId: string = destination.destinations[0].id.toString();
    this.orgApi.getSuggestions(
      ['Trading Partner Field List'],
      organization.id,
      ['destinationId'],
      [FilterJoin.AND],
      [FilterMatch.IS],
      [destinationId]
    ).pipe(
      tap(
        (suggestions: { [key: string]: Array<Suggestion> }) => {
          patchState({ tradingPartnerFieldListSuggestions: suggestions['Trading Partner Field List'] });
        }
      )
    ).subscribe();
  }

  @Action(LoadDestinationExportMappingTemplateParentListSuggestionsAction)
  loadDestinationExportMappingTemplateParentListSuggestionsAction(
    { patchState }: StateContext<DestinationExportMappingStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = this.store.selectSnapshot(DestinationDetailState.getDestination);
    const destinationId: string = destination.destinations[0].id.toString();
    this.orgApi.getSuggestions(
      ['Template Parent List'],
      organization.id,
      ["destinationId", "templateHeader"],
      [FilterJoin.AND, FilterJoin.AND],
      [FilterMatch.IS, FilterMatch.IS],
      [destinationId, "ExportProduct"]
    ).pipe(
      tap(
        (suggestions: { [key: string]: Array<Suggestion> }) => {
          patchState({ parentTemplateSuggestionList: suggestions['Template Parent List'] });
        }
      )
    ).subscribe();
  }

  @Action(LoadBrandTpAlignmentsAction, { cancelUncompleted: true })
  loadBrandTpAlignment(
    { patchState }: StateContext<DestinationExportMappingStateModel>,
    { payload: { columnName, internalColumnName } }: LoadBrandTpAlignmentsAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = this.store.selectSnapshot(DestinationDetailState.getDestination);
    const destinationId: string = destination.destinations[0].id.toString();
    const filterBy: Array<string> = ['columnName', 'internalColumnName', 'destinationId'];
    const filterJoin: Array<FilterJoin> = [FilterJoin.AND, FilterJoin.AND, FilterJoin.AND];
    const filterMatch: Array<FilterMatch> = [FilterMatch.IS, FilterMatch.IS, FilterMatch.IS];
    const filterValue: Array<string> = [columnName, internalColumnName, destinationId];
    patchState({ brandTpAlignmentsLoading: true });
    return this.brandTpApi.getBrandTradingPartnerAlignments(organization.id, filterBy, filterJoin, filterMatch, filterValue).pipe(
      tap(
        (brandTpAlignments: Array<TradingPartnerAlignment>) => {
          patchState({ brandTpAlignments, brandTpAlignmentsLoading: false });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          if (error.status !== 404) {
            console.log('error=>', error.status)
            // this.toast.add({
            //   expiration: 5000,
            //   title: 'Service Error',
            //   message: 'Unable to Load Trading Partner Alignments',
            //   type: NotificationType.ERROR
            // });
          }
          patchState({ brandTpAlignments: defaults.brandTpAlignments, brandTpAlignmentsLoading: false });
          return throwError(error);
        }
      )
    );
  }

  @Action(RemoveAllBrandTradingPartnerAlignmentsAction)
  removeAllBrandTpAlignments(
    { }: StateContext<DestinationExportMappingStateModel>,
    { payload: { columnName, internalColumnName } }: RemoveAllBrandTradingPartnerAlignmentsAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination: Catalog = this.store.selectSnapshot(DestinationDetailState.getDestination);
    const destinationId: string = destination.destinations[0].id.toString();
    const filterBy: Array<string> = ['columnName', 'internalColumnName', 'destinationId'];
    const filterJoin: Array<FilterJoin> = [FilterJoin.AND, FilterJoin.AND, FilterJoin.AND];
    const filterMatch: Array<FilterMatch> = [FilterMatch.IS, FilterMatch.IS, FilterMatch.IS];
    const filterValue: Array<string> = [columnName, internalColumnName, destinationId];
    const toast = this.toast.add({
      title: 'Please wait',
      message: 'Removing all Trading Partner Alignments',
      type: NotificationType.INFO
    });
    return this.brandTpApi.removeBrandTradingPartnerAlignmentByFilter(organization.id, filterBy, filterJoin, filterMatch, filterValue).pipe(
      tap(() => this.toast.remove(toast)),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.remove(toast);
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Remove Trading Partner Alignments',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(RemoveBrandTpAlignmentAction)
  removeBrandTpAlignment(
    { dispatch }: StateContext<DestinationExportMappingStateModel>,
    { payload: { tradingPartnerBrandId, columnName, internalColumnName } }: RemoveBrandTpAlignmentAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.brandTpApi.removeBrandTradingPartnerAlignment(tradingPartnerBrandId, organization.id).pipe(
      tap(
        () =>
          this.toast.add({
            title: 'Success',
            expiration: 1500,
            message: 'Trading Partner Alignment removed!',
            type: NotificationType.SUCCESS
          })
      ),
      mergeMap(() => dispatch(new LoadBrandTpAlignmentsAction({ columnName, internalColumnName }))),
      catchError(
        (error: HttpErrorResponse) => {
          console.log('errorrr=>', error)
          return throwError(error);
        }
      )
    );
  }

  @Action(AddOrSaveBrandTpAlignmentAction)
  addOrSaveBrandTpAlignment(
    { getState, setState }: StateContext<DestinationExportMappingStateModel>,
    { payload: { alignment } }: AddOrSaveBrandTpAlignmentAction
  ) {

    const brandTpAlignments: Array<TradingPartnerAlignment> = getState().brandTpAlignments;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const index = brandTpAlignments.findIndex(bTpA => bTpA.columnValueBrand === alignment.columnValueBrand);
    if (index > -1) {
      const tradingPartnerAlignment: TradingPartnerAlignment = {
        ...brandTpAlignments[index],
        ...alignment
      };
      // brandTpAlignments[index].tradingPartnerBrandId should be equivalent to syn_setup_brandtpmapping.BRANDTPMAPPING
      return this.brandTpApi.patchBrandTradingPartnerAlignmentById(brandTpAlignments[index].tradingPartnerBrandId, organization.id, tradingPartnerAlignment).pipe(
        tap(
          () => {
            setState(patch({ brandTpAlignments: updateItem(index, tradingPartnerAlignment) }));
            if (alignment.columnValueTradingPartner == null) {
              // no toast
            } else {
              this.toast.add({
                title: 'Success',
                expiration: 1500,
                message: 'Trading Partner Alignments added!',
                type: NotificationType.SUCCESS
              })
            }

          }
        ),
        catchError(
          (error: HttpErrorResponse) => {
            this.toast.add({
              expiration: 5000,
              title: 'Service Error',
              message: 'Unable to Add Trading Partner Alignment',
              type: NotificationType.ERROR
            });
            return throwError(error);
          }
        )
      );
    }
    const destination: Catalog = this.store.selectSnapshot(DestinationDetailState.getDestination);
    const destinationId: number = destination.destinations[0].id;
    return this.brandTpApi.addBrandTradingPartnerAlignment(organization.id, {
      ...alignment,
      orgRefTrandingPartner: {
        id: destinationId
      }
    }).pipe(
      tap(
        (tradingPartnerAlignment: TradingPartnerAlignment) => {
          setState(patch({ brandTpAlignments: append([tradingPartnerAlignment]) }));
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Add Trading Partner Alignment',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(ResetDestinationExportMappingTemplateAction)
  resetDestinationExportMappingTemplate(
    { patchState }: StateContext<DestinationExportMappingStateModel>
  ) {
    patchState({ template: defaults.template });
  }

  //
  //RunAiBot

  @Action(RunAiBot)
  runAiBot(
    { patchState }: StateContext<DestinationExportMappingStateModel>,
    { payload: { runAiBot } }: any
  ) {
    patchState({ runAiBot: runAiBot });
  }
  //

  //Go back to AI summery

  @Action(GobackToAISummary)
  gobackToAISummary(
    { patchState }: StateContext<DestinationExportMappingStateModel>,
    { payload: { goBackToAISummary } }: any
  ) {
    patchState({ goBackToAISummary: goBackToAISummary });
  }
  //


  @Action(UpdateDestinationExportMappingTemplateAction)
  updateDestinationExportMappingTemplate(
    { getState, dispatch }: StateContext<DestinationExportMappingStateModel>,
    { payload: { templateMap, templateSheetName, parentTemplateName, changeEntryList,  currencyCodeOutput, languageCodePrimary, languageCodeOutput, inputDateFormat, forceParentAlignment } } : UpdateDestinationExportMappingTemplateAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const destination = this.store.selectSnapshot(DestinationDetailState.getDestination);
    const template: Template = getState().template;
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const sheet: number = queryParams?.sheet ? parseInt(queryParams.sheet) : 1;

    // need to have appendFile = null if length is 0, instead of ""
    let appendFile = templateMap.appendFile;
    if (appendFile?.length === 0) {
      appendFile = null;
    }

    let updatedTemplateMap = {
      ...templateMap,
      appendFile
    }

    const blockList: Array<DataProcessBlock> = getState().template.dataProcessTemplate.blockList.map((item, index) => {
      if (index === sheet) {
        return {
          ...getState().template.dataProcessTemplate.blockList[sheet],
          name: templateSheetName,
          outputName: templateSheetName,
          displayName: templateSheetName
        };
      }
      return item;
    });
    debugger
    return this.templateApi.patchTemplateById(organization.id, template.templateId, {
      ...template,
      dataProcessTemplate: {
        ...template.dataProcessTemplate,
        parentTemplateName,
        currencyCodeOutput,
        languageCodePrimary,
        languageCodeOutput,
        forceParentAlignment,
        inputDateFormat,
        templateMap: {
          ...template.dataProcessTemplate.templateMap,
          ...updatedTemplateMap
        },
        blockList
      }
    }).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Export Template Properties Updated',
            type: NotificationType.SUCCESS
          });
        }
      ),
      mergeMap(() => dispatch([
        new LoadDestinationExportMappingAction(),
        new LoadDestinationExportMappingSuggestionsAction(),
        new Navigate([`/org/${organization.id}/channel/${destination.id}/field-mapping/${template.templateId}`])
      ])),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Update Template Properties',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    )
  }

  @Action(AddDestinationExportMappingTemplateSheetAction)
  addDestinationExportMappingTemplateSheet(
    { getState, dispatch }: StateContext<DestinationExportMappingStateModel>,
    { payload: { fieldNames, sheetName } }: AddDestinationExportMappingTemplateSheetAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const template: Template = getState().template;
    return this.templateApi.addTemplateSheet(organization.id, { ...template, fieldNames: fieldNames }, sheetName).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Template Sheet Added',
            type: NotificationType.SUCCESS
          });
        }
      ),
      mergeMap(() => dispatch(new LoadDestinationExportMappingAction())),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Add Template Sheet',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(RemoveDestinationExportMappingTemplateSheetAction)
  removeDestinationExportMappingTemplateSheet(
    { getState, dispatch }: StateContext<DestinationExportMappingStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const template: Template = getState().template;
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    const sheet = queryParams?.sheet ? parseInt(queryParams.sheet, null) : 1;
    const { name } = getState().template.dataProcessTemplate.blockList[sheet];
    return this.templateApi.removeTemplateSheet(organization.id, template, name).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Template Sheet Removed',
            type: NotificationType.SUCCESS
          });
        }
      ),
      mergeMap(() => dispatch(new LoadDestinationExportMappingAction())),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Remove Template Sheet',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(UpdateDestinationExportMappingTemplateSheetAction)
  updateDestinationExportMappingTemplateSheet(
    { getState, dispatch }: StateContext<DestinationExportMappingStateModel>,
    { payload: { sheet, index } }: UpdateDestinationExportMappingTemplateSheetAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const template = getState().template;
    return this.templateApi.patchTemplateById(organization.id, template.templateId, {
      ...template,
      dataProcessTemplate: {
        ...template.dataProcessTemplate,
        blockList: template.dataProcessTemplate.blockList.map((block, i) => {
          if (i === index) {
            return { ...block, ...sheet };
          }
          return block;
        })
      }
    }).pipe(
      tap(
        () => {
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Template Sheet Updated',
            type: NotificationType.SUCCESS
          });
        }
      ),
      mergeMap(() => dispatch(new LoadDestinationExportMappingAction())),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Update Template Sheet',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    );
  }

  @Action(AddDestinationExportMappingTemplateRowAction)
  addDestinationExportMappingTemplateRow(
    { getState, patchState }: StateContext<DestinationExportMappingStateModel>,
    { payload: { index } }: AddDestinationExportMappingTemplateRowAction
  ) {
    const template = getState().template;
    if (!template) {
      return;
    }

    /** Grab the Sheet number from the Query Params */
    const { queryParams } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);
    /** + will convert it to a number */
    const sheetNum = +queryParams?.sheet

    const newField: DataProcessField = {
      outputName: '',
      name: '',
      parentName: null,
      aliasName: null,
      dataType: 'String',
      format: null,
      matchOperator: null,
      matchLine: null,
      fieldStartOperator: null,
      fieldStartMatch: 'COLUMNNAME:NOCOLUMN',
      fieldEndOperator: null,
      fieldEndMatch: null,
      validate: null,
      convert: null,
      defaultValue: null,
      lineOffset: '',
      pageNumber: 0,
      lineNumber: 0,
      fieldStart: 0,
      fieldEnd: 0,
      requiredDisplay: false,
      requiredConditionalDisplay: false,
      recommendedDisplay: false,
      requiredBrandDisplay: false,
      required: false,
      inactive: false,
      newField: false,
      xmlAttribute: false,
      skipXmlConversion: false,
      saveEmpty: false,
      minCharacterLength: 0,
      maxCharacterLength: 0
    };
    patchState({
      template: {
        ...template,
        dataProcessTemplate: {
          ...template.dataProcessTemplate,
          blockList: template.dataProcessTemplate.blockList.map((block, i) => {
            if (i === sheetNum) {
              return {
                ...block,
                fields: [
                  ...block.fields.slice(0, index),
                  newField,
                  ...block.fields.slice(index)
                ]
              };
            }
            return block;
          })
        }
      }
    });
  }

  @Action(ToggleShowMessagesAction)
  toggleShowMessages(
    { getState, patchState }: StateContext<DestinationExportMappingStateModel>
  ) {
    const showMessages = getState().showMessages;
    patchState({ showMessages: !showMessages });
  }

  @Action(ToggleShowOrderAction)
  toggleShowOrderAction(
    { getState, patchState }: StateContext<DestinationExportMappingStateModel>
  ) {
    const showSheetReorder = getState().showSheetReorder;
    patchState({ showSheetReorder: !showSheetReorder });
  }

  @Action(SaveMessagesAction)
  saveMessages(
    { patchState, getState, dispatch }: StateContext<DestinationExportMappingStateModel>,
    { payload: { type, table, block, key } }: SaveMessagesAction
  ) {
    patchState({ templateLoading: true });
    const template = {
      ...getState().template,
      dataProcessTemplate: {
        ...getState().template.dataProcessTemplate,
        templateMap: {
          ...getState().template.dataProcessTemplate.templateMap,
          messageType: type,
          messageTable: table,
          messageBlock: block,
          messageKey: key
        }
      }
    }
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.templateApi.patchTemplateById(organization.id, template.templateId, template).pipe(
      tap(() => {
        this.toast.add({
          expiration: 5000,
          title: 'Success',
          message: '3rd Party Messages Saved',
          type: NotificationType.SUCCESS
        });
        patchState({ templateLoading: false });
      }),
      mergeMap(() => dispatch([new ToggleShowMessagesAction(), new LoadDestinationExportMappingAction()])),
      catchError(
        (error: HttpErrorResponse) => {
          patchState({ templateLoading: false });
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Save 3rd Party Messages',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    )
  }

  @Action(SaveSheetOrderAction)
  saveSheetOrderAction(
    { patchState, getState, dispatch }: StateContext<DestinationExportMappingStateModel>,
    { payload: { blockList } }: SaveSheetOrderAction
  ) {
    patchState({ templateLoading: true });
    const template = {
      ...getState().template,
      dataProcessTemplate: {
        ...getState().template.dataProcessTemplate,
        blockList
      }
    }
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.templateApi.patchTemplateById(organization.id, template.templateId, template).pipe(
      tap(() => {
        this.toast.add({
          expiration: 5000,
          title: 'Success',
          message: 'Template Saved',
          type: NotificationType.SUCCESS
        });
        patchState({ templateLoading: false });
      }),
      mergeMap(() => dispatch([new ToggleShowOrderAction(), new LoadDestinationExportMappingAction()])),
      catchError(
        (error: HttpErrorResponse) => {
          patchState({ templateLoading: false });
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Save Template',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    )
  }

  @Action(UpdateTemplateSheetAction)
  updateTemplateSheetAction(
    { patchState, getState, dispatch }: StateContext<DestinationExportMappingStateModel>,
    { payload: { sheet, index } }: UpdateTemplateSheetAction
  ) {
    patchState({ templateLoading: true });

    const template = getState().template;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    const { url, params } = this.store.selectSnapshot<RouterStateParams>(RouterState.state);

    return this.templateApi.patchTemplateById(organization.id, template.templateId, {
      ...template,
      dataProcessTemplate: {
        ...template.dataProcessTemplate,
        blockList: template.dataProcessTemplate.blockList.map((block, i) => {
          if (i === index) {
            return { ...block, ...sheet };
          }
          return block;
        })
      }
    }).pipe(
      tap(() => {
        this.toast.add({
          expiration: 5000,
          title: 'Success',
          message: 'Template Saved',
          type: NotificationType.SUCCESS
        });
        patchState({ templateLoading: false });
      }),
      mergeMap(() => dispatch([new LoadDestinationExportMappingAction()])),
      catchError(
        (error: HttpErrorResponse) => {
          patchState({ templateLoading: false });
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Save Template',
            type: NotificationType.ERROR
          });
          return throwError(error);
        }
      )
    )
  }
}
