import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApplicationService } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class DeviceGuard  {

  constructor(private router: Router, private device: DeviceDetectorService, private appSvc: ApplicationService) {}

  canActivate(): boolean | UrlTree {
    if (this.appSvc.skipUserAgentCheck || !this.device.isDesktop()) {
      return true;
    }
    return this.router.createUrlTree(['/']);
  }
}
