import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Suggestion } from '@app/core';
import { InfiniteScrollColor, InfiniteScrollColumn, InfiniteScrollColumnSuggestionProperty, InfiniteScrollColumnType, InfiniteScrollService, InfiniteScrollStyle } from '../../../services/infinite-scroll.service';
import { Store } from '@ngxs/store';
import { AOrAnPipe } from '@app/shared/pipes/aOrAn.pipe';

@Component({
  selector: 'app-infinite-scroll-new-row',
  templateUrl: './infinite-scroll-new-row.component.html',
  styleUrls: ['./infinite-scroll-new-row.component.scss']
})
export class InfiniteScrollNewRowComponent implements OnInit {
  @Input() set columns(columns: Array<InfiniteScrollColumn>) {
    this._columns = columns;
    this.updateRowForm();
  }
  @Input() suggestions: { [key: string]: Array<Suggestion> };
  @Input() minWidths: Array<number> = [];

  @Input() addAction: ({ row }: { row: { [key: string]: any } }) => void;

  @ViewChild('valuesRef') valuesRef!: ElementRef<any>;

  _columns: Array<InfiniteScrollColumn>;
  row: { [key: string]: any } = {};
  rowForm: UntypedFormGroup;

  InfiniteScrollColumnType = InfiniteScrollColumnType;
  InfiniteScrollStyle = InfiniteScrollStyle;
  InfiniteScrollColor = InfiniteScrollColor;
  InfiniteScrollColumnSuggestionProperty = InfiniteScrollColumnSuggestionProperty;

  constructor(private scrollSvc: InfiniteScrollService, private store: Store, private aOrAnPipe: AOrAnPipe) { }

  ngOnInit(): void {
  }

  get showColumnFill(): boolean {
    return this.scrollSvc.showColumnFill;
  }

  getFormControl(column: InfiniteScrollColumn): UntypedFormControl {
    return this.rowForm.get(column.propertyName.split('.')) as UntypedFormControl;
  }

  addNewRow(): void {
    if (this.rowForm.invalid) {
      this.rowForm.markAllAsTouched();
      return;
    }
    if (!this.addAction) {
      return;
    }
    const row = this.rowForm.value;
    this.store.dispatch(new this.addAction({ row })).subscribe(() => this.rowForm.reset())
  }

  private updateRowForm(): void {
    if (!this._columns) {
      return;
    }

    const rowObject: { [key: string]: any } = {};
    this.row = this._columns.reduce(
      (acc, column: InfiniteScrollColumn) => {
        return { ...acc, [column.propertyName]: null };
      },
      rowObject
    )
    this.rowForm = this.scrollSvc.buildValidationFormGroup(this.row, this._columns) as UntypedFormGroup;
  }

  getSuggestionLabel(displayName: string): string {
    return `Select ${this.aOrAnPipe.transform(displayName)} ${displayName}`;
  }
}
