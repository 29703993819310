<app-modal width="400" height="600" [open]="open" (openChange)="openChange.emit($event)" (close)="close()">
  <app-modal-head></app-modal-head>
  <app-modal-body>
    <ag-grid-angular #agGrid class="ag-theme-alpine" [gridOptions]="gridOptions"  [rowData]="rowSub | async">
    </ag-grid-angular>
  </app-modal-body>
  <app-modal-foot>
    <app-button color="red" (click)="openChange.emit(false)">Cancel</app-button>
    <table>
      <tr>
        <td>
          Left & Drag to Change Order
        </td>
      </tr>
      <tr>
        <td>
          Hold Shift & Drag to Change Parent
        </td>
      </tr>
    </table>
    <app-button color="green" (click)="onClick()">Save</app-button>
  </app-modal-foot>
</app-modal>
