import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  private routeHistory: Array<RouterEvent> = [];

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      filter((event: NavigationEnd) => !event.url.startsWith('/error')),
      filter((event: NavigationEnd) => !event.url.startsWith('/missing')),
      filter((event: NavigationEnd) => !event.url.startsWith('/callback')),
      filter((event: NavigationEnd) => !event.url.startsWith('/missing')),
      filter((event: NavigationEnd) => !event.urlAfterRedirects.startsWith('/missing'))
    ).subscribe(
      (event: NavigationEnd) => {
        this.routeHistory = [...this.routeHistory, event];
      }
    );
  }

  getPreviousUrl(): string {
    return this.getUrl(1);
  }

  getUrl(fallback: number = 0): string {
    if (this.routeHistory.length > 0 && this.routeHistory.length > fallback) {
      const url = this.routeHistory[this.routeHistory.length - (fallback + 1)].url;
      return url.split('?')[0];
    }
    return '/';
  }

  getRouteHistroy(): Array<RouterEvent> {
    return this.routeHistory;
  }
}
