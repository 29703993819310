import { Component, OnInit, Input } from '@angular/core';
import { WorkflowStat } from '@app/core/api';

@Component({
  selector: 'app-product-lifecycle-status-chart',
  templateUrl: './product-lifecycle-status-chart.component.html',
  styleUrls: ['./product-lifecycle-status-chart.component.scss']
})
export class ProductLifecycleStatusChartComponent implements OnInit {
  @Input() categories: Array<WorkflowStat> = [];

  constructor() { }

  ngOnInit() {
  }
}
