import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-panel-button',
  templateUrl: './panel-button.component.html',
  styleUrls: ['./panel-button.component.scss']
})
export class PanelButtonComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  @Input() bold = false;
  @Output() clicked: EventEmitter<void> = new EventEmitter();
  @Input() disabled = false;

  constructor() { }

  ngOnInit(): void {
  }

  click(): void {
    if (this.disabled) {
      return;
    }
    this.clicked.emit();
  }
}
