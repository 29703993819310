/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Organization } from './organization';
import { RoleGroup } from './roleGroup';


export interface User { 
    accountLocked?: boolean;
    active?: boolean;
    authUserId?: string;
    clientId?: string;
    defaultOrg?: number;
    edrayUser?: boolean;
    email?: string;
    firstName?: string;
    id?: number;
    lastLoginDate?: string;
    lastName?: string;
    locale?: string;
    organizations?: Array<Organization>;
    partnerConnection?: string;
    passwordExpired?: boolean;
    passwordExpires?: boolean;
    passwordFailCount?: number;
    passwordUpdated?: string;
    phone?: string;
    profileImage?: string;
    roles?: Array<RoleGroup>;
    serviceType?: string;
    termOfService?: string;
    termOfServiceEdray?: string;
    timezone?: string;
    userName?: string;
}

