import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { ToastState } from '@app/core/stores/toast.state';
import { Observable } from 'rxjs';
import { Notification } from '@app/core/api';

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss']
})
export class ToastContainerComponent implements OnInit {
  @Select(ToastState.getToasts) toasts$: Observable<Array<Notification>>;

  constructor() { }

  ngOnInit() {
  }

}
