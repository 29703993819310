import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  set(name: string, value: string, expireDays: number = 7): void {
    let secure = false;
    if (window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1') {
      secure = true;
    }

    const date = new Date();
    date.setTime(date.getTime() + (expireDays * 24 * 60 * 60 * 1000));

    const cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/ ;samesite=strict' + (secure ? '; secure' : '');
    document.cookie = cookie;
  }

  get(name: string): string {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');

    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }

  remove(name: string): void {
    const date = new Date();

    // Expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
  }
}
