import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-template-sheet-edit',
  templateUrl: './template-sheet-edit.component.html',
  styleUrls: ['./template-sheet-edit.component.scss']
})
export class TemplateSheetEditComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  // Query all input elements
  @ViewChildren('input') inputs!: QueryList<ElementRef>;
  constructor() { }

  ngOnInit(): void {
  }
  // Move focus to the next input
  moveFocus(event: KeyboardEvent) {
    const currentInputIndex = this.inputs?.toArray()?.findIndex(
      (input) => input?.nativeElement === event?.target
    );

    // If the current input is found and it is not the last one
    if (currentInputIndex >= 0 && currentInputIndex < this.inputs?.length - 1) {
      // Set focus to the next input
      setTimeout(() => {
        const nextInput = this.inputs?.toArray()[currentInputIndex + 1];
        nextInput.nativeElement?.focus();
      }, 0);
    }
  }

}
