import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FilterJoin, FilterMatch, Suggestion } from '@app/core';
import { QueryFilterService, QueryFilter, QueryFilterConfiguration, SuggestionProperty, QueryFilterType } from '@app/shared/services/query-filter.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-query-filter-quick',
  templateUrl: './query-filter-quick.component.html',
  styleUrls: ['./query-filter-quick.component.scss']
})
export class QueryFilterQuickComponent implements OnInit, OnDestroy {
  @Input() suggestions: { [key: string]: Array<Suggestion> } = {};
  @Input() queryFilter: QueryFilter;
  @Input() expanded = false;
  @Output() expandedChange: EventEmitter<boolean> = new EventEmitter();

  unsubscribe$: Subject<void>;
  QueryFilterType = QueryFilterType;
  freeTextForm: UntypedFormControl = new UntypedFormControl('');
  searchformControl: UntypedFormControl = new UntypedFormControl('');

  constructor(private queryFilterSvc: QueryFilterService) { }

  ngOnInit(): void {
    this.unsubscribe$ = new Subject<void>();
    if (this.suggestions[this.queryFilter.suggestion]?.length > 0 && this.suggestions[this.queryFilter.suggestion]?.length <= 5) {
      this.expanded = true;
      this.expandedChange.emit(true);
    }
  }

  get filteredSuggestions() {
    const suggestions = this.suggestions[this.queryFilter.suggestion];
    const currentSearch = this.searchformControl.value?.toLowerCase() || null;
    const filtered = currentSearch?.length > 0 ?
      [...suggestions].filter(s => s.name?.toLowerCase().includes(currentSearch)) :
      suggestions;
    // console.log('Doing search:', currentSearch, filtered);
    return filtered;
  }

  private getSuggestionValue(suggestion: Suggestion): string {
    if (!suggestion) {
      return;
    }
    return this.queryFilter.suggestionProperty ? suggestion[this.queryFilter.suggestionProperty].toString() : suggestion[SuggestionProperty.Id].toString();
  }

  quickFilterIsActive(): boolean {
    if (this.suggestions[this.queryFilter.suggestion]?.length > 0) {
      if (this.queryFilter.filters && this.queryFilter.filters.length > 0) {
        return this.queryFilterSvc.filters.findIndex(filter => this.queryFilter.filters.findIndex(filters => filters.findIndex((f, i) => filter.by === f.by && filter.value === this.getSuggestionValue(this.suggestions[this.queryFilter.suggestion][i])) > -1) > -1) > -1;
      } else {
        const values: Array<string> = this.suggestions[this.queryFilter.suggestion].map(suggestion => this.getSuggestionValue(suggestion));
        return this.queryFilterSvc.filters.findIndex(filter => filter.by === this.queryFilter.propertyName && values.findIndex(v => v === filter.value) > -1) > -1;
      }
    }
  }

  filterIsActive(suggestion: Suggestion): boolean {
    if (this.queryFilter.filters && this.queryFilter.filters.length > 0 && this.suggestions[this.queryFilter.suggestion]?.length > 0) {
      const index = this.suggestions[this.queryFilter.suggestion].findIndex(s => s.id.toString() === suggestion.id);
      return this.queryFilter.filters[index].reduce(
        (acc: boolean, filter: QueryFilterConfiguration) => {
          const value = filter.value === undefined ? this.getSuggestionValue(suggestion) : filter.value;
          if (this.queryFilterSvc.filters.findIndex(f => f.by === filter.by && f.join === filter.join && f.match === filter.match && f.value === value) > -1) {
            return acc;
          }
          return false;
        },
        true
      );
    } else {
      return this.findFilterIndex(this.queryFilter.propertyName, this.getSuggestionValue(suggestion)) >= 0;
    }
  }

  filterByProperty(suggestion: Suggestion): void {
    if (this.queryFilter.filters && this.queryFilter.filters.length > 0) {
      this.removeAllFilters().then(() => {
        const index = this.suggestions[this.queryFilter.suggestion].findIndex(s => s.id.toString() === suggestion.id);
        const filters: Array<QueryFilterConfiguration> = this.queryFilter.filters[index];
        const by: Array<string> = filters.map(f => f.by);
        const join: Array<FilterJoin> = filters.map(f => f.join);
        const match: Array<FilterMatch> = filters.map(f => f.match);
        const value: Array<string> = filters.map(filter => filter.value === undefined ? this.getSuggestionValue(suggestion) : filter.value);
        this.queryFilterSvc.addFilters({ by, join, match, value });
      });
    } else {
      if (this.queryFilter.radio) {
        this.removeAllFilters().then(() => {
          this.queryFilterSvc.addFilters({
            by: [this.queryFilter.propertyName],
            join: [FilterJoin.AND],
            match: [FilterMatch.IS],
            value: [this.getSuggestionValue(suggestion)]
          });
        })
      } else {
        const index = this.findFilterIndex(this.queryFilter.propertyName, this.getSuggestionValue(suggestion));
        if (index > -1) {
          this.queryFilterSvc.removeFilters([index]);
        } else {
          // Subsequent checkbox filters of the same suggestion get joined with an `Or`
          const join: FilterJoin = this.queryFilterSvc.filters.findIndex(filter =>
            filter.by === this.queryFilter.propertyName &&
            filter.match === FilterMatch.IS &&
            this.suggestions[this.queryFilter.suggestion].findIndex(suggestion => filter.value === this.getSuggestionValue(suggestion)) > -1
          ) > -1 ? FilterJoin.OR : FilterJoin.AND;
          this.queryFilterSvc.addFilters({
            by: [this.queryFilter.propertyName],
            join: [join],
            match: [FilterMatch.IS],
            value: [this.getSuggestionValue(suggestion)]
          });
        }
      }
    }
  }

  filterByText() {
    const by: Array<string> = [this.queryFilter.propertyName];
    const join: Array<FilterJoin> = [FilterJoin.AND];
    const match: Array<FilterMatch> = [FilterMatch.CONTAINS];
    const value: Array<string> = [this.freeTextForm.value];
    this.queryFilterSvc.addFilters({ by, join, match, value });
  }

  removeAllFilters(): Promise<boolean> {
    let indexes: Array<number> = [];
    if (this.queryFilter.filters && this.queryFilter.filters.length > 0) {
      this.queryFilter.filters.forEach(filters => {
        indexes = [
          ...indexes,
          ...filters.reduce(
            (acc: Array<number>, filter: QueryFilterConfiguration) => {
              const index = this.queryFilterSvc.filters.findIndex(f => f.by === filter.by && f.join === filter.join && f.match === filter.match && f.value === filter.value);
              if (index > -1) {
                return [...acc, index];
              }
              return acc;
            },
            []
          )
        ];
      });
    } else {
      indexes = this.suggestions[this.queryFilter.suggestion].reduce(
        (acc: Array<number>, suggestion: Suggestion) => {
          const index = this.findFilterIndex(this.queryFilter.propertyName, this.getSuggestionValue(suggestion));
          if (index > -1) {
            return [...acc, index];
          }
          return acc;
        },
        []
      );
    }
    if (indexes.length > 0) {
      const unique = indexes.filter(
        (i, index) => {
          return indexes.indexOf(i) === index;
        }
      );
      return this.queryFilterSvc.removeFilters(unique);
    } else {
      return new Promise((resolve) => resolve(true))
    }
  }

  findFilterIndex(filterProperty: string, value: string): number {
    return this.queryFilterSvc.filters.findIndex(filter => filter.by === filterProperty && filter.value === value);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
