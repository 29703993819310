import { Organization, Filter } from '@app/core/api';

export class LoadOrganizationAction {
  static readonly type = '[Organization] Load Organization by ID';
  constructor(public payload: { organizationId: Organization['id'] }) { }
}

export class ResetOrganizationAction {
  static readonly type = '[Organization] Reset state';
  constructor() { }
}

export class LoadOrganizationFiltersAction {
  static readonly type = '[Organization] Load Organization filters';
  constructor() { }
}

export class AddOrganizationFilterAction {
  static readonly type = '[Organization] Add Organization filter';
  constructor(public payload: { filter: Filter }) { }
}

export class DeleteOrganizationFilterAction {
  static readonly type = '[Organization] Delete filter';
  constructor(public payload: { filter: Filter }) { }
}

export class ToggleOrganizationEditModeAction {
  static readonly type = '[Organization] Toggle Edit Mode';
  constructor() { }
}
