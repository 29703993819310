/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderBy } from './orderBy';
import { FilterEntry } from './filterEntry';
import { SortEntry } from './sortEntry';


export interface FilterDefinition { 
    fields?: Array<string>;
    filterEntries?: Array<FilterEntry>;
    groupBy?: string;
    keys?: Array<string>;
    orderBy?: Array<OrderBy>;
    sortEntries?: Array<SortEntry>;
    limit?: number;
    offset?: number;
}

