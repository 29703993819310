import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class RadioService {

  private activationSubject: Subject<number> = new Subject()

  constructor() { }

  get activation$(): Observable<number> {
    return this.activationSubject.asObservable();
  }

  activate(index: number): void {
    this.activationSubject.next(index);
  }
}
