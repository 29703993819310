<div class="logo">
  <img src="../../../../assets/logos/logo-color.png">
</div>
<div class="h1">Privacy and Terms</div>

<div class="p">
  Before you can continue, you must review and accept our Terms of Service and Privacy Policy. Usage of the Syndic8 platform
  constitutes acceptance of these terms.
</div>


<div class="button-container">
  <div class="button" [ngClass]="{'read': privacyRead}">
    <span (click)="viewPrivacyPolicy()">Click here to review our privacy policy</span>
    <i class="fal fa-check"></i>
  </div>
  <div class="button" [ngClass]="{'read': termsRead}">
    <span (click)="viewTerms()">Click here to review our terms of use</span>
    <i class="fal fa-check"></i>
  </div>
</div>

<div class="continue-button-container">
  <app-button color="green" (click)="continueToApp()" [disabled]="!privacyRead || !termsRead">
    <div>
      Continue
      <i class="fal fa-chevron-right"></i>
    </div>
  </app-button>
</div>

<div class="cancel" (click)="cancel()">
  Exit
  <i class="fal fa-chevron-right"></i>
</div>
