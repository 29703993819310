/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type NotificationType = 'ACTION' | 'ERROR' | 'INFO' | 'PROGRESS' | 'SUCCESS' | 'WARN';

export const NotificationType = {
    ACTION: 'ACTION' as NotificationType,
    ERROR: 'ERROR' as NotificationType,
    INFO: 'INFO' as NotificationType,
    PROGRESS: 'PROGRESS' as NotificationType,
    SUCCESS: 'SUCCESS' as NotificationType,
    WARN: 'WARN' as NotificationType
};

