import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Page } from '../../typings/page.types';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  @Input() page: Page;
  @Input() set entities(entities: Array<any>) {
    this._entities = entities;
    this.isHidden = entities.length === 0 && this.page.previous === 0;
  }

  @HostBinding('hidden') isHidden = false;

  _entities: Array<any> = [];

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }

  nextPage(): void {
    if (this._entities.length === this.page.length) {
      const relativeTo = this.route;
      this.router.navigate([], { relativeTo, queryParams: { page: this.page.next }, queryParamsHandling: 'merge' });
    }
  }

}
