<!-- <div class="toast-wrap" [ngClass]="{'error': notification.type === 'ERROR', 'warn': notification.type === 'WARN', 'success': notification.type === 'SUCCESS' }" (click)="dismissToast(notification)">
  <div class="icon-container">
    <i
      [ngClass]="
        notification.icon ?
          'fas fa-' + notification.icon :
          'fas fa-info-circle'
      "
    ></i>
  </div>
  <div class="text-container">
    <div class="title">{{ notification.title }}</div>
    <div class="message">{{ notification.message }}</div>
  </div>
</div> -->

<!-- Global notification live region, render this permanently at the end of the document -->
<div aria-live="assertive"
  class="tw-pointer-events-none tw-fixed tw-inset-0 tw-flex tw-items-end tw-px-4 tw-py-6 sm:tw-items-start sm:tw-p-6">
  <div class="tw-flex tw-w-full tw-flex-col tw-items-center tw-space-y-4 sm:tw-items-end">
    <!--
      Notification panel, dynamically insert this into the live region when it needs to be displayed

      Entering: "transform ease-out duration-300 transition"
        From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        To: "translate-y-0 opacity-100 sm:translate-x-0"
      Leaving: "transition ease-in duration-100"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div
      class="toast-tailwind  tw-pointer-events-auto tw-w-full tw-max-w-sm tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5">
      <div class="tw-p-4">
        <div class="tw-flex tw-items-start">
          <div class="tw-flex-shrink-0" *ngIf="notification.type === 'SUCCESS'">
            <svg class="tw-h-7 tw-w-7 tw-text-green-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div class="tw-flex-shrink-0" *ngIf="notification.type === 'ERROR'">
            <img src="https://cdn-icons-png.flaticon.com/512/9068/9068699.png" width="24px" heigh="24px" />
          </div>
          <div class="tw-flex-shrink-0" *ngIf="notification.type === 'INFO'">
            <img src="https://cdn-icons-png.flaticon.com/512/471/471713.png" width="24px" heigh="24px" />
          </div>
          <div class="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
            <p class="tw-text-sm tw-font-medium tw-text-gray-900">{{ notification.title }}</p>
            <p class="tw-mt-1 tw-text-sm tw-text-gray-500">{{ notification.message }}</p>
          </div>
          <div class="tw-ml-4 tw-flex tw-flex-shrink-0">
            <button type="button"
              class="tw-inline-flex tw-rounded-md tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2">
              <span class="tw-sr-only">Close</span>
              <svg class="tw-h-5 tw-w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                (click)="dismissToast(notification)">
                <path
                  d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>