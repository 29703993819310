import { State, Action, Selector, StateContext, Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { patch, append, removeItem, updateItem } from '@ngxs/store/operators';

import { Notification, NotificationApiService, Count, NotificationType, Organization } from '@app/core/api';

import {
  AddNotificationAction,
  LoadNotificationsAction,
  LoadNotificationByIdAction,
  RemoveNotificationAction,
  RemoveAllNotificationsAction,
  MarkAllNotificationsReadAction,
  MarkNotificationReadAction,
  UpdateNotificationAction,
  LoadNotificationCountAction,
  ResetNotificationAction,
} from './notification.actions';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LogoutUserAction, ToastService } from '@app/core';
import { OrganizationState } from '@app/features/organization/store/organization.state';

export interface NotificationStateModel {
  notifications: Array<Notification>;
  notificationCount: number;
}

const defaults: NotificationStateModel = {
  notifications: [
    // {
    //   id: 1,
    //   title: 'Product Optimization Failed',
    //   message: '16 products failed SEO optimization. I\'ll take you to the Product page!',
    //   route: '/org/1/product',
    //   read: false,
    //   type: NotificationType.ERROR,
    //   sentTime: new Date()
    // },
    // {
    //   id: 2,
    //   title: 'Catalog Notification',
    //   message: 'I\'ll take you to the Catalog page!',
    //   route: '/org/1/catalog',
    //   read: false,
    //   type: NotificationType.WARN,
    //   sentTime: new Date()
    // },
    // {
    //   id: 3,
    //   title: 'Catalog Processed Successfully',
    //   message: 'Your catalog finished all optimization procedures. Click here and I\'ll take you to the Catalog page!',
    //   route: '/org/1/catalog',
    //   read: false,
    //   type: NotificationType.SUCCESS,
    //   sentTime: new Date()
    // },
    // {
    //   id: 4,
    //   title: 'Info Notification',
    //   message: 'Syndic8 update 2.1.0 is now available',
    //   route: '/org/1/catalog',
    //   read: true,
    //   type: NotificationType.INFO,
    //   sentTime: new Date()
    // }
  ],
  notificationCount: 0
};

@State<NotificationStateModel>({
  name: 'notification',
  defaults
})
@Injectable()
export class NotificationState {

  constructor(
    private actions: Actions,
    private store: Store,
    private notificationApi: NotificationApiService,
    private toast: ToastService
  ) {
    // Reset Notification state on User Logout
    this.actions.pipe(ofActionDispatched(LogoutUserAction)).subscribe(
      () => {
        this.store.dispatch(new ResetNotificationAction());
      }
    );
  }

  @Selector()
  static getNotifications(state: NotificationStateModel): Array<Notification> {
    return state.notifications;
  }

  @Selector()
  static getNotificationCount(state: NotificationStateModel): number {
    return state.notificationCount;
  }

  @Action(AddNotificationAction)
  addNotification(
    { setState }: StateContext<NotificationStateModel>,
    { notification }: AddNotificationAction
  ) {
    setState(
      patch({ notifications: append([notification])})
    );
  }

  @Action(LoadNotificationCountAction)
  loadNotificationCount(
    { setState }: StateContext<NotificationStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.notificationApi.getNotificationCount(organization.id).pipe(
      tap(
        (count: Count) => {
          const notificationCount = count.count;
          setState(
            patch({ notificationCount })
          );
        }
      ),
      catchError(e => of(e))
    ).subscribe();
  }

  @Action(LoadNotificationsAction)
  loadNotifications(
    { setState }: StateContext<NotificationStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.notificationApi.getNotifications(organization.id).pipe(
      tap(
        (notifications: Array<Notification>) => {
          setState(
            patch({ notifications })
          );
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          return of(error);
        }
      )
    ).subscribe();
  }

  @Action(LoadNotificationByIdAction)
  loadNotificationById(
    { setState }: StateContext<NotificationStateModel>,
    { notificationId }: LoadNotificationByIdAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.notificationApi.getNotificationById(notificationId, organization.id).pipe(
      tap(
        (notification: Notification) => {
          setState(
            patch({ notifications: append([notification]) })
          );
        }
      )
    );
  }

  @Action(UpdateNotificationAction)
  updateNotification(
    { setState, dispatch }: StateContext<NotificationStateModel>,
    { notification }: UpdateNotificationAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.notificationApi.patchNotificationById(notification.id, organization.id, notification).pipe(
      tap(
        () => {
          setState(
            patch({ notifications: updateItem(note => note.id === notification.id, notification) })
          );
          dispatch(new LoadNotificationCountAction());
        }
      )
    );
  }

  @Action(MarkNotificationReadAction)
  markNotificationRead(
    { dispatch }: StateContext<NotificationStateModel>,
    { notification }: MarkNotificationReadAction
  ) {
    dispatch(
      new UpdateNotificationAction({
        ...notification,
        read: true
      })
    );
  }

  @Action(MarkAllNotificationsReadAction)
  markAllNotificationsRead(
    { getState, dispatch }: StateContext<NotificationStateModel>
  ) {
    const notifications = getState().notifications;
    for (let i = 0; i < notifications.length; i++) {
      dispatch(new MarkNotificationReadAction(notifications[i]));
    }
  }

  @Action(RemoveNotificationAction)
  removeNotification(
    { setState, dispatch }: StateContext<NotificationStateModel>,
    { notification }: RemoveNotificationAction
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.notificationApi.removeNotificationById(notification.id, organization.id).pipe(
      tap(
        () => {
          setState(
            patch<NotificationStateModel>({ notifications: removeItem(note => note.id === notification.id)})
          );
          dispatch(new LoadNotificationCountAction());
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'Notification Removed',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Remove Notification',
            type: NotificationType.ERROR
          });
          return of(error);
        }
      )
    );
  }

  @Action(RemoveAllNotificationsAction)
  removeAllNotifications(
    { setState, dispatch }: StateContext<NotificationStateModel>
  ) {
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    return this.notificationApi.removeNotifications(organization.id).pipe(
      tap(
        () => {
          setState(
            patch<NotificationStateModel>({ notifications: [] })
          );
          dispatch(new LoadNotificationCountAction());
          this.toast.add({
            expiration: 5000,
            title: 'Success',
            message: 'All Notifications Removed',
            type: NotificationType.SUCCESS
          });
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.toast.add({
            expiration: 5000,
            title: 'Service Error',
            message: 'Unable to Remove Notifications',
            type: NotificationType.ERROR
          });
          return of(error);
        }
      )
    );
  }

  @Action(ResetNotificationAction)
  resetNotifications(
    { setState }: StateContext<NotificationStateModel>
  ) {
    setState(defaults);
  }
}
