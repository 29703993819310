import { Notification } from '@app/core/api';

export class AddNotificationAction {
  static readonly type = '[Notification] Add Notification';
  constructor(public notification: Notification) { }
}

export class LoadNotificationCountAction {
  static readonly type = '[Notification] Load Notification count';
  constructor() { }
}

export class LoadNotificationsAction {
  static readonly type = '[Notification] Load all Notifications';
  constructor() { }
}

export class LoadNotificationByIdAction {
  static readonly type = '[Notification] Load a Notification by Id';
  constructor(public notificationId: Notification['id']) { }
}

export class UpdateNotificationAction {
  static readonly type = '[Notification] Update a Notification';
  constructor(public notification: Notification) { }
}

export class MarkNotificationReadAction {
  static readonly type = '[Notification] Mark a Notification as read';
  constructor(public notification: Notification) { }
}

export class MarkAllNotificationsReadAction {
  static readonly type = '[Notification] Mark all Notifications as read';
  constructor() { }
}

export class RemoveNotificationAction {
  static readonly type = '[Notification] Remove a Notification';
  constructor(public notification: Notification) { }
}

export class RemoveAllNotificationsAction {
  static readonly type = '[Notification] Remove all Notifications';
  constructor() {}
}

export class ResetNotificationAction {
  static readonly type = '[Notification] Reset state';
  constructor() {}
}
