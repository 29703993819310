import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-help-panel-header',
  templateUrl: './help-panel-header.component.html',
  styleUrls: ['./help-panel-header.component.scss']
})
export class HelpPanelHeaderComponent implements OnInit {

  @Input() title: string = 'Help';
  @Output() onSearchChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCloseClicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() searchForm: FormControl;
  @ViewChild('videoPlayer') videoplayer: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

}
