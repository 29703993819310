<div class="checkbox-placeholder" *ngIf="showNewRow || (showSelection && !showMasterCheckbox)"></div>
<div class="checkbox-container" (click)="checkMasterCheckbox()" *ngIf="showSelection && showMasterCheckbox">
  <div class="checkbox" [ngClass]="{'selected': (masterCheckbox$ | async)?.checked}"
    *ngIf="!loading && _rows.length > 0">
    <i class="fas fa-check"></i>
  </div>
</div>
<div class="image" *ngIf="images"></div>
<div class="chips" *ngIf="chips" [ngStyle]="{
    'min-width': chipWidth ? chipWidth + 'px' : 'unset',
    'max-width': chipWidth ? chipWidth + 'px' : 'unset'
  }">
</div>
<div class="title-subtitle" *ngIf="titles" [ngStyle]="{
    'min-width': _columns ? titleWidth + 'px' : 'unset',
    'max-width': _columns ? titleWidth + 'px' : 'unset'
  }">
</div>
<div class="ellipsis" *ngIf="showEllipsis"></div>
<div class="download-button" *ngIf="showDownloadButton"></div>
<div class="processing" *ngIf="processings && processings.length > 0"></div>
<div class="actions" *ngIf="actions && actions.length > 0"></div>
<div #columnsRef class="columns">
  <div #columnRef class="column" *ngFor="let column of _columns; trackBy: trackByFn; index as i" [ngStyle]="{
      'min-width': !column.fill && columnMinWidths[i] ? columnMinWidths[i] + 'px' : column.fill ? 'unset' : column.width ? column.width + 'px' : 100 / _columns.length + '%',
      'max-width': !column.fill && columnMinWidths[i] ? columnMinWidths[i] + 'px' : column.fill ? 'unset' : column.width ? column.width + 'px' : 100 / _columns.length + '%'
    }" [ngClass]="{
      'showSort': showSort && column.sortable !== false,
      'showFilter': showFilter && column.filterable !== false,
      'key-field': column.keyField
    }">
    <div class="name" [title]="column.displayName" [ngClass]="[hasActiveSort(column) ? 'active' : '', column.headerColor ? column.headerColor : '']"
      (click)="column.sortable !== false && sortColumn(column)">{{ column.displayName }} <span *ngIf="column.keyField">*</span></div>
    <div class="flex-fill"></div>
    <div class="filter-icon" *ngIf="showFilter && column.propertyName && column.filterable !== false"
      [ngClass]="{'active': hasActiveFilter(column)}"
      (click)="showFilterMenuClick = true; showFilterMenu = showFilterMenu?.propertyName === column.propertyName ? null : column">
      <i class="fas fa-filter"></i>
    </div>
    <div class="filter-menu" *ngIf="column.propertyName && showFilterMenu?.propertyName === column.propertyName"
      (click)="showFilterMenuClick = true">
      <div class="filter-input" [ngSwitch]="true" [ngClass]="{'row': column.type === InfiniteScrollColumnType.Boolean}">
        <ng-container *ngSwitchCase="column.type === InfiniteScrollColumnType.Date">
          <div class="filter-date"
            [ngClass]="{'disabled': columnsFilterForm.get([column.propertyName + 'Null'])?.value === true }">
            <div class="filter-name">From:</div>
            <app-date [formControl]="columnsFilterForm.get([column.propertyName + 'Start'])"></app-date>
            <div class="filter-name">To:</div>
            <app-date [formControl]="columnsFilterForm.get([column.propertyName + 'End'])"></app-date>
          </div>
          <div class="filter-null"
            [ngClass]="{'disabled': columnsFilterForm.get([column.propertyName + 'Start'])?.value !== '' || columnsFilterForm.get([column.propertyName + 'End'])?.value !== '' }">
            <input type="checkbox" [formControl]="columnsFilterForm.get([column.propertyName + 'Null'])" />
            <div class="filter-name">None</div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="column.type === InfiniteScrollColumnType.Datetime">
          <div class="filter-date"
            [ngClass]="{'disabled': columnsFilterForm.get([column.propertyName + 'Null'])?.value === true }">
            <div class="filter-name">From:</div>
            <app-date-time [formControl]="columnsFilterForm.get([column.propertyName + 'Start'])"></app-date-time>
            <div class="filter-name">To:</div>
            <app-date-time [formControl]="columnsFilterForm.get([column.propertyName + 'End'])"></app-date-time>
          </div>
          <div class="filter-null"
            [ngClass]="{'disabled': columnsFilterForm.get([column.propertyName + 'Start'])?.value !== '' || columnsFilterForm.get([column.propertyName + 'End'])?.value !== '' }">
            <input type="checkbox" [formControl]="columnsFilterForm.get([column.propertyName + 'Null'])" />
            <div class="filter-name">None</div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="column.type === InfiniteScrollColumnType.Boolean">
          <app-circle-button (click)="setCheckboxFilter(column, true)" label="Yes"
            [disabled]="columnsFilterForm.get([column.propertyName])?.value === true"></app-circle-button>
          <app-circle-button (click)="setCheckboxFilter(column, false)" label="No"
            [disabled]="columnsFilterForm.get([column.propertyName])?.value === false"></app-circle-button>
          <app-circle-button (click)="setCheckboxFilter(column)" label="Not Set"
            [disabled]="columnsFilterForm.get([column.propertyName])?.value === 'null'"></app-circle-button>
        </ng-container>
        <ng-container
          *ngSwitchCase="(column.type === InfiniteScrollColumnType.Suggestion || column.type === InfiniteScrollColumnType.MultiSuggestion) && !column.freeFormFilter && _suggestions[column.suggestion] && _suggestions[column.suggestion]?.length > 0">
          <app-suggestion #suggestion [disabled]="isAnyCheckboxSelected(column)" [include]="column?.suggestionInternalInclude ? column.suggestion : ''"
            [multiSelection]="column?.type === InfiniteScrollColumnType.MultiSuggestion" [field]="column.propertyName"
            [form]="columnsFilterForm" [provided]="_suggestions[column.suggestion]"
            [label]="getSuggestionLabel(column.displayName)" [showList]="true" [resetAfterNavigation]="false"
            (optionSelected)="addFilter(column, suggestion._chosen)" (optionDeselected)="removeFilter(column, $event)"
            (closed)="removeFilter(column)"
            [optionProp]="column.suggestionValueProperty ? column.suggestionValueProperty : InfiniteScrollSuggProp.Name">
          </app-suggestion>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <input *ngIf="!column.multiFilter" [type]="getFilterInputType(column)" placeholder="Filter query..."
            [formControl]="columnsFilterForm.get([column.propertyName])" (keyup.enter)="addFilter(column)" />
          <textarea *ngIf="column.multiFilter" placeholder="Multiple filter queries..."
            [formControl]="columnsFilterForm.get([column.propertyName])">
          </textarea>
        </ng-container>
      </div>
      <div class="filter-checkboxes" *ngIf="column.checkboxes && column.checkboxes.length > 0">
        <div class="filter-checkbox" *ngFor="let checkbox of column.checkboxes">
          <input type="checkbox" [formControl]="columnsFilterForm.get([column.propertyName + checkbox.title])"
            (change)="checkboxFilterSelected(column, checkbox)" />
          <div class="filter-name">{{ checkbox.title }}</div>
        </div>
      </div>
      <div class="filter-actions"
        *ngIf="(column.type !== InfiniteScrollColumnType.Suggestion || column.checkboxes && column.checkboxes.length > 0) ||
          column.freeFormFilter ||
          !_suggestions[column.suggestion] || _suggestions[column.suggestion] && _suggestions[column.suggestion]?.length === 0">
        <div class="button red" title="Clear" (click)="removeFilter(column)">Clear</div>
        <div class="flex-fill"></div>
        <div class="button green" title="Apply" *ngIf="column.type !== InfiniteScrollColumnType.Boolean" [ngClass]="{ 'disabled':
            column.type === InfiniteScrollColumnType.Date || column.type === InfiniteScrollColumnType.Datetime ? (
              columnsFilterForm.get([column.propertyName + 'Start'])?.value !== '' || columnsFilterForm.get([column.propertyName + 'End'])?.value !== '' ?
              columnsFilterForm.get([column.propertyName + 'Start'])?.invalid ||
              columnsFilterForm.get([column.propertyName + 'End'])?.invalid :
              columnsFilterForm.get([column.propertyName + 'Null'])?.invalid
            ) :
            column.checkboxes && column.checkboxes.length > 0 ? false :
            columnsFilterForm.get([column.propertyName])?.invalid
          }" (click)="addFilter(column)">Apply
        </div>
      </div>
    </div>
    <div class="handle" *ngIf="showHandles" (mousedown)="!column.fill && mouseDown($event, i)"
      [ngClass]="{ 'disabled': column.fill }">
      <div class="line"></div>
    </div>
  </div>
  <div class="column flex-fill" *ngIf="showColumnFill"></div>
</div>
