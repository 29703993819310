<div class="navigation-actions">
  <div class="logos" [ngClass]="{
    'inriver': (isInriver$ | async),
    'acquia': (isAcquia$ | async)
  }">
    <div class="wrap">
      <div class="icon" (click)="goHome()" *ngIf="!(isInriver$ | async)">
        <i [ngClass]="{ 'color': !navigationOpen && !selectionOpen }"></i>
      </div>
      <div class="text">
        <i></i>
      </div>
    </div>
  </div>
  <div class="upper navigation-action-list">
    <!-- Product Content -->
    <div class="navigation-group-header">
      <div class="navigation-group-header-icon"></div>
      <span>Product Content</span>
    </div>
    <app-navigation-action *ngIf="productsAction" [action]="productsAction"></app-navigation-action>
    <app-navigation-action *ngIf="collectionsAction" [action]="collectionsAction"></app-navigation-action>
    <app-navigation-action *ngIf="mediaAction" [action]="mediaAction"></app-navigation-action>
    <app-navigation-action *ngIf="importAction" [action]="importAction"></app-navigation-action>
    <app-navigation-action *ngIf="importFlowAction" [action]="importFlowAction"></app-navigation-action>

    <!-- Syndication -->
    <div class="navigation-group-header">
      <div class="navigation-group-header-icon"></div>
      <span>Syndication</span>
    </div>
    <app-navigation-action *ngIf="tradingPartnersAction" [action]="tradingPartnersAction"></app-navigation-action>
    <app-navigation-action *ngIf="verificationAction" [action]="verificationAction"></app-navigation-action>
    <app-navigation-action *ngIf="dataAuditAction" [action]="dataAuditAction"></app-navigation-action>
    <!-- <app-navigation-action *ngIf="inventoryAction" [action]="inventoryAction"></app-navigation-action> -->

    <!-- Administration -->
    <ng-container>
      <div class="navigation-group-header">
        <div class="navigation-group-header-icon"></div>
        <span>Administration</span>
      </div>
      <app-navigation-action *ngIf="templateAdminAction" [action]="templateAdminAction"></app-navigation-action>
      <app-navigation-action *ngIf="metadataViewAction" [action]="metadataViewAction"></app-navigation-action>
      <app-navigation-action *ngIf="referenceTableListAction" [action]="referenceTableListAction"></app-navigation-action>
      <app-navigation-action *ngIf="subscriptionsAction && userTokenMsLevel >= 100" [action]="subscriptionsAction"></app-navigation-action>
    </ng-container>
  </div>

  <div class="flex-fill"></div>
  <div class="lower navigation-action-list">
    <app-navigation-action *ngIf="dashboardAction" [action]="dashboardAction"></app-navigation-action>
    <app-navigation-action *ngIf="notificationsAction" [action]="notificationsAction"></app-navigation-action>
    <app-navigation-action *ngIf="videoTutorialsAction" [action]="videoTutorialsAction"></app-navigation-action>
    <app-navigation-action *ngIf="adminAction" [action]="adminAction"></app-navigation-action>
    <app-navigation-action [action]="accountNavigationActionItem"></app-navigation-action>
  </div>
</div>
