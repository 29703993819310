/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DataMapField } from './dataMapField';
import { DataMap } from './dataMap';


export interface DataMapping { 
    availableOrgFields?: Array<DataMapField>;
    availableSyndic8Fields?: Array<DataMapField>;
    id?: number;
    maps?: Array<DataMap>;
}

