<div class="accordion" [ngClass]="{'expanded': expanded}">
  <div class="title" (click)="expanded = !expanded; expandedChange.emit(expanded)">
    {{ queryFilter.displayName }}
    <span class="filter-icon" *ngIf="quickFilterIsActive()">
      <i class="fas fa-filter"></i>
    </span>
  </div>
  <div class="collapsible">
    <!-- Search -->
    <div class="searchbar-container" *ngIf="queryFilter.showSearch">
      <input type="search" placeholder="Search..." class="search-input" [formControl]="searchformControl">
    </div>

    <ng-container *ngIf="suggestions[queryFilter.suggestion]?.length > 0; else noSuggestions">
      <ng-container *ngIf="!queryFilter.radio">
        <div class="filter-container" *ngFor="let suggestion of filteredSuggestions"
          (click)="filterByProperty(suggestion)">
          <app-filter-checkbox [checked]="filterIsActive(suggestion)"></app-filter-checkbox>{{ suggestion.name }}
        </div>
      </ng-container>
      <app-radio-parent *ngIf="queryFilter.radio">
        <div class="filter-container" *ngFor="let suggestion of suggestions[queryFilter.suggestion]"
          (click)="!radio.checked && filterByProperty(suggestion)">
          <app-radio #radio [checked]="filterIsActive(suggestion)"></app-radio>{{ suggestion.name }}
        </div>
        <div class="filter-container" *ngIf="queryFilter.radioReset"
          (click)="quickFilterIsActive() && removeAllFilters()">
          <app-radio [checked]="!quickFilterIsActive()"></app-radio><i>None</i>
        </div>
      </app-radio-parent>
    </ng-container>
    <ng-template #noSuggestions>
      <ng-container *ngIf="queryFilter?.type === QueryFilterType.String else notAnyOtherType">
        <form *ngIf="queryFilter?.type === QueryFilterType.String" class="string" (submit)="filterByText()">
          <input type="text" [formControl]="freeTextForm">
          <app-button (click)="filterByText()">Search</app-button>
        </form>
      </ng-container>
      <ng-template #notAnyOtherType>
        <div class="no-filters">
          No filters available
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
