<ng-container *ngIf="(templateFields$ | async | fieldsfilter:(headerFilters | async)) as templateFields">
  <ng-container
    *ngIf="(!loading && templateFields.length > 0 && showTemplateRows) || (headerFilters | async).length > 0">
    <div class="grid" cdkDropList (cdkDropListDropped)="templateRowDrop($event)">
      <div class="headers" [ngStyle]="{'grid-template-columns': gridTemplateColumns}">
        <div class="column handle" *ngIf="isEditing && draggable"></div>
        <ng-container *ngFor="let column of _columns; index as i">
          <div *ngIf="column.type === TemplateColumnType.Action && isEditing" class="column action"></div>
          <div *ngIf="column.type === TemplateColumnType.Icon" class="column icon"></div>
          <div *ngIf="column.type === TemplateColumnType.Parent" class="column parent">
            <div class="title">Override</div>
            <app-tooltip text="Override this field by the parent value or revert back to a child value"
              [placement]="i >= _columns.length - 2 ? 'left' : 'right'">
            </app-tooltip>
          </div>
          <div *ngIf="column.type === TemplateColumnType.Name" class="column name">
            <ng-container *ngIf="viewAs === TemplateViewAs.Syndic8">
              <div class="title">{{ destination?.name }} Field</div>
              <app-tooltip text="These are the trading partner fields"
                [placement]="i >= _columns.length - 2 ? 'left' : 'right'">
              </app-tooltip>
            </ng-container>
            <ng-container *ngIf="viewAs === TemplateViewAs.AllPortCargoServicesInbound">
              <div class="title">ACS Field</div>
              <div class="flex-fill"></div>
              <div class="type">{{ templateOutputType }}</div>
            </ng-container>
            <ng-container *ngIf="viewAs === TemplateViewAs.Syndic8Inbound">
              <div class="title">Syndic8 Field</div>
              <!-- <div class="flex-fill"></div> -->
              <!-- <div class="type">{{ templateOutputType }}</div> -->
            </ng-container>
            <ng-container *ngIf="viewAs === TemplateViewAs.AllPortCargoServicesOutbound">
              <div class="title">Outbound Field</div>
              <div class="flex-fill"></div>
              <div class="type">{{ templateOutputType }}</div>
            </ng-container>
            <div class="handle" (mousedown)="mouseDown($event, i)">
              <div class="line"></div>
            </div>
            <div class="flex-fill"></div>
            <div class="filter-icon"
              (click)="showFilterMenuClick = true; showFilterMenu = (showFilterMenu === column.type ? null : column.type)"
              [ngClass]="{'active': hasActiveFilter(column.type)}">
              <i class="tw-pr-2 fas fa-filter"></i>
            </div>
            <div *ngIf="showFilterMenu === column.type" style="left:100px;" class="filter-menu">
              <div class="filter-input">
                <ng-container>
                  <input placeholder="Filter column..." [formControl]="columnsFilterForm.get([column.type])"
                    (keyup.enter)="addFilter(column.type)" />
                  <div class="filter-actions">
                    <div class="button red" title="Clear"
                      (click)="removeFilter(column.type); columnsFilterForm.reset();">Clear</div>
                    <div class="flex-fill"></div>
                    <div class="button green" title="Apply" (click)="addFilter(column.type)">Apply</div>
                  </div>
                </ng-container>
              </div>
            </div>

          </div>
          <div *ngIf="column.type === TemplateColumnType.JsonPath" class="column arrow"></div>
          <div *ngIf="column.type === TemplateColumnType.Arrow" class="column arrow"></div>
          <div *ngIf="column.type === TemplateColumnType.Required" class="column checkbox">
            <div class="title">Required</div>
            <app-tooltip text="Indicates whether this field is required, recommended, or optional"
              [placement]="i >= _columns.length - 2 ? 'left' : 'right'"></app-tooltip>

            <div class="flex-fill"></div>
            <div class="filter-icon"
              (click)="showFilterMenuClick = true; showFilterMenu = (showFilterMenu === column.type ? null : column.type)"
              [ngClass]="{'active': hasActiveFilter(column.type)}">
              <i class="tw-pr-2 fas fa-filter"></i>
            </div>

            <div class="handle" (mousedown)="mouseDown($event, i)">
              <div class="line"></div>
            </div>

            <div *ngIf="showFilterMenu === column.type" class="filter-menu">
              <div class="filter-input">
                <app-suggestion [form]="columnsFilterForm.get(column.type)" (optionSelected)="addFilter(column.type)"
                  (optionDeselected)="removeFilter(column.type)" (closed)="removeFilter(column.type)"
                  [provided]="requiredFilterOptions" label="Select a Requirement" optionProp="id">
                </app-suggestion>
              </div>
            </div>

          </div>
          <div *ngIf="column.type === TemplateColumnType.Length" class="column length">
            <div class="title">Length</div>
            <app-tooltip text="Require a minimum and/or maximum length"
              [placement]="i >= _columns.length - 2 ? 'left' : 'right'"></app-tooltip>
            <div class="handle" (mousedown)="mouseDown($event, i)">
              <div class="line"></div>
            </div>
          </div>
          <div *ngIf="column.type === TemplateColumnType.Default" class="column default">
            <div class="title">Default Value</div>
            <app-tooltip text="Configure a default value for each field"
              [placement]="i >= _columns.length - 2 ? 'left' : 'right'"></app-tooltip>
            <div class="handle" (mousedown)="mouseDown($event, i)">
              <div class="line"></div>
            </div>
          </div>
          <div *ngIf="column.type === TemplateColumnType.Type" class="column type">
            <div class="title">Data Type</div>
            <app-tooltip text="Select a data type"
              [placement]="i >= _columns.length - 2 ? 'left' : 'right'"></app-tooltip>
            <div class="handle" (mousedown)="mouseDown($event, i)">
              <div class="line"></div>
            </div>
          </div>
          <div *ngIf="column.type === TemplateColumnType.Format" class="column format">
            <div class="title">Data Format</div>
            <app-tooltip text="Select a data format"
              [placement]="i >= _columns.length - 2 ? 'left' : 'right'"></app-tooltip>
            <div class="handle" (mousedown)="mouseDown($event, i)">
              <div class="line"></div>
            </div>
          </div>

          <div *ngIf="column.type === TemplateColumnType.Field" class="column field">
            <ng-container *ngIf="viewAs === TemplateViewAs.Syndic8">
              <div *ngIf="!(isAcquia$ | async) && !(isInriver$| async)" class="title">Internal Field</div>
              <app-tooltip *ngIf="!(isAcquia$ | async) && !(isInriver$| async)"
                text="Select your internal field to map to the trading partner field"
                [placement]="i >= _columns.length - 2 ? 'left' : 'right'"></app-tooltip>

              <div *ngIf="(isAcquia$ | async) || (isInriver$| async)" class="title">Input Field</div>
              <app-tooltip *ngIf="(isAcquia$ | async) || (isInriver$| async)"
                text="Select your input field to map to the trading partner field"
                [placement]="i >= _columns.length - 2 ? 'left' : 'right'"></app-tooltip>
            </ng-container>
            <!-- Switches the title based upon TemplateViewAs (inbound, outbound, acs) -->
            <ng-container *ngIf="viewAs === TemplateViewAs.AllPortCargoServicesInbound">
              <div class="title">Inbound Field</div>
              <div class="flex-fill"></div>
              <div class="type">{{ templateInputType }}</div>
            </ng-container>
            <ng-container *ngIf="viewAs === TemplateViewAs.Syndic8Inbound">
              <div class="title">Field from Source</div>
              <!-- <div class="flex-fill"></div> -->
              <!-- <div class="type">{{ templateInputType }}</div> -->
            </ng-container>
            <ng-container *ngIf="viewAs === TemplateViewAs.AllPortCargoServicesOutbound">
              <div class="title">ACS Field</div>
              <div class="flex-fill"></div>
              <div class="type">{{ templateInputType }}</div>
            </ng-container>
            <div class="handle" (mousedown)="mouseDown($event, i)">
              <div class="line"></div>
            </div>

            <div class="flex-fill"></div>
            <div class="filter-icon"
              (click)="showFilterMenuClick = true; showFilterMenu = (showFilterMenu === column.type ? null : column.type)"
              [ngClass]="{'active': hasActiveFilter(column.type)}">
              <i class="tw-pr-2 fas fa-filter"></i>
            </div>
            <div *ngIf="showFilterMenu === column.type" style="left:100px;" class="filter-menu">
              <div class="filter-input">
                <ng-container>
                  <input placeholder="Filter column..." [formControl]="columnsFilterForm.get([column.type])"
                    (keyup.enter)="addFilter(column.type)" />
                  <div class="filter-actions">
                    <div class="button red" title="Clear"
                      (click)="removeFilter(column.type); columnsFilterForm.reset();">Clear</div>
                    <div class="flex-fill"></div>
                    <div class="button green" title="Apply" (click)="addFilter(column.type)">Apply</div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div *ngIf="column.type === TemplateColumnType.Comment" class="column comment">
            <div class="title">Comment</div>
            <div class="handle" (mousedown)="mouseDown($event, i)">
              <div class="line"></div>
            </div>
          </div>
          <div *ngIf="column.type === TemplateColumnType.Example" class="column comment">
            <div class="title">Example</div>
            <div class="handle" (mousedown)="mouseDown($event, i)">
              <div class="line"></div>
            </div>
          </div>
          <div *ngIf="column.type === TemplateColumnType.Definition" class="column comment">
            <div class="title">Definition</div>
            <div class="handle" (mousedown)="mouseDown($event, i)">
              <div class="line"></div>
            </div>
          </div>
          <div *ngIf="column.type === TemplateColumnType.Function" class="column function">
            <div class="title">Function</div>
            <app-tooltip text="Add or edit a function"
              [placement]="i >= _columns.length - 2 ? 'left' : 'right'"></app-tooltip>
            <div class="handle" (mousedown)="mouseDown($event, i)">
              <div class="line"></div>
            </div>
          </div>
          <div *ngIf="column.type === TemplateColumnType.Edit" class="column comment">
            <div class="title"></div>
            <div class="handle" (mousedown)="mouseDown($event, i)">
              <div class="line"></div>
            </div>
          </div>
        </ng-container>
      </div>
      <cdk-virtual-scroll-viewport #virtualScroll itemSize="58" style="height: 100vh;" class="viewport">
        <ng-container *cdkVirtualFor="let field of templateFields ; index as i;  ">
          <app-template-row [ngClass]="{'opacityClass': showCircleProgress === true}"
            [ngStyle]="{'grid-template-columns': gridTemplateColumns}" *ngIf="field && fieldsForm.at(field.idx)" cdkDrag
            [cdkDragDisabled]="!draggable || !isEditing" [columns]="_columns" [index]="field.idx"
            [templateInputType]="templateInputType" [templateOutputType]="templateOutputType" [viewAs]="viewAs"
            [field]="field" [fieldsLength]="templateFields.length" [isEditing]="isEditing"
            [dataTypeSuggestions]="dataTypeSuggestions" [syndic8FieldSuggestions]="syndic8FieldSuggestions"
            [acsFieldSuggestions]="acsFieldSuggestions" [isParent]="isParent" [draggable]="draggable"
            [destination]="destination" [form]="fieldsForm.at(field.idx)" [highlightRequired]="highlightRequired"
            (editFunction)="editFunction($event); showGoBackButton=false" (editComment)="editComment($event)"
            (editExample)="editExample($event)" (editDefinition)="editDefinition($event)"
            (remove)="activeRemoveRowIndex = field.idx; activeRemoveRow = field; showRemoveRowModal = true"
            (insertRow)="insertTemplateRow($event)" (blur)="rowBlurred()" (editJsonPath)="editJsonPath($event)"
            (editFieldBlock)="editField($event)">
            <i class="handle fas fa-grip-vertical" cdkDragHandle></i>
          </app-template-row>
        </ng-container>
        <div class="placeholder"></div>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-container>
  <div class="no-fields" *ngIf="!loading && templateFields.length === 0 && (headerFilters | async).length === 0">
    This Mapping Template Is Not Currently Available
  </div>
</ng-container>
<app-modal [(open)]="showRemoveRowModal" (close)="closeRemoveRowModal()" height="250" width="480">
  <app-modal-head>
    <app-modal-title>Remove Template Field</app-modal-title>
  </app-modal-head>
  <app-modal-body [center]="true">
    <div>
      Are you sure you'd like to remove <b>{{ activeRemoveRow?.name ? activeRemoveRow.name : 'this row' }}</b>?
    </div>
  </app-modal-body>
  <app-modal-foot [center]="true">
    <app-button (click)="closeRemoveRowModal()">Cancel</app-button>
    <div class="flex-fill"></div>
    <app-button color="red" (click)="removeRow()">Remove</app-button>
  </app-modal-foot>
</app-modal>
<app-modal [(open)]="showEditFunctionModal" height="85%" width="67%" (close)="closeEditFunctionModal()">
  <app-modal-head>
    <app-modal-title style="text-align: center;">
      <b *ngIf="viewAs === TemplateViewAs.Syndic8">{{ destination?.name }} Field: </b>
      <b *ngIf="viewAs === TemplateViewAs.Syndic8Inbound">{{ destination?.name }} Field: </b>
      <b *ngIf="viewAs === TemplateViewAs.AllPortCargoServicesInbound">Inbound Field: </b>
      <b *ngIf="viewAs === TemplateViewAs.AllPortCargoServicesOutbound">Outbound Field: </b>
      {{ activeTemplateField?.name }}
    </app-modal-title>
  </app-modal-head>
  <app-modal-body>
    <div class="tabs tw-relative">
      <div class="tab" [ngClass]="{
          active: 1 === selectedModalTab}" (click)="swapTabs(1)"> Function Mapping</div>
      <div *ngIf="showTemplateFieldMapping" class="tab" [ngClass]="{
          active: 2 === selectedModalTab}" (click)="swapTabs(2)"> Field Mapping</div>
          <!-- hide button below until we have a proper link -->
          <!-- <app-button class="tw-absolute tw-top-0 tw-right-0 tw-mt-4" (click)="navigateToFunctions()" color="green">Add Function  <i class="fas fa-external-link-square tw-ml-1"></i> </app-button> -->
    </div>


    <div class="section-body">
      <ng-container *ngIf="selectedModalTab === 1">
        <app-suggestion style="width: 47%;" class="function-select" label="Select a Function Type"
          [provided]="functionSuggestions" [selected]="[activeFunctionType]" (optionSelected)="setupFunction($event)"
          optionProp="id" [showList]="true" [showClose]="true">
        </app-suggestion>
        <div class="function-description" *ngIf="activeFunctionSuggestion?.description">Function Description: {{
          activeFunctionSuggestion?.description }}</div>
        <ng-container [ngSwitch]="true">
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.Concat">
            <div class="xpath-entry">
              <!-- change based on !Syndic8Inbound view -->
              <ng-container *ngIf="viewAs !== TemplateViewAs.Syndic8Inbound">
                <app-suggestion *ngIf="!isXPath" label="Select an internal field or enter text"
                  [provided]="viewAs === TemplateViewAs.AllPortCargoServicesInbound ? acsFieldSuggestions : syndic8FieldSuggestions"
                  (optionSelected)="concatOptionSelected($event)" [disableSetTypeahead]="true" [showClose]="true"
                  [showConfirm]="true">
                </app-suggestion>
              </ng-container>
              <!-- change based on Syndic8Inbound view -->
              <ng-container *ngIf="viewAs === TemplateViewAs.Syndic8Inbound">
                <app-suggestion *ngIf="!isXPath" label="Select an internal field or enter text"
                  [provided]="viewAs === TemplateViewAs.Syndic8Inbound ? acsFieldSuggestions : syndic8FieldSuggestions"
                  (optionSelected)="concatOptionSelected($event)" [disableSetTypeahead]="true" [showClose]="true"
                  [showConfirm]="true">
                </app-suggestion>
              </ng-container>
              <input *ngIf="isXPath" #concatInput placeholder="Enter an XPATH"
                (keyup.enter)="concatOptionSelected(concatInput.value); concatInput.value = ''" />
              <app-toggle leftText="Text/Variable" leftIcon="text" [(selected)]="isXPath" rightText="XPATH"
                rightIcon="code"></app-toggle>
            </div>
            <ng-container *ngIf="viewAs !== TemplateViewAs.Syndic8Inbound">
              <app-concatenation *ngIf="activeFunction" [(concatenation)]="activeFunction"
                (chipClick)="chipClick($event)" [showDeleteButtons]="true" [wrap]="true"
                [suggestions]="viewAs === TemplateViewAs.AllPortCargoServicesInbound ? acsFieldSuggestions : syndic8FieldSuggestions">
              </app-concatenation>
            </ng-container>
            <ng-container *ngIf="viewAs === TemplateViewAs.Syndic8Inbound">
              <app-concatenation *ngIf="activeFunction" [(concatenation)]="activeFunction"
                (chipClick)="chipClick($event)" [showDeleteButtons]="true" [wrap]="true"
                [suggestions]="viewAs === TemplateViewAs.Syndic8Inbound ? acsFieldSuggestions : syndic8FieldSuggestions">
              </app-concatenation>
            </ng-container>
            <app-suggestion label="Select a Case Function" [provided]="caseFunctionSuggestions"
              [selected]="[activeCaseFunction]" (optionSelected)="caseFunctionSelected($event)"
              (closed)="caseFunctionClosed($event)" [showList]="true">
            </app-suggestion>
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.ValidValue">
            <!-- <span class="function-description" *ngIf="activeFunctionType === FunctionType.ValidValue">This is a
              recommendation match
              <div class="tooltip"><span class="tooltiptext_3">AI match </span> <img class="rotate" id="my-img"
                  width="27" height="24" src="https://cdn-icons-png.flaticon.com/512/1035/1035552.png"
                  (mouseover)="hover(index);" (mouseout)="unhover(index);" style="margin-bottom: 0.7em;" /></div>
            </span> -->
            <app-valid-value *ngIf="activeFunctionType === FunctionType.ValidValue" [destination]="destination"
              [alignments$]="alignments$" [alignmentsLoading]="alignmentsLoading"
              [activeInternalColumnName]="activeInternalColumnName" [activeTemplateField]="activeTemplateField"
              [internalFieldSuggestions]="internalFieldSuggestions" [fieldListSuggestions]="fieldListSuggestions"
              [selectedSyndic8Field]="autoAImatchedValue" (validValueOptionSelected)="validValueOptionSelected($event)"
              [AddOrSaveBrandTpAlignmentAction]="AddOrSaveBrandTpAlignmentAction"
              [RemoveBrandTpAlignmentAction]="RemoveBrandTpAlignmentAction"
              [LoadBrandTpAlignmentsAction]="LoadBrandTpAlignmentsAction" [contextReference]="autoAImatchedValue"
              (newItemEvent)="addItem($event)" [readOnlyField]="readOnly" [showCloseOption]="showCloseOption">
            </app-valid-value>
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.FormatMarketingDescription">
            <app-suggestion class="field-select" label="Select a marketing decription format"
              [provided]="marketingDescriptionFormatSuggestions"
              (optionSelected)="descriptionTypeOptionSelected($event)" optionProp="id" [showClose]="true"
              [showList]="true">
            </app-suggestion>
            <app-destination-chip
              [chip]="{ value: getFunctionValue(activeTemplateField, activeFunctionType), type: ChipType.Literal }">
            </app-destination-chip>
          </ng-container>
          <ng-container
            *ngSwitchCase="activeFunctionType === FunctionType.ImageList || activeFunctionType === FunctionType.ImageListRow || activeFunctionType === FunctionType.ImageMultiList">
            <app-suggestion class="field-select" label="Select an Image Angle" include="Image Angle"
              (optionSelected)="imageListOptionSelected($event)" [resetAfterSelection]="true" [showClose]="true">
            </app-suggestion>
            <app-destination-chip-list [chips]="getFunctionValueChips(activeTemplateField, activeFunctionType)"
              [showDelete]="true" (removeChip)="removeActiveImageListChip($event)"></app-destination-chip-list>
          </ng-container>
          <ng-container
            *ngSwitchCase="activeFunctionType === FunctionType.Image || activeFunctionType === FunctionType.ImageThumbnail">
            <app-suggestion class="field-select" label="Select an Image Angle" include="Image Angle"
              (optionSelected)="imageOptionSelected($event)" [showClose]="true" [showList]="true">
            </app-suggestion>
            <app-destination-chip
              [chip]="{ value: getFunctionValue(activeTemplateField, activeFunctionType), type: ChipType.Variable }">
            </app-destination-chip>
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.TodayDateTime">
            <app-destination-chip
              [chip]="{ value: getFunctionValue(activeTemplateField, activeFunctionType), type: ChipType.Variable }">
            </app-destination-chip>
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.ToLower">
            <app-destination-chip
              [chip]="{ value: getFunctionValue(activeTemplateField, activeFunctionType), type: ChipType.Variable }">
            </app-destination-chip>
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.ToUpper">
            <app-destination-chip
              [chip]="{ value: getFunctionValue(activeTemplateField, activeFunctionType), type: ChipType.Variable }">
            </app-destination-chip>
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.ToProper">
            <app-destination-chip
              [chip]="{ value: getFunctionValue(activeTemplateField, activeFunctionType), type: ChipType.Variable }">
            </app-destination-chip>
          </ng-container>
          <app-reference-table-form *ngSwitchCase="activeFunctionType === FunctionType.ReferenceTable"
            [destination]="destination" [convert]="getConvert(activeTemplateField)" [(valid)]="saveDisabledSelf"
            (convertChange)="setConvert(activeTemplateField, $event)"
            [provided]="viewAs === TemplateViewAs.AllPortCargoServicesInbound ? acsFieldSuggestions : syndic8FieldSuggestions">
          </app-reference-table-form>

          <ng-contatiner *ngIf="viewAs !== TemplateViewAs.Syndic8Inbound">
            <app-if-then-else-form *ngSwitchCase="activeFunctionType === FunctionType.IfThenElse"
              [convert]="getConvert(activeTemplateField)" [(valid)]="saveDisabledSelf"
              (convertChange)="setConvert(activeTemplateField, $event)"
              [provided]="viewAs === TemplateViewAs.AllPortCargoServicesInbound ? acsFieldSuggestions : syndic8FieldSuggestions">
            </app-if-then-else-form>
          </ng-contatiner>
          <ng-contatiner *ngIf="viewAs === TemplateViewAs.Syndic8Inbound">
            <app-if-then-else-form *ngSwitchCase="activeFunctionType === FunctionType.IfThenElse"
              [convert]="getConvert(activeTemplateField)" [(valid)]="saveDisabledSelf"
              (convertChange)="setConvert(activeTemplateField, $event)"
              [provided]="viewAs === TemplateViewAs.Syndic8Inbound ? acsFieldSuggestions : syndic8FieldSuggestions">
            </app-if-then-else-form>
          </ng-contatiner>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.Substr">
            <input placeholder="Enter Start Position::End Position" [formControl]="genericFunctionControl" type="string"
              (ngModelChange)="substrValueChange()" />
            <!--
            <app-destination-chip
              [chip]="{ value: getFunctionValue(activeTemplateField, activeFunctionType), type: ChipType.Variable }">
            </app-destination-chip>
    -->
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.XMLPartyInfo">
            <input placeholder="Enter Party Info Field name" [formControl]="genericFunctionControl" type="string"
              (ngModelChange)="xmlPartyInfoValueChange()" />
            <!--
            <app-destination-chip
              [chip]="{ value: getFunctionValue(activeTemplateField, activeFunctionType), type: ChipType.Variable }">
            </app-destination-chip>
    -->
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.XMLTypeValue">
            <input placeholder="Enter Type Value Field name" [formControl]="genericFunctionControl" type="string"
              (ngModelChange)="xmlTypeValueValueChange()" />
            <!--
            <app-destination-chip
              [chip]="{ value: getFunctionValue(activeTemplateField, activeFunctionType), type: ChipType.Variable }">
            </app-destination-chip>
    -->
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.XMLDimensionInfo">
            <input placeholder="Enter Dimension Info Field name" [formControl]="genericFunctionControl" type="string"
              (ngModelChange)="xmlDimensionInfoValueChange()" />
            <!--
            <app-destination-chip
              [chip]="{ value: getFunctionValue(activeTemplateField, activeFunctionType), type: ChipType.Variable }">
            </app-destination-chip>
    -->
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.XMLShipWindow">
            <!--        <input placeholder="Enter Ship Window Field name" [formControl]="genericFunctionControl" type="string" (ngModelChange)="xmlShipWindowValueChange()" /> -->
            <app-destination-chip
              [chip]="{ value: getFunctionValue(activeTemplateField, activeFunctionType), type: ChipType.Variable }">
            </app-destination-chip>
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.XMLHarmonizedCodeInfo">
            <!--        <input placeholder="Enter Harmonized Code Info Field name" [formControl]="genericFunctionControl" type="string" (ngModelChange)="xmlHarmonizedCodeInfoValueChange()" /> -->
            <app-destination-chip
              [chip]="{ value: getFunctionValue(activeTemplateField, activeFunctionType), type: ChipType.Variable }">
            </app-destination-chip>
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.Replace">
            <input placeholder="Enter Replacement Characters::New Characters" [formControl]="genericFunctionControl"
              type="string" (ngModelChange)="replaceValueChange()" />
            <!--
            <app-destination-chip
              [chip]="{ value: getFunctionValue(activeTemplateField, activeFunctionType), type: ChipType.Variable }">
            </app-destination-chip>
    -->
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.MathAdd">
            <input placeholder="Enter an Add value" [formControl]="mathAddControl" type="string"
              (ngModelChange)="mathAddValueChange()" />
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.MathSubtract">
            <input placeholder="Enter a Subtract value" [formControl]="mathSubtractControl" type="string"
              (ngModelChange)="mathSubtractValueChange()" />
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.Language">
            <form [formGroup]="languageForm" class="tw-flex tw-gap-x-4">
              <input placeholder="Enter Current Language" formControlName="currentLanguage" type="string" />
              <input placeholder="Enter Output Language" formControlName="secondaryLanguage" type="string"/>
              <div class="tw-block tw-flex tw-flex-col">
                <label for="useFallBack">Return Current if Output Not Found</label>
                <input class="!tw-h-4" placeholder="" formControlName="useFallback" type="checkbox" />
              </div>
              <app-button (click)="saveLanguageFunction()" [disabled]="languageForm.invalid" color="green">Save Changes</app-button>
            </form>
            <div *ngIf="activeTemplateField?.convert">
              <p>Language Function: {{activeTemplateField?.convert}}</p>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.Pricing">
            <form [formGroup]="pricingForm" class="tw-flex tw-gap-x-4">
              <input placeholder="Enter Output Field" formControlName="outuputField" type="string" />
              <input placeholder="Enter Output Currency" formControlName="outputCurrency" type="string"/>
              <app-button (click)="savePrincingFunction()" [disabled]="pricingForm.invalid" color="green">Save Changes</app-button>
            </form>
            <div *ngIf="activeTemplateField?.convert">
              <p>Pricing Function: {{activeTemplateField?.convert}}</p>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.Function">
            <input placeholder="Enter value" [formControl]="genericFunctionControl" type="string"
              (ngModelChange)="genericFunctionValueChange()" />
          </ng-container>
          <ng-container *ngSwitchCase="activeFunctionType === FunctionType.ConcatDelimiter">
            <div class="xpath-entry">
              <ng-container *ngIf="viewAs !== TemplateViewAs.Syndic8Inbound">
                <app-suggestion label="Select an internal field or enter text" *ngIf="!isXPath"
                  [provided]="viewAs === TemplateViewAs.AllPortCargoServicesInbound ? acsFieldSuggestions : syndic8FieldSuggestions"
                  (optionSelected)="concatDelimiterOptionSelected($event)" [disableSetTypeahead]="true"
                  [showClose]="true" [showConfirm]="true">
                </app-suggestion>
              </ng-container>
              <ng-container *ngIf="viewAs === TemplateViewAs.Syndic8Inbound">
                <app-suggestion label="Select an internal field or enter text" *ngIf="!isXPath"
                  [provided]="viewAs === TemplateViewAs.Syndic8Inbound ? acsFieldSuggestions : syndic8FieldSuggestions"
                  (optionSelected)="concatDelimiterOptionSelected($event)" [disableSetTypeahead]="true"
                  [showClose]="true" [showConfirm]="true">
                </app-suggestion>
              </ng-container>
              <input *ngIf="isXPath" #concatDelimiterInput placeholder="Enter an XPATH"
                (keyup.enter)="concatDelimiterOptionSelected(concatDelimiterInput.value); concatDelimiterInput.value = ''" />
              <app-toggle leftText="Text/Variable" leftIcon="text" [(selected)]="isXPath" rightText="XPATH"
                rightIcon="code"></app-toggle>
            </div>
            <ng-container *ngIf="viewAs !== TemplateViewAs.Syndic8Inbound">
              <app-concatenation *ngIf="activeFunction" [functionType]="FunctionType.ConcatDelimiter"
                [concatenation]="concatDelimiterForm.value.concat" (concatenationChange)="updateConcatDelimiter($event)"
                [showDeleteButtons]="true" [wrap]="true"
                [suggestions]="viewAs === TemplateViewAs.AllPortCargoServicesInbound ? acsFieldSuggestions : syndic8FieldSuggestions">
              </app-concatenation>
            </ng-container>
            <ng-container *ngIf="viewAs === TemplateViewAs.Syndic8Inbound">
              <app-concatenation *ngIf="activeFunction" [functionType]="FunctionType.ConcatDelimiter"
                [concatenation]="concatDelimiterForm.value.concat" (concatenationChange)="updateConcatDelimiter($event)"
                [showDeleteButtons]="true" [wrap]="true"
                [suggestions]="viewAs === TemplateViewAs.Syndic8Inbound ? acsFieldSuggestions : syndic8FieldSuggestions">
              </app-concatenation>
            </ng-container>
            <input placeholder="Enter a Delimiter" (change)="updateConcatDelimiter()"
              [formControl]="concatDelimiterForm.get('delimiter')" />
            <input placeholder="Enter an element to select by index" (change)="updateConcatDelimiter()"
              [formControl]="concatDelimiterForm.get('index')" type="number" />
          </ng-container>

          <!--          Deprecated in favor of the 3 dots editing-->
          <!--          <ng-container *ngIf="userTokenMsLevel | async as msLevel">-->
          <!--            <ng-container *ngIf="msLevel >= 70 && activeFunctionType !== FunctionType.ValidValue">-->
          <!--              <div style="margin-top: 1.6em;" (click)="hideRawFieldDisplay()">Raw Field Value (Click to-->
          <!--                Show)</div>-->
          <!--              <ng-container *ngIf="showRawFieldDisplay">-->
          <!--                <ng-container *ngFor="let formVal of convertDisplayFormList;">-->
          <!--                  <input-->
          <!--                    style="height: 40px;font-size: 0.75em;padding: 0 10px;border: 1px solid rgba(0, 0, 0, 0.1);border-radius: 0;"-->
          <!--                    *ngIf="showRawFieldDisplay" [ngStyle]="{ 'mindWidth' : 200}" placeholder="Enter value"-->
          <!--                    [formControl]="convertDisplayForm.get(formVal)" type="string"-->
          <!--                    (ngModelChange)="genericFunctionValueChange()" />-->
          <!--                </ng-container>-->
          <!--                <div><app-button style="  margin-top: 1.9em;" (click)="saveConvert()">Save Field Value</app-button>-->
          <!--                </div>-->
          <!--              </ng-container>-->
          <!--            </ng-container>-->
          <!--          </ng-container>-->

        </ng-container>
      </ng-container>
      <!--    /** Used for preflight to allow field mapping on screen */-->
      <ng-container *ngIf="showTemplateFieldMapping && selectedModalTab === 2">
        <table>
          <tr>
            <th>Internal Field</th>
            <th></th>
            <th>Outbound Field</th>
          </tr>
          <tr>
            <td>
              <app-suggestion label="Select an internal field" [form]="fieldMappingForm" field="fieldStartMatch"
                optionProp="id" [showList]="true" [showClose]="true" [provided]="syndic8FieldSuggestions"
                (optionSelected)="fieldMappingForm.get('suggestedMatch').setValue(false)">
              </app-suggestion>
            </td>
            <td>
              <i class="far fa-long-arrow-right"></i>
            </td>
            <td>
              <span>{{ activeTemplateField?.name }}</span>
            </td>
          </tr>
          <tr>
            <th>Default Value</th>
          </tr>
          <tr>
            <td>
              <input placeholder="Default Value" [formControl]="fieldMappingForm.get('defaultValue')" />
            </td>
          </tr>
          <tr>
            <th>Data Type</th>
          </tr>
          <tr>
            <app-suggestion label="Select a Data Type" field="dataType" optionProp="id" [provided]="dataTypeSuggestions"
              [form]="fieldMappingForm" [showList]="true" [showClose]="true">
            </app-suggestion>
          </tr>
          <tr>
            <th>Data Format</th>
          </tr>
          <tr>
            <input placeholder="Data Format" [formControl]="fieldMappingForm.get('dataFormat')" />
          </tr>
        </table>
      </ng-container>
    </div>
  </app-modal-body>
  <app-modal-foot [center]="true">
    <app-button *ngIf="!showGoBackButton" (click)="closeEditFunctionModal()">Cancel</app-button>
    <app-button *ngIf="!showGoBackButton" color="red" (click)="clearFunction()">Clear</app-button>
    <app-button color="purple" (click)="goBack()" style="cursor: pointer;font-size: 0.95em;"
      *ngIf="activeFunctionType === FunctionType.ValidValue && showGoBackButton ">Back to AI Summery</app-button>
    <div class="flex-fill"></div>
    <app-button color="green" *ngIf="!showGoBackButton" [disabled]="saveDisabled || saveDisabledSelf"
      (click)="saveFunction()">Done</app-button>
  </app-modal-foot>
</app-modal>

<app-modal [(open)]="showValidValueModal" height="500" (close)="showValidValueModal = false">
  <app-modal-head>
    <app-modal-title><b>Concat With Valid Value:</b> {{ activeTemplateField?.name }}</app-modal-title>
  </app-modal-head>
  <app-modal-body *ngIf="getConvert(activeTemplateField)">
    <app-valid-value [destination]="destination" [alignments$]="alignments$" [alignmentsLoading]="alignmentsLoading"
      [activeInternalColumnName]="activeInternalColumnName" [activeTemplateField]="activeTemplateField"
      [internalFieldSuggestions]="internalFieldSuggestions" [fieldListSuggestions]="fieldListSuggestions"
      [selectedSyndic8Field]="activeInternalColumnName"
      (validValueOptionSelected)="concatValidValueOptionSelected($event)"
      [AddOrSaveBrandTpAlignmentAction]="AddOrSaveBrandTpAlignmentAction"
      [RemoveBrandTpAlignmentAction]="RemoveBrandTpAlignmentAction"
      [LoadBrandTpAlignmentsAction]="LoadBrandTpAlignmentsAction">
    </app-valid-value>
  </app-modal-body>
  <app-modal-foot [center]="true">
    <app-button color="red" *ngIf="!editingValidValue" (click)="showValidValueModal = false">Cancel</app-button>
    <app-button *ngIf="editingValidValue" (click)="showValidValueModal = false">Back</app-button>
    <div class="flex-fill"></div>
    <app-button color="green" *ngIf="!editingValidValue" (click)="addValidValueToConcat(activeInternalColumnName)">Add
      Valid Value</app-button>
  </app-modal-foot>
</app-modal>

<app-modal [(open)]="showEditCommentModal" class="edit-comment">
  <app-modal-head>
    <app-modal-title>Edit Comment</app-modal-title>
  </app-modal-head>
  <app-modal-body>
    <textarea [formControl]="editCommentControl"></textarea>
  </app-modal-body>
  <app-modal-foot [center]="true">
    <app-button color="red" (click)="showEditCommentModal = false">Cancel</app-button>
    <div class="flex-fill"></div>
    <app-button (click)="saveComment()">Save</app-button>
  </app-modal-foot>
</app-modal>

<app-modal [(open)]="showEditExampleModal" class="edit-comment">
  <app-modal-head>
    <app-modal-title>Edit Example</app-modal-title>
  </app-modal-head>
  <app-modal-body>
    <textarea [formControl]="editExampleControl"></textarea>
  </app-modal-body>
  <app-modal-foot [center]="true">
    <app-button color="red" (click)="showEditExampleModal = false">Cancel</app-button>
    <div class="flex-fill"></div>
    <app-button (click)="saveExample()">Save</app-button>
  </app-modal-foot>
</app-modal>

<app-modal [(open)]="showEditDefinitionModal" class="edit-comment">
  <app-modal-head>
    <app-modal-title>Edit Definition</app-modal-title>
  </app-modal-head>
  <app-modal-body>
    <textarea [formControl]="editDefinitionControl"></textarea>
  </app-modal-body>
  <app-modal-foot [center]="true">
    <app-button color="red" (click)="showEditDefinitionModal = false">Cancel</app-button>
    <div class="flex-fill"></div>
    <app-button (click)="saveDefinition()">Save</app-button>
  </app-modal-foot>
</app-modal>

<div *ngIf="showCircleProgress">
  <circle-progress
    style="position: fixed; background-color: white; bottom: 15em; left: auto; right: 0; width: calc(40% - 4.5rem); z-index: 9999; background-color: transparent; width: 56%; margin-left: auto; margin-right: 0;"
    [percent]="percetageCircleProgress" [radius]="100" [outerStrokeWidth]="16" [innerStrokeWidth]="8"
    [outerStrokeColor]="'#845bcb'" [innerStrokeColor]="'#a186cf'" [animation]="true" [animationDuration]="1300"
    [showUnits]="true" [showInnerStroke]="true" [clockwise]="true" [startFromZero]="true" [lazy]="false"
    [renderOnClick]="false"></circle-progress>
</div>

<app-modal [open]="showAImatchSummery" (close)="closeAISummery()" [height]="620" [width]="850" [showClose]="true">
  <div class="tabs_AI">
    <div class="tab_AI" [ngClass]="{
        active: 1 === selectedModalTab_AI}" (click)="swapTabs_AI(1)"> Valid Value Pairing</div>
    <div class="tab_AI" [ngClass]="{
        active: 2 === selectedModalTab_AI}" (click)="swapTabs_AI(2)"> Suggested Matches</div>
  </div>
  <!-- Start- Valid Value Matches View - TAB -->
  <ng-container *ngIf="selectedModalTab_AI === 1">
    <div style="margin-bottom: 0.5em; font-size: 1.03em; font-weight: 440; text-align: center; color: #845bcb;"> <i
        style="margin-right: 0.6em;" class="fas fa-robot"></i>AI Valid Values Summary
    </div>
    <div style="text-align: center; color: #24202c; margin-bottom: 2.5em;" class="tw-mt-2 tw-text-sm tw-text-gray-700">
      This
      summary allows for you to see the work that was generated by AI and review the valid values and its alignments
    </div>
    <ul role="list" style=" overflow-x: hidden; overflow-y: auto;"
      class="tw-divide-y tw-divide-gray-100 tw-overflow-hidden tw-bg-white tw-shadow-sm tw-ring-1 tw-ring-gray-900/5 ">
      <li class="tw-relative tw-flex tw-justify-between tw-gap-x-6 tw-px-4 tw-py-5 sm:tw-px-6">
        <div class="tw-flex tw-min-w-0 tw-gap-x-4">
          <div class="tw-min-w-0 tw-flex-auto">
            <p class="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">
              <span>
                <span class="tw-inset-x-0 tw--top-px tw-bottom-0"></span>
                Valid Value Fields
              </span>
            </p>
            <p class="tw-mt-1 tw-flex tw-text-xs tw-leading-5 tw-text-gray-500" *ngFor="let vvmatches of VVmatches">
              <span class="tw-text-lg tw-relative tw-truncate"> {{vvmatches ?? '-'}}</span>
            </p>
          </div>
        </div>
        <div class="tw-flex tw-min-w-0 tw-gap-x-4">
          <div class="tw-min-w-0 tw-flex-auto">
            <p class="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">
              <span>
                <span class="tw-inset-x-0 tw--top-px tw-bottom-0"></span>
                Trading Partner Fields
              </span>
            </p>
            <p class="tw-mt-1 tw-flex tw-text-xs tw-leading-5 tw-text-gray-500"
              *ngFor="let tradingPartnerField of tradingPartnerFieldsSummeryAI">
              <span class="tw-text-lg tw-relative tw-truncate"> {{tradingPartnerField.name}}</span>
            </p>
          </div>
        </div>
        <div class="tw-flex tw-min-w-0 tw-gap-x-4">
          <div class="tw-min-w-0 tw-flex-auto">
            <p class="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">
              <span style="margin-left: 1.3em;">
                Alignments
              </span>
            </p>
            <span class="tw-mt-1 tw-flex tw-text-xs tw-leading-5 tw-text-gray-500" style="margin-bottom: 0.5em;"
              *ngFor="let tradingPartnerField of tradingPartnerFieldsSummeryAI ; let index = index">
              <app-button class="buttonReview" style="    width: 120px;" color="purple" id="{{index}}"
                *ngIf="!visibleIndices.has(index)" title="Review proposed correlations"
                alt="Review proposed correlations"
                (click)="editFunction(tradingPartnerField,'showGoBackButton', index); showAImatchSummery =false">Review
              </app-button>
              <p *ngIf="visibleIndices.has(index)" class="tw-mt-1 tw-flex tw-text-xs tw-leading-5 tw-text-gray-500"
                style="display: flex; margin-bottom: 0 !important;">
                <span class="tw-text-lg tw-relative tw-truncate"> <b style="display: flex;">Reviewed<img class="checked"
                      src="https://cdn-icons-png.flaticon.com/128/5290/5290119.png" width="21" height="21"
                      title="Checked" style="position: relative; margin-bottom: 0.50rem; margin-left: 0.3em;"></b>
                </span>
              </p>
            </span>
          </div>
        </div>
      </li>
      <div *ngIf="VVmatches.length == 0" style="text-align: center; padding: 7em; font-size: 1.1em;"> No valid values
        found!</div>
    </ul>
  </ng-container>
  <!-- End - Valid Value Matches View - TAB 1 -->

  <!-- Start - Suggested Matches View  - TAB 2 -->
  <ng-container *ngIf="selectedModalTab_AI === 2">
    <div style="margin-bottom: 0.5em; font-size: 1.03em; font-weight: 440; text-align: center; color: #845bcb;"> <i
        style="margin-right: 0.6em;" class="fas fa-robot"></i>Suggested Matches Summary
    </div>
    <div style="text-align: center; color: #24202c;" class="tw-mt-2 tw-text-sm tw-text-gray-700">
      These are
      suggested/best-matched internal brand fields to their corresponding destination fields
    </div>
    <div style="text-align: center; color: #24202c;" class="tw-mt-2 tw-text-sm tw-text-gray-700"> Sorted by correlation
      percentage (from low to high)
    </div>
    <app-modal-head>
      <div *ngIf="showSuggestedMatchesResult && showButtonsAcceptReject" style="float: right; margin-right: 0.5em;">
        <app-button style="margin: 1em 0.5em 0em 0em;" color="purple" (click)="acceptSuggestedMatches()">Accept
          All</app-button>
        <app-button color="red" (click)="rejectAllSuggestedFields()">Reject All</app-button>
      </div>
      <div *ngIf="acceptedAllSuggested && !showButtonsAcceptReject"
        style="position: relative; top: 0.6em; float: right; margin-right: 0.5em; display: flex;">
        <h3 class="fadeIn" style="font-size: 1.1em !important;"> Accepted</h3> <img class="checked_2"
          src="https://cdn-icons-png.flaticon.com/128/5290/5290119.png" width="21" height="21" title="Checked"
          style=" margin-bottom: 0.50rem; margin-left: 0.5em;">
      </div>
      <div *ngIf="rejectAllSuggested && !showButtonsAcceptReject"
        style="position: relative; top: 0.6em; float: right; margin-right: 0.5em; display: flex;">
        <h3 class="fadeIn" style="font-size: 1.1em !important;"> Rejected</h3> <img class="checked_2"
          src="https://cdn-icons-png.flaticon.com/512/7698/7698976.png" width="21" height="21" title="Cross"
          style="margin-bottom: 0.50rem; margin-left: 0.5em;">
      </div>
      <div *ngIf="showSpinner"
        style="position: relative; float: right; font-size: 0.6em; font-weight: 400; top: 2.5em;">
        <app-spinner [smaller]="true"></app-spinner>
      </div>
    </app-modal-head>
    <app-modal-body style="background-color: white !important;" class="fadeIn" *ngIf="showSuggestedMatchesResult"
      [center]="false" style="background-color: white !important;">
      <ul role="list" style=" overflow-x: hidden; overflow-y: auto;"
        class="tw-divide-y tw-divide-gray-100 tw-overflow-hidden tw-bg-white ">
        <li class="tw-relative tw-flex tw-justify-between tw-gap-x-6 tw-px-4 tw-py-5 sm:tw-px-6">
          <div class="tw-flex tw-min-w-0 tw-gap-x-4">
            <div class="tw-min-w-0 tw-flex-auto">
              <p class="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">
                <span>
                  <span class="tw-inset-x-0 tw--top-px tw-bottom-0"></span>
                  Suggested Internal Fields
                </span>
              </p>
              <span class="tw-mt-1 tw-flex tw-text-xs tw-leading-5 tw-text-gray-500"
                *ngFor="let suggestedInt of suggestedInternal">
                <div class="tooltip" style=" width: 100%; display: flex; margin-left: 0.8em">
                  <span *ngIf="suggestedInt.resultValue !==null && suggestedInt.resultValue !==NaN" class="tooltiptext">
                    {{suggestedInt.resultValue * 100 | number: '2.'}} % AI correlation </span>
                  <span *ngIf="suggestedInt.resultValue == null " class="tooltiptext2">AI correlation not found
                  </span>
                  <!-- green lightbulb = 100% correlation-->
                  <img style="right: 1em; position: relative; min-width: 26px;
              min-height: 24px; max-width: 24px;
              max-height: 24px;" class="rotate" *ngIf="suggestedInt.resultValue >= 0.8" width="24" height="24"
                    src="../../../../../assets/logos/green_lightbulb.png" />
                  <!-- yellow lightbulb = from 0 to 99% correlation-->
                  <img style="right: 1em; position: relative; min-width: 26px;
              min-height: 24px; max-width: 24px;
              max-height: 24px;" class="rotate" *ngIf="suggestedInt.resultValue < 0.8" width="24" height="24"
                    src="https://cdn-icons-png.flaticon.com/512/1035/1035633.png" />
                  <!-- red lightbulb means no correlation found-->
                  <img style="right: 1em; position: relative; min-width: 26px;
              min-height: 24px; max-width: 24px;
              max-height: 24px;" class="rotate"
                    *ngIf="suggestedInt.resultValue == null || suggestedInt.resultValue == 0 " width="24" height="24"
                    src="https://cdn-icons-png.flaticon.com/512/1035/1035471.png" />
                  <span class="tw-text-lg tw-relative tw-truncate">{{ (suggestedInt.name.length>28)? (suggestedInt.name
                    | slice:0:30)+'...':(suggestedInt.name) | lowercase }}</span>
                </div>
              </span>
            </div>
          </div>
          <div class="tw-flex tw-min-w-0 tw-gap-x-4">
            <div class="tw-min-w-0 tw-flex-auto">
              <p class="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900" style="text-align: right;">
                <span>
                  <span class="tw-inset-x-0 tw--top-px tw-bottom-0"></span>
                  Trading Partner Fields
                </span>
              </p>
              <span class="tw-mt-1 tw-flex tw-text-xs tw-leading-5 tw-text-gray-500" *ngFor="let tpvalues of TPvalues"
                style="text-align: right !important;">
                <div class="tooltip" style=" width: 100%; display: initial !important;">
                  <span class="tw-text-lg tw-relative tw-truncate"> {{(tpvalues.length>30)? (tpvalues|
                    slice:0:28)+'...':(tpvalues) }}</span>
                </div>
              </span>
            </div>
          </div>
        </li>
      </ul>
    </app-modal-body>
    <app-modal-body *ngIf="!showSuggestedMatchesResult" [center]="false" style="background-color: white !important;">
      <app-spinner style="position: relative; top: 90px;" [bigger]="true"></app-spinner>
      <span style="text-align: center; position: relative; top: 70px;">Loading suggested matches..</span>
    </app-modal-body>
  </ng-container>
  <!-- End - Suggested Matches View  - TAB -->

</app-modal>
<app-template-messages *ngIf="showTemplateRows" [open]="showMessages" (openChange)="showMessagesChange.emit($event)"
  [template]="template" [internalFieldSuggestions]="internalFieldSuggestions" [sheetSuggestions]="sheetSuggestions"
  [SaveMessagesAction]="SaveMessagesAction">
</app-template-messages>

<app-template-sheet-order [open]="showSheetOrder" (openChange)="showSheetOrderChange.emit($event)" [template]="template"
  [SaveSheetOrderAction]="SaveSheetOrderAction">
</app-template-sheet-order>

<app-json-path-modal [open]="showJsonPathModal" (openChange)="toggleJsonPathModal($event)"
  [jsonPathBaseFile$]="jsonPathBaseFile$" [jsonPathControlFormGroup]="jsonPathControlFormGroup"
  [LoadSegmentPaths]="LoadSegmentPaths" [segmentPaths$]="segmentList$" [SaveJsonPath]="saveJsonPath.bind(this)"
                     [SaveLanguageMap]="saveLanguageMapFunc.bind(this)">
</app-json-path-modal>

<app-template-field-edit #EditField [open]="showEditField" (openChange)="toggleEditField($event)"
  [SaveField]="saveEditField.bind(this)">
</app-template-field-edit>
