<div class="wrapper">
  <div class="content" [ngClass]="{'in': in}">
    <div class="header">
      Oops...
    </div>
    <div class="subheader" *ngIf="!info">
      It looks like we're having some issues.
    </div>
    <div class="subheader" *ngIf="info">
      {{ info }}
    </div>
    <hr>
    <div class="clearfix"></div>
    <div class="context">
      Go back to the previous page and try again.<br>
      If you think something is broken, please report a problem.
    </div>
    <div class="buttons">
      <a class="button" [href]="supportUrl" target="_blank">Report a problem</a>
      <a class="button" (click)="navigateBack()">Go Back</a>
      <a class="button" routerLink="/logout">Logout</a>
      <a class="button" *ngIf="error" (click)="showDebug = !showDebug">Show debug</a>
    </div>
    <div class="debug" *ngIf="showDebug && error">
      <pre>{{ error }}</pre>
    </div>
  </div>
</div>

<app-toast-container></app-toast-container>
