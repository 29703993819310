import {Component, ElementRef, ViewChild} from '@angular/core';
import {IHeaderAngularComp} from "@ag-grid-community/angular";
import {IHeaderParams} from "@ag-grid-community/core";
import {Actions, ofActionDispatched, Store} from "@ngxs/store";
import {
  SetActiveDataProcessField,
  UpdateSummaryStats
} from "@app/features/destination/stores/destination-preflight.actions.state";

@Component({
  selector: 'app-preflight-custom-header',
  templateUrl: './preflight-custom-header.component.html',
  styleUrls: ['./preflight-custom-header.component.scss']
})
export class PreflightCustomHeaderComponent implements IHeaderAngularComp {
  public params!: IHeaderParams;
  totalFailed = 0;
  totalPassed = 0;
  colDisplayName = "";

  static canvas = document.createElement("canvas");
  static cachedSized = new Map<string, number>();

  constructor(private store: Store, private actions$: Actions) {
  }

  autoSizeAll(skipHeader: boolean) {
    const allColumnIds: string[] = [];
    this.params.columnApi!.getColumns()!.forEach((column) => {
      allColumnIds.push(column.getId());
    });
    this.params.columnApi!.autoSizeColumns(allColumnIds, skipHeader);
  }
  dataProcessField = undefined;

  agInit(params: any): void {
    this.colDisplayName = params.displayName;
    this.params = params;
    this.dataProcessField = params.dataProcessField;
    this.totalFailed = params.totalFailed;
    this.totalPassed = params.totalPassed;

    this.actions$
      .pipe(ofActionDispatched(UpdateSummaryStats))
      .subscribe(({payload}) => {
        const summaryStats = payload["summaryStats"]
        this.updateFailed(summaryStats[params.displayName])
      });
  }

  @ViewChild('content') elementView: ElementRef;


  ngAfterViewInit() {
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }

  updateFailed(val) {
    this.totalFailed = val;
  }

  onClick(): void {
    console.log("On click for header")
    if(this.dataProcessField)
      this.store.dispatch(new SetActiveDataProcessField(this.dataProcessField));
  }
}
