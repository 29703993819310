/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationReference } from './organizationReference';


export interface TradingPartnerAlignment { 
    aiGenerated?: boolean;
    aiPercent?: number;
    columnName?: string;
    columnValueBrand?: string;
    columnValueTradingPartner?: string;
    internalColumnName?: string;
    orgRef?: OrganizationReference;
    orgRefBrand?: OrganizationReference;
    orgRefTrandingPartner?: OrganizationReference;
    tradingPartnerBrandId?: number;
    tradingPartnerId?: number;
    typeName1?: string;
    typeName2?: string;
    typeName3?: string;
    typeName4?: string;
    typeName5?: string;
    typeValue1?: string;
    typeValue2?: string;
    typeValue3?: string;
    typeValue4?: string;
    typeValue5?: string;
}

