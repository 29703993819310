import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { APlusContentApiService } from './api/aPlusContentApi.service';
import { AdminApiService } from './api/adminApi.service';
import { AmazonApiService } from './api/amazonApi.service';
import { AuthAPIService } from './api/authAPI.service';
import { BrandTpAlignmentApiService } from './api/brandTpAlignmentApi.service';
import { BrandTpSpecApiService } from './api/brandTpSpecApi.service';
import { CalendarApiService } from './api/calendarApi.service';
import { CatalogApiService } from './api/catalogApi.service';
import { ChannelApiService } from './api/channelApi.service';
import { ChartApiService } from './api/chartApi.service';
import { ClientApiService } from './api/clientApi.service';
import { ConnectorApiService } from './api/connectorApi.service';
import { ContainerApiService } from './api/containerApi.service';
import { CostApiService } from './api/costApi.service';
import { CreateSseAPIService } from './api/createSseAPI.service';
import { DataflowApiService } from './api/dataflowApi.service';
import { DocApiService } from './api/docApi.service';
import { DynamicTableApiService } from './api/dynamicTableApi.service';
import { EdilogisticsApiService } from './api/edilogisticsApi.service';
import { FilterApiService } from './api/filterApi.service';
import { GridApiService } from './api/gridApi.service';
import { ImageApiService } from './api/imageApi.service';
import { ImportApiService } from './api/importApi.service';
import { LanguageApiService } from './api/languageApi.service';
import { LocationApiService } from './api/locationApi.service';
import { LogisticsApiService } from './api/logisticsApi.service';
import { LogisticsDocApiService } from './api/logisticsDocApi.service';
import { LookupGroupApiService } from './api/lookupGroupApi.service';
import { MappingApiService } from './api/mappingApi.service';
import { MessageApiService } from './api/messageApi.service';
import { MetricApiService } from './api/metricApi.service';
import { NotificationApiService } from './api/notificationApi.service';
import { OpenaiService } from './api/openai.service';
import { OrgApiService } from './api/orgApi.service';
import { ParentApiService } from './api/parentApi.service';
import { PartnerApiService } from './api/partnerApi.service';
import { PluginsApiService } from './api/pluginsApi.service';
import { PricingApiService } from './api/pricingApi.service';
import { ProductApiService } from './api/productApi.service';
import { PublicApiService } from './api/publicApi.service';
import { PubliclinkApiService } from './api/publiclinkApi.service';
import { PurchaseOrderApiService } from './api/purchaseOrderApi.service';
import { ReportApiService } from './api/reportApi.service';
import { RoleApiService } from './api/roleApi.service';
import { SseApiService } from './api/sseApi.service';
import { SubcatalogApiService } from './api/subcatalogApi.service';
import { SubscriptionApiService } from './api/subscriptionApi.service';
import { SyndicationApiService } from './api/syndicationApi.service';
import { TeamApiService } from './api/teamApi.service';
import { TemplateApiService } from './api/templateApi.service';
import { TestApiService } from './api/testApi.service';
import { TpAlignmentApiService } from './api/tpAlignmentApi.service';
import { TpSpecApiService } from './api/tpSpecApi.service';
import { UploadApiService } from './api/uploadApi.service';
import { UserApiService } from './api/userApi.service';
import { VerificationApiService } from './api/verificationApi.service';
import { ViewApiService } from './api/viewApi.service';
import { WorkTaskApiService } from './api/workTaskApi.service';
import { WorkflowApiService } from './api/workflowApi.service';
import { YardManagementApiService } from './api/yardManagementApi.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
