<app-query-filter-form #queryFilterForm
  [suggestions]="suggestions"
  [queryFilters]="queryFilters"
  [(filters)]="filters"
  (filtersChange)="filtersChange.emit($event)"
  [loadProductTypeQueryFiltersAction]="loadProductTypeQueryFiltersAction"
  [allowOrJoin]="allowOrJoin"
  [allowDateRange]="allowDateRange">
</app-query-filter-form>
<app-query-filter-list [suggestions]="suggestions" [queryFilters]="queryFilters" [filters]="filters" (removeFilter)="removeFilter($event)"></app-query-filter-list>
