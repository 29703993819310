/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationReference } from './organizationReference';


export interface VerificationReport { 
    companyId?: number;
    destinationId?: number;
    destinationRef?: OrganizationReference;
    docId?: number;
    inventoryLevel?: number;
    liveness?: number;
    mapCompliancy?: number;
    overallScore?: number;
    reportDate?: string;
    reportId?: number;
    requestId?: number;
    skuFullyLive?: number;
    skuNoLongerLive?: number;
    skuNotLive?: number;
    skuPartiallyLive?: number;
    skuTotal?: number;
    styleFullyLive?: number;
    styleNoLongerLive?: number;
    styleNotLive?: number;
    stylePartiallyLive?: number;
    styleTotal?: number;
    upcFullyLive?: number;
    upcLiveInv?: number;
    upcLiveNaInv?: number;
    upcLiveNaNoInv?: number;
    upcLiveNoInv?: number;
    upcNoLongerLive?: number;
    upcNotFound?: number;
    upcNotLive?: number;
    upcNotLiveInv?: number;
    upcNotLiveNoInv?: number;
    upcPartiallyLive?: number;
    upcTotal?: number;
}

