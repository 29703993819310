<ng-container *ngIf="cards.length > 0">
  <ng-container *ngFor="let card of cards; index as i">
    <div class="card"
      [ngClass]="{
        'blue': card.join === FilterJoin.OR,
        'first': isFirstFilter(card),
        'middle': isMiddleFilter(card),
        'last': isLastFilter(card)
      }">
      <div class="detail">
        <span *ngIf="i > 0">{{ card.join.toLowerCase() }} </span>
        {{ card?.displayName }} <b>{{ card.match.displayName | lowercase }}</b>
        <ng-container [ngSwitch]="card.type" *ngIf="card.value !== 'null'">
          <ng-container *ngSwitchCase="QueryFilterType.Date">
            "{{ card.value | date:'shortDate' }}"
          </ng-container>
          <ng-container *ngSwitchCase="QueryFilterType.Datetime">
            "{{ card.value | date:'short' }}"
          </ng-container>
          <ng-container *ngSwitchCase="QueryFilterType.Time">
            "{{ card.value | date:'shortTime' }}"
          </ng-container>
          <ng-container *ngSwitchCase="QueryFilterType.Boolean">
            <ng-container *ngIf="isBooleanTruthy(card.value); else no"> "Yes"</ng-container>
            <ng-template #no> "No"</ng-template>
          </ng-container>
          <ng-container *ngSwitchDefault>
            "{{ getValue(card) }}"
          </ng-container>
        </ng-container>
      </div>
      <div class="remove" *ngIf="removeFilter.observers.length > 0" (click)="removeFilter.emit({ queryFilter: getQueryFilter(card), index: i })">
        <i class="fas fa-times"></i>
      </div>
    </div>
  </ng-container>
</ng-container>
<div class="no-filters" *ngIf="cards.length === 0 && noFiltersMessage?.length > 0">{{ noFiltersMessage }}</div>
