/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DataProcessField { 
    aiGenerated?: boolean;
    aiPercent?: number;
    aliasName?: Array<string>;
    columnOutput?: number;
    columnWidth?: number;
    comments?: string;
    convert?: Array<string>;
    dataType?: string;
    defaultValue?: string;
    definition?: string;
    example?: string;
    fieldEnd?: number;
    fieldEndMatch?: string;
    fieldEndOperator?: string;
    fieldStart?: number;
    fieldStartMatch?: string;
    fieldStartOperator?: string;
    format?: string;
    inactive?: boolean;
    lineNumber?: number;
    lineOffset?: string;
    mappingRowNumber?: number;
    matchLine?: string;
    matchOperator?: string;
    maxCharacterLength?: number;
    minCharacterLength?: number;
    name?: string;
    newField?: boolean;
    outputName?: string;
    pageNumber?: number;
    parentName?: string;
    parentOverrideField?: boolean;
    recommendedDisplay?: boolean;
    required?: boolean;
    requiredBrandDisplay?: boolean;
    requiredConditionalDisplay?: boolean;
    requiredDisplay?: boolean;
    reqularExpression?: string;
    rowHeight?: number;
    rowOutput?: number;
    saveEmpty?: boolean;
    skipTrim?: boolean;
    skipXmlConversion?: boolean;
    stackSize?: number;
    suggestedMatch?: boolean;
    validate?: Array<string>;
    xmlAttribute?: boolean;
}

