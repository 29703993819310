/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductSeo } from './productSeo';
import { ProductSku } from './productSku';
import { Category } from './category';
import { ProductProductType } from './productProductType';
import { WorkflowError } from './workflowError';
import { Image } from './image';
import { ModifiedDate } from './modifiedDate';
import { WorkflowStatus } from './workflowStatus';
import { APlusTemplate } from './aPlusTemplate';
import { Pricing } from './pricing';
import { CatalogReference } from './catalogReference';
import { Shipping } from './shipping';
import { OrganizationReference } from './organizationReference';
import { VerifyLive } from './verifyLive';


export interface Product { 
    active?: boolean;
    aplusTemplateList?: Array<APlusTemplate>;
    asin?: string;
    availabilityDate?: string;
    brandRef?: OrganizationReference;
    bullet1?: string;
    bullet10?: string;
    bullet11?: string;
    bullet12?: string;
    bullet2?: string;
    bullet3?: string;
    bullet4?: string;
    bullet5?: string;
    bullet6?: string;
    bullet7?: string;
    bullet8?: string;
    bullet9?: string;
    catalogList?: Array<CatalogReference>;
    categories?: Array<Category>;
    categoryItemType?: string;
    categoryProductType?: string;
    color?: string;
    createdDate?: ModifiedDate;
    customGroup?: string;
    destinationName?: string;
    dropshipProfitAmount?: number;
    dropshipProfitAmountStatus?: string;
    dropshipProfitPercent?: number;
    dropshipProfitPercentStatus?: string;
    errorCount?: number;
    id?: number;
    imageReady?: boolean;
    images?: Array<Image>;
    inventoryList?: Array<{ [key: string]: any; }>;
    lastSyndicatedDate?: string;
    lastSyndicatedStatsId?: number;
    lastUpdated?: ModifiedDate;
    manufacturerRef?: OrganizationReference;
    map?: number;
    msrp?: number;
    orgRef?: OrganizationReference;
    organizationStatus?: string;
    pricing?: Array<Pricing>;
    productDescription?: string;
    productName?: string;
    productReady?: boolean;
    productSeoList?: Array<ProductSeo>;
    productType?: ProductProductType;
    shipping?: Array<Shipping>;
    shortDescription?: string;
    size?: string;
    sku?: string;
    skus?: Array<ProductSku>;
    styleName?: string;
    styleNumber?: string;
    subProductList?: Array<{ [key: string]: any; }>;
    syndic8Id?: string;
    syndicateUpdate?: boolean;
    syndicatedAction?: string;
    syndicatedDate?: string;
    syndicatedStatus?: string;
    syndicatedSuccessDate?: string;
    upc?: string;
    verifyLiveList?: Array<VerifyLive>;
    warehouseProfitAmount?: number;
    warehouseProfitAmountStatus?: string;
    warehouseProfitPercent?: number;
    warehouseProfitPercentStatus?: string;
    warnCount?: number;
    wholesaleCost?: number;
    width?: string;
    worflowStatusList?: Array<WorkflowStatus>;
    workflowErrorList?: Array<WorkflowError>;
    workflowStatus?: WorkflowStatus;
}

