import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { GroupBy } from '@app/core';

export interface CategorySelect {
  displayName?: string;
  groupBy: GroupBy
}

@Component({
  selector: 'app-category-select',
  templateUrl: './category-select.component.html',
  styleUrls: ['./category-select.component.scss']
})
export class CategorySelectComponent implements OnInit {
  @Input() categories: Array<CategorySelect> = [];
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();
  @Input() disabled = false;

  openClick = false;
  navigateClick = false;

  @HostBinding('class.open') @Input() open = false;
  @HostListener('document:click')
  clickOutside() {
    if (!this.openClick) {
      this.open = false;
      this.openChange.emit(false);
    }
    this.openClick = false;
  }
  @HostListener('click')
  click() {
    if (this.navigateClick || this.categories.length === 0 || this.disabled) {
      this.navigateClick = false;
      return;
    }
    this.openClick = true;
    this.open = !this.open;
    this.openChange.emit(!this.open);
  }

  constructor() { }

  ngOnInit(): void {
  }

  navigate(): void {
    this.openClick = false;
    this.navigateClick = true;
  }
}
