import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCopyAsTable]'
})
export class CopyAsTableDirective {
  @Input() appCopyAsTable = 1;
  @Input() fromHeader = false

  constructor() { }

  @HostListener('copy', ['$event'])
  copy(event: ClipboardEvent) {
    const selection = document.getSelection();
    const split = selection.toString().split('\n');
    if (this.fromHeader) {
      split.shift();
    }
    if (split.length < this.appCopyAsTable || split.length % this.appCopyAsTable > 0) {
      event.clipboardData.setData('text/plain', selection.toString());
    } else {
      let string = '';
      split.forEach(
        (seg, i) => {
          if ((i + 1) % this.appCopyAsTable === 0) {
            string += seg + '\n';
          } else {
            string += seg + '\t';
          }
        }
      );
      event.clipboardData.setData('text/plain', string);
    }
    event.preventDefault();
  }
}
