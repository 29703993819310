<ng-container *ngFor="let toast of toasts$ | async">
  <ng-container *ngIf="toast.type === 'INFO' || toast.type === 'WARN' || toast.type === 'ERROR' || toast.type === 'SUCCESS'">
    <app-toast-message [notification]="toast"></app-toast-message>
  </ng-container>
  <ng-container *ngIf="toast.type === 'ACTION'">
    <app-toast-action [notification]="toast"></app-toast-action>
  </ng-container>
  <ng-container *ngIf="toast.type === 'PROGRESS'">
    <app-toast-progress [notification]="toast"></app-toast-progress>
  </ng-container>
</ng-container>