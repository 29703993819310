<div class="header">
  <div class="button" (click)="openChange.emit(!open)">
    <i class="fas fa-arrow-alt-right"></i>
  </div>
  <div class="title-wrapper">
    <div class="title">Fields</div>
  </div>
</div>
<div class="columns">
  <div class="column" *ngFor="let column of allColumns" (click)="toggleColumn(column)"
    [ngClass]="{'active': isColumnActive(column)}" [title]="column.friendlyName ? column.friendlyName : column.displayName">
    {{ column.friendlyName ? column.friendlyName : column.displayName }}
  </div>
</div>
