import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Suggestion } from '@app/core';
import { FunctionType } from '@app/shared/services/template.service';

interface IfThenElse {
  condition: FormControl<string>;
  value: FormControl<string>;
  ifElse: FormControl<string>;
  ifThen: FormControl<string>;
}

@Component({
  selector: 'app-if-then-else-form',
  templateUrl: './if-then-else-form.component.html',
  styleUrls: ['./if-then-else-form.component.scss']
})
export class IfThenElseFormComponent implements OnInit {
  @Input() convert = '';
  @Output() convertChange: EventEmitter<string> = new EventEmitter();
  @Input() valid = false;
  @Output() validChange: EventEmitter<boolean> = new EventEmitter();
  @Input() provided: Array<Suggestion> = [];

  get con(): string {
    return this.convert ? this.convert.split(FunctionType.IfThenElse)[1] : '';
  }

  get condition(): string {
    const vert = this.con.split('::')[0].split('==');
    return this.con ? vert[0] ? vert[0].trim() : '' : ''
  }

  get value(): string {
    const vert = this.con.split('::')[0].split('==');
    return this.con ? vert[1] ? vert[1].trim().slice(1, -1) : '' : '';
  }

  get ifThen(): string {
    return this.con ? this.con.split('::')[1] ? this.con.split('::')[1] : '' : '';
  }

  get ifElse(): string {
    return this.con ? this.con.split('::')[2] ? this.con.split('::')[2] : '' : '';
  }

  isConditionXPath = false;
  isValueXPath = false;
  isIfThenXPath = false;
  isIfElseXPath = false;

  form: FormGroup = new FormGroup<IfThenElse>({
    condition: new FormControl(''),
    value: new FormControl(''),
    ifThen: new FormControl(''),
    ifElse: new FormControl('')
  });

  constructor() { }

  ngOnInit(): void {
    this.updateForm();
  }

  private updateForm(): void {
    this.isConditionXPath = this.condition.startsWith('$X{');
    this.isValueXPath = this.value.startsWith('$X{');
    this.isIfThenXPath = this.ifThen.startsWith('$X{');
    this.isIfElseXPath = this.ifElse.startsWith('$X{');
    const condition = this.transformValue(this.condition);
    const value = this.transformValue(this.value);
    const ifThen = this.transformValue(this.ifThen);
    const ifElse = this.transformValue(this.ifElse);
    this.form.setValue({ condition, value, ifThen, ifElse });
  }

  private checkValidity(condition: string, value: string, ifThen: string): void {
    this.valid = !condition || !value || !ifThen;
    this.validChange.emit(this.valid);
  }

  private buildConvert(condition: string, value: string, ifThen: string, ifElse: string): string {
    return `${condition} == '${value}'::${ifThen}${ifElse ? `::${ifElse}` : ''}`;
  }

  private convertValue(isXPath: boolean, value: string): string {
    if (!value) {
      return '';
    }
    if (isXPath) {
      return `$X{${value}}`;
    } else if (value.startsWith('COLUMNNAME:')) {
      return `$D{${value.split(':')[1]}}`;
    } else {
      return value;
    }
  }

  private transformValue(value: string): string {
    if (!value) {
      return '';
    }
    if (value.startsWith('$X{') || value.startsWith('$D{')) {
      return value.substring(3, value.length - 1);
    }
    return value;
  }

  selectCondition(str?: string): void {
    const value = this.convertValue(this.isConditionXPath, str ? str : this.form.value.condition);
    const convert = FunctionType.IfThenElse + this.buildConvert(value, this.value, this.ifThen, this.ifElse);
    this.convertChange.emit(convert);
    this.convert = convert;
    this.checkValidity(value, this.value, this.ifThen);
    this.updateForm();
  }

  closedCondition(): void {
    const convert = FunctionType.IfThenElse + this.buildConvert('', this.value, this.ifThen, this.ifElse);
    this.convertChange.emit(convert);
    this.convert = convert;
    this.checkValidity('', this.value, this.ifThen);
    this.updateForm();
  }

  selectValue(str?: string): void {
    const value = this.convertValue(this.isValueXPath, str ? str : this.form.value.value);
    const convert = FunctionType.IfThenElse + this.buildConvert(this.condition, value, this.ifThen, this.ifElse);
    this.convertChange.emit(convert);
    this.convert = convert;
    this.checkValidity(this.condition, value, this.ifThen);
    this.updateForm();
  }

  closedValue(): void {
    const convert = FunctionType.IfThenElse + this.buildConvert(this.condition, '', this.ifThen, this.ifElse);
    this.convertChange.emit(convert);
    this.convert = convert;
    this.checkValidity(this.condition, '', this.ifThen);
    this.updateForm();
  }

  selectIfThen(str?: string): void {
    const value = this.convertValue(this.isIfThenXPath, str ? str : this.form.value.ifThen);
    const convert = FunctionType.IfThenElse + this.buildConvert(this.condition, this.value, value, this.ifElse);
    this.convertChange.emit(convert);
    this.convert = convert;
    this.checkValidity(this.condition, this.value, value);
    this.updateForm();
  }

  closedIfThen(): void {
    const convert = FunctionType.IfThenElse + this.buildConvert(this.condition, this.value, '', this.ifElse);
    this.convertChange.emit(convert);
    this.convert = convert;
    this.checkValidity(this.condition, this.value, '');
    this.updateForm();
  }

  selectIfElse(str?: string): void {
    const value = this.convertValue(this.isIfElseXPath, str ? str : this.form.value.ifElse);
    const convert = FunctionType.IfThenElse + this.buildConvert(this.condition, this.value, this.ifThen, value);
    this.convertChange.emit(convert);
    this.convert = convert;
    this.updateForm();
  }

  closedIfElse(): void {
    const convert = FunctionType.IfThenElse + this.buildConvert(this.condition, this.value, this.ifThen, '')
    this.convertChange.emit(convert);
    this.convert = convert;
    this.updateForm();
  }

}
