import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Permission } from '@app/core';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

export type EditState = 'NotEdit' | 'Edit' | 'Save' | 'Cancel';

export const EditState = {
  NotEdit: 'NotEdit' as EditState,
  Edit: 'Edit' as EditState,
  Save: 'Save' as EditState,
  Cancel: 'Cancel' as EditState
};

@Component({
  selector: 'app-edit-lock',
  templateUrl: './edit-lock.component.html',
  styleUrls: ['./edit-lock.component.scss']
})
export class EditLockComponent implements OnInit {
  @Select(OrganizationState.getPagePermission()) permission$: Observable<Permission>;
  @Input() isEditing = true;
  @Input() permission: Permission;
  @Output() setEdit: EventEmitter<EditState> = new EventEmitter();
  EditState = EditState;

  constructor() { }

  ngOnInit(): void {
    this.permission$.subscribe((x) => {
      this.permission = x;
      console.log('hello from edit lock component=>', this.permission)
    });
  }
}
