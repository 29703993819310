<app-page-header>
  <app-page-title title="Page Title"></app-page-title>
  <app-page-subtitle subtitle="Subtitle or description goes here"></app-page-subtitle>
</app-page-header>

<app-page-tabs>
  <app-page-tab class="active">First Tab</app-page-tab>
  <app-page-tab>Second Tab</app-page-tab>
  <app-page-tab>Third Tab</app-page-tab>
</app-page-tabs>

<table>
  <thead>
    <tr>
      <th>Column 1</th>
      <th>Column 2</th>
      <th>Column 3</th>
      <th>Column 4</th>
      <th>Column 5</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Cell 1, 1</td>
      <td>Cell 1, 2</td>
      <td>Cell 1, 3</td>
      <td>Cell 1, 4</td>
      <td>Cell 1, 5</td>
    </tr>
    <tr>
      <td>Cell 2, 1</td>
      <td>Cell 2, 2</td>
      <td>Cell 2, 3</td>
      <td>Cell 2, 4</td>
      <td>Cell 2, 5</td>
    </tr>
    <tr>
      <td>Cell 3, 1</td>
      <td>Cell 3, 2</td>
      <td>Cell 3, 3</td>
      <td>Cell 3, 4</td>
      <td>Cell 3, 5</td>
    </tr>
  </tbody>
</table>
