/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ImageImportStatus { 
    backgroundColor?: string;
    endDate?: string;
    height?: number;
    imageColors?: string;
    imageType?: string;
    loadedAs?: string;
    modeOpenCV?: string;
    numberOfColors?: string;
    organizationId?: number;
    productName?: string;
    propertyNames?: string;
    runStatus?: string;
    sku?: string;
    startDate?: string;
    transparency?: string;
    uriId?: number;
    uriName?: string;
    uriParentId?: number;
    uriPath?: string;
    uriType?: string;
    width?: number;
}

