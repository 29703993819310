<form class="fuzzy-search" *ngIf="!hideFuzzySearch" [formGroup]="fuzzySearchForm" (ngSubmit)="fuzzySearch()">
  <i class="fal fa-search"></i>
  <input formControlName="input" type="text" placeholder="Search Products"
    [ngClass]="{'show-clear': fuzzySearchForm.value.input?.length > 0 }" data-lpignore="true" autocomplete="off">
  <div class="clear" *ngIf="fuzzySearchForm.value.input?.length > 0" (click)="resetFilters()"><i
      class="fal fa-times"></i></div>
</form>

<div class="collapse-all">
  <div class="flex-fill"></div>
  <i title="Collapse all" *ngIf="oneOrMoreExpanded$ | async" (click)="collapseAllQuickFilters()" class="far fa-chevron-double-up"></i>
</div>

<!-- Saved Views -->
<div class="accordion" *ngIf="!hideSavedViews" [ngClass]="{'expanded': savedViewsExpanded}">
  <div class="title" (click)="savedViewsExpanded = !savedViewsExpanded">Saved Views <i class="fad fa-star"></i></div>
  <div class="collapsible">
    <app-query-filter-views></app-query-filter-views>
  </div>
</div>

<!-- Quick filters -->
<app-query-filter-quick *ngFor="let queryFilter of quickFilters" [queryFilter]="queryFilter" [suggestions]="suggestions"></app-query-filter-quick>

<!-- Advanced Filters -->
<div class="accordion" [ngClass]="{'expanded': advancedFiltersExpanded, 'no-border': moreFilters.length === 0}">
  <div class="title" (click)="advancedFiltersExpanded = !advancedFiltersExpanded">Advanced Filters</div>
  <div class="collapsible" *ngIf="filters$ | async as filters">
    <app-query-filter-list [suggestions]="suggestions" [queryFilters]="queryFilters" [filters]="filters" (removeFilter)="removeFilter($event)"></app-query-filter-list>
    <!-- buttons -->
    <app-button class="add-filters-button" (click)="resetTempFilters(filters); showAdvancedFiltersModal = true" [grow]="true">Add filter</app-button>
    <div class="flex-container" *ngIf="!hideSavedViews">
      <div class="flex-fill"></div>
      <app-button class="save-view-button" [disabled]="filters?.length === 0" (click)="showSaveViewModal = true" color="green">Save view</app-button>
    </div>

    <!-- Sort -->
    <app-query-filter-sort [queryFilters]="queryFilters" [(sortAscending)]="sortAscending"></app-query-filter-sort>
  </div>
</div>

<!-- More Filters -->
<div class="accordion no-border" *ngIf="moreFilters.length > 0" [ngClass]="{'expanded': moreFiltersExpanded}">
  <div class="title" (click)="moreFiltersExpanded = !moreFiltersExpanded">More Filters</div>
  <div class="collapsible">
    <app-query-filter-more [moreFilters]="moreFilters"></app-query-filter-more>
  </div>
</div>

<!-- Clear filters -->
<app-button class="cancel-filters" [disabled]="(filters$ | async)?.length === 0" (click)="resetFilters()" color="red">Clear filters</app-button>

<app-modal [(open)]="showAdvancedFiltersModal" (close)="queryFilterModalClosed(queryFilterPicker)">
  <app-modal-head>
    <app-modal-title>Add Filter</app-modal-title>
  </app-modal-head>
  <app-modal-body>
    <app-query-filter-picker #queryFilterPicker [suggestions]="suggestions" [(queryFilters)]="queryFilters" [filters]="tempFilters" [loadProductTypeQueryFiltersAction]="loadProductTypeQueryFiltersAction"></app-query-filter-picker>
  </app-modal-body>
  <app-modal-foot [center]="true">
    <app-button [disabled]="queryFilterPicker.formInvalid" (click)="queryFilterPicker.addFilter()" color="navy">Add Filter</app-button>
    <div class="flex-fill"></div>
    <app-button [disabled]="queryFilterPicker.filters.length === 0" (click)="applyAdvancedFilters(queryFilterPicker)" color="green">Apply</app-button>
  </app-modal-foot>
</app-modal>

<app-modal class="save-view" [(open)]="showSaveViewModal" (close)="filterViewNameControl.reset()">
  <app-modal-head>
    <app-modal-title>Save View</app-modal-title>
  </app-modal-head>
  <app-modal-body>
    <div class="body">
      <app-query-filter-list [suggestions]="suggestions" [queryFilters]="queryFilters" [filters]="filters$ | async"></app-query-filter-list>
      <div class="flex-fill"></div>
      <input type="text" [formControl]="filterViewNameControl" placeholder="View name">
    </div>
  </app-modal-body>
  <app-modal-foot [center]="true">
    <app-button (click)="filterViewNameControl.reset(); showSaveViewModal = false" color="red">Cancel</app-button>
    <div class="flex-fill"></div>
    <app-button (click)="saveView()" [disabled]="filterViewNameControl.invalid" color="green">Save view</app-button>
  </app-modal-foot>
</app-modal>
