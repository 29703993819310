/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Audit } from './audit';
import { POContainer } from './pOContainer';
import { PurchaseOrderDetail } from './purchaseOrderDetail';
import { EdiLog } from './ediLog';
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';
import { Location } from './location';


export interface PurchaseOrder { 
    accountNumber?: string;
    auditList?: Array<Audit>;
    cartonQuantity?: number;
    createdDate?: ModifiedDate;
    currentStatus?: string;
    customerCode?: string;
    department?: string;
    departmentName?: string;
    endDate?: string;
    endDateDelivery?: string;
    inDCDate?: string;
    inStoreDate?: string;
    isaTrackingNumber?: string;
    jsonData?: { [key: string]: any; };
    lastUpdated?: ModifiedDate;
    loaderId?: number;
    locationAlsoNotify?: Location;
    locationBillTo?: Location;
    locationConsignee?: Location;
    locationCustomer?: Location;
    locationDestination?: Location;
    locationFactory?: Location;
    locationNotify?: Location;
    locationShipTo?: Location;
    locationSupplier?: Location;
    messageId?: string;
    messageOriginator?: string;
    mode?: string;
    msgList?: Array<EdiLog>;
    orderDate?: string;
    orderType?: string;
    orgRefFrom?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    orgRefTo?: OrganizationReference;
    originType?: string;
    poContact?: string;
    poContainerList?: Array<POContainer>;
    poNumber?: string;
    poPurpose?: string;
    poReferenceNumber1?: string;
    poReferenceNumber2?: string;
    poReferenceNumber3?: string;
    poReferenceNumber4?: string;
    poReferenceNumber5?: string;
    poType?: string;
    purchaseOrderDetails?: Array<PurchaseOrderDetail>;
    purchaseOrderId?: number;
    purchaseType?: string;
    shipmentTerms?: string;
    startDate?: string;
    startDateDelivery?: string;
    status?: string;
    versionNumber?: number;
}

