import { NgModule, SkipSelf, Optional, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy, UrlSerializer } from '@angular/router';
import { RouterStateSerializer } from '@ngxs/router-plugin';

// Core dependencies
import { ApiModule, Configuration, ConfigurationParameters } from './api';
import { environment } from '@env/environment';

// Providers
import { CustomRouteReuseStrategy } from './providers/custom-route-reuse-strategy';
import { CustomUrlSerializer } from './providers/custom-url-serializer';
import { CustomRouterStateSerializer } from './providers/custom-router-state-serializer';
import { GlobalErrorHandler } from './providers/global-error-handler';

// Interceptor
import { TokenInterceptor } from './providers/token.interceptor';
import { ExceptionInterceptor } from './providers/exception.interceptor';
import { EmptyResponseInterceptor } from './providers/empty-response.interceptor';
import { TimeoutInterceptor } from './providers/timeout.interceptor';
import { PrometheusInterceptor } from './providers/prometheus.interceptor';

// Shared module
import { SharedModule } from '@app/shared/shared.module';

// Pages
import { LogoutComponent } from './pages/logout/logout.component';
import { ErrorComponent } from './pages/error/error.component';
import { MissingComponent } from './pages/missing/missing.component';
import { BrowserComponent } from './pages/browser/browser.component';
import { DeviceComponent } from './pages/device/device.component';
import { CallbackComponent } from './pages/callback/callback.component';
import { AmazonCallbackComponent } from './pages/amazon-callback/amazon-callback.component';
import { TermsAndPrivacyComponent } from './pages/terms-and-privacy/terms-and-privacy.component';
import { UiShowcaseComponent } from './pages/ui-showcase/ui-showcase.component';
import { MagentoCallbackComponent } from "@app/core/pages/magento-callback/magento-callback.component";


// NGXS modules
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

// Formly
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';

// Auth0
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

// States
import { AuthState } from './stores/auth.state';
import { ToastState } from './stores/toast.state';
import { PartnerCallbackComponent } from './pages/partner-callback/partner-callback/partner-callback.component';
import { DynamicStyleLoaderService } from './pages/callback/dynamic-sytle-loader.service';

// API configuration factory
export function apiConfigFactory(): Configuration {
  const apiVersion = environment.apiVersion ? '/' + environment.apiVersion : '';
  const params: ConfigurationParameters = { basePath: environment.apiUrl + apiVersion, withCredentials: true };
  return new Configuration(params);
}

@NgModule({
  declarations: [
    ErrorComponent,
    LogoutComponent,
    MissingComponent,
    DeviceComponent,
    BrowserComponent,
    CallbackComponent,
    TermsAndPrivacyComponent,
    UiShowcaseComponent,
    AmazonCallbackComponent,
    MagentoCallbackComponent,
    PartnerCallbackComponent
  ],
  providers: [
    [DynamicStyleLoaderService],
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ExceptionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EmptyResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PrometheusInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: UrlSerializer, useClass: CustomUrlSerializer }
  ],
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    ApiModule.forRoot(apiConfigFactory),
    NgxsModule.forRoot([AuthState, ToastState], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: !environment.logging }),
    NgxsLoggerPluginModule.forRoot({ disabled: !environment.logging }),
    NgxsFormPluginModule.forRoot(),
    FormlyModule.forRoot({ extras: { lazyRender: true } }),
    FormlyBootstrapModule,
    AuthModule.forRoot({
      domain: environment.domain,
      clientId: environment.clientId,
      httpInterceptor: { allowedList: [`${environment.apiUrl}/api/*`] },
      authorizationParams: {
        redirect_uri: `${window.location.origin}/callback`,
        audience: environment.audience,
        useRefreshTokens: true,
        httpInterceptor: { allowedList: [`${environment.apiUrl}/api/*`] },
        skipRedirectCallback: window.location.pathname !== '/callback',
      }
    })
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('CoreModule can only be injected once');
    }
  }
}
