import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormlyModule } from '@ngx-formly/core';
import { DragDropModule } from '@angular/cdk/drag-drop';

// States
import { NotificationState } from './stores/notification.state';
import { QueryCacheState } from './stores/query-cache.state';

// Components
import { ImageDetailModalComponent } from './components/image-detail-modal/image-detail-modal.component';
import { BulkUpdateComponent } from './components/bulk-update/bulk-update/bulk-update.component';
import { BulkUpdateModalComponent } from './components/bulk-update/bulk-update-modal/bulk-update-modal.component';
import { PageLoadingComponent } from './components/page-loading/page-loading.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { ImageComponent } from './components/image/image.component';
import { ChipComponent } from './components/chip/chip.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { EditLockComponent } from './components/edit-lock/edit-lock.component';
import { ActiveColumnsPanelComponent } from './components/active-columns-panel/active-columns-panel.component';
import { TemplateFieldEditComponent } from './components/template/template-field-edit/template-field-edit.component';
import { NgCircleProgressModule } from 'ng-circle-progress';

// Template components
import { ValidValueComponent } from './components/template/valid-value/valid-value.component';
import { DestinationChipComponent } from './components/template/destination-chip/destination-chip.component';
import { DestinationChipListComponent } from './components/template/destination-chip-list/destination-chip-list.component';
import { ConcatenationComponent } from './components/template/concatenation/concatenation.component';
import { TemplateRowComponent } from './components/template/template-row/template-row.component';
import { ReferenceTableComponent } from './components/template/reference-table/reference-table.component';
import { ReferenceTableFormComponent } from './components/template/reference-table-form/reference-table-form.component';
import { IfThenElseComponent } from './components/template/if-then-else/if-then-else.component';
import { IfThenElseFormComponent } from './components/template/if-then-else-form/if-then-else-form.component';
import { TemplateEditComponent } from './components/template/template-edit/template-edit.component'
import { TemplateComponent } from './components/template/template/template.component';
import { TemplateSheetEditComponent } from './components/template/template-sheet-edit/template-sheet-edit.component';
import { TemplateMessagesComponent } from './components/template/template-messages/template-messages.component';
import { TemplateSheetOrderComponent } from './components/template/template-sheet-order/template-sheet-order.component';
import { JsonPathModalComponent } from "@app/shared/components/template/json-path-modal/json-path-modal.component";

// Calendar components
import { ReleaseDateComponent } from './components/calendar/release-date/release-date.component';

// Chart components
import { ProductReadinessChartComponent } from './components/charts/product-readiness-chart/product-readiness-chart.component';
// tslint:disable-next-line:max-line-length
import { ProductReadinessBacklogChartComponent } from './components/charts/product-readiness-backlog-chart/product-readiness-backlog-chart.component';
// tslint:disable-next-line:max-line-length
import { ProductLifecycleStatusChartComponent } from './components/charts/product-lifecycle-status-chart/product-lifecycle-status-chart.component';
// tslint:disable-next-line:max-line-length
import { ProductLifecycleStatusDivergentChartComponent } from './components/charts/product-lifecycle-status-divergent-chart/product-lifecycle-status-divergent-chart.component';
import { SplitBarChartComponent } from './components/charts/split-bar-chart/split-bar-chart.component';

// Input components
import { ButtonComponent } from './components/input/button/button.component';
import { CircleButtonComponent } from './components/input/circle-button/circle-button.component';
import { SimpleButtonComponent } from './components/input/simple-button/simple-button.component';
import { ShadowButtonComponent } from './components/input/shadow-button/shadow-button.component';
import { SuggestionComponent } from './components/input/suggestion/suggestion.component';
import { DateComponent } from './components/input/date/date.component';
import { DateTimeComponent } from './components/input/date-time/date-time.component';
import { CheckboxComponent } from './components/checkbox/checkbox/checkbox.component';
import { CheckboxWrapperComponent } from './components/checkbox/checkbox-wrapper/checkbox-wrapper.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { FilterCheckboxComponent } from './components/input/filter-checkbox/filter-checkbox.component';
import { RadioParentComponent } from './components/input/radio-parent/radio-parent.component';
import { RadioComponent } from './components/input/radio/radio.component';
import { MultiSelectComponent } from './components/input/multi-select/multi-select.component';
import { ToggleComponent } from './components/input/toggle/toggle.component';

// Panel
import { PanelComponent } from './components/panel/panel/panel.component';
import { PanelFormComponent } from './components/panel/panel-form/panel-form.component';
import { PanelButtonComponent } from './components/panel/panel-button/panel-button.component';
import { PanelEditLockComponent } from './components/panel/panel-edit-lock/panel-edit-lock.component';

import { PluginComponent } from './components/plugin/plugin.component';

import { UploadOverlayComponent } from './components/upload-overlay/upload-overlay.component';

// Toast components
import { ToastContainerComponent } from './components/toast/toast-container/toast-container.component';
import { ToastMessageComponent } from './components/toast/toast-message/toast-message.component';
import { ToastProgressComponent } from './components/toast/toast-progress/toast-progress.component';
import { ToastActionComponent } from './components/toast/toast-action/toast-action.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';

// Navigation components
import { NavigationListComponent } from './components/navigation/navigation-list/navigation-list.component';
import { NavigationLogisticsListComponent } from './components/navigation/navigation-logistics-list/navigation-logistics-list.component';
import { NavigationActionComponent } from './components/navigation/navigation-action/navigation-action.component';
import { NavigationLogisticsActionComponent } from './components/navigation/navigation-logistics-action/navigation-logistics-action.component';
import { SelectionListComponent } from './components/navigation/selection-list/selection-list.component';
import { SelectionActionComponent } from './components/navigation/selection-action/selection-action.component';
import { SelectionSearchComponent } from './components/navigation/selection-search/selection-search.component';
import { NotificationListComponent } from './components/navigation/notification-list/notification-list.component';
import { NotificationActionComponent } from './components/navigation/notification-action/notification-action.component';
import { NavigationFreightForwardListComponent } from './components/navigation/navigation-freight-forward-list/navigation-freight-forward-list.component';

// Infinite Scroll components
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll/infinite-scroll.component';
import { InfiniteScrollHeaderComponent } from './components/infinite-scroll/infinite-scroll-header/infinite-scroll-header.component';
import { InfiniteScrollRowComponent } from './components/infinite-scroll/infinite-scroll-row/infinite-scroll-row.component';
import { InfiniteScrollSelectComponent } from './components/infinite-scroll/infinite-scroll-select/infinite-scroll-select.component';
import { InfiniteScrollCellComponent } from './components/infinite-scroll/infinite-scroll-cell/infinite-scroll-cell.component';
import { InfiniteScrollCustomComponent } from './components/infinite-scroll/infinite-scroll-custom/infinite-scroll-custom.component';
import { InfiniteScrollNewRowComponent } from './components/infinite-scroll/infinite-scroll-new-row/infinite-scroll-new-row.component';
import { SelectedActionsComponent } from './components/selected-actions/selected-actions.component';
import { CategorySelectComponent } from './components/category-select/category-select.component';
import { LayoutSelectComponent } from './components/layout-select/layout-select.component';
import { ActiveColumnsComponent } from './components/active-columns/active-columns.component';
import { GalleryCardComponent } from './components/gallery-card/gallery-card.component';

// Content Editable
import { NgsContenteditableModule } from '@ng-stack/contenteditable';

// Page components
import { PagesComponent } from './components/pages/pages.component';
import { PageHeaderComponent } from './components/page/page-header/page-header.component';
import { PageTitleComponent } from './components/page/page-title/page-title.component';
import { PageSubtitleComponent } from './components/page/page-subtitle/page-subtitle.component';
import { DrawerHeaderComponent } from './components/page/drawer-header/drawer-header.component';
import { DrawerComponent } from './components/page/drawer/drawer.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PageTabsComponent } from './components/page/page-tabs/page-tabs.component';
import { PageTabComponent } from './components/page/page-tab/page-tab.component';

// Wizard components
import { WizardComponent } from './components/wizard/wizard/wizard.component';
import { WizardPageComponent } from './components/wizard/wizard-page/wizard-page.component';

// Query Filter components
import { QueryFiltersComponent } from './components/query-filter/query-filters/query-filters.component';
import { QueryFilterFormComponent } from './components/query-filter/query-filter-form/query-filter-form.component';
import { QueryFilterListComponent } from './components/query-filter/query-filter-list/query-filter-list.component';
import { QueryFilterPickerComponent } from './components/query-filter/query-filter-picker/query-filter-picker.component';
import { QueryFilterViewsComponent } from './components/query-filter/query-filter-views/query-filter-views.component';
import { QueryFilterSortComponent } from './components/query-filter/query-filter-sort/query-filter-sort.component';
import { QueryFilterQuickComponent } from './components/query-filter/query-filter-quick/query-filter-quick.component';
import { QueryFilterMoreComponent } from './components/query-filter/query-filter-more/query-filter-more.component';

// Help components
import { HelpButtonComponent } from './components/help-button/help-button.component';
import { HelpPanelComponent } from './components/help-panel/help-panel.component';
import { HelpPanelHeaderComponent } from './components/help-panel-header/help-panel-header.component';

// Modals
import { ModalComponent } from './components/modal/modal/modal.component';
import { ModalSelectionComponent } from './components/modal/modal-selection/modal-selection.component';
import { ModalTitleComponent } from './components/modal/modal-title/modal-title.component';
import { ModalHeadComponent } from './components/modal/modal-head/modal-head.component';
import { ModalBodyComponent } from './components/modal/modal-body/modal-body.component';
import { ModalFootComponent } from './components/modal/modal-foot/modal-foot.component';

// Directives
import { VirtualScrollViewportResizeDirective } from './directives/virtual-scroll-viewport-resize.directive';
import { CopyAsTableDirective } from './directives/copy-as-table.directive';

// Pipes
import { ChainedQueryFilterPipe } from './pipes/chained-query-filter.pipe';
import { BeautifyCamelCasePipe } from './pipes/beautify-camel-case.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { BeautifyCommaPipe } from './pipes/beautify-comma.pipe';
import { StringToCamelCasePipe } from './pipes/string-to-camel-case.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FieldsFilterPipe } from './pipes/fields-filter.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { PositiveNumberPipe } from './pipes/positive-number.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { BeautifyUpperCasePipe } from './pipes/beautify-upper-case.pipe';
import { MinuteHoursPipe } from './pipes/minutes-to-hours.pipe';
import { AOrAnPipe } from './pipes/aOrAn.pipe';
import { PrettyXmlPipe } from './pipes/pretty-xml.pipe';

// AG Grid
import { AgGridModule } from '@ag-grid-community/angular';
import { LicenseManager } from "@ag-grid-enterprise/core";
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule, RichSelectModule, SetFilterModule } from "ag-grid-enterprise";

// Media
import { MediaNamingBuilderComponent } from './components/media/media-naming-builder/media-naming-builder.component';
import { MediaChipListComponent } from './components/media/media-chip-list/media-chip-list.component';
import { MentionModule } from 'angular-mentions';
import { SuggestionComponent2 } from './components/input/suggestion/suggestion-2.component';
import { ScrollingModule } from "@angular/cdk/scrolling";
import { SuggestionComponent3 } from './components/input/suggestion/suggestion-3.component';
import { AgScrollTableComponent } from './components/ag-scroll-table/ag-scroll-table.component';
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-056632}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Syndic8_DMS,_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Syndic8}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Syndic8}_need_to_be_licensed___{Syndic8}_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{24_May_2025}____[v3]_[01]_MTc0ODA0MTIwMDAwMA==b8b63a308cb88729fbd21028055162fe\n');
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  RangeSelectionModule,
  RowGroupingModule,
  ServerSideRowModelModule,
  ColumnsToolPanelModule,
  SetFilterModule,
  RichSelectModule,
  MenuModule
]);

@NgModule({
  providers: [
    BeautifyCamelCasePipe,
    BeautifyCommaPipe,
    StringToCamelCasePipe,
    FilterPipe,
    FieldsFilterPipe,
    DatePipe,
    SanitizeHtmlPipe,
    TruncatePipe,
    DecimalPipe,
    ReplacePipe,
    AOrAnPipe
  ],
  declarations: [
    PageTitleComponent,
    PageSubtitleComponent,
    ButtonComponent,
    CircleButtonComponent,
    SimpleButtonComponent,
    ShadowButtonComponent,
    PagesComponent,
    DrawerComponent,
    DrawerHeaderComponent,
    ChainedQueryFilterPipe,
    BeautifyCamelCasePipe,
    StringToCamelCasePipe,
    BeautifyCommaPipe,
    EnumToArrayPipe,
    FilterPipe,
    FieldsFilterPipe,
    SanitizeHtmlPipe,
    TruncatePipe,
    WizardComponent,
    WizardPageComponent,
    SuggestionComponent,
    SuggestionComponent2,
    SuggestionComponent3,
    DateComponent,
    DateTimeComponent,
    ReleaseDateComponent,
    ProductReadinessChartComponent,
    ProductReadinessBacklogChartComponent,
    ProductLifecycleStatusChartComponent,
    ProductLifecycleStatusDivergentChartComponent,
    VirtualScrollViewportResizeDirective,
    CopyAsTableDirective,
    ModalComponent,
    ImageDetailModalComponent,
    ModalSelectionComponent,
    ModalTitleComponent,
    ModalHeadComponent,
    ModalBodyComponent,
    ModalFootComponent,
    SpinnerComponent,
    UploadOverlayComponent,
    NavigationListComponent,
    NavigationLogisticsListComponent,
    NavigationActionComponent,
    NavigationLogisticsActionComponent,
    SelectionListComponent,
    SelectionActionComponent,
    SelectionSearchComponent,
    NotificationListComponent,
    NotificationActionComponent,
    ToastContainerComponent,
    ToastMessageComponent,
    ToastProgressComponent,
    ToastActionComponent,
    SelectedActionsComponent,
    CategorySelectComponent,
    LayoutSelectComponent,
    ActiveColumnsComponent,
    InfiniteScrollComponent,
    InfiniteScrollHeaderComponent,
    InfiniteScrollRowComponent,
    InfiniteScrollSelectComponent,
    InfiniteScrollCellComponent,
    InfiniteScrollCustomComponent,
    InfiniteScrollNewRowComponent,
    CheckboxComponent,
    CheckboxWrapperComponent,
    QueryFiltersComponent,
    QueryFilterFormComponent,
    QueryFilterListComponent,
    QueryFilterPickerComponent,
    QueryFilterViewsComponent,
    QueryFilterSortComponent,
    QueryFilterQuickComponent,
    QueryFilterMoreComponent,
    BulkUpdateComponent,
    ProgressBarComponent,
    PageLoadingComponent,
    PageHeaderComponent,
    PageTabsComponent,
    PageTabComponent,
    FilterCheckboxComponent,
    ToggleButtonComponent,
    GalleryCardComponent,
    PositiveNumberPipe,
    ImageComponent,
    SimpleButtonComponent,
    RadioParentComponent,
    RadioComponent,
    ReplacePipe,
    TooltipComponent,
    BeautifyUpperCasePipe,
    SplitBarChartComponent,
    TableHeaderComponent,
    EditLockComponent,
    ActiveColumnsPanelComponent,
    ChipComponent,
    MultiSelectComponent,
    PluginComponent,
    PanelComponent,
    PanelFormComponent,
    PanelButtonComponent,
    PanelEditLockComponent,
    BulkUpdateModalComponent,
    MinuteHoursPipe,
    AOrAnPipe,
    NavigationFreightForwardListComponent,
    PrettyXmlPipe,
    ValidValueComponent,
    DestinationChipComponent,
    ConcatenationComponent,
    DestinationChipListComponent,
    TemplateRowComponent,
    TemplateComponent,
    ReferenceTableComponent,
    ReferenceTableFormComponent,
    IfThenElseComponent,
    IfThenElseFormComponent,
    TemplateEditComponent,
    TemplateSheetEditComponent,
    ToggleComponent,
    TemplateMessagesComponent,
    HelpButtonComponent,
    HelpPanelComponent,
    HelpPanelHeaderComponent,
    MediaNamingBuilderComponent,
    MediaChipListComponent,
    TemplateSheetOrderComponent,
    JsonPathModalComponent,
    TemplateFieldEditComponent,
    AgScrollTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxsModule.forFeature([NotificationState, QueryCacheState]),
    NgxsFormPluginModule,
    RouterModule,
    InfiniteScrollModule,
    NgsContenteditableModule,
    FormlyModule,
    AgGridModule,
    DragDropModule,
    MentionModule,
    ScrollingModule,
        // Specify ng-circle-progress as an import
        NgCircleProgressModule.forRoot({
          // set defaults here
          radius: 100,
          outerStrokeWidth: 16,
          innerStrokeWidth: 8,
          outerStrokeColor: "#78C000",
          innerStrokeColor: "#C7E596",
          animationDuration: 300,
          lazy: false,
          renderOnClick: false

        })

  ],
    exports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PageTitleComponent,
        PageSubtitleComponent,
        ButtonComponent,
        CircleButtonComponent,
        SimpleButtonComponent,
        ShadowButtonComponent,
        PagesComponent,
        DrawerComponent,
        DrawerHeaderComponent,
        ChainedQueryFilterPipe,
        BeautifyCamelCasePipe,
        BeautifyUpperCasePipe,
        StringToCamelCasePipe,
        BeautifyCommaPipe,
        FilterPipe,
        FieldsFilterPipe,
        EnumToArrayPipe,
        SanitizeHtmlPipe,
        TruncatePipe,
        ReplacePipe,
        WizardComponent,
        WizardPageComponent,
        SuggestionComponent,
        SuggestionComponent2,
        SuggestionComponent3,
        DateComponent,
        DateTimeComponent,
        ReleaseDateComponent,
        ProductReadinessChartComponent,
        ProductReadinessBacklogChartComponent,
        ProductLifecycleStatusChartComponent,
        ProductLifecycleStatusDivergentChartComponent,
        VirtualScrollViewportResizeDirective,
        ModalComponent,
        ImageDetailModalComponent,
        ModalSelectionComponent,
        ModalTitleComponent,
        ModalHeadComponent,
        ModalBodyComponent,
        ModalFootComponent,
        SpinnerComponent,
        UploadOverlayComponent,
        NavigationListComponent,
        NavigationLogisticsListComponent,
        NavigationActionComponent,
        NavigationLogisticsActionComponent,
        SelectionListComponent,
        SelectionActionComponent,
        SelectionSearchComponent,
        NotificationListComponent,
        NotificationActionComponent,
        ToastContainerComponent,
        ToastMessageComponent,
        ToastProgressComponent,
        ToastActionComponent,
        ProgressBarComponent,
        SelectedActionsComponent,
        CategorySelectComponent,
        LayoutSelectComponent,
        ActiveColumnsComponent,
        InfiniteScrollComponent,
        InfiniteScrollCustomComponent,
        CheckboxComponent,
        CheckboxWrapperComponent,
        QueryFiltersComponent,
        QueryFilterListComponent,
        QueryFilterPickerComponent,
        BulkUpdateComponent,
        BulkUpdateModalComponent,
        PageLoadingComponent,
        ImageComponent,
        PageHeaderComponent,
        PageTabsComponent,
        PageTabComponent,
        FilterCheckboxComponent,
        ToggleButtonComponent,
        GalleryCardComponent,
        PositiveNumberPipe,
        RadioParentComponent,
        RadioComponent,
        TooltipComponent,
        SplitBarChartComponent,
        TableHeaderComponent,
        EditLockComponent,
        ActiveColumnsPanelComponent,
        ChipComponent,
        MultiSelectComponent,
        PluginComponent,
        MinuteHoursPipe,
        AOrAnPipe,
        AgGridModule,
        NavigationFreightForwardListComponent,
        PrettyXmlPipe,
        DestinationChipListComponent,
        TemplateComponent,
        TemplateEditComponent,
        TemplateSheetEditComponent,
        ToggleComponent,
        HelpButtonComponent,
        HelpPanelComponent,
        HelpPanelHeaderComponent,
        MediaNamingBuilderComponent,
        MediaChipListComponent,
        MentionModule,
        TemplateSheetOrderComponent,
        JsonPathModalComponent,
        TemplateFieldEditComponent,
        AgScrollTableComponent
    ]
})
export class SharedModule { }
