import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngxs/router-plugin';
import { Injectable } from "@angular/core";

export interface RouterStateParams {
  url: string;
  params: Params;
  queryParams: Params;
  data: any;
}

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateParams> {
  serialize(routerState: RouterStateSnapshot): RouterStateParams {
    const {
      url,
      root: { queryParams }
    } = routerState;

    let params: Params;
    let { root: route, root: { data } } = routerState;
    while (route.firstChild) {
      route = route.firstChild;
      params = { ...params, ...route.params };
      data = { ...data, ...route.data };
    }

    return { url, params, queryParams, data };
  }
}
