import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PanelService {

  private _editableSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  get editable$(): Observable<boolean> {
    return this._editableSubject.asObservable();
  }

  edit(state: boolean): void {
    this._editableSubject.next(state);
  }
}
