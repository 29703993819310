import { State, Action, Selector, StateContext, Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { patch, append, removeItem, updateItem } from '@ngxs/store/operators';

import { Notification } from '../api';
import { LogoutUserAction } from '../stores/auth.actions';

import {
  AddToastAction,
  RemoveToastAction,
  UpdateToastMessageAction,
  UpdateToastProgressAction,
  ResetToastAction
} from './toast.actions';

export interface ToastStateModel {
  toasts: Array<Notification>;
}

const defaults: ToastStateModel = {
  toasts: [
    // {
    //   title: 'Action Notification',
    //   message: 'This is a test message',
    //   icon: 'alarm-clock',
    //   type: NotificationType.ACTION,
    //   actions: [
    //     { title: 'Dashboard', route: '/org/1209/dashboard' },
    //     { title: 'Products', route: '/org/1209/product' }
    //   ]
    // },
    // {
    //   title: 'Message Notification',
    //   message: 'This is a test message',
    //   icon: 'bell',
    //   type: NotificationType.INFO
    // },
    // {
    //   title: 'Uploading...',
    //   message: 'virus.exe',
    //   icon: 'cloud-upload-alt',
    //   progress: {
    //     currentFinished: 8,
    //     currentTotal: 20,
    //     overallFinished: 51,
    //     overallTotal: 100
    //   },
    //   type: NotificationType.PROGRESS
    // }
  ]
};

@State<ToastStateModel>({
  name: 'toast',
  defaults
})
@Injectable()
export class ToastState {

  constructor(private actions: Actions, private store: Store) {
    // Reset Toast state on User Logout
    this.actions.pipe(ofActionDispatched(LogoutUserAction)).subscribe(
      () => {
        this.store.dispatch(new ResetToastAction());
      }
    );
  }

  @Selector()
  static getToasts(state: ToastStateModel): Array<Notification> {
    return state.toasts;
  }

  @Action(AddToastAction)
  addToast(
    { setState }: StateContext<ToastStateModel>,
    { toast }: AddToastAction
  ) {
    setState(
      patch({
        toasts: append<Notification>([toast])
      })
    );
  }

  @Action(UpdateToastMessageAction)
  updateToastMessage(
    { setState }: StateContext<ToastStateModel>,
    { payload: { toast, message } }: UpdateToastMessageAction
  ) {
    setState(
      patch({
        toasts: updateItem<Notification>(t => t.id === toast.id,
          patch<Notification>({
            message
          })
        )
      })
    );
  }

  @Action(UpdateToastProgressAction)
  updateToastProgress(
    { setState }: StateContext<ToastStateModel>,
    { payload: { toast, progress } }: UpdateToastProgressAction
  ) {
    setState(
      patch({
        toasts: updateItem<Notification>(t => t.id === toast.id,
          patch<Notification>({
            title: toast.title,
            message: toast.message,
            icon: toast.icon,
            progress: patch(progress)
          })
        )
      })
    );
  }

  @Action(RemoveToastAction)
  removeToast(
    { setState }: StateContext<ToastStateModel>,
    { toast }: RemoveToastAction
  ) {
    setState(
      patch({ toasts: removeItem<Notification>(t => t.id === toast.id) })
    );
  }

  @Action(ResetToastAction)
  resetNotifications(
    { setState }: StateContext<ToastStateModel>
  ) {
    setState(defaults);
  }
}
