import { Component, OnInit, Input, HostBinding, HostListener, OnDestroy, inject } from '@angular/core';
import { NavigationService, NavigationActionItem } from '../../../services/navigation.service';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { EnvironmentColor } from '../../../../../environments/environment.types';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { Select, Store } from '@ngxs/store';
import { AuthState, User } from '@app/core';

@Component({
  selector: 'app-navigation-list',
  templateUrl: './navigation-list.component.html',
  styleUrls: ['./navigation-list.component.scss']
})
export class NavigationListComponent implements OnInit, OnDestroy {
  @Input() accountNavigationActionItem: NavigationActionItem;
  @Input() productsAction: NavigationActionItem;
  @Input() collectionsAction: NavigationActionItem;
  @Input() mediaAction: NavigationActionItem;
  @Input() importAction: NavigationActionItem;
  @Input() importFlowAction: NavigationActionItem;
  @Input() templateAdminAction: NavigationActionItem;
  @Input() subscriptionsAction: NavigationActionItem;
  @Input() metadataViewAction: NavigationActionItem;
  @Input() referenceTableListAction: NavigationActionItem;
  @Input() verificationAction: NavigationActionItem;
  @Input() dataAuditAction: NavigationActionItem;
  @Input() inventoryAction: NavigationActionItem;
  @Input() tradingPartnersAction: NavigationActionItem;
  @Input() dashboardAction: NavigationActionItem;
  @Input() notificationsAction: NavigationActionItem;
  @Input() videoTutorialsAction: NavigationActionItem;
  @Input() adminAction: NavigationActionItem;
  @Input() userTokenMsLevel = 0;

  isInriver$: Observable<boolean> = inject(Store).select(OrganizationState.isInriverServiceType);
  isAcquia$: Observable<boolean> = inject(Store).select(OrganizationState.isAcquiaServiceType);
  isImportWizardEnabled$: Observable<boolean> = inject(Store).select(OrganizationState.isImportWizardEnabled);

  // userSub :Subscription;
  user:User;
  selectionOpen = false;
  activeNavigationSub: Subscription;
  activeSelectionSub: Subscription;
  environment = environment;
  EnvironmentColor = EnvironmentColor;

  @HostBinding('class.expanded') navigationOpen = false;
  @HostBinding('class.black') devEnv = environment.color === EnvironmentColor.Black || environment.color === EnvironmentColor.Dev;
  @HostBinding('class.red') redEnv = environment.color === EnvironmentColor.Red;
  @HostBinding('class.green') greenEnvdEnv = environment.color === EnvironmentColor.Green;
  @HostBinding('class.indigo') indigo = environment.color === EnvironmentColor.Indigo;
  @HostBinding('class.navy') navy = environment.color === EnvironmentColor.White || environment.color === EnvironmentColor.Product;
  @HostBinding('class.inriver') inriver = false;
  @HostBinding('class.acquia') acquia = false;

  @HostListener('mouseenter')
  mouseEnter() {
    this.navigation.expandNavigation(undefined);
  }
  @HostListener('mouseleave')
  mouseLeave() {
    this.navigation.collapseNavigation();
  }

  constructor(private navigation: NavigationService, private router: Router) { }

  ngOnInit(): void {
    this.isInriver$.subscribe(isInriverServiceType => this.inriver = isInriverServiceType);
    this.isAcquia$.subscribe(isAcquiaServiceType => this.acquia = isAcquiaServiceType);
    this.activeNavigationSub = this.navigation.activeNavigation.subscribe(
      (action: NavigationActionItem) => {
        if (!action) {
          this.navigationOpen = false;
        } else {
          this.navigationOpen = true;
        }
      }
    );
    this.activeSelectionSub = this.navigation.activeSelection.subscribe(
      (action: NavigationActionItem) => {
        if (!action) {
          this.selectionOpen = false;
        } else {
          this.selectionOpen = true;
        }
      }
    );
  }

  ngOnDestroy(): void {
    // this.userSub.unsubscribe();
    this.activeNavigationSub?.unsubscribe();
    this.activeSelectionSub?.unsubscribe();
    this.navigation?.collapseBoth();
  }

  goHome(): void {
    this.navigation.collapseBoth();
    this.router.navigate(['/'], { queryParams: { default: true } });
  }
}
