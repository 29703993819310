<ng-container *ngIf="chips?.length > 0">
  <ng-container *ngFor="let chip of chips; index as i">
    <app-destination-chip [chip]="chip"
      [ngClass]="{'pointer': chipClick.observers.length > 0 }"
      [showDelete]="showDeleteButtons"
      (removeSegment)="deleteSegment(i)"
      (click)="chipClick.emit(chip)">
    </app-destination-chip>
    <div class="plus">+</div>
  </ng-container>
</ng-container>
<div class="no-chips" *ngIf="!chips || chips?.length === 0">
  <i>None</i>
</div>
