import { Component, OnInit, Input} from '@angular/core';
import { ToastService } from '@app/core/services/toast.service';
import { Notification, NotificationAction } from '@app/core/api';

@Component({
  selector: 'app-toast-action',
  templateUrl: './toast-action.component.html',
  styleUrls: ['./toast-action.component.scss']
})
export class ToastActionComponent implements OnInit {
  @Input() notification: Notification;

  constructor(private toast: ToastService) { }

  ngOnInit() {
  }

  dismissToast(notification: Notification, action: NotificationAction): void {
    this.toast.actionDismiss(notification, action);
  }

}
