/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionItem } from './subscriptionItem';
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';


export interface Subscription { 
    billingPartner?: string;
    createdDate?: ModifiedDate;
    externalCustomerId?: string;
    externalId?: string;
    lastUpdated?: ModifiedDate;
    orgRef?: OrganizationReference;
    orgRefCustomer?: OrganizationReference;
    orgRefPartner?: OrganizationReference;
    subscriptionId?: number;
    subscriptionItemList?: Array<SubscriptionItem>;
}

