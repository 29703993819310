<div class="color-circle" [ngClass]="{
  'navy': color === 'navy',
  'blue': color === 'blue',
  'light-blue':color === 'light-blue',
  'green': color === 'green',
  'light-green': color === 'light-green',
  'red': color === 'red',
  'light-red': color === 'light-red',
  'amber': color === 'amber',
  'light-amber': color === 'light-amber',
  'dark-amber': color === 'dark-amber'
}">
  <div class="tool-tip" *ngIf="tooltip">{{ tooltip }}</div>
  <div class="center-circle">
    <i class="icon" [ngClass]="'fas fa-' + icon"></i>
  </div>
</div>
