/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LogisticsDatasetName = 'ALLOCATIONBYVOYAGE' | 'ATTERMINAL' | 'CONTAINERPIPELINE' | 'CURRENTCOUNTYARDMGT' | 'CURRENTVESSELSSNAPSHOT' | 'CURRENTYARDLOCATIONCAPACITY' | 'DCVESSELETACOUNT' | 'DETENTIONDASHBOARD' | 'DETENTIONTOTALDASHBOARD' | 'DRAYCARRIERACTIVE' | 'DRAYCARRIERCOMPLETE' | 'DRAYCARRIERDCCOMPLETE' | 'DRAYCARRIERFUTURE' | 'EDICOMPLETEDELIVERYDC' | 'EDICOMPLETEDELIVERYTERMINAL' | 'EDICOMPLETEPICKUPDC' | 'EDICOMPLETETERMINALPICKUP' | 'EDITIMELINESSDELIVERY' | 'EDITIMELINESSPICKUP' | 'EMPTYNOTIFY' | 'FINALDESTINATIONSTATUS' | 'GATEMGTDRIVERS' | 'GATEWAYCYCLETIME' | 'INTRANSITTORAIL' | 'LEGSTATUSARRIVED' | 'LEGSTATUSASSIGNEDNOTACCEPTED' | 'LEGSTATUSASSIGNEDNOTACCEPTEDHISTORY' | 'LEGSTATUSAVAILABLENOTSCHEDULED' | 'LEGSTATUSAVAILABLENOTSCHEDULEDHISTORY' | 'LEGSTATUSEMPTY' | 'LEGSTATUSLIVE' | 'LEGSTATUSRDYNOTASSIGNED' | 'LEGSTATUSRDYNOTASSIGNEDHISTORY' | 'LEGSTATUSTOTALDASHBOARD' | 'MISSINGDETENTIONRULES' | 'MOTORCARRIERPLAN' | 'NETWORKDRAYCARRIRDETAILS1' | 'NETWORKDRAYCARRIRDETAILS2' | 'NETWORKDRAYCARRIRTOTAL1' | 'NETWORKDRAYCARRIRTOTAL2' | 'OCEANTERMINALCURRENTALL' | 'OCEANTERMINALCURRENTNOTTRACED' | 'OCEANTERMINALHISTORYAVAILABLE' | 'OCEANTERMINALHISTORYDISCHARGE' | 'OCEANTERMINALHISTORYGATEOUT' | 'OFFDOCK' | 'OFFDOCKCOMPLETE' | 'ONRAIL' | 'PASTLFDOFFDOCK' | 'PASTLFDTERMINAL' | 'PLANACTUALIMPORT' | 'PLANACTUALRETURN' | 'POITEMDASHBOARD' | 'RAILETALESSTHENTODAYTOTALDASHBOARD' | 'RAILHISTORYTOTALARRIVAL' | 'RAILHISTORYTOTALDASHBOARD' | 'RAILHISTORYTOTALETA' | 'RAILHISTORYTOTALONRAIL' | 'RAILNOTTRACINGTOTALDASHBOARD' | 'RAILONMISSINGPUTOTALDASHBOARD' | 'RAILONTOTALDASHBOARD' | 'RAILONTOTALPLUSDASHBOARD' | 'RAILTOTALARRIVEDPLUSDASHBOARD' | 'RAILTOTALDASHBOARD' | 'TABLECONTAINER' | 'TABLECONTAINERTYPES' | 'TABLELOCATION' | 'TABLELOCATIONREFERENCE' | 'VALIDATIONPENDINGTOTALSSCAC' | 'VALIDATIONTOTALS' | 'VALIDATIONTOTALSSCAC' | 'VALIDATIONTOTALSUSER' | 'VALIDATIONVESSELETA' | 'VESSELETA' | 'YARDMGTBOBTAILCOUNT' | 'YARDMGTMOVES' | 'YARDSWELL';

export const LogisticsDatasetName = {
    ALLOCATIONBYVOYAGE: 'ALLOCATIONBYVOYAGE' as LogisticsDatasetName,
    ATTERMINAL: 'ATTERMINAL' as LogisticsDatasetName,
    CONTAINERPIPELINE: 'CONTAINERPIPELINE' as LogisticsDatasetName,
    CURRENTCOUNTYARDMGT: 'CURRENTCOUNTYARDMGT' as LogisticsDatasetName,
    CURRENTVESSELSSNAPSHOT: 'CURRENTVESSELSSNAPSHOT' as LogisticsDatasetName,
    CURRENTYARDLOCATIONCAPACITY: 'CURRENTYARDLOCATIONCAPACITY' as LogisticsDatasetName,
    DCVESSELETACOUNT: 'DCVESSELETACOUNT' as LogisticsDatasetName,
    DETENTIONDASHBOARD: 'DETENTIONDASHBOARD' as LogisticsDatasetName,
    DETENTIONTOTALDASHBOARD: 'DETENTIONTOTALDASHBOARD' as LogisticsDatasetName,
    DRAYCARRIERACTIVE: 'DRAYCARRIERACTIVE' as LogisticsDatasetName,
    DRAYCARRIERCOMPLETE: 'DRAYCARRIERCOMPLETE' as LogisticsDatasetName,
    DRAYCARRIERDCCOMPLETE: 'DRAYCARRIERDCCOMPLETE' as LogisticsDatasetName,
    DRAYCARRIERFUTURE: 'DRAYCARRIERFUTURE' as LogisticsDatasetName,
    EDICOMPLETEDELIVERYDC: 'EDICOMPLETEDELIVERYDC' as LogisticsDatasetName,
    EDICOMPLETEDELIVERYTERMINAL: 'EDICOMPLETEDELIVERYTERMINAL' as LogisticsDatasetName,
    EDICOMPLETEPICKUPDC: 'EDICOMPLETEPICKUPDC' as LogisticsDatasetName,
    EDICOMPLETETERMINALPICKUP: 'EDICOMPLETETERMINALPICKUP' as LogisticsDatasetName,
    EDITIMELINESSDELIVERY: 'EDITIMELINESSDELIVERY' as LogisticsDatasetName,
    EDITIMELINESSPICKUP: 'EDITIMELINESSPICKUP' as LogisticsDatasetName,
    EMPTYNOTIFY: 'EMPTYNOTIFY' as LogisticsDatasetName,
    FINALDESTINATIONSTATUS: 'FINALDESTINATIONSTATUS' as LogisticsDatasetName,
    GATEMGTDRIVERS: 'GATEMGTDRIVERS' as LogisticsDatasetName,
    GATEWAYCYCLETIME: 'GATEWAYCYCLETIME' as LogisticsDatasetName,
    INTRANSITTORAIL: 'INTRANSITTORAIL' as LogisticsDatasetName,
    LEGSTATUSARRIVED: 'LEGSTATUSARRIVED' as LogisticsDatasetName,
    LEGSTATUSASSIGNEDNOTACCEPTED: 'LEGSTATUSASSIGNEDNOTACCEPTED' as LogisticsDatasetName,
    LEGSTATUSASSIGNEDNOTACCEPTEDHISTORY: 'LEGSTATUSASSIGNEDNOTACCEPTEDHISTORY' as LogisticsDatasetName,
    LEGSTATUSAVAILABLENOTSCHEDULED: 'LEGSTATUSAVAILABLENOTSCHEDULED' as LogisticsDatasetName,
    LEGSTATUSAVAILABLENOTSCHEDULEDHISTORY: 'LEGSTATUSAVAILABLENOTSCHEDULEDHISTORY' as LogisticsDatasetName,
    LEGSTATUSEMPTY: 'LEGSTATUSEMPTY' as LogisticsDatasetName,
    LEGSTATUSLIVE: 'LEGSTATUSLIVE' as LogisticsDatasetName,
    LEGSTATUSRDYNOTASSIGNED: 'LEGSTATUSRDYNOTASSIGNED' as LogisticsDatasetName,
    LEGSTATUSRDYNOTASSIGNEDHISTORY: 'LEGSTATUSRDYNOTASSIGNEDHISTORY' as LogisticsDatasetName,
    LEGSTATUSTOTALDASHBOARD: 'LEGSTATUSTOTALDASHBOARD' as LogisticsDatasetName,
    MISSINGDETENTIONRULES: 'MISSINGDETENTIONRULES' as LogisticsDatasetName,
    MOTORCARRIERPLAN: 'MOTORCARRIERPLAN' as LogisticsDatasetName,
    NETWORKDRAYCARRIRDETAILS1: 'NETWORKDRAYCARRIRDETAILS1' as LogisticsDatasetName,
    NETWORKDRAYCARRIRDETAILS2: 'NETWORKDRAYCARRIRDETAILS2' as LogisticsDatasetName,
    NETWORKDRAYCARRIRTOTAL1: 'NETWORKDRAYCARRIRTOTAL1' as LogisticsDatasetName,
    NETWORKDRAYCARRIRTOTAL2: 'NETWORKDRAYCARRIRTOTAL2' as LogisticsDatasetName,
    OCEANTERMINALCURRENTALL: 'OCEANTERMINALCURRENTALL' as LogisticsDatasetName,
    OCEANTERMINALCURRENTNOTTRACED: 'OCEANTERMINALCURRENTNOTTRACED' as LogisticsDatasetName,
    OCEANTERMINALHISTORYAVAILABLE: 'OCEANTERMINALHISTORYAVAILABLE' as LogisticsDatasetName,
    OCEANTERMINALHISTORYDISCHARGE: 'OCEANTERMINALHISTORYDISCHARGE' as LogisticsDatasetName,
    OCEANTERMINALHISTORYGATEOUT: 'OCEANTERMINALHISTORYGATEOUT' as LogisticsDatasetName,
    OFFDOCK: 'OFFDOCK' as LogisticsDatasetName,
    OFFDOCKCOMPLETE: 'OFFDOCKCOMPLETE' as LogisticsDatasetName,
    ONRAIL: 'ONRAIL' as LogisticsDatasetName,
    PASTLFDOFFDOCK: 'PASTLFDOFFDOCK' as LogisticsDatasetName,
    PASTLFDTERMINAL: 'PASTLFDTERMINAL' as LogisticsDatasetName,
    PLANACTUALIMPORT: 'PLANACTUALIMPORT' as LogisticsDatasetName,
    PLANACTUALRETURN: 'PLANACTUALRETURN' as LogisticsDatasetName,
    POITEMDASHBOARD: 'POITEMDASHBOARD' as LogisticsDatasetName,
    RAILETALESSTHENTODAYTOTALDASHBOARD: 'RAILETALESSTHENTODAYTOTALDASHBOARD' as LogisticsDatasetName,
    RAILHISTORYTOTALARRIVAL: 'RAILHISTORYTOTALARRIVAL' as LogisticsDatasetName,
    RAILHISTORYTOTALDASHBOARD: 'RAILHISTORYTOTALDASHBOARD' as LogisticsDatasetName,
    RAILHISTORYTOTALETA: 'RAILHISTORYTOTALETA' as LogisticsDatasetName,
    RAILHISTORYTOTALONRAIL: 'RAILHISTORYTOTALONRAIL' as LogisticsDatasetName,
    RAILNOTTRACINGTOTALDASHBOARD: 'RAILNOTTRACINGTOTALDASHBOARD' as LogisticsDatasetName,
    RAILONMISSINGPUTOTALDASHBOARD: 'RAILONMISSINGPUTOTALDASHBOARD' as LogisticsDatasetName,
    RAILONTOTALDASHBOARD: 'RAILONTOTALDASHBOARD' as LogisticsDatasetName,
    RAILONTOTALPLUSDASHBOARD: 'RAILONTOTALPLUSDASHBOARD' as LogisticsDatasetName,
    RAILTOTALARRIVEDPLUSDASHBOARD: 'RAILTOTALARRIVEDPLUSDASHBOARD' as LogisticsDatasetName,
    RAILTOTALDASHBOARD: 'RAILTOTALDASHBOARD' as LogisticsDatasetName,
    TABLECONTAINER: 'TABLECONTAINER' as LogisticsDatasetName,
    TABLECONTAINERTYPES: 'TABLECONTAINERTYPES' as LogisticsDatasetName,
    TABLELOCATION: 'TABLELOCATION' as LogisticsDatasetName,
    TABLELOCATIONREFERENCE: 'TABLELOCATIONREFERENCE' as LogisticsDatasetName,
    VALIDATIONPENDINGTOTALSSCAC: 'VALIDATIONPENDINGTOTALSSCAC' as LogisticsDatasetName,
    VALIDATIONTOTALS: 'VALIDATIONTOTALS' as LogisticsDatasetName,
    VALIDATIONTOTALSSCAC: 'VALIDATIONTOTALSSCAC' as LogisticsDatasetName,
    VALIDATIONTOTALSUSER: 'VALIDATIONTOTALSUSER' as LogisticsDatasetName,
    VALIDATIONVESSELETA: 'VALIDATIONVESSELETA' as LogisticsDatasetName,
    VESSELETA: 'VESSELETA' as LogisticsDatasetName,
    YARDMGTBOBTAILCOUNT: 'YARDMGTBOBTAILCOUNT' as LogisticsDatasetName,
    YARDMGTMOVES: 'YARDMGTMOVES' as LogisticsDatasetName,
    YARDSWELL: 'YARDSWELL' as LogisticsDatasetName
};

