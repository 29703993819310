<div class="cell actions">
  <app-circle-button
    (click)="addNewRow()"
    color="green"
    icon="plus"
    title="Add new row">
  </app-circle-button>
</div>
<div #valuesRef class="values" *ngIf="_columns && _columns.length > 0">
  <div class="value-wrap" *ngFor="let column of _columns; index as i" [ngStyle]="{
      'min-width': minWidths[i] ? minWidths[i] + 'px' : column.width ? column.width + 'px' : 100 / _columns.length + '%',
      'max-width': minWidths[i] ? minWidths[i] + 'px' : column.width ? column.width + 'px' : 100 / _columns.length + '%'
    }"
    [ngClass]="{ 'invalid': getFormControl(column).invalid && getFormControl(column).touched }">
    <ng-container [ngSwitch]="column.type">
      <app-date class="value" *ngSwitchCase="InfiniteScrollColumnType.Date" [formControl]="getFormControl(column)">
      </app-date>
      <app-date-time class="value" *ngSwitchCase="InfiniteScrollColumnType.Datetime"
        [formControl]="getFormControl(column)"></app-date-time>
      <ng-container *ngSwitchCase="InfiniteScrollColumnType.Boolean">
        <select class="value" [formControl]="getFormControl(column)">
          <option [value]="" *ngIf="!column.notNull"></option>
          <option [value]="true">Yes</option>
          <option [value]="false">No</option>
        </select>
      </ng-container>
      <app-infinite-scroll-select *ngSwitchCase="InfiniteScrollColumnType.Suggestion" [row]="row"
        [column]="column" [rowForm]="rowForm" [suggestions]="suggestions" [style]="InfiniteScrollStyle.Default">
      </app-infinite-scroll-select>
      <app-suggestion *ngSwitchCase="InfiniteScrollColumnType.SuggestionList || InfiniteScrollColumnType.MultiSuggestion"
        [include]="column?.suggestionInternalInclude ? column.suggestion : ''"
        [exclude]="column?.suggestionExclude ? column.suggestionExclude : null"
        [filters]="column?.suggestionFilters ? column.suggestionFilters : { by: [], join: [], match: [], value: [] }"
        [multiSelection]="column.type === InfiniteScrollColumnType.MultiSuggestion"
        [label]="getSuggestionLabel(column.displayName)"
        [provided]="suggestions[column.suggestion]"
        [form]="rowForm"
        [field]="column.propertyName"
        [optionProp]="column.suggestionValueProperty ? column.suggestionValueProperty : InfiniteScrollColumnSuggestionProperty.Name"
        [removeBorder]="true">
      </app-suggestion>
      <input *ngSwitchDefault class="value" [contenteditable]="true" [formControl]="getFormControl(column)"/>
    </ng-container>
  </div>
  <div class="value-wrap flex-fill" *ngIf="showColumnFill"></div>
</div>
