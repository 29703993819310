import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-release-date',
  templateUrl: './release-date.component.html',
  styleUrls: ['./release-date.component.scss']
})
export class ReleaseDateComponent implements OnInit {
  @Input() date: string;
  @Input() color: string;
  @Input() showDayOfWeek = true;
  constructor() { }

  ngOnInit() {
  }

}
