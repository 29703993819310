import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-drawer-header',
  templateUrl: './drawer-header.component.html',
  styleUrls: ['./drawer-header.component.scss']
})
export class DrawerHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;

  constructor() { }

  ngOnInit() {
  }

}
