import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit {
  @HostBinding('class.collapse') @Input() toggle = false;

  constructor() { }

  ngOnInit() {
  }

}
