import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  HostListener,
  HostBinding,
  ChangeDetectorRef,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Suggestion, OrgApiService, Organization, FilterJoin, FilterMatch } from '@app/core/api';
import { Store } from '@ngxs/store';
import { OrganizationState } from '@app/features/organization/store/organization.state';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { of, Subject, Subscription, throwError } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { catchError, debounceTime, distinctUntilChanged, filter, mergeMap } from 'rxjs/operators';
import { SuggestionList } from '@app/shared/typings/suggestion-list.types';
// import Fuse from 'fuse.js';

export interface SuggestionFilter {
  by: Array<string>;
  join: Array<FilterJoin>;
  match: Array<FilterMatch>;
  value: Array<string>;
}

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.scss']
})
export class SuggestionComponent implements OnInit, OnDestroy {
  @HostBinding('class.disabled') @Input() disabled = false;
  @Input() set provided(provided: Array<Suggestion>) {
    this._provided = provided;
    if (!this.include) {
      this.suggestions = this.exclusionCheck(provided, this.exclusions);
      this.filterProvidedSelection();
    }
  }
  @Input() include: string;
  @Input() label = 'Select a suggestion';
  @Input() form: UntypedFormControl | UntypedFormGroup;
  @Input() field: string;
  @Input() set exclude(value: Array<string>) {
    this.exclusions = value;
    if (this._provided && this._provided.length >= 1) {
      this.suggestions = this.exclusionCheck(this._provided, value);
    } else {
      this.suggestions = this.exclusionCheck(this.suggestions, value);
    }
  }
  @Input() removeBorder = false;
  // used for when there are multiple suggestion components in the same parent component and you want to debug a specific suggestion component
  @Input() debug = false;
  @Input() backgroundColor = 'transparent';

  @Input() optionProp: string; // Provide `name` or `id` to select either prop from the Suggestion when selecting an option, otherwise both props
  @Input() multiSelection = false;
  @Input() multiSelectionWrap = false;
  @Input() fullWidth = true;
  @Input() height = null;
  @Input() allowOnlyOne = true;
  @Input() set selected(selected: Array<string | number>) {
    this._selected = selected;
    this.setTypeahead();
  };
  @Output() selectedChange: EventEmitter<Array<string | number>> = new EventEmitter();
  @Input() set chosen(chosen: Array<Suggestion>) {
    this._chosen = chosen;
    this._selected = chosen.map(c => c.name);
    this.setTypeahead();
  } // Same as `selected` but list of Suggestion objects
  @Output() chosenChange: EventEmitter<Array<Suggestion>> = new EventEmitter();
  @Input() showList = false;
  @Input() showClose = true;
  @Input() readOnly = false;
  @Output() optionSelected: EventEmitter<string | number | Suggestion> = new EventEmitter();
  @Output() optionDeselected: EventEmitter<string | number> = new EventEmitter();
  @Output() closed: EventEmitter<string | number | Suggestion> = new EventEmitter();
  @Input() resetAfterNavigation = true;
  @Input() resetAfterSelection = false;
  @Input() disableSetTypeahead = false;
  @Input() setTypeaheadIfNotFound = false; // Set Typeahead even if value from form isn't found in list
  @Input() altField: string; // Alternative field property name for fallback
  @Input() invalid = false;
  @Input() loading = false;
  @Input() alignment: 'above' | 'below' = 'below';
  @Input() hideDisabled = false;
  @Input() filters: SuggestionFilter = {
    by: [],
    join: [],
    match: [],
    value: []
  };
  @Input() pageLength = 50;
  @Output() blur: EventEmitter<InputEvent> = new EventEmitter();
  @Output() onChange: EventEmitter<string> = new EventEmitter();
  @Input() showConfirm = false;
  @ViewChild('testJAA') input: ElementRef;
  _provided: Array<Suggestion>;
  _selected: Array<string | number> = [];
  _chosen: Array<Suggestion> = [];

  suggestions: Array<Suggestion> = [];
  filteredSuggestions: Array<Suggestion> = [];
  exclusions: Array<string> = [];
  fetchSub: Subscription;
  typeahead = '';
  formSub: Subscription;
  insideClick = false;
  hasFocus = false;
  openResults = false;
  disableInfiniteScroll = false;
  lastSearch: string;
  filterSelectionDebouncer: Subject<string> = new Subject();

  routerSub: Subscription;
  filterSelectionDebouncerSub: Subscription;

  @HostListener('click')
  clickInside() {
    this.insideClick = true;
    this.hasFocus = true;
  }
  @HostListener('document:click', ['$event'])
  clickOutside(event: InputEvent) {
    if (!this.insideClick) {
      this.openResults = false;
      if(this.tempTypeAhead) this.typeahead = this.tempTypeAhead
      this.filteredSuggestions = [];
      if (this.hasFocus) {
        this.blur.emit(event);
        this.hasFocus = false;
      }
    }

    this.insideClick = false;
  }

  constructor(
    private orgApi: OrgApiService,
    private store: Store,
    private router: Router,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.routerSub = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd)
    ).subscribe(() => {
      if (this.resetAfterNavigation) {
        // because the resetAfterNavigation input defaults to true, this can be causing inputs to be empty unintendly
        if(this.debug) debugger
        this.resetInput();
      }
    });
    this.filterSelectionDebouncerSub = this.filterSelectionDebouncer
      .pipe(debounceTime(350), distinctUntilChanged())
      .subscribe(str => this.filterSelection(str));

    if (this.form) {
      this.formSub = this.form.valueChanges.subscribe(() => {
        this.setTypeahead();
      });
      this.setTypeahead();
    }
  }

  focus() {
    this.input.nativeElement.click();
  }

  ngOnDestroy(): void {
    if (this.fetchSub) {
      this.fetchSub.unsubscribe();
    }
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
    this.routerSub.unsubscribe();
    this.filterSelectionDebouncerSub.unsubscribe();
  }

  public setTypeahead(): void {
    if (this.disableSetTypeahead) {
      return;
    }
    if (!this.multiSelection) {
      if (this._selected && this._selected.length >= 1 && this._selected[0]) {
        const filtered = this.suggestions.filter(
          sugg => this._selected.findIndex(
            pro => this.lazyMatch(pro, sugg.id) || this.lazyMatch(pro, sugg.name)) > -1);
        if (filtered.length === 1 && filtered[0]?.name) {
          this.typeahead = filtered[0].name;
        } else {
          this.typeahead = this._selected[0].toString();
        }
      } else {
        this.typeahead = '';
      }
    }
    if (this.form) {
      const value = this.field && this.form.get([this.field]) ? this.form.get([this.field]).value : this.form.value;
      if (value && this.multiSelection) {
        this._chosen = value.split(', ').map((v: string) => this.suggestions.find(s => this.lazyMatch(v, s.name) || this.lazyMatch(v, s.id))).filter(v => v);
        this._selected = value.split(', ').map((v: string) => this.suggestions.find(s => this.lazyMatch(v, s.name) || this.lazyMatch(v, s.id))?.name).filter(v => v);
        this.selectedChange.emit(this._selected);
        this.chosenChange.emit(this._chosen);
      } else if (typeof value === 'string' || typeof value === 'number') {
        const sugg = this.suggestions.find(s => this.lazyMatch(value, s.name) || this.lazyMatch(value, s.id))?.name;
        if (sugg) {
          this.typeahead = sugg;
        } else if (this.setTypeaheadIfNotFound) {
          if (this.altField && this.form.get([this.altField])?.value) {
            this.typeahead = this.form.get([this.altField]).value
          } else {
            this.typeahead = value as string;
          }
        }
      } else if (value?.name) {
        this.typeahead = value.name;
      } else if (value?.id) {
        this.typeahead = value.id;
      } else {
        this._selected = [];
        this.selectedChange.emit(this._selected);
        this._chosen = [];
        this.chosenChange.emit(this._chosen);
        this.typeahead = '';
      }
    }
    this.cd.markForCheck();
  }

  private lazyMatch(a: number | string, b: number | string): boolean {
    if (!a || !b) {
      return a === b;
    }
    return a === b || a.toString() === b || a === b.toString();
  }

  selectOption(option: Suggestion): void {
    if (this.disabled || option.disabled) {
      return;
    }

    const optionValue: string = this.optionProp ? option[this.optionProp] : option?.name;
    if (!this.allowOnlyOne) {
      this._selected = [...this._selected, optionValue];
    } else {
      const selectedIndex = this._selected.findIndex(s => this.lazyMatch(s, optionValue));
      if (selectedIndex === -1) {
        this._selected = [...this._selected, optionValue];
      } else {
        this._selected = [...this._selected.slice(0, selectedIndex), ...this._selected.slice(selectedIndex + 1)];
      }
    }
    this.selectedChange.emit(this._selected);

    const value: Suggestion | string | number = this.optionProp ? option[this.optionProp] : { id: option?.id, name: option?.name };
    if (!this.multiSelection) {
      this.openResults = false;
      this.filteredSuggestions = [];
    }
    if (!this.disableSetTypeahead && !this.multiSelection) {
      this.typeahead = option?.name ? option.name : option as string;
    }
    if (this.multiSelection) {
      if (!this.allowOnlyOne) {
        this._chosen = [...this._chosen, option];
      } else {
        const chosenIndex = this._chosen.findIndex(s => this.lazyMatch(s.id, option.id) || this.lazyMatch(s.name, option.name));
        if (chosenIndex === -1) {
          this._chosen = [...this._chosen, option];
        } else {
          this._chosen = [...this._chosen.slice(0, chosenIndex), ...this._chosen.slice(chosenIndex + 1)];
        }
      }
      this.chosenChange.emit(this._chosen);
    }
    if (this.form) {
      if (this.field && this.form.get([this.field])) {
        this.form.get([this.field]).setValue(this.multiSelection ? this._selected.join(', ') : value);
      } else {
        this.form.setValue(this.multiSelection ? this._selected.join(', ') : value);
      }
      this.form.markAsDirty();
    }

    // Must be last
    this.optionSelected.emit(value);
    if (this.multiSelection) {
      this.typeahead = '';
      this.openResults = false;
      this.filteredSuggestions = [];
    }
    if (this.resetAfterSelection) {
      this.resetInput();
    }
  }

  selectFirstOption(): void {
    if (this.disableSetTypeahead) {
      this.selectOption({ id: this.typeahead, name: this.typeahead });
      this.resetInput();
    }
    if (this.filteredSuggestions.length === 0) {
      return;
    }
    this.selectOption(this.filteredSuggestions[0]);
  }

  filterSelectionDebounce(str: string): void {
    this.onChange.emit(str);
    if (this.include && (!this._provided || this._provided.length === 0)) {
      this.filterSelectionDebouncer.next(str);
    } else {
      this.filterSelection(str);
    }
  }

  filterSelection(inputText: string): void {
    const trimmed = inputText.trim();
    if (!trimmed && !this.disableSetTypeahead) {
      // Reset if only spaces
      this.resetInput();
      return;
    }
    this.lastSearch = inputText;
    if (this.include && (!this._provided || this._provided.length === 0)) {
      this.suggestions = [];
      this.fetchSuggestions(trimmed);
    } else {
      this.filterProvidedSelection(trimmed);
    }
    this.openResults = true;
  }

  filterProvidedSelection(inputText?: string): void {
    if (inputText) {
      const suggestions = this.suggestions.filter(s => s.name?.trim()?.toLowerCase().includes(inputText.toLowerCase()));
      this.filteredSuggestions = this.alignment === 'above' ? [...suggestions].reverse() : suggestions;
    } else if (this.typeahead && this.typeahead.trim()) {
      const suggestions = this.suggestions.filter(s => s.name?.trim()?.toLowerCase().includes(this.typeahead.trim().toLowerCase()));
      this.filteredSuggestions = this.alignment === 'above' ? [...suggestions].reverse() : suggestions;
    } else {
      this.filteredSuggestions = this.alignment === 'above' ? [...this.suggestions].reverse() : this.suggestions;
    }
    // const suggestions = new Fuse(this.suggestions, { keys: ['name'] }).search(trimmed).map(f => f.item);
  }

  close() {

    if (this.disabled) {
      return;
    }
    const suggestion = this.suggestions.find(s => this.lazyMatch(this.typeahead, s.name) || this.lazyMatch(this.typeahead, s.id));
    this.closed.emit(suggestion && this.optionProp ? suggestion[this.optionProp] : suggestion);
    this.resetInput();
  }


  resetInput(): void {
    if (!this.multiSelection) {
      this._selected = [];
      this.selectedChange.emit(this._selected);
      this._chosen = [];
      this.chosenChange.emit(this._chosen);
    }
    this.lastSearch = null;
    this.tempTypeAhead = ''
    this.typeahead = '';
    this.openResults = false;
    this.filteredSuggestions = [];
    if (this.form) {
      if (this.altField && this.form.get([this.altField])) {
        this.form.get([this.altField]).setValue('');
      }
      if (this.field && this.form.get([this.field])) {
        this.form.get([this.field]).setValue('');
      } else {
        this.form.reset();
      }
      this.form.markAsPristine();
    }
  }

tempTypeAhead = ""
  toggleList(): void {

    if (this.disabled) {
      return;
    }
    if (this.openResults) {
      this.typeahead = this.tempTypeAhead
      this.openResults = false;
      this.filteredSuggestions = [];
      return;
    }

    if (this.include && (!this._provided || this._provided.length === 0)) {
      this.suggestions = [];
      this.tempTypeAhead = this.typeahead
      this.typeahead = ""
      this.openResults = true;
      this.fetchSuggestions();
    } else if (this.suggestions && this.suggestions.length > 0) {
      this.tempTypeAhead = this.typeahead
      this.typeahead = ""
      this.openResults = true;
      this.filterProvidedSelection();
    } else {
      this.tempTypeAhead = this.typeahead
      this.typeahead = ""
      this.openResults = true;
    }
  }

  exclusionCheck(suggestions: Suggestion[], exclusions: Array<string>): Suggestion[] {
    if (!suggestions || suggestions.length === 0) {
      return [];
    }
    if (!this.exclusions || this.exclusions.length === 0) {
      return suggestions;
    }
    return suggestions.filter(
      (suggestion: Suggestion) => {
        let found = true;
        for (let i = 0; i < this.exclusions.length; i++) {
          if (exclusions[i] === suggestion.name) {
            found = false;
          }
        }
        return found;
      }
    );
  }

  onScrollDown(): void {
    if (this.include && !this._provided) {
      this.fetchSuggestions();
    }
  }

  fetchSuggestions(str?: string): void {
    this.disableInfiniteScroll = true;
    const pageLength = this.pageLength;
    const pageToGet = Math.ceil(this.suggestions.length / pageLength) + 1;
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    let by: Array<string> = [...(this.filters.by ? this.filters.by : [])];
    let join: Array<FilterJoin> = [...(this.filters.join ? this.filters.join : [])];
    let match: Array<FilterMatch> = [...(this.filters.match ? this.filters.match : [])];
    let value: Array<string> = [...(this.filters.value ? this.filters.value : [])];
    if (str || this.lastSearch) {
      by = [...by, 'suggestionKey'];
      join = [...join, FilterJoin.AND];
      match = [...match, FilterMatch.CONTAINS];
      value = [...value, str ? str : this.lastSearch];
    }
    this.loading = true;
    this.openResults = true;
    this.cd.markForCheck();
    this.fetchSub = this.orgApi.getSuggestions(
      [this.include],
      organization.id,
      by,
      join,
      match,
      value,
      pageLength,
      pageToGet
    ).pipe(
      mergeMap(suggestions => {
        if (suggestions && suggestions[this.include] && Array.isArray(suggestions[this.include])) {
          return of(suggestions);
        }
        return throwError('Invalid Suggestion response');
      }),
      catchError(error => {
        this.loading = false;
        this.disableInfiniteScroll = false;
        this.openResults = true;
        this.cd.markForCheck();
        return throwError(error);
      })
    ).subscribe(
      (suggestions: SuggestionList) => {
        this.suggestions = [...this.suggestions, ...this.exclusionCheck(suggestions[this.include], this.exclusions)];
        this.filterProvidedSelection();
        this.loading = false;
        this.disableInfiniteScroll = false;
        this.openResults = true;
        this.cd.markForCheck();
      }
    );
  }

  removeChip(index: number): void {
    this.optionDeselected.emit(this._selected[index]);
    this._selected = [...this._selected.slice(0, index), ...this._selected.slice(index + 1)];
    this._chosen = [...this._chosen.slice(0, index), ...this._chosen.slice(index + 1)];
    if (this.form) {
      if (this.field && this.form.get([this.field])) {
        this.form.get([this.field]).setValue(this._selected.join(', '));
      } else {
        this.form.setValue(this._selected.join(', '));
      }
      this.form.markAsDirty();
    }
    if (this._selected.length === 0 && this._chosen.length === 0) {
      this.typeahead = '';
      this.openResults = false;
      this.filteredSuggestions = [];
    }
    this.selectedChange.emit(this._selected);
    this.chosenChange.emit(this._chosen);
  }
}
