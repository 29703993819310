<app-page-loading [loading]="loading$ | async"></app-page-loading>
<!--Tailwind design -Start -->
<form>
  <div class="tw-space-y-12">
    <!-- Template -->
    <div
      class="tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 tw-border-b tw-border-gray-900/10 tw-pb-12 md:tw-grid-cols-3">
      <div>
        <h2 class="sticky tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">Template</h2>
        <p class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-600">Edit information about the template.</p>
        <div style="display: flex; margin-bottom: 1em;" *ngIf="createdDate">
          <span style="margin-right: 0.5em;" class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-600">Created
            date:</span> <span
            class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-blue-50 tw-px-1.5 tw-py-0.5 tw-text-xs tw-font-medium tw-text-blue-700 tw-ring-1 tw-ring-inset tw-ring-blue-700/10">
            {{createdDate | date: 'fullDate'}} </span>
        </div>
        <div style="display: flex;" *ngIf="changedDate">
          <span style="margin-right: 0.5em;" class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-600">Last
            Modifed:</span> <span
            class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-blue-50 tw-px-1.5 tw-py-0.5 tw-text-xs tw-font-medium tw-text-blue-700 tw-ring-1 tw-ring-inset tw-ring-blue-700/10">
            {{changedDate | date: 'fullDate'}} </span>
        </div>
      </div>

      <div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2">
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Template
            Name:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('fileKey')" placeholder="Enter a Template Name"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Seed File
            Name:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('appendFile')" placeholder="Enter a Seed File Name"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Header
            Key:</label>
          <div class="tw-mt-2">
            <div class="tw-mt-2 sm:tw-col-span-2 sm:tw-mt-0">
              <app-suggestion-3 [provided]="headerKeysSuggestions" [showList]="true" label="Select a Header Type"
                [form]="form" field="headerKey" optionProp="name" [resetAfterNavigation]="false" [fullWidth]="true"
                removeBorder="false"></app-suggestion-3>
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">File
            Extension:</label>
          <div class="tw-mt-2">
            <div class="tw-mt-2 sm:tw-col-span-2 sm:tw-mt-0">
              <app-suggestion-3 [provided]="fileTypeSuggestions" [showList]="true" label="Select a File Extension"
                [form]="form" field="fileExtension" optionProp="id" [resetAfterNavigation]="false" [fullWidth]="true"
                removeBorder="false"></app-suggestion-3>
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"> Force Parent Inheritance:</label>
          <div class="tw-mt-2">
              <!-- <input 
                style="padding: 1em;" 
                type="checkbox"
                [checked]="templateForm.get('forceParentAlignment')?.value == true"
                (change)="templateForm.get('forceParentAlignment')?.setValue($event.target.checked ? true : false)"
                class="tw-h-4 tw-w-4 tw-text-indigo-600 tw-border-gray-300 tw-rounded focus:tw-ring-indigo-500"> -->
                <app-checkbox
                shape="square"
                (selectedChange)="templateForm.get('forceParentAlignment')?.setValue($event ? true : false)"
                [selected]="templateForm.get('forceParentAlignment')?.value === true">
              </app-checkbox>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"> Parent
            Template:</label>
          <div class="tw-mt-2">
            <div class="tw-mt-2 sm:tw-col-span-2 sm:tw-mt-0">
              <app-suggestion-3 [provided]="templateParentSuggestions$ | async" label="Select a Parent Template"
                [form]="templateForm" field="parentTemplateName" optionProp="name" [fullWidth]="true"
                removeBorder="false"></app-suggestion-3>
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"> Template
            Language Code:</label>
          <div class="tw-mt-2">
            <div class="tw-mt-2 sm:tw-col-span-2 sm:tw-mt-0">
              <app-suggestion-3 label="Select a Language Code" [provided]="languageCodeSuggestionList$ | async"
                field="languageCodePrimary" [form]="templateForm" optionProp="id" [fullWidth]="true"
                removeBorder="false"></app-suggestion-3>
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"> Output
            Language Code:</label>
          <div class="tw-mt-2">
            <div class="tw-mt-2 sm:tw-col-span-2 sm:tw-mt-0">
              <app-suggestion-3 label="Select a Language Code" [provided]="languageCodeSuggestionList$ | async"
                field="languageCodeOutput" [form]="templateForm" optionProp="id" [fullWidth]="true"
                removeBorder="false"></app-suggestion-3>
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"> Currency
            Code:</label>
          <div class="tw-mt-2">
            <div class="tw-mt-2 sm:tw-col-span-2 sm:tw-mt-0">
              <app-suggestion-3 label="Select a Currency Code" [provided]="currencyCodeSuggestionList$ | async"
                field="currencyCodeOutput" [form]="templateForm" optionProp="id" [fullWidth]="true"
                removeBorder="false"></app-suggestion-3>
            </div>
          </div>
        </div>
      
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Input Date Format:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
              [formControl]="templateForm.get('inputDateFormat')" placeholder="Enter a format"
              class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
           </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Template Map-->
    <div
      class="tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 tw-border-b tw-border-gray-900/10 tw-pb-12 md:tw-grid-cols-3">
      <div>
        <h2 class="sticky tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">Template Map</h2>
        <p class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-600">Edit Mapping Information.</p>
      </div>

      <div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2">
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Append
            File:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('appendFile')" placeholder="Append File"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Sheet
            Name:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('sheetName')" placeholder="Enter a Sheet Name"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Client
            Key:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('clientKey')" placeholder="Enter a Client Key"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Input
            Type:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('inputType')" placeholder="Enter an Input Type"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Output
            Type:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('outputType')" placeholder="Enter an Output Type"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">File
            Key:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('fileKey')" placeholder="Enter a File Key"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Skip
            Header:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('skipHeader')" placeholder="Enter Skip Header"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Skip
            Empty:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('skipEmpty')" placeholder="Enter Skip Empty"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Skip
            Catalog:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('skipCatalogProduct')" placeholder="Enter a Skip Catalog Product"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Message
            Type:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('messageType')" placeholder="Enter a Message Type"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Message
            Table:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('messageTable')" placeholder="Enter a Message Table"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Message
            Block:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('messageBlock')" placeholder="Enter a Message Block"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Message
            Key:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('messageKey')" placeholder="Enter a Message Key"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Message
            Flow:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('messageFlow')" placeholder="Enter a Message Flow"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Token:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="form.get('token')" placeholder="Enter a Token"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Sheet-->
    <div
      class="tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 tw-border-b tw-border-gray-900/10 tw-pb-12 md:tw-grid-cols-3">
      <div>
        <h2 class="sticky tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">Sheet</h2>
        <p class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-600">Edit Sheet Information.</p>
      </div>
      <div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2">
        <div class="sm:tw-col-span-4">
          <label for="website" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Sheet
            Name:</label>
          <div class="tw-mt-2">
            <div
              class="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
              <input #input (keydown.enter)="moveFocus($event)" style="padding: 1em;" type="text"
                [formControl]="templateForm.get('sheetName')" placeholder="Sheet Name"
                class="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6">
            </div>
            <div style=" margin-top: 1em; display: flex; flex-direction: row-reverse;"
              class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2">
              <app-button color="blue" (click)="showAddSheetModal = true"><img style="margin-right: 0.6em;"
                  src="https://cdn-icons-png.flaticon.com/512/11366/11366824.png" width="18" height="18">Add
                Sheet</app-button>
              <app-button (click)="showRemoveSheetModal = true" color="red">Remove Sheet</app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Root Sheet-->
    <div class="tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10  tw-border-gray-900/10  md:tw-grid-cols-3">
      <div>
        <h2 class="sticky tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">Root Sheet
        </h2>
        <p class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-600">Edit Root Sheet
          Information.</p>
      </div>
      <app-template-sheet-edit [form]="rootSheetForm">
      </app-template-sheet-edit>
    </div>
    <div
      class="tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 tw-border-b tw-border-gray-900/10 tw-pb-12 md:tw-grid-cols-3">
      <div style="display: flex;">
      </div>
      <div style="display: flex;">
        <app-button (click)="cancel()" color="red" style="margin-right: 1.5em;">Cancel</app-button>
        <app-button (click)="updateRootSheet()" [disabled]="form.invalid" color="blue"><img style="margin-right: 0.6em;"
            src="https://cdn-icons-png.flaticon.com/512/875/875100.png" width="20" height="18">Update Root
          Sheet</app-button>
      </div>
    </div>
    <!-- Log-->
    <div
      class="tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 tw-border-b tw-border-gray-900/10 tw-pb-12 md:tw-grid-cols-3">
      <div>
        <h2 class="sticky tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">Log
        </h2>
        <p class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-600">Edit Log
          information.</p>
      </div>
      <div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2">
        <div class="sm:tw-col-span-4">
          <div class="tw-mt-2">
            <div *ngIf="logs?.length === 0">There are no log entries yet.</div>
            <div class="log" *ngFor="let log of logs">
              <div class="log-text">{{ log.notes }}</div>
              <div class="log-date">Added on {{log.changeDate | date:'long'}} by {{log.author}}</div>
            </div>
          </div>
        </div>
        <div class="sm:tw-col-span-4">
          <div class="tw-mt-2">
            <textarea [formControl]="logInputForm" placeholder="Add a new log entry..." id="about" rows="3"
              class="tw-block tw-w-full tw-max-w-2xl tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"></textarea>
            <p class="tw-mt-3 tw-text-sm tw-leading-6 tw-text-gray-600">Add a new log entry.</p>
          </div>
          <div style="display: flex; flex-direction: row-reverse;"
            class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6 md:tw-col-span-2">
            <app-button color="blue" [disabled]="!logInputForm.valid" (click)="addLog()"><img
                style="margin-right: 0.6em;" src="https://cdn-icons-png.flaticon.com/512/11366/11366824.png" width="18"
                height="18">
              Add log
            </app-button>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="tw-mt-6 tw-flex tw-items-center tw-justify-end tw-gap-x-6">
    <app-button (click)="cancel()" color="red"
      class="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">Cancel</app-button>
    <app-button (click)="updateTemplateProperties()" [disabled]="form.invalid" color="blue"><img
        style="margin-right: 0.6em;" src="https://cdn-icons-png.flaticon.com/512/875/875100.png" width="20"
        height="18">Update Template</app-button>
  </div>
</form>
<!-- Tailwind design END -->

<app-modal [open]="showRemoveSheetModal" [height]="250" [width]="400">
  <app-modal-head>
    <app-modal-title>Warning!</app-modal-title>
  </app-modal-head>
  <app-modal-body [center]="true">
    <div>Do you want to delete <b>{{ sheet$ | async }}</b> sheet?</div>
  </app-modal-body>
  <app-modal-foot [center]="true">
    <app-button (click)="showRemoveSheetModal = false">Cancel</app-button>
    <div class="flex-fill"></div>
    <app-button (click)="removeSheet()" color="red">Remove</app-button>
  </app-modal-foot>
</app-modal>

<app-modal [open]="showAddSheetModal" [height]="500">
  <app-modal-head>
    <app-modal-title>Add New Template Sheet</app-modal-title>
  </app-modal-head>
  <app-modal-body>
    <input #input (keydown.enter)="moveFocus($event)" [formControl]="sheetForm.get('sheetName')"
      placeholder="Sheet Name" />
    <div><b>Note: </b>Copy list of field names directly from spreadsheet and paste below</div>
    <input #input (keydown.enter)="moveFocus($event)" [formControl]="sheetForm.get('fieldNames')"
      placeholder="Field Names" />
    <ol class="field-names">
      <li class="field-name" *ngFor="let field of formattedFieldNames">{{ field }}</li>
    </ol>
  </app-modal-body>
  <app-modal-foot [center]="true">
    <app-button (click)="sheetForm.reset(); showAddSheetModal = false" color="red">Cancel</app-button>
    <div class="flex-fill"></div>
    <app-button (click)="addNewSheet()" color="green"
      [disabled]="sheetForm.invalid || formattedFieldNames.length === 0">Save</app-button>
  </app-modal-foot>
</app-modal>