<div class="calendar-page" [ngClass]="{
  'green': color === 'green',
  'amber': color === 'amber',
  'red': color === 'red',
  'blue': color === 'blue',
  'grey': color === 'grey',
  'show-day-of-week': showDayOfWeek
}">
  <div class="month">{{ date | date: 'MMM'}}</div>
  <div class="day-of-month">{{ date | date: 'dd' }}</div>
  <div class="day-of-week" *ngIf="showDayOfWeek">{{ date | date: 'EEE' }}</div>
</div>
