<div class="header">
  <div class="button" (click)="openChange.emit(!open)">
    <i class="fas fa-arrow-alt-right"></i>
  </div>
  <div class="title-wrapper">
    <div class="title">Fields</div>
  </div>
</div>
<div class="filter" *ngIf="showFilter">
  <input placeholder="Filter by field" [formControl]="filterControl" />
</div>
<div class="columns">
  <div class="column" *ngFor="let column of filteredColumns" (click)="toggleColumn(column)" [ngClass]="{
      'active': isColumnActive(column) || column?.validation.required === true,
      'required': column?.validation.required === true
    } " [title]="column.friendlyName ? column.friendlyName : column.displayName">
    <ng-container *ngIf="column?.validation.required === true">*</ng-container>{{ column.friendlyName ?
    column.friendlyName : column.displayName }}<span class="core-badge" *ngIf="column?.genericFlag === true">Core</span>
  </div>
</div>