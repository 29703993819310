import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApplicationService } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class AgentGuard  {

  private allowedBrowsers: Array<string> = [
    'Chrome',
    'MS-Edge',
    'MS-Edge-Chromium',
    'Firefox'
  ];

  constructor(private router: Router, private device: DeviceDetectorService, private appSvc: ApplicationService) {}

  canActivate(): boolean | UrlTree {
    if (!this.appSvc.skipUserAgentCheck) {
      if (!this.device.isDesktop()) {
        return this.router.createUrlTree(['/device']);
      }
      if (this.allowedBrowsers.indexOf(this.device.browser) === -1) {
        return this.router.createUrlTree(['/browser']);
      }
    }
    return true;
  }
}
