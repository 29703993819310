import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'minutesToHours'
})
export class MinuteHoursPipe implements PipeTransform {

    transform(value: number): string {

        var num = value;
        if(num === 0 || isNaN(num)) {
            return 'n/a';
        }
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + "h " + rminutes + "m";

    }

}