/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VesselVoyage } from './vesselVoyage';
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';


export interface Vessel { 
    capacity?: number;
    createdDate?: ModifiedDate;
    imoVesselId?: string;
    lastUpdated?: ModifiedDate;
    latestPositionDate?: string;
    latitude?: string;
    lloydsCode?: string;
    longitude?: string;
    orgRef?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    totalVoyageCount?: number;
    vesselId?: number;
    vesselName?: string;
    vesselVoyages?: Array<VesselVoyage>;
    voyageCount?: number;
}

