<!-- draw the frame and add a row for each workflow step -->
<div class="chart-container">
  <div class="chart-body">
    <div class="column labels">
      <div class="cell column-header label">
        # Not Ready
      </div>
      <div class="cell category-label" *ngFor="let category of workflow.categories">
        {{ category.name }}
      </div>
      <div class="cell date-label title">
        Days past Availability
      </div>
    </div>
    <div class="column" *ngFor="let date of dates">
      <div class="cell column-header">
        {{ date.totalWarnings + date.totalErrors }}
      </div>
      <div class="cell value" *ngFor="let category of date.categories">
        <div class="figure errors" *ngIf="(category.warnings + category.errors) > 0">
          {{ category.errors }}
        </div>

        <div class="figure total" *ngIf="(category.warnings + category.errors) > 0">
          {{ category.warnings + category.errors}}
        </div>

        <div class="figure warnings" *ngIf="(category.warnings + category.errors) > 0">
          {{ category.warnings }}
        </div>
      </div>
      <div class="cell date-label">
        {{ date.date }}
      </div>
    </div>
  </div>
</div>
