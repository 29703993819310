import { Notification, NotificationProgress } from '../api';

export class AddToastAction {
  static readonly type = '[Toast] Add Notification';
  constructor(public toast: Notification) { }
}

export class UpdateToastMessageAction {
  static readonly type = '[Toast] Update Notification message';
  constructor(public payload: { toast: Notification, message: Notification['message']}) { }
}

export class UpdateToastProgressAction {
  static readonly type = '[Toast] Update Notification progress';
  constructor(public payload: { toast: Notification, progress: NotificationProgress }) { }
}

export class RemoveToastAction {
  static readonly type = '[Toast] Remove Notification';
  constructor(public toast: Notification) { }
}

export class ResetToastAction {
  static readonly type = '[Toast] Reset state';
  constructor() {}
}
