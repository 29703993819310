/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Audit } from './audit';
import { UserReference } from './userReference';
import { OrganizationReference } from './organizationReference';
import { ViewField } from './viewField';


export interface View { 
    auditList?: Array<Audit>;
    description?: string;
    groupBy?: string;
    jsonData?: { [key: string]: any; };
    orgRef?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    productSubType?: string;
    productSubType2?: string;
    productType?: string;
    sparkView?: string;
    usedList?: string;
    userRef?: UserReference;
    viewFields?: Array<ViewField>;
    viewId?: number;
    viewName?: string;
    viewType?: string;
}

