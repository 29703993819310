<div class="title">Notifications</div>
<div class="mark-all-as-read" (click)="markAllAsRead()" *ngIf="notifications.length > 0">
  Mark all as read
</div>
<div class="notifications" *ngIf="notifications.length > 0">
  <div class="date-section" *ngFor="let date of notificationDates">
    <div class="date-title">{{ date.date }}</div>
    <app-notification-action *ngFor="let notification of date.notifications" [notification]="notification">
    </app-notification-action>
  </div>
</div>
<div class="no-notifications" *ngIf="notifications.length === 0">
  <span>Nothing yet...</span>
</div>
<div class="dismiss-all" (click)="dismissAll()" *ngIf="notifications.length > 0">
  Dismiss all
</div>
