import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { ExceptionService } from '../services/exception.service';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {

  constructor(private exception: ExceptionService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const error = (e: any): Observable<any> => {
      if (e instanceof TimeoutError) {
        this.exception.add({
          name: 'Request timeout',
          info: 'The server is having trouble processing your request.',
          error: JSON.stringify(e)
        });
        return throwError({ ...e, url: request.url });
      }
      return throwError(e);
    };

    // Extend timeout for requesting a file
    if (request.responseType === 'blob') {
      return next.handle(request).pipe(
        timeout(900000),
        catchError(error)
      )
    }
    return next.handle(request).pipe(
      timeout(45000),
      catchError(error)
    );
  }
}
