import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {
  @Input() toggle = false;
  @Output() toggleChange: EventEmitter<boolean> = new EventEmitter();
  @Input() showText = 'Show';
  @Input() hideText = 'Hide';
  @Input() icon: string;
  @Input() color: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleClick(): void {
    if (this.toggle) {
      this.toggle = false;
      this.toggleChange.emit(false);
    } else {
      this.toggle = true;
      this.toggleChange.emit(true);
    }
  }
}
