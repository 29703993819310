/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';


export interface EdiLog { 
    bucketName?: string;
    createdDate?: ModifiedDate;
    ediLogId?: number;
    fileError?: string;
    fileMdn?: string;
    fileName?: string;
    fileResponse?: string;
    message?: string;
    messageError?: string;
    messageMdn?: string;
    messageResponse?: string;
    messageStatus?: number;
    messageType?: string;
    orgRefFrom?: OrganizationReference;
    orgRefTo?: OrganizationReference;
    outbound?: boolean;
    processed?: boolean;
    resentDate?: ModifiedDate;
    tableKey?: string;
    tableName?: string;
    transferId?: string;
    transferType?: string;
    uriPath?: string;
}

