import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
  @Input() solid = false;
  @Input() icon = 'circle';
  @Input() leftIcon: string;
  @Input() rightIcon: string;
  @Input() leftTitle: string;
  @Input() rightTitle: string
  @Input() leftText: string;
  @Input() rightText: string;
  @Input() leftColor = 'blue';
  @Input() rightColor = 'blue';

  @Input() selected = true;
  @Output() selectedChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  left(): void {
    if (!this.selected) {
      return;
    }
    this.selected = false;
    this.selectedChange.emit(this.selected);
  }

  right(): void {
    if (this.selected) {
      return;
    }
    this.selected = true;
    this.selectedChange.emit(this.selected);
  }

}
