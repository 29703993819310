/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Channel } from './channel';
import { Product } from './product';
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';


export interface PublicLink { 
    channelData?: Channel;
    channelId?: number;
    createdDate?: ModifiedDate;
    email?: string;
    linkId?: number;
    linkName?: string;
    linkType?: string;
    linkUrl?: string;
    orgRef?: OrganizationReference;
    productList?: Array<Product>;
    purchaseType?: string;
    screenData?: { [key: string]: any; };
    shareType?: string;
    subCatalogId?: number;
}

