<i *ngIf="icon || !icon && !label" [attr.class]="(solid ? 'fab' : 'fas') + ' fa-' + (icon ? icon : 'circle') + (disabled ? ' red' : ' ' + color)"></i>
<span *ngIf="label" class="label" [ngClass]="disabled ? ' red' : ' ' + color">{{ label }}</span>


<ng-container *ngIf="tooltip">
  <div class="tooltip-hover">
    <div class="tooltip">
      <div class="icon" (mouseover)="showToolTip = true" (mouseout)="showToolTip = false">
      </div>
      <div class="body" *ngIf="showToolTip">{{tooltip}}</div>
    </div>
  </div>
</ng-container>
