<form class="controls" [formGroup]="form" (ngSubmit)="submitFilter()">
  <div class="filter" *ngFor="let filter of filters">
    <div class="label">
      <div>{{ filter.displayName }}</div>
      <i class="fad fa-times-circle" *ngIf="filterIsActive(filter)" (click)="clearFilter(filter)"></i>
    </div>
    <div class="inputs" *ngFor="let f of filter.filters">
      <div class="sub-label" *ngIf="f.name">{{ f.name }}</div>
      <input [formControlName]="f.name ? f.name + filter.propertyName : filter.propertyName" [type]="f.inputType">
    </div>
  </div>

  <div class="filter-button">
    <div class="flex-fill"></div>
    <app-button (click)="submitFilter()" color="green">Add</app-button>
    <button type="submit" style="display: none"></button>
  </div>
</form>
