<div class="left" [ngClass]="{
  'green': leftColor === 'green',
  'amber': leftColor === 'amber',
  'red': leftColor === 'red',
  'blue': leftColor === 'blue',
  'navy': leftColor === 'navy',
  'active': !selected
}" (click)="left()" [title]="leftTitle ? leftTitle : leftText ? leftText : ''">
  <i *ngIf="leftIcon" [ngClass]="{ 'right-margin': leftText }" [attr.class]="(solid ? 'fab' : 'fas') + ' fa-' + leftIcon"></i>
  <div>{{ leftText }}</div>
</div>
<div class="right" [ngClass]="{
  'green': rightColor === 'green',
  'amber': rightColor === 'amber',
  'red': rightColor === 'red',
  'blue': rightColor === 'blue',
  'navy': rightColor === 'navy',
  'active': selected
}" (click)="right()" [title]="rightTitle ? rightTitle : rightText ? rightText : ''">
  <i *ngIf="rightIcon" [ngClass]="{ 'right-margin': rightText }" [attr.class]="(solid ? 'fab' : 'fas') + ' fa-' + rightIcon"></i>
  <div>{{ rightText }}</div>
</div>
