

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, ModuleWithProviders, NgZone, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  AuthState,
  BrandTpAlignmentApiService,
  Catalog,
  DataProcessField,
  FilterJoin,
  FilterMatch,
  NotificationType,
  OpenaiService,
  OrgApiService,
  Organization,
  SuggestedMatch,
  Suggestion,
  TemplateApiService,
  ToastService,
  TradingPartnerAlignment,
  FilterEntry
} from '@app/core';
import { FunctionType, TemplateService } from '@app/shared/services/template.service';
import { Template } from '@app/core';
import { Select, Store } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { Observable, Subscription, catchError, of, tap, BehaviorSubject } from 'rxjs';
import { EditState } from '../../edit-lock/edit-lock.component';
import { Chip, ChipType } from '../destination-chip/destination-chip.component';
import { OrganizationState } from "@app/features/organization/store/organization.state";
import { DestinationExportMappingState } from '@app/features/destination/stores/destination-export-mapping.state';
import { GobackToAISummary, RunAiBot } from '@app/features/destination/stores/destination-export-mapping.actions';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { FieldsFilterPipe } from '@app/shared/pipes/fields-filter.pipe';

export type TemplateViewAs = 'Syndic8' | 'Syndic8Inbound' | 'AllPortCargoServicesInbound' | 'AllPortCargoServicesOutbound';

export const TemplateViewAs = {
  Syndic8: 'Syndic8' as TemplateViewAs,
  Syndic8Inbound: 'Syndic8Inbound' as TemplateViewAs,
  AllPortCargoServicesInbound: 'AllPortCargoServicesInbound' as TemplateViewAs,
  AllPortCargoServicesOutbound: 'AllPortCargoServicesOutbound' as TemplateViewAs
};

export type TemplateColumnType = 'action' | 'icon' | 'prefix' | 'name' | 'arrow' | 'required' | 'length' | 'default' | 'type' | 'format' | 'field' | 'comment' | 'example' | 'definition' | 'function' | 'parent';

export const TemplateColumnType = {
  Action: 'action' as TemplateColumnType,
  Icon: 'icon' as TemplateColumnType,
  Prefix: 'prefix' as TemplateColumnType,
  Name: 'name' as TemplateColumnType,
  Arrow: 'arrow' as TemplateColumnType,
  Required: 'required' as TemplateColumnType,
  Length: 'length' as TemplateColumnType,
  Default: 'default' as TemplateColumnType,
  Type: 'type' as TemplateColumnType,
  Format: 'format' as TemplateColumnType,
  Field: 'field' as TemplateColumnType,
  Comment: 'comment' as TemplateColumnType,
  Example: 'example' as TemplateColumnType,
  JsonPath: 'jsonpath' as TemplateColumnType,
  Definition: 'definition' as TemplateColumnType,
  Function: 'function' as TemplateColumnType,
  Parent: 'parent' as TemplateColumnType,
  Edit: 'edit' as TemplateColumnType
};

export interface TemplateColumn {
  type: TemplateColumnType;
  width: string | number;
}

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  providers: [
    (
      NgCircleProgressModule.forRoot(
        {}
      ) as ModuleWithProviders<NgCircleProgressModule>
    ).providers!,
  ],
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit, OnDestroy {
  @Select(AuthState.getTokenMsLevel) userTokenMsLevel: Observable<number>;
  @Select(OrganizationState.isInriverServiceType) isInriver$: Observable<boolean>;
  @Select(OrganizationState.isAcquiaServiceType) isAcquia$: Observable<boolean>;
  @Select(DestinationExportMappingState.getRunAiBot) runAiBot$: Observable<Template>;
  @Select(DestinationExportMappingState.goBackToAISummaryValue) goBackToAISummaryValue$: Observable<Template>;

  @ViewChild('virtualScroll') viewPort: CdkVirtualScrollViewport;

  @Input() template: Template;
  @Input() templateFields$: Observable<Array<DataProcessField>>;
  @Input() editState$: Observable<EditState>;
  @Input() loading = false;
  @Input() isEditing = false;
  @Input() destination: Catalog;
  @Input() syndic8FieldSuggestions: Array<Suggestion> = [];
  @Input() acsFieldSuggestions: Array<Suggestion> = [];
  @Input() internalFieldSuggestions: Array<Suggestion> = [];
  @Input() sheetSuggestions: Array<Suggestion> = [];
  @Input() alignments$: Observable<Array<TradingPartnerAlignment>>;
  @Input() alignmentsLoading = false;
  @Input() fieldListSuggestions: Array<Suggestion> = [];
  @Input() viewAs: TemplateViewAs = TemplateViewAs.Syndic8;

  @ViewChild('EditField') editFieldModal;

  autoAImatchedValue: string;
  uniqueInternalFields: any;
  showValidValue: boolean = false
  counterSummery: number = 0
  showAImatchSummery: boolean = false;
  tradingPartnerFieldsSummeryAI: any = [];
  VVmatches: any = [];
  showCircleProgress: boolean = false;
  showAnimation: boolean = false;
  percetageCircleProgress: number = 100;
  AiBotActive: boolean = false;
  showGoBackButton: boolean = false;
  acceptedAllSuggested: boolean = false;
  TPvalues: any = [];
  visibleIndices = new Set<number>();
  suggestedInternal: any = [];
  counterCall: number = 0
  showCloseOption: boolean = true
  rejectAllSuggested: boolean = false;
  matchesForFields: any = [];
  formValuesOnlyName: any = [];
  counterAI = 0;
  @Input() set columns(columns: Array<TemplateColumn>) {
    this._columns = columns;
    this._columnsConst = columns;
  };
  @Input() isParent = false
  @Input() draggable = false;
  /** String input for building a json path */
  @Input() jsonPathBaseFile$: Observable<string>;

  @Input() segmentList$: Array<any>;
  @Input() LoadSegmentPaths: () => void;


  @Input() LoadSyndic8FieldSuggestionsAction: () => void;
  @Input() LoadDestinationExportMappingAction: () => void;
  @Input() SetDestinationExportMappingEditingAction: ({ state }: { state: EditState }) => void;
  @Input() LoadDestinationExportInternalFieldSuggestionsAction: () => void;
  @Input() SaveDestinationExportMappingFieldsAction: ({ fields }: { fields: Array<DataProcessField> }) => void;
  @Input() LoadDestinationExportMappingTPFieldListSuggestionsAction: () => void;
  @Input() RemoveAllBrandTradingPartnerAlignmentsAction: ({ columnName, internalColumnName }: { columnName: string, internalColumnName: string }) => void;
  @Input() AddDestinationExportMappingTemplateRowAction: ({ index }: { index: number }) => void;
  @Input() AddOrSaveBrandTpAlignmentAction: ({ alignment }: { alignment: TradingPartnerAlignment }) => void;
  @Input() RemoveBrandTpAlignmentAction: ({ tradingPartnerBrandId, columnName, internalColumnName }: { tradingPartnerBrandId: number, columnName: string, internalColumnName: string }) => void;
  @Input() LoadBrandTpAlignmentsAction: ({ columnName, internalColumnName }: { columnName: string, internalColumnName: string }) => void;
  @Input() SaveMessagesAction: ({ type, table, block, key }: { type: string, table: string, block: string, key: string }) => void;
  @Input() SaveSheetOrderAction: ({ blockList }: { blockList }) => void;
  @Input() MoveRowAction: ({ currentIndex, previousIndex }: { currentIndex: number, previousIndex: number }) => void;

  @Input() templateInputType = 'CSV';
  @Input() templateOutputType = 'CSV';

  @Input() showMessages = false;
  @Input() showSheetOrder = false;
  @Input() showJsonPathModal = false;
  @Input() showEditField = false;
  /** Used to hide template rows, used for preflight to have function editing */
  @Input() showTemplateRows = true;
  /** Enables the field to field mapping on the function modal, off by default - used by preflight */
  @Input() showTemplateFieldMapping = false;
  @Input() highlightRequired = false;

  @Output() showMessagesChange: EventEmitter<boolean> = new EventEmitter();
  @Output() showSheetOrderChange: EventEmitter<boolean> = new EventEmitter();
  @Output() showEditFieldChange: EventEmitter<boolean> = new EventEmitter();
  @Output() showJsonPathChange: EventEmitter<boolean> = new EventEmitter();


  /** Below are functions and vars primarily related to filtering on the template screen */
  showFilterMenuClick = false;
  showFilterMenu: TemplateColumnType = null;

  /** Options for the Required drop down filter */
  requiredFilterOptions: Suggestion[] = [
    {
      name: "Required",
      id: "Required"
    },
    {
      name: "Recommended",
      id: "Recommended"
    },
    {
      name: "Conditional",
      id: "Conditional"
    }
  ];

  columnsFilterForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('',),
    field: new UntypedFormControl(''),
    required: new UntypedFormControl(''),
  });

  headerFilters: BehaviorSubject<FilterEntry[]> = new BehaviorSubject([]);

  addFilter(columnType: TemplateColumnType) {
    const control = this.columnsFilterForm.get([columnType]);
    /** Filter out old entries */
    let filters = this.headerFilters.getValue().filter((fe) => fe.filterBy !== columnType);
    if (columnType === TemplateColumnType.Required) {
      filters = [...filters, {
        filterBy: columnType,
        filterValue: control.value
      }]
    } else if (columnType === TemplateColumnType.Name || columnType === TemplateColumnType.Field) {
      filters = [...filters, {
        filterBy: columnType,
        filterValue: control.value
      }]
    }
    this.showFilterMenuClick = false;
    this.showFilterMenu = null;
    this.headerFilters.next(filters)
  }

  removeFilter(columnType: TemplateColumnType) {
    let filters = this.headerFilters.getValue().filter((fe) => fe.filterBy !== columnType);
    this.showFilterMenuClick = false;
    this.showFilterMenu = null;
    this.headerFilters.next(filters);

  }

  hasActiveFilter(columnType: TemplateColumnType) {
    return this.headerFilters.getValue().findIndex(f => f.filterBy === columnType) > -1;
  }

  getFieldId(field) {
    let fieldIndex = this.fieldsForm?.value.findIndex(a => a.name === field.name);
    return this.fieldsForm.at(fieldIndex);
  }

  /** End of Filtering Logic */

  _columns: Array<TemplateColumn> = [];
  _columnsConst: Array<TemplateColumn> = [];
  fieldsForm: UntypedFormArray = new UntypedFormArray([]);
  /** Used for adding field mapping to the function modal, used by preflight */

  fieldMappingForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, null),
    outputName: new UntypedFormControl(null, null),
    suggestedMatch: new UntypedFormControl(null, null),
    fieldStartMatch: new UntypedFormControl(null, null),
    defaultValue: new UntypedFormControl(null, null),
    dataType: new UntypedFormControl(null, null),
    dataFormat: new UntypedFormControl(null, null),
  });
  activeTemplateField: DataProcessField;
  showEditFunctionModal = false;
  showEditCommentModal = false;
  showEditExampleModal = false;
  showEditDefinitionModal = false;
  showValidValueModal = false;
  showRemoveRowModal = false;
  showRawFieldDisplay = false;
  editingValidValue = false;
  showSuggestedMatchesResult = false
  showButtonsAcceptReject = true
  showSpinner = false
  readOnly = false
  activeInternalColumnName: string;
  concatDelimiterForm: UntypedFormGroup = new UntypedFormGroup({
    concat: new UntypedFormControl(FunctionType.ConcatDelimiter, [Validators.required]),
    delimiter: new UntypedFormControl('', [Validators.required]),
    index: new UntypedFormControl('')
  });
  languageForm: UntypedFormGroup = new UntypedFormGroup({
    currentLanguage: new UntypedFormControl('', [Validators.required]),
    secondaryLanguage: new UntypedFormControl('', [Validators.required]),
    useFallback: new UntypedFormControl(true, [Validators.required])
  });
  pricingForm: UntypedFormGroup = new UntypedFormGroup({
    outuputField: new UntypedFormControl('', [Validators.required]),
    outputCurrency: new UntypedFormControl('', [Validators.required]),
  });
  mathAddControl: FormControl<string> = new FormControl('', [Validators.required]);
  mathSubtractControl: FormControl<string> = new FormControl('', [Validators.required]);
  genericFunctionControl: FormControl<string> = new FormControl('', [Validators.required]);
  convertDisplayForm: UntypedFormGroup = new UntypedFormGroup({
  });
  convertDisplayFormList: Array<string> = [];
  activeRemoveRow: DataProcessField;
  activeRemoveRowIndex: number;
  minColumnWidths: Array<number> = [170, 110, 120, 168, 168, 168, 200];
  mouseDownColumnIndex: number = null;
  editCommentControl: UntypedFormControl = new UntypedFormControl('');
  editExampleControl: UntypedFormControl = new UntypedFormControl('');
  editDefinitionControl: UntypedFormControl = new UntypedFormControl('');
  jsonPathControlFormGroup = null;
  saveDisabledSelf = false;
  isXPath = false;
  selectedModalTab = 1;
  selectedModalTab_AI = 1;
  dataTypeSuggestions: Array<Suggestion> = [
    {
      id: 'String',
      name: 'String'
    },
    {
      id: 'Date',
      name: 'Date'
    },
    {
      id: 'Integer',
      name: 'Integer'
    },
    {
      id: 'Decimal',
      name: 'Decimal'
    },
    {
      id: 'ExcelImage',
      name: 'Excel Image'
    },
    {
      id: 'ExcelBoolean',
      name: 'Excel Boolean'
    },
    {
      id: 'ExcelFormula',
      name: 'Excel Formula'
    },
    {
      id: 'ExcelHyperlink',
      name: 'Excel Hyperlink'
    },
    {
      id: 'ExcelDate',
      name: 'Excel Date'
    },
    {
      id: 'ExcelDouble',
      name: 'Excel Double'
    }];
  functionSuggestions: Array<Suggestion> = [
    {
      id: FunctionType.Concat,
      name: 'Concatenation',
      description: 'Combine product fields, valid values, and free form text'
    },
    {
      id: FunctionType.ConcatDelimiter,
      name: 'Concatenation Delimiter',
      description: 'Combine product fields, valid values, and free form text separated by a specified delimiter, with no blanks'
    },
    {
      id: FunctionType.TodayDateTime,
      name: 'Current Date Time',
      description: 'Current Date Time'
    },
    {
      id: FunctionType.ValidValue,
      name: 'Valid Value',
      description: 'Transform your internal value to a Trading Partner valid value'
    },
    {
      id: FunctionType.Image,
      name: 'Image',
      description: 'Export image URL for the selected image tag'
    },
    {
      id: FunctionType.ImageThumbnail,
      name: 'Image Thumbnail',
      description: 'Export the image thumbnail for the selected image tag'
    },
    {
      id: FunctionType.ImageList,
      name: 'Image List',
      description: 'Export image URL for the first image tag in the selected list. If the image tag does not exist, the proceeding image tag will be used'
    },
    {
      id: FunctionType.ImageListRow,
      name: 'Image List Row',
      description: 'Export image URLs in a column based on the selected image tag order'
    },
    {
      id: FunctionType.ImageListPosition,
      name: 'Image List Position',
      description: 'Exports a position number based off of the image tag order. Requires use of Image List Row function'
    },
    {
      id: FunctionType.ImageMultiList,
      name: 'Image Multi-List',
      description: 'Export multiple image URLs bassed off of the image tag order'
    },
    {
      id: FunctionType.FormatMarketingDescription,
      name: 'Description Format',
      description: 'Select the format for the product description'
    },
    {
      id: FunctionType.ToLower,
      name: 'To Lower',
      description: 'Converts to lower case'
    },
    {
      id: FunctionType.ToUpper,
      name: 'To Upper',
      description: 'Converts to upper case'
    },
    {
      id: FunctionType.ToProper,
      name: 'To Proper',
      description: 'Converts to proper case'
    },
    {
      id: FunctionType.ReferenceTable,
      name: 'Reference Table',
      description: 'Returns rule based on the reference table'
    },
    {
      id: FunctionType.IfThenElse,
      name: 'If Then Else',
      description: 'Returns the then value when true otherwise the else value'
    },
    {
      id: FunctionType.Substr,
      name: 'Substr',
      description: 'Returns defined string positions'
    },
    {
      id: FunctionType.XMLPartyInfo,
      name: 'XML Party Info',
      description: 'Returns predefined party info xml format'
    },
    {
      id: FunctionType.XMLTypeValue,
      name: 'XML Type Value',
      description: 'Returns predefined type value xml format'
    },
    {
      id: FunctionType.XMLDimensionInfo,
      name: 'XML Dimension Info',
      description: 'Returns predefined Dimension Info xml format'
    },
    {
      id: FunctionType.XMLShipWindow,
      name: 'XML Ship Window',
      description: 'Returns predefined ship window xml format'
    },
    {
      id: FunctionType.XMLHarmonizedCodeInfo,
      name: 'XML Harmonized Code Info',
      description: 'Returns predefined Harmonized Code Info xml format'
    },
    {
      id: FunctionType.Replace,
      name: 'Replace',
      description: 'Returns value based on replace using regular expressions'
    },
    {
      id: FunctionType.MathAdd,
      name: 'Math Add',
      description: 'Adds your entered value'
    },
    {
      id: FunctionType.MathSubtract,
      name: 'Math Subtract',
      description: 'Subtracts your entered value'
    },
    {
      id: FunctionType.Function,
      name: 'Function',
      description: 'Generic function value'
    },
    {
      id: FunctionType.Language,
      name: 'Get Language',
      description: 'Retrieve language value'
    },
    {
      id: FunctionType.Pricing,
      name: 'Get Pricing',
      description: 'Generic pricing value'
    }
  ].sort((a, b) => (a.name > b.name) ? 1 : -1);
  marketingDescriptionFormatSuggestions: Array<Suggestion> = [
    {
      id: 'Bullets:Text',
      name: 'Bullets - Text'
    },
    {
      id: 'Bullets:Html',
      name: 'Bullets - HTML'
    },
    {
      id: 'Marketing:Text',
      name: 'Marketing Description - Text'
    },
    {
      id: 'Marketing:Html',
      name: 'Marketing Description - HTML'
    },
    {
      id: 'MarketingBullets:Text',
      name: 'Marketing Description & Bullets- Test'
    },
    {
      id: 'MarketingBullets:Html',
      name: 'Marketing Description & Bullets- HTML'
    }
  ];

  /* Used for the fuzzy search */
  previousIdx = 0;
  previousFieldName = ""

  templateFieldsSub: Subscription;
  runAiBotSub: Subscription;
  goBackToAISummaryValueSub: Subscription;

  editSub: Subscription;


  getFunctionValue = this.templateSvc.getFunctionValue.bind(this.templateSvc);
  getFunctionValueChips = this.templateSvc.getFunctionValueChips.bind(this.templateSvc);
  getConvert = this.templateSvc.getConvert.bind(this.templateSvc);
  setConvert = this.templateSvc.setConvert.bind(this.templateSvc);
  getFunctionValidValue = this.templateSvc.getFunctionValidValue.bind(this.templateSvc);
  caseFunctionSuggestions = this.templateSvc.caseFunctionSuggestions;
  convertValue = this.templateSvc.convertValue.bind(this.templateSvc);
  columnSelected = this.templateSvc.columnSelected.bind(this.templateSvc);

  ChipType = ChipType;
  EditState = EditState;
  FunctionType = FunctionType;
  TemplateViewAs = TemplateViewAs;
  TemplateColumnType = TemplateColumnType;


  @HostListener('document:mouseup')
  clickOutside() {
    //Reset cursor and mouseDownColumnIndex
    document.body.style.cursor = 'auto';
    this.mouseDownColumnIndex = null;
  }

  @HostListener('document:mousemove', ['$event'])
  mouseMove(event: MouseEvent) {
    if (this.mouseDownColumnIndex === null) {
      return;
    }

    document.body.style.cursor = 'col-resize';
    this._columns = this._columns.map((col, i) => {
      const width = col.width as string;
      if (
        i !== this.mouseDownColumnIndex ||
        col.width === 'auto' ||
        this._columnsConst.find(c => c.type === c.type)?.width > width + event.movementX
      ) {
        return col;
      }
      return {
        ...col,
        width: width + event.movementX
      };
    });
  }

  mouseDown(event: MouseEvent, columnIndex: number): void {
    if (event.button !== 0) {
      return;
    }
    event.preventDefault();
    this.mouseDownColumnIndex = columnIndex;
  }

  get gridTemplateColumns(): string {
    const trailingUnit = this._columns[this._columns?.length - 1]?.width === 'auto' ? '' : 'px';
    const widths: Array<string | number> = this._columns
      .filter(c => this.isEditing ? true : c.type !== TemplateColumnType.Action)
      .map(c => c.width);
    return `${this.isEditing ? '24px ' : ''}${widths.join('px ')}${trailingUnit}`;
  }

  getGridTemplateRows(fields: Array<DataProcessField>): string {
    return `34px ${new Array(fields.length + 1).join('58px ')}1fr`;
  }

  constructor(private store: Store, private templateSvc: TemplateService, private templateApi: TemplateApiService, private openAIService: OpenaiService, private orgApi: OrgApiService, private cdRef: ChangeDetectorRef, private zone: NgZone, private brandTpApi: BrandTpAlignmentApiService, private toast: ToastService) { }
activeFunctionTypeSub:Subscription
  ngOnInit(): void {
    // Not the biggest fan of doing this here, but we should only ever need this on inbound
    if (this.viewAs === TemplateViewAs.Syndic8Inbound || this.viewAs === TemplateViewAs.AllPortCargoServicesInbound) {
      this.dataTypeSuggestions.push(
        {
          id: 'String List',
          name: 'String List'
        }
      )
    }

    this.alignments$.subscribe(
      (alignments: any) => {
        console.log('alingments=>', alignments)
      })
    // Run AI bot auto field map
    this.runAiBotSub = this.runAiBot$.subscribe(
      (runAiBot: any) => {
        this.AiBotActive = runAiBot
        if (runAiBot == true && this.counterAI == 0) {
          this.runAiAutoField()
          this.clearStatus()
          this.showButtonsAcceptReject = true
          this.acceptedAllSuggested = false
          this.rejectAllSuggested = false
          this.counterAI++
        }
        else if (runAiBot == true && this.counterAI == 1) {
          this.showCircleProgress = true
          setTimeout(() => {
            this.showCircleProgress = false
            this.showAImatchSummery = true
          }, 1500);
        }

      })
    this.goBackToAISummaryValue$.subscribe(
      (goBackToAISummaryValue: any) => {
        if (goBackToAISummaryValue == true && this.showGoBackButton) {
          this.showEditFunctionModal = false;
          this.showAImatchSummery = true
          this.store.dispatch(new GobackToAISummary({ goBackToAISummary: false }));
        }
      })

    this.templateFieldsSub = this.templateFields$.subscribe(fields => this.setFieldsForm(fields));

    if (
      this.editState$ &&
      this.SetDestinationExportMappingEditingAction &&
      this.SaveDestinationExportMappingFieldsAction &&
      this.LoadDestinationExportMappingAction
    ) {

      // I should change the edit state to not be set as .NotEdit after a EditState gets triggered.
      this.editSub = this.editState$.subscribe(
        (editing: EditState) => {
          if (editing === EditState.Save) {
            this.store.dispatch([
              new this.SaveDestinationExportMappingFieldsAction({ fields: this.fieldsForm.value }),
              new this.SetDestinationExportMappingEditingAction({ state: EditState.Edit })
            ]);
          } else if (editing === EditState.Cancel) {
            this.store.dispatch([
              new this.SetDestinationExportMappingEditingAction({ state: EditState.NotEdit }),
              new this.LoadDestinationExportMappingAction()
            ]);
          }
        }
      );
    }

    if (this.LoadSyndic8FieldSuggestionsAction) {
      this.store.dispatch(new this.LoadSyndic8FieldSuggestionsAction());
    }
    if (this.LoadDestinationExportInternalFieldSuggestionsAction) {
      this.store.dispatch(new this.LoadDestinationExportInternalFieldSuggestionsAction());
    }
    this.store.dispatch(new this.LoadDestinationExportMappingAction());
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log('changes', changes)

  }

  rejectAllSuggestedFields() {
    this.rejectAllSuggested = true
    this.showButtonsAcceptReject = false

  }
  acceptSuggestedMatches() {
    // mapping the form to add the AI suggested values for each corresponding row/value
    this.showButtonsAcceptReject = false
    this.showSpinner = true
    for (let i = 0; i < this.fieldsForm.value?.length; i++) {
      let suggestion = this.matchesForFields?.find(a => a?.inputName == this.fieldsForm?.value[i]?.name);
      let fieldIndex = this.fieldsForm?.value.findIndex(a => a.name == suggestion?.inputName);
      if (suggestion?.id && fieldIndex !== -1) {
        let match = suggestion?.id
        if (match !== null && match !== '') {
          this.fieldsForm.value[fieldIndex].fieldStartMatch = match;
          this.fieldsForm.value[fieldIndex].suggestedMatch = true;
        }
      }
    }
    const fields = this.fieldsForm.value
    this.setFieldsForm(this.fieldsForm.value);
    // dispatching action to upload the template with the new AI suggested matches
    if (this.SaveDestinationExportMappingFieldsAction) {
      this.store.dispatch(new this.SaveDestinationExportMappingFieldsAction({ fields })).subscribe(
        (any: any) => {
          setTimeout(() => {
            this.showSpinner = false
            this.acceptedAllSuggested = true
          }, 1500);
        },
        (error) => {
          console.log('error', error)
          this.showSpinner = false
          this.showButtonsAcceptReject = true
        }
      );
    }
  }
  runAiAutoField() {
    let validValuePrueba;
    this.showCircleProgress = true
    // let's retrieve the valid values from the template
    for (let i = 0; i < this.fieldsForm.value?.length; i++) {
      // valid values matches
      validValuePrueba = this.templateSvc.getFunctionValidValue(this.fieldsForm.value[i])
      if (validValuePrueba) {
        if (validValuePrueba.includes("FUNC:") || validValuePrueba.includes("MAP:")) {
          // not a valid value
        } else {
          this.tradingPartnerFieldsSummeryAI.push(this.fieldsForm.value[i])
          let str = validValuePrueba;
          let result = str.split(":");
          this.VVmatches.push(result[1])
        }
      }
    }
    this.tradingPartnerFieldsSummeryAI = Array.from(this.tradingPartnerFieldsSummeryAI.reduce((m, t) => m.set(t.name, t), new Map()).values());
    this.VVmatches = this.VVmatches.filter((v, i) => this.VVmatches.findIndex(item => item == v) === i);

    setTimeout(() => {
      this.showCircleProgress = false
      this.showAImatchSummery = true
    }, 1500);

  }
  clearStatus() {
    for (let i = 0; i < this.tradingPartnerFieldsSummeryAI.length; i++) {
      this.visibleIndices.delete(i)
    }
  }
  getSuggestionsForAllFields() {
    this.formValuesOnlyName = []

    for (let i = 0; i < this.fieldsForm.value?.length; i++) {
      /** bringing all of the matches at once instead of multiple individual calls */
      this.formValuesOnlyName.push({ inputName: this.fieldsForm.value[i].name })

      /*
        /* Parses out fields that should not be auto matched.
        1) If it has a convert function dont match
        2) if it has a something mapped dont match
        3) if it has a function dont match

        The logic for this is borrowed from columnSelected
         */
      /*
     const convert = this.fieldsForm.value[i]?.convert;
     const fieldStartMatch = this.fieldsForm.value[i]?.fieldStartMatch;
     const defaultValue = this.fieldsForm.value[i]?.defaultValue;
     const hasMatch = this.columnSelected({ convert, fieldStartMatch, defaultValue })

     if (!hasMatch)
       this.formValuesOnlyName.push({ inputName: this.fieldsForm.value[i].name })
    */
    }

    const organizationn: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.templateApi.getSuggestionMatches(this.destination.destinations[0].id, organizationn.id, this.formValuesOnlyName).pipe(
      tap((matches: Array<SuggestedMatch>) => {
        for (let i = 0; i < matches.length; i++) {
          this.matchesForFields.push(matches[i])
          if (matches[i].inputName && matches[i].name !== "") {
            this.TPvalues.push(matches[i].inputName)
            console.log('matches=>', matches[i])
            this.suggestedInternal.push(matches[i])
          }
        }
        const searchString = 'COLUMNNAME:';
        this.suggestedInternal.forEach(obj => {
          if (obj.name.includes(searchString)) {
            obj.name = obj.name.replace(searchString, '');
          }
        });
        //console.log('suggested internal =>',this.suggestedInternal)
        //  sorting results (Sort by correlation from low to high)
        let sortSuggestions = this.suggestedInternal?.sort(({ resultValue: a }, { resultValue: b }) => a - b);
        let sorted = []
        sortSuggestions?.forEach(k => {
          let n = this.TPvalues.filter(obj => {
            return obj === k.inputName
          })
          if (n?.length > 0) {
            sorted?.push(n[0]);
          }
        })
        this.TPvalues = sorted
        this.showSuggestedMatchesResult = true
      }),
      catchError(error => {
        this.showSuggestedMatchesResult = true
        this.toast.add({
          expiration: 5000,
          title: 'Service Error',
          message: 'Unable to Load Suggested Matches',
          type: NotificationType.ERROR
        });
        console.log('error getSuggestionsForAllFields', error)
        return of(error);

      })
    ).subscribe();
    setTimeout(() => {
      //this.showSuggestedMatchesResult = true
    }, 7000);
  }
  closeAISummery() {
    this.counterSummery = this.counterSummery + 1
    this.showAImatchSummery = false
    this.store.dispatch(new RunAiBot({ runAiBot: false }));
  }
  ngOnDestroy(): void {
    this.templateFieldsSub.unsubscribe();
    if (this.editSub) {
      this.editSub.unsubscribe();
    }
  }

  /** If a field is not present here, it will be turned to the default value on save. Add it here if you need it to persist */
  private setFieldsForm(fields: Array<DataProcessField>): void {
    this.fieldsForm.clear();
    fields.forEach(({
      name,
      outputName,
      requiredDisplay,
      recommendedDisplay,
      minCharacterLength,
      maxCharacterLength,
      defaultValue,
      dataType,
      format,
      fieldStartMatch,
      convert,
      comments,
      example,
      definition,
      parentOverrideField,
      suggestedMatch,
      skipXmlConversion,
      xmlAttribute,

      // These fields are just being passed through for editing purposes
      aiGenerated,
      aiPercent,
      aliasName,
      columnOutput,
      columnWidth,
      // comments,
      // convert,
      // dataType,
      // defaultValue,
      // definition,
      // example,
      fieldEnd,
      fieldEndMatch,
      fieldEndOperator,
      fieldStart,
      // fieldStartMatch,
      fieldStartOperator,
      // format,
      inactive,
      lineNumber,
      lineOffset,
      matchLine,
      matchOperator,
      // maxCharacterLength,
      // minCharacterLength,
      // name,
      newField,
      // outputName,
      pageNumber,
      parentName,
      // parentOverrideField,
      // recommendedDisplay,
      required,
      requiredBrandDisplay,
      requiredConditionalDisplay,
      // requiredDisplay,
      reqularExpression,
      rowHeight,
      saveEmpty,
      rowOutput,
      skipTrim,
      // skipXmlConversion,
      stackSize,
      // suggestedMatch,
      validate,
      // xmlAttribute
    }) => {
      this.fieldsForm.push(new UntypedFormGroup({
        name: new UntypedFormControl(name),
        outputName: new UntypedFormControl(outputName),
        requiredDisplay: new UntypedFormControl(requiredDisplay),
        recommendedDisplay: new UntypedFormControl(recommendedDisplay),
        minCharacterLength: new UntypedFormControl(minCharacterLength),
        maxCharacterLength: new UntypedFormControl(maxCharacterLength),
        defaultValue: new UntypedFormControl(defaultValue),
        dataType: new UntypedFormControl(dataType),
        format: new UntypedFormControl(format),
        fieldStartMatch: new UntypedFormControl(fieldStartMatch),
        convert: new UntypedFormArray(convert && convert.length > 0 ? convert.map(c => new UntypedFormControl(c)) : []),
        comments: new UntypedFormControl(comments),
        example: new UntypedFormControl(example),
        definition: new UntypedFormControl(definition),
        parentOverrideField: new UntypedFormControl(parentOverrideField),
        suggestedMatch: new UntypedFormControl(suggestedMatch),
        skipXmlConversion: new UntypedFormControl(skipXmlConversion),
        xmlAttribute: new UntypedFormControl(xmlAttribute),
        // These fields are just being passed through for editing purposes
        aiGenerated: new UntypedFormControl(aiGenerated),
        aiPercent: new UntypedFormControl(aiPercent),
        aliasName: new UntypedFormArray(aliasName && aliasName.length > 0 ? aliasName.map(c => new UntypedFormControl(c)) : []),
        columnOutput: new UntypedFormControl(columnOutput),
        columnWidth: new UntypedFormControl(columnWidth),
        fieldEnd: new UntypedFormControl(fieldEnd),
        fieldEndMatch: new UntypedFormControl(fieldEndMatch),
        fieldEndOperator: new UntypedFormControl(fieldEndOperator),
        fieldStart: new UntypedFormControl(fieldStart),
        fieldStartOperator: new UntypedFormControl(fieldStartOperator),
        inactive: new UntypedFormControl(inactive),
        lineNumber: new UntypedFormControl(lineNumber),
        lineOffset: new UntypedFormControl(lineOffset),
        matchLine: new UntypedFormControl(matchLine),
        matchOperator: new UntypedFormControl(matchOperator),
        newField: new UntypedFormControl(newField),
        pageNumber: new UntypedFormControl(pageNumber),
        parentName: new UntypedFormControl(parentName),
        required: new UntypedFormControl(required),
        requiredBrandDisplay: new UntypedFormControl(requiredBrandDisplay),
        requiredConditionalDisplay: new UntypedFormControl(requiredConditionalDisplay),
        reqularExpression: new UntypedFormControl(reqularExpression),
        rowHeight: new UntypedFormControl(rowHeight),
        saveEmpty: new UntypedFormControl(saveEmpty),
        rowOutput: new UntypedFormControl(rowOutput),
        skipTrim: new UntypedFormControl(skipTrim),
        stackSize: new UntypedFormControl(stackSize),
        validate: new UntypedFormArray(validate && validate.length > 0 ? validate.map(c => new UntypedFormControl(c)) : []),
      }));
    });
  }

  /** Used by preflight to enable field to field mapping on the function modal */
  editFieldMappingForm(field: DataProcessField): void {
    this.fieldMappingForm.reset();
    this.fieldMappingForm.setValue(
      {
        name: field.name,
        outputName: field.outputName,
        suggestedMatch: field.suggestedMatch,
        fieldStartMatch: field.fieldStartMatch,
        defaultValue: field.defaultValue,
        dataType: field.dataType,
        dataFormat: field.format,
      });
  }

  goBack() {
    this.showEditFunctionModal = false;
    this.showAImatchSummery = true
  }

  editFunction(field: DataProcessField, showGoBackButton?: any, index?: any): void {

    this.activeTemplateField = cloneDeep(field);
    if (field?.convert?.length > 0 && field.convert[0].includes('FUNC:VALIDVALUE')) {
      this.readOnly = true
      this.showCloseOption = false
      this.visibleIndices.add(index);
      if (showGoBackButton) {
        this.showGoBackButton = true
      }
      this.autoAImatchedValue = this.templateSvc.getFunctionValidValue(this.activeTemplateField)


      let validValuePrueba;
      for (let i = 0; i < this.fieldsForm.value?.length; i++) {
        validValuePrueba = this.templateSvc.getFunctionValidValue(this.fieldsForm.value[i])
      }
      this.validValueOptionSelected(this.templateSvc.getFunctionValidValue(this.activeTemplateField));
    } else {
      this.readOnly = false
      this.showCloseOption = true
    }
    this.showEditFunctionModal = true;
    if (this.activeFunctionType === FunctionType.ValidValue && this.templateSvc.getFunctionValidValue(this.activeTemplateField)) {
      console.log('hello',this.activeTemplateField)
      this.validValueOptionSelected(this.templateSvc.getFunctionValidValue(this.activeTemplateField));
      this.autoAImatchedValue = this.templateSvc.getFunctionValidValue(this.activeTemplateField);

    }
    if (this.activeFunctionType === FunctionType.ValidValue || this.activeFunctionType === FunctionType.Concat) {
      console.log('hello',this.activeTemplateField)
      this.store.dispatch(new this.LoadDestinationExportInternalFieldSuggestionsAction());
      this.store.dispatch(new this.LoadDestinationExportMappingTPFieldListSuggestionsAction());
    }
    if (this.activeFunctionType === FunctionType.MathAdd) {
      this.mathAddControl.setValue(this.templateSvc.getFunctionValue(this.activeTemplateField, FunctionType.MathAdd));
    }
    if (this.activeFunctionType === FunctionType.MathSubtract) {
      this.mathSubtractControl.setValue(this.templateSvc.getFunctionValue(this.activeTemplateField, FunctionType.MathSubtract));
    }
    if (this.activeFunctionType === FunctionType.Function) {
      this.genericFunctionControl.setValue(this.templateSvc.getFunctionValue(this.activeTemplateField, FunctionType.Function));
    }
    if (this.activeFunctionType === FunctionType.Substr) {
      this.genericFunctionControl.setValue(this.templateSvc.getFunctionValue(this.activeTemplateField, FunctionType.Substr));
    }
    if (this.activeFunctionType === FunctionType.Replace) {
      this.genericFunctionControl.setValue(this.templateSvc.getFunctionValue(this.activeTemplateField, FunctionType.Replace));
    }
    if (this.activeFunctionType === FunctionType.XMLPartyInfo) {
      this.genericFunctionControl.setValue(this.templateSvc.getFunctionValue(this.activeTemplateField, FunctionType.XMLPartyInfo));
    }
    if (this.activeFunctionType === FunctionType.XMLTypeValue) {
      this.genericFunctionControl.setValue(this.templateSvc.getFunctionValue(this.activeTemplateField, FunctionType.XMLTypeValue));
    }
    if (this.activeFunctionType === FunctionType.XMLDimensionInfo) {
      this.genericFunctionControl.setValue(this.templateSvc.getFunctionValue(this.activeTemplateField, FunctionType.XMLDimensionInfo));
    }

    // this.genericFunctionControl.setValue(this.activeTemplateField.convert);

    /*
        concat: new UntypedFormControl(FunctionType.ConcatDelimiter, [Validators.required]),
      delimiter: new UntypedFormControl('', [Validators.required]),
      index: new UntypedFormControl('')
     */
    // this.convertDisplayForm.
    const convertSplit: Array<String> = this.activeTemplateField.convert;
    this.convertDisplayFormList = [];
    if (convertSplit) {
      convertSplit.forEach((value, index) => {
        const t = new UntypedFormControl();
        t.setValue(value);
        this.convertDisplayForm.addControl(value.toString(), t);
        this.convertDisplayFormList.push(value.toString())
      });
    }

    //if (this.activeFunctionType === FunctionType.XMLShipWindow) {
    //  this.genericFunctionControl.setValue(this.templateSvc.getFunctionValue(this.activeTemplateField, FunctionType.XMLShipWindow));
    //}
    //if (this.activeFunctionType === FunctionType.XMLHarmonizedCodeInfo) {
    //  this.genericFunctionControl.setValue(this.templateSvc.getFunctionValue(this.activeTemplateField, FunctionType.XMLHarmonizedCodeInfo));
    //}
  }

  editComment(field: DataProcessField): void {
    this.activeTemplateField = cloneDeep(field);
    if (field.comments?.length > 0) {
      this.editCommentControl.setValue(field.comments);
    } else {
      this.editCommentControl.reset();
    }
    this.showEditCommentModal = true;
  }

  saveComment(): void {
    const t = this.fieldsForm;
    const comments: string = this.editCommentControl.value;
    const index = this.fieldsForm.value.findIndex(field => field.name === this.activeTemplateField.name);
    if (index > -1) {
      const control = this.fieldsForm.at(index).get('comments');
      control.markAsDirty();
      control.setValue(comments);
    }
    this.showEditCommentModal = false;
  }

  editExample(field: DataProcessField): void {
    this.activeTemplateField = cloneDeep(field);
    //console.log("editExample ", this.activeTemplateField)

    if (field.example?.length > 0) {
      this.editExampleControl.setValue(field.example);
    } else {
      this.editExampleControl.reset();
    }
    this.showEditExampleModal = true;
  }

  saveExample(): void {
    const example: string = this.editExampleControl.value;
    const index = this.fieldsForm.value.findIndex(field => field.name === this.activeTemplateField.name);
    if (index > -1) {
      const control = this.fieldsForm.at(index).get('example');
      control.markAsDirty();
      control.setValue(example);
    }
    this.showEditExampleModal = false;
  }

  editDefinition(field: DataProcessField): void {
    this.activeTemplateField = cloneDeep(field);
    //console.log("editDefinition ", this.activeTemplateField)

    if (field.example?.length > 0) {
      this.editDefinitionControl.setValue(field.definition);
    } else {
      this.editDefinitionControl.reset();
    }
    this.showEditDefinitionModal = true;
  }

  saveDefinition(): void {
    const example: string = this.editDefinitionControl.value;
    const index = this.fieldsForm.value.findIndex(field => field.name === this.activeTemplateField.name);
    if (index > -1) {
      const control = this.fieldsForm.at(index).get('definition');
      control.markAsDirty();
      control.setValue(example);
    }
    this.showEditDefinitionModal = false;
  }

  loadOnce = true;
  editJsonPath(field: DataProcessField): void {
    if (this.loadOnce) {
      this.loadOnce = false;
      if (this.LoadSegmentPaths)
        this.store.dispatch(new this.LoadSegmentPaths());
    }

    this.activeTemplateField = cloneDeep(field);
    console.log("editJsonPath ", this.activeTemplateField)
    const index = this.fieldsForm.value.findIndex(field => field.name === this.activeTemplateField.name);
    if (index > -1) {
      const control = this.fieldsForm.at(index);
      this.jsonPathControlFormGroup = control;
    }
    this.showJsonPathModal = true;
  }

  saveJsonPath(path): void {
    console.log("Saving JSON Path", path.path)
    const val = path.path;
    const index = this.fieldsForm.value.findIndex(field => field.name === this.activeTemplateField.name);
    if (index > -1) {
      const control = this.fieldsForm.at(index).get('fieldStartMatch');
      control.markAsDirty();
      control.setValue('JSONPATH:' + val);
    }

    if (this.SaveDestinationExportMappingFieldsAction) {
      this.store.dispatch(new this.SaveDestinationExportMappingFieldsAction({ fields: this.fieldsForm.value })).subscribe((x) => {
      });
    }

    this.showJsonPathModal = false;
  }

  saveLanguageMapFunc(path) {
    console.log("saveLanguageMapFunc", path)

    const val = path.path;
    const index = this.fieldsForm.value.findIndex(field => field.name === this.activeTemplateField.name);
    if (index > -1) {
      // convert: new UntypedFormArray(convert && convert.length > 0 ? convert.map(c => new UntypedFormControl(c)) : []),

      // @ts-ignore
      const wut: FormArray = this.fieldsForm.at(index).get('convert');
      wut.clear()
      wut.push(new UntypedFormControl(val))

      if (this.SaveDestinationExportMappingFieldsAction) {
        this.store.dispatch(new this.SaveDestinationExportMappingFieldsAction({ fields: this.fieldsForm.value })).subscribe((x) => {
        });
      }
    }
    this.showJsonPathModal = false;
  }

  editField(field: DataProcessField) {
    this.activeTemplateField = cloneDeep(field);
    console.log("editFieldBlock ", this.activeTemplateField)
    this.editFieldModal?.setActiveField(field);
    this.showEditField = true;
  }

  saveEditField(field: DataProcessField) {
    console.log("saveEditField=>", field);

    const fields: Array<DataProcessField> = this.fieldsForm.value.map(f => {
      return f.name === field.name ? ({ ...f, ...field }) : f;
    });

    this.setFieldsForm(fields);
    if (this.SaveDestinationExportMappingFieldsAction) {
      this.store.dispatch(new this.SaveDestinationExportMappingFieldsAction({ fields: this.fieldsForm.value })).subscribe((x) => {
      });
    }
    this.showEditField = false;
  }

  toggleJsonPathModal(toggle) {
    this.showJsonPathModal = toggle;
  }

  toggleEditField(toggle) {
    this.showEditField = toggle;
  }

  validValueOptionSelected(suggestion: string): void {
    if (this.autoAImatchedValue) {

      // we do nothing
    } else {
      this.autoAImatchedValue = suggestion

    }
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${FunctionType.ValidValue}$D{${this.activeTemplateField.fieldStartMatch}}`]
    };
    console.log("vv " + this.activeTemplateField)
    this.activeInternalColumnName = suggestion;

  }

  /* AI Prototype - Function modal - upon selecting valid values function run automatch to offer a suggested internal field pairing */
  runAutoFieldMatchesAI() {
    const fieldObject = {
      id: this.activeTemplateField.name,
      inputName: this.activeTemplateField.name,
      name: this.activeTemplateField.name
    }
    const organization: Organization = this.store.selectSnapshot(OrganizationState.getOrganization);
    this.templateApi.getSuggestionMatches(this.destination.destinations[0].id, organization.id, [fieldObject]).pipe(
      tap((matches: Array<SuggestedMatch>) => {
        if (matches?.length > 0 && matches[0]?.id) {
          this.saveDisabledSelf = false
          this.validValueOptionSelected(matches[0]?.name)
          this.showValidValue = true
        } else {
          if (this.activeTemplateField.name === "" || this.activeTemplateField.name === '' || !this.activeTemplateField.name) {
            if (this.activeTemplateField.fieldStartMatch.startsWith('COLUMNNAME:')) {
              this.autoAImatchedValue = this.templateSvc.getFunctionValidValue(this.activeTemplateField);
              this.saveDisabledSelf = false
              this.showValidValue = true
            }
          } else {
          }
          this.showValidValue = true
        }
      }),
      catchError(error => {
        return of(error);
      })
    ).subscribe();
  }

  concatValidValueOptionSelected(suggestion: string): void {
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [FunctionType.Concat]
    };
    console.log("cv " + this.activeTemplateField)
    this.activeInternalColumnName = suggestion;
  }

  saveConvert(): void {
    const convert = []
    this.convertDisplayFormList.forEach((val) => {
      const t = this.convertDisplayForm.get(val);
      convert.push(t.getRawValue());
      console.log(t.getRawValue());
    });
    console.log('hello',this.activeTemplateField)
    this.activeTemplateField.convert = convert;
    console.log('hello',this.activeTemplateField)

    const fields: Array<DataProcessField> = this.fieldsForm.value.map(f => {
      return f.name === this.activeTemplateField.name ? ({ ...f, ...this.activeTemplateField }) : f;
    });

    if (this.SaveDestinationExportMappingFieldsAction) {
      this.store.dispatch(new this.SaveDestinationExportMappingFieldsAction({ fields }))
    }
    this.closeEditFunctionModal();
  }

  /** Gets the FieldStartMatch value from the form */
  selectedFieldStartMatch(): string {
    const { value } = this.fieldMappingForm.get('fieldStartMatch');
    return value === 'COLUMNNAME:NOCOLUMN' ? '' : value;
  }

  saveFunction(): void {
    // this.activeTemplateField.convert[0] = this.activeTemplateField.fieldStartMatch
    /** Saving if you are accessing from preflight */
    if (this.showTemplateFieldMapping && this.activeTemplateField) {
      // this.activeTemplateField.fieldStartMatch = this.fieldMappingForm.get("fieldStartMatch");
      const value = this.selectedFieldStartMatch();
      // const outputName = this.fieldMappingForm.get('outputName').value;
      const defaultValue = this.fieldMappingForm.get('defaultValue').value;
      const dataType = this.fieldMappingForm.get('dataType').value;
      const dataFormat = this.fieldMappingForm.get('dataFormat').value;
      // this.activeTemplateField.outputName = outputName;
      this.activeTemplateField.fieldStartMatch = value;
      this.activeTemplateField.defaultValue = defaultValue;
      this.activeTemplateField.dataType = dataType;
      this.activeTemplateField.format = dataFormat;
      return
    }

    const fields: Array<DataProcessField> = this.fieldsForm.value.map(f => {
      return f.name === this.activeTemplateField.name ? ({ ...f, ...this.activeTemplateField }) : f;
    });

    this.setFieldsForm(fields);
    if (this.SaveDestinationExportMappingFieldsAction) {
      this.store.dispatch(new this.SaveDestinationExportMappingFieldsAction({ fields }))
    }
    this.closeEditFunctionModal();

  }
  addItem(event) {
    //
  }

  clearFunction(): void {
    const finish: () => void = () => {
      const fields: Array<DataProcessField> = this.fieldsForm.value.map(field => {
        if (field.name === this.activeTemplateField.name) {
          return {
            ...field,
            convert: [],
            fieldStartMatch: 'COLUMNNAME:NOCOLUMN'
          };
        }
        return field;
      });
      this.setFieldsForm(fields);
      if (this.SaveDestinationExportMappingFieldsAction) {
        this.store.dispatch(new this.SaveDestinationExportMappingFieldsAction({ fields }))
      }
      this.closeEditFunctionModal();
    };

    const columnName: string = this.activeTemplateField?.name;
    const internalColumnName: string = this.activeInternalColumnName;
    if (columnName && internalColumnName) {
      this.store.dispatch(new this.RemoveAllBrandTradingPartnerAlignmentsAction({ columnName, internalColumnName }));
    }
    finish();
  }

  concatOptionSelected(suggestion: string | Suggestion): void {
    if (this.activeTemplateField?.convert?.length > 0 && this.activeTemplateField.convert[0].startsWith(FunctionType.Concat)) {
      this.activeTemplateField = {
        ...this.activeTemplateField,
        convert: [`${this.activeTemplateField.convert[0]}${this.templateSvc.convertValue(this.isXPath, suggestion)}`, ...(this.activeCaseFunction ? [this.activeCaseFunction] : [])]
      };
    } else {
      this.activeTemplateField = {
        ...this.activeTemplateField,
        convert: [`${FunctionType.Concat}${this.templateSvc.convertValue(this.isXPath, suggestion)}`, ...(this.activeCaseFunction ? [this.activeCaseFunction] : [])]
      };
    }
  }

  caseFunctionSelected(suggestion: Suggestion): void {
    if (!this.activeCaseFunction && this.activeTemplateField?.convert) {
      this.activeTemplateField = {
        ...this.activeTemplateField,
        convert: [...this.activeTemplateField.convert, suggestion.id]
      };
      console.log("caseFunctionSelected ", this.activeTemplateField)
    }
  }

  caseFunctionClosed(suggestion: Suggestion): void {
    const index = this.activeTemplateField?.convert.indexOf(suggestion?.id as FunctionType)
    if (index > -1) {
      this.activeTemplateField = {
        ...this.activeTemplateField,
        convert: [...this.activeTemplateField.convert.slice(0, index), ...this.activeTemplateField.convert.slice(index + 1)]
      };
      console.log("caseFunctionClosed ", this.activeTemplateField)
    }
  }

  mathAddValueChange(): void {
    const { value, invalid } = this.mathAddControl;
    if (invalid) {
      return;
    }
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${FunctionType.MathAdd}${value}`]
    };
    console.log("mathAddValueChange ", this.activeTemplateField)
  }

  saveLanguageFunction(){
    if(this.languageForm.invalid){
      return
    }
    const currentLanguage = this.languageForm?.value?.currentLanguage
    const secondaryLanguage = this.languageForm.value.secondaryLanguage
    const defaultToCurret = this.languageForm.value.useFallback
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${FunctionType.Language}${currentLanguage};${secondaryLanguage};${defaultToCurret}`]
    };
  }
  savePrincingFunction(){
    if(this.pricingForm.invalid){
      return
    }
    const outuputField = this.pricingForm?.value?.outuputField
    const outputCurrency = this.pricingForm.value.outputCurrency
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${FunctionType.Pricing}${outuputField};${outputCurrency}`]
    };
  }

  mathSubtractValueChange(): void {
    const { value, invalid } = this.mathSubtractControl;
    if (invalid) {
      return;
    }
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${FunctionType.MathSubtract}${value}`]
    };
    console.log("mathSubtractValueChange ", this.activeTemplateField)
  }

  substrValueChange(): void {
    const { value, invalid } = this.genericFunctionControl;
    if (invalid) {
      return;
    }
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${FunctionType.Substr}${value}`]
    };
    console.log("substrValueChange ", this.activeTemplateField)
  }

  replaceValueChange(): void {
    const { value, invalid } = this.genericFunctionControl;
    if (invalid) {
      return;
    }
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${FunctionType.Replace}${value}`]
    };
    console.log("replaceValueChange ", this.activeTemplateField)
  }

  xmlPartyInfoValueChange(): void {
    const { value, invalid } = this.genericFunctionControl;
    if (invalid) {
      return;
    }
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${FunctionType.XMLPartyInfo}${value}`]
    };
    console.log("xmlPartyInfoValueChange ", this.activeTemplateField)
  }

  xmlTypeValueValueChange(): void {
    const { value, invalid } = this.genericFunctionControl;
    if (invalid) {
      return;
    }
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${FunctionType.XMLTypeValue}${value}`]
    };
    console.log("xmlTypeValueValueChange ", this.activeTemplateField)
  }

  xmlDimensionInfoValueChange(): void {
    const { value, invalid } = this.genericFunctionControl;
    if (invalid) {
      return;
    }
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${FunctionType.XMLDimensionInfo}${value}`]
    };
    console.log("xmlDimensionInfoValueChange ", this.activeTemplateField)
  }

  xmlShipWindowValueChange(): void {
    const { value, invalid } = this.genericFunctionControl;
    if (invalid) {
      return;
    }
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${FunctionType.XMLShipWindow}${value}`]
    };
    console.log("xmlShipWindowValueChange ", this.activeTemplateField)
  }

  xmlHarmonizedCodeInfoValueChange(): void {
    const { value, invalid } = this.genericFunctionControl;
    if (invalid) {
      return;
    }
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${FunctionType.XMLHarmonizedCodeInfo}${value}`]
    };
    console.log("xmlHarmonizedCodeInfoValueChange ", this.activeTemplateField)
  }

  genericFunctionValueChange(): void {
    const { value, invalid } = this.genericFunctionControl;
    if (invalid) {
      return;
    }
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${FunctionType.Function}${value}`]
    };
    console.log("genericFunctionValueChange ", this.activeTemplateField)
  }

  concatXPathDelimiterOptionSelected(value: string): void {
    this.concatDelimiterOptionSelected(`$X{${value}}`);
  }

  concatDelimiterOptionSelected(suggestion: string | Suggestion): void {
    this.concatDelimiterForm.patchValue({ concat: `${this.concatDelimiterForm.value.concat}${this.templateSvc.convertValue(this.isXPath, suggestion)}` });
    this.updateConcatDelimiter();
  }

  updateConcatDelimiter(c?: string): void {
    if (c) {
      this.concatDelimiterForm.patchValue({ concat: c });
    }
    const { concat, delimiter, index } = this.concatDelimiterForm.value;
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${concat}${delimiter ? `::${delimiter}` : ''}${index ? `::${index}` : ''}`]
    };
    console.log("updateConcatDelimiter ", this.activeTemplateField)
  }

  imageOptionSelected(suggestion: Suggestion): void {
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${this.activeFunctionType}${suggestion.id}`]
    };
    console.log("imageOptionSelected ", this.activeTemplateField)
  }

  imageListOptionSelected(suggestion: Suggestion): void {
    if (this.activeTemplateField.convert[0] === this.activeFunctionType) {
      this.activeTemplateField = {
        ...this.activeTemplateField,
        convert: [`${this.activeFunctionType}${suggestion.id}`]
      };
      console.log("imageListOptionSelected ", this.activeTemplateField)
    } else {
      this.activeTemplateField = {
        ...this.activeTemplateField,
        convert: [`${this.activeTemplateField.convert[0]},${suggestion.id}`]
      };
      console.log("imageListOptionSelected2 ", this.activeTemplateField)
    }
  }

  removeActiveImageListChip(segment: Chip): void {
    const chips: Array<string> = this.activeFunction.replace(this.activeFunctionType, '').split(',');
    const index = chips.indexOf(segment.value);
    if (index > -1) {
      this.activeTemplateField = {
        ...this.activeTemplateField,
        convert: [`${this.activeFunctionType}${[...chips.slice(0, index), ...chips.slice(index + 1)].join(',')}`]
      };
      console.log("removeActiveImageListChip ", this.activeTemplateField)
    }
  }

  get activeFunctionSuggestion(): Suggestion {
    return this.functionSuggestions.find(s => s.id === this.activeFunctionType);
  }

  get activeFunctionType(): FunctionType {
    return this.templateSvc.getFunctionType(this.activeTemplateField);
  }

  get activeCaseFunction(): FunctionType {
    if (this.activeTemplateField?.convert?.length > 1) {
      const caseFunctions = this.caseFunctionSuggestions.map(s => s.id as FunctionType);
      for (const key in caseFunctions) {
        if (this.activeTemplateField.convert[1].startsWith(caseFunctions[key])) {
          return caseFunctions[key];
        }
      }
    }
  }

  get activeFunction(): string {
    return this.templateSvc.getConvert(this.activeTemplateField);
  }

  set activeFunction(func: string) {
    this.activeTemplateField.convert[0] = func;
  }

  get saveDisabled(): boolean {
    if (this.activeFunction) {
      if (this.activeFunctionType === FunctionType.ValidValue) {
        return false;
      }
      if (this.activeFunctionType === FunctionType.ImageListPosition) {
        return false;
      }
      if ((this.activeFunctionType === FunctionType.TodayDateTime) ||
        (this.activeFunctionType === FunctionType.ToLower) ||
        (this.activeFunctionType === FunctionType.ToUpper) ||
        (this.activeFunctionType === FunctionType.Language) ||
        (this.activeFunctionType === FunctionType.ToProper)) {
        return false;
      }
      if (this.activeFunction?.replace(this.activeFunctionType, '').length === 0) {
        return true;
      }
      if (this.activeFunctionType === FunctionType.ConcatDelimiter && this.concatDelimiterForm.invalid || this.concatDelimiterForm.value.concat.replace(this.activeFunctionType, '').length === 0) {
        return true;
      }
      if (this.activeFunctionType === FunctionType.MathAdd && this.mathAddControl.invalid) {
        return true;
      }
      if (this.activeFunctionType === FunctionType.MathSubtract && this.mathSubtractControl.invalid) {
        return true;
      }
      if (this.activeFunctionType === FunctionType.Function && this.genericFunctionControl.invalid) {
        return true;
      }
      if (this.activeFunctionType === FunctionType.Substr && this.genericFunctionControl.invalid) {
        return true;
      }
    }
    return false;
  }

  setupFunction(functionPrefix: string): void {
    // console.log('heeejjeje')
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [functionPrefix]
    };
    console.log("setupFunction ", this.activeTemplateField)

    if (functionPrefix === FunctionType.ValidValue || functionPrefix === FunctionType.Concat) {
      this.store.dispatch(new this.LoadDestinationExportInternalFieldSuggestionsAction());
      this.store.dispatch(new this.LoadDestinationExportMappingTPFieldListSuggestionsAction());
      if (functionPrefix === FunctionType.ValidValue) {
        this.runAutoFieldMatchesAI()
        //console.log("running AI bot");
      }
    }
  }

  closeEditFunctionModal(): void {
    console.log("closeEditFunctionModal ",)
    this.mathAddControl.reset();
    this.mathSubtractControl.reset();
    this.genericFunctionControl.reset();
    this.activeTemplateField = null;
    this.activeInternalColumnName = null;
    this.autoAImatchedValue = null
    this.showEditFunctionModal = false;
    this.editingValidValue = false;
    this.saveDisabledSelf = false;
    this.isXPath = false;
  }

  hideRawFieldDisplay(): void {
    this.showRawFieldDisplay = !this.showRawFieldDisplay;
  }

  descriptionTypeOptionSelected(suggestion: string) {

    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [`${FunctionType.FormatMarketingDescription}${suggestion}`]
    };
    console.log("descriptionTypeOptionSelected ", this.activeTemplateField)

  }

  trackByFn(index: number, field: DataProcessField): any {
    return field.name;
  }

  addValidValueToConcat(): void {
    this.activeTemplateField = {
      ...this.activeTemplateField,
      convert: [this.activeTemplateField.convert[0] + `VALIDVALUE:$D{${this.activeInternalColumnName}}`]
    };
    console.log("addValidValueToConcat ", this.activeTemplateField)

    this.showValidValueModal = false;
  }

  chipClick(chip: Chip): void {
    if (chip && chip.value) {
      if (chip.value.substring(0, 11) === 'VALIDVALUE:') {
        this.activeInternalColumnName = chip.value.substring(11, chip.value.length);
        this.editingValidValue = true;
        // this.showEditFunctionModal = false;
        this.showValidValueModal = true;
      }
    }
  }

  insertTemplateRow(index: number): void {
    this.store.dispatch(new this.AddDestinationExportMappingTemplateRowAction({ index })).subscribe(() => this.saveIfWithoutEditState())
  }

  removeRow(): void {
    const fields: Array<DataProcessField> = [
      ...this.fieldsForm.value.slice(0, this.activeRemoveRowIndex),
      ...this.fieldsForm.value.slice(this.activeRemoveRowIndex + 1)
    ];
    this.setFieldsForm(fields);
    this.saveIfWithoutEditState();
    this.closeRemoveRowModal();
  }
  hover(element) {
    const el: HTMLElement = document.getElementById('my-img');
    el.setAttribute('src', 'https://cdn-icons-png.flaticon.com/512/1035/1035633.png');
  }

  unhover(element) {
    const el: HTMLElement = document.getElementById('my-img');
    el.setAttribute('src', 'https://cdn-icons-png.flaticon.com/512/1035/1035552.png');
  }

  rowBlurred() {
    if (this.viewAs === TemplateViewAs.Syndic8) {
      return;
    }
    if (this.SaveDestinationExportMappingFieldsAction && this.fieldsForm.dirty) {
      /** Mark the form as pristine to reduce the amount of save actions sent */
      this.fieldsForm.markAsPristine();
      this.store.dispatch(new this.SaveDestinationExportMappingFieldsAction({ fields: this.fieldsForm.value })).subscribe((x) => {
      });
    }
  }

  /** Scrolls to the next matching inbound field */
  scrollToField(fieldName: string): void {
    let newSearch = true;
    let idx = -1;

    let templateFields = []
    this.templateFields$.subscribe((fields) => {
      templateFields = [...fields]
    });

    if (fieldName === this.previousFieldName) {
      newSearch = false;
    }

    // If this is a new search, We start with the entire field list.
    if (newSearch) {
      idx = templateFields.findIndex((field) => {
        return field.name.toLowerCase().includes(fieldName.toLowerCase());
      })
    } else {
      // If it is a continuation of the previous search slice the fields starting from the previous idx
      let tfSliced = [...templateFields.slice(this.previousIdx + 1)]
      idx = tfSliced.findIndex((field) => {
        return field.name.toLowerCase().includes(fieldName.toLowerCase());
      })

      // Restart the search
      if (idx === -1) {
        this.previousIdx = -1;
        this.previousFieldName = "";
        this.scrollToField(fieldName);
      } else {
        idx = this.previousIdx + idx + 1;
      }
    }

    if (idx != -1) {
      this.viewPort.scrollToIndex(idx, 'smooth');
      this.previousIdx = idx;
      this.previousFieldName = fieldName;
    }
  }

  saveIfWithoutEditState(): void {
    if (!this.editState$ && this.SaveDestinationExportMappingFieldsAction) {
      this.store.dispatch(new this.SaveDestinationExportMappingFieldsAction({ fields: this.fieldsForm.value }));
    }
  }

  closeRemoveRowModal(): void {
    this.showRemoveRowModal = false;
    this.activeRemoveRowIndex = null;
    this.activeRemoveRow = null;
  }

  templateRowDrop({ currentIndex, previousIndex }: CdkDragDrop<Array<DataProcessField>>): void {
    if (!this.MoveRowAction) {
      return;
    }
    this.store.dispatch(new this.MoveRowAction({ currentIndex, previousIndex })).subscribe(() => {
      if (this.SaveDestinationExportMappingFieldsAction) {
        this.store.dispatch(new this.SaveDestinationExportMappingFieldsAction({ fields: this.fieldsForm.value }));
      }
    });
  }

  /* Swaps between tabs (if enabled) on the Function Modal */
  swapTabs(tab: number) {
    this.selectedModalTab = tab;
  }
  /* Swaps between tabs (if enabled) on the Function Modal */
  swapTabs_AI(tab: number) {
    this.selectedModalTab_AI = tab;
    if (tab == 2 && this.counterCall == 0) {
      this.counterCall++
      this.getSuggestionsForAllFields()
    }

  }


  navigateToFunctions(){
    const functionLink = "https://syndic8.atlassian.net/wiki/spaces/II/pages/2074148865/Export+Import+Functions"
    window.open(functionLink, '_blank');
  }
}

