import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-button',
  templateUrl: './simple-button.component.html',
  styleUrls: ['./simple-button.component.scss']
})
export class SimpleButtonComponent implements OnInit {
  @Input() icon: string;

  @HostBinding('class.disabled') @Input() disabled = false;

  constructor() { }

  ngOnInit(): void {
  }

}
