import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

export interface NavigationSelectionItem {
  title: string;
  route?: string;
  action?: () => void;
  selections?: NavigationSelectionItem[];
}

// A nav action can either be a direct route or have a list of selections in a menu
export interface NavigationActionItem {
  id?: number;
  title: string;
  selections?: NavigationSelectionItem[];
  description?: string;
  icon?: string;
  route?: string;
  permission?: {
    create: boolean;
    read: boolean;
    edit: boolean;
    remove: boolean;
    license: boolean;
  };
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  activeNavigation: BehaviorSubject<NavigationActionItem> = new BehaviorSubject<NavigationActionItem>(undefined);
  activeSelection: BehaviorSubject<NavigationActionItem> = new BehaviorSubject<NavigationActionItem>(undefined);

  constructor() { }

  expandNavigation(action: NavigationActionItem): void {
    console.log('hello action=>', action)
    if (!action) {
      // Expand only NavigationListComponent
      action = {
        title: '',
        route: ''
      };
    }
    this.activeNavigation.next(action);
  }

  collapseNavigation(): void {
    this.activeNavigation.next(undefined);
  }

  expandSelection(action: NavigationActionItem): void {
    console.log('hello action=>', action)
    this.activeSelection.next(action);
  }

  collapseSelection(): void {
    this.activeSelection.next(undefined);
  }

  collapseBoth(): void {
    this.activeNavigation.next(undefined);
    this.activeSelection.next(undefined);
  }

  isMatchedRouteBySegments(route: string, action: string): boolean {
    console.log('hello action=>', action)
    const routeSegments: Array<string> = route.split('/');
    const actionSegments: Array<string> = action.split('/');
    let match = false;
    if (!route || routeSegments.length === 0 || actionSegments.length === 0) {
      return false;
    }
    for (let i = 0; i < actionSegments.length; i++) {
      const actionSeg = actionSegments[i];
      const routeSeg = routeSegments[i];
      if (actionSeg !== undefined && routeSeg === actionSeg) {
        match = true;
      } else {
        match = false;
        break;
      }
    }
    return match;
  }
}
