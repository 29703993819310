/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Shipping { 
    activeFlag?: boolean;
    assembledProductHeightUnit?: string;
    assembledProductHeightValue?: number;
    assembledProductLengthUnit?: string;
    assembledProductLengthValue?: number;
    assembledProductWeightUnit?: string;
    assembledProductWeightValue?: number;
    assembledProductWidthUnit?: string;
    assembledProductWidthValue?: number;
    breakPack?: boolean;
    cartonDepthUnit?: string;
    cartonDepthValue?: number;
    cartonHeightUnit?: string;
    cartonHeightValue?: number;
    cartonLengthUnit?: string;
    cartonLengthValue?: number;
    cartonQuantity?: number;
    cartonSellableItems?: number;
    cartonType?: string;
    cartonUpc?: string;
    cartonVolumeUnit?: string;
    cartonVolumeValue?: number;
    cartonWeightUnit?: string;
    cartonWeightValue?: number;
    cartonWidthUnit?: string;
    cartonWidthValue?: number;
    caseCount?: number;
    caseDepthUnit?: string;
    caseDepthValue?: number;
    caseGTIN?: string;
    caseHeightUnit?: string;
    caseHeightValue?: number;
    caseLengthUnit?: string;
    caseLengthValue?: number;
    caseSellableItems?: number;
    caseVolumeUnit?: string;
    caseVolumeValue?: number;
    caseWeightUnit?: string;
    caseWeightValue?: number;
    caseWidthUnit?: string;
    caseWidthValue?: number;
    countPerPack?: number;
    duration?: number;
    durationUnit?: string;
    gdsnSync?: string;
    internationalRestriction?: boolean;
    itemBookingDate?: string;
    itemDepthUnit?: string;
    itemDepthValue?: number;
    itemHeightUnit?: string;
    itemHeightValue?: number;
    itemLengthUnit?: string;
    itemLengthValue?: number;
    itemSellableItems?: number;
    itemTotalCount?: number;
    itemVolumeUnit?: string;
    itemVolumeValue?: number;
    itemWeightUnit?: string;
    itemWeightValue?: number;
    itemWidthUnit?: string;
    itemWidthValue?: number;
    jsonData?: { [key: string]: any; };
    packageDepthUnit?: string;
    packageDepthValue?: number;
    packageHeightUnit?: string;
    packageHeightValue?: number;
    packageLengthUnit?: string;
    packageLengthValue?: number;
    packageQuantity?: number;
    packageSellableItems?: number;
    packageType?: string;
    packageUpc?: string;
    packageVolumeUnit?: string;
    packageVolumeValue?: number;
    packageWeightUnit?: string;
    packageWeightValue?: number;
    packageWidthUnit?: string;
    packageWidthValue?: number;
    palletCount?: number;
    palletDepthUnit?: string;
    palletDepthValue?: number;
    palletGTIN?: string;
    palletHeightUnit?: string;
    palletHeightValue?: number;
    palletLengthUnit?: string;
    palletLengthValue?: number;
    palletSellableItems?: number;
    palletVolumeUnit?: string;
    palletVolumeValue?: number;
    palletWeightUnit?: string;
    palletWeightValue?: number;
    palletWidthUnit?: string;
    palletWidthValue?: number;
    pieces?: number;
    productDepthUnit?: string;
    productDepthValue?: number;
    productId?: number;
    productLifecycleSupplyType?: string;
    productName?: string;
    productPackage?: string;
    rigidPackage?: boolean;
    segregation?: string;
    shipAlone?: boolean;
    shippingId?: number;
    sku?: string;
    upc?: string;
}

