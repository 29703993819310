/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AlternateProperty } from './alternateProperty';
import { Filter } from './filter';


export interface ViewField { 
    dataType?: string;
    displayName?: string;
    editable?: boolean;
    fieldFormat?: string;
    genericFlag?: boolean;
    gmt?: boolean;
    internalName?: string;
    jsonData?: { [key: string]: any; };
    jsonField?: boolean;
    keyField?: boolean;
    maxLength?: number;
    minLength?: number;
    multiFilter?: boolean;
    multiLingual?: boolean;
    multiSelection?: boolean;
    propertyName?: string;
    recommendedFlag?: boolean;
    required?: boolean;
    showField?: boolean;
    sortOrder?: number;
    suggestion?: string;
    suggestionAlternateProperty?: AlternateProperty;
    suggestionQueryFilter?: Filter;
    tableAlias?: string;
    tableName?: string;
    type?: string;
    userViewId?: number;
    viewFieldId?: number;
    width?: number;
}

