<ng-container *ngIf="column.suggestionQueryFilter && column.suggestionQueryFilter[column.suggestion]; else noFetch">
  <div *ngIf="open" class="select">
    <div class="option loading" *ngIf="fetching && !this.fetchedSuggestionsByRowIndex[this.column.propertyName]"
      (click)="openClick = true">loading...</div>
    <ng-container *ngIf="!fetching && this.fetchedSuggestionsByRowIndex[this.column.propertyName]">
      <div class="option" *ngIf="!column.required" [title]="emptyCellText" (click)="setSuggestionValue()">
        <i>{{emptyCellText}}</i>
      </div>
      <div class="option" *ngFor="let suggestion of fetchedSuggestionsByRowIndex[column.propertyName]"
        [title]="suggestion.name" (click)="setSuggestionValue(suggestion)">
        <div class="wrapper">{{ suggestion.name }}</div>
      </div>
      <div class="option" *ngIf="fetchedSuggestionsByRowIndex[column.propertyName]?.length === 0"
        (click)="openClick = true">no available options</div>
    </ng-container>
  </div>
  <div class="value" [attr.title]="selectedValue ? selectedValue : null" (click)="fetchSuggestions()">
    <div class="wrapper">{{ selectedValue || column?.emptyCellText}}</div>
    <div class="flex-fill"></div>
    <ng-container *ngIf="!fetching">
      <i *ngIf="!open" class="fas fa-chevron-down"></i>
      <i *ngIf="open" class="fas fa-chevron-up"></i>
    </ng-container>
  </div>
</ng-container>
<ng-template #noFetch>
  <app-suggestion #selectTest [label]="'Select a option'" (click)="fillSuggestions()" (keyup)="focus()" [height]="'96%'"
  [provided]="filledSuggestions[column.propertyName]" [showClose]="true" [showList]="true" [removeBorder]="true"
  (optionSelected)="setSuggestionValue($event)"
  (closed)="resetValue(column.propertyName)"
  [selected]="[selectedValue]"></app-suggestion>
  <!--
   <div *ngIf="filledSuggestions[column.propertyName] && open" class="select">
    <div class="option" *ngIf="!column.required" (click)="setSuggestionValue()"><i>{{ column.emptyCellText ||
        emptyCellText}}</i></div>
    <div class="option" *ngFor="let suggestion of filledSuggestions[column.propertyName]" [title]="suggestion.name"
      (click)="setSuggestionValue(suggestion)">
      <div class="wrapper">{{ suggestion.name }}</div>
    </div>
  </div>
  <div class="value" [attr.title]="selectedValue ? selectedValue : null" (click)="fillSuggestions()">
    <div class="wrapper">{{ selectedValue || column?.emptyCellText}}</div>
    <div class="flex-fill"></div>
    <i *ngIf="!open" class="fas fa-chevron-down"></i>
    <i *ngIf="open" class="fas fa-chevron-up"></i>
  </div> 
-->
</ng-template>