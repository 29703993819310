import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Suggestion } from '@app/core';
import { QueryFilterFormComponent } from '@app/shared/components/query-filter/query-filter-form/query-filter-form.component';
import { QueryFilter, QueryFilterConfiguration, SearchFilter } from '../../../services/query-filter.service';

@Component({
  selector: 'app-query-filter-picker',
  templateUrl: './query-filter-picker.component.html',
  styleUrls: ['./query-filter-picker.component.scss']
})
export class QueryFilterPickerComponent implements OnInit {
  @Input() suggestions: { [key: string]: Array<Suggestion> } = {};
  @Input() queryFilters: Array<QueryFilter> = [];
  
  @Input() filters: Array<SearchFilter> = [];
  @Output() filtersChange: EventEmitter<Array<SearchFilter>> = new EventEmitter();

  @Input() loadProductTypeQueryFiltersAction: ({ viewName }: { viewName: string }) => void;

  @ViewChild(QueryFilterFormComponent) queryFilterForm: QueryFilterFormComponent;

  @Input() allowOrJoin: boolean = true;
  @Input() allowDateRange: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  removeFilter({ queryFilter, index }): void {
    let indexes: Array<number> = []
    if (queryFilter.filters && queryFilter.filters.length > 0) {
      if (this.queryFilters?.length > 0) {
        indexes = [
          ...indexes,
          ...queryFilter.filters.reduce(
            (acc: Array<number>, filters: Array<QueryFilterConfiguration>) => {
              const subIndexes: Array<number> = filters.reduce(
                (subAcc: Array<number>, filter: QueryFilterConfiguration) => {
                  const index = this.filters.findIndex(f => f.by === queryFilter.propertyName && f.match === filter.match);
                  if (index > -1) {
                    return [...subAcc, index];
                  }
                  return subAcc;
                },
                []
              );
              return [...acc, ...subIndexes];
            },
            []
          )
        ];
      } else [
        indexes
      ]
    } else {
      indexes = [index];
    }
    this.filters = this.filters.filter((f, i) => indexes.findIndex(index => index === i) === -1);
    this.filtersChange.emit(this.filters);
  }

  get formInvalid(): boolean {
    if (this.queryFilterForm === undefined) {
      return true;
    }
    return this.queryFilterForm.filterForm.invalid;
  }

  resetForm(): void {
    this.queryFilterForm.resetFilterForm();
  }

  addFilter(): void {
    this.queryFilterForm.addFilter();
  }

}
