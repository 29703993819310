import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {
  @Input() set options(options: Array<string>) {
    this._options = [...options];
    this.optionsFiltered = [...options];
  };
  @Input() selections: Array<string> = [];
  @Output() selectionsChange: EventEmitter<Array<string>> = new EventEmitter();

  @ViewChild('selectInput') selectInput: ElementRef;

  @HostBinding('style.width.px') @Input() width: string = 'auto';
  @HostListener('click')
  onClick() {
    this.interiorClick = true;
    this.showOptions = true;
    this.selectInput.nativeElement.focus();
  };
  @HostListener('window:click')
  windowClick() {
    if (!this.interiorClick) {
      this.showOptions = false;
    }
    this.interiorClick = false;
  }

  private _options: Array<string> = [];
  optionsFiltered: Array<string> = [];
  interiorClick = false;
  showOptions = false;
  selectInputControl: UntypedFormControl = new UntypedFormControl('');

  constructor() { }

  ngOnInit(): void {
  }

  filterOptions(): void {
    if (!this.selectInputControl.value) {
      this.optionsFiltered = this._options;
      return;
    }
    this.optionsFiltered = [...this._options.filter(option => {
      return option.toLowerCase().includes(this.selectInputControl.value.toLowerCase());
    })];
  }

  addFirstOption(): void {
    if (!this.optionsFiltered[0]) {
      return;
    }
    this.addOption(this.optionsFiltered[0]);
    this.selectInputControl.reset();
    this.filterOptions();
  }

  addOption(option: string): void {
    this.selections = [...this.selections, option];
    this.selectionsChange.emit(this.selections);
  }

  removeSelection(index: number): void {
    this.selections = [...this.selections.filter((c, i) => i === index ? false : true)];
    this.selectionsChange.emit(this.selections);
  }
}
