/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Vessel } from './vessel';
import { ModifiedDate } from './modifiedDate';
import { Location } from './location';


export interface VesselVoyage { 
    arrivedCount?: number;
    availableCount?: number;
    containerCount?: number;
    createdDate?: ModifiedDate;
    distanceToDestination?: number;
    gateOutCount?: number;
    lastUpdated?: ModifiedDate;
    locationDischarge?: Location;
    originalETA?: string;
    vessel?: Vessel;
    vesselArrivalDate?: string;
    vesselETA?: string;
    vesselId?: number;
    vesselVoyageId?: number;
    vesselVoyageName?: string;
    voyageName?: string;
}

