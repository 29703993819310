import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() src: string = '';
  @Input() alt = '';
  @Input() title = '';
  @Input() fallbackIcon = 'image';
  @Input() missingIcon = 'times';

  @ViewChild('image') private image: ElementRef
  imageError: () => void;
  imageLoad: () => void;

  showFallback = false;
  showImage = false;
  showMissing = false;

  constructor(private renderer: Renderer2, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (!this.src) {
      this.showMissing = true;
    }
  }

  ngAfterViewInit(): void {
    this.imageError = this.renderer.listen(this.image.nativeElement, 'error', () => {
      this.showFallback = true;
      this.cd.markForCheck();
    });
    this.imageLoad = this.renderer.listen(this.image.nativeElement, 'load', () => {
      this.showImage = true;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    if (this.imageError) {
      this.imageError();
    }
    if (this.imageLoad) {
      this.imageLoad();
    }
  }
}
