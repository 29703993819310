import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationSelectionItem, NavigationService } from '../../../services/navigation.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';
import { RouterState } from '@ngxs/router-plugin';

@Component({
  selector: 'app-selection-action',
  templateUrl: './selection-action.component.html',
  styleUrls: ['./selection-action.component.scss']
})
export class SelectionActionComponent implements OnInit, OnDestroy {
  @Input() selection: NavigationSelectionItem;

  isSelectionActive = false;

  routerEvents: Subscription;

  constructor(private router: Router, private navigation: NavigationService, private store: Store) { }

  ngOnInit(): void {
    this.routerEvents = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
    ).subscribe(() => this.setActiveSelection());

    this.setActiveSelection();
  }

  ngOnDestroy(): void {
    this.routerEvents.unsubscribe();
  }

  setActiveSelection(): void {
    console.log('hello here selection action')
    const url: string = this.store.selectSnapshot(RouterState.url).split('?')[0];
    console.log('hello here selection action',url)
    if (this.selection?.route && url.indexOf(this.selection.route) > -1 && (this.selection.route !== '/org' || url === '/org')) {
      this.isSelectionActive = true;
      console.log('active')
    } else {
      this.isSelectionActive = false;
      console.log('not active');
    }
  }

  navigateToSelection(event: Event, route: string): void {
    event.preventDefault();
    this.navigation.collapseSelection();
    this.router.navigateByUrl(route);
  }
}
