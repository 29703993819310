import { Pipe, PipeTransform } from '@angular/core';
import { Query, QueryOperator } from '../typings/query.types';

@Pipe({ name: 'ChainedQueryFilter' })
export class ChainedQueryFilterPipe implements PipeTransform {
  transform(value: Array<any>, queries: Array<Query>) {
    const that = this;
    return queries.reduce(function (filteredValues, currentQuery) {
      return filteredValues.filter(that.getQueryFunction(currentQuery));
    }, value);
  }

  getQueryFunction(query) {
    switch (query.operator) {
      case QueryOperator.Is:
        return (item) => {
          return item[query.propertyName].toString().toLowerCase() === query.value.toString().toLowerCase();
        };
      case QueryOperator.IsNot:
        return (item) => {
          return item[query.propertyName] !== query.value;
        };
      case QueryOperator.IsLessThan:
        return function (item) {
          return item[query.propertyName] < query.value;
        };
      case QueryOperator.IsMoreThan:
        return (item) => {
          return item[query.propertyName] > query.value;
        };
      //   case QueryOperator.IsBetween:
      //   return function(item){
      //     return item[query.propertyName] <= query.value && item[query.propertyName] >= query.value;
      //   }
      case QueryOperator.IsBefore:
        return (item) => {
          return item[query.propertyName] < query.value;
        };
      case QueryOperator.IsAfter:
        return (item) => {
          return item[query.propertyName] > query.value;
        };
      case QueryOperator.Contains:
        return (item) => {
          return item[query.propertyName].toString().toLowerCase().includes(query.value.toString().toLowerCase());
        };
    }
  }
}
