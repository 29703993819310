import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aOrAn'
})
export class AOrAnPipe implements PipeTransform {
  transform(value: string): string {
    if (this.isVowel(value.charAt(0))) {
      return 'an'
    }
    return 'a';
  }

  private isVowel(char: string): boolean {
    if (!char || char.length > 1) {
      return false;
    }
    const lowerChar: string = char.toLowerCase();
    return lowerChar === 'a' || lowerChar === 'e' || lowerChar === 'i' || lowerChar === 'o' || lowerChar === 'u' || false;
  }
}
