/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FilterMatch = 'CONTAINS' | 'DOESNOTSTARTSWITH' | 'ENDSWITH' | 'INLIST' | 'IS' | 'ISAFTER' | 'ISBEFORE' | 'ISBETWEEN' | 'ISLESSTHAN' | 'ISLESSTHANEQUAL' | 'ISMORETHAN' | 'ISMORETHANEQUAL' | 'ISNOT' | 'ISNOTNULL' | 'ISNULL' | 'NOTINLIST' | 'STARTSWITH';

export const FilterMatch = {
    CONTAINS: 'CONTAINS' as FilterMatch,
    DOESNOTSTARTSWITH: 'DOESNOTSTARTSWITH' as FilterMatch,
    ENDSWITH: 'ENDSWITH' as FilterMatch,
    INLIST: 'INLIST' as FilterMatch,
    IS: 'IS' as FilterMatch,
    ISAFTER: 'ISAFTER' as FilterMatch,
    ISBEFORE: 'ISBEFORE' as FilterMatch,
    ISBETWEEN: 'ISBETWEEN' as FilterMatch,
    ISLESSTHAN: 'ISLESSTHAN' as FilterMatch,
    ISLESSTHANEQUAL: 'ISLESSTHANEQUAL' as FilterMatch,
    ISMORETHAN: 'ISMORETHAN' as FilterMatch,
    ISMORETHANEQUAL: 'ISMORETHANEQUAL' as FilterMatch,
    ISNOT: 'ISNOT' as FilterMatch,
    ISNOTNULL: 'ISNOTNULL' as FilterMatch,
    ISNULL: 'ISNULL' as FilterMatch,
    NOTINLIST: 'NOTINLIST' as FilterMatch,
    STARTSWITH: 'STARTSWITH' as FilterMatch
};

