import { Injectable } from '@angular/core';
import { UrlTree, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';

import { LoadEmailUserAction, LoadUserAction } from '../stores/auth.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { AuthState, TokenData } from '../stores/auth.state';
import { ExceptionService } from '../services/exception.service';
import jwt_decode from 'jwt-decode';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LoadUserGuard  {

  constructor(private store: Store, private auth: AuthService, private router: Router, private exception: ExceptionService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.auth.getAccessTokenSilently().pipe(
      mergeMap(
        (token: string) => {
          const tokenData: TokenData = jwt_decode(token);

          // Don't allow email only Users access to non-public routes, forward them to public route
          if (state.url.startsWith('/org') && tokenData.sub.startsWith('email|')) {
            return of(this.router.createUrlTree(['pub']));
          }

          // Prevent the User from loading twice because this Action is dispatched from a route Guard
          // This is an issue with how Angular runs all Guards first down the route tree before any Resolvers
          // This might cause Angular to run the LoadUserAction more than once if a child guard returns a UrlTree and redirects
          // Like a guard that checks if any queryParams are invalid or missing
          const isUserLoaded: boolean = this.store.selectSnapshot(AuthState.isLoaded);
          if (!isUserLoaded) {
            if (tokenData.sub.startsWith('auth0|') || tokenData.sub.startsWith('oidc|') || tokenData.sub.startsWith('samlp|')) {
              return this.store.dispatch(new LoadUserAction({ token, tokenData })).pipe(
                map(() => true)
              );
            } else if(tokenData.sub.startsWith('email|')) {
              return this.store.dispatch(new LoadEmailUserAction({ token, tokenData })).pipe(
                map(() => true)
              );
            }
            return of(false);
          }
          return of(true);
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.exception.add({
            name: 'LoadUserGuard',
            info: 'The server is having trouble loading your User.',
            error: JSON.stringify(error)
          });
          return of(this.router.createUrlTree(['/error']));
        }
      )
    );
  }
}
