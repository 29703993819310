/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';


export interface VerifyLive { 
    createdDate?: ModifiedDate;
    fulfilmentMethod?: string;
    inventoryFeed?: number;
    isLive?: boolean;
    isMap?: boolean;
    lastUpdated?: ModifiedDate;
    liveUrl?: string;
    liveVerifyDate?: string;
    notes?: string;
    numberOfImages?: number;
    orgRef?: OrganizationReference;
    orgRefDestination?: OrganizationReference;
    productName?: string;
    siteIdentifier?: string;
    sitePrice?: number;
    sku?: string;
    status?: string;
    subCatalogId?: number;
    upc?: string;
    verifyLiveId?: number;
}

