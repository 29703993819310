/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';


export interface POContainer { 
    blNumber?: string;
    containerNumber?: string;
    containerType?: string;
    createdDate?: ModifiedDate;
    jsonData?: { [key: string]: any; };
    ladingQuantity?: number;
    lastUpdated?: ModifiedDate;
    masterBlNumber?: string;
    orgRef?: OrganizationReference;
    orgRefLsp?: OrganizationReference;
    purchaseDetailId?: number;
    purchaseOrderId?: number;
    sealNumber?: string;
    statsId?: number;
    vesselEta?: string;
    vesselMode?: string;
    vesselName?: string;
    vesselVoyage?: string;
}

