/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BatchLoadStatus { 
    batchEndDate?: string;
    batchLoadId?: number;
    batchStartDate?: string;
    batchStatus?: string;
    organizationId?: number;
    partnerBatchEndDate?: string;
    partnerBatchStartDate?: string;
    partnerKey?: string;
    partnerTotalCount?: number;
    partnerTotalErrors?: number;
    partnerTotalPass?: number;
    processType?: string;
    totalCount?: number;
    totalErrors?: number;
    totalPass?: number;
}

