import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CheckboxComponent } from '../checkbox/checkbox.component';

@Component({
  selector: 'app-checkbox-wrapper',
  templateUrl: './checkbox-wrapper.component.html',
  styleUrls: ['./checkbox-wrapper.component.scss']
})
export class CheckboxWrapperComponent implements OnInit {
  @Input() shape = 'circle';
  @Input() color = 'green';
  @Output() selectedChange: EventEmitter<boolean> = new EventEmitter();

  @HostBinding('class.disabled') @Input() disabled = false;
  @HostBinding('class.selected') @Input() selected = false;

  @HostListener('click', ['$event'])
  click(event: MouseEvent) {
    if (this.element.nativeElement.isSameNode(event.target)) {
      this.checkbox.check();
    }
  }

  @ViewChild(CheckboxComponent) checkbox: CheckboxComponent;

  constructor(private element: ElementRef) { }

  ngOnInit(): void {
  }

}
