import { HostBinding } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Chip, ChipType } from '../destination-chip/destination-chip.component';

@Component({
  selector: 'app-destination-chip-list',
  templateUrl: './destination-chip-list.component.html',
  styleUrls: ['./destination-chip-list.component.scss']
})
export class DestinationChipListComponent implements OnInit {
  @Input() chips: Array<Chip> = [];
  @Input() showDelete = false;
  @Output() removeChip: EventEmitter<Chip> = new EventEmitter();

  @HostBinding('class.wrap') @Input() wrap = false;

  noValueChip: Chip = {
    type: ChipType.Missing,
    value: 'None'
  };

  ChipType = ChipType;

  constructor() { }

  ngOnInit(): void {
  }

}
