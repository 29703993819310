<div class="wrapper">
  <div class="content" [ngClass]="{'in': in}">
    <div class="header">
      Sorry...
    </div>
    <div class="subheader">
      The page you're looking<br>
      for does not exist.
    </div>
    <hr>
    <div class="clearfix"></div>
    <div class="context">
      Go back to the previous page and try again.<br>
      If you think something is broken, please report a problem.
    </div>
    <div class="buttons">
      <a class="button" [href]="supportUrl" target="_blank">Report a problem</a>
      <a class="button" (click)="navigateBack()">Go Back</a>
      <a class="button" routerLink="/logout">Logout</a>
    </div>
  </div>
</div>
