/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';


export interface DrayCarrier { 
    approved?: boolean;
    createdDate?: ModifiedDate;
    documents?: boolean;
    dot?: string;
    email?: string;
    geoTags?: string;
    lastUpdated?: ModifiedDate;
    mc?: string;
    notes?: string;
    orgRef?: OrganizationReference;
    phoneNumber?: string;
    tms?: string;
}

