<ng-container *ngIf="logistics">
  <div *ngIf="selectedRows.length === 0 && !screenName" class="selected-count">none selected</div>
  <div *ngIf="!rowCountLoading && (selectedRows.length > 0 || screenName)" class="selected-count">
    {{ masterChecked.checked && !hideAllDisplay ? 'All' : selectedRows.length }} selected<ng-container
      *ngIf="totalRowCount && screenName"> of <span *ngIf="!rowCountLoading">{{ totalRowCount | number
        }}</span></ng-container>
  </div>
  <div *ngIf="rowCountLoading && (selectedRows.length > 0 || screenName)" class="selected-count">
    <app-spinner></app-spinner>
  </div>
</ng-container>
<ng-container *ngIf="!logistics">
  <div *ngIf="selectedRows.length === 0" class="selected-count">
    <ng-container *ngIf="screenName || productView; else elseNone">0</ng-container>
    <ng-template #elseNone>none</ng-template> selected<ng-container *ngIf="totalRowCount && screenName || productView">
      of {{ totalRowCount | number }}</ng-container>
  </div>
  <div *ngIf="selectedRows.length > 0" class="selected-count">
    {{ masterChecked.checked && masterChecked.directly && !hideAllDisplay ? 'All' : selectedRows.length }}
    selected<ng-container *ngIf="totalRowCount && screenName || productView"> of {{ totalRowCount | number
      }}</ng-container>
  </div>
</ng-container>
<div class="actions-button" (click)="openActions()" [ngClass]="{'expanded': open }">
  Actions
  <i class="fas fa-caret-down"></i>
  <div class="actions">
    <ng-container *ngFor="let action of actions">
      <div class="action" (click)="actionClick($event, action)"
        *ngIf="isPermissible(action) && action.hidden !== true && (!action.hiddenFn || action.hiddenFn(selectedRows) !== true)"
        [ngClass]="{'border-bottom': action.bottomBorder, 'border-top': action.topBorder}">
        <div class="icon" *ngIf="action.icon">
          <i [attr.class]="(action.iconPrefix || 'fad') +' fa-' + action.icon" [ngClass]="{
            'red': action.iconColor === SelectedActionColor.Red,
            'green': action.iconColor === SelectedActionColor.Green,
            'blue': action.iconColor === SelectedActionColor.Blue,
            'yellow': action.iconColor === SelectedActionColor.Yellow,
            'navy': action.iconColor === SelectedActionColor.Navy,
            'purple': action.iconColor === SelectedActionColor.Purple
          }"></i>
        </div>
        <div class="title">
          {{ action.title }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
