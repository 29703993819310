import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToCamelCase'
})
export class StringToCamelCasePipe implements PipeTransform {

  transform(input: string): string {
    input = input.replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) => idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()).replace(/\s+/g, '');
    return input;
  }

}
