/**
 * Syndic8 DMS Dev API
 * This is the official Syndic8 DMS Dev application program interface
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModifiedDate } from './modifiedDate';
import { OrganizationReference } from './organizationReference';


export interface PurchaseOrderItem { 
    cartonId?: string;
    cartonQuantity?: number;
    cartonWeight?: number;
    containerId?: number;
    createdDate?: ModifiedDate;
    cubicMeters?: number;
    htsCode?: string;
    itemName?: string;
    itemNumber?: string;
    lastUpdated?: ModifiedDate;
    multiSku?: boolean;
    packQuantity?: number;
    productOrgRef?: OrganizationReference;
    purchaseOrderDetailId?: number;
    purchaseOrderId?: number;
    purchaseOrderItemId?: number;
    quantity?: number;
    sku?: string;
    styleName?: string;
    upc?: string;
}

